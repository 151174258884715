import * as React from 'react';
import {PocAnnouncement} from './components/poc-announcement.component';
import {IntegrationSetupAnnouncement} from './components/integration-setup-announcement.component';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {IntegrationStatus} from '../../../../objects/models/product-data.model';
import {InactiveSourceAnnouncement} from './components/inactive-source-announcement.component';
import {DemoEnvironmentAnnouncement} from './components/demo-environment-announcement.component';
import {useDemoProduct} from '../../../../core/hooks/use-demo-product.hook';

interface OwnProps {}

type AllProps = OwnProps;
export const AppAnnouncementBar: React.FC<AllProps> = () => {
  const {integrationStatus, isSourceActive} = useProductData();
  const {isDemoProduct} = useDemoProduct();

  if (isDemoProduct) {
    return <DemoEnvironmentAnnouncement />;
  }

  if (integrationStatus === IntegrationStatus.SETUP) {
    return <IntegrationSetupAnnouncement />;
  }

  if (!isSourceActive) {
    return <InactiveSourceAnnouncement />;
  }

  return <PocAnnouncement />;
};

import * as React from 'react';
import {useCallback, useMemo, useState} from 'react';
import classes from './error-viewer.module.scss';
import {AppErrorFigureProps} from './app-error-viewer.component';
import {ImagesResource} from '../../../../../../assets/images';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../../../constants/translation-keys';
import {Button, Checkbox} from 'ui-components';
import {AmplitudeEvent} from '../../../../../../constants/amplitude-event';
import {useAmplitude} from '../../../../../../core/hooks/amplitude.hook';
import {contactSupport} from '../../../../../../utils/general.utils';
import {NoteText} from '../../../../components/general/note-text/note-text.component';
import classNames from 'classnames';
import {useDispatch} from 'react-redux';
import {changeAnalysisParameters} from '../../../../../../store/analyses/analyses.actions';
import {useHistory} from 'react-router';
import {AppRoutes} from '../../../../../../constants/app-routes';

export function Error2003Viewer(props: AppErrorFigureProps) {
  const {
    analysisId,
    analysisResultId,
    data: {payload = {}},
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const {t} = useTranslation();
  const notify = useAmplitude();
  const [selectedOption, setSelectedOption] = useState(null);
  const [isDone, setIsDone] = useState(false);

  const onSubmit = useCallback(() => {
    dispatch(
      changeAnalysisParameters(
        {
          analysisId,
          analysisResultId,
          parameters: {
            control_group_name: selectedOption,
          },
        },
        {onSuccess: () => setIsDone(true)}
      )
    );
    notify(AmplitudeEvent.ERROR_2003_CHANGE_CONTROL_GROUP_CLICKED, {
      analysisId,
      analysisResultId,
    });
  }, [notify, analysisId, analysisResultId, selectedOption, dispatch]);
  const mainText = useMemo(() => {
    if (isDone) {
      return t(TransKeys.ANALYSIS_ERRORS.ERROR_2003.TITLE_DONE);
    }
    return t(TransKeys.ANALYSIS_ERRORS.ERROR_2003.TITLE, payload);
  }, [isDone, payload, t]);

  return (
    <div className={classes.ErrorFigure}>
      <div className={classes.Error}>
        <div className={classes.ImageWrapper}>
          <img src={ImagesResource.app.magnifyingGlassOnUsers} alt={''} />
        </div>
        <div className={classes.Title}>{mainText}</div>
        {!isDone && (
          <>
            <div className={classes.Content}>
              <ol className={classNames(classes.List, classes.OptionsList)}>
                {(payload.options || []).map((o, idx) => (
                  <li key={idx} className={classes.Option}>
                    <div
                      onClick={() => setSelectedOption(o.name)}
                      className={classNames(classes.OptionContent, classes.Clickable)}
                    >
                      <Checkbox
                        multi={false}
                        checked={o.name === selectedOption}
                        onChange={() => setSelectedOption(o.name)}
                        className={classes.Checkbox}
                      />
                      <span>{o.name}</span>
                    </div>
                  </li>
                ))}
              </ol>
            </div>
            <div className={classes.Button}>
              <Button
                size={'large'}
                className={classes.CTA}
                disabled={selectedOption === null}
                onClick={onSubmit}
              >
                {t(TransKeys.ANALYSIS_ERRORS.ERROR_2003.CONTENT.ACTION)}
              </Button>
            </div>
            <div className={classes.Support}>
              <NoteText
                text={t(TransKeys.SUPPORT.NEED_HELP)}
                buttonText={t(TransKeys.SUPPORT.SUPPORT_BUTTON_TEXT)}
                onButtonClicked={contactSupport}
              />
            </div>
          </>
        )}
        {isDone && (
          <Button className={classes.CTA} onClick={() => history.push(AppRoutes.experiments())}>
            {t(TransKeys.ANALYSIS_ERRORS.ERROR_2003.CONTENT.ACTION_DONE)}
          </Button>
        )}
      </div>
    </div>
  );
}

import {RCAInsightsSentenceKeys} from './objects';
import {SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseAndKpiIncreaseDecreaseFormatter} from './spike-drop-over-under-performing-segment-share-increase-decrease-and-kpi-increase-decrease.component';
import {SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseFormatter} from './spike-drop-over-under-performing-segment-share-increase-decrease.component';
import {SpikeDropOverUnderPerformingIncreasedDecreasedKpiFormatter} from './spike-drop-over-under-performing-increased-decreased-kpi.component';
import {NoExplainersFormatter} from './no-explainers.component';
import {NotSignificantChangeKPIAndUsersFormatter} from './not-significant-change-kpi-and-users.component';
import {NotSignificantChangeKPIFormatter} from './not-significant-change-kpi.component';
import {NotSignificantUsersChangeFormatter} from './not-significant-users-change.component';

export const RCA_INSIGHTS_SENTENCE_FORMATTERS_MAPPING = {
  [RCAInsightsSentenceKeys.SPIKE_OVERPERFORMING_SEGMENT_SHARE_INCREASE_AND_KPI_INCREASE]:
    SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseAndKpiIncreaseDecreaseFormatter,
  [RCAInsightsSentenceKeys.SPIKE_UNDERPERFORMING_SEGMENT_SHARE_DECREASE_AND_KPI_INCREASE]:
    SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseAndKpiIncreaseDecreaseFormatter,
  [RCAInsightsSentenceKeys.DROP_UNDERPERFORMING_SEGMENT_SHARE_INCREASE_AND_KPI_DECREASED]:
    SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseAndKpiIncreaseDecreaseFormatter,
  [RCAInsightsSentenceKeys.DROP_OVERPERFORMING_SEGMENT_SHARE_DECREASE_AND_KPI_DECREASED]:
    SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseAndKpiIncreaseDecreaseFormatter,

  [RCAInsightsSentenceKeys.SPIKE_OVERPERFORMING_SEGMENT_SHARE_INCREASE]:
    SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseFormatter,
  [RCAInsightsSentenceKeys.SPIKE_UNDERPERFORMING_SEGMENT_SHARE_DECREASE]:
    SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseFormatter,
  [RCAInsightsSentenceKeys.DROP_UNDERPERFORMING_SEGMENT_SHARE_INCREASE]:
    SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseFormatter,
  [RCAInsightsSentenceKeys.DROP_OVERPERFORMING_SEGMENT_SHARE_DECREASE]:
    SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseFormatter,

  [RCAInsightsSentenceKeys.SPIKE_OVERPERFORMING_SEGMENT_INCREASES_KPI]:
    SpikeDropOverUnderPerformingIncreasedDecreasedKpiFormatter,
  [RCAInsightsSentenceKeys.SPIKE_UNDERPERFORMING_SEGMENT_INCREASED_KPI]:
    SpikeDropOverUnderPerformingIncreasedDecreasedKpiFormatter,
  [RCAInsightsSentenceKeys.DROP_UNDERPERFORMING_SEGMENT_DECREASED_KPI]:
    SpikeDropOverUnderPerformingIncreasedDecreasedKpiFormatter,
  [RCAInsightsSentenceKeys.DROP_OVERPERFORMING_SEGMENT_DECREASED_KPI]:
    SpikeDropOverUnderPerformingIncreasedDecreasedKpiFormatter,

  [RCAInsightsSentenceKeys.NO_EXPLAINERS]: NoExplainersFormatter,

  [RCAInsightsSentenceKeys.NOT_SIGNIFICANT_CHANGE_KPI_AND_USERS]:
    NotSignificantChangeKPIAndUsersFormatter,
  [RCAInsightsSentenceKeys.NOT_SIGNIFICANT_CHANGE_KPI]: NotSignificantChangeKPIFormatter,
  [RCAInsightsSentenceKeys.NOT_SIGNIFICANT_USERS_CHANGE]: NotSignificantUsersChangeFormatter,
};

import {useCallback, useMemo} from 'react';
import {useAmplitude} from '../../../../../core/hooks/amplitude.hook';
import {DocumentTrackingType, InteractionType} from 'ui-components';
import {AnalysisResult} from 'src/objects/models/analysis-result.model';
import {v4 as uuidv4} from 'uuid';

export const useDocumentTracking = (analysisResult: AnalysisResult) => {
  const notify = useAmplitude();

  const commonEventProperties = useMemo(
    () => ({
      analysis_result_id: analysisResult.id,
      analysis_type_id: analysisResult.analysisTypeId,
      is_example_analysis: analysisResult.isExample,
      viewing_session_id: uuidv4(),
      viewing_session_start: new Date(),
    }),
    [analysisResult]
  );

  const track = useCallback(
    (type: InteractionType, message: any) => {
      let eventName: string = `analysis_result_command_${type.toLowerCase()}`;
      let payload = {};

      if (type === InteractionType.REFERENCE) {
        payload['value'] = message.modelType;
        payload['info1'] = message.modelId;
      }

      if (type === InteractionType.CREATION) {
        payload['value'] = message.modelType;
      }

      if (type === InteractionType.VIEW_ARTICLE) {
        payload['value'] = message.articleId;
      }

      if (type === InteractionType.FOLLOW_UP) {
        payload['value'] = message.type;
      }

      if (type === InteractionType.NOTIFY) {
        eventName = `analysis_result_${message.trackingType.toLowerCase()}`;
        payload['id'] = message.id;
        payload['element_type'] = message.elementType;
        payload['value'] = message.value;
        payload['tracking_type'] = message.trackingType;

        switch (message.trackingType) {
          case DocumentTrackingType.INPUT:
            payload['info1'] = message.info.inputType;
            payload['info2'] = message.info.value;
            break;
          case DocumentTrackingType.ITEM_CLICKED:
            payload['info1'] = message.info.name;
            payload['info2'] = message.info.type;
            break;
          case DocumentTrackingType.PAGINATION:
            payload['info1'] = message.info?.perPage;
            break;
          case DocumentTrackingType.SORT:
            payload['info1'] = message.info?.order;
            break;
          case DocumentTrackingType.FILTER:
            payload['info1'] = message.info?.filter;
            break;
          case DocumentTrackingType.ITEM_TOGGLED:
            payload['info1'] = message.info?.title;
            break;
          case DocumentTrackingType.MODAL_OPENED:
            payload['info1'] = message.info?.name;
        }
      }

      const sessionDuration =
        new Date().getTime() - commonEventProperties.viewing_session_start.getTime();

      if (eventName) {
        notify(eventName as any, {
          ...commonEventProperties,
          ...payload,
          viewing_session_duration: sessionDuration,
        });
      }
    },
    [notify, commonEventProperties]
  );

  return track;
};

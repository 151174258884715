import {AppRoutes} from './app-routes';

export enum UserOnboardingStatus {
  NOT_STARTED = 'not_started',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  DISMISSED = 'dismissed',
  COMPLETED_AND_DISMISSED = 'completed_and_dismissed',
}

export enum UserOnboardingTaskType {
  COMPLETE_SIGNUP = 'complete_signup',
  VIEW_SHARED_ANALYSIS = 'view_shared_analysis',
  VIEW_EXAMPLE_ANALYSIS = 'view_example_analysis',
  VIEW_OPPORTUNITY = 'view_opportunity',
  VIEW_ANALYSIS_OF_INTEREST = 'view_analysis_of_interest',
  ADD_METRIC_TO_HEALTH_MONITOR = 'add_metric_to_health_monitor',
  ADD_METRIC_TO_HOMEPAGE = 'add_metric_to_homepage',
  CREATE_EXPERIMENT = 'create_experiment',
  RUN_AND_VIEW_ANALYSIS = 'run_and_view_analysis',
}

export const USER_ONBOARDING_TASK_ACTION_URL_MAP = {
  [UserOnboardingTaskType.COMPLETE_SIGNUP]: () => null,
  [UserOnboardingTaskType.VIEW_SHARED_ANALYSIS]: () => AppRoutes.sharedAnalyses(),
  [UserOnboardingTaskType.VIEW_EXAMPLE_ANALYSIS]: () => AppRoutes.analyses(),
  [UserOnboardingTaskType.VIEW_ANALYSIS_OF_INTEREST]: ({analysisId, analysisResultId}) =>
    AppRoutes.viewAnalysis(analysisId, {resultId: analysisResultId}),
  [UserOnboardingTaskType.VIEW_OPPORTUNITY]: () => AppRoutes.opportunities(),
  [UserOnboardingTaskType.ADD_METRIC_TO_HOMEPAGE]: () => AppRoutes.homepage(),
  [UserOnboardingTaskType.ADD_METRIC_TO_HEALTH_MONITOR]: () => AppRoutes.healthMonitor(),
  [UserOnboardingTaskType.CREATE_EXPERIMENT]: () => AppRoutes.experiments(),
  [UserOnboardingTaskType.RUN_AND_VIEW_ANALYSIS]: () => AppRoutes.createAnalysis(),
};

export const USER_ONBOARDING_TASK_ORDER = [
  UserOnboardingTaskType.COMPLETE_SIGNUP,
  UserOnboardingTaskType.ADD_METRIC_TO_HOMEPAGE,
  UserOnboardingTaskType.VIEW_SHARED_ANALYSIS,
  UserOnboardingTaskType.VIEW_EXAMPLE_ANALYSIS,
  UserOnboardingTaskType.VIEW_ANALYSIS_OF_INTEREST,
  UserOnboardingTaskType.VIEW_OPPORTUNITY,
  UserOnboardingTaskType.ADD_METRIC_TO_HEALTH_MONITOR,
  UserOnboardingTaskType.CREATE_EXPERIMENT,
  UserOnboardingTaskType.RUN_AND_VIEW_ANALYSIS,
];

export function userOnboardingTaskOrderComparator(
  a: UserOnboardingTaskType,
  b: UserOnboardingTaskType
) {
  return USER_ONBOARDING_TASK_ORDER.indexOf(a) - USER_ONBOARDING_TASK_ORDER.indexOf(b);
}

import * as React from 'react';
import {Redirect, Route, Switch} from 'react-router';
import {AppRoutes} from '../../constants/app-routes';
import {FeaturesMain} from './pages/features-main/features-main.component';
import {SegmentsMain} from './pages/segments-main/segments-main.component';
import {MetricsMain} from './pages/metrics-main/metrics-main.component';
import {AnnotationsMain} from './pages/annotations-main/annotations-main.component';
import {FunnelsMain} from './pages/funnels-main/funnels-main.component';
import {ContentsMain} from './pages/contents-main/contents-main.component';
import {TablesMain} from './pages/tables-main/tables-main.component';
import {TableEventsMain} from './pages/table-events-main/table-events-main.component';
import {EventGroupsMain} from './pages/event-groups-main/event-groups-main.component';
import {MilestonesMain} from './pages/milestones-main/milestones-main.component';

export const DMPRouting: React.FC = props => {
  return (
    <Switch>
      <Route path={AppRoutes.features().pathname} component={FeaturesMain} exact />
      <Route path={AppRoutes.contents().pathname} component={ContentsMain} exact />
      <Route path={AppRoutes.funnels().pathname} component={FunnelsMain} exact />
      <Route path={AppRoutes.segments().pathname} component={SegmentsMain} exact />
      <Route path={AppRoutes.kpis().pathname} component={MetricsMain} exact />
      <Route path={AppRoutes.eventGroups().pathname} component={EventGroupsMain} exact />
      <Route path={AppRoutes.annotations().pathname} component={AnnotationsMain} exact />
      <Route path={AppRoutes.views().pathname} component={TablesMain} exact />
      <Route path={AppRoutes.tableEvents().pathname} component={TableEventsMain} exact />
      <Route path={AppRoutes.milestones().pathname} component={MilestonesMain} exact />
      <Route path={AppRoutes.dmp().pathname} render={props => <Redirect to={AppRoutes.kpis()} />} />
    </Switch>
  );
};

import {StoreDefinition, StorePropertyType} from 'front-core';
import {SelectedActionType} from './selected.actions';
import {
  createSelectedReducer,
  modelChanged,
  removeSelectedReducer,
  upsertSelectedReducer,
} from './selected.reducers';
import {CoreActionsType} from '../core/core.actions';

export const SELECTED_STORE_KEY = 'SELECTED_STORE';

export const selectedStore: StoreDefinition = {
  key: SELECTED_STORE_KEY,
  properties: {
    selected: {
      propertyType: StorePropertyType.OBJECT,
      defaultValue: {},
    },
  },
  reducer: {
    [SelectedActionType.CREATE_SELECTED]: createSelectedReducer,
    [SelectedActionType.UPSERT_SELECTED]: upsertSelectedReducer,
    [SelectedActionType.REMOVE_SELECTED]: removeSelectedReducer,
    [CoreActionsType.MODEL_UPDATED]: modelChanged,
  },
};

import classes from './analysis-executed-sqls-panel.module.scss';
import {useTranslation} from 'react-i18next';
import {
  AppTabs,
  CodeLightIcon,
  DatabaseIcon,
  TableListLightIcon,
  ArrowUpRightLightIcon,
  FancyHeader,
  ModalLayout,
} from 'ui-components';
import {composition, exists} from 'front-core';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {ANALYSIS_RESULT_ID_PATH_PARAM, TABLE_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {getAnalysisResultExecutedSqlNetworkRequest} from '../../../../http/analysis-results.network-requests';
import {StringParam, useQueryParam} from 'use-query-params';
import {useCallback, useContext, useEffect, useMemo} from 'react';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {PanelKey} from '../../../../constants/panels';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import TransKeys from 'translations';
import {ExecutedSql} from '../../../../objects/models/executed-sql.model';
import {format} from 'sql-formatter';
import {SqlInput} from '../../../shared/form/components/sql-input/sql-input.component';

interface OwnProps {
  onClose: () => void;
  executedSqls: ExecutedSql[];
}

type AllProps = OwnProps;

const SELECT_ANALYSIS_QUERIES_KEY = 'VIEW_ANALYSIS/QUERIES';
const FORMAT_CONFIG = {
  expressionWidth: 34,
};

const parseTabKey = (idx: number, str?: string) => {
  if (str) {
    return str.split(' ').join('_').toLowerCase();
  }
  return `query_${idx + 1}`;
};

const AnalysisExecutedSqlsPanelComponent: React.FC<AllProps> = (props: AllProps) => {
  const {onClose, executedSqls} = props;
  // Hooks
  const {openSecondaryPanel} = useContext(PanelsContext);
  const {t} = useTranslation();
  const [selectedTab, setSelectedTab] = useQueryParam('executedSqlTab', StringParam);
  const notify = useAmplitude();
  // Memos
  const tabs = useMemo(
    () =>
      executedSqls.map((q, idx) => ({
        key: parseTabKey(idx, q.name),
        label: q.name || `Query ${idx + 1}`,
      })),
    [executedSqls]
  );

  const currentExecutedSql = useMemo(() => {
    if (!selectedTab && tabs?.length > 0) {
      return null;
    }
    let sql = executedSqls.find((q, idx) => parseTabKey(idx, q.name) === selectedTab);
    const tempStatement = sql?.statement || '';

    try {
      sql.statement = format(sql.statement, FORMAT_CONFIG);
    } catch (e) {
      if (!exists(sql)) {
        sql = {
          statement: tempStatement,
        } as any;
      } else {
        sql.statement = tempStatement;
      }
    }
    return sql;
  }, [selectedTab, tabs, executedSqls]);
  // Callbacks
  const onTabClick = useCallback(
    (queryTab: string) => {
      setSelectedTab(queryTab);
      notify(AmplitudeEvent.EXECUTED_SQL_TAB_CLICKED, {tab: queryTab});
    },
    [setSelectedTab, notify]
  );

  const showTable = useCallback(
    (tableId: number) =>
      openSecondaryPanel(PanelKey.VIEW_TABLE_PANEL, {[TABLE_ID_PATH_PARAM]: tableId}),
    [openSecondaryPanel]
  );

  // Effects
  useEffect(() => {
    if (!selectedTab && tabs?.length > 0) {
      setSelectedTab(tabs[0].key);
    }
  }, [selectedTab, tabs, setSelectedTab]);
  // cleanup query
  useEffect(() => {
    return () => {
      setSelectedTab(undefined);
    };
  }, [setSelectedTab]);

  return (
    <div className={classes.Container}>
      <ModalLayout>
        <FancyHeader
          title={t(TransKeys.ANALYSIS_EXECUTED_SQLS_PANEL.HEADER.TITLE)}
          icon={CodeLightIcon}
          onClose={onClose}
        />
        <div className={classes.ExecutedSqlsBody}>
          <AppTabs
            tabs={tabs}
            selected={selectedTab}
            onChange={onTabClick}
            className={classes.Tabs}
          />
          <div className={classes.ExecutionContent}>
            {currentExecutedSql?.description && (
              <div className={classes.Description}>
                <DatabaseIcon className={classes.Icon} />
                <span>{currentExecutedSql?.description}</span>
              </div>
            )}
            <div className={classes.Row}>
              <div className={classes.Sql}>
                <SqlInput value={currentExecutedSql?.statement} disabled copyButton />
              </div>
              {currentExecutedSql?.views && currentExecutedSql.views.length > 0 && (
                <div className={classes.ViewsInSql}>
                  <span className={classes.SubHeading}>
                    {t(TransKeys.ANALYSIS_EXECUTED_SQLS_PANEL.VIEWS_IN_SQL_HEADING)}
                  </span>
                  {currentExecutedSql.views.map(v => (
                    <div
                      className={classes.ViewItem}
                      key={`${v.name}_${v.id}`}
                      onClick={() => showTable(v.id)}
                    >
                      <TableListLightIcon />
                      <div className={classes.NameContent}>
                        <div className={classes.Name}>{v.name}</div>
                        <div className={classes.ArrowUpRightIcon}>
                          <ArrowUpRightLightIcon />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </ModalLayout>
    </div>
  );
};

const AnalysisExecutedSqlsPanel = composition<AllProps>(
  AnalysisExecutedSqlsPanelComponent,
  withLoadBefore({
    executedSqls: {
      selectedKey: SELECT_ANALYSIS_QUERIES_KEY,
      actionKey: SELECT_ANALYSIS_QUERIES_KEY,
      request: getAnalysisResultExecutedSqlNetworkRequest,
      mapPayloadFromProps: props => props[ANALYSIS_RESULT_ID_PATH_PARAM],
      shouldCall: props => props[ANALYSIS_RESULT_ID_PATH_PARAM] !== undefined,
    },
  })
);

export default AnalysisExecutedSqlsPanel;

import * as React from 'react';
import classNames from 'classnames';
import classes from './segment-filter-selector.module.scss';
import {useMemo} from 'react';
import {values} from 'lodash';
import {ParameterInputWrapper} from '../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {
  QueryBuilder,
  ExtendedSqlElementType,
  SqlElementType,
  SqlElementModel,
  DropdownButton,
  DialogLayout,
  PopoverWrapper,
} from 'ui-components';
import {queryBuilderModelConfig} from '../../../../../../constants/query-builder';
import {searchSegmentsNetworkRequest} from '../../../../../../http/segments.network-requests';
import {TableEntity, TableEntityBinding} from '../../../../../../objects/models/table.model';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../../../constants/translation-keys';
import {hasErrors} from '../../../../../../utils/general.utils';
import {createSegmentCondition} from '../../../../../shared/core/query-builders/population-segment-query-builder/population-segment-query-builder.component';
import {
  PopulationSegmentQueryBuilder,
  BUILDER_COMPONENT_NAME,
} from '../../../../../shared/core/query-builders/population-segment-query-builder/population-segment-query-builder.component';
import {
  PARAMETERS_METADATA_KEY,
  METADATA_KEY,
} from '../../../../../../constants/parameters-saved-keys';
import {AnalysisSelectorProps, AnalysisSelectorVariant} from '../analysis-selector.types';
import {exists} from 'front-core';
import {BuilderWrapper} from '../../../../../shared/components/general/builder-wrapper/builder-wrapper.component';

interface SchemaKeysMapping {
  population_filter: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  population_filter: 'population_filter',
};

interface OwnProps extends AnalysisSelectorProps {
  schemaKeysMapping?: SchemaKeysMapping;
  entityContext?: string;
  bottomHelper?: any;
  label?: string;
  className?: string;
}

type AllProps = OwnProps;

const SEGMENT_PLACEHOLDER = createSegmentCondition();

const LegacyQueryBuilder = ({query, onChange, errors, entityContext}) => {
  const modelConfig = useMemo(
    () => ({
      ...queryBuilderModelConfig,
      [SqlElementModel.SEGMENT]: {
        networkRequest: searchSegmentsNetworkRequest,
        labelAttributePath: 'name',
        valueAttributePath: 'id',
        requestPayload: {
          signal_filters: {
            entityContext,
            entityBinding: TableEntityBinding.DEFAULT,
          },
          limit: 1000,
        },
      },
    }),
    [entityContext]
  );

  const queryBuilderConfig = useMemo(
    () => ({
      accept: [
        ExtendedSqlElementType.SEGMENT_CONDITION,
        SqlElementType.AND_CONDITION,
        SqlElementType.OR_CONDITION,
        SqlElementType.LITERAL,
      ],
      entry: [ExtendedSqlElementType.SEGMENT_CONDITION],
      modelConfig: modelConfig,
    }),
    [modelConfig]
  );

  return (
    <QueryBuilder
      onChange={onChange}
      query={query}
      errors={errors}
      config={queryBuilderConfig}
      placeholder={SEGMENT_PLACEHOLDER}
    />
  );
};

export const SegmentFilterSelector: React.FC<AllProps> = (props: AllProps) => {
  const {
    title,
    subTitle,
    helperText,
    bottomHelper,
    errors,
    entityContext,
    onChange,
    schemaKeysMapping,
    value,
    variant,
    label,
    className,
  } = props;
  const {t} = useTranslation();

  const hasError = useMemo(
    () => hasErrors(errors, values(schemaKeysMapping)),
    [errors, schemaKeysMapping]
  );

  const QueryBuilderComponent = useMemo(() => {
    const query = value[schemaKeysMapping.population_filter];
    if (!query) {
      return PopulationSegmentQueryBuilder;
    }
    if (
      query[PARAMETERS_METADATA_KEY]?.[METADATA_KEY.BUILDER_COMPONENT_NAME_KEY] ===
      BUILDER_COMPONENT_NAME
    ) {
      return PopulationSegmentQueryBuilder;
    }
    return LegacyQueryBuilder;
  }, [value, schemaKeysMapping]);
  const renderContent = () => {
    return (
      <BuilderWrapper>
        <QueryBuilderComponent
          className={variant === AnalysisSelectorVariant.INLINE ? className : undefined}
          onChange={value => onChange({[schemaKeysMapping.population_filter]: value})}
          query={value[schemaKeysMapping.population_filter]}
          errors={errors ? errors[schemaKeysMapping.population_filter] : {}}
          entityContext={entityContext as TableEntity}
        />
      </BuilderWrapper>
    );
  };

  if (variant === AnalysisSelectorVariant.INLINE) {
    return (
      <PopoverWrapper
        buttonRenderer={props => (
          <DropdownButton
            {...props}
            error={exists(errors[schemaKeysMapping.population_filter])}
            label={label || 'all population'}
            capitalize={false}
          />
        )}
        placement={'top'}
      >
        <DialogLayout className={classes.DialogLayout}>{renderContent()}</DialogLayout>
      </PopoverWrapper>
    );
  }

  return (
    <ParameterInputWrapper
      title={title || t(TransKeys.ANALYSIS_FORMS.SHARED.SEGMENT_FILTER.TITLE)}
      subTitle={subTitle || t(TransKeys.ANALYSIS_FORMS.SHARED.SEGMENT_FILTER.SUB_TITLE)}
      helperText={helperText || t(TransKeys.ANALYSIS_FORMS.SHARED.SEGMENT_FILTER.HELPER_TEXT)}
      className={classNames(classes.SegmentFilterSelector, className)}
      bottomHelper={bottomHelper}
      error={hasError}
    >
      {renderContent()}
    </ParameterInputWrapper>
  );
};

SegmentFilterSelector.defaultProps = {
  schemaKeysMapping: DEFAULT_SCHEMA_KEYS_MAPPING,
};

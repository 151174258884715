import {useRemoteSourceStated} from 'ui-components';
import {useEffect, useMemo} from 'react';
import {getAnalysisTypesCatalogNetworkRequest} from '../../../http/analysis-types.network-requests';
import {useIsAdmin} from '../../../core/hooks/use-is-admin.hook';
import {useHiddenFlag} from '../../../core/hooks/use-hidden-flag.hook';

interface UseAnalysisTypesProps {
  accepted?: number[];
  exclude?: number[];
  orderBy?: string;
  order?: 'asc' | 'desc';
  limit?: number;
  withHiddenFromCatalog?: boolean;
}

type Category = {
  id: number;
  name: string;
  displayOrder: number;
  fontAwesomeIcon?: string;
  analyses: any[];
};

const DEFAULT_ORDER_BY = 'displayOrder';
const DEFAULT_ORDER = 'asc';
const DEFAULT_LIMIT = 1000;

export function useAnalysisTypesCatalog(props: UseAnalysisTypesProps) {
  const {accepted, exclude, withHiddenFromCatalog} = props;
  const isAdmin = useIsAdmin();
  const showHiddenAnalyses = useHiddenFlag('showHiddenAnalyses');
  // The network request hook
  const {
    source: analysisTypes,
    exec: getAnalysisTypes,
    isLoading,
  } = useRemoteSourceStated({
    type: 'source',
    initialValue: [],
    networkRequest: getAnalysisTypesCatalogNetworkRequest,
  });

  // Executing the network request
  useEffect(() => {
    const filters = {
      orderBy: props.orderBy || DEFAULT_ORDER_BY,
      order: props.order || DEFAULT_ORDER,
      limit: props.limit || DEFAULT_LIMIT,
      is_hidden_from_catalog: false,
    };
    if (accepted && accepted.length > 0) {
      filters['id'] = accepted;
    }
    if (exclude && exclude.length > 0) {
      filters['exclude_id'] = exclude;
    }
    if (withHiddenFromCatalog || (isAdmin && showHiddenAnalyses)) {
      delete filters['is_hidden_from_catalog'];
    }

    getAnalysisTypes(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAnalysisTypes, isAdmin, showHiddenAnalyses]);

  // Transforming the analysis types into a categories map
  const categories = useMemo(() => {
    const categories: {[index: number]: Category} = {};

    for (const analysisType of analysisTypes) {
      const analysisTypeCategories = analysisType.categories || [];

      for (const analysisTypeCategory of analysisTypeCategories) {
        categories[analysisTypeCategory.id] ||= {
          ...analysisTypeCategory,
          analyses: [],
        };
        categories[analysisTypeCategory.id].analyses.push(analysisType);
      }
    }

    return categories;
  }, [analysisTypes]);

  return {
    analysisTypes,
    isLoading,
    categories,
  };
}

import * as React from 'react';
import classNames from 'classnames';
import {PrimaryTabs} from 'ui-components';
import {useMemo, useState} from 'react';
import classes from './query-sql-tabs.module.scss';
import {exists} from 'front-core';
import QueryDefinition from '../../components/general/query-definition/query-definition.component';
import SignalSql from '../../components/general/signal-sql/signal-sql.component';
import {TableEntity} from '../../../../objects/models/table.model';

export enum QuerySqlTabsNames {
  QUERY_DEFINITION = 'query',
  SQL = 'sql',
}

interface OwnProps {
  signalId?: number;
  query: any;
  entityContext?: TableEntity;
  stickyHeader?: boolean;
  className?: string;
  initialTab?: QuerySqlTabsNames;
}

type AllProps = OwnProps;

export const QuerySqlTabs: React.FC<AllProps> = (props: AllProps) => {
  const {signalId, query, entityContext, stickyHeader, className, initialTab} = props;
  const [selectedTabKey, setSelectedTabKey] = useState<string | QuerySqlTabsNames>(
    initialTab || QuerySqlTabsNames.QUERY_DEFINITION
  );
  const tabs = useMemo(
    () => [
      {
        label: 'Definition',
        key: 'query',
        render: () => (
          <div className={classes.TabWrapper}>
            <QueryDefinition query={query} entityContext={entityContext} />
          </div>
        ),
      },
      {
        label: 'SQL',
        key: 'sql',
        render: () => (
          <div className={classes.TabWrapper}>
            <SignalSql signalId={signalId} />
          </div>
        ),
        hide: !exists(signalId),
      },
    ],
    [query, signalId, entityContext]
  );

  return (
    <div className={classNames(className)}>
      <PrimaryTabs
        selected={selectedTabKey}
        onChange={setSelectedTabKey}
        sticky={stickyHeader}
        tabs={tabs}
      />
    </div>
  );
};

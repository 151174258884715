import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {composition, HttpClientContext} from 'front-core';
import TransKeys from '../../../../constants/translation-keys';
import {Button, ExperimentsMenuIcon, FlaskGearLightIcon} from 'ui-components';
import {ANALYSIS_TYPE_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {ModelKey} from '../../../../constants/model-key';
import {
  getExperimentsNetworkRequest,
  getExperimentCountsNetworkRequest,
} from '../../../../http/experiments.network-requests';
import {experimentToastCreator} from '../../../../store/toasts.actions';
import {useDispatch} from 'react-redux';
import {PanelKey} from '../../../../constants/panels';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {CoreActionsType} from '../../../../store/core/core.actions';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {useRemoteList} from '../../../../core/hooks/use-remote-list.hook';
import ExperimentEmptyState from '../../components/experiment-empty-state/experiment-empty-state.component';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {getAnalysisTypesCatalogNetworkRequest} from '../../../../http/analysis-types.network-requests';
import {AnalysisType} from '../../../../objects/models/analysis-type.model';
import {AnalysisTypeId} from '../../../../constants/analysis-type-id';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import PageLayout from '../../../shared/components/layout/page-layout/index';
import {useFeatureIsOn} from '@growthbook/growthbook-react';
import {FeatureFlag} from '../../../../constants/feature-flags';
import classes from './experiments-main.module.scss';
import {ExperimentsListFilters} from './components/experiments-list-filters/experiments-list-filters.component';
import {ExperimentsList} from './components/experiments-list/experiments-list.component';
import {Pagination} from '../../../shared/components/general/pagination/pagination.component';
import {useTranslation} from 'react-i18next';
import {ExperimentsCounts, ExperimentStatus} from '../../../../objects/models/experiment.model';

type ExperimentsMainComponentProps = {
  analysisTypes?: AnalysisType[];
};

const createListKey = (key: string = 'EXPERIMENT') => `EXPERIMENTS_MAIN/${key}`;

const ExperimentsMainComponent = (props: ExperimentsMainComponentProps) => {
  const {analysisTypes} = props;
  const http = useContext(HttpClientContext);
  const dispatch = useDispatch();
  const {openPrimaryPanel} = useContext(PanelsContext);
  const {t} = useTranslation();
  const {experimentsCount: experimentsCountFromProductData, defaultTeamId} = useProductData();
  const showAutomations = useFeatureIsOn(FeatureFlag.EXPERIMENT_AUTOMATIONS as any);
  const [experimentCounts, setExperimentCounts] = useState<ExperimentsCounts>({
    status: {},
    type: {},
  } as ExperimentsCounts);

  const config = useMemo(
    () => ({
      listKey: createListKey(),
      actionKey: createListKey(),
      request: getExperimentsNetworkRequest,
      onError: err => [experimentToastCreator('GET_ERROR')],
      modelKey: ModelKey.EXPERIMENT,
    }),
    []
  );

  const defaultFilters = useMemo(
    () => ({
      orderBy: 'startDate',
      order: 'desc',
      status: ExperimentStatus.IN_PROGRESS,
      itemsPerPage: 50,
      teamId: defaultTeamId,
    }),
    [defaultTeamId]
  );

  const {isLoading, listsData, onFiltersChange, onRefresh, onPageChange, onSort, filters} =
    useRemoteList({
      defaultFilters,
      config,
      filterKeys: ['q', 'status', 'teamId'],
      syncQueryFilters: true,
    });

  const onCreateExperiment = useCallback(
    (analysisTypeId?: number) =>
      openPrimaryPanel(PanelKey.EXPERIMENT_FORM_PANEL, {
        [ANALYSIS_TYPE_ID_PATH_PARAM]: analysisTypeId,
      }),
    [openPrimaryPanel]
  );

  const onAutomationsClicked = useCallback(
    () => openPrimaryPanel(PanelKey.EXPERIMENT_AUTOMATIONS_PANEL),
    [openPrimaryPanel]
  );

  const handlePageChange = useCallback(
    (newPageNumber: number) => {
      onPageChange(newPageNumber + 1);
    },
    [onPageChange]
  );

  useEffect(() => {
    // load initially or update status counts when changing team

    (async () => {
      const res: ExperimentsCounts = (await http.exec(
        getExperimentCountsNetworkRequest(filters)
      )) as unknown as ExperimentsCounts;

      setExperimentCounts(res);
    })();
  }, [filters, http]);

  useEffect(() => {
    const listener = action => {
      if (action.payload.modelKey === ModelKey.EXPERIMENT) {
        onRefresh();
      }
    };
    dispatch(registerActionListener(CoreActionsType.MODEL_CREATED, listener));
    dispatch(registerActionListener(CoreActionsType.MODEL_UPDATED, listener));
    return () => {
      dispatch(removeActionListener(CoreActionsType.MODEL_CREATED, listener));
      dispatch(removeActionListener(CoreActionsType.MODEL_UPDATED, listener));
    };
  }, [dispatch, onRefresh]);

  const perPage = listsData?.meta?.numPerPage;
  const total = listsData?.meta?.total;
  const page = listsData?.meta?.page - 1;

  if (experimentsCountFromProductData === 0) {
    return (
      <ExperimentEmptyState
        analysisTypes={analysisTypes}
        onSelect={(analysisTypeId: number) => onCreateExperiment(analysisTypeId)}
      />
    );
  }

  return (
    <PageLayout.Layout>
      <PageLayout.Title title={t(TransKeys.EXPERIMENTS.HEADER.TITLE)} icon={ExperimentsMenuIcon} />
      <PageLayout.Actions>
        {showAutomations && (
          <Button
            onClick={() => onAutomationsClicked()}
            size={'large'}
            variant={'outlined'}
            icon={FlaskGearLightIcon}
          >
            {t(TransKeys.EXPERIMENTS.ACTIONS.AUTOMATIONS)}
          </Button>
        )}
        <Button onClick={() => onCreateExperiment()} size={'large'}>
          {t(TransKeys.GENERAL.ACTIONS.CREATE_EXPERIMENT)}
        </Button>
      </PageLayout.Actions>
      <PageLayout.Body>
        <div className={classes.FiltersStickyContainer}>
          <ExperimentsListFilters
            disabled={isLoading}
            filters={filters}
            onChangeFilter={onFiltersChange}
            counts={experimentCounts}
            onSort={onSort}
          />
        </div>
        <ExperimentsList isLoading={isLoading} experiments={listsData?.list ?? []} />
        <Pagination
          total={total}
          isLoading={isLoading}
          perPage={perPage}
          page={page}
          onPageChange={handlePageChange}
        />
      </PageLayout.Body>
    </PageLayout.Layout>
  );
};

const ExperimentsMain = composition<ExperimentsMainComponentProps>(
  ExperimentsMainComponent,
  withLoadBefore<ExperimentsMainComponentProps>({
    analysisTypes: {
      selectedKey: createListKey('ANALYSIS_TYPES'),
      actionKey: createListKey('ANALYSIS_TYPES'),
      request: getAnalysisTypesCatalogNetworkRequest,
      shouldCall: _ => true,
      mapPayloadFromProps: _ => ({
        id: [AnalysisTypeId.RELEASE_IMPACT, AnalysisTypeId.A_B_TEST],
      }),
    },
  })
);

export default ExperimentsMain;

import * as React from 'react';
import classNames from 'classnames';
import classes from './statistical-significance-selector.module.scss';
import {useCallback, useMemo} from 'react';
import {Select} from 'ui-components';
import {ParameterInputWrapper} from '../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {hasErrors} from '../../../../../../utils/general.utils';
import {values} from 'lodash';

interface SchemaKeysMapping {
  statistical_significance: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  statistical_significance: 'statistical_significance',
};

interface OwnProps {
  title: string;
  onChange: (parameters: any) => void;
  value: any;
  schemaKeysMapping?: SchemaKeysMapping;
  subTitle?: string;
  helperText?: string;
  errors?: any;
  className?: string;
}

const OPTIONS = [
  {
    label: '95%',
    value: 0.95,
  },
  {
    label: '90%',
    value: 0.9,
  },
  {
    label: '85%',
    value: 0.85,
  },
  {
    label: '80%',
    value: 0.8,
  },
];

type AllProps = OwnProps;

export const StatisticalSignificanceSelector: React.FC<AllProps> = (props: AllProps) => {
  const {
    title,
    subTitle,
    helperText,
    errors,
    onChange,
    schemaKeysMapping,
    value: data,
    className,
  } = props;
  const hasError = useMemo(
    () => hasErrors(errors, values(schemaKeysMapping)),
    [errors, schemaKeysMapping]
  );
  const value = useMemo(
    () => data[schemaKeysMapping.statistical_significance],
    [data, schemaKeysMapping.statistical_significance]
  );
  const options = useMemo(() => ({options: OPTIONS}), []);
  const onOptionSelected = useCallback(
    (value: string) => onChange({[schemaKeysMapping.statistical_significance]: value}),
    [schemaKeysMapping, onChange]
  );

  return (
    <ParameterInputWrapper
      title={title}
      subTitle={subTitle}
      className={classNames(classes.FeatureInteractionSelector, className)}
      helperText={helperText}
      error={hasError}
    >
      <div className={classes.Row}>
        <Select
          value={value}
          onChange={onOptionSelected as any}
          options={options}
          searchable={false}
          sortValues={false}
          clearable={false}
        />
      </div>
    </ParameterInputWrapper>
  );
};

StatisticalSignificanceSelector.defaultProps = {
  schemaKeysMapping: DEFAULT_SCHEMA_KEYS_MAPPING,
};

import * as React from 'react';
import {useCallback, useMemo, useState} from 'react';
import sharedClasses from '../analysis-types-follow-up-panel/analysis-types-follow-up-panel.module.scss';
import {withLoadBefore} from '../../../../../core/hoc/with-load-before.hoc';
import {getAnalysisResultNetworkRequest} from '../../../../../http/analysis-results.network-requests';
import {getAnalysisTypesNetworkRequest} from '../../../../../http/analysis-types.network-requests';
import {
  ShapesDuotoneIcon,
  AnchorDuotoneIcon,
  Select,
  RetentionAnalysisBucketInvestigationFollowUpParameters,
} from 'ui-components';
import {useAmplitude} from '../../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../../constants/amplitude-event';
import {GenericLoading} from '../../../components/general/generic-loading/generic-loading.component';
import {preventSubmitOnEnter} from '../../../../../utils/general.utils';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useDispatch, useSelector} from 'react-redux';
import {getReducedLoadingStateSelector} from '../../../../../store/store.selectors';
import {ActionKey} from '../../../../../constants/action-key';
import {retentionAnalysisInvestigateBucketFollowUpActionParametersValidator} from '../../../../../objects/dto/follow-up-actions.dto';
import {ANALYSIS_RESULT_ID_PATH_PARAM} from '../../../../../constants/app-routes';
import {AnalysisResult} from '../../../../../objects/models/analysis-result.model';
import {AnalysisType} from '../../../../../objects/models/analysis-type.model';
import {AnalysisTypeId} from '../../../../../constants/analysis-type-id';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {createRetentionAnalysisInvestigateBucketFollowUpActions} from '../../../../../store/follow-up-actions/follow-up-actions.actions';
import AnalysisTypesFollowUpPanel from '../analysis-types-follow-up-panel/analysis-types-follow-up-panel.component';
import AnalysisTypesFollowUpPanelHeader from '../analysis-types-follow-up-panel/components/analysis-types-follow-up-panel-header.component';
import AnalysisTypesFollowUpPanelBody from '../analysis-types-follow-up-panel/components/analysis-types-follow-up-panel-body.component';
import {range} from 'lodash';
import {capitalize, composition} from 'front-core';
import {withModalInactiveSourceHandler} from '../../../../../core/hoc/with-modal-inactive-source-handler.hoc';
import {withDisableDemoProduct} from '../../../../../core/hoc/with-disable-demo-product.hoc';

interface OwnProps {
  parameters: RetentionAnalysisBucketInvestigationFollowUpParameters;
  analysisResult: AnalysisResult;
  analysisTypes: {data: AnalysisType[]};
  onClose?: () => void;
  disabled?: boolean;
}

type AllProps = OwnProps;

const FOLLOW_UP_ANALYSES_TYPES = [AnalysisTypeId.GOAL_DRIVERS, AnalysisTypeId.HABIT_MOMENTS];

const createSelectedKey = (key: string) =>
  `RETENTION_ANALYSIS_BUCKET_INVESTIGATION_FOLLOW_UP_FORM/${key}`;

const RetentionAnalysisBucketInvestigationFollowUpFormPanelComponent: React.FC<AllProps> = (
  props: AllProps
) => {
  const {parameters, analysisResult, analysisTypes, onClose, disabled: disabledFromProps} = props;
  const [followupAnalysesRunning, setFollowupAnalysesRunning] = useState(false);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const notify = useAmplitude();
  const formMethods = useForm({
    defaultValues: {
      bucket: parameters.bucket,
      analysisResultId: analysisResult.id,
      analysisFolderId: analysisResult.analysisFolderId,
      analysisTypeIds: [],
    },
    resolver: yupResolver(retentionAnalysisInvestigateBucketFollowUpActionParametersValidator),
  });
  const {handleSubmit, watch, setValue} = formMethods;
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(
      ActionKey.CREATE_GOAL_DRIVERS_ANALYSIS_IMPROVE_FEATURE_ADOPTION_FOLLOW_UP_ACTIONS
    )(state)
  );
  const followUpAnalysesTypeIds = watch('analysisTypeIds');
  const bucket = watch('bucket');

  const bucketsOptions = useMemo(() => {
    const {bucket_granularity: bucketGranularity} = analysisResult.parameters;
    const {numberOfBuckets} = parameters;
    const bucketPrefix = capitalize(bucketGranularity);
    return {
      options: range(1, numberOfBuckets).map(i => ({label: `${bucketPrefix} ${i}`, value: i})),
    };
  }, [analysisResult, parameters]);

  const disabled = useMemo(
    () => isLoading || followUpAnalysesTypeIds.length === 0 || disabledFromProps,
    [isLoading, followUpAnalysesTypeIds, disabledFromProps]
  );

  const onSubmit = useCallback(
    data => {
      dispatch(
        createRetentionAnalysisInvestigateBucketFollowUpActions(data, () =>
          setFollowupAnalysesRunning(true)
        )
      );
      const amplitudePayload = followUpAnalysesTypeIds.reduce(
        (acc, curr, idx) => ({...acc, [String(idx)]: curr}),
        {}
      );
      notify(
        AmplitudeEvent.RETENTION_ANALYSIS_INVESTIGATE_BUCKET_FOLLOW_UP_ACTIONS_CLICKED,
        amplitudePayload
      );
    },
    [dispatch, setFollowupAnalysesRunning, notify, followUpAnalysesTypeIds]
  );
  const analysisTypeBoxConfig = useMemo(
    () => ({
      [AnalysisTypeId.GOAL_DRIVERS]: {
        description: t(
          TransKeys.RETENTION_ANALYSIS_INVESTIGATE_BUCKET_FOLLOW_UP_PANEL.FOLLOW_UP_ANALYSES
            .ANALYSIS_80.DESCRIPTION
        ),
        icon: <ShapesDuotoneIcon className={sharedClasses.ShapesDuotoneIcon} />,
      },
      [AnalysisTypeId.HABIT_MOMENTS]: {
        description: t(
          TransKeys.RETENTION_ANALYSIS_INVESTIGATE_BUCKET_FOLLOW_UP_PANEL.FOLLOW_UP_ANALYSES
            .ANALYSIS_67.DESCRIPTION,
          {
            bucket: bucketsOptions.options.find(b => b.value === bucket).label,
          }
        ),
        icon: <AnchorDuotoneIcon className={sharedClasses.AnchorDuotoneIcon} />,
      },
    }),
    [t, bucketsOptions, bucket]
  );
  return (
    <FormProvider {...formMethods}>
      <AnalysisTypesFollowUpPanel
        onClose={onClose}
        showSuccessMessage={followupAnalysesRunning}
        disabled={disabled}
        onSubmit={e => {
          e.stopPropagation();
          handleSubmit(onSubmit)(e);
        }}
      >
        {isLoading && <GenericLoading />}
        <form
          onKeyDown={preventSubmitOnEnter}
          onSubmit={e => {
            e.stopPropagation();
            handleSubmit(onSubmit)(e);
          }}
        >
          <AnalysisTypesFollowUpPanelHeader
            onClose={onClose}
            staticTitle={t(TransKeys.RETENTION_ANALYSIS_INVESTIGATE_BUCKET_FOLLOW_UP_PANEL.HEADER)}
            dynamicTitle={
              <Select
                sortValues={false}
                clearable={false}
                value={bucket}
                options={bucketsOptions}
                onChange={v => setValue('bucket', v as number)}
              />
            }
          />
          <AnalysisTypesFollowUpPanelBody
            analysisTypeBoxConfig={analysisTypeBoxConfig}
            analysisTypes={analysisTypes}
            defaultFollowUpAnalysesTypeIds={FOLLOW_UP_ANALYSES_TYPES}
          />
        </form>
      </AnalysisTypesFollowUpPanel>
    </FormProvider>
  );
};

export const RetentionAnalysisInvestigateBucketFollowUpFormPanel = composition<AllProps>(
  RetentionAnalysisBucketInvestigationFollowUpFormPanelComponent,
  withModalInactiveSourceHandler,
  withDisableDemoProduct,
  withLoadBefore<AllProps>({
    analysisResult: {
      selectedKey: createSelectedKey('ANALYSIS_RESULT'),
      actionKey: createSelectedKey('ANALYSIS_RESULT'),
      request: getAnalysisResultNetworkRequest,
      mapPayloadFromProps: props => props[ANALYSIS_RESULT_ID_PATH_PARAM],
      shouldCall: props => props[ANALYSIS_RESULT_ID_PATH_PARAM] !== undefined,
    },
    analysisTypes: {
      selectedKey: createSelectedKey('ANALYSIS_TYPES'),
      actionKey: createSelectedKey('ANALYSIS_TYPES'),
      request: getAnalysisTypesNetworkRequest,
      mapPayloadFromProps: props => ({
        id: FOLLOW_UP_ANALYSES_TYPES,
      }),
    },
  })
);

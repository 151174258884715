import {filter, map} from 'rxjs/operators';
import {Epic, ofType} from 'redux-observable';
import {createRequestEpic} from 'front-core';
import {ActionKey} from '../../constants/action-key';
import HttpClient from '../../services/http-client.service';
import {
  createUserReactionSuccess,
  deleteUserReactionSuccess,
  UserReactionsActionType,
} from './user-reactions.actions';
import {
  createUserReactionNetworkRequest,
  deleteShareUserReactionNetworkRequest,
  deleteUserReactionNetworkRequest,
  shareUserReactionNetworkRequest,
} from '../../http/user-reactions.network-requests';
import {opportunityToastCreator, reactionToastCreator, shareToastCreator} from '../toasts.actions';
import {
  getAppStatusCounters,
  modelCreated,
  modelDeleted,
  modelUpdated,
  notifyEvent,
} from '../core/core.actions';
import {ModelDiscriminatorType, UserReactionType} from '../../objects/models/user-reaction.model';
import {
  modelDiscriminatorTypeToModelKeyMap,
  userReactionTypeToKeyMap,
} from '../../utils/amplitude.utils';
import {ShareResourceType} from '../../objects/models/share.model';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {showToastMessage} from '../interface/interface.actions';
import {ToastType} from '../../objects/system/toast-type.enum';
import i18n from '../../config/i18n.config';
import TransKeys from '../../constants/translation-keys';
import {ModelKey} from '../../constants/model-key';
import {demoProductLimitedActionsFilter} from '../store.utils';

export const createUserReactionEpic = createRequestEpic(
  {
    types: [UserReactionsActionType.CREATE_REACTION],
    actionKey: ActionKey.CREATE_USER_REACTION,
    request: ({type, data}) => createUserReactionNetworkRequest(type, data),
    onSuccess: (res, payload) =>
      [
        payload.type === UserReactionType.VIEW || payload.type === UserReactionType.USEFUL
          ? null
          : reactionToastCreator(payload.data.discriminator, 'UPDATE_SUCCESS'),
        modelUpdated(
          {
            id: payload.data.parentId,
            [userReactionTypeToKeyMap[payload.type]]: res.data.reactionValue,
          },
          modelDiscriminatorTypeToModelKeyMap[payload.data.discriminator]
        ),
        createUserReactionSuccess(payload),
      ].filter(Boolean),
    onError: (err, payload) => [
      payload.type === UserReactionType.USEFUL
        ? showToastMessage(
            i18n.t(TransKeys.TOASTS.MARK_USEFUL_ERROR, {model: payload.data.discriminator}),
            ToastType.ERROR
          )
        : reactionToastCreator(payload.data.discriminator, 'UPDATE_ERROR'),
    ],
  },
  HttpClient
);

export const deleteUserReactionEpic = createRequestEpic(
  {
    types: [UserReactionsActionType.DELETE_REACTION],
    actionKey: ActionKey.DELETE_USER_REACTION,
    request: ({type, data}) => deleteUserReactionNetworkRequest(type, data),
    onSuccess: (res, payload) => [
      reactionToastCreator(payload.data.discriminator, 'UPDATE_SUCCESS'),
      modelUpdated(
        {
          id: payload.data.parentId,
          [userReactionTypeToKeyMap[payload.type]]: res.data.reactionValue,
        },
        modelDiscriminatorTypeToModelKeyMap[payload.data.discriminator]
      ),
      deleteUserReactionSuccess(payload),
    ],
    onError: err => [opportunityToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);

export const getAppStatusOnReactionEpic: Epic = action$ =>
  action$.pipe(
    ofType(
      UserReactionsActionType.CREATE_REACTION_SUCCESS,
      UserReactionsActionType.DELETE_REACTION_SUCCESS
    ),
    filter(
      ({payload}) =>
        (payload.type === UserReactionType.VIEW || payload.type === UserReactionType.DISMISS) &&
        payload.data.discriminator === ModelDiscriminatorType.ANALYSIS_RESULT
    ),
    map(() => getAppStatusCounters())
  );

const shareResourceHooksMap = () => ({
  [ShareResourceType.OPPORTUNITY]: {
    toasts: {
      success: i18n.t(TransKeys.TOASTS.OPPORTUNITY_SHARED_SUCCESSFULLY),
      error: i18n.t(TransKeys.TOASTS.OPPORTUNITY_SHARED_ERROR),
    },
  },
  [ShareResourceType.ANALYSIS_RESULT]: {
    toasts: {
      success: i18n.t(TransKeys.TOASTS.ANALYSIS_RESULTS_SHARED_SUCCESSFULLY),
      error: i18n.t(TransKeys.TOASTS.ANALYSIS_RESULTS_SHARED_ERROR),
    },
  },
  [ShareResourceType.ANALYSIS_FOLDER]: {
    toasts: {
      success: i18n.t(TransKeys.TOASTS.ANALYSIS_FOLDER_SHARED_SUCCESSFULLY),
      error: i18n.t(TransKeys.TOASTS.ANALYSIS_FOLDER_SHARED_ERROR),
    },
  },
  [ShareResourceType.EXPERIMENT]: {
    toasts: {
      success: i18n.t(TransKeys.TOASTS.EXPERIMENT_SHARED_SUCCESSFULLY),
      error: i18n.t(TransKeys.TOASTS.EXPERIMENT_SHARED_ERROR),
    },
  },
});

export const shareResourceEpic = createRequestEpic(
  {
    types: [UserReactionsActionType.SHARE_RESOURCE],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.SHARE_RESOURCE,
    request: data => shareUserReactionNetworkRequest(data),
    onSuccess: (_, payload) => [
      notifyEvent(AmplitudeEvent.RESOURCE_SHARED, {id: payload.modelId, type: payload.type}),
      showToastMessage(shareResourceHooksMap()[payload.type].toasts.success, ToastType.SUCCESS),
      modelCreated(payload, ModelKey.SHARE),
    ],
    onError: (_, payload) => [
      showToastMessage(shareResourceHooksMap()[payload.type].toasts.error, ToastType.ERROR),
    ],
  },
  HttpClient
);

export const deleteShareResourceEpic = createRequestEpic(
  {
    types: [UserReactionsActionType.DELETE_SHARE_RESOURCE],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.DELETE_SHARE_RESOURCE,
    request: data => deleteShareUserReactionNetworkRequest(data),
    onSuccess: (res, payload) => [
      shareToastCreator('DELETE_SUCCESS'),
      modelDeleted(payload, ModelKey.SHARE),
    ],
    onError: err => [shareToastCreator('DELETE_ERROR')],
  },
  HttpClient
);

import {composition} from 'front-core';
import {DMPRouting} from './dmp-routing.component';
import classes from './dmp.module.scss';
import {AppRoutes} from '../../constants/app-routes';
import {useCallback, useContext, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {setIsGrowthMapHidden} from '../../store/interface/interface.actions';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../constants/translation-keys';
import {
  Nav,
  NavLink,
  NavList,
  NavSubtitle,
} from '../shared/components/navigation/left-navigation/left-navigation.component';
import {
  AnnotationDuetoneIcon,
  BullsEyeArrowDuotoneIcon,
  Button,
  ChartPyramidDuotoneIcon,
  Chip,
  ChipVariant,
  DMPMenuIcon,
  FoldersDuotoneIcon,
  HandBackPointUpSolidIcon,
  MapPinDuotoneIcon,
  ShapesDuotoneIcon,
  SquareListDuotoneIcon,
  TableListDuotoneIcon,
  UserGroupDuotoneIcon,
} from 'ui-components';
import {LocationDescriptorObject} from 'history';
import PageLayout from '../shared/components/layout/page-layout';
import {useIsAdmin} from '../../core/hooks/use-is-admin.hook';
import {PanelsContext} from '../../core/contexts/panels.context';
import {PanelKey} from '../../constants/panels';

interface OwnProps {}

type AllProps = OwnProps;

interface DMPNavLinkProps {
  to: LocationDescriptorObject;
  text: string;
  icon: any;
  new?: boolean;
}

function DMPNavLink(props: DMPNavLinkProps) {
  const {to, text, icon: Icon, new: isNew} = props;

  return (
    <NavLink to={to} className={classes.NavLinkContent}>
      <div className={classes.IconWrapper}>
        <Icon className={classes.Icon} />
      </div>
      <span className={classes.LinkText}>{text}</span>
      {isNew && <Chip variant={ChipVariant.EXAMPLE} size={'xsmall'} label={'NEW'} />}
    </NavLink>
  );
}

const DMPComponent: React.FC<AllProps> = (props: AllProps) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {openSecondaryPanel} = useContext(PanelsContext);
  const isAdmin = useIsAdmin();

  useEffect(() => {
    dispatch(setIsGrowthMapHidden(true));
    return () => {
      dispatch(setIsGrowthMapHidden(false));
    };
  }, [dispatch]);

  const onQuickRun = useCallback(
    () => openSecondaryPanel(PanelKey.QUICK_RUN_ANALYSES_PANEL),
    [openSecondaryPanel]
  );

  const onPlayAnomalyGame = useCallback(
    () => openSecondaryPanel(PanelKey.ANOMALY_GAME_PANEL),
    [openSecondaryPanel]
  );

  return (
    <PageLayout.Layout>
      <PageLayout.Title icon={DMPMenuIcon} title={t(TransKeys.DMP.HEADER.TITLE)} />
      {isAdmin && (
        <PageLayout.Actions>
          <Button variant="outlined" onClick={onPlayAnomalyGame}>
            🎲&nbsp;&nbsp;&nbsp;Play Anomaly Game
          </Button>
          <Button onClick={onQuickRun}>Quick run analyses</Button>
        </PageLayout.Actions>
      )}
      <PageLayout.Body noPadding className={classes.DMPModule}>
        <Nav className={classes.Nav}>
          <NavSubtitle className={classes.NavSubtitle}>
            {t(TransKeys.DMP.NAVIGATORS.MODELING)}
          </NavSubtitle>
          <NavList>
            <DMPNavLink
              to={AppRoutes.kpis()}
              text={t(TransKeys.METRICS.HEADER.TITLE)}
              icon={BullsEyeArrowDuotoneIcon}
            />
            <DMPNavLink
              to={AppRoutes.segments()}
              text={t(TransKeys.SEGMENTS.HEADER.TITLE)}
              icon={UserGroupDuotoneIcon}
            />
            <DMPNavLink
              to={AppRoutes.features()}
              text={t(TransKeys.FEATURES.HEADER.TITLE)}
              icon={ShapesDuotoneIcon}
            />
            <DMPNavLink
              to={AppRoutes.contents()}
              text={t(TransKeys.CONTENTS.HEADER.TITLE)}
              icon={SquareListDuotoneIcon}
            />
            <DMPNavLink
              to={AppRoutes.funnels()}
              text={t(TransKeys.FUNNELS.HEADER.TITLE)}
              icon={ChartPyramidDuotoneIcon}
            />
            <DMPNavLink
              to={AppRoutes.eventGroups()}
              text={t(TransKeys.EVENT_GROUPS.HEADER.TITLE)}
              icon={FoldersDuotoneIcon}
            />
            <DMPNavLink
              to={AppRoutes.milestones()}
              text={t(TransKeys.MILESTONES.HEADER.TITLE)}
              icon={MapPinDuotoneIcon}
            />
            <DMPNavLink
              to={AppRoutes.annotations()}
              text={t(TransKeys.ANNOTATIONS.HEADER.TITLE)}
              icon={AnnotationDuetoneIcon}
            />
          </NavList>
          <NavSubtitle className={classes.NavSubtitle}>
            {t(TransKeys.DMP.NAVIGATORS.RAW_DATA)}
          </NavSubtitle>
          <NavList>
            <DMPNavLink
              to={AppRoutes.tableEvents()}
              text={t(TransKeys.TABLE_EVENTS.HEADER.TITLE)}
              icon={HandBackPointUpSolidIcon}
            />
            <DMPNavLink
              to={AppRoutes.views()}
              text={t(TransKeys.TABLES.HEADER.TITLE)}
              icon={TableListDuotoneIcon}
            />
          </NavList>
        </Nav>
        <div className={classes.SubModules}>
          <DMPRouting />
        </div>
      </PageLayout.Body>
    </PageLayout.Layout>
  );
};

const DMP = composition<OwnProps>(DMPComponent);

export default DMP;

import classes from './layout.module.scss';
import classNames from 'classnames';

interface OwnProps {
  title?: string;
  children?: React.ReactNode;
  icon?: any;
  className?: string;
}

type AllProps = OwnProps;

export const Title: React.FC<AllProps> = (props: AllProps) => {
  const {title, children, icon: Icon, className} = props;

  return (
    <div className={classNames(classes.Title, className)}>
      {Icon && <Icon className={classes.Icon} />} {title} {children}
    </div>
  );
};

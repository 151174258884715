import appConfig from './app.config';
import {PendoEvent} from '../constants/pendo-events';
import {captureException} from '@sentry/react';
import {User} from '../objects/models/user.model';

declare global {
  interface Window {
    pendo: any;
  }
}

export const loadPendo = () => {
  if (appConfig.pendoApiKey) {
    const script = document.createElement('script');
    script.defer = true;
    // @ts-ignore
    script.onload = (function (apiKey) {
      (function (p, e, n, d, o) {
        var v, w, x, y, z;
        o = p[d] = p[d] || {};
        o._q = [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad'];
        for (w = 0, x = v.length; w < x; ++w)
          (function (m) {
            o[m] =
              o[m] ||
              function () {
                o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
              };
          })(v[w]);
        y = e.createElement(n);
        y.async = !0;
        y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
        z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
      })(window, document, 'script', 'pendo');
    })(appConfig.pendoApiKey);

    document.getElementsByTagName('head')[0].appendChild(script);
  }
};

export const initPendo = (user: User, product) => {
  if (window.pendo) {
    // @ts-ignore
    window.pendo.initialize({
      visitor: {
        id: String(user.id),
        is_demo_user: user.isDemoUser,
      },
      account: {
        id: String(product.id),
        product_id: product.id,
        product_name: product.name,
        is_demo_product: product.isDemoProduct,
      },
    });
  }
};

export const pendoTrack = (
  eventName: PendoEvent,
  payload: any,
  payloadKeysToAddToName: string[]
) => {
  try {
    if (window.pendo && window.pendo.isReady && window.pendo.isReady()) {
      let event = eventName as string;
      if (payloadKeysToAddToName.length > 0) {
        event += '_';
        payloadKeysToAddToName.forEach(key => {
          event += `_${key}_${payload[key]}`;
        });
      }
      event = event.substring(0, 250);
      return window.pendo.track(event, payload);
    }
  } catch (e) {
    captureException(e);
  }
};

export enum PendoGuides {
  SHARE_ANALYSIS = 'eSBgd5m00XarIF_zJ-wzK2Oz8EI',
  EXAMPLE_GOAL_DRIVERS = 'PnXBJrC7iOMH92-OR852zzwGB10',
}

export const triggerPendoGuide = (id: PendoGuides, showOnce = true) => {
  try {
    if (window.pendo && window.pendo.isReady && window.pendo.isReady()) {
      const {pendo} = window;
      const guide = pendo.findGuideById(id);
      if (!guide) {
        return;
      }
      if (showOnce && guide.guideDismissCount > 0) {
        return;
      }

      return pendo.showGuideById(id);
    }
  } catch (e) {
    captureException(e);
  }
};

import {NRC} from './index';
import {RequestType, toCamelCase, toSnakeCase} from 'front-core';
import {RCAInsightsExecutiveSummeryDTO} from '../objects/dto/insights.dto';

export const getRCAInsightsExecutiveSummeryNetworkRequest: NRC<
  void,
  RCAInsightsExecutiveSummeryDTO
> = (payload: RCAInsightsExecutiveSummeryDTO) => ({
  method: RequestType.GET,
  relativeUrl: `/app/insights/rca-insights`,
  query: payload,
  queryTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

import * as React from 'react';
import {
  MultiLoadResponse,
  SmartSelectorParameters,
  SmartSelectorSource,
  SmartSelector,
  useRemoteSourceStated,
} from 'ui-components';
import {useCallback, useContext, useEffect, useMemo, useRef} from 'react';
import {exists, HttpClientContext} from 'front-core';
import {signalSmartSelectorNetworkRequest} from '../../../../http/smart-selector.network-requests';
import classes from './smart-selector.module.scss';
import {SignalPreview} from './components/previews/signal-preview.component';
import {getSignalNetworkRequest} from '../../../../http/signals.network-requests';
import {isArray, isEmpty, isObject} from 'lodash';
import {SmartSelectorLayout} from './smart-selector-layout.component';
import {getMetricTypeName, getMilestoneTypeName} from '../../../../constants/options';
import {SmartSelectorSharedProps} from './smart-selector.shared';
import {useTeamFilter} from '../../../../core/contexts/team-filter.context';
import {TeamFilterHelper} from './components/helpers/team-filter-helper.component';

export enum SignalSmartSelectorKey {
  METRICS = 'metrics',
  FEATURES = 'features',
  CONTENTS = 'contents',
  FUNNELS = 'funnels',
  MILESTONES = 'milestones',
  SIGNALS = 'signals',
  EVENT_GROUPS = 'event_groups',
  SEGMENTS = 'segments',
}

interface OwnProps extends SmartSelectorSharedProps {
  include?: SignalSmartSelectorKey[];
}

type AllProps = OwnProps;

export const SignalSmartSelector: React.FC<AllProps> = (props: AllProps) => {
  const {
    placeholder,
    value,
    filters: filtersFromProps,
    onChange: onChangeFromProps,
    onSignalInfo,
    className,
    disabled,
    error,
    include,
    clearable,
    fullWidth,
    emptyState,
    withPreview,
  } = props;
  const {
    teamId,
    teamName,
    ignore: ignoreTeamFilter,
    isActive: isActiveTeamFilter,
  } = useTeamFilter();
  const selectorRef = useRef<any>(null);
  const http = useContext(HttpClientContext);
  const {source: signal, exec} = useRemoteSourceStated({
    type: 'source',
    networkRequest: getSignalNetworkRequest,
    initialValue: null,
  });
  const filters = useMemo(() => {
    if (!filtersFromProps) {
      return undefined;
    }
    if (isArray(filtersFromProps) && filtersFromProps.length) {
      return filtersFromProps;
    } else if (isObject(filtersFromProps) && !isEmpty(filtersFromProps)) {
      return [filtersFromProps];
    }
    return undefined;
  }, [filtersFromProps]);
  const selectedSignal = useMemo(
    () => (value && signal?.id === value ? signal : null),
    [value, signal]
  );
  const labelValue = useMemo(() => {
    if (value && selectedSignal) {
      return selectedSignal.name;
    }
  }, [value, selectedSignal]);
  const onChange = useCallback(
    (value: number, item?) => {
      selectorRef.current.close();
      onChangeFromProps(value, item);
    },
    [onChangeFromProps]
  );
  const sources: SmartSelectorSource[] = useMemo(
    () =>
      [
        {
          key: SignalSmartSelectorKey.METRICS,
          name: `KPIs`,
          onSelect: item => onChange(item.signalId, item),
          helperText: isActiveTeamFilter ? (
            <TeamFilterHelper teamName={teamName} onIgnore={ignoreTeamFilter} />
          ) : undefined,
          renderItemPreview: item => (
            <SignalPreview
              label={'KPI'}
              data={{
                ...item,
                type: getMetricTypeName(item.type),
              }}
              onInfo={() => onSignalInfo(item.signalId)}
            />
          ),
        },
        {
          key: SignalSmartSelectorKey.SEGMENTS,
          name: `Segments`,
          onSelect: item => onChange(item.signalId, item),
          helperText: isActiveTeamFilter ? (
            <TeamFilterHelper teamName={teamName} onIgnore={ignoreTeamFilter} />
          ) : undefined,
          renderItemPreview: item => (
            <SignalPreview
              label={'Segment'}
              data={item}
              onInfo={() => onSignalInfo(item.signalId)}
            />
          ),
        },
        {
          key: SignalSmartSelectorKey.EVENT_GROUPS,
          name: `Event Groups`,
          onSelect: item => onChange(item.signalId, item),
          renderItemPreview: item => (
            <SignalPreview
              label={'Event Group'}
              data={item}
              onInfo={() => onSignalInfo(item.signalId)}
            />
          ),
        },
        {
          key: SignalSmartSelectorKey.FEATURES,
          name: 'Features',
          childrenPath: 'children',
          onSelect: item => onChange(item.id, item),
          renderItemPreview: item => (
            <SignalPreview
              label={`Feature ${item.overrideName?.toLowerCase()}`}
              data={item}
              onInfo={() => onSignalInfo(item.id)}
            />
          ),
        },
        {
          key: SignalSmartSelectorKey.CONTENTS,
          name: 'Contents',
          childrenPath: 'children',
          onSelect: item => onChange(item.id, item),
          renderItemPreview: item => (
            <SignalPreview
              label={`Content ${item.overrideName?.toLowerCase()}`}
              data={item}
              onInfo={() => onSignalInfo(item.id)}
            />
          ),
        },
        {
          key: SignalSmartSelectorKey.FUNNELS,
          name: 'Funnels',
          childrenPath: 'steps',
          onSelect: item => onChange(item.id, item),
          renderItemPreview: item => (
            <SignalPreview
              label={`Funnel ${item.chip}`}
              data={item}
              onInfo={() => onSignalInfo(item.id)}
            />
          ),
        },
        {
          key: SignalSmartSelectorKey.MILESTONES,
          name: `Milestones`,
          onSelect: item => onChange(item.signalId, item),
          renderItemPreview: item => (
            <SignalPreview
              label={'Milestone'}
              data={{...item, type: getMilestoneTypeName(item.type)}}
              onInfo={() => onSignalInfo(item.signalId)}
            />
          ),
        },
        {
          key: SignalSmartSelectorKey.SIGNALS,
          name: 'All',
          onSelect: item => onChange(item.id, item),
          renderItemPreview: item => (
            <SignalPreview label={'All'} data={item} onInfo={() => onSignalInfo(item.id)} />
          ),
        },
      ].filter(l => (!include ? true : include.indexOf(l.key) > -1)),
    [onChange, onSignalInfo, include, ignoreTeamFilter, teamName, isActiveTeamFilter]
  );
  const load = useCallback(
    async (keys: string[], parameters: SmartSelectorParameters): Promise<MultiLoadResponse> => {
      // @ts-ignore
      return await http.exec(
        signalSmartSelectorNetworkRequest({
          signalFilters: filters,
          page: parameters.page,
          q: parameters.query,
          limit: include?.length > 1 ? 5 : 10,
          teamId: isActiveTeamFilter ? teamId : undefined,
          keys,
        })
      );
    },
    [http, filters, include, teamId, isActiveTeamFilter]
  );
  useEffect(() => {
    value && signal?.id !== value && exec(value);
  }, [value, exec, signal]);

  return (
    <SmartSelectorLayout
      id={'signal-selector'}
      label={labelValue}
      disabled={disabled}
      error={error}
      clearable={clearable && exists(value)}
      onClear={() => onChange(null)}
      placeholder={placeholder}
      className={className}
      ref={selectorRef}
      fullWidth={fullWidth}
    >
      <SmartSelector
        className={classes.Selector}
        sources={sources}
        load={load}
        emptyState={emptyState}
        withPreview={withPreview}
      />
    </SmartSelectorLayout>
  );
};

SignalSmartSelector.defaultProps = {
  include: null,
};

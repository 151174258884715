/* eslint-disable */
// @ts-nocheck
import appConfig from './app.config';
import {store} from '../store/app.store';
import {notifyEvent} from '../store/core/core.actions';
import {AmplitudeEvent} from '../constants/amplitude-event';

export const Beacon = (...args) => {
  if (window?.Beacon) {
    window.Beacon(...args);
  }
};

function onViewArticle(article: any) {
  store.dispatch(
    notifyEvent(AmplitudeEvent.ARTICLE_VIEWED, {
      article_id: article.id,
    })
  );
}

export const initHelpscout = () => {
  const script = document.createElement('script');
  script.defer = true;
  script.onload = !(function (e, t, n) {
    function a() {
      var e = t.getElementsByTagName('script')[0],
        n = t.createElement('script');
      (n.type = 'text/javascript'),
        (n.async = !0),
        (n.src = 'https://beacon-v2.helpscout.net'),
        e.parentNode.insertBefore(n, e);
    }
    if (
      ((e.Beacon = n =
        function (t, n, a) {
          e.Beacon.readyQueue.push({method: t, options: n, data: a});
        }),
      (n.readyQueue = []),
      'complete' === t.readyState)
    )
      return a();
    e.attachEvent ? e.attachEvent('onload', a) : e.addEventListener('load', a, !1);
  })(window, document, window.Beacon || function () {});
  document.getElementsByTagName('head')[0].appendChild(script);

  if (appConfig.helpScoutApiKey) {
    Beacon('init', appConfig.helpScoutApiKey);
    Beacon('config', {display: {zIndex: '1000000'}});
    Beacon('on', 'article-viewed', onViewArticle);
  }
};

// Support open new article by data-beacon-article-window
document.addEventListener('DOMContentLoaded', () => {
  document.addEventListener('click', e => {
    if (e.target.hasAttribute('data-beacon-article-window')) {
      e.preventDefault();
      const v = e.target.getAttribute('data-beacon-article-window');
      const anchor = e.target.getAttribute('data-beacon-article-window-anchor');
      let url = `https://help.getloops.ai/article/${v}-loops`;
      if (anchor) {
        url += `#${anchor}`;
      }
      window.open(url, '_blank');
      onViewArticle({id: v});
    }
  });
});

import {CalcSeriesMode} from '../../constants/metric-series.consts';
import i18n from '../../config/i18n.config';
import TransKeys from '../../constants/translation-keys';
import {useMemo} from 'react';
import {Beacon} from '../../config/helpscout.config';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {sharedClasses} from '../../modules/shared';
import {exists} from 'front-core';
import {capitalize} from 'lodash';
import {TIME_FORMATS} from '../../constants/time-formats';
import {MonitoredMetric} from '../../objects/models/health-monitor.model';
import {ProductEntities} from '../../objects/models/product-data.model';
import {TableEntity} from '../../objects/models/table.model';
import pluralize from 'pluralize';

const CALC_SERIES_MODE_HELPERS = () => ({
  [CalcSeriesMode.VS_EXPECTED]: {
    helperText: i18n.t(TransKeys.VIEW_MONITORED_METRIC.CALC_SERIES_MODE_HELPERS.VS_EXPECTED),
    beaconId: '29',
  },
});

const WEIGHT_UNIT_TO_ENTITY = {
  USER: TableEntity.USER,
  USERS: TableEntity.USER,
  TEAMS: TableEntity.TEAM,
  LEADS: TableEntity.LEAD,
  CAMPAIGNS: TableEntity.CAMPAIGN,
};

export function useCalcSeriesModeHelpers(mode: CalcSeriesMode) {
  const {t} = useTranslation();

  return useMemo(() => {
    const data = CALC_SERIES_MODE_HELPERS()[mode];
    if (!data) {
      return;
    }
    return (
      <div className={sharedClasses.Helper}>
        {data.helperText}
        <span
          className={sharedClasses.Link}
          onClick={() => Beacon('article', data.beaconId, {type: 'sidebar'})}
        >
          {t(TransKeys.GENERAL.LABELS.LEARN_MORE)}
        </span>
      </div>
    );
  }, [mode, t]);
}

export function useMetricSeriesSamplesToChartData(
  monitoredMetric: MonitoredMetric,
  productEntities: ProductEntities
) {
  return useMemo(() => {
    if (!monitoredMetric) {
      return;
    }
    if (monitoredMetric.samples.length === 0) {
      return;
    }
    const datasets = [];
    let selectedSamples = monitoredMetric.samples.filter(
      s => exists(s.expectedLower) && exists(s.expectedUpper)
    );
    // of all samples has no upper & lower, just render the values
    if (selectedSamples.length === 0) {
      selectedSamples = monitoredMetric.samples;
    }
    const valuesDataset = selectedSamples.map(
      s =>
        ({
          x: s.date,
          y: s.value,
        }) as any
    );
    if (valuesDataset.length === 0) {
      return null;
    }
    valuesDataset[valuesDataset.length - 1].isTrend = true;

    const ciDataset = selectedSamples
      .filter(s => exists(s.expectedLower) && exists(s.expectedUpper))
      .map(s => ({
        x: s.date,
        y: (s.expectedLower + s.expectedUpper) / 2,
        upper: s.expectedUpper,
        lower: s.expectedLower,
        dashed: true,
      }))
      .filter(s => s.y !== null);

    datasets.push({
      id: `${monitoredMetric.id}_values`,
      label: 'Actual',
      data: valuesDataset,
    });
    ciDataset.length > 0 &&
      datasets.push({
        id: `${monitoredMetric.id}_ci`,
        label: 'Expected',
        data: ciDataset,
      });

    if (exists(monitoredMetric.weightUnit)) {
      const weights = selectedSamples.map(s => ({
        x: s.date,
        y: s.weight,
      }));
      let entity = WEIGHT_UNIT_TO_ENTITY[monitoredMetric.weightUnit];
      if (entity) {
        entity = productEntities[entity].name;
      } else {
        entity = monitoredMetric.weightUnit;
      }
      entity = pluralize(entity);
      datasets.push({
        id: `${monitoredMetric.id}_weights`,
        label: capitalize(entity),
        data: weights,
        yAxis: 'secondary',
      });
    }

    return {
      datasets,
      displayedDatasetIds: [`${monitoredMetric.id}_values`, `${monitoredMetric.id}_ci`],
      options: {
        xLabel: capitalize(monitoredMetric.xType),
        yLabelSuffix: monitoredMetric.unit === 'PERCENTAGE' ? '%' : undefined,
        labels: {
          dateFormat: TIME_FORMATS.READABLE_DATE_NO_YEAR,
          type: 'date',
          timeUnit:
            monitoredMetric.cadence !== 'DAY' ? monitoredMetric.cadence?.toLowerCase() : undefined,
        },
        errorBar: ciDataset.length > 0,
        yAxisMaxTicks: 5,
        showIncompleteMark: true,
        colors: ['#8D94AE', '#45ACCC', '#9AB9F3', '#B8ADF9', '#F9ADAD', '#5AD9AC'],
      },
    } as any;
  }, [monitoredMetric, productEntities]);
}

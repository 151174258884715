import yup from '../../config/yup.config';
import {SchemaOf} from 'yup';
import {SchedulingDTO, schedulingDTOValidator} from './scheduling.dto';

export interface ExperimentSubscriptionDTO extends SchedulingDTO {
  experimentId: number;
  subscriberId: number;
}

export const experimentSubscriptionDTOValidator: SchemaOf<ExperimentSubscriptionDTO> = yup
  .object()
  .shape({
    experimentId: yup.number().required(),
    subscriberId: yup.number().required(),
    ...schedulingDTOValidator,
  });

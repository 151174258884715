import {StoreDefinition, StorePropertyType} from 'front-core';
import {AuthActionType} from './auth.actions';
import {logoutRequestReducer, updateToken} from './auth.reducers';

export const AUTH_STORE_KEY = 'AUTH_STORE';

export const authStore: StoreDefinition = {
  key: AUTH_STORE_KEY,
  properties: {
    status: {
      propertyType: StorePropertyType.STRING,
      defaultValue: undefined,
    },
    mfaTokenId: {
      propertyType: StorePropertyType.NUMBER,
      defaultValue: undefined,
    },
    user: {
      propertyType: StorePropertyType.OBJECT,
      defaultValue: undefined,
    },
  },
  reducer: {
    [AuthActionType.UPDATE_TOKEN]: updateToken,
    [AuthActionType.LOGOUT_REQUEST]: logoutRequestReducer,
  },
};

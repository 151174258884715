import {AnalysisTypeId} from '../../../../constants/analysis-type-id';
import {
  analysis101ParametersTransformer,
  analysis101ParametersValidator,
} from './analysis-101/analysis-101.config';
import {
  analysis80ParametersTransformer,
  analysis80ParametersValidator,
} from './analysis-80/analysis-80.config';
import {
  analysis69ParametersTransformer,
  analysis69ParametersValidator,
} from './analysis-69/analysis-69.config';
import {
  analysis67ParametersTransformer,
  analysis67ParametersValidator,
} from './analysis-67/analysis-67.config';
import {
  analysis102ParametersTransformer,
  analysis102ParametersValidator,
} from './analysis-102/analysis-102.config';
import {
  analysis107ParametersTransformer,
  analysis107ParametersValidator,
} from './analysis-107/analysis-107.config';
import {
  analysis109ParametersTransformer,
  analysis109ParametersValidator,
} from './analysis-109/analysis-109.config';
import {
  analysis91ParametersTransformer,
  analysis91ParametersValidator,
} from './analysis-91/analysis-91.config';
import {
  analysis59ParametersTransformer,
  analysis59ParametersValidator,
} from './analysis-59/analysis-59.config';
import {
  analysis96ParametersTransformer,
  analysis96ParametersValidator,
} from './analysis-96/analysis-96.config';
import {
  analysis122ParametersTransformer,
  analysis122ParametersValidator,
} from './analysis-122/analysis-122.config';
import {
  analysis138ParametersTransformer,
  analysis138ParametersValidator,
} from './analysis-138/analysis-138.config';
import {
  analysis104ParametersTransformer,
  analysis104ParametersValidator,
} from './analysis-104/analysis-104.config';
import {
  analysis139ParametersTransformer,
  analysis139ParametersValidator,
} from './analysis-139/analysis-139.config';
import {
  analysis142ParametersTransformer,
  analysis142ParametersValidator,
} from './analysis-142/analysis-142.config';
import {
  analysis49ParametersTransformer,
  analysis49ParametersValidator,
} from './analysis-49/analysis-49.config';
import {
  analysis89ParametersTransformer,
  analysis89ParametersValidator,
} from './analysis-89/analysis-89.config';
import {Analysis80Form} from './analysis-80/analysis-80-form.component';
import {Analysis67Form} from './analysis-67/analysis-67-form.component';
import {Analysis101Form} from './analysis-101/analysis-101-form.component';
import {Analysis69Form} from './analysis-69/analysis-69-form.component';
import {Analysis102Form} from './analysis-102/analysis-102-form.component';
import {Analysis91Form} from './analysis-91/analysis-91-form.component';
import {Analysis109Form} from './analysis-109/analysis-109-form.component';
import {Analysis74Form} from './analysis-74/analysis-74-form.component';
import {Analysis89Form} from './analysis-89/analysis-89-form.component';
import {Analysis49Form} from './analysis-49/analysis-49-form.component';
import {Analysis111Form} from './analysis-111/analysis-111-form.component';
import {Analysis107Form} from './analysis-107/analysis-107-form.component';
import {Analysis96Form} from './analysis-96/analysis-96-form.component';
import {Analysis59Form} from './analysis-59/analysis-59-form.component';
import {Analysis122Form} from './analysis-122/analysis-122-form.component';
import {Analysis138Form} from './analysis-138/analysis-138-form.component';
import {Analysis104Form} from './analysis-104/analysis-104-form.component';
import {Analysis139Form} from './analysis-139/analysis-139-form.component';
import {Analysis142Form} from './analysis-142/analysis-142-form.component';
import {analysis74ParametersValidator} from './analysis-74/analysis-74.config';
import {analysis111ParametersValidator} from './analysis-111/analysis-111.config';

export const ANALYSIS_PARAMETERS_TRANSFORMERS_MAP = {
  [AnalysisTypeId.KPI_ANALYSIS]: analysis101ParametersTransformer,
  [AnalysisTypeId.GOAL_DRIVERS]: analysis80ParametersTransformer,
  [AnalysisTypeId.RETENTION_COHORTS]: analysis69ParametersTransformer,
  [AnalysisTypeId.HABIT_MOMENTS]: analysis67ParametersTransformer,
  [AnalysisTypeId.CORRELATION_DRIVERS]: analysis102ParametersTransformer,
  [AnalysisTypeId.USER_JOURNEY]: analysis107ParametersTransformer,
  [AnalysisTypeId.RELEASE_IMPACT]: analysis109ParametersTransformer,
  [AnalysisTypeId.A_B_TEST]: analysis91ParametersTransformer,
  [AnalysisTypeId.POWER_USERS]: analysis59ParametersTransformer,
  [AnalysisTypeId.FIRST_SESSION]: analysis96ParametersTransformer,
  [AnalysisTypeId.ENGAGEMENT_DRIVERS]: analysis122ParametersTransformer,
  [AnalysisTypeId.EVENTS_DISCOVERY]: analysis138ParametersTransformer,
  [AnalysisTypeId.ERRORS_ANALYSIS]: analysis104ParametersTransformer,
  [AnalysisTypeId.RCA_ANALYSIS]: analysis139ParametersTransformer,
  [AnalysisTypeId.DEVILS_MOMENT]: analysis142ParametersTransformer,
  [AnalysisTypeId.FUNNEL_ANALYSIS]: analysis49ParametersTransformer,
  [AnalysisTypeId.FEATURES_ANALYSIS]: analysis89ParametersTransformer,
};

export const ANALYSIS_FORM_MAP = {
  [AnalysisTypeId.GOAL_DRIVERS]: Analysis80Form,
  [AnalysisTypeId.HABIT_MOMENTS]: Analysis67Form,
  [AnalysisTypeId.KPI_ANALYSIS]: Analysis101Form,
  [AnalysisTypeId.RETENTION_COHORTS]: Analysis69Form,
  [AnalysisTypeId.CORRELATION_DRIVERS]: Analysis102Form,
  [AnalysisTypeId.A_B_TEST]: Analysis91Form,
  [AnalysisTypeId.RELEASE_IMPACT]: Analysis109Form,
  [AnalysisTypeId.FEATURE_FUNNEL]: Analysis74Form,
  [AnalysisTypeId.FEATURES_ANALYSIS]: Analysis89Form,
  [AnalysisTypeId.FUNNEL_ANALYSIS]: Analysis49Form,
  [AnalysisTypeId.CONTENT_FUNNEL]: Analysis111Form,
  [AnalysisTypeId.USER_JOURNEY]: Analysis107Form,
  [AnalysisTypeId.FIRST_SESSION]: Analysis96Form,
  [AnalysisTypeId.POWER_USERS]: Analysis59Form,
  [AnalysisTypeId.ENGAGEMENT_DRIVERS]: Analysis122Form,
  [AnalysisTypeId.EVENTS_DISCOVERY]: Analysis138Form,
  [AnalysisTypeId.ERRORS_ANALYSIS]: Analysis104Form,
  [AnalysisTypeId.RCA_ANALYSIS]: Analysis139Form,
  [AnalysisTypeId.DEVILS_MOMENT]: Analysis142Form,
};

export const ANALYSIS_VALIDATOR_MAP = {
  [AnalysisTypeId.GOAL_DRIVERS]: analysis80ParametersValidator,
  [AnalysisTypeId.HABIT_MOMENTS]: analysis67ParametersValidator,
  [AnalysisTypeId.KPI_ANALYSIS]: analysis101ParametersValidator,
  [AnalysisTypeId.RETENTION_COHORTS]: analysis69ParametersValidator,
  [AnalysisTypeId.CORRELATION_DRIVERS]: analysis102ParametersValidator,
  [AnalysisTypeId.A_B_TEST]: analysis91ParametersValidator,
  [AnalysisTypeId.RELEASE_IMPACT]: analysis109ParametersValidator,
  [AnalysisTypeId.FEATURE_FUNNEL]: analysis74ParametersValidator,
  [AnalysisTypeId.FEATURES_ANALYSIS]: analysis89ParametersValidator,
  [AnalysisTypeId.FUNNEL_ANALYSIS]: analysis49ParametersValidator,
  [AnalysisTypeId.CONTENT_FUNNEL]: analysis111ParametersValidator,
  [AnalysisTypeId.USER_JOURNEY]: analysis107ParametersValidator,
  [AnalysisTypeId.FIRST_SESSION]: analysis96ParametersValidator,
  [AnalysisTypeId.POWER_USERS]: analysis59ParametersValidator,
  [AnalysisTypeId.ENGAGEMENT_DRIVERS]: analysis122ParametersValidator,
  [AnalysisTypeId.EVENTS_DISCOVERY]: analysis138ParametersValidator,
  [AnalysisTypeId.ERRORS_ANALYSIS]: analysis104ParametersValidator,
  [AnalysisTypeId.RCA_ANALYSIS]: analysis139ParametersValidator,
  [AnalysisTypeId.DEVILS_MOMENT]: analysis142ParametersValidator,
};

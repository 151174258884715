import * as React from 'react';
import classNames from 'classnames';
import {min, isNumber, get} from 'lodash';
import classes from './table.module.scss';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {StandardCheckBox} from '../standard-check-box/standard-check-box.component';
import {ArrowDownIcon, ChevronDownRegularIcon} from 'ui-components';
import {EmptyState} from '../override';
import {FlexHorizontal} from '../../layout/flex-layout/general-flex-layouts.component.';
import {GenericLoading} from '../generic-loading/generic-loading.component';
import {Tooltip} from '@material-ui/core';

function createComparator(orderBy?: string) {
  return (a, b) => {
    const a_ = get(a, orderBy, a);
    const b_ = get(b, orderBy, b);
    if (isNumber(a)) {
      return a_ - b_;
    }
    return a_?.localeCompare(b_);
  };
}

function sortCompare(order: 'asc' | 'desc', orderBy?: string, comparator?: (a, b) => number) {
  const comparator_ = comparator || createComparator(orderBy);
  return (a, b) => comparator_(a, b) * (order === 'asc' ? 1 : -1);
}

export const SimpleTableClasses = classes;

export interface TableColumn {
  title: string;
  key: string;
  render?: (item: any) => JSX.Element | string | number;
  sortable?: boolean;
  comparator?: (a, b) => number;
  width?: string;
  align?: string;
  hidden?: boolean;
  showOnHover?: boolean;
  helperText?: any;
}

export interface SimpleTableProps {
  columns: TableColumn[];
  data: any[];
  dataKey: string;
  tableLayoutFixed?: boolean;
  // Sortable
  onSort?: (e, key: string) => void;
  order?: 'asc' | 'desc';
  orderBy?: string;
  // pagination
  pagination?: boolean;
  paginationMode?: 'pages' | 'load-more';
  onPageChange?: (e, page: number) => void;
  total?: number;
  perPage?: number;
  page?: number;
  className?: string;
  thClassName?: string;
  emptyStateProps?: any;
  emptyStateRow?: any;
  allowSelection?: boolean;
  selectedKeys?: AcceptedSelectionKey[];
  onSelectedKeysChange?: (selection: AcceptedSelectionKey[]) => void;
  onRowClicked?: (item: any) => void;
  selectedItemKey?: string | number;
  isLoading?: boolean;
  scrollable?: boolean;
  zebra?: boolean;
  rowClassName?: string | ((item: any) => string);
  contentWrapper?: (item: any, children: any) => any;
}

export const SimpleTable: React.FC<SimpleTableProps> = (props: SimpleTableProps) => {
  const {
    data,
    dataKey,
    tableLayoutFixed,
    columns: columnsFromProps,
    onSort,
    orderBy,
    order,
    total,
    page,
    perPage,
    onPageChange,
    onRowClicked,
    pagination,
    className,
    thClassName,
    paginationMode,
    emptyStateProps,
    emptyStateRow,
    selectedKeys,
    allowSelection,
    selectedItemKey,
    onSelectedKeysChange,
    isLoading,
    scrollable,
    zebra,
    rowClassName,
    contentWrapper,
  } = props;
  const prevDisabled = pagination ? page <= 0 : true;
  const nextDisabled = pagination ? page + 1 >= total / perPage : true;
  const selectedKeysSet = useMemo(() => new Set(selectedKeys), [selectedKeys]);
  const onSelectionChange = useCallback(
    (key: AcceptedSelectionKey) => {
      if (selectedKeysSet.has(key)) {
        return onSelectedKeysChange(selectedKeys.filter(i => i !== key));
      }
      return onSelectedKeysChange([key, ...selectedKeys]);
    },
    [onSelectedKeysChange, selectedKeys, selectedKeysSet]
  );
  const columns = useMemo(() => {
    const res = columnsFromProps.filter(c => !c.hidden);
    if (allowSelection) {
      res.unshift({
        title: '',
        key: 'selection',
        sortable: false,
        width: '4.8rem',
        render: item => (
          <FlexHorizontal fullWidth horizontalAlignCenter>
            <StandardCheckBox
              checked={selectedKeysSet.has(item[dataKey])}
              onChange={() => onSelectionChange(item[dataKey])}
            />
          </FlexHorizontal>
        ),
      });
    }
    return res;
  }, [allowSelection, columnsFromProps, selectedKeysSet, dataKey, onSelectionChange]);

  return (
    <div
      className={classNames(
        classes.TableContainer,
        scrollable && classes.Scrollable,
        pagination && paginationMode === 'pages' && classes.Pagination,
        tableLayoutFixed && classes.TableLayoutFixed,
        className
      )}
    >
      {isLoading && <GenericLoading />}
      <table className={classes.Table}>
        <thead className={classes.TableHead}>
          <tr className={classes.TableRow}>
            {columns.map(c => (
              <th
                className={classNames(
                  classes.TableColumn,
                  c.sortable && onSort && classes.Sortable,
                  c.key === orderBy && classes.Sorted,
                  thClassName
                )}
                style={c.width ? {minWidth: c.width, width: c.width} : undefined}
                onClick={e => c.sortable && onSort && onSort(e, c.key)}
                key={c.key}
              >
                <div className={classes.TableColumnHeader}>
                  <Tooltip title={c.helperText ? c.helperText : ''} placement={'top'}>
                    <span className={classes.Title}>{c.title}</span>
                  </Tooltip>
                  {c.sortable && c.key === orderBy && (
                    <div
                      className={classNames(classes.SortIndicator, order === 'asc' && classes.Flip)}
                    >
                      <ArrowDownIcon className={classes.SortIcon} />
                    </div>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>

        <tbody className={classes.TableBody}>
          {data.map((item, index) => (
            <tr
              key={`row_${item[dataKey]}_${index}`}
              onClick={onRowClicked ? () => onRowClicked(item) : undefined}
              className={classNames(
                classes.TableRow,
                selectedItemKey && selectedItemKey === item[dataKey] && classes.Selected,
                zebra && classes.Zebra,
                rowClassName
                  ? typeof rowClassName === 'function'
                    ? rowClassName(item)
                    : rowClassName
                  : undefined,
                onRowClicked && classes.Clickable
              )}
            >
              {columns.map(c => (
                <td
                  key={`row_${item[dataKey]}_column_${c.key}`}
                  className={classNames(classes.TableColumn)}
                >
                  {contentWrapper(
                    item,
                    <div
                      className={classNames(
                        classes.ContentWrapper,
                        c.align === 'right' && classes.AlignRight,
                        c.showOnHover && classes.ShowOnHover
                      )}
                    >
                      {c.render(item)}
                    </div>
                  )}
                </td>
              ))}
            </tr>
          ))}
          {total === 0 && emptyStateRow && (
            <tr key={'empty-state'} className={classes.EmptyStateRow}>
              <td colSpan={columns.length} className={classes.EmptyStateCell}>
                {emptyStateRow}
              </td>
            </tr>
          )}
          {pagination && total > 0 && total > perPage && paginationMode === 'pages' && (
            <tr
              key={'pagination'}
              className={classNames(classes.PaginationRow, isLoading && classes.Loading)}
            >
              <td colSpan={50} className={classes.PaginationCell}>
                <div className={classes.Pagination}>
                  <div className={classes.Status}>{`${1 + page * perPage}-${min([
                    (page + 1) * perPage,
                    total,
                  ])} of ${total}`}</div>
                  <div className={classes.Navigation}>
                    <span
                      onClick={e => !isLoading && !prevDisabled && onPageChange(e, page - 1)}
                      className={classNames(classes.Button, prevDisabled && classes.Disabled)}
                    >
                      {'<'}
                    </span>
                    <span
                      onClick={e => !isLoading && !nextDisabled && onPageChange(e, page + 1)}
                      className={classNames(classes.Button, nextDisabled && classes.Disabled)}
                    >
                      {'>'}
                    </span>
                  </div>
                </div>
              </td>
            </tr>
          )}
          {pagination && paginationMode === 'load-more' && !nextDisabled && (
            <tr key={'load-more'} className={classes.LoadMoreRow}>
              <td colSpan={50} className={classes.LoadMoreCell}>
                <div
                  className={classNames(classes.LoadMoreWrapper, isLoading && classes.Loading)}
                  onClick={isLoading ? undefined : e => onPageChange(e, page + 1)}
                >
                  {!isLoading && (
                    <div className={classes.LoadMoreButton}>
                      Load more ({data.length} / {total} displayed)
                      <ChevronDownRegularIcon className={classes.LoadMoreArrow} />
                    </div>
                  )}
                  {isLoading && <span className={classes.LoadingText}>Loading...</span>}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className={classes.EmptyStateWrapper}>
        {data.length === 0 && !isLoading && emptyStateProps && <EmptyState {...emptyStateProps} />}
      </div>
    </div>
  );
};

SimpleTable.defaultProps = {
  perPage: 10,
  scrollable: true,
  contentWrapper: (item, children) => children,
};

export type AcceptedSelectionKey = number | string;

interface AdvancedTableProps extends SimpleTableProps {}

export const AdvancedTable: React.FC<AdvancedTableProps> = (props: AdvancedTableProps) => {
  const {data: initialData, perPage, pagination, columns} = props;
  const [sorting, setSort] = useState({order: undefined, orderBy: undefined});
  // holds local data for example sorted data
  const [localData, setLocalData] = useState([...initialData]);
  // holds the data that the table currently shows, for example after each pagination
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  // Handles a page change
  const refreshTableData = useCallback(
    (page: number = 0) => {
      if (pagination) {
        const from = page * perPage;
        const to = from + perPage;
        setTableData(localData.slice(from, to));
        setPage(page);
      } else {
        setTableData(localData);
      }
    },
    [localData, pagination, perPage]
  );

  const onSort = (key: string) => {
    let order = sorting.order;
    let orderBy = sorting.orderBy;

    if (orderBy === key) {
      switch (order) {
        case 'asc':
          order = 'desc';
          break;
        case 'desc':
          order = undefined;
          orderBy = undefined;
      }
    } else {
      orderBy = key;
      order = 'asc';
    }

    setSort({
      order: order,
      orderBy: orderBy,
    });
  };

  useEffect(() => {
    setLocalData([...initialData]);
  }, [initialData]);
  // Refresh the table data when the Local data is changed (sorting, pagination, etc...)
  useEffect(() => {
    refreshTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localData]);
  // Update the local data when sorting is changed
  useEffect(() => {
    let order = sorting.order;
    let orderBy = sorting.orderBy;
    let data = [...initialData];

    const column = columns.find(c => c.key === orderBy);
    if (order && orderBy) {
      const comparator = sortCompare(order, orderBy, get(column, 'comparator'));
      data = data.sort((a, b) => comparator(a, b));
    }

    setLocalData(data);
  }, [columns, initialData, sorting]);

  return (
    <SimpleTable
      {...props}
      data={tableData}
      // sorting
      order={sorting.order}
      orderBy={sorting.orderBy}
      onSort={(e, key) => onSort(key)}
      // pagination
      total={localData.length}
      perPage={perPage}
      page={page}
      onPageChange={(e, page) => refreshTableData(page)}
    />
  );
};

AdvancedTable.defaultProps = {
  selectedKeys: [],
  perPage: 10,
  paginationMode: 'pages',
};

import * as React from 'react';
import {SelectedConfig} from '../../store/selected/selected.types';
import {useEffect, useMemo} from 'react';
import {createSelected, getSelected, removeSelected} from '../../store/selected/selected.actions';
import {useDispatch, useSelector} from 'react-redux';
import {every, keys, values} from 'lodash';
import {getErrorSelector, getSelectedSelector} from '../../store/store.selectors';
import {GenericLoading} from '../../modules/shared/components/general/generic-loading/generic-loading.component';

export interface LoadBeforeConfig<P> extends SelectedConfig {
  mapPayloadFromProps?: (props: P) => any;
  shouldCall?: (props: P) => boolean;
}

const DEFAULT_CONFIG_SETTINGS: any = {
  shouldCall: props => true,
};

export interface WithLoadBeforeConfig<P = {}> {
  [propKey: string]: LoadBeforeConfig<P>;
}

// This HOC is used to load data into panels when assuming to change in the props
export function withLoadBefore<P>(
  configFromProps: WithLoadBeforeConfig<P>,
  loadingComponent?: React.ComponentType
): any {
  // We're injecting a default config to each WithLoadBeforeConfig in order to
  // avoid changing it all the places in the code base
  let config: WithLoadBeforeConfig<P> = {};
  for (const propKey of keys(configFromProps)) {
    config[propKey] = {
      ...DEFAULT_CONFIG_SETTINGS,
      ...configFromProps[propKey],
    };
  }
  return function WithLoadBefore(WrappedComponent: React.ComponentType<P>): React.ComponentType<P> {
    const WithLoadBefore: React.FC<P> = (props: P) => {
      const dispatch = useDispatch();
      const selectedKeys = useMemo(() => values(config).map(c => c.selectedKey), []);
      const LoadingComponent = useMemo(() => loadingComponent || GenericLoading, []);
      /**
       * Creating selected store
       */
      useEffect(() => {
        for (const propKey of keys(config)) {
          const selectedConfig = config[propKey];
          dispatch(
            createSelected({
              selectedKey: selectedConfig.selectedKey,
              actionKey: selectedConfig.actionKey,
              request: selectedConfig.request,
            })
          );
        }

        return () => {
          for (const selectedKey of selectedKeys) {
            dispatch(removeSelected(selectedKey));
          }
        };
      }, [dispatch, selectedKeys]);
      /**
       * Get all selected data according to config
       */
      const selectedModels = useSelector(state => getSelectedSelector(...selectedKeys)(state));
      const selectedErrors = useSelector(state => getErrorSelector(...selectedKeys)(state));
      /**
       * Calling initial load
       */
      useEffect(() => {
        for (const propKey of keys(config)) {
          const selectedConfig = config[propKey];
          const shouldCall = selectedConfig.shouldCall(props);
          if (!shouldCall) {
            continue;
          }
          if (selectedConfig.mapPayloadFromProps) {
            const payload = selectedConfig.mapPayloadFromProps(props);
            dispatch(getSelected(selectedConfig.selectedKey, payload));
            continue;
          }
          dispatch(getSelected(selectedConfig.selectedKey));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [dispatch]);
      /**
       * Mapping from propKey => selected model from store
       */
      const extendedProps = useMemo(() => {
        const propsMapping = {};
        const errors = {};
        for (const propKey of keys(config)) {
          const key = config[propKey].selectedKey;
          propsMapping[propKey] = selectedModels[key];
          errors[propKey] = selectedErrors[key];
        }
        propsMapping['errors'] = errors;
        return propsMapping;
      }, [selectedModels, selectedErrors]);
      /**
       * This should render only if all selected models are loaded, or we don't need to wait for them
       */
      const shouldRender = useMemo(
        () =>
          every(
            keys(extendedProps),
            propKey =>
              extendedProps[propKey] !== undefined ||
              extendedProps['errors'][propKey] !== undefined ||
              config[propKey].shouldCall(props) === false
          ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [extendedProps]
      );

      if (shouldRender) {
        return <WrappedComponent {...props} {...extendedProps} />;
      }

      /**
       * If the component is not ready, just render the GenericLoading
       */
      return <LoadingComponent {...props} />;
    };

    const wrappedComponentName =
      WrappedComponent.displayName || (WrappedComponent as any).name || 'Component';
    (WithLoadBefore as any).displayName = `WithLoadBefore(${wrappedComponentName})`;

    return WithLoadBefore as any;
  };
}

import classes from './general-flex-layouts.module.scss';
import classNames from 'classnames';

interface FlexProps {
  children: any;
  className?: string;
  spacing?: boolean;
  verticalAlignCenter?: boolean;
  horizontalAlignCenter?: boolean;
  spaceBetween?: boolean;
  fullWidth?: boolean;
  wrap?: boolean;
}

// todo: should be a single component named FlexLayout - default horizontal, vertical?: boolean as prop
export const FlexVertical = ({
  children,
  className,
  spacing,
  verticalAlignCenter,
  horizontalAlignCenter,
  spaceBetween,
  fullWidth,
  wrap,
}: FlexProps) => (
  <div
    className={classNames(
      classes.FlexVertical,
      spacing && classes.Spacing,
      verticalAlignCenter && classes.VerticalAlignCenter,
      horizontalAlignCenter && classes.HorizontalAlignCenter,
      spaceBetween && classes.SpaceBetween,
      fullWidth && classes.Fullwidth,
      wrap && classes.Wrap,
      className
    )}
  >
    {children}
  </div>
);

export const FlexHorizontal = ({
  children,
  className,
  spacing,
  verticalAlignCenter,
  horizontalAlignCenter,
  spaceBetween,
  fullWidth,
  wrap,
}: FlexProps) => (
  <div
    className={classNames(
      classes.FlexHorizontal,
      spacing && classes.Spacing,
      verticalAlignCenter && classes.VerticalAlignCenter,
      horizontalAlignCenter && classes.HorizontalAlignCenter,
      spaceBetween && classes.SpaceBetween,
      fullWidth && classes.Fullwidth,
      wrap && classes.Wrap,
      className
    )}
  >
    {children}
  </div>
);

export enum AnalysisTypeId {
  RETENTION_DRIVERS = 51, // deprecated
  HABIT_MOMENTS = 67,
  RETENTION_COHORTS = 69,
  GOAL_DRIVERS = 80,
  KPI_ANALYSIS = 101,
  CORRELATION_DRIVERS = 102,
  FEATURE_FUNNEL = 74,
  FEATURES_ANALYSIS = 89,
  FUNNEL_ANALYSIS = 49,
  CONTENT_FUNNEL = 111,
  A_B_TEST = 91,
  RELEASE_IMPACT = 109,
  USER_JOURNEY = 107,
  FIRST_SESSION = 96,
  POWER_USERS = 59,
  USERS_ANALYSIS = 129,
  ENGAGEMENT_DRIVERS = 122,
  EVENTS_DISCOVERY = 138,
  ENGAGEMENT_ANALYSIS = 124,
  ERRORS_ANALYSIS = 104,
  RCA_ANALYSIS = 139,
  DEVILS_MOMENT = 142,
  DATA_VALIDATION = 144,
  REACH_KPI_WORKFLOW = 145,
  CUSTOM_SQL_WORKFLOW = 147,
}

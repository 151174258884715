import {NRC} from './index';
import {RequestType, toSnakeCase} from 'front-core';
import {toCamelCase} from 'front-core';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';
import {FunnelDTO, GetRCAForFunnelByDateDTO} from '../objects/dto/funnel.dto';

export const getFunnelNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/funnels/${id}`,
  responseTransformer: res => toCamelCase(res.data, ['definition']),
});

export const searchFunnelsNetworkRequest: NRC<void, any> = (value: any, payload: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/funnels`,
  query: {q: value, ...payload},
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data.data),
});

export const getFunnelsNetworkRequest: NRC<void, any> = query => ({
  method: RequestType.GET,
  relativeUrl: `/app/funnels`,
  query: query,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data, ['data.*.definition']),
});

export const getFunnelPageNetworkRequest: NRC<void, null> = (funnelId: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/funnels/funnel-page/${funnelId}`,
  responseTransformer: res => toCamelCase(res.data, ['definition']),
});

export const getRCAForFunnelByDate: NRC<void, any> = (data: GetRCAForFunnelByDateDTO) => ({
  method: RequestType.GET,
  relativeUrl: `/app/funnels/funnel-page/${data.funnelId}/rca`,
  query: {date: data.date, granularity: data.granularity},
  queryTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const createFunnelNetworkRequest: NRC<FunnelDTO, void> = (data: FunnelDTO) => ({
  method: RequestType.POST,
  relativeUrl: `/app/funnels`,
  body: data,
  bodyTransformer: data => toSnakeCase(data, ['definition']),
  responseTransformer: res => toCamelCase(res.data, ['definition']),
});

export const editFunnelNetworkRequest: NRC<FunnelDTO, void> = (data: FunnelDTO) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/funnels/${data.id}`,
  body: data,
  bodyTransformer: data => toSnakeCase(data, ['definition']),
  responseTransformer: res => toCamelCase(res.data, ['definition']),
});

export const deleteFunnelNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.DELETE,
  relativeUrl: `/app/funnels/${id}`,
  responseTransformer: toCamelCase,
});

export const publishFunnelNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.POST,
  relativeUrl: `/app/funnels/${id}/publish`,
  responseTransformer: toCamelCase,
});

import i18n, {InitOptions} from 'i18next';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-http-backend';
import {Language} from '../constants/language';
import {keys, set, startCase} from 'lodash';
import HttpClient from '../services/http-client.service';
import {RequestType, sanitize} from 'front-core';
import appConfig from './app.config';
import parse from 'html-react-parser';
import {Fragment} from 'react';
import pluralize from 'pluralize';

function parseTranslations(data) {
  const translations = {};
  for (const k of keys(data)) {
    set(translations, k, data[k]);
  }
  return translations;
}

const getTranslationsNetworkRequest = {
  url: appConfig.translationsUrl,
  method: RequestType.GET,
};

const initOptions: InitOptions = {
  lng: Language.EN,
  load: 'languageOnly',
  fallbackLng: 'en',
  backend: {
    allowMultiLoading: false,
    request: function (options, url, payload, callback) {
      HttpClient.exec(getTranslationsNetworkRequest).then(res =>
        callback(null, {
          data: parseTranslations(res.data),
          status: res.status,
        })
      );
    },
  },
  interpolation: {
    formatSeparator: '|',
    escapeValue: false,
    format: (value: any, format?: string, lng?: string) => {
      if (format === 'bold') {
        return `<span style="font-weight: bold">${value}</span>`;
      }
      if (format === 'lower' && value) {
        return value.toLowerCase();
      }
      if (format === 'upper' && value) {
        return value.toUpperCase();
      }
      if (format === 'title' && value) {
        return startCase(value.toLowerCase());
      }
      if (format === 'plural' && value) {
        return pluralize(value);
      }
      return value || '';
    },
  },
};

i18n.use(Backend).use(initReactI18next).init(initOptions);
// override t function
const originalT = i18n.t;
i18n.t = ((...args) => {
  if (args[0] === undefined) {
    return '';
  }
  // @ts-ignore
  const v: string = originalT.apply(i18n, args);
  if (v === '') {
    return v;
  }
  if (v === args[0] && v.includes('_')) {
    return '';
  }
  const parsed = parse(sanitize(v));
  if (typeof parsed === 'string') {
    return v;
  }
  return <Fragment>{parsed}</Fragment>;
}) as any;

// @ts-ignore
window.t = i18n.t;

export default i18n;

import {Epic} from 'redux-observable';
import {createRequestEpic} from 'front-core';
import {ActionKey} from '../../constants/action-key';
import HttpClient from '../../services/http-client.service';
import {ContentActionType} from './content.actions';
import {modelCreated, modelDeleted, modelUpdated, notifyEvent} from '../core/core.actions';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {
  createContentNetworkRequest,
  deleteContentNetworkRequest,
  editContentNetworkRequest,
  rescanContentNetworkRequest,
} from '../../http/contents.network-requests';
import {Content} from '../../objects/models/content.model';
import {contentToastCreator} from '../toasts.actions';
import {ModelKey} from '../../constants/model-key';
import {
  demoProductLimitedActionsFilter,
  errorExtractor,
  getDMPToastErrorMessage,
} from '../store.utils';
import {showToastMessage} from '../interface/interface.actions';
import {ToastType} from '../../objects/system/toast-type.enum';

export const createContentEpic: Epic = createRequestEpic(
  {
    types: [ContentActionType.CREATE_CONTENT],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.CREATE_CONTENT,
    request: createContentNetworkRequest,
    onSuccess: (content: Content) => [
      notifyEvent(AmplitudeEvent.CONTENT_CREATED, {id: content.id}),
      contentToastCreator('CREATE_SUCCESS'),
      modelCreated(content, ModelKey.CONTENT),
    ],
    onError: err_ => {
      const err = errorExtractor(err_);
      const toastMessage = getDMPToastErrorMessage(err, 'content');
      if (toastMessage) {
        return [
          showToastMessage(toastMessage, ToastType.ERROR),
          notifyEvent(AmplitudeEvent.CREATE_CONTENT_ERROR, err),
        ];
      }
      return [contentToastCreator('CREATE_ERROR')];
    },
  },
  HttpClient
);
export const updateContentEpic: Epic = createRequestEpic(
  {
    types: [ContentActionType.UPDATE_CONTENT],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.UPDATE_CONTENT,
    request: editContentNetworkRequest,
    onSuccess: (content: Content) => [
      notifyEvent(AmplitudeEvent.CONTENT_UPDATED, {id: content.id}),
      contentToastCreator('UPDATE_SUCCESS'),
      modelUpdated(content, ModelKey.CONTENT),
    ],
    onError: err_ => {
      const err = errorExtractor(err_);
      const toastMessage = getDMPToastErrorMessage(err, 'content');
      if (toastMessage) {
        return [
          showToastMessage(toastMessage, ToastType.ERROR),
          notifyEvent(AmplitudeEvent.UPDATE_CONTENT_ERROR, err),
        ];
      }
      return [contentToastCreator('UPDATE_ERROR')];
    },
  },
  HttpClient
);
export const deleteContentEpic: Epic = createRequestEpic(
  {
    types: [ContentActionType.DELETE_CONTENT],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.DELETE_CONTENT,
    request: deleteContentNetworkRequest,
    onSuccess: (res, payload) => [
      notifyEvent(AmplitudeEvent.CONTENT_DELETED, {id: payload}),
      contentToastCreator('DELETE_SUCCESS'),
      modelDeleted(payload, ModelKey.CONTENT),
    ],
    onError: err => [contentToastCreator('DELETE_ERROR')],
  },
  HttpClient
);

export const rescanContentEpic: Epic = createRequestEpic(
  {
    types: [ContentActionType.RESCAN_CONTENT],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.RESCAN_CONTENT,
    request: rescanContentNetworkRequest,
    onSuccess: content => [
      notifyEvent(AmplitudeEvent.CONTENT_RESCANNED, {id: content.id}),
      contentToastCreator('RESCAN_SUCCESS'),
      modelUpdated(content, ModelKey.CONTENT),
    ],
    onError: err => [contentToastCreator('RESCAN_ERROR')],
  },
  HttpClient
);

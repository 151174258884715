import classNames from 'classnames';
import classes from './model-page-header.module.scss';
import {EditIcon, IconButton} from 'ui-components';
import {exists} from 'front-core';
import {useTranslation} from 'react-i18next';
import TransKeys from 'src/constants/translation-keys';

interface OwnProps {
  onEdit?: () => void;
  title: string;
  className?: string;
  subtitleItems: React.ReactNodeArray;
  value: string;
  valueDescription: string;
}

type AllProps = OwnProps;

export const ModelPageHeader = (props: AllProps) => {
  const {valueDescription, subtitleItems, className, title, value, onEdit} = props;

  const {t} = useTranslation();

  return (
    <div className={classNames(classes.ModelPageHeader, className)}>
      <div className={classes.Head}>
        <div className={classes.Left}>
          <div className={classes.TitleWrapper}>
            <div className={classes.Title}>{title}</div>
            {exists(onEdit) && (
              <IconButton
                className={classes.EditButton}
                tooltipText={t(TransKeys.GENERAL.ACTIONS.EDIT)}
                icon={EditIcon}
                border={false}
                size={'normal'}
                onClick={onEdit}
              />
            )}
          </div>
          <div className={classes.Descriptors}>
            {subtitleItems.map(item => item && <div className={classes.Item}>{item}</div>)}
          </div>
        </div>
        <div className={classes.Right}>
          <div className={classes.Value}>{value}</div>
          <div className={classes.Updated}>{valueDescription}</div>
        </div>
      </div>
    </div>
  );
};

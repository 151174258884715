import {EventEmitter} from 'events';
import {AnyAction} from 'redux';
import {isArray} from 'lodash';

class ActionsListenerService {
  private emitter = new EventEmitter();

  registerActionListener(actionKey: string | string[], listener: (action: AnyAction) => void) {
    if (isArray(actionKey)) {
      actionKey.forEach(a => this.emitter.on(a, listener));
      return;
    }
    this.emitter.on(actionKey, listener);
  }

  removeActionListener(actionKey: string | string[], listener: (action: AnyAction) => void) {
    if (isArray(actionKey)) {
      actionKey.forEach(a => this.emitter.removeListener(a, listener));
      return;
    }
    this.emitter.removeListener(actionKey, listener);
  }

  onAction(action: AnyAction) {
    const {type} = action;
    this.emitter.emit(type, action);
  }
}

export default new ActionsListenerService();

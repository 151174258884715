import * as React from 'react';
import {useMemo} from 'react';
import classNames from 'classnames';
import classes from './base-conversion-builder.module.scss';
import {LabelWrapper, LiteralValueType, Select, TextInput} from 'ui-components';
import {SignalSmartSelectorKey} from '../../smart-selector/signal-smart-selector.component';
import {SignalDataType, SignalType} from '../../../../../objects/models/signal.model';
import {TableEntity, TableEntityBinding, TableType} from 'src/objects/models/table.model';
import {limitNumberValue} from '../../../../../utils/general.utils';
import {TemplateItemQueryBuilder} from '../template-item-query-builder/template-item-query-builder.component';
import {SelectorModelType} from '../../smart-selector/advanced-smart-selector.component';
import {TIME_UNIT_PLURAL_OPTIONS} from '../../../../../constants/options';

interface SchemaKeysMapping {
  goal: string;
  ref_date: string;
  goal_bound_unit: string;
  goal_bound_n: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  goal: 'goal',
  ref_date: 'ref_date',
  goal_bound_unit: 'goal_bound_unit',
  goal_bound_n: 'goal_bound_n',
};

interface OwnProps {
  value: any;
  onChange?: (parameters: any) => void;
  schemaKeysMapping?: SchemaKeysMapping;
  entityContext?: TableEntity;
  onSignalInfo?: (value: string | number) => void;
  refDateSignalFilters?: any;
  refDateAllowTypes?: SelectorModelType[];
  goalSignalFilters?: any;
  errors?: any;
  disabled?: any;
  className?: string;
}

type AllProps = OwnProps;

const createGoalFilters = (entityContext = undefined) => [
  {
    type: SignalType.DIMENSION,
    data_type: SignalDataType.TIMESTAMP,
    exclude_tag: ['join_date', 'churn'],
    entity_binding: TableEntityBinding.DEFAULT,
    exclude_templates: '*',
    entityContext,
  },
  {
    type: SignalType.MEASURE,
    data_type: SignalDataType.BOOLEAN,
    entity_binding: TableEntityBinding.TWO_WAY,
    exclude_templates: '*',
    entityContext,
  },
];

const createRefDateFilters = (entityContext = undefined) => [
  {
    type: SignalType.DIMENSION,
    data_type: SignalDataType.TIMESTAMP,
    entity_binding: TableEntityBinding.DEFAULT,
    exclude_templates: '*',
    entityContext,
  },
  {
    type: SignalType.DIMENSION,
    data_type: SignalDataType.TIMESTAMP,
    entity_binding: TableEntityBinding.DEFAULT,
    templates: ['dom_template'],
    entityContext,
  },
];

const createColumnFilters = (entityContext = undefined) => ({
  tableType: TableType.ENTITY_PROPERTIES,
  literalType: LiteralValueType.DATE,
  entity_binding: TableEntityBinding.DEFAULT,
  entityContext,
});

const REF_DATE_ALLOW_TYPES = [SelectorModelType.SIGNAL, SelectorModelType.COLUMN];

const INCLUDE_SELECTOR = [
  SignalSmartSelectorKey.METRICS,
  SignalSmartSelectorKey.FUNNELS,
  SignalSmartSelectorKey.FEATURES,
  SignalSmartSelectorKey.CONTENTS,
  SignalSmartSelectorKey.SIGNALS,
  SignalSmartSelectorKey.MILESTONES,
];

const MIN_COUNT_VALUE = 1;
const MAX_COUNT_VALUE = 181;

export const BaseConversionBuilder: React.FC<AllProps> = (props: AllProps) => {
  const {
    errors,
    onChange,
    onSignalInfo,
    schemaKeysMapping,
    value,
    disabled,
    entityContext,
    className,
    refDateSignalFilters: refDateSignalFiltersFromProps,
    refDateAllowTypes,
    goalSignalFilters: goalSignalFiltersFromProps,
  } = props;
  const goalFilters = useMemo(
    () => goalSignalFiltersFromProps || createGoalFilters(entityContext),
    [entityContext, goalSignalFiltersFromProps]
  );
  const refDateFilters = useMemo(
    () => refDateSignalFiltersFromProps || createRefDateFilters(entityContext),
    [entityContext, refDateSignalFiltersFromProps]
  );
  const columnFilters = useMemo(() => createColumnFilters(entityContext), [entityContext]);
  const eventFilters = useMemo(
    () => ({
      entity_binding: TableEntityBinding.TWO_WAY,
      entityContext,
    }),
    [entityContext]
  );
  const fullWidthSince = useMemo(
    () => value[schemaKeysMapping.ref_date]?.type === 'And',
    [value, schemaKeysMapping]
  );

  return (
    <div className={classNames(classes.BaseConversionBuilder, className)}>
      <div className={classes.Row}>
        <TemplateItemQueryBuilder
          placeholder={'Select conversion goal'}
          onChange={value => onChange({[schemaKeysMapping.goal]: value})}
          query={value[schemaKeysMapping.goal]}
          errors={errors?.[schemaKeysMapping.goal]}
          signalFilters={goalFilters}
          signalsInclude={INCLUDE_SELECTOR}
          columnFilters={columnFilters}
          eventFilters={eventFilters}
          disabled={disabled}
          onSignalInfo={onSignalInfo}
        />
      </div>
      <div className={classes.Row}>
        <LabelWrapper label={`What’s the time to convert?`}>
          <div className={classes.Row}>
            <div className={classes.Item}>within</div>
            <div className={classes.Item}>
              <TextInput
                className={classes.Input}
                value={value?.[schemaKeysMapping.goal_bound_n]}
                onChange={value =>
                  onChange({
                    [schemaKeysMapping.goal_bound_n]: limitNumberValue(
                      value as any,
                      MIN_COUNT_VALUE,
                      MAX_COUNT_VALUE
                    ),
                  })
                }
                disabled={disabled}
                type={'number'}
              />
            </div>
            <div className={classes.Item}>
              <Select
                value={value?.[schemaKeysMapping.goal_bound_unit]}
                onChange={value =>
                  onChange({
                    [schemaKeysMapping.goal_bound_unit]: value,
                  })
                }
                options={TIME_UNIT_PLURAL_OPTIONS}
                searchable={false}
                clearable={false}
                disabled={disabled}
                capitalize={false}
              />
            </div>
            <div className={classes.Item}>since</div>
            <div className={classNames(classes.Item, fullWidthSince && classes.FullWidth)}>
              <TemplateItemQueryBuilder
                onChange={value =>
                  onChange({
                    [schemaKeysMapping.ref_date]: value,
                  })
                }
                query={value[schemaKeysMapping.ref_date]}
                errors={errors?.[schemaKeysMapping.ref_date]}
                signalFilters={refDateFilters}
                columnFilters={columnFilters}
                eventFilters={eventFilters}
                disabled={disabled}
                allowTypes={refDateAllowTypes || REF_DATE_ALLOW_TYPES}
                onSignalInfo={onSignalInfo}
              />
            </div>
          </div>
        </LabelWrapper>
      </div>
    </div>
  );
};

BaseConversionBuilder.defaultProps = {
  schemaKeysMapping: DEFAULT_SCHEMA_KEYS_MAPPING,
};

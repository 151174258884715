import classes from './model-page-header-short-description.module.scss';
import {BookOpenCoverIcon, TooltipIfOverflow} from 'ui-components';
import {exists} from 'front-core';
import classNames from 'classnames';

type ModelPageHeaderShortDescriptionProps = {
  placeholder?: string;
  description?: string;
};

export const ModelPageHeaderShortDescription = (props: ModelPageHeaderShortDescriptionProps) => {
  const {placeholder, description} = props;

  return (
    <div className={classes.ModelPageHeaderShortDescription}>
      <BookOpenCoverIcon />
      <TooltipIfOverflow title={description}>
        <span className={classNames(classes.Text, !exists(description) && classes.Placeholder)}>
          {exists(description) ? description : placeholder}
        </span>
      </TooltipIfOverflow>
    </div>
  );
};

import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import classes from './ai-interaction-analysis-panel.module.scss';
import {FancyHeader, MicrochipAILightIcon, ModalLayout} from 'ui-components';
import {exists, HttpClientContext} from 'front-core';
import {
  askAIChatNetworkRequest,
  deleteAIChartThreadNetworkRequest,
  getAIChartThreadNetworkRequest,
  getAIChartThreadsNetworkRequest,
  sendAIChatMessageFeedbackNetworkRequest,
  updateAIChartThreadNetworkRequest,
} from '../../../../http/ai-chart.network-requests';
import {useCurrentUser} from '../../../../core/hooks/use-user.hook';
import {UserTextInput} from './components/user-text-input/user-text-input.component';
import {ThreadsList} from './components/threads-list/threads-list.component';
import {ChatMessages} from './components/chat-messages/chat-messages.component';
import {
  AICMessageFeedback,
  AIModelType,
  AIConversationRole,
  AIConversationThread,
  RunAnalysisAction,
} from '../../../../objects/models/ai-chat.model';
import moment from 'moment';
import {TIME_FORMATS} from '../../../../constants/time-formats';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {PanelKey} from '../../../../constants/panels';
import {
  ANALYSIS_TYPE_ID_PATH_PARAM,
  FEATURE_ID_PATH_PARAM,
  FUNNEL_ID_PATH_PARAM,
  METRIC_ID_PATH_PARAM,
  SEGMENT_ID_PATH_PARAM,
} from '../../../../constants/app-routes';

interface OwnProps {
  onClose: () => void;
}

type AllProps = OwnProps;
const TEMP_ID = -1;

export const AIInteractionAnalysis: React.FC<AllProps> = (props: AllProps) => {
  const {onClose} = props;
  const http = useContext(HttpClientContext);
  const user = useCurrentUser();
  const {openPrimaryPanel} = useContext(PanelsContext);
  const [message, setMessage] = useState('');
  const [activeThread, setActiveTread_] = useState<AIConversationThread>(null);
  const [isLoadingAnswer, setIsLoadingAnswer] = useState(false);
  const [isLoadingThreads, setIsLoadingThreads] = useState(false);
  const [threads, setThreads] = useState([]);

  const setActiveTread = useCallback(
    thread => {
      setActiveTread_(thread);
      setMessage('');
    },
    [setActiveTread_, setMessage]
  );
  const getTreads = useCallback(async () => {
    setIsLoadingThreads(true);
    const threads = await http.exec(getAIChartThreadsNetworkRequest());
    setThreads(threads as any);
    setIsLoadingThreads(false);
  }, [http, setThreads, setIsLoadingThreads]);
  const onSubmitMessage = useCallback(
    async message => {
      setMessage('');
      setActiveTread(thread => ({
        ...(thread || {}),
        messages: [
          ...(thread?.messages || []),
          {
            id: TEMP_ID,
            role: AIConversationRole.USER,
            content: message,
            createdOn: moment.utc().format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT),
          },
        ],
      }));
      setIsLoadingAnswer(true);
      const res: any = await http.exec(
        askAIChatNetworkRequest({
          aiConversionThreadId: activeThread?.id,
          message,
        })
      );
      setActiveTread(thread => ({
        ...(thread || {}),
        ...res.thread,
        messages: [...thread.messages.filter(m => m.id !== TEMP_ID), ...(res.newMessages as any)],
      }));
      setIsLoadingAnswer(false);
      if (activeThread === null) {
        getTreads();
      } else {
        setThreads(threads => threads.map(t => (t.id === activeThread.id ? res.thread : t)));
      }
    },
    [http, setActiveTread, setIsLoadingAnswer, setThreads, activeThread, getTreads]
  );
  const getThread = useCallback(
    async (threadId: number) => {
      const thread = await http.exec(getAIChartThreadNetworkRequest(threadId));
      setActiveTread(thread as any);
    },
    [setActiveTread, http]
  );
  const startNewSession = useCallback(() => setActiveTread(null), [setActiveTread]);
  const onMessageFeedback = useCallback(
    async (messageId: number, feedback: AICMessageFeedback) => {
      const message: any = await http.exec(
        sendAIChatMessageFeedbackNetworkRequest({
          aiConversationMessageId: messageId,
          feedback,
        })
      );
      setActiveTread(thread => ({
        ...thread,
        messages: thread.messages.map(m => (m.id === messageId ? message : m)),
      }));
    },
    [http, setActiveTread]
  );
  const onRunAnalysisAction = useCallback(
    (action: RunAnalysisAction) => {
      openPrimaryPanel(PanelKey.ANALYSIS_FORM_PANEL, {
        [ANALYSIS_TYPE_ID_PATH_PARAM]: action.analysisTypeId,
        parameters: action.parameters,
      });
    },
    [openPrimaryPanel]
  );
  const onDeleteThread = useCallback(async () => {
    setActiveTread(null);
    await http.exec(deleteAIChartThreadNetworkRequest(activeThread.id));
    setThreads(threads => threads.filter(t => t.id !== activeThread.id));
  }, [setActiveTread, activeThread, setThreads, http]);
  const onEditTitle = useCallback(
    async (title: string) => {
      setActiveTread(thread => ({
        ...thread,
        title,
      }));
      const thread: any = await http.exec(
        updateAIChartThreadNetworkRequest({
          threadId: activeThread.id,
          title,
        })
      );
      setThreads(threads => threads.map(t => (t.id === thread.id ? thread : t)));
    },
    [setActiveTread, setThreads, http, activeThread]
  );
  const panelMapping = useMemo(
    () => ({
      [AIModelType.FEATURE]: {
        panel: PanelKey.VIEW_FEATURE_PANEL,
        param: FEATURE_ID_PATH_PARAM,
      },
      [AIModelType.METRIC]: {
        panel: PanelKey.VIEW_METRIC_PANEL,
        param: METRIC_ID_PATH_PARAM,
      },
      [AIModelType.FUNNEL]: {
        panel: PanelKey.VIEW_FUNNEL_PANEL,
        param: FUNNEL_ID_PATH_PARAM,
      },
      [AIModelType.SEGMENT]: {
        panel: PanelKey.VIEW_SEGMENT_PANEL,
        param: SEGMENT_ID_PATH_PARAM,
      },
    }),
    []
  );
  const onModelReference = useCallback(
    (modelId: number, modelType: AIModelType) => {
      const info = panelMapping[modelType];
      openPrimaryPanel(info.panel, {
        [info.param]: modelId,
      });
    },
    [openPrimaryPanel, panelMapping]
  );
  const inputDisabled = useMemo(
    () => exists(activeThread?.completedOn) || isLoadingAnswer,
    [activeThread, isLoadingAnswer]
  );

  useEffect(() => {
    getTreads();
  }, [getTreads]);

  return (
    <div className={classNames(classes.AIInteractionAnalysisContainer)}>
      <ModalLayout>
        <div className={classes.Content}>
          <FancyHeader
            icon={MicrochipAILightIcon}
            title={'Loops Assistant'}
            onClose={onClose}
            className={classes.Header}
          />
          <div className={classes.Main}>
            <ThreadsList
              onNewSession={startNewSession}
              threads={threads}
              onSelectThread={getThread}
              selectedThreadId={activeThread?.id}
              className={classes.Threads}
              isLoading={isLoadingThreads}
            />
            <div className={classes.ChatWrapper}>
              <ChatMessages
                thread={activeThread}
                className={classes.Messages}
                isLoading={isLoadingAnswer}
                username={`${user.firstName} ${user.lastName}`}
                onStartNewSession={startNewSession}
                onMessageFeedback={onMessageFeedback}
                onDeleteThread={onDeleteThread}
                onUpdateThreadTitle={onEditTitle}
                onRunAnalysisAction={onRunAnalysisAction}
                onModelReference={onModelReference}
              />
              {!exists(activeThread?.completedOn) && (
                <UserTextInput
                  className={classes.UserTextInput}
                  value={message}
                  onChange={setMessage}
                  onSubmit={() => onSubmitMessage(message)}
                  username={`${user.firstName} ${user.lastName}`}
                  disabled={inputDisabled}
                />
              )}
            </div>
          </div>
        </div>
      </ModalLayout>
    </div>
  );
};

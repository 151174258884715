import {useDispatch} from 'react-redux';
import {useCallback, useEffect, useMemo} from 'react';
import {EXCLUDE_ANALYSIS_TYPE_IDS} from '../pages/analyses-main/analyses-main.component';
import {getAnalysisResultsNetworkRequest} from '../../../http/analysis-results.network-requests';
import {analysisToastCreator} from '../../../store/toasts.actions';
import {ModelKey} from '../../../constants/model-key';
import {useRemoteList} from '../../../core/hooks/use-remote-list.hook';
import {useInterval} from '../../../core/hooks/use-interval.hook';
import {exists, getNextSort} from 'front-core';
import {removeList, replaceList} from '../../../store/remote-lists/remote-list.actions';
import {
  registerActionListener,
  removeActionListener,
} from '../../../store/actions-listener/actions-listener.actions';
import {CoreActionsType} from '../../../store/core/core.actions';
import {NRC} from '../../../http';
import {AnalysisResultFiltersDTO} from '../../../objects/dto/analysis-result-filters.dto';

const createListKey = (key: string | number) => `ANALYSIS_FOLDER_VIEW/${key}`;
const AUTO_REFRESH_SEC = 30;

interface Config {
  key: string | number;
  extraDefaultFilters?: any;
  filtersDef?: any[];
  networkRequest?: NRC<void, AnalysisResultFiltersDTO>;
  autoRefresh?: boolean;
}

const LISTENER_ACTIONS = [
  CoreActionsType.MODEL_CREATED,
  CoreActionsType.MODEL_UPDATED,
  CoreActionsType.MODEL_DELETED,
];
const RELEVANT_MODELS = [
  // ModelKey.ANALYSIS_RESULT,
  ModelKey.ANALYSIS,
  ModelKey.ANALYSIS_FOLDER,
  ModelKey.ANALYSIS_FOLLOWUP,
];

export const useAnalysisResultList = (config: Config) => {
  const dispatch = useDispatch();

  const defaultFilters = useMemo(
    () => ({
      orderBy: ['createdOn'],
      order: ['desc'],
      itemsPerPage: 30,
      // isAutoGenerated: false,
      isSystemGenerated: false,
      isNewest: true,
      excludeAnalysisTypeId: EXCLUDE_ANALYSIS_TYPE_IDS,
      ...(config.extraDefaultFilters || {}),
    }),
    [config.extraDefaultFilters]
  );

  const listConfig = useMemo(
    () => ({
      listKey: createListKey(config.key),
      actionKey: createListKey(config.key),
      request: config.networkRequest || getAnalysisResultsNetworkRequest,
      onError: err => [analysisToastCreator('GET_ERROR')],
      modelKey: ModelKey.ANALYSIS_RESULT,
    }),
    [config.key, config.networkRequest]
  );

  const useRemoteListProps = useRemoteList({
    config: listConfig,
    filtersDef: config.filtersDef,
    defaultFilters: defaultFilters,
    syncQueryFilters: true,
    switchRequest: true, // this is used to solve race condition caused by useInterval
  });
  const {filters, onFiltersChange, onRefresh, onLoadMore} = useRemoteListProps;
  useInterval({
    callback: onRefresh,
    timeoutSec: AUTO_REFRESH_SEC,
    initial: config.autoRefresh,
  });

  const onSortTable = useCallback(
    (key: string) => {
      const newSort: any = getNextSort(key, {
        order: filters.order?.[0],
        orderBy: filters.orderBy?.[0],
      });
      if (exists(newSort.orderBy) && newSort.orderBy !== 'createdOn') {
        newSort.order = [newSort.order, 'desc'];
        newSort.orderBy = [newSort.orderBy, 'createdOn'];
      } else {
        newSort.order = [newSort.order];
        newSort.orderBy = [newSort.orderBy];
      }
      onFiltersChange({...filters, ...newSort});
    },
    [onFiltersChange, filters]
  );

  useEffect(() => {
    const listener = action => {
      if (RELEVANT_MODELS.indexOf(action.payload.modelKey) > -1) {
        dispatch(replaceList(listConfig.listKey, {}, 'append', true));
      }
    };
    dispatch(registerActionListener(LISTENER_ACTIONS, listener));
    return () => {
      dispatch(removeList(listConfig.listKey));
      dispatch(removeActionListener(LISTENER_ACTIONS, listener));
    };
  }, [dispatch, listConfig.listKey]);

  return {
    ...useRemoteListProps,
    onSortTable,
    onLoadMore,
  };
};

import {useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';
import {ShareResourceType} from '../../../objects/models/share.model';
import {PanelsContext} from '../../../core/contexts/panels.context';
import {
  changeAnalysisFolderIcon,
  deleteAnalysisFolder,
} from '../../../store/analysis-folders/analysis-folders.actions';
import {PanelKey} from '../../../constants/panels';
import {PanelType} from '../../../objects/system/panel-type.enum';
import {deleteShareResourceConfirmed} from '../../../store/user-reactions/user-reactions.actions';
import {ANALYSIS_FOLDER_ID_PATH_PARAM} from '../../../constants/app-routes';
import {useIsExplored} from '../../../core/hooks/user-reactions.hook';
import {ModelDiscriminatorType} from '../../../objects/models/user-reaction.model';
import {AnalysisFolder} from '../../../objects/models/analysis-folder.model';
import {useCurrentUser} from '../../../core/hooks/use-user.hook';
import {ModelKey} from '../../../constants/model-key';
import {ActionHooks} from 'front-core';

export interface AnalysisFolderActions {
  onCreate: (parameters: any, hooks?: ActionHooks) => void;
  onExplore: (analysisFolder: AnalysisFolder) => void;
  onEdit: (folderId: number) => void;
  onDelete: (folderId: number) => void;
  onShare: (folderId: number) => void;
  onDeshare: (folderId: number) => void;
  onChangeIcon: (folderId: number, icon: string) => void;
  onMove: (folderId: AnalysisFolder) => void;
}

export function useAnalysisFolderActions(): AnalysisFolderActions {
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const {openPrimaryPanel} = useContext(PanelsContext);
  const onExplore = useIsExplored(ModelDiscriminatorType.ANALYSIS_FOLDER);

  const onCreate = useCallback(
    (parameters: any = {}) => {
      openPrimaryPanel(PanelKey.ANALYSIS_FOLDER_FORM_PANEL, parameters, PanelType.MODAL);
    },
    [openPrimaryPanel]
  );

  const onMove = useCallback(
    (analysisFolder: AnalysisFolder) =>
      openPrimaryPanel(
        PanelKey.MOVE_TO_ANALYSIS_FOLDER_PANEL,
        {
          modelId: analysisFolder.id,
          modelKey: ModelKey.ANALYSIS_FOLDER,
        },
        PanelType.MODAL
      ),
    [openPrimaryPanel]
  );

  const onEdit = useCallback(
    (folderId: number) => {
      openPrimaryPanel(
        PanelKey.ANALYSIS_FOLDER_FORM_PANEL,
        {
          [ANALYSIS_FOLDER_ID_PATH_PARAM]: folderId,
        },
        PanelType.MODAL
      );
    },
    [openPrimaryPanel]
  );

  const onDelete = useCallback(
    (folderId: number) => dispatch(deleteAnalysisFolder(folderId)),
    [dispatch]
  );

  const onChangeIcon = useCallback(
    (folderId: number, icon: string) => dispatch(changeAnalysisFolderIcon(folderId, icon)),
    [dispatch]
  );

  const onShare = useCallback(
    (folderId: number) =>
      openPrimaryPanel(
        PanelKey.SHARE_RESOURCE_PANEL,
        {
          modelId: folderId,
          type: ShareResourceType.ANALYSIS_FOLDER,
        },
        PanelType.MODAL
      ),
    [openPrimaryPanel]
  );

  const onDeshare = useCallback(
    (folderId: number) =>
      dispatch(
        deleteShareResourceConfirmed(folderId, ShareResourceType.ANALYSIS_FOLDER, [user.id])
      ),
    [dispatch, user.id]
  );

  return {
    onCreate,
    onExplore,
    onEdit,
    onDelete,
    onShare,
    onDeshare,
    onMove,
    onChangeIcon,
  };
}

import {BaseModel} from './base.model';
import {HistorifyModel} from './historify-model.model';

export enum TableDefinitionType {
  QUERY = 'query',
  TABLE = 'table',
}

export interface TableDefinition {
  type: TableDefinitionType;
  name?: string;
  prefix?: string;
  query?: string;
}

export enum TableType {
  ENTITY_PROPERTIES = 'entity_properties',
  EVENTS = 'events',
  STATE = 'state',
}

export enum TableEntity {
  USER = 'user',
  TEAM = 'team',
  LEAD = 'lead',
  CAMPAIGN = 'campaign',
  CUSTOM_ENTITY_1 = 'custom_entity_1',
  CUSTOM_ENTITY_2 = 'custom_entity_2',
  CUSTOM_ENTITY_3 = 'custom_entity_3',
}

export enum TableEntityBinding {
  DEFAULT = 'default',
  ONE_WAY = 'one_way',
  TWO_WAY = 'two_way',
}

export enum GroupByTimeStrategy {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export interface TableParameters {
  entity_id?: string;
  event_name?: string;
  timestamp?: string;
  start_at?: string;
  group_by_time_strategy?: GroupByTimeStrategy;
  is_main?: boolean;
}

export interface Table extends BaseModel, HistorifyModel {
  name: string;
  type: TableType;
  entity: TableEntity;
  definition: TableDefinition;
  parameters: TableParameters;
  isComputed: boolean;
  uiMetadata: any;
  shortDescription?: string;
}

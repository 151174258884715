import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {FlaskIcon} from 'ui-components';
import {ActionKey} from '../../../../constants/action-key';
import TransKeys from '../../../../constants/translation-keys';
import {EXPERIMENT_ID_PATH_PARAM} from '../../../../constants/app-routes';
import classes from './experiment-subscription-panel.module.scss';
import {
  createExperimentSubscription,
  deleteExperimentSubscription,
  editExperimentSubscription,
} from '../../../../store/experiments/experiments.actions';
import {experimentSubscriptionDTOValidator} from '../../../../objects/dto/experiment-subscription.dto';
import {CRON_DEFAULT_VALUES} from '../../../../constants/cron-exp.consts';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {getExperimentNetworkRequest} from '../../../../http/experiments.network-requests';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {Experiment, ExperimentStatus} from '../../../../objects/models/experiment.model';
import moment from 'moment';
import {TIME_FORMATS} from '../../../../constants/time-formats';
import CronPanelFormComponent from '../../../shared/components/general/cron-panel-form/cron-panel-form.component';
import {AnalysisTypeId} from '../../../../constants/analysis-type-id';
import {useCurrentUser} from '../../../../core/hooks/use-user.hook';
import {composition} from 'front-core';
import {withDisableDemoProduct} from '../../../../core/hoc/with-disable-demo-product.hoc';

const SELECTED_EXPERIMENT_KEY = SharedSelectionKeys.EXPERIMENT_FORM__EXPERIMENT;

interface OwnProps {
  experiment: Experiment;
  onClose: () => void;
  panelId?: string;
  disabled?: boolean;
}

type AllProps = OwnProps;

const ExperimentSubscriptionPanelComponent: React.FC<AllProps> = (props: AllProps) => {
  const {experiment, onClose, panelId, disabled} = props;
  const {t} = useTranslation();
  const user = useCurrentUser();
  const defaultValues = useMemo(
    () => ({
      cronExp: experiment?.cronExp || CRON_DEFAULT_VALUES.WEEKLY,
      experimentId: experiment.id,
      subscriberId: user.id,
    }),
    [experiment, user]
  );

  const editMode = useMemo(() => Boolean(experiment?.cronExp), [experiment]);

  const actionHandlers = useMemo(
    () => ({
      edit: editExperimentSubscription,
      create: createExperimentSubscription,
      delete: deleteExperimentSubscription,
    }),
    []
  );

  const onDeleteDataParam = useMemo(
    () => ({
      experimentId: experiment.id,
      subscriberId: user.id,
    }),
    [experiment, user]
  );
  const isCompleted = useMemo(
    () =>
      experiment.status === ExperimentStatus.DONE || experiment.status === ExperimentStatus.STOPPED,
    [experiment]
  );
  const texts = useMemo(
    () => ({
      title: t(TransKeys.EXPERIMENT.SUBSCRIPTION_FORM.TITLE),
      subTitleCta: t(
        TransKeys.EXPERIMENT.SUBSCRIPTION_FORM[editMode ? 'EDIT' : 'CREATE'].SUBTITLE_CTA
      ),
      deleteBtn: t(TransKeys.GENERAL.ACTIONS.UNSUBSCRIBE),
      submitBtn: editMode
        ? t(TransKeys.EXPERIMENT.EXPERIMENT_SUBSCRIPTION.EDIT_SUBSCRIPTION)
        : t(TransKeys.GENERAL.ACTIONS.SUBSCRIBE),
      modalBody:
        experiment.analysis.analysisTypeId === AnalysisTypeId.A_B_TEST
          ? t(TransKeys.EXPERIMENT.EXPERIMENT_SUBSCRIPTION.COMPLETED_MESSAGE, {
              endDate: moment(experiment.endedOn).format(TIME_FORMATS.DEFAULT_DATE_FORMAT),
            })
          : t(TransKeys.EXPERIMENT.EXPERIMENT_SUBSCRIPTION.ONLY_AB_TEST_MESSAGE),
    }),
    [t, editMode, experiment]
  );
  const showForm = useMemo(
    () =>
      experiment.analysis.analysisTypeId === AnalysisTypeId.A_B_TEST &&
      experiment.status === ExperimentStatus.IN_PROGRESS,
    [experiment]
  );
  const containerClasses = useMemo(() => isCompleted && classes.Completed, [isCompleted]);
  const formHiddenInputsNames = useMemo(() => ['experimentId'], []);

  return (
    <CronPanelFormComponent
      onClose={onClose}
      panelId={panelId}
      defaultValues={defaultValues}
      resolverSchema={experimentSubscriptionDTOValidator}
      loadingKeys={[
        ActionKey.CREATE_EXPERIMENT_SUBSCRIPTION,
        ActionKey.EDIT_EXPERIMENT_SUBSCRIPTION,
        ActionKey.DELETE_EXPERIMENT_SUBSCRIPTION,
      ]}
      editMode={editMode}
      actionsHandlers={actionHandlers}
      onDeleteDataParam={onDeleteDataParam}
      texts={texts}
      showModalFooter={showForm}
      showForm={showForm}
      containerClasses={containerClasses}
      formHiddenInputsNames={formHiddenInputsNames}
      icon={FlaskIcon}
      showWeekly
      showDaily
      disabled={disabled}
    />
  );
};

export const ExperimentSubscriptionPanel = composition<AllProps>(
  ExperimentSubscriptionPanelComponent,
  withDisableDemoProduct,
  withLoadBefore({
    experiment: {
      selectedKey: SELECTED_EXPERIMENT_KEY,
      actionKey: SELECTED_EXPERIMENT_KEY,
      request: getExperimentNetworkRequest,
      mapPayloadFromProps: props => props[EXPERIMENT_ID_PATH_PARAM],
      shouldCall: props => props[EXPERIMENT_ID_PATH_PARAM] !== undefined,
    },
  })
);

import classes from './main-header.module.scss';
import {HoverHelperTip} from 'ui-components';

interface OwnProps {
  title: string;
  renderRight?: any;
  helperText?: any;
  icon?: any;
  isLoading?: boolean;
}

type AllProps = OwnProps;

export const MainHeader: React.FC<AllProps> = (props: AllProps) => {
  const {title, renderRight, icon: Icon, helperText} = props;

  return (
    <div className={classes.MainHeader}>
      <div className={classes.Header}>
        {Icon && (
          <div className={classes.IconWrapper}>
            <Icon className={classes.Icon} />
          </div>
        )}
        <div className={classes.Title}>{title}</div>
        {helperText && <HoverHelperTip title={helperText} />}
      </div>
      {renderRight && <div className={classes.Right}>{renderRight}</div>}
    </div>
  );
};

import React, {useCallback, useMemo, useState, useRef, FC, useEffect} from 'react';
import classNames from 'classnames';
import {AnalysisDTO} from 'src/objects/dto/analysis.dto';
import modalClasses from './modal-layout.module.scss';
import {SideMenu, createFontAwesomeIcon, SearchInput} from 'ui-components';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {GenericLoading} from '../../../../../shared/components/general/generic-loading/generic-loading.component';
import {AnalysisType} from '../../../../../../objects/models/analysis-type.model';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import yup from '../../../../../../config/yup.config';
import {useAmplitude} from 'src/core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../../../constants/amplitude-event';
import classes from './analysis-type-selection-tab.module.scss';
import {useAnalysisTypesCatalog} from '../../../../hooks/use-analysis-types-catalog.hook';
import {AnalysisTypeSelectionAbout} from './components/analysis-type-selection-about/analysis-type-selection-about.component';
import {AnalysisTypeSelectionCategory} from './components/analysis-type-selection-category/analysis-type-selection-category.component';
import i18n from 'i18next';
import {
  AnalysisTypeSelectionSentenceWizard,
  AnalysisTypeSubject,
} from './components/analysis-type-selection-sentence-wizard/analysis-type-selection-sentence-wizard.component';
import Fuse from 'fuse.js';
import {debounce} from 'lodash';
import {exists} from 'front-core';
import {getAnalysisTypeIds} from './analysis-type-selection-tab.utils';
import {analysisTypeSubjectParametersFiller} from './analysis-type-subject-parameters-filler';

interface OwnProps {
  onSubmit: (data: Partial<AnalysisDTO>) => void;
  data: Partial<AnalysisDTO>;
  accepted?: number[];
  exclude?: number[];
  showCategories?: boolean;
  showSearch?: boolean;
  onSignalInfo: (signalId: number) => void;
  className?: string;
}

const validator = yup
  .object()
  .shape({
    analysisTypeId: yup.number().required(),
    annotationId: yup.number().nullable(),
    parameters: yup.object().nullable(),
  })
  .noUnknown();

type AllProps = OwnProps;

const getResultsCategory = () => ({
  id: 0,
  name: i18n.t(TransKeys.CREATE_ANALYSIS_FORM.SEARCH_RESULTS),
  fontAwesomeIcon: 'fa-duotone fa-magnifying-glass',
});

const fuseOptions = {
  threshold: 0.3,
  ignoreLocation: true,
  keys: [{name: 'staticName', weight: 2}, 'shortDescription'],
};

export const AnalysisTypeSelectionTab: FC<AllProps> = (props: AllProps) => {
  const {data, onSubmit, accepted, exclude, showCategories, showSearch, onSignalInfo} = props;
  const {t} = useTranslation();
  const notify = useAmplitude();
  const {analysisTypes, isLoading, categories} = useAnalysisTypesCatalog({accepted, exclude});
  const categoryResultsRef = useRef(null);
  // state
  const [searchText, setSearchText] = useState('');
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [focusedCategoryId, setFocusedCategoryId] = useState(null);
  const [aboutAnalysisType, setAboutAnalysisType] = useState(null);
  const [searchSubject, setSearchSubject] = useState<AnalysisTypeSubject>(undefined);
  const fuse = useMemo(() => new Fuse(analysisTypes, fuseOptions), [analysisTypes]);

  const {handleSubmit, setValue} = useForm({
    defaultValues: data,
    resolver: yupResolver(validator),
  });
  // Memos
  const displayedResults = useMemo(() => {
    let resultsCategory = getResultsCategory();

    if (!showCategories) {
      return [{...resultsCategory, analyses: analysisTypes}];
    }
    if (exists(searchText)) {
      return [
        {...resultsCategory, analyses: fuse.search(searchText).map((result: any) => result.item)},
      ];
    }
    if (exists(searchSubject)) {
      const matchingAnalysisTypeIds = getAnalysisTypeIds(
        searchSubject.modelType as any,
        searchSubject.model,
        searchSubject.topic
      );
      return [
        {
          ...resultsCategory,
          analyses: analysisTypes.filter(at => matchingAnalysisTypeIds.indexOf(at.id) > -1),
        },
      ];
    }

    return Object.values(categories).sort((a, b) => a.displayOrder - b.displayOrder);
  }, [categories, analysisTypes, showCategories, searchText, fuse, searchSubject]);
  const menuItems = useMemo(
    () =>
      displayedResults.map(category => ({
        label: category.name,
        value: category.id,
        icon: createFontAwesomeIcon(category.fontAwesomeIcon),
      })),
    [displayedResults]
  );
  // Callbacks
  const onTypeMenuSelected = useCallback(
    (id?: number) => {
      if (id) {
        setSelectedCategoryId(id);
        setFocusedCategoryId(null);
        notify(AmplitudeEvent.CREATE_ANALYSIS_CATEGORY_SELECTED, {
          category_id: id,
          category_name: menuItems.find(item => item.value === id)?.label,
        });
      }
    },
    [notify, menuItems, setSelectedCategoryId, setFocusedCategoryId]
  );
  const onPreview = useCallback(
    (analysisType: AnalysisType) => {
      setAboutAnalysisType(analysisType);
      notify(AmplitudeEvent.CREATE_ANALYSIS_PREVIEW_CLICKED, {analysis_type_id: analysisType.id});
    },
    [notify, setAboutAnalysisType]
  );
  const onSelect = useCallback(
    (analysisType: AnalysisType) => {
      setValue('analysisTypeId', analysisType.id);
      setValue('parameters', analysisTypeSubjectParametersFiller(analysisType.id, searchSubject));
      handleSubmit(onSubmit)();
    },
    [setValue, handleSubmit, onSubmit, searchSubject]
  );
  const onWizardChanged = useCallback(
    (change: AnalysisTypeSubject) => {
      setSearchText('');
      if (!exists(change)) {
        setSearchSubject(undefined);
        return;
      }
      setSearchSubject(sub => ({...sub, ...change}));
    },
    [setSearchSubject]
  );

  const notifySearch = useMemo(
    () =>
      debounce(searchValue => {
        notify(AmplitudeEvent.CREATE_ANALYSIS_SEARCH, {
          value: searchValue,
        });
      }, 1000),
    [notify]
  );
  useEffect(() => {
    notifySearch(searchText);
  }, [notifySearch, searchText]);

  if (isLoading) {
    return <GenericLoading />;
  }

  if (aboutAnalysisType) {
    return (
      <div className={classNames(modalClasses.ModalContainer, classes.SelectAnalysisTab)}>
        <AnalysisTypeSelectionAbout
          onSelect={onSelect}
          onDismiss={() => setAboutAnalysisType(null)}
          analysisType={aboutAnalysisType}
        />
      </div>
    );
  }

  return (
    <div className={classNames(modalClasses.ModalContainer, classes.SelectAnalysisTab)}>
      {showCategories && (
        <div className={modalClasses.ModalLeftPanel}>
          <div className={modalClasses.Header}>
            {t(TransKeys.CREATE_ANALYSIS_FORM.SIDE_MENU.TITLE)}
          </div>
          <div className={modalClasses.Section}>
            <SideMenu
              items={menuItems}
              selected={focusedCategoryId || selectedCategoryId}
              onSelect={v => onTypeMenuSelected(v as number)}
            />
          </div>
        </div>
      )}
      <div className={modalClasses.ModalBody}>
        <div className={modalClasses.ModalContent}>
          <div className={classes.Categories}>
            <div className={classes.List} ref={categoryResultsRef}>
              {showSearch && (
                <div className={classes.SearchWrapper}>
                  <AnalysisTypeSelectionSentenceWizard
                    onChange={onWizardChanged}
                    onSignalInfo={onSignalInfo}
                    {...searchSubject}
                  />
                  <SearchInput
                    className={classes.SearchInput}
                    value={searchText}
                    onChange={setSearchText}
                    placeholder={t(TransKeys.CREATE_ANALYSIS_FORM.SEARCH_ANALYSIS_PLACEHOLDER)}
                  />
                </div>
              )}
              {displayedResults.map((category: any) => (
                <AnalysisTypeSelectionCategory
                  key={category.id}
                  {...category}
                  analyses={category.analyses}
                  onSelect={onSelect}
                  onPreview={onPreview}
                  selectedCategoryId={selectedCategoryId}
                  onCategoryFocus={setFocusedCategoryId}
                  containerRef={categoryResultsRef}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AnalysisTypeSelectionTab.defaultProps = {
  showCategories: true,
  showSearch: true,
};

import {createRequestEpic} from 'front-core';
import {Epic} from 'redux-observable';
import {ActionKey} from '../../constants/action-key';
import HttpClient from '../../services/http-client.service';
import {PushInsightsActionType} from './push-insights.actions';
import {createWeeklyInterestsNetworkRequest} from '../../http/push-insights.network-requests';

export const createWeeklyInterestEpic: Epic = createRequestEpic(
  {
    types: [PushInsightsActionType.CREATE_WEEKLY_INTERESTS],
    actionKey: ActionKey.GET_USER_ONBOARDING,
    request: createWeeklyInterestsNetworkRequest,
  },
  HttpClient
);

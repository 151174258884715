import * as React from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import classes from './health-monitor-empty-state.module.scss';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../constants/translation-keys';
import {Button, useRemoteSourceStated} from 'ui-components';
import {getHealthMonitorInitialMetricsNetworkRequest} from '../../../../http/health-monitor.network-requests';
import {getLastSampleValue} from '../../../../utils/metric-series.utils';
import {Sparklines, SparklinesLine} from 'react-sparklines';
import {CalcSeriesMode} from '../../../../constants/metric-series.consts';
import {StandardCheckBox} from '../../../shared/components/general/standard-check-box/standard-check-box.component';
import {keys} from 'lodash';

interface OwnProps {
  onSubmit: (monitoredMetricIds: number[]) => void;
  mode: CalcSeriesMode;
  className?: string;
}

type AllProps = OwnProps;

export const HealthMonitorEmptyState: React.FC<AllProps> = (props: AllProps) => {
  const {onSubmit, className} = props;
  const {t} = useTranslation();
  const [selectedMetrics, setSelectedMetrics] = useState({});

  const {source: metrics, exec: getMetrics} = useRemoteSourceStated({
    initialValue: [],
    networkRequest: getHealthMonitorInitialMetricsNetworkRequest,
  });
  const metricsData = useMemo(() => {
    return metrics.map(m => ({
      ...m,
      lastSampleValue: getLastSampleValue(m),
      samples: (m.samples || []).map(s => s.value),
    }));
  }, [metrics]);
  const onSelectionChanged = useCallback(
    (id: number, v: boolean) => {
      setSelectedMetrics(selectedMetrics => ({
        ...selectedMetrics,
        [id]: v,
      }));
    },
    [setSelectedMetrics]
  );
  const selectedMetricsArray = useMemo(
    () =>
      keys(selectedMetrics)
        .filter(k => selectedMetrics[k])
        .map(Number),
    [selectedMetrics]
  );
  const buttonText = useMemo(() => {
    if (selectedMetricsArray.length === 0) {
      return t(TransKeys.HEALTH_MONITOR.EMPTY_STATE.PLEASE_SELECT_METRICS);
    }
    return t(TransKeys.HEALTH_MONITOR.EMPTY_STATE.SELECT_COUNT_METRICS, {
      count: selectedMetricsArray.length,
    });
  }, [selectedMetricsArray, t]);

  useEffect(() => {
    getMetrics();
  }, [getMetrics]);

  return (
    <div className={classes.EmptyStateWrapper}>
      <div className={classNames(classes.HealthMonitorEmptyState, className)}>
        <div className={classes.Main}>
          <div className={classes.Header}>
            <div className={classes.Title}>{t(TransKeys.HEALTH_MONITOR.EMPTY_STATE.TITLE)}</div>
            <div className={classes.SubTitle}>
              {t(TransKeys.HEALTH_MONITOR.EMPTY_STATE.SUB_TITLE)}
            </div>
          </div>
          <div className={classes.MetricList}>
            {metricsData.map(m => (
              <div
                className={classNames(
                  classes.MetricCard,
                  selectedMetrics[m.id] && classes.Selected
                )}
                key={m.id}
                onClick={e => onSelectionChanged(m.id, !selectedMetrics[m.id])}
              >
                <div className={classes.Head}>
                  <StandardCheckBox
                    checked={selectedMetrics[m.id]}
                    onChange={v => onSelectionChanged(m.id, v)}
                  />
                  <div className={classes.Value}>
                    {t(TransKeys.GENERAL.LABELS.VALUE)}: {m.lastSampleValue}
                  </div>
                </div>
                <div className={classes.Name}>{m.name}</div>
                <div className={classes.Chart}>
                  <Sparklines height={40} margin={4} data={m.samples}>
                    <SparklinesLine
                      style={{fill: 'none', strokeWidth: 3}}
                      color="rgba(137, 142, 168, 1)"
                    />
                  </Sparklines>
                </div>
              </div>
            ))}
          </div>
          <div className={classes.SelectMetrics}>
            <div className={classes.Info}>*graph shows trend over the past 5 measures</div>
            <div className={classes.SubmitWrapper}>
              <Button
                disabled={selectedMetricsArray.length === 0}
                onClick={() => onSubmit(selectedMetricsArray)}
              >
                {buttonText}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

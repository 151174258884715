import * as React from 'react';
import {useCallback, useEffect, useMemo} from 'react';
import {BaseConversionBuilder} from './base-conversion-builder.component';
import {cloneDeep, get, keys, set} from 'lodash';
import {exists} from 'front-core';
import {QueryBuilderFactory} from 'ui-components';
import {BOUNDED_ACTION_ANALYSIS_PARAMETER_MAPPING} from '../query-builders.config';
import {
  METADATA_KEY,
  PARAMETERS_METADATA_KEY,
} from '../../../../../constants/parameters-saved-keys';
import {TableEntity} from '../../../../../objects/models/table.model';
import {SelectorModelType} from '../../smart-selector/advanced-smart-selector.component';

interface OwnProps {
  query: any;
  onChange?: (parameters: any) => void;
  onSignalInfo?: (value: string | number) => void;
  entityContext?: TableEntity;
  refDateSignalFilters?: any;
  refDateAllowTypes?: SelectorModelType[];
  goalSignalFilters?: any;
  errors?: any;
  disabled?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const CONVERSION_BUILDER_COMPONENT_NAME = 'ConversionQueryBuilder';

const mapper = (value: any) => ({
  goal: get(value, BOUNDED_ACTION_ANALYSIS_PARAMETER_MAPPING.goal),
  ref_date: get(value, BOUNDED_ACTION_ANALYSIS_PARAMETER_MAPPING.ref_date),
  goal_bound_unit: get(value, BOUNDED_ACTION_ANALYSIS_PARAMETER_MAPPING.goal_bound_unit),
  goal_bound_n: get(value, BOUNDED_ACTION_ANALYSIS_PARAMETER_MAPPING.goal_bound_n),
});

export const ConversionQueryBuilder: React.FC<AllProps> = (props: AllProps) => {
  const {
    errors,
    onChange,
    onSignalInfo,
    query,
    disabled,
    entityContext,
    className,
    refDateSignalFilters,
    refDateAllowTypes,
    goalSignalFilters,
  } = props;
  const mappedValue = useMemo(() => mapper(query), [query]);
  const mappedErrors = useMemo(() => mapper(errors), [errors]);

  const mappedOnChange = useCallback(
    changes => {
      let newValue = cloneDeep(query);
      if (!exists(newValue)) {
        newValue = QueryBuilderFactory.createTemplate();
        newValue.template = 'bounded_action_ts';
        newValue.parameters[1] = 0;
      }
      for (const k of keys(changes)) {
        set(newValue, BOUNDED_ACTION_ANALYSIS_PARAMETER_MAPPING[k], changes[k]);
      }
      newValue[PARAMETERS_METADATA_KEY] = {
        [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: CONVERSION_BUILDER_COMPONENT_NAME,
      };
      onChange && onChange(newValue);
    },
    [onChange, query]
  );

  useEffect(() => {
    if (!exists(query)) {
      return;
    }
    if (!(PARAMETERS_METADATA_KEY in query)) {
      const newQuery = set(query, PARAMETERS_METADATA_KEY, {
        [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: 'ConversionQueryBuilder',
      });
      onChange && onChange(newQuery);
    }
  }, [query, onChange]);

  return (
    <BaseConversionBuilder
      onChange={mappedOnChange}
      value={mappedValue}
      errors={mappedErrors}
      entityContext={entityContext}
      onSignalInfo={onSignalInfo}
      className={className}
      disabled={disabled}
      refDateSignalFilters={refDateSignalFilters}
      refDateAllowTypes={refDateAllowTypes}
      goalSignalFilters={goalSignalFilters}
    />
  );
};

import moment from 'moment';
import {isEmpty} from 'lodash';
import {exists} from 'front-core';
import {AnalysisResult, AnalysisResultStatus} from '../objects/models/analysis-result.model';

const DEFAULT_INPUT_DATE_FORMAT = 'YYYY-MM-DD';

export const setRunParametersDefaultDates = (parameters: any, runParametersSchema: any): any => {
  let defaultDates = {};
  if ('end_date' in runParametersSchema && !exists(parameters?.end_date)) {
    defaultDates['end_date'] = moment().subtract(1, 'day').format(DEFAULT_INPUT_DATE_FORMAT);
  }
  if (
    'start_date' in runParametersSchema &&
    !exists(parameters?.start_date) &&
    defaultDates?.['end_date']
  ) {
    defaultDates['start_date'] = moment()
      .subtract(1, 'day')
      .subtract(3, 'months')
      .format(DEFAULT_INPUT_DATE_FORMAT);
  }
  return !isEmpty(defaultDates) ? defaultDates : null;
};

export const isAnalysisResultProcessing = (
  analysisResult: AnalysisResult,
  graceForFailedStatus = false
) => {
  const delayFailure =
    analysisResult.status === AnalysisResultStatus.FAILED &&
    moment(analysisResult.completedOn).add(24, 'h').isAfter(moment());

  const res =
    analysisResult.status === AnalysisResultStatus.RUNNING ||
    analysisResult.status === AnalysisResultStatus.QUEUED ||
    analysisResult.status === AnalysisResultStatus.REQUESTED ||
    (graceForFailedStatus && delayFailure);

  return res;
};

export const getAnalysisStepProgress = (analysisResult: AnalysisResult) => {
  const {totalStepsCount = 1, currentStepIndex = 0} = analysisResult?.progress || {};
  return currentStepIndex / totalStepsCount;
};

import {useCallback, useState} from 'react';

type CopiedValue = string | null;
type CopyFn = (text: string) => Promise<boolean>; // Return success
type ResetFn = () => void;

export function useCopyToClipboard(): {
  copiedText: CopiedValue;
  copy: CopyFn;
  resetCopiedText: ResetFn;
} {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);

  const resetCopiedText = useCallback(() => setCopiedText(null), [setCopiedText]);

  const copy: CopyFn = useCallback(
    async text => {
      if (!navigator?.clipboard) {
        console.warn('Clipboard not supported');
        return false;
      }

      // Try to save to clipboard then save it in the state if worked
      try {
        await navigator.clipboard.writeText(text);
        setCopiedText(text);
        return true;
      } catch (error) {
        console.warn('Copy failed', error);
        setCopiedText(null);
        return false;
      }
    },
    [setCopiedText]
  );

  return {
    copiedText,
    copy,
    resetCopiedText,
  };
}

import {useCallback} from 'react';
import {useMemo} from 'react';
import classes from '../table-form-panel.module.scss';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../../constants/translation-keys';
import {useFormContext, Controller} from 'react-hook-form';
import {
  GroupByTimeStrategy,
  TableDefinitionType,
  TableEntity,
  TableType,
} from '../../../../../objects/models/table.model';
import {sharedClasses} from '../../../../shared';
import {
  EnumFormInput,
  TextFormInput,
} from '../../../../shared/form/components/shared-form-input.component';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';
import TableEntityTypeField from '../table-form-panel-shared-fields/table-entity-type-field.component';
import {TabHeader} from '../../../../shared/components/general/tab-header/tab-header.component';
import {contactSupport} from '../../../../../utils/general.utils';
import {NoteText} from '../../../../shared/components/general/note-text/note-text.component';
import {ApplicationError, exists} from 'front-core';
import {isEmpty, values} from 'lodash';
import {AGG_TO_TEXT} from '../table-form-panel.component';
import {LabelWrapper} from 'ui-components';
import TableIsMainField from '../table-form-panel-shared-fields/table-is-main-field.component';
import TableTypeInput from '../../../../shared/form/components/table-type-input/table-type-field.component';
import {SqlInput} from '../../../../shared/form/components/sql-input/sql-input.component';
import {useSqlValidation} from '../../../../shared/form/hooks/use-sql-validation.hook';

interface OwnProps {
  setEntity: (entity: TableEntity) => void;
  setIsMain: (isMain: boolean) => void;
  submitError: ApplicationError;
  resetParameters: () => void;
  disabled?: boolean;
}

type AllProps = OwnProps;

const QueryTab: React.FC<AllProps> = (props: AllProps) => {
  const {submitError, setEntity, setIsMain, resetParameters, disabled} = props;
  const {t} = useTranslation();
  const {productEntitiesMap} = useProductData();
  const {
    watch,
    setValue,
    control,
    trigger,
    formState: {errors},
  } = useFormContext();
  const entity = watch('entity');
  const tableType = watch('type');
  const query = watch('definition.query');
  const sqlValidation = useSqlValidation(query);

  const groupByTimeStrategyOptions = useMemo(
    () => ({options: values(GroupByTimeStrategy).map(v => ({value: v, label: AGG_TO_TEXT[v]}))}),
    []
  );
  const onQueryFieldChange = useCallback(
    (query: string) => {
      setValue('definition.query', query);
    },
    [setValue]
  );
  const onTableTypeFieldChange = useCallback(
    (tableType: TableType) => {
      setValue('type', tableType);
      trigger('type');
      resetParameters();
    },
    [resetParameters, setValue, trigger]
  );

  return (
    <div className={classes.TableScopeTab} key={TableDefinitionType.QUERY}>
      {!isEmpty(submitError) && <div className={classes.Error}>{submitError.message}</div>}
      <TabHeader
        title={t(TransKeys.TABLE_FORM.TABS.SQL_TAB.TITLE)}
        subTitle={t(TransKeys.TABLE_FORM.TABS.SQL_TAB.SUB_TITLE)}
        size={'small'}
      />
      <div className={sharedClasses.Block}>
        <SqlInput
          value={query}
          placeholder={t(TransKeys.TABLE_FORM.INPUTS.DEFINITION.QUERY.PLACEHOLDER)}
          onChange={onQueryFieldChange}
          validation={sqlValidation}
          onBlur={sqlValidation.validate}
          minHeight="14rem"
          disabled={disabled}
        />
      </div>
      <div className={sharedClasses.Block}>
        <LabelWrapper
          error={exists(errors?.type)}
          label={t(TransKeys.TABLE_FORM.INPUTS.TABLE_TYPE.LABEL)}
          className={classes.LabelWrapper}
        >
          <Controller
            name={'type'}
            control={control}
            render={({field}) => (
              <TableTypeInput
                value={field.value}
                onChange={onTableTypeFieldChange}
                disabled={disabled}
              />
            )}
          />
          {tableType === TableType.ENTITY_PROPERTIES && (
            <Controller
              name={'parameters.is_main'}
              control={control}
              render={({field}) => (
                <TableIsMainField
                  value={field.value as unknown as boolean}
                  onChange={setIsMain}
                  disabled={disabled}
                />
              )}
            />
          )}
        </LabelWrapper>
        <Controller
          name={'entity'}
          control={control}
          render={({field}) => (
            <TableEntityTypeField
              value={field.value}
              onChange={setEntity}
              error={exists(errors?.entity)}
              disabled={disabled}
            />
          )}
        />
      </div>
      <div className={sharedClasses.Block}>
        <div className={sharedClasses.Input}>
          <TextFormInput
            label={t(TransKeys.TABLE_FORM.INPUTS.ENTITY_ID.LABEL, {
              entity: productEntitiesMap[entity].name.toLowerCase() || 'entity',
            })}
            placeholder={t(TransKeys.TABLE_FORM.INPUTS.ENTITY_ID.PLACEHOLDER)}
            name={'parameters.entity_id'}
            disabled={disabled}
            required
          />
        </div>
        {tableType === TableType.EVENTS && (
          <div className={sharedClasses.Input}>
            <TextFormInput
              label={t(TransKeys.TABLE_FORM.INPUTS.EVENT_NAME.LABEL)}
              placeholder={t(TransKeys.TABLE_FORM.INPUTS.EVENT_NAME.PLACEHOLDER)}
              name={'parameters.event_name'}
              disabled={disabled}
              required
            />
          </div>
        )}
        {tableType === TableType.STATE && (
          <div className={sharedClasses.Input}>
            <TextFormInput
              label={t(TransKeys.TABLE_FORM.INPUTS.START_AT.LABEL)}
              placeholder={t(TransKeys.TABLE_FORM.INPUTS.START_AT.PLACEHOLDER)}
              name={'parameters.start_at'}
              disabled={disabled}
              required
            />
          </div>
        )}
        {tableType === TableType.ENTITY_PROPERTIES && <div className={sharedClasses.Input} />}
      </div>
      {tableType === TableType.EVENTS && (
        <div className={sharedClasses.Block}>
          <div className={sharedClasses.Input}>
            <TextFormInput
              label={t(TransKeys.TABLE_FORM.INPUTS.TIMESTAMP.LABEL)}
              placeholder={t(TransKeys.TABLE_FORM.INPUTS.TIMESTAMP.PLACEHOLDER)}
              name={'parameters.timestamp'}
              disabled={disabled}
              required
            />
          </div>
          <div className={sharedClasses.Input} />
        </div>
      )}
      {tableType === TableType.STATE && (
        <div className={sharedClasses.Block}>
          <div className={sharedClasses.Input}>
            <EnumFormInput
              label={t(TransKeys.TABLE_FORM.INPUTS.TIME_AGG.LABEL)}
              options={groupByTimeStrategyOptions}
              placeholder={t(TransKeys.TABLE_FORM.INPUTS.TIME_AGG.PLACEHOLDER)}
              sortValues={false}
              clearable={false}
              name={'parameters.group_by_time_strategy'}
              disabled={disabled}
            />
          </div>
          <div className={sharedClasses.Input} />
        </div>
      )}
      <NoteText
        text={t(TransKeys.SUPPORT.MISSING_EVENT_OR_PROPERTY)}
        buttonText={t(TransKeys.SUPPORT.SUPPORT_BUTTON_TEXT)}
        onButtonClicked={contactSupport}
      />
    </div>
  );
};

export default QueryTab;

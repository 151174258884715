import {ProductArea} from '../objects/system/product-area.enum';
import TransKeys from './translation-keys';
import i18n from '../config/i18n.config';
import {ImagesResource} from '../assets/images';
import {
  AcquisitionIcon,
  ActivationIcon,
  BugIcon,
  EngagementIcon,
  MonetizationIcon,
  OnboardingIcon,
  RetentionIcon,
  RocketLaunchLightIcon,
  MessagePenLightIcon,
} from 'ui-components';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import {AnnotationType} from '../objects/models/annotation.model';

export const AREA_COLOR = {
  [ProductArea.RETENTION]: 'rgba(249, 159, 132, 1)',
  [ProductArea.ONBOARDING]: 'rgba(130, 203, 182, 1)',
  [ProductArea.ACTIVATION]: 'rgba(126, 209, 234, 1)',
  [ProductArea.MONETIZATION]: 'rgba(210, 173, 249, 1)',
  [ProductArea.ACQUISITION]: 'rgba(177, 104, 255, 1)',
  [ProductArea.ENGAGEMENT]: 'rgba(203, 130, 130, 1)',
  [ProductArea.TRENDS]: 'rgba(206, 84, 76, 1)',
};

export const AREA_ICONS = {
  [ProductArea.ACQUISITION]: AcquisitionIcon,
  [ProductArea.ONBOARDING]: OnboardingIcon,
  [ProductArea.RETENTION]: RetentionIcon,
  [ProductArea.ACTIVATION]: ActivationIcon,
  [ProductArea.MONETIZATION]: MonetizationIcon,
  [ProductArea.ENGAGEMENT]: EngagementIcon,
  [ProductArea.TRENDS]: TrendingDownIcon,
};

export const ANNOTATION_ICONS = {
  [AnnotationType.BUG]: BugIcon,
  [AnnotationType.RELEASE]: RocketLaunchLightIcon,
  [AnnotationType.OTHER]: MessagePenLightIcon,
};

export const EFFORT_LABELS = [
  i18n.t(TransKeys.GENERAL.ENUMS.EFFORT['1']),
  i18n.t(TransKeys.GENERAL.ENUMS.EFFORT['2']),
  i18n.t(TransKeys.GENERAL.ENUMS.EFFORT['3']),
];

export const IMPACT_LABELS = [
  i18n.t(TransKeys.GENERAL.ENUMS.POTENTIAL_IMPACT['1']),
  i18n.t(TransKeys.GENERAL.ENUMS.POTENTIAL_IMPACT['2']),
  i18n.t(TransKeys.GENERAL.ENUMS.POTENTIAL_IMPACT['3']),
];

export const COMPANIES_LOGOS = {
  google: ImagesResource.companiesLogos.google,
  spotify: ImagesResource.companiesLogos.spotify,
  facebook: ImagesResource.companiesLogos.facebook,
  slack: ImagesResource.companiesLogos.slack,
};

export const DEMO_PRODUCT_ID = 13;

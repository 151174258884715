import yup from '../../../../../config/yup.config';
import {PARAMETERS_METADATA_KEY} from '../../../../../constants/parameters-saved-keys';
import {values} from 'lodash';
import {TableEntity} from '../../../../../objects/models/table.model';
import {queryElementValidatorFactory} from '../../../../../objects/dto/query-builder.dto';
import {TIME_UNIT_OPTIONS} from '../shared-validators';
import {ParameterType} from 'ui-components';
import {
  AnalysisParametersTransformer,
  baseAnalysisParametersTransformer,
  UNIT_TO_OCCURRENCE,
} from '../shared-transformers';

export const analysis69ParametersValidator = yup.object().shape({
  parameters: yup
    .object()
    .shape({
      [PARAMETERS_METADATA_KEY]: yup.object().nullable(),
      entity: yup.string().oneOf(values(TableEntity)).required(),
      actions: yup.array().of(queryElementValidatorFactory(true)).min(1),
      ref_date: queryElementValidatorFactory(true),
      cohort_granularity: yup.string().oneOf(TIME_UNIT_OPTIONS).required(),
      n_cohorts: yup.number().required(),
      bucket_granularity: yup.string().oneOf(TIME_UNIT_OPTIONS).required(),
      n_buckets: yup.number().required(),
      bucket_n_unit: yup.number().nullable(),
      is_custom_buckets: yup.boolean().nullable(),
      group_by_signal: yup.number().nullable(),
      population_filter: queryElementValidatorFactory(false),
      tags_to_use: yup.string().nullable(),
    })
    .required(),
  runParameters: yup.object().shape({
    end_date: yup.string().required(),
  }),
});

export const analysis69ParametersTransformer: AnalysisParametersTransformer = (
  values,
  schema,
  productEntities
) => {
  [values, schema] = baseAnalysisParametersTransformer(values, schema, productEntities);
  const newValues = {...values};

  if ('ref_date' in schema) {
    schema['ref_date'].name = 'Starting point';
    schema['ref_date'].order = 1;
  }
  if ('actions' in schema) {
    schema['actions'].name = 'Retention definition';
    schema['actions'].order = 2;
  }
  if ('cohort_granularity' in schema && 'n_cohorts' in schema) {
    schema['cohort_granularity'].order = 3;
    schema['cohort_granularity'].name = 'Cohorts';
    schema['cohort_granularity'].type = ParameterType.STRING;
    newValues['cohort_granularity'] = `${newValues['n_cohorts']} ${
      UNIT_TO_OCCURRENCE[newValues['cohort_granularity']]
    } cohorts`;
    delete schema['n_cohorts'];
  }
  if ('bucket_granularity' in schema && 'n_buckets' in schema) {
    schema['bucket_granularity'].order = 3;
    schema['bucket_granularity'].name = 'Buckets';
    schema['bucket_granularity'].type = ParameterType.STRING;
    newValues['bucket_granularity'] = `${newValues['n_buckets']} ${
      UNIT_TO_OCCURRENCE[newValues['bucket_granularity']]
    } buckets`;
    delete schema['n_buckets'];
  }
  if (newValues['is_custom_buckets'] !== true) {
    delete schema['bucket_n_unit'];
  }

  return [newValues, schema];
};

import * as React from 'react';
import {composition} from 'front-core';
import {OpportunitiesRouting} from './opportunities-routing.component';

interface OwnProps {}

type AllProps = OwnProps;

const OpportunitiesComponent: React.FC<AllProps> = (props: AllProps) => {
  return <OpportunitiesRouting />;
};

const Opportunities = composition<OwnProps>(OpportunitiesComponent);

export default Opportunities;

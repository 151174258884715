export enum ShareResourceType {
  ANALYSIS_RESULT = 'analysis_result',
  ANALYSIS_FOLDER = 'analysis_folder',
  OPPORTUNITY = 'opportunity',
  EXPERIMENT = 'experiment',
}

export enum ShareStrategyTypes {
  DEFAULT = 'default',
  DIRECT_SLACK = 'direct_slack',
}

export interface ShareResourceModel {
  modelId: number;
  type: ShareResourceType;
  recipients: number[];
  invitees: string[];
  message: string;
  strategyType: ShareStrategyTypes;
}

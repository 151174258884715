import {MetricValueType} from '../../../objects/models/metric.model';
import {TableEntity} from '../../../objects/models/table.model';
import {ChangeDescription, ChangeSentiment} from '../rca/objects';

export enum ModelSampleSentenceKeys {
  USERS_ONGOING_TREND = 'USERS_ONGOING_TREND',
  USERS_SIGNIFICANT_CHANGE = 'USERS_SIGNIFICANT_CHANGE',
  USERS_ALL_TIME_HIGH_OR_LOW = 'USERS_ALL_TIME_HIGH_OR_LOW',
  LOOPS_KPI_TREND = 'LOOPS_KPI_TREND',
  PERIOD_METRIC_ALL_TIME_HIGH_OR_LOW = 'PERIOD_METRIC_ALL_TIME_HIGH_OR_LOW',
}

interface SharedModelSampleInsight {
  key: ModelSampleSentenceKeys;
  metricName: string;
  metricValueType: MetricValueType;
  hasNegativeSample: boolean;
  isSignificant: boolean;
  isSignificantByLoops: boolean;
  allowInsightNumberOfUsersSentence: boolean;
  entity: TableEntity;
  valueBasedChangeSentiment: ChangeSentiment;
  denominatorBasedChangeSentiment: ChangeSentiment;
  valueBasedChangeDescription: ChangeDescription;
  denominatorBasedChangeDescription: ChangeDescription;
  valueBasedPercentageChangeBetweenLastAndPrevious: number;
  denominatorBasedPercentageChangeBetweenLastAndPrevious: number;
  lastSampleValue: number;
  lastSampleDenominator: number;
  lastSampleDate: string;
  lastSampleRcaResultId: number;
  previousSampleValue: number;
  previousSampleDate: string;
  previousSampleDenominator: number;
}

interface SharedSentenceParts {
  value: number;
}

// Trend insight sentence
interface TrendSentenceParts extends SharedSentenceParts {
  trendDescription: ChangeDescription;
  trendNumberOfPeriods: number;
  trendStartDate: string;
  changeDescription: ChangeDescription;
}

export interface TrendModelSampleInsight extends SharedModelSampleInsight {
  key: ModelSampleSentenceKeys;
  sentenceParts: TrendSentenceParts;
}

// Period All-Time High or Low sentence
interface PeriodAllTimeHighOrLowInsightSentenceParts extends SharedSentenceParts {
  valueBasedChangeDescription: ChangeDescription;
}

export interface PeriodAllTimeHighOrLowInsight extends SharedModelSampleInsight {
  key: ModelSampleSentenceKeys;
  sentenceParts: PeriodAllTimeHighOrLowInsightSentenceParts;
}

// Number of users insight sentence
export interface UsersAllTimeHighOrLowSentenceParts extends SharedSentenceParts {
  changeDescription: ChangeDescription;
  isMaximumInPeriod: boolean;
}

export interface UsersSignificantChangeSentenceParts extends SharedSentenceParts {
  changeDescription: ChangeDescription;
  lastSampleUserCount: number;
  previousSampleUserCount: number;
}

export interface UsersOngoingTrendSentenceParts extends SharedSentenceParts {
  changeDescription: ChangeDescription;
  lastSampleUserCount: number;
  previousSampleUserCount: number;
  trendNumberOfPeriods: number;
}

type NumberOfUsersSentenceParts =
  | UsersAllTimeHighOrLowSentenceParts
  | UsersSignificantChangeSentenceParts
  | UsersOngoingTrendSentenceParts;

export interface NumberOfUsersMetricSampleInsight extends SharedModelSampleInsight {
  sentenceKey: ModelSampleSentenceKeys;
  sentenceParts: NumberOfUsersSentenceParts;
}

export interface MetricSampleSentences {
  trendInsight?: TrendModelSampleInsight;
  periodAllTimeHighOrLowInsight?: PeriodAllTimeHighOrLowInsight;
  numberOfUsersInsight?: NumberOfUsersMetricSampleInsight;
}

export interface MetricSampleWoWOverview {
  metricName: string;
  metricValueType: MetricValueType;
  higherIsBetter: boolean;
  lastSampleValue: number;
  lastSampleExpectedValue: number;
  lastValueAndLastExpectedValueByLoopsChange: number;
  wowChangeValue: number;
  wowChangeDescription: ChangeDescription;
  isSignificantWoW: boolean;
  isSignificantLoopsAlgo: boolean;
}

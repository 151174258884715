import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../../../../../../constants/translation-keys';
import {UserSmartSelectorAvatar} from 'src/modules/shared/core/smart-selector/user-smart-selector-avatar/user-smart-selector-avatar.component';
import {Experiment} from '../../../../../../../../../objects/models/experiment.model';
import {ListRowItemCell} from '../../../../../../../../shared/components/general/list-row-item-cell/list-row-item-cell.component';

type ExperimentOwnerCellProps = {
  experiment: Experiment;
  onChangeOwner: (userId: number, experiment: Experiment) => void;
  className?: string;
};

export const ExperimentOwnerCell = (props: ExperimentOwnerCellProps) => {
  const {experiment, onChangeOwner, className} = props;
  const {t} = useTranslation();

  return (
    <ListRowItemCell
      title={t(TransKeys.EXPERIMENTS.OWNER_CELL.TITLE)}
      className={className}
      content={
        <>
          <UserSmartSelectorAvatar
            userName={experiment.ownerUserName}
            onChange={userId => onChangeOwner(userId, experiment)}
            clearText={t(TransKeys.EXPERIMENTS.ACTIONS.REMOVE_OWNER)}
            tooltipText={t(TransKeys.EXPERIMENTS.ACTIONS.SET_OWNER)}
            size={'small'}
          />
        </>
      }
    />
  );
};

import * as React from 'react';
import classes from './success-follow-up-panel.module.scss';
import {CloseRegularIcon, PartyHornIcon} from 'ui-components';

interface OwnProps {
  onClose: () => void;
  title: string;
  subTitle: string;
}

type AllProps = OwnProps;

const SuccessFollowUpPanel: React.FC<AllProps> = (props: AllProps) => {
  const {onClose, title, subTitle} = props;
  return (
    <div className={classes.Container}>
      <div className={classes.Header}>
        <div className={classes.Close} onClick={onClose}>
          <CloseRegularIcon className={classes.Icon} />
        </div>
      </div>
      <div className={classes.AnalysisRunningMessageContainer}>
        <PartyHornIcon className={classes.SuccessIcon} />
        <div className={classes.Title}>{title}</div>
        <div className={classes.SubTitle}>{subTitle}</div>
      </div>
    </div>
  );
};

export default SuccessFollowUpPanel;

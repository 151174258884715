import * as React from 'react';
import {useMemo} from 'react';
import {
  AnalysisFolder,
  AnalysisFolderType,
} from '../../../../../../objects/models/analysis-folder.model';
import {AnalysesViewHeader} from '../analyses-view-header/analyses-view-header.component';
import {useAnalysisFolderActions} from '../../../../hooks/use-analysis-folder-actions.hook';
import {AnalysesMainViews} from '../../analysis-main.types';
import {BaseAnalysesView} from './base-analyses-view.component';
import {EmptyState} from '../../../../../shared/components/general/override';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';

interface OwnProps {
  analysisFolder: AnalysisFolder;
  onFolderClicked: (analysisFolderId: number | string) => void;
  onCreateAnalysis: (parameters?: any) => void;
  isLoading?: boolean;
  className?: string;
}

type AllProps = OwnProps;

const SHOW_PARTICIPANTS_TYPES = [AnalysisFolderType.PUBLIC, AnalysisFolderType.SHARED];
const CUSTOM_EMPTY_STATE_TYPES = [AnalysesMainViews.ARCHIVED];

export const AnalysesFolderView: React.FC<AllProps> = (props: AllProps) => {
  const {analysisFolder, onFolderClicked, onCreateAnalysis, isLoading, className} = props;
  const analysisFolderActions = useAnalysisFolderActions();
  const {t} = useTranslation();

  const extraFilters = useMemo(() => {
    if (analysisFolder?.id === (AnalysesMainViews.SHARED as any)) {
      return {
        sharedWithUser: true,
      };
    }
    if (analysisFolder?.id === (AnalysesMainViews.FAVORITES as any)) {
      return {
        isExplored: true,
      };
    }
    if (analysisFolder?.id === (AnalysesMainViews.ARCHIVED as any)) {
      return {
        isDeleted: true,
        isNewest: undefined,
      };
    }
    return {
      parentAnalysisFolderId: analysisFolder?.id,
    };
  }, [analysisFolder?.id]);
  const customEmptyState = useMemo(() => {
    if (CUSTOM_EMPTY_STATE_TYPES.indexOf(analysisFolder?.id as any) > -1) {
      return (
        <EmptyState
          title={t(TransKeys.ANALYSES.EMPTY_STATE.TITLE)}
          subTitle={t(TransKeys.ANALYSES.EMPTY_STATE.SUB_TITLE)}
        />
      );
    }
  }, [analysisFolder?.id, t]);

  return (
    <BaseAnalysesView
      key_={analysisFolder?.id}
      isPrivateFolder={analysisFolder?.actualType === AnalysisFolderType.PRIVATE}
      className={className}
      isLoading={isLoading}
      extraFilters={extraFilters}
      children={analysisFolder?.children}
      onFolderClicked={onFolderClicked}
      onCreateAnalysis={onCreateAnalysis}
      showParticipants={SHOW_PARTICIPANTS_TYPES.indexOf(analysisFolder?.actualType) > -1}
      customEmptyState={customEmptyState}
      header={
        analysisFolder ? (
          <AnalysesViewHeader
            analysisFolder={analysisFolder}
            onParentClicked={onFolderClicked}
            {...analysisFolderActions}
          />
        ) : null
      }
    />
  );
};

import {RequestType, toCamelCase} from 'front-core';
import {NRC} from './index';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';

export const multiLoaderNetworkRequest: NRC<void, any> = (payload: {[key: string]: number[]}) => ({
  method: RequestType.GET,
  relativeUrl: `/app/multi-loader`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data, ['tags.*']),
});

import {BaseModel} from './base.model';
import {UserBasicInfo} from './user.model';
import {Team} from './team.model';

export enum AnalysisFolderType {
  PUBLIC = 'public',
  PRIVATE = 'private',
  SHARED = 'shared',
}

export interface AnalysisFolder extends BaseModel {
  id: number;
  productId?: number;
  name: string;
  shortDescription?: string;
  parentId?: number;
  isRoot: boolean;
  isShared: boolean;
  isExplored: boolean;
  isPrivateOther: boolean;
  team?: Team;
  icon?: string;
  type: AnalysisFolderType;
  actualType: AnalysisFolderType;
  isAutoGenerated?: boolean;
  userId: number;
  participants: Partial<UserBasicInfo>[];
  children: Partial<AnalysisFolder>[];
  numberOfChildren?: number;
  parents: Partial<AnalysisFolder>[];
  deletedOn: string;
}

import * as yup from 'yup';
import {SchemaOf} from 'yup';

export interface SignUpDto {
  firstName: string;
  lastName: string;
  companyName: string;
  jobTitle: string;
  email: string;
  otherJobTitle?: string;
}

export const signUpDTOValidator: SchemaOf<SignUpDto> = yup.object().shape({
  firstName: yup.string().min(2).required(),
  lastName: yup.string().min(2).required(),
  companyName: yup.string().min(2).required(),
  jobTitle: yup.string().min(2).required(),
  email: yup.string().email().required(),
  otherJobTitle: yup.string().min(2).nullable(),
});

import * as React from 'react';
import {PanelLayout} from '../../modules/shared/components/layout/panel-layout/panel-layout.component';

export interface WithPanelInjectedProps {
  onClose?: (e) => void;
}

export function withPanel<P>(
  WrappedComponent: React.ComponentType<P & WithPanelInjectedProps>
): React.ComponentType<P & WithPanelInjectedProps> {
  const WithPanel: React.FC<any> = (props: P & WithPanelInjectedProps) => {
    return (
      <PanelLayout {...props}>
        <WrappedComponent {...props} />
      </PanelLayout>
    );
  };
  return WithPanel;
}

import * as React from 'react';
import {useMemo} from 'react';
import {COMPANIES_LOGOS} from '../../../../constants/ui';
import classes from './also-did-this.module.scss';
import {Title} from '../../../shared/components/general/title/title.component';
import TransKeys from '../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'front-core';
import classNames from 'classnames';

interface OwnProps {
  companies: string[];
  split?: boolean;
}

type AllProps = OwnProps;

export const AlsoDidThis: React.FC<AllProps> = (props: AllProps) => {
  // Props
  const {companies, split} = props;
  const {t} = useTranslation();
  // Computed
  const companyNames = useMemo(
    () => companies.map(c => capitalize(c.replace('_', ' '))),
    [companies]
  );
  const icons = useMemo(
    () => companies.map(c => c.replace(' ', '_').toLowerCase()).map(c => COMPANIES_LOGOS[c]),
    [companies]
  );

  return (
    <div className={classNames(classes.AlsoDidThis, split && classes.Split)}>
      {companies.length > 0 && (
        <Title className={classes.Label} text={t(TransKeys.ACTIONS.ALSO_DID_THIS)} size={'small'} />
      )}
      <div className={classes.Companies}>
        {companyNames.map((company, idx) => (
          <div key={`${company}_${idx}`} className={classes.Company}>
            {icons[idx] && (
              <div className={classes.Logo}>
                <img src={icons[idx]} alt={''} />
              </div>
            )}
            <div className={classes.Name}>{company}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

import {FC, useCallback, useContext, useMemo, useRef, useState} from 'react';
import {
  MultiLoadResponse,
  SmartSelectorParameters,
  SmartSelectorSource,
  SmartSelector,
} from 'ui-components';
import {exists, HttpClientContext} from 'front-core';
import {
  funnelSmartSelectorNetworkRequest,
  metricSmartSelectorNetworkRequest,
} from '../../../../http/smart-selector.network-requests';
import classes from './smart-selector.module.scss';
import {FunnelPreview} from './components/previews/funnel-preview.component';
import {SmartSelectorLayout} from './smart-selector-layout.component';
import {SignalPreview} from './components/previews/signal-preview.component';
import {getMetricTypeName} from '../../../../constants/options';
import {MetricType} from '../../../../objects/models/metric.model';
import {EditableSmartSelector, SmartSelectorSharedProps} from './smart-selector.shared';

const ALLOWED_METRIC_TYPES = [
  MetricType.CONVERSION,
  MetricType.RETENTION,
  MetricType.L7,
  MetricType.L28,
  MetricType.DAU,
  MetricType.WAU,
  MetricType.MAU,
  MetricType.USAGE,
];

interface OwnProps extends SmartSelectorSharedProps, EditableSmartSelector {}

type AllProps = OwnProps;

export const AnalysisTypeWizardSmartSelector: FC<AllProps> = (props: AllProps) => {
  const {
    value,
    placeholder,
    onChange: onChangeFromProps,
    className,
    disabled,
    onSignalInfo,
    error,
    fullWidth,
    emptyState,
  } = props;
  const selectorRef = useRef<any>(null);
  const http = useContext(HttpClientContext);
  const [item, setItem] = useState<any>(null);
  const labelValue = useMemo(() => item?.name, [item]);

  const onChange = useCallback(
    (value: number, item?: any) => {
      selectorRef.current.close();
      setItem(item);
      onChangeFromProps(value, item);
    },
    [onChangeFromProps]
  );

  const sources: SmartSelectorSource[] = useMemo(
    () => [
      {
        key: 'metrics',
        name: `KPIs`,
        onSelect: item => onChange(item.id, item),
        renderItemPreview: item => (
          <SignalPreview
            label={'KPI'}
            data={{
              ...item,
              type: getMetricTypeName(item.type),
            }}
            onInfo={() => onSignalInfo(item.signalId)}
          />
        ),
      },
      {
        key: 'funnels',
        name: `Funnels`,
        onSelect: item => onChange(item.id, item),
        renderItemPreview: item => (
          <FunnelPreview label={'Funnel'} data={item} onSignalInfo={onSignalInfo} />
        ),
      },
    ],
    [onChange, onSignalInfo]
  );

  const load = useCallback(
    async (_keys: string[], parameters: SmartSelectorParameters): Promise<MultiLoadResponse> => {
      const metricPromise = http.exec(
        metricSmartSelectorNetworkRequest({
          page: parameters.page,
          q: parameters.query,
          limit: 10,
          orderBy: 'id',
          order: 'asc',
          type: ALLOWED_METRIC_TYPES,
        })
      );

      const funnelPromise = http.exec(
        funnelSmartSelectorNetworkRequest({
          page: parameters.page,
          q: parameters.query,
          limit: 10,
          orderBy: 'id',
          order: 'asc',
        })
      );

      const [metrics, funnels] = await Promise.all([metricPromise, funnelPromise]);

      return {
        metrics: metrics as any,
        funnels: funnels as any,
      };
    },
    [http]
  );

  return (
    <SmartSelectorLayout
      id={'anaylsis-type-wizard-smart-selector'}
      label={labelValue}
      disabled={disabled}
      error={error}
      clearable={exists(value) && true}
      onClear={() => onChange(null)}
      placeholder={placeholder}
      className={className}
      ref={selectorRef}
      fullWidth={fullWidth}
    >
      <SmartSelector
        className={classes.Selector}
        sources={sources}
        load={load}
        emptyState={emptyState}
      />
    </SmartSelectorLayout>
  );
};

AnalysisTypeWizardSmartSelector.defaultProps = {};

import {action, OnSuccessActionHook} from 'front-core';
import {MetricDTO, SetMetricOwnerDTO, UpdateTeamMetricsDTO} from '../../objects/dto/metric.dto';
import {showConfirmationMessage} from '../interface/interface.actions';
import i18n from '../../config/i18n.config';
import TransKeys from '../../constants/translation-keys';

export enum MetricsActionType {
  CREATE_METRIC = '@@metrics/CREATE_METRIC',
  UPDATE_METRIC = '@@metrics/UPDATE_METRIC',
  DELETE_METRIC = '@@metrics/DELETE_METRIC',
  PUBLISH_AD_HOC_METRIC = '@@metrics/PUBLISH_AD_HOC_METRIC',
  SET_METRIC_OWNER = '@@metrics/SET_METRIC_OWNER',
  SET_METRIC_NORTH_STAR = '@@metrics/SET_METRIC_NORTH_STAR',
  RESAMPLE_METRIC = '@@metrics/RESAMPLE_METRIC',
  VALIDATE_METRIC = '@@metrics/VALIDATE_METRIC',
  UPDATE_TEAM_METRICS = '@@metrics/UPDATE_TEAM_METRICS',
  ADD_METRIC_TO_TEAM = '@@metrics/ADD_METRIC_TO_TEAM',
}

export const createMetric = (data: MetricDTO, onSuccess?: OnSuccessActionHook) =>
  action(MetricsActionType.CREATE_METRIC, data, {onSuccess});

export const updateMetric = (data: MetricDTO, onSuccess?: OnSuccessActionHook) =>
  action(MetricsActionType.UPDATE_METRIC, data, {onSuccess});

export const deleteMetric = (id: number) => action(MetricsActionType.DELETE_METRIC, id);

export const deleteMetricConfirmed = (id: number) =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.DELETE_METRIC.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.DELETE_METRIC.CONTENT),
    },
    () => [deleteMetric(id)]
  );

export const publishAdHocMetric = (id: number, onSuccess?: OnSuccessActionHook) =>
  action(MetricsActionType.PUBLISH_AD_HOC_METRIC, id, {onSuccess});

export const setMetricNorthStar = (id: number, onSuccess?: OnSuccessActionHook) =>
  action(MetricsActionType.SET_METRIC_NORTH_STAR, id, {onSuccess});

export const setMetricOwner = (data: SetMetricOwnerDTO, onSuccess?: OnSuccessActionHook) =>
  action(MetricsActionType.SET_METRIC_OWNER, data, {onSuccess});

export const publishAdHocMetricConfirmed = (id: number, onSuccess?: OnSuccessActionHook) =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.PUBLISH_AD_HOC_METRIC.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.PUBLISH_AD_HOC_METRIC.CONTENT),
    },
    () => [publishAdHocMetric(id, onSuccess)]
  );

export const resampleMetricConfirmed = (metricId: number, onSuccess?: OnSuccessActionHook) =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.RESMAPLE_MODEL.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.RESMAPLE_MODEL.CONTENT),
    },
    () => [resampleMetric(metricId, onSuccess)]
  );

export const resampleMetric = (metricId: number, onSuccess?: OnSuccessActionHook) =>
  action(MetricsActionType.RESAMPLE_METRIC, metricId, {onSuccess});

export const validateMetric = (metricId: number, onSuccess?: OnSuccessActionHook) =>
  action(MetricsActionType.VALIDATE_METRIC, metricId, {onSuccess});

export const updateTeamMetrics = (data: UpdateTeamMetricsDTO, onSuccess?: OnSuccessActionHook) =>
  action(MetricsActionType.UPDATE_TEAM_METRICS, data, {onSuccess});

export const addMetricToTeam = (metricId: number, teamId: number) =>
  action(MetricsActionType.ADD_METRIC_TO_TEAM, {metricId, teamId});

export const METRIC_SERIES_MOCK = [
  {
    id: 0,
    name: 'auxiliary retention',
    unit: 'PERCENTAGE',
    samples: [
      {
        value: 34,
        expectedUpper: 42.5,
        expectedLower: 23.12,
      },
      {
        value: 40,
        expectedUpper: 42.400000000000006,
        expectedLower: 3.5999999999999996,
      },
      {
        value: 50,
        expectedUpper: 100,
        expectedLower: 15,
      },
      {
        value: 41,
        expectedUpper: 43.870000000000005,
        expectedLower: 25.830000000000002,
      },
      {
        value: -30,
        expectedUpper: -60,
        expectedLower: -26.1,
      },
    ],
  },
  {
    id: 1,
    name: 'multi-byte conversion',
    samples: [
      {
        value: -18,
        expectedUpper: -25.020000000000003,
        expectedLower: -2.16,
      },
      {
        value: 7,
        expectedUpper: 8.82,
        expectedLower: 4.34,
      },
      {
        value: 32,
        expectedUpper: 34.56,
        expectedLower: 4.48,
      },
      {
        value: -43,
        expectedUpper: -73.53,
        expectedLower: -19.35,
      },
      {
        value: 11,
        expectedUpper: 20.24,
        expectedLower: 6.050000000000001,
      },
    ],
  },
  {
    id: 2,
    name: 'online total count',
    unit: 'PERCENTAGE',
    samples: [
      {
        value: -25,
        expectedUpper: -30,
        expectedLower: -4.5,
      },
      {
        value: 19,
        expectedUpper: 24.89,
        expectedLower: 8.36,
      },
      {
        value: 17,
        expectedUpper: 21.08,
        expectedLower: 13.94,
      },
      {
        value: 17,
        expectedUpper: 29.58,
        expectedLower: 13.26,
      },
      {
        value: 49,
        expectedUpper: 85.75,
        expectedLower: 25.970000000000002,
      },
    ],
  },
  {
    id: 3,
    name: 'open-source retention',
    samples: [
      {
        value: -15,
        expectedUpper: -28.650000000000002,
        expectedLower: -14.7,
      },
      {
        value: 5,
        expectedUpper: 5.550000000000001,
        expectedLower: 4.65,
      },
      {
        value: -33,
        expectedUpper: -52.800000000000004,
        expectedLower: -12.870000000000001,
      },
      {
        value: -14,
        expectedUpper: -26.32,
        expectedLower: -6.859999999999999,
      },
      {
        value: -12,
        expectedUpper: -19.56,
        expectedLower: -10.32,
      },
    ],
  },
  {
    id: 4,
    name: 'wireless conversion',
    unit: 'PERCENTAGE',
    samples: [
      {
        value: 33,
        expectedUpper: 63.36,
        expectedLower: 31.68,
      },
      {
        value: -33,
        expectedUpper: -63.03,
        expectedLower: -6.2700000000000005,
      },
      {
        value: 6,
        expectedUpper: 9.36,
        expectedLower: 0,
      },
      {
        value: -33,
        expectedUpper: -44.879999999999995,
        expectedLower: -9.24,
      },
      {
        value: -2,
        expectedUpper: -2.7,
        expectedLower: -0.74,
      },
    ],
  },
];

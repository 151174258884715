import * as yup from 'yup';
import {SchemaOf} from 'yup';

export interface LoginDTO {
  email: string;
  password: string;
  redirectTo?: string;
}

export const loginDTOValidator: SchemaOf<LoginDTO> = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(6).required(),
  redirectTo: yup.string().nullable(),
});

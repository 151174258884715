import {useContext, useMemo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {
  CrumbNavItem,
  PageHeader,
} from '../../../shared/components/layout/page-header/page-header.component';
import TransKeys from '../../../../constants/translation-keys';
import {ANALYSIS_RESULT_ID_PATH_PARAM, AppRoutes} from '../../../../constants/app-routes';
import {
  ArchiveIcon,
  Button,
  Chip,
  ChipVariant,
  ClockIcon,
  EditIcon,
  HistoryIcon,
  IconButton,
  MoreIcon,
  RenameIcon,
  RerunIcon,
  ShareIcon,
  StarLightIcon,
  StarSolidIcon,
  StopIcon,
  ToggleIconButton,
  usePendoAnchor,
  PendoAnchors,
  CodeLightIcon,
  FileCodeLightIcon,
  InteractionContext,
  InteractionType,
} from 'ui-components';
import {AnalysisResult} from '../../../../objects/models/analysis-result.model';
import {AnalysisParameters} from '../analysis-parameters/analysis-parameters.component';
import {Parameter} from '../../../shared/components/general/parameter/parameter.component';
import {get, isEmpty} from 'lodash';
import {formatDate} from '../../../../utils/general.utils';
import {AppSources} from '../../../../constants/app-sources';
import {isAnalysisResultProcessing} from '../../../../utils/analysis-result.utils';
import {AnalysisResultActions} from '../../hooks/use-analysis-result-actions.hook';
import {useCurrentUser} from '../../../../core/hooks/use-user.hook';
import {exists} from 'front-core';
import {PanelKey} from '../../../../constants/panels';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {useIsAdmin} from '../../../../core/hooks/use-is-admin.hook';
import {AnalysisArtifacts} from '../analysis-artifacts/analysis-artifacts.component';
import {AnalysisTypeId} from '../../../../constants/analysis-type-id';
import {viewAnalysisResultNotebook} from '../../../../http/analysis-results.network-requests';
import {useFeatureIsOn} from '@growthbook/growthbook-react';
import {FeatureFlag} from '../../../../constants/feature-flags';
import {ModelActionsDropdown} from '../../../shared/core/model-actions-dropdown/model-actions-dropdown.component';

interface OwnProps extends Partial<AnalysisResultActions> {
  analysisResult: AnalysisResult;
  docTitle?: string;
  versionsCount: number;
  onVersions: () => void;
  disableEditing?: boolean;
}

type AllProps = OwnProps;

export const AnalysisHeader: React.FC<AllProps> = (props: AllProps) => {
  const {
    analysisResult = {} as any,
    docTitle,
    versionsCount,
    onDuplicate,
    onShare,
    onStop,
    onEdit,
    disableEditing,
    onDelete,
    onExplore,
    onRename,
    onSchedule,
    onVersions,
    onDownloadArtifact,
    onRerun,
    onChangeFolder,
  } = props;
  const {t} = useTranslation();
  const shareAnalysisRef = usePendoAnchor(PendoAnchors.SHARE_ANALYSIS_BTN);
  const user = useCurrentUser();
  const isAdmin = useIsAdmin();
  const exposeArtifacts = useFeatureIsOn(FeatureFlag.EXPOSE_ARTIFACTS as string);
  const {postMessage} = useContext(InteractionContext);
  const {openSecondaryPanel} = useContext(PanelsContext);
  const isOwner = useMemo(
    () => analysisResult.requestedByUserId === user.id,
    [analysisResult, user]
  );
  const analysisTitle = useMemo(
    () => analysisResult.userAnalysisName || analysisResult.analysisName,
    [analysisResult]
  );
  const crumbs: CrumbNavItem[] = useMemo(() => {
    const arr = [
      {
        name: t(TransKeys.ANALYSES.HEADER.TITLE),
        navigateTo: AppRoutes.analyses(),
      },
    ];
    if (docTitle) {
      arr.push({
        name: analysisTitle,
        navigateTo: AppRoutes.viewAnalysis(analysisResult.analysisId, {
          [ANALYSIS_RESULT_ID_PATH_PARAM]: analysisResult.id,
        }),
      });
    }
    return arr;
  }, [t, docTitle, analysisTitle, analysisResult]);
  const startDate = useMemo(
    () => formatDate(get(analysisResult, 'runParameters.start_date')),
    [analysisResult]
  );
  const endDate = useMemo(
    () => formatDate(get(analysisResult, 'runParameters.end_date')),
    [analysisResult]
  );
  const dates = useMemo(() => {
    if (startDate && endDate) {
      return `${startDate} - ${endDate}`;
    }
    return startDate || endDate;
  }, [endDate, startDate]);
  const anomalyStartDate = useMemo(
    () => formatDate(get(analysisResult, 'runParameters.start_date_anomaly')),
    [analysisResult]
  );

  const isDeprecated = useMemo(
    () => Boolean(analysisResult.deprecatedByUser?.fullName),
    [analysisResult]
  );
  const isDeprecatedHelperText = useMemo(
    () =>
      isDeprecated
        ? {
            helperText: t(TransKeys.ANALYSIS_RESULT.DEPRECATION_TOOLTIP_TITLE, {
              fullName: analysisResult.deprecatedByUser.fullName,
            }),
          }
        : {},
    [analysisResult, t, isDeprecated]
  );

  const viewSqlQueriesProps = useMemo(() => {
    const disabled = Boolean(analysisResult?.executedSqlsCount) === false;

    return {
      tooltipText: disabled
        ? t(TransKeys.ANALYSIS_RESULT.EXECUTED_SQL_QUERIES.NOT_AVAILABLE)
        : t(TransKeys.GENERAL.ACTIONS.VIEW_QUERIES),
      icon: CodeLightIcon,
      onClick: () =>
        analysisResult?.executedSqlsCount &&
        openSecondaryPanel(PanelKey.VIEW_ANALYSIS_EXECUTED_SQLS_PANEL, {
          [ANALYSIS_RESULT_ID_PATH_PARAM]: analysisResult?.id,
        }),
      size: 'large' as any,
      disabled,
    };
  }, [analysisResult, t, openSecondaryPanel]);
  const showArtifacts = useMemo(() => {
    if (!exists(analysisResult.artifacts) || analysisResult.artifacts?.length === 0) {
      return false;
    }
    if (isAdmin) {
      return true;
    }
    return exposeArtifacts && analysisResult.analysisTypeId === AnalysisTypeId.GOAL_DRIVERS;
  }, [isAdmin, analysisResult, exposeArtifacts]);
  const onKpiClick = useCallback(() => {
    postMessage({
      type: InteractionType.REFERENCE,
      payload: {
        modelId: analysisResult.analysisGoal.modelId,
        modelType: analysisResult.analysisGoal.modelType.toUpperCase(),
      },
    });
  }, [analysisResult, postMessage]);
  const onViewNotebook = id => window.open(viewAnalysisResultNotebook(id), '_blank');

  return (
    <PageHeader
      title={docTitle || analysisTitle}
      description={docTitle ? null : analysisResult.userNotes}
      crumbs={crumbs}
      actions={
        <>
          {analysisResult.deletedAt === null && (
            <>
              <ToggleIconButton
                titleChecked={t(TransKeys.GENERAL.ACTIONS.UNEXPLORE)}
                titleUnchecked={t(TransKeys.GENERAL.ACTIONS.EXPLORE)}
                onChange={() => onExplore(analysisResult)}
                isChecked={analysisResult.isExplored}
                icon={StarLightIcon}
                checkedIcon={StarSolidIcon}
                size={'large'}
              />
              <IconButton {...viewSqlQueriesProps} />
              <IconButton
                ref={shareAnalysisRef}
                tooltipText={t(TransKeys.GENERAL.ACTIONS.SHARE)}
                icon={ShareIcon}
                onClick={() => onShare(analysisResult)}
                size={'large'}
              />
            </>
          )}
          <ModelActionsDropdown
            iconSize={'large'}
            actions={[
              {
                key: 'rename',
                title: t(TransKeys.GENERAL.ACTIONS.RENAME),
                onClick: _ => onRename(analysisResult),
                icon: RenameIcon,
                disabled: !isOwner || analysisResult.isExample,
                hide: analysisResult.deletedAt !== null,
                showEnabled: true,
              },
              {
                key: 'stop',
                title: t(TransKeys.GENERAL.ACTIONS.STOP),
                onClick: _ => onStop(analysisResult),
                icon: StopIcon,
                hide:
                  !isAnalysisResultProcessing(analysisResult, true) ||
                  analysisResult.deletedAt !== null,
                disabled: !isOwner || isDeprecated,
                ...isDeprecatedHelperText,
              },
              {
                key: 'edit',
                title: t(TransKeys.GENERAL.ACTIONS.EDIT),
                onClick: _ => onEdit(analysisResult),
                icon: EditIcon,
                disabled: disableEditing || !isOwner || isDeprecated,
                hide: analysisResult.deletedAt !== null,
                ...isDeprecatedHelperText,
                showEnabled: true,
              },
              {
                key: 'archive',
                title:
                  analysisResult.deletedAt === null
                    ? t(TransKeys.GENERAL.ACTIONS.ARCHIVE)
                    : t(TransKeys.GENERAL.ACTIONS.UNARCHIVE),
                onClick: _ => onDelete(analysisResult),
                icon: ArchiveIcon,
                disabled: !isOwner,
              },
              {
                key: 'schedule',
                title: !Boolean(analysisResult.cronExp)
                  ? t(TransKeys.GENERAL.ACTIONS.SCHEDULE)
                  : t(TransKeys.GENERAL.ACTIONS.EDIT_SCHEDULE),
                onClick: _ => onSchedule(analysisResult),
                icon: ClockIcon,
                disabled: !isOwner || isDeprecated || analysisResult.isExample,
                ...isDeprecatedHelperText,
                hide: analysisResult.deletedAt !== null,
                showEnabled: true,
              },
              {
                key: 'history',
                title: t(TransKeys.GENERAL.ACTIONS.RUN_HISTORY, {count: versionsCount}),
                onClick: _ => onVersions(),
                icon: HistoryIcon,
                hide: analysisResult.deletedAt !== null || versionsCount <= 1,
              },
              {
                key: 'rerun',
                title: t(TransKeys.GENERAL.ACTIONS.RERUN),
                onClick: _ => onRerun(analysisResult),
                icon: RerunIcon,
                disabled: isDeprecated || analysisResult.isExample,
                ...isDeprecatedHelperText,
                hide: analysisResult.deletedAt !== null,
              },
              {
                key: 'changeFolder',
                title: t(TransKeys.GENERAL.ACTIONS.MOVE_TO_FOLDER),
                onClick: _ => onChangeFolder(analysisResult),
                icon: RenameIcon,
                hide: analysisResult.deletedAt !== null,
                showEnabled: true,
              },
              {
                key: 'notebook',
                title: 'View notebook (admins only)',
                onClick: _ => onViewNotebook(analysisResult.id),
                icon: FileCodeLightIcon,
                hide: !isAdmin,
              },
            ]}
            label={t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
            icon={MoreIcon}
            iconDropdown
          />
          {onDuplicate && analysisResult.deletedAt === null && (
            <Button onClick={onDuplicate} size={'large'} variant={'outlined'}>
              {t(TransKeys.GENERAL.ACTIONS.DUPLICATE_ANALYSIS)}
            </Button>
          )}
        </>
      }
      subHeaderItems={[
        // analysisResult?.isCausal && (
        //   <Chip
        //     label={t(TransKeys.GENERAL.LABELS.CAUSAL)}
        //     icon={SparklesSolidIcon}
        //     variant={ChipVariant.CAUSAL}
        //   />
        // ),
        isDeprecated && (
          <Chip
            variant={ChipVariant.WARN}
            label={t(TransKeys.GENERAL.LABELS.DEPRECATED)}
            helperText={isDeprecatedHelperText.helperText}
          />
        ),
        exists(analysisResult.deletedAt) && (
          <Chip variant={ChipVariant.WARN} label={t(TransKeys.GENERAL.LABELS.ARCHIVED)} />
        ),
        <Parameter
          label={t(TransKeys.GENERAL.LABELS.TYPE)}
          value={analysisResult.analysisTypeName}
        />,
        exists(analysisResult.analysisGoal?.name) && (
          <Parameter
            label={t(TransKeys.GENERAL.LABELS.KPI)}
            value={analysisResult.analysisGoal.name}
            onClick={onKpiClick}
          />
        ),
        dates && <Parameter label={t(TransKeys.GENERAL.LABELS.TIME_FRAME)} value={dates} />,
        anomalyStartDate && (
          <Parameter
            label={t(TransKeys.GENERAL.LABELS.ANOMALY_START_DATE)}
            value={anomalyStartDate}
          />
        ),
        !isEmpty(analysisResult) && (
          <AnalysisParameters
            label={t(TransKeys.GENERAL.LABELS.PARAMETERS)}
            analysisResultId={analysisResult.id}
            appSource={AppSources.VIEW_ANALYSIS_RESULT__VIEW_PARAMETERS}
            border={false}
          />
        ),
        showArtifacts && (
          <AnalysisArtifacts
            onDownloadArtifact={artifactId => onDownloadArtifact(analysisResult, artifactId)}
            artifacts={analysisResult.artifacts}
          />
        ),
      ]}
    />
  );
};

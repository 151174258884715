import {Phenomena} from './phenomena.model';

export enum MonitoredMetricType {
  KPI = 'kpi',
  FUNNEL = 'funnel',
  RETENTION = 'retention',
  FEATURE = 'feature',
  CONTENT = 'content',
  SEGMENT = 'segment',
}

export enum SegmentMetricType {
  NEW_USERS = 'NEW_USERS',
  SHARE_NEW_USERS = 'SHARE_NEW_USERS',
  ACTIVE_USERS = 'ACTIVE_USERS',
  SHARE_ACTIVE_USERS = 'SHARE_ACTIVE_USERS',
}

export enum MetricSeriesSeriesType {
  RATE = 'RATE',
  TIME_BETWEEN = 'TIME_BETWEEN',
  NUMBER = 'NUMBER',
  PROBABILITY_TO_WIN = 'PROBABILITY_TO_WIN',
  PROBABILITY_TO_WIN_VS_ALL = 'PROBABILITY_TO_WIN_VS_ALL',
  IMPROVEMENT = 'IMPROVEMENT',
  IMPROVEMENT_VS_ALL = 'IMPROVEMENT_VS_ALL',
}

export interface MetricSeriesSample {
  value: number;
  date: string;
  expectedUpper: number;
  expectedLower: number;
  weight: number;
  createdOn: string;
}

export interface MonitoredMetric {
  id: number;
  metricSeriesId: number;
  analysisId: number;
  analysisContext?: any;
  productId: number;
  name: string;
  shortDescription?: string;
  metricType: MonitoredMetricType;
  type: string;
  xType: string;
  cadence: 'DAY' | 'WEEK' | 'MONTH';
  weightUnit: string;
  unit: string;
  unitParameters: any;
  higherIsBetter: boolean;
  samples: MetricSeriesSample[];
  phenomenas: Partial<Phenomena>[];
  phenomenasCount: number;
  rootDocumentId: string;
  relatedAnalysisResultId: number;
  order?: number;
  isNorthStar?: boolean;
  createdOn: string;
  updatedOn: string;
}

export interface HealthMonitorGroup {
  id: number;
  name: string;
  order: number;
  isMain: boolean;
  series: MonitoredMetric[];
}

export interface HealthMonitorModel {
  id: number;
  productId: number;
  userId: number;
  totalMetrics: number;
  initialOptionsCount: number;
  lastUpdated: string;
  toDate: string;
  groups: HealthMonitorGroup[];
  cronExp?: string;
}

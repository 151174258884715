import * as React from 'react';
import {composition} from 'front-core';
import {
  withPartialDocument,
  WithPartialDocumentInjectedProps,
} from '../../../../../../shared/core/document-viewer/with-partial-document-hoc/with-partial-document.hoc';
import {
  DocumentElementType,
  TreatmentAdoptionSimulationModeSwitchWrapperFigure,
  TreatmentAdoptionSimulationItemType,
  TreatmentAdoptionSimulationFigure,
} from 'ui-components';
import {useMemo} from 'react';
import {TreatmentAdoption} from './treatment-adoption.component';

interface OwnProps extends WithPartialDocumentInjectedProps {
  className?: string;
}

type AllProps = OwnProps;

const GoalDriversComponent: React.FC<AllProps> = (props: AllProps) => {
  const {analysisResult, elementData, className} = props;
  let {[DocumentElementType.TREATMENT_ADOPTION_SIMULATION_MODE_SWITCH_WRAPPER_FIGURE]: data} =
    elementData as any;
  const wrapper = data as TreatmentAdoptionSimulationModeSwitchWrapperFigure;
  const {causation, correlation} = wrapper?.data || {};

  const drivers: TreatmentAdoptionSimulationFigure = useMemo(() => {
    if (!wrapper) {
      return null;
    }
    const items = causation.data.items;
    const showCorrelation =
      items.length === 0 ||
      items.filter(item => item.type === TreatmentAdoptionSimulationItemType.POSITIVE).length === 0;
    return showCorrelation ? correlation : causation;
  }, [causation, correlation, wrapper]);

  if (!wrapper) {
    return null;
  }

  return (
    <TreatmentAdoption feature={drivers} analysisResult={analysisResult} className={className} />
  );
};

export const GoalDrivers = composition<AllProps>(
  GoalDriversComponent,
  withPartialDocument({
    elementType: [DocumentElementType.TREATMENT_ADOPTION_SIMULATION_MODE_SWITCH_WRAPPER_FIGURE],
  })
);

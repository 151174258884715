import {BaseModel} from './base.model';
import {TableEntity} from './table.model';
import {SignalDependency} from './signal-dependency.model';

export enum LiteralType {
  INTEGER = 'integer',
  FLOAT = 'float',
  STRING = 'string',
  BOOLEAN = 'boolean',
  DATE = 'date',
}

export interface TableColumn extends BaseModel {
  productId: number;
  tableId: number;
  tableEntity: TableEntity;
  tableDefinition: any;
  name: string;
  dataType: string;
  specialStatus?: string;
  literalType: LiteralType;
  deprecated: boolean;
  dependencies?: SignalDependency[];
}

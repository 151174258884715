import * as React from 'react';
import classNames from 'classnames';
import classes from './user-kpi-step.module.scss';
import yup from '../../../../../../config/yup.config';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {StepTitle} from '../../components/step-title/step-title.component';
import {Button} from 'ui-components';
import {KPI_TYPES_OPTIONS} from '../consts';

interface FormData {
  userKPIS: string[];
}

interface OwnProps {
  data: FormData;
  onChange: (value: FormData) => void;
  className?: string;
}

export const userKPIStepValidations = yup.object().shape({
  userKPIS: yup.array().of(yup.string()).min(1).max(3).required(),
});

type AllProps = OwnProps;

export const UserKPIStep: React.FC<AllProps> = (props: AllProps) => {
  const {data, onChange, className} = props;
  const {t} = useTranslation();

  const {
    handleSubmit,
    watch,
    setValue,
    formState: {isValid, errors},
  } = useForm({
    defaultValues: {
      userKPIS: [],
      ...data,
    },
    resolver: yupResolver(userKPIStepValidations.noUnknown()),
  });
  const onSubmit = useCallback(data => onChange(data), [onChange]);
  const userKPIS = watch('userKPIS');
  const onOptionClicked = useCallback(
    (option: string) => {
      const set = new Set(userKPIS);
      if (set.has(option)) {
        set.delete(option);
      } else {
        set.add(option);
      }
      setValue('userKPIS', Array.from(set), {shouldValidate: true});
    },
    [userKPIS, setValue]
  );

  return (
    <div className={classNames(classes.UserKPIStep, className)}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.Form}>
        <StepTitle
          title={t(TransKeys.QUESTIONNAIRE.KPIS_STEP.TITLE)}
          subTitle={t(TransKeys.QUESTIONNAIRE.KPIS_STEP.SUB_TITLE)}
        />
        {Boolean(errors?.userKPIS) && (
          <div className={classes.Error}>{t(TransKeys.QUESTIONNAIRE.KPIS_STEP.ERROR)}</div>
        )}
        <div className={classes.Options}>
          {KPI_TYPES_OPTIONS.map((o, idx) => (
            <div
              key={o.value}
              onClick={() => onOptionClicked(o.value)}
              className={classNames(
                classes.Option,
                o.className,
                userKPIS.indexOf(o.value) > -1 && classes.Selected
              )}
            >
              <o.icon className={classes.Icon} />
              <div className={classes.Label}>{t(o.label)}</div>
            </div>
          ))}
        </div>
        <div className={classes.Spacer} />
        <Button
          disabled={!isValid}
          onClick={handleSubmit(onSubmit)}
          type={'submit'}
          className={classNames(classes.Submit, !isValid && classes.Disabled)}
        >
          {t(TransKeys.QUESTIONNAIRE.ACTIONS.CONTINUE)}
        </Button>
      </form>
    </div>
  );
};

import * as React from 'react';
import {useCallback, useEffect, useMemo} from 'react';
import {MetricDefinitionProps} from './metric-definition.types';
import classes from './metric-definition.module.scss';
import {FunnelSmartSelector} from '../../../../../../shared/core/smart-selector/funnel-smart-selector.component';
import {FUNNEL_PARAMETER_KEY} from '../../../../../../analyses/analysis-forms/analysis-parameters/analysis-49/analysis-49-form.component';
import {TableEntityBinding} from '../../../../../../../objects/models/table.model';
import {useFormContext} from 'react-hook-form';
import TransKeys from '../../../../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {Select, useRemoteSourceStated} from 'ui-components';
import {getFunnelNetworkRequest} from '../../../../../../../http/funnels.network-requests';
import {exists} from 'front-core';
import {get, some, take, takeRight} from 'lodash';
import {FunnelStep} from '../../../../../../../objects/models/funnel.model';
import {ParameterInputWrapper} from '../../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {MetricSeriesSeriesType} from '../../../../../../../objects/models/health-monitor.model';

interface OwnProps extends MetricDefinitionProps {
  onCreateFunnel?: () => void;
}

type AllProps = OwnProps;

const METRIC_SERIES_TYPE_OPTIONS = [
  {value: MetricSeriesSeriesType.RATE, label: 'Conversion rate'},
  // {value: MetricSeriesSeriesType.TIME_BETWEEN, label: 'Time between'},
  {value: MetricSeriesSeriesType.NUMBER, label: 'Total users'},
];

const ERRORS_KEYS = [
  `parameters.${FUNNEL_PARAMETER_KEY}`,
  `parameters.series_type`,
  `parameters.start_step`,
  `parameters.end_step`,
];

export const MonitoredMetricFunnelDefinition: React.FC<AllProps> = (props: AllProps) => {
  const {
    parameters,
    setParameters,
    onSignalInfo,
    onCreateFunnel,
    entityContext,
    errors,
    className,
  } = props;
  const {t} = useTranslation();
  const {setValue} = useFormContext();
  const {source: selectedFunnel, exec: getFunnel} = useRemoteSourceStated({
    type: 'source',
    networkRequest: getFunnelNetworkRequest,
  });
  const hasError = useMemo(() => some(ERRORS_KEYS.map(k => Boolean(get(errors, k)))), [errors]);
  const funnelId = useMemo(() => parameters[FUNNEL_PARAMETER_KEY], [parameters]);
  const metricSeriesSeriesTypeOptions = useMemo(() => ({options: METRIC_SERIES_TYPE_OPTIONS}), []);
  const onFunnelSelected = useCallback(
    (funnelId: number, funnel) => {
      setValue('name', funnel.name);
      setParameters({
        start_step: undefined,
        end_step: undefined,
        [FUNNEL_PARAMETER_KEY]: funnelId,
      });
    },
    [setValue, setParameters]
  );
  const steps = useMemo(() => {
    if (!exists(selectedFunnel)) {
      return [];
    }
    return selectedFunnel.steps.map((s: FunnelStep, idx) => ({
      label: `Step ${idx + 1} - ${s.name}`,
      value: idx,
    }));
  }, [selectedFunnel]);
  useEffect(() => {
    if (!exists(funnelId)) {
      return;
    }
    if (selectedFunnel?.id !== funnelId) {
      getFunnel(funnelId);
    }
  }, [getFunnel, funnelId, selectedFunnel]);
  useEffect(() => {
    if (!exists(parameters.series_type)) {
      setParameters({series_type: MetricSeriesSeriesType.RATE});
    }
  }, [setParameters, parameters]);

  const renderSteps = () => {
    const startStep = get(parameters, 'start_step');
    const endStep = get(parameters, 'end_step');

    // this is done because on edit / back we don't have options yet
    if ((startStep || endStep) && steps.length === 0) {
      return null;
    }

    const sharedSelectProps = {
      searchable: false,
      clearable: false,
      sortValues: false,
      disabled: !funnelId,
    };

    if (parameters.series_type === MetricSeriesSeriesType.NUMBER) {
      return (
        <>
          <div className={classes.Label}>
            {t(TransKeys.CREATE_MONITORED_METRIC_FORM.TABS.METRIC_DEFINITION.FUNNEL.FOR_STEP)}
          </div>
          <div className={classes.Item}>
            <Select
              {...sharedSelectProps}
              placeholder={'Select step'}
              value={endStep}
              error={get(errors, 'parameters.end_step')}
              onChange={v =>
                setParameters({
                  start_step: undefined,
                  end_step: v,
                })
              }
              options={{options: steps}}
            />
          </div>
        </>
      );
    }
    if (
      [MetricSeriesSeriesType.RATE, MetricSeriesSeriesType.TIME_BETWEEN].indexOf(
        parameters.series_type
      ) > -1
    ) {
      return (
        <>
          <div className={classes.Label}>
            {t(TransKeys.CREATE_MONITORED_METRIC_FORM.TABS.METRIC_DEFINITION.FUNNEL.BETWEEN)}
          </div>
          <div className={classes.Item}>
            <Select
              {...sharedSelectProps}
              placeholder={'Select start step'}
              error={get(errors, 'parameters.start_step')}
              value={startStep}
              onChange={v =>
                setParameters({
                  start_step: v,
                  end_step: undefined,
                })
              }
              options={{options: take(steps, steps.length - 1)}}
            />
          </div>
          <div className={classes.Label}>
            {t(TransKeys.CREATE_MONITORED_METRIC_FORM.TABS.METRIC_DEFINITION.FUNNEL.AND)}
          </div>
          <div className={classes.Item}>
            <Select
              {...sharedSelectProps}
              placeholder={'Select end step'}
              error={get(errors, 'parameters.end_step')}
              value={endStep}
              onChange={v =>
                setParameters({
                  end_step: v,
                })
              }
              options={{options: takeRight(steps, steps.length - 1 - startStep)}}
              disabled={sharedSelectProps.disabled || !exists(startStep)}
            />
          </div>
        </>
      );
    }
  };

  return (
    <ParameterInputWrapper
      title={t(TransKeys.CREATE_MONITORED_METRIC_FORM.TABS.SELECT_METRIC.METRIC_DEFINITION.TITLE)}
      subTitle={t(
        TransKeys.CREATE_MONITORED_METRIC_FORM.TABS.SELECT_METRIC.METRIC_DEFINITION.SUB_TITLE
      )}
      className={className}
      error={hasError}
    >
      <div className={classes.Definition}>
        <div className={classes.Item}>
          <FunnelSmartSelector
            placeholder={t(
              TransKeys.CREATE_MONITORED_METRIC_FORM.TABS.METRIC_DEFINITION.FUNNEL.SELECT_FUNNEL
            )}
            error={exists(get(errors, `parameters.${FUNNEL_PARAMETER_KEY}`))}
            value={funnelId}
            onChange={onFunnelSelected}
            onCreate={onCreateFunnel}
            onSignalInfo={onSignalInfo as any}
            filters={{
              entityContext,
              entityBinding: TableEntityBinding.DEFAULT,
            }}
          />
        </div>
        <div className={classes.Label}>
          {t(TransKeys.CREATE_MONITORED_METRIC_FORM.TABS.METRIC_DEFINITION.FUNNEL.FOR)}
        </div>
        <div className={classes.Item}>
          <Select
            value={parameters?.series_type}
            onChange={v =>
              setParameters({
                series_type: v,
              })
            }
            options={metricSeriesSeriesTypeOptions}
            clearable={false}
            searchable={false}
            sortValues={false}
          />
        </div>
        {renderSteps()}
      </div>
    </ParameterInputWrapper>
  );
};

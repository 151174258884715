import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {Modal, Popover} from '@material-ui/core';
import classes from './analysis-parameters.module.scss';
import {
  DropdownButton,
  IconButton,
  InteractionContext,
  InteractionType,
  ModalLayout,
  ModelType,
  ParameterSchema,
  ParameterType,
  SlidersIcon,
} from 'ui-components';
import {HttpClientContext} from 'front-core';
import {getAnalysisResultParametersNetworkRequest} from '../../../../http/analysis-results.network-requests';
import {sharedClasses} from '../../../shared';
import {get} from 'lodash';
import {EmptyState} from '../../../shared/components/general/override';
import TransKeys from '../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {getAnalysisParametersNetworkRequest} from '../../../../http/analyses.network-requests';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {PanelKey} from '../../../../constants/panels';
import {ANALYSIS_RESULT_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {ParametersViewer} from '../parameters-viewer/parameters-viewer.component';
import {PanelType} from '../../../../objects/system/panel-type.enum';
import {QuerySqlTabs} from '../../../shared/core/query-sql-tabs/query-sql-tabs.component';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {refactorAnalysisParameters} from '../../panels/analysis-form-panel/components/analysis-parameters-tab/refactor-analysis-parameters';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {SOURCE_PROP_KEY} from '../../../../constants/shared-component-prop-key';
import {ANALYSIS_PARAMETERS_TRANSFORMERS_MAP} from '../../analysis-forms/analysis-parameters/analysis-mapping';
import {baseAnalysisParametersTransformer} from '../../analysis-forms/analysis-parameters/shared-transformers';
import {ViewSQLModal} from '../../../shared/components/general/view-sql-modal/view-sql-modal.component';

interface OwnProps {
  label: string;
  analysisResultId?: number;
  analysisId?: number;
  className?: string;
  fullWidth?: boolean;
  asIcon?: boolean;
  size?: 'small' | 'normal' | 'large';
  border?: boolean;
  allowDuplicate?: boolean;
  [SOURCE_PROP_KEY]?: string;
}

const parameterTypeToModelType = {
  [ParameterType.SIGNAL]: ModelType.SIGNAL,
  [ParameterType.FUNNEL]: ModelType.FUNNEL,
  [ParameterType.FEATURE]: ModelType.FEATURE,
  [ParameterType.CONTENT]: ModelType.CONTENT,
  [ParameterType.METRIC]: ModelType.METRIC,
  [ParameterType.SEGMENT]: ModelType.SEGMENT,
  // internal
  [ParameterType.__MERGED_TREATMENTS]: ModelType.SIGNAL,
  [ParameterType.__MERGED_SEGMENT_GROUPS]: ModelType.SIGNAL,
};

export const AnalysisParameters: React.FC<OwnProps> = (props: OwnProps) => {
  const {
    analysisResultId,
    analysisId,
    label,
    className,
    asIcon,
    size,
    border,
    allowDuplicate,
    [SOURCE_PROP_KEY]: appSource,
  } = props;
  const notify = useAmplitude();
  const {postMessage} = useContext(InteractionContext);
  const {openPrimaryPanel} = useContext(PanelsContext);
  const {productEntitiesMap} = useProductData();
  const [anchorEl, setAnchorEl] = useState(null);
  const [parameters, setParameters_] = useState(undefined);
  const [queryOpen, setQueryOpen] = useState(false);
  const [query, setQuery] = useState(undefined);
  const [viewedSQL, setViewedSQL] = useState(undefined);
  const [error, setError] = useState(undefined);
  const httpClient = useContext(HttpClientContext);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const {t} = useTranslation();
  const setParameters = useCallback(
    parameters => {
      const transformer =
        ANALYSIS_PARAMETERS_TRANSFORMERS_MAP[parameters.analysisTypeId] ||
        baseAnalysisParametersTransformer;
      // fix analysis parameters - front migration
      parameters.values = refactorAnalysisParameters(parameters.analysisTypeId, parameters.values);
      if (transformer) {
        const [values, schema] = transformer(
          parameters.values,
          parameters.schema,
          productEntitiesMap
        );
        setParameters_({
          ...parameters,
          values,
          schema,
        });
        return;
      }
      setParameters_(parameters);
    },
    [setParameters_, productEntitiesMap]
  );

  const getParameters = useCallback(async () => {
    try {
      let parameters = undefined;
      if (analysisResultId) {
        parameters = await httpClient.exec(
          getAnalysisResultParametersNetworkRequest(analysisResultId)
        );
      } else if (analysisId) {
        parameters = await httpClient.exec(getAnalysisParametersNetworkRequest(analysisId));
      }
      setParameters(parameters);
    } catch (e: any) {
      console.log(e);
      setError(`Error: ${get(e, 'data.message')}`);
    }
  }, [analysisResultId, analysisId, httpClient, setParameters]);
  const onParameterClick = useCallback(
    (value: any, p: ParameterSchema) => {
      if (p.type === ParameterType.QUERY_BUILDER) {
        setQueryOpen(true);
        setQuery(value);
        return;
      }
      if (p.type === ParameterType.SQL) {
        setViewedSQL(value);
        return;
      }
      let modelType = parameterTypeToModelType[p.type];
      let modelId = value;
      if (modelType && modelId) {
        postMessage &&
          postMessage({
            type: InteractionType.REFERENCE,
            payload: {modelId, modelType},
          });
      }
    },
    [postMessage]
  );
  const handleClick = useCallback(
    event => {
      event.stopPropagation();
      event.preventDefault();
      notify(AmplitudeEvent.ANALYSIS_RESULT_USER_PRESSED_PARAMETERS, {
        analysis_id: analysisId,
        analysis_result_id: analysisResultId,
      });
      setAnchorEl(event.currentTarget);
    },
    [notify, analysisId, analysisResultId]
  );
  const handleClose = useCallback(
    (event = undefined) => {
      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }
      setAnchorEl(null);
    },
    [setAnchorEl]
  );
  const closeModal = useCallback(() => {
    setQueryOpen(false);
    setQuery(undefined);
  }, []);
  const onDuplicate = useCallback(() => {
    openPrimaryPanel(
      PanelKey.ANALYSIS_FORM_PANEL,
      {
        [ANALYSIS_RESULT_ID_PATH_PARAM]: analysisResultId,
        [SOURCE_PROP_KEY]: appSource,
      },
      PanelType.MODAL
    );
    notify(AmplitudeEvent.ANALYSIS_DUPLICATE_CLICKED, {
      analysis_result_id: analysisResultId,
      source: 'parameters-viewer',
    });
    handleClose();
  }, [handleClose, openPrimaryPanel, notify, analysisResultId, appSource]);

  const id = open ? 'parameters-popover' : undefined;

  useEffect(() => {
    if (open && !parameters) {
      getParameters();
    }
  }, [open, parameters, getParameters]);

  return (
    <>
      {!asIcon && (
        <DropdownButton
          className={className}
          onClick={handleClick}
          label={label}
          isOpen={open}
          rounded
          border={border}
        />
      )}
      {asIcon && (
        <IconButton
          className={className}
          onClick={handleClick}
          icon={SlidersIcon}
          active={open}
          size={size}
          tooltipText={label}
          border={border}
        />
      )}
      <Popover
        classes={{
          paper: sharedClasses.BlankPaper,
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {parameters && (
          <ParametersViewer
            className={classes.ParametersPopover}
            analysisTypeName={parameters?.analysisTypeName}
            values={parameters?.values}
            schema={parameters?.schema}
            onParameterClick={onParameterClick}
            onDuplicate={analysisResultId && allowDuplicate ? onDuplicate : undefined}
          />
        )}
        {error && (
          <div className={classes.Error}>
            {error && (
              <EmptyState
                size={'small'}
                title={t(TransKeys.GENERAL.LABELS.MISSING_DATA)}
                subTitle={error}
                variant={'dark'}
                error={Boolean(error)}
              />
            )}
          </div>
        )}
      </Popover>
      {query && (
        <Modal
          onClick={e => e.stopPropagation()}
          className={classes.QueryModalWrapper}
          open={queryOpen}
          onClose={closeModal}
        >
          <ModalLayout className={classes.Modal} onClose={closeModal}>
            <div className={classes.QueryBuilder}>
              <QuerySqlTabs query={query} />
            </div>
          </ModalLayout>
        </Modal>
      )}
      {viewedSQL && (
        <ViewSQLModal sql={viewedSQL} isOpen={!!viewedSQL} onClose={() => setViewedSQL('')} />
      )}
    </>
  );
};

AnalysisParameters.defaultProps = {
  allowDuplicate: true,
};

import {
  FunnelAnalysisConversionFollowUpActionsDTO,
  GoalDriversAnalysisImproveFeatureAdoptionFollowUpActionsDTO,
  RetentionAnalysisInvestigateBucketFollowUpActionsDTO,
  RCAFromLineChartFollowUpActionsDTO,
} from '../../objects/dto/follow-up-actions.dto';
import {action, OnSuccessActionHook} from 'front-core';

export enum FollowUpActionsActionType {
  RCA_FROM_LINE_CHART_FOLLOW_UP_ACTIONS = '@@follow_up_actions/RCA_FROM_LINE_CHART_FOLLOW_UP_ACTIONS',
  CREATE_FUNNEL_ANALYSIS_CONVERSION_FOLLOW_UP_ACTIONS = '@@follow_up_actions/CREATE_FUNNEL_ANALYSIS_CONVERSION_FOLLOW_UP_ACTIONS',
  CREATE_GOAL_ANALYSIS_DRIVERS_IMPROVE_FEATURE_ADOPTION_FOLLOW_UP_ACTIONS = '@@follow_up_actions/CREATE_GOAL_ANALYSIS_DRIVERS_IMPROVE_FEATURE_ADOPTION_FOLLOW_UP_ACTIONS',
  CREATE_RETENTION_ANALYSIS_INVESTIGATE_BUCKET_FOLLOW_UP_ACTIONS = '@@follow_up_actions/CREATE_RETENTION_ANALYSIS_INVESTIGATE_BUCKET_FOLLOW_UP_ACTIONS',
}

export const createRCAFromLineChartFollowUpAction = (
  data: RCAFromLineChartFollowUpActionsDTO,
  onSuccess?: OnSuccessActionHook
) =>
  action(FollowUpActionsActionType.RCA_FROM_LINE_CHART_FOLLOW_UP_ACTIONS, data, {
    onSuccess,
  });

export const createFunnelAnalysisConversionFollowUpActions = (
  data: FunnelAnalysisConversionFollowUpActionsDTO,
  onSuccess?: OnSuccessActionHook
) =>
  action(FollowUpActionsActionType.CREATE_FUNNEL_ANALYSIS_CONVERSION_FOLLOW_UP_ACTIONS, data, {
    onSuccess,
  });

export const createGoalDriversAnalysisImproveFeatureAdoptionFollowUpActions = (
  data: GoalDriversAnalysisImproveFeatureAdoptionFollowUpActionsDTO,
  onSuccess?: OnSuccessActionHook
) =>
  action(
    FollowUpActionsActionType.CREATE_GOAL_ANALYSIS_DRIVERS_IMPROVE_FEATURE_ADOPTION_FOLLOW_UP_ACTIONS,
    data,
    {
      onSuccess,
    }
  );

export const createRetentionAnalysisInvestigateBucketFollowUpActions = (
  data: RetentionAnalysisInvestigateBucketFollowUpActionsDTO,
  onSuccess?: OnSuccessActionHook
) =>
  action(
    FollowUpActionsActionType.CREATE_RETENTION_ANALYSIS_INVESTIGATE_BUCKET_FOLLOW_UP_ACTIONS,
    data,
    {
      onSuccess,
    }
  );

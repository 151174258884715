export const BOUNDED_ACTION_ANALYSIS_PARAMETER_MAPPING = {
  goal: 'parameters.0',
  ref_date: 'parameters.4',
  goal_bound_unit: 'parameters.3',
  goal_bound_n: 'parameters.2',
};

export const BOUNDED_ACTION_NUMERIC_PARAMETER_MAPPING = {
  goal: 'parameters.0',
  ref_date: 'parameters.4',
  goal_bound_unit: 'parameters.3',
  goal_bound_n_start: 'parameters.1',
  goal_bound_n_end: 'parameters.2',
  dimension_aggregation: 'parameters.5',
};

export const BOUNDED_ACTIONS_ANALYSIS_PARAMETER_MAPPING = {
  actions: 'parameters.0',
  ref_date: 'parameters.4',
  actions_retention_unit: 'parameters.3',
  actions_retention_n: 'parameters.2',
};

export const SUBSCRIPTION_RETENTION_ANALYSIS_PARAMETER_MAPPING = {
  goal_bound_unit: 'parameters.0',
  goal_bound_n: 'parameters.1',
  plan: 'parameters.2',
};

export const SIGNAL_COLUMN_ANALYSIS_PARAMETER_MAPPING = {
  signal_id: 'signal_id',
};

export const HABIT_MOMENT_PARAMETER_MAPPING = {
  signals: 'parameters.0',
  ref_date: 'parameters.1',
  time_window_unit: 'parameters.2',
  time_window_n: 'parameters.3',
  reference_unit: 'parameters.4',
  reference_n: 'parameters.5',
};

export const SQL_TEMPLATE_PARAMETER_MAPPING = {
  sql_definition: 'parameters.0',
  table_type: 'parameters.1',
  table_granularity: 'parameters.7',
  entity_id_column: 'parameters.3',
  value_column: 'parameters.4',
  timestamp_column: 'parameters.8',
  dimension_aggregation: 'parameters.5',
  time_aggregation: 'parameters.9',
  data_type: 'parameters.2',
  slug: 'parameters.6',
  event_name_column: 'parameters.10', // for future development
  partition_ts_column: 'parameters.11', // for future development
};

export const BEHAVIORAL_CHURN_PARAMETER_MAPPING = {
  signal_id: 'parameters.0',
  units: 'parameters.1',
  n_units: 'parameters.2',
};

export const BEHAVIORAL_FIRST_OR_LAST_VALUE_PARAMETER_MAPPING = {
  table_events_value_query: 'parameters.0',
  table_column_data_type: 'parameters.1',
  table_column_literal_type: 'parameters.2',
};

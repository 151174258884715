import {useCallback, useContext, useMemo, useState} from 'react';
import sharedClasses from '../analysis-types-follow-up-panel/analysis-types-follow-up-panel.module.scss';
import {withLoadBefore} from '../../../../../core/hoc/with-load-before.hoc';
import {getAnalysisResultNetworkRequest} from '../../../../../http/analysis-results.network-requests';
import {getAnalysisTypesNetworkRequest} from '../../../../../http/analysis-types.network-requests';
import {multiLoaderNetworkRequest} from '../../../../../http/multi-loader.network-requests';
import {
  GoalDriversAnalysisImproveFeatureAdoptionFollowUpParameters,
  RouteDuotoneIcon,
  EyeDuotoneIcon,
  Select,
  HoverHelperTip,
} from 'ui-components';
import {useAmplitude} from '../../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../../constants/amplitude-event';
import {GenericLoading} from '../../../components/general/generic-loading/generic-loading.component';
import {preventSubmitOnEnter} from '../../../../../utils/general.utils';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useDispatch, useSelector} from 'react-redux';
import {getReducedLoadingStateSelector} from '../../../../../store/store.selectors';
import {ActionKey} from '../../../../../constants/action-key';
import {goalDriversAnalysisImproveFeatureAdoptionFollowUpActionParametersValidator} from '../../../../../objects/dto/follow-up-actions.dto';
import {
  ANALYSIS_RESULT_ID_PATH_PARAM,
  SIGNAL_ID_PATH_PARAM,
} from '../../../../../constants/app-routes';
import {AnalysisResult} from '../../../../../objects/models/analysis-result.model';
import {AnalysisType} from '../../../../../objects/models/analysis-type.model';
import {AnalysisTypeId} from '../../../../../constants/analysis-type-id';
import i18n from 'i18next';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {createGoalDriversAnalysisImproveFeatureAdoptionFollowUpActions} from '../../../../../store/follow-up-actions/follow-up-actions.actions';
import AnalysisTypesFollowUpPanel from '../analysis-types-follow-up-panel/analysis-types-follow-up-panel.component';
import AnalysisTypesFollowUpPanelHeader from '../analysis-types-follow-up-panel/components/analysis-types-follow-up-panel-header.component';
import AnalysisTypesFollowUpPanelSelectSegment from '../analysis-types-follow-up-panel/components/analysis-types-follow-up-panel-select-segment.component';
import AnalysisTypesFollowUpPanelBody from '../analysis-types-follow-up-panel/components/analysis-types-follow-up-panel-body.component';
import {PanelKey} from '../../../../../constants/panels';
import {PanelsContext} from '../../../../../core/contexts/panels.context';
import {compact, flatten} from 'lodash';
import {composition} from 'front-core';
import {withModalInactiveSourceHandler} from '../../../../../core/hoc/with-modal-inactive-source-handler.hoc';
import {withDisableDemoProduct} from '../../../../../core/hoc/with-disable-demo-product.hoc';

interface OwnProps {
  parameters: GoalDriversAnalysisImproveFeatureAdoptionFollowUpParameters;
  analysisResult: AnalysisResult;
  analysisTypes: {data: AnalysisType[]};
  multiEntities: any;
  onClose?: () => void;
  disabled?: boolean;
}

type AllProps = OwnProps;

const FOLLOW_UP_ANALYSES_TYPES = [AnalysisTypeId.USER_JOURNEY, AnalysisTypeId.FEATURE_FUNNEL];
const BOXES_CONFIG = () => ({
  [AnalysisTypeId.USER_JOURNEY]: {
    description: i18n.t(
      TransKeys.GOAL_DRIVERS_ANALYSIS_FOLLOW_UP_PANEL.FOLLOW_UP_ANALYSES.ANALYSIS_107.DESCRIPTION
    ),
    icon: <RouteDuotoneIcon className={sharedClasses.RouteDuotoneIcon} />,
  },
  [AnalysisTypeId.FEATURE_FUNNEL]: {
    description: i18n.t(
      TransKeys.GOAL_DRIVERS_ANALYSIS_FOLLOW_UP_PANEL.FOLLOW_UP_ANALYSES.ANALYSIS_74.DESCRIPTION
    ),
    icon: <EyeDuotoneIcon className={sharedClasses.EyeDuotoneIcon} />,
  },
});

const _FEATURE_SIGNAL_ID_KEY = 'featureSignal';
const _FEATURES_SIGNAL_IDS_KEY = 'featuresSignals';
const _SEGMENT_SIGNAL_ID_KEY = 'segment';

const createSelectedKey = (key: string) => `GOAL_DRIVERS_ANALYSIS_FOLLOW_UP_FORM/${key}`;

const GoalDriversAnalysisFollowUpFormPanelComponent: React.FC<AllProps> = (props: AllProps) => {
  const {
    parameters,
    analysisResult,
    analysisTypes,
    multiEntities,
    onClose,
    disabled: disabledFromProps,
  } = props;
  const [followupAnalysesRunning, setFollowupAnalysesRunning] = useState(false);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {openSecondaryPanel} = useContext(PanelsContext);
  const notify = useAmplitude();
  const formMethods = useForm({
    defaultValues: {
      ...parameters,
      analysisResultId: analysisResult.id,
      analysisFolderId: analysisResult.analysisFolderId,
      analysisTypeIds: [],
      treatmentsTag: analysisResult.parameters.treatmentsTag,
      treatmentsSignals: analysisResult.parameters.treatmentsSignals,
    },
    resolver: yupResolver(
      goalDriversAnalysisImproveFeatureAdoptionFollowUpActionParametersValidator
    ),
  });
  const {handleSubmit, watch, setValue} = formMethods;
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(
      ActionKey.CREATE_GOAL_DRIVERS_ANALYSIS_IMPROVE_FEATURE_ADOPTION_FOLLOW_UP_ACTIONS
    )(state)
  );
  const followUpAnalysesTypeIds = watch('analysisTypeIds');
  const selectedFeatureId = watch(_FEATURE_SIGNAL_ID_KEY);

  const disabled = useMemo(
    () => isLoading || followUpAnalysesTypeIds.length === 0 || disabledFromProps,
    [isLoading, followUpAnalysesTypeIds, disabledFromProps]
  );
  const segment = useMemo(
    () => multiEntities.signals[parameters[_SEGMENT_SIGNAL_ID_KEY]],
    [multiEntities, parameters]
  );
  const feature = useMemo(
    () => multiEntities.signals[selectedFeatureId],
    [multiEntities, selectedFeatureId]
  );

  const featureOptions = useMemo(() => {
    const featureSignalIds = parameters[_FEATURES_SIGNAL_IDS_KEY] || [
      parameters[_FEATURE_SIGNAL_ID_KEY],
    ];
    return featureSignalIds
      .map(curr => multiEntities.signals[curr])
      .filter(Boolean)
      .map(feature => ({
        value: feature.id,
        label: feature.name,
      }));
  }, [multiEntities, parameters]);

  const onFeatureChange = useCallback(value => setValue(_FEATURE_SIGNAL_ID_KEY, value), [setValue]);

  const dynamicTitle = useMemo(() => {
    return (
      <>
        <Select
          dropdownButtonClassName={sharedClasses.DropdownButtonClassName}
          options={{options: featureOptions}}
          value={selectedFeatureId}
          onChange={onFeatureChange}
          clearable={false}
          disabled={featureOptions.length === 1}
          fullWidth
        />
        <div
          className={sharedClasses.SignalInfoHelperTip}
          onClick={() =>
            !isLoading &&
            openSecondaryPanel(PanelKey.SIGNAL_DEFINITION_PANEL, {
              [SIGNAL_ID_PATH_PARAM]: feature.id,
            })
          }
        >
          <HoverHelperTip
            title={t(TransKeys.GOAL_DRIVERS_ANALYSIS_FOLLOW_UP_PANEL.SIGNAL_DEFINITION_HELPER_TEXT)}
          />
        </div>
        {segment && (
          <AnalysisTypesFollowUpPanelSelectSegment segment={segment} isLoading={isLoading} />
        )}
      </>
    );
  }, [
    t,
    segment,
    isLoading,
    selectedFeatureId,
    openSecondaryPanel,
    onFeatureChange,
    featureOptions,
    feature,
  ]);

  const onSubmit = useCallback(
    data => {
      dispatch(
        createGoalDriversAnalysisImproveFeatureAdoptionFollowUpActions(data, () =>
          setFollowupAnalysesRunning(true)
        )
      );
      const amplitudePayload = followUpAnalysesTypeIds.reduce(
        (acc, curr, idx) => ({...acc, [String(idx)]: curr}),
        {}
      );
      notify(
        AmplitudeEvent.GOAL_DRIVERS_ANALYSIS_IMPROVE_FEATURE_ADOPTION_FOLLOW_UP_ACTIONS_CLICKED,
        amplitudePayload
      );
    },
    [dispatch, setFollowupAnalysesRunning, notify, followUpAnalysesTypeIds]
  );

  const analysisTypeBoxConfig = useMemo(() => BOXES_CONFIG(), []);
  return (
    <FormProvider {...formMethods}>
      <AnalysisTypesFollowUpPanel
        onClose={onClose}
        showSuccessMessage={followupAnalysesRunning}
        disabled={disabled}
        onSubmit={e => {
          e.stopPropagation();
          handleSubmit(onSubmit)(e);
        }}
      >
        {isLoading && <GenericLoading />}
        <form
          onKeyDown={preventSubmitOnEnter}
          onSubmit={e => {
            e.stopPropagation();
            handleSubmit(onSubmit)(e);
          }}
        >
          <AnalysisTypesFollowUpPanelHeader
            onClose={onClose}
            staticTitle={t(TransKeys.GOAL_DRIVERS_ANALYSIS_FOLLOW_UP_PANEL.HEADER)}
            dynamicTitle={dynamicTitle}
          />
          <AnalysisTypesFollowUpPanelBody
            analysisTypeBoxConfig={analysisTypeBoxConfig}
            analysisTypes={analysisTypes}
            defaultFollowUpAnalysesTypeIds={FOLLOW_UP_ANALYSES_TYPES}
          />
        </form>
      </AnalysisTypesFollowUpPanel>
    </FormProvider>
  );
};

export const GoalDriversAnalysisFollowUpFormPanel = composition<AllProps>(
  GoalDriversAnalysisFollowUpFormPanelComponent,
  withModalInactiveSourceHandler,
  withDisableDemoProduct,
  withLoadBefore<AllProps>({
    analysisResult: {
      selectedKey: createSelectedKey('ANALYSIS_RESULT'),
      actionKey: createSelectedKey('ANALYSIS_RESULT'),
      request: getAnalysisResultNetworkRequest,
      mapPayloadFromProps: props => props[ANALYSIS_RESULT_ID_PATH_PARAM],
      shouldCall: props => props[ANALYSIS_RESULT_ID_PATH_PARAM] !== undefined,
    },
    analysisTypes: {
      selectedKey: createSelectedKey('ANALYSIS_TYPES'),
      actionKey: createSelectedKey('ANALYSIS_TYPES'),
      request: getAnalysisTypesNetworkRequest,
      mapPayloadFromProps: () => ({
        id: FOLLOW_UP_ANALYSES_TYPES,
      }),
    },
    multiEntities: {
      selectedKey: createSelectedKey('MULTI'),
      actionKey: createSelectedKey('MULTI'),
      request: multiLoaderNetworkRequest,
      mapPayloadFromProps: props => {
        const {
          [_FEATURE_SIGNAL_ID_KEY]: featureSignalId,
          [_FEATURES_SIGNAL_IDS_KEY]: featuresSignalIds,
          [_SEGMENT_SIGNAL_ID_KEY]: segmentSignalId,
        } = props.parameters;
        const signals = compact(flatten([featuresSignalIds, featureSignalId, segmentSignalId]));
        return {signals};
      },
    },
  })
);

import {values} from 'lodash';
import {ProductArea} from '../objects/system/product-area.enum';
import i18n from '../config/i18n.config';
import TransKeys from './translation-keys';
import {ExperimentStatus, ExperimentType} from '../objects/models/experiment.model';
import {AnnotationType} from '../objects/models/annotation.model';
import {AnalysisResultStatus} from '../objects/models/analysis-result.model';
import {MetricType, MetricValueType} from '../objects/models/metric.model';
import {TableType, TableEntity} from '../objects/models/table.model';
import {EventGroupType} from '../objects/models/event-group.model';
import {MilestoneType} from '../objects/models/milestone.model';

export const getMetricTypeName = (type: MetricType) => {
  if (!type) {
    return undefined;
  }
  return (
    i18n.t(TransKeys.METRIC_FORM.SIDE_MENU.METRIC_TYPE_LABELS[type.toUpperCase()]) ||
    type.toString()
  );
};

export const getMilestoneTypeName = (milestoneType: MilestoneType) => {
  if (!milestoneType) {
    return undefined;
  }
  return (
    i18n.t(TransKeys.MILESTONE.TYPE[milestoneType.toUpperCase()]?.NAME) || milestoneType.toString()
  );
};

export const AREA_IN_PRODUCT_OPTIONS = () =>
  values(ProductArea).map(p => ({
    value: p,
    label: i18n.t(TransKeys.GENERAL.ENUMS.PRODUCT_AREA[p.toUpperCase()]),
  }));

export const EXPERIMENT_TYPE_OPTIONS = () =>
  values(ExperimentType).map(i => ({
    value: i,
    label: i18n.t(TransKeys.EXPERIMENT.TYPE[i.toUpperCase()]),
  }));

export const EXPERIMENT_STATUS_OPTIONS = () =>
  values(ExperimentStatus).map(i => ({
    value: i,
    label: i18n.t(TransKeys.EXPERIMENT.STATUS[i.toUpperCase()]),
  }));

export const METRIC_VALUE_TYPE_OPTIONS = () =>
  values(MetricValueType).map(i => ({
    value: i,
    label: i18n.t(TransKeys.METRIC.VALUE_TYPE[i.toUpperCase()]),
  }));

export const METRIC_TYPE_OPTIONS = () =>
  values(MetricType).map(i => ({
    value: i,
    label: getMetricTypeName(i),
  }));

export const MILESTONE_TYPE_OPTIONS = () =>
  values(MilestoneType).map(i => ({
    value: i,
    label: i18n.t(TransKeys.MILESTONE.TYPE[i.toUpperCase()].NAME),
  }));

export const EVENT_GROUP_TYPE_OPTIONS = () =>
  values(EventGroupType).map(i => ({
    value: i,
    label: i18n.t(TransKeys.EVENT_GROUP.TYPE[i.toUpperCase()]),
  }));

export const ANNOTATION_TYPE_OPTIONS = () =>
  values(AnnotationType).map(i => ({
    value: i,
    label: i18n.t(TransKeys.ANNOTATION.TYPE[i.toUpperCase()]),
  }));

export const ANALYSIS_RESULT_STATUS_OPTIONS = () => [
  {
    value: AnalysisResultStatus.COMPLETED,
    label: i18n.t(TransKeys.ANALYSIS_RESULT.STATUS[AnalysisResultStatus.COMPLETED.toUpperCase()]),
  },
  {
    value: AnalysisResultStatus.RUNNING,
    label: i18n.t(TransKeys.ANALYSIS_RESULT.STATUS[AnalysisResultStatus.RUNNING.toUpperCase()]),
  },
  {
    value: AnalysisResultStatus.FAILED,
    label: i18n.t(TransKeys.ANALYSIS_RESULT.STATUS[AnalysisResultStatus.FAILED.toUpperCase()]),
  },
];

export const TABLE_TYPE_OPTIONS = () =>
  values(TableType).map(i => ({
    value: i,
    label: i18n.t(TransKeys.TABLES.TYPE[i.toUpperCase()]),
  }));

export const TABLE_ENTITY_OPTIONS = () =>
  values(TableEntity).map(i => ({
    value: i,
    label: i18n.t(TransKeys.TABLES.ENTITY[i.toUpperCase()]),
  }));

export const TIME_UNIT_OPTIONS = {
  options: [
    {
      value: 'day',
      label: 'day',
    },
    {
      value: 'week',
      label: 'week',
    },
    {
      value: 'month',
      label: 'month',
    },
  ],
};

export const TIME_UNIT_PLURAL_OPTIONS = {
  options: [
    {
      value: 'day',
      label: 'days',
    },
    {
      value: 'week',
      label: 'weeks',
    },
    {
      value: 'month',
      label: 'months',
    },
  ],
};

export const TIME_UNIT_PERIODIC_OPTIONS = {
  options: [
    {
      value: 'day',
      label: 'daily',
    },
    {
      value: 'week',
      label: 'weekly',
    },
    {
      value: 'month',
      label: 'monthly',
    },
  ],
};

import {useSelector} from 'react-redux';
import {PermissionSet, Action, Role, Subject} from 'src/constants/permissions';
import {User} from 'src/objects/models/user.model';
import {AUTH_STORE_KEY} from 'src/store/auth/auth.store';
import {CORE_STORE_KEY} from 'src/store/core/core.store';

function checkPermission(permissions: PermissionSet) {
  return (subject: Subject, action: Action): boolean => {
    // Users without permissions are yet to be migrated - always allow
    if (!permissions) {
      return true;
    }

    const subjectPermissions = permissions[subject] || [];
    return subjectPermissions.includes(action);
  };
}

export interface UsePermissionsReturn {
  role: Role;
  can: (subject: Subject, action: Action) => boolean;
  permissions: PermissionSet;
}

function usePermissions(): UsePermissionsReturn {
  const scopeProduct = useSelector(state => state[CORE_STORE_KEY].scopeProduct);
  const user = useSelector<any, User>(state => state[AUTH_STORE_KEY].user);

  const productPermissions = user.productPermissions?.[scopeProduct];

  return {
    role: productPermissions?.role,
    can: checkPermission(productPermissions?.permissions),
    permissions: productPermissions?.permissions,
  };
}

export default usePermissions;

import * as React from 'react';
import classNames from 'classnames';
import {composition, number2k} from 'front-core';
import classes from './favorites-segments-form-panel.module.scss';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {
  getFavoritesSegmentsNetworkRequest,
  getSegmentsByCategoryNetworkRequest,
} from '../../../../http/segments.network-requests';
import {
  FavoriteSegment,
  FavoriteSegmentsByTeam,
  SegmentCategory,
  SegmentsByCategory,
} from '../../../../objects/models/segment.model';
import {
  Button,
  Checkbox,
  FancyHeader,
  ModalLayout,
  Select,
  TeamSelector,
  UserGroupLightIcon,
} from 'ui-components';
import TransKeys from '../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {useCallback, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {updateFavoriteSegments} from '../../../../store/segments/segments.actions';
import {toPairs} from 'lodash';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {TEAM_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {withDisableDemoProduct} from '../../../../core/hoc/with-disable-demo-product.hoc';

interface OwnProps {
  segmentsByCategory: SegmentsByCategory;
  favorites: FavoriteSegmentsByTeam;
  [TEAM_ID_PATH_PARAM]: number;
  onClose: () => void;
  disabled?: boolean;
}

type AllProps = OwnProps;

const initState = (favorites: FavoriteSegment[] = []): {[segmentId: number]: string[]} =>
  favorites.reduce((acc, curr) => {
    acc[curr.segmentId] = curr.classes;
    return acc;
  }, {});

interface ExtendedSegmentCategory extends SegmentCategory {
  selectedCount: number;
}

const MIN_CLASSES_FOR_SEARCH = 5;

export const FavoritesSegmentsFormPanelComponent: React.FC<AllProps> = (props: AllProps) => {
  const {
    segmentsByCategory,
    favorites: favoritesByTeam,
    [TEAM_ID_PATH_PARAM]: teamIdFromProps,
    onClose,
    disabled,
  } = props;
  const {t} = useTranslation();
  const {actualTeams: teams, defaultTeamId} = useProductData();
  const {categories: categories_} = segmentsByCategory;
  const dispatch = useDispatch();
  const [teamId, setTeamId] = useState<number>(
    () => Number(teamIdFromProps) || defaultTeamId || teams[0]?.id
  );
  const initialFavorites = useMemo(() => favoritesByTeam[teamId] || [], [favoritesByTeam, teamId]);
  const [formData, setFormData] = React.useState(() => initState(initialFavorites));

  const isFirstTime = useMemo(() => initialFavorites.length === 0, [initialFavorites]);
  const categories: ExtendedSegmentCategory[] = useMemo(
    () =>
      categories_
        .map(c => ({
          ...c,
          selectedCount: c.segments.filter(s => formData[s.id] !== undefined).length,
          segments: c.segments.filter(s => s.classes.length > 0),
        }))
        .filter(c => c.segments.length > 0),
    [categories_, formData]
  );
  const onTeamChanged = useCallback(
    (teamId: number) => {
      setFormData(initState(favoritesByTeam[teamId] || []));
      setTeamId(teamId);
    },
    [setTeamId, setFormData, favoritesByTeam]
  );
  const segmentClassesOptionsMap = useMemo(() => {
    const res = {};
    const segments = [];
    for (const c of categories) {
      segments.push(...c.segments);
    }
    for (const s of segments) {
      res[s.id] = s.classes
        .sort((a, b) => b.count - a.count)
        .map(c => ({
          value: c.name,
          label: `${c.name} (${number2k(c.count)})`,
        }));
    }
    return res;
  }, [categories]);
  const onSegmentSelected = useCallback(
    (segmentId: number) =>
      setFormData(formData => {
        const new_ = {...formData};
        if (segmentId in new_) {
          delete new_[segmentId];
        } else {
          new_[segmentId] = null;
        }
        return new_;
      }),
    [setFormData]
  );
  const onSelectCategory = useCallback(
    (categoryId: number) => {
      const category = categories.find(c => c.id === categoryId);
      setFormData(formData => {
        const new_ = {...formData};
        if (category.selectedCount > 0) {
          for (const s of category.segments) {
            delete new_[s.id];
          }
        } else {
          for (const s of category.segments) {
            new_[s.id] = null;
          }
        }
        return new_;
      });
    },
    [categories, setFormData]
  );
  const onSelectSegmentClasses = useCallback(
    (segmentId: number, classes: string[]) => {
      setFormData(formData => {
        const new_ = {...formData};
        new_[segmentId] = classes;
        return new_;
      });
    },
    [setFormData]
  );
  const onSubmit = useCallback(() => {
    const sendData = toPairs(formData).map(d => ({
      segmentId: parseInt(d[0]),
      classes: d[1] as string[],
    }));
    dispatch(
      updateFavoriteSegments(
        {
          teamId,
          favorites: sendData,
        },
        onClose
      )
    );
  }, [formData, teamId, onClose, dispatch]);

  return (
    <ModalLayout
      className={classes.Container}
      footer={
        <div className={classes.Footer}>
          <div className={classes.Note}>
            <div className={classes.NoteTitle}>
              {t(TransKeys.FAVORITE_SEGMENTS_PANEL.FOOTER.NOTE.TITLE)}
            </div>
            <div className={classes.NoteDescription}>
              {t(TransKeys.FAVORITE_SEGMENTS_PANEL.FOOTER.NOTE.DESCRIPTION)}
            </div>
          </div>
          <div className={classes.Actions}>
            <Button onClick={onClose} variant={'outlined'}>
              {t(TransKeys.GENERAL.ACTIONS.CANCEL)}
            </Button>
            <Button disabled={disabled} onClick={onSubmit}>
              {t(TransKeys.GENERAL.ACTIONS.SAVE)}
            </Button>
          </div>
        </div>
      }
    >
      <div className={classes.FavoritesSegmentsForm}>
        <FancyHeader
          title={t(TransKeys.FAVORITE_SEGMENTS_PANEL.TITLE)}
          icon={UserGroupLightIcon}
          onClose={onClose}
          className={classes.Header}
        />
        <div className={classes.Main}>
          <div className={classes.TeamSelector}>
            <TeamSelector
              teams={teams}
              value={teamId}
              onChange={onTeamChanged}
              clearable={false}
              className={classes.TeamSelection}
              withPrefix
            />
          </div>
          <div className={classes.SectionHeader}>
            <div className={classes.Title}>
              {isFirstTime
                ? t(TransKeys.FAVORITE_SEGMENTS_PANEL.INNER_TITLE_FIRST_TIME_TEAM)
                : t(TransKeys.FAVORITE_SEGMENTS_PANEL.INNER_TITLE_TEAM)}
            </div>
            <div className={classes.SubTitle}>
              {t(TransKeys.FAVORITE_SEGMENTS_PANEL.INNER_SUB_TITLE)}
            </div>
          </div>
          {categories.map(category => (
            <div className={classes.Category} key={category.id}>
              <div className={classes.CategoryHeader}>
                <span className={classes.CategoryName}>{category.name}</span>
                <span className={classes.CategoryCount}>
                  {category.selectedCount} / {category.segments.length}
                </span>
                <span
                  className={classes.CategorySelect}
                  onClick={() => onSelectCategory(category.id)}
                >
                  {category.selectedCount > 0 && t(TransKeys.GENERAL.ACTIONS.REMOVE_ALL)}
                  {category.selectedCount === 0 && t(TransKeys.GENERAL.ACTIONS.ADD_ALL)}
                </span>
              </div>
              <div className={classes.ListOptions}>
                {category.segments.map(segment => (
                  <div
                    className={classNames(
                      classes.Option,
                      segment.id in formData && classes.Selected
                    )}
                    key={segment.id}
                  >
                    <Checkbox
                      className={segment.id in formData ? undefined : classes.Checkbox}
                      checked={segment.id in formData}
                      onChange={() => onSegmentSelected(segment.id)}
                      size={'large'}
                    />
                    <div className={classes.SegmentName}>{segment.name}</div>
                    <div className={classes.ClassSelection}>
                      <Select
                        options={{options: segmentClassesOptionsMap[segment.id]}}
                        value={formData[segment.id] as any}
                        onChange={v => onSelectSegmentClasses(segment.id, v as any)}
                        searchable={
                          segmentClassesOptionsMap[segment.id].length > MIN_CLASSES_FOR_SEARCH
                        }
                        disabled={!(segment.id in formData)}
                        sortValues={false}
                        capitalize={false}
                        placeholder={
                          segment.id in formData ? (
                            <span className={classes.Placeholder}>
                              {t(TransKeys.FAVORITE_SEGMENTS_PANEL.ALL_SEGMENTS_SELECTED_LABEL)}
                            </span>
                          ) : (
                            t(TransKeys.FAVORITE_SEGMENTS_PANEL.SELECT_SEGMENTS_PLACEHOLDER)
                          )
                        }
                        multiSelectItemName={t(
                          TransKeys.FAVORITE_SEGMENTS_PANEL.MULTI_SELECT_ITEM_NAME
                        )}
                        popoverDirection={'right'}
                        multi
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </ModalLayout>
  );
};

export const FavoritesSegmentsFormPanel = composition<AllProps>(
  FavoritesSegmentsFormPanelComponent,
  withDisableDemoProduct,
  withLoadBefore({
    segmentsByCategory: {
      selectedKey: 'SEGMENTS_BY_CATEGORY',
      actionKey: 'SEGMENTS_BY_CATEGORY',
      request: getSegmentsByCategoryNetworkRequest,
    },
    favorites: {
      selectedKey: 'FAVORITE_SEGMENTS',
      actionKey: 'FAVORITE_SEGMENTS',
      request: getFavoritesSegmentsNetworkRequest,
    },
  })
);

import {NRC} from './index';
import {RequestType, toCamelCase, toSnakeCase} from 'front-core';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';
import {TableDTO} from '../objects/dto/table.dto';

export const getTablesNetworkRequest: NRC<void, any> = query => ({
  method: RequestType.GET,
  relativeUrl: `/app/tables`,
  query: query,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data, ['definition', 'parameters']),
});

export const getTableNetworkRequest: NRC<void, any> = (id: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/tables/${id}`,
  responseTransformer: res => toCamelCase(res.data, ['definition', 'parameters']),
});

export const searchTablesNetworkRequest: NRC<void, any> = (value: any, payload: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/tables`,
  query: {q: value, ...payload},
  queryTransformer: toFiltersDTO,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data.data),
});

export const createTableNetworkRequest: NRC<TableDTO, void> = (data: TableDTO) => ({
  method: RequestType.POST,
  relativeUrl: `/app/tables`,
  body: data,
  bodyTransformer: data => toSnakeCase(data, ['definition', 'parameters']),
  responseTransformer: res => toCamelCase(res.data),
});

export const editTableNetworkRequest: NRC<TableDTO, void> = (data: TableDTO) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/tables/${data.id}`,
  body: data,
  bodyTransformer: data => toSnakeCase(data, ['definition', 'parameters']),
  responseTransformer: res => toCamelCase(res.data),
});

export const triggerTableDiscoveryNetworkRequest: NRC<void, any> = (tableId: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/tables/${tableId}/discovery`,
  responseTransformer: res => toCamelCase(res.data),
});

export const triggerAllTableDiscoveryNetworkRequest: NRC<void, any> = () => ({
  method: RequestType.GET,
  relativeUrl: `/app/tables/discovery`,
  responseTransformer: res => toCamelCase(res.data),
});

export const triggerScanAllTypesOfTablesNetworkRequest: NRC<void, any> = () => ({
  method: RequestType.GET,
  relativeUrl: `/app/tables/rescan`,
  responseTransformer: res => toCamelCase(res.data),
});

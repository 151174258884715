import {useMemo} from 'react';
import classes from './sql-viewer.module.scss';
import {format} from 'sql-formatter';
import {SqlInput} from '../../../form/components/sql-input/sql-input.component';
import classNames from 'classnames';

export interface OwnProps {
  sql: string;
  allowCopy?: boolean;
  border?: boolean;
  className?: string;
}

const FORMAT_CONFIG = {
  expressionWidth: 34,
};

export const SQLViewer: React.FC<OwnProps> = (props: OwnProps) => {
  const {sql, allowCopy, border, className} = props;
  const formattedSql = useMemo(() => {
    try {
      return format(sql, FORMAT_CONFIG);
    } catch (e) {
      return sql;
    }
  }, [sql]);

  return (
    <div className={classNames(classes.SQLViewerWrapper, className)}>
      <SqlInput value={formattedSql} disabled border={border} copyButton={allowCopy} />
    </div>
  );
};

SQLViewer.defaultProps = {
  allowCopy: true,
  border: true,
};

import * as React from 'react';
import classNames from 'classnames';
import classes from './active-users-query-builder.module.scss';
import {
  METADATA_KEY,
  PARAMETERS_METADATA_KEY,
} from '../../../../../constants/parameters-saved-keys';
import {LabelWrapper, QueryBuilderFactory, Select} from 'ui-components';
import {get, invert} from 'lodash';
import {useCallback, useMemo} from 'react';
import {del, set} from 'object-path-immutable';
import {ItemList} from '../components/item-list/item-list.component';
import pluralize from 'pluralize';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';
import {SelectorModelType} from '../../smart-selector/advanced-smart-selector.component';
import {TableEntity, TableEntityBinding} from '../../../../../objects/models/table.model';
import {SignalDataType, SignalType} from '../../../../../objects/models/signal.model';
import {TemplateItemQueryBuilder} from '../template-item-query-builder/template-item-query-builder.component';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';

interface OwnProps {
  query: any;
  onChange?: (parameters: any) => void;
  onSignalInfo?: (value: string | number) => void;
  entityContext?: TableEntity;
  errors?: any;
  disabled?: boolean;
  className?: string;
  hideEngagementPeriod?: boolean;
}

type AllProps = OwnProps;

export enum ActiveUsersType {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

const ACTIVE_USERS_TYPE_TO_TEMPLATE_TYPE = {
  [ActiveUsersType.DAILY]: 'dau',
  [ActiveUsersType.WEEKLY]: 'wau',
  [ActiveUsersType.MONTHLY]: 'mau',
};

export const createActiveUsersInitialQuery = (type: ActiveUsersType) => {
  const q = QueryBuilderFactory.createTemplate();
  q.template = ACTIVE_USERS_TYPE_TO_TEMPLATE_TYPE[type];
  q.parameters[0] = [null];

  q[PARAMETERS_METADATA_KEY] = {
    [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: 'ActiveUsersQueryBuilder',
  };
  return q;
};

const createSelectOptions = (entity: TableEntity = TableEntity.USER) => [
  {label: `Daily active ${pluralize(entity).toLowerCase()}`, value: ActiveUsersType.DAILY},
  {label: `Weekly active ${pluralize(entity).toLowerCase()}`, value: ActiveUsersType.WEEKLY},
  {label: `Monthly active ${pluralize(entity).toLowerCase()}`, value: ActiveUsersType.MONTHLY},
];

const ALLOW_TYPES = [SelectorModelType.SIGNAL, SelectorModelType.EVENT];

const createSignalFilters = (entityContext = undefined) => ({
  type: SignalType.MEASURE,
  data_type: SignalDataType.BOOLEAN,
  entity_binding: TableEntityBinding.TWO_WAY,
  entityContext,
});

export const ActiveUsersQueryBuilder: React.FC<AllProps> = (props: AllProps) => {
  const {
    query,
    onChange,
    onSignalInfo,
    errors,
    disabled,
    entityContext,
    hideEngagementPeriod,
    className,
  } = props;
  const {t} = useTranslation();
  const {productEntitiesMap} = useProductData();
  const selectedType = useMemo(
    () => invert(ACTIVE_USERS_TYPE_TO_TEMPLATE_TYPE)[query?.template],
    [query]
  );
  const signalFilters = useMemo(() => createSignalFilters(entityContext), [entityContext]);
  const eventFilters = useMemo(
    () => ({
      entity_binding: TableEntityBinding.TWO_WAY,
      entityContext,
    }),
    [entityContext]
  );
  const options = useMemo(
    () => ({options: createSelectOptions(productEntitiesMap[signalFilters?.entityContext]?.name)}),
    [signalFilters, productEntitiesMap]
  );
  const actions = useMemo(() => get(query, `parameters.0`, []), [query]);
  const onChangeType = useCallback(
    (type: ActiveUsersType) => {
      onChange(createActiveUsersInitialQuery(type));
    },
    [onChange]
  );
  const onActionsChanged = useCallback(
    (selectedValue, path) => {
      const newValue = set(query, path, selectedValue);
      onChange(newValue);
    },
    [query, onChange]
  );
  const removeAction = useCallback(
    path => {
      const newValue = del(query, path);
      onChange(newValue);
    },
    [query, onChange]
  );
  const addAction = useCallback(() => {
    const newValue = set(query, 'parameters.0', [...actions, null]);
    onChange(newValue);
  }, [query, actions, onChange]);

  return (
    <div className={classNames(classes.ActiveUsersQueryBuilder, className)}>
      {!hideEngagementPeriod && (
        <div className={classes.Row}>
          <div className={classes.Item}>
            <LabelWrapper label={t(TransKeys.QUERY_BUILDERS.ACTIVE_USERS.TEMPLATE_NAME.LABEL)}>
              <Select
                // use to refresh selected value when entityContext is changed
                key={signalFilters?.entityContext}
                value={selectedType}
                options={options}
                onChange={onChangeType as any}
                searchable={false}
                clearable={false}
                sortValues={false}
                disabled={disabled}
              />
            </LabelWrapper>
          </div>
        </div>
      )}
      <div className={classes.Row}>
        <LabelWrapper label={t(TransKeys.QUERY_BUILDERS.ACTIVE_USERS.ACTIONS.LABEL)}>
          <ItemList
            items={actions}
            renderItem={(item, idx) => (
              <TemplateItemQueryBuilder
                onChange={v => onActionsChanged(v, `parameters.0.${idx}`)}
                query={actions[idx]}
                errors={get(errors, `parameters.0.${idx}`)}
                signalFilters={signalFilters}
                eventFilters={eventFilters}
                disabled={disabled}
                allowTypes={ALLOW_TYPES}
                onSignalInfo={onSignalInfo}
              />
            )}
            onRemoveItem={idx => removeAction(`parameters.0.${idx}`)}
            onAddItem={addAction}
            disabled={disabled}
          />
        </LabelWrapper>
      </div>
    </div>
  );
};

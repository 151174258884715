import {action, OnSuccessActionHook} from 'front-core';
import {showConfirmationMessage} from '../interface/interface.actions';
import i18n from '../../config/i18n.config';
import TransKeys from '../../constants/translation-keys';
import {EventGroupDTO} from '../../objects/dto/event-group.dto';

export enum EventGroupActionType {
  CREATE_EVENT_GROUP = '@@event-groups/CREATE_EVENT_GROUP',
  UPDATE_EVENT_GROUP = '@@event-groups/UPDATE_EVENT_GROUP',
  DELETE_EVENT_GROUP = '@@event-groups/DELETE_EVENT_GROUP',
  VALIDATE_EVENT_GROUP = '@@event-groups/VALIDATE_EVENT_GROUP',
}

export const createEventGroup = (data: EventGroupDTO, onSuccess?: OnSuccessActionHook) =>
  action(EventGroupActionType.CREATE_EVENT_GROUP, data, {onSuccess});

export const updateEventGroup = (data: EventGroupDTO, onSuccess?: OnSuccessActionHook) =>
  action(EventGroupActionType.UPDATE_EVENT_GROUP, data, {onSuccess});

export const deleteEventGroup = (id: number) => action(EventGroupActionType.DELETE_EVENT_GROUP, id);

export const deleteEventGroupConfirmed = (id: number) =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.DELETE_EVENT_GROUP.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.DELETE_EVENT_GROUP.CONTENT),
    },
    () => [deleteEventGroup(id)]
  );

export const validateEventGroup = (id: number, onSuccess?: OnSuccessActionHook) =>
  action(EventGroupActionType.VALIDATE_EVENT_GROUP, id, {onSuccess});

import React from 'react';
import classes from './auth-form.module.scss';
import {Button} from '@material-ui/core';

interface OwnProps {
  children?: React.ReactNode;
  disableOnSubmit?: boolean;
  footer?: any;
  onSubmit: (data: any) => any;
  onSubmitText: string;
  subTitle?: string;
  title?: string;
  error?: string;
  responseInfo?: string;
  renderLeftToSubmit?: JSX.Element;
}

type AllProps = OwnProps;

const AuthForm: React.FC<AllProps> = (props: AllProps) => {
  const {
    children,
    disableOnSubmit,
    footer,
    onSubmit,
    onSubmitText,
    subTitle,
    title,
    error,
    responseInfo,
    renderLeftToSubmit,
  } = props;

  return (
    <form onSubmit={onSubmit}>
      <div className={classes.Form}>
        {responseInfo && <div className={classes.Info}>{responseInfo}</div>}
        {!responseInfo && error && <div className={classes.Error}>{error}</div>}
        {!responseInfo && title && <div className={classes.FormHeading}>{title}</div>}
        {subTitle && <div className={classes.FormSubHeading}>{subTitle}</div>}
        <div className={classes.FormBody}>
          {children}
          <div className={classes.Submit}>
            <div>{renderLeftToSubmit}</div>
            <Button
              className={classes.SubmitButton}
              type={'submit'}
              disabled={disableOnSubmit}
              variant={'contained'}
              color={'primary'}
            >
              {onSubmitText}
            </Button>
          </div>
          {footer || null}
        </div>
      </div>
    </form>
  );
};

export default AuthForm;

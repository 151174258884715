import {INFRA_STORE_KEY, Linker} from 'front-core';
import {ActionKey} from '../../../../constants/action-key';
import {ApplicationError} from 'front-core';
import {AUTH_STORE_KEY} from '../../../../store/auth/auth.store';
import {AuthStatus} from '../../../../objects/models/auth.model';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors';

export interface LoginViewModel {
  isLoading: boolean;
  status: AuthStatus;
  mfaTokenId: number;
  error: ApplicationError;
}

export const LoginLinker: Linker<LoginViewModel> = {
  status: {
    store: AUTH_STORE_KEY,
    property: 'status',
  },
  mfaTokenId: {
    store: AUTH_STORE_KEY,
    property: 'mfaTokenId',
  },
  error: {
    store: INFRA_STORE_KEY,
    property: ['errors', ActionKey.LOGIN],
  },
  isLoading: {
    selector: getReducedLoadingStateSelector(ActionKey.LOGIN),
  },
};

import {NRC} from './index';
import {RequestType, toCamelCase} from 'front-core';

export const getSourceTableNetworkRequest: NRC<void, any> = (id: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/source-tables/${id}`,
  responseTransformer: res => toCamelCase(res.data),
});

export const triggerSourceTableDiscoveryNetworkRequest: NRC<void, any> = () => ({
  method: RequestType.GET,
  relativeUrl: `/app/source-tables/discovery`,
  responseTransformer: res => toCamelCase(res.data),
});

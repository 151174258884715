import {NRC} from './index';
import {RequestType, toCamelCase, toSnakeCase} from 'front-core';
import {userOnboardingTaskOrderComparator} from '../constants/user-onboarding';
import {UserQuestionnaireDTO} from '../objects/dto/user-questionnaire.dto';

export const getUserOnboardingNetworkRequest: NRC<void, any> = () => ({
  method: RequestType.GET,
  relativeUrl: `/app/user/onboarding`,
  responseTransformer: res =>
    res.data &&
    toCamelCase({
      ...res.data,
      tasks: Object.keys(res.data?.tasks || {})
        .map(type => ({
          type,
          ...res.data.tasks[type],
        }))
        .sort((a, b) => userOnboardingTaskOrderComparator(a.type, b.type)),
    }),
});

export const dismissUserOnboardingNetworkRequest: NRC<any, void> = data => ({
  method: RequestType.POST,
  relativeUrl: `/app/user/onboarding/dismiss`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const updateUserQuestionnaireNetworkRequest: NRC<UserQuestionnaireDTO, void> = (
  data: UserQuestionnaireDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/user/questionnaire`,
  body: data,
  bodyTransformer: data => toSnakeCase(data),
  responseTransformer: res => toCamelCase(res.data),
});

import React from 'react';
import classes from './schedule-cron-exp-item.module.scss';
import classNames from 'classnames';
import {CronExpBuilderContextProvider} from '../../cron-exp-builder-context-provider/cron-exp-builder-context-provider.component';
import CronExpCheckbox from './components/cron-exp-checkbox/cron-exp-checkbox.component';

interface OwnProps {
  checked: boolean;
  onChange: (value: any) => any;
  children: React.ReactElement | React.ReactElement[];
  defaultCronExp: string;
  value: string;
}

type AllProps = OwnProps;

const ScheduleCronExpItem: React.FC<AllProps> = (props: AllProps) => {
  const {checked, children, defaultCronExp, onChange, value} = props;

  return (
    <CronExpBuilderContextProvider
      defaultCronExp={checked ? value : defaultCronExp}
      onChange={onChange}
    >
      <div className={classNames(classes.CronExpItem, !checked && classes.Muted)}>
        <CronExpCheckbox setCronExpInputValue={onChange} currentCronExpInputValue={value} />
        <div className={classNames(classes.CronExpItem, !checked && classes.Muted)}>{children}</div>
      </div>
    </CronExpBuilderContextProvider>
  );
};

export default ScheduleCronExpItem;

import * as React from 'react';
import {useCallback, useEffect, useMemo} from 'react';
import {
  ANALYSIS_MODE_KEY,
  GOAL_KEY,
} from '../../../../../../analyses/analysis-forms/analysis-parameters/analysis-101/analysis-101-form.component';
import {exists} from 'front-core';
import {get, some} from 'lodash';
import {TableEntityBinding} from '../../../../../../../objects/models/table.model';
import {MetricForSignalSmartSelector} from '../../../../../../shared/core/smart-selector/metric-for-signal-smart-selector.component';
import {useFormContext} from 'react-hook-form';
import {MetricDefinitionProps} from './metric-definition.types';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../../../../constants/translation-keys';
import {ParameterInputWrapper} from '../../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import classes from './metric-definition.module.scss';
import {SwitchActions, useRemoteSourceStated} from 'ui-components';
import {getMetricNetworkRequest} from '../../../../../../../http/metrics.network-requests';
import {METRIC_ID_KEY} from '../select-metric-tab.component';
import {MetricType} from '../../../../../../../objects/models/metric.model';
import {useMetricAggregationModeSwitchActions} from '../../../../../../analyses/analysis-forms/hooks/use-metric-aggregation-mode-switch-actions.hook';
import {AggregationMode} from '../../../../../../../objects/models/signal.model';

interface OwnProps extends MetricDefinitionProps {
  onCreateKPI?: () => void;
}

type AllProps = OwnProps;

const ERRORS_KEYS = [`parameters.${GOAL_KEY}`];

export const MonitoredMetricKPIDefinition: React.FC<AllProps> = (props: AllProps) => {
  const {parameters, onSignalInfo, entityContext, onCreateKPI, setParameters, errors, className} =
    props;
  const {t} = useTranslation();
  const {setValue} = useFormContext();
  const hasError = useMemo(() => some(ERRORS_KEYS.map(k => Boolean(get(errors, k)))), [errors]);
  const {[GOAL_KEY]: signalId, [ANALYSIS_MODE_KEY]: mode, [METRIC_ID_KEY]: metricId} = parameters;
  const {
    source: metric,
    exec: getMetric,
    clear: clearMetric,
  } = useRemoteSourceStated({
    type: 'source',
    networkRequest: getMetricNetworkRequest,
  });
  const usageSwitchActions = useMemo(
    () => [
      {
        label: t(
          TransKeys.CREATE_MONITORED_METRIC_FORM.TABS.METRIC_DEFINITION.KPI.MODES.ACTIVE_PERCENT
            .LABEL
        ),
        isActive: mode === AggregationMode.COUNT_DISTINCT_FROM_X,
        onClick: () => setParameters({[ANALYSIS_MODE_KEY]: AggregationMode.COUNT_DISTINCT_FROM_X}),
        helperText: t(
          TransKeys.CREATE_MONITORED_METRIC_FORM.TABS.METRIC_DEFINITION.KPI.MODES.ACTIVE_PERCENT
            .HELPER_TEXT
        ),
      },
      {
        label: t(
          TransKeys.CREATE_MONITORED_METRIC_FORM.TABS.METRIC_DEFINITION.KPI.MODES.COUNT_ENTITIES
            .LABEL
        ),
        isActive: mode === AggregationMode.COUNT_ENTITIES,
        onClick: () => setParameters({[ANALYSIS_MODE_KEY]: AggregationMode.COUNT_ENTITIES}),
        helperText: t(
          TransKeys.CREATE_MONITORED_METRIC_FORM.TABS.METRIC_DEFINITION.KPI.MODES.COUNT_ENTITIES
            .HELPER_TEXT
        ),
      },
      {
        label: t(
          TransKeys.CREATE_MONITORED_METRIC_FORM.TABS.METRIC_DEFINITION.KPI.MODES.COUNT.LABEL
        ),
        isActive: mode === AggregationMode.SUM,
        onClick: () => setParameters({[ANALYSIS_MODE_KEY]: AggregationMode.SUM}),
        helperText: t(
          TransKeys.CREATE_MONITORED_METRIC_FORM.TABS.METRIC_DEFINITION.KPI.MODES.COUNT.HELPER_TEXT
        ),
      },
    ],
    [t, mode, setParameters]
  );
  const switchActions_ = useMetricAggregationModeSwitchActions({
    metricType: metric?.type,
    mode: parameters[ANALYSIS_MODE_KEY],
    onChange: mode => setParameters({[ANALYSIS_MODE_KEY]: mode}),
  });
  const switchActions = useMemo(() => {
    if (switchActions_.length === 0 || metric?.type === MetricType.REVENUE) {
      return switchActions_;
    }
    if (metric?.type === MetricType.USAGE) {
      return usageSwitchActions;
    }
    return [];
  }, [switchActions_, metric, usageSwitchActions]);
  const metricFilters = useMemo(
    () => ({
      entityContext,
      entityBinding: TableEntityBinding.DEFAULT,
    }),
    [entityContext]
  );

  const showSelectMode = useMemo(() => switchActions.length > 0, [switchActions]);
  const onMetricSelected = useCallback(
    (signalId: number, kpi: any) => {
      setParameters({
        [GOAL_KEY]: signalId,
        [ANALYSIS_MODE_KEY]: undefined,
        [METRIC_ID_KEY]: kpi.id,
      });
      clearMetric();
      // set the name of the metric to be as the kpi
      setValue('name', kpi?.name);
    },
    [setParameters, setValue, clearMetric]
  );

  useEffect(() => {
    if (exists(metricId) && metric?.id !== metricId) {
      getMetric(metricId);
    }
  }, [metricId, getMetric, metric]);

  useEffect(() => {
    // Set the default mode for the selected metric
    if (showSelectMode && !exists(mode) && metric?.type === MetricType.REVENUE) {
      setParameters({[ANALYSIS_MODE_KEY]: AggregationMode.SUM});
    }
    if (showSelectMode && !exists(mode) && metric?.type === MetricType.USAGE) {
      setParameters({[ANALYSIS_MODE_KEY]: AggregationMode.COUNT_ENTITIES});
    }
  }, [showSelectMode, mode, metric, setParameters]);

  return (
    <ParameterInputWrapper
      title={t(TransKeys.CREATE_MONITORED_METRIC_FORM.TABS.SELECT_METRIC.METRIC_DEFINITION.TITLE)}
      subTitle={t(
        TransKeys.CREATE_MONITORED_METRIC_FORM.TABS.SELECT_METRIC.METRIC_DEFINITION.SUB_TITLE
      )}
      className={className}
      error={hasError}
    >
      <div className={classes.Definition}>
        <div className={classes.Item}>
          <MetricForSignalSmartSelector
            placeholder={t(
              TransKeys.CREATE_MONITORED_METRIC_FORM.TABS.METRIC_DEFINITION.KPI.SELECT_KPI
            )}
            value={signalId}
            onSignalInfo={onSignalInfo as any}
            error={exists(get(errors, `parameters.${GOAL_KEY}`))}
            onCreate={onCreateKPI}
            onChange={onMetricSelected}
            filters={metricFilters}
            addButton
          />
        </div>
        {showSelectMode && (
          <>
            <div className={classes.Label}>as</div>
            <div className={classes.Item}>
              <SwitchActions showActionsLabel actions={switchActions} />
            </div>
          </>
        )}
      </div>
    </ParameterInputWrapper>
  );
};

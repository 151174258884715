import * as React from 'react';
import classNames from 'classnames';
import classes from './homepage-list-item.module.scss';
import {HomepageListMode} from '../homepage-list.component';
import {Tooltip} from '@material-ui/core';
import TransKeys from '../../../../../../../constants/translation-keys';
import {withStopPropagation} from 'front-core';
import {
  CheckSolidIcon,
  CloseRegularIcon,
  PlusSolidIcon,
  TooltipIfOverflow,
  TrendChip,
} from 'ui-components';
import {Sparklines, SparklinesLine} from 'react-sparklines';
import {useTranslation} from 'react-i18next';
import {ModelSeriesGranularity} from '../../../../../../../objects/models/model-sample-series.model';

interface ListItemData {
  name: string;
  granularity: ModelSeriesGranularity;
  samples?: number[];
  renderValue: string;
  trend?: {
    value: number;
    higherIsBetter?: boolean;
    isSignificant?: boolean;
  };
}

interface OwnProps {
  item: ListItemData;
  subTitle?: string | React.ReactNode;
  mode: HomepageListMode;
  onClick: () => void;
  onRemove: () => void;
  onAdd: () => void;
  isSelected?: boolean;
  isOnHomepage: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const HomepageListItem: React.FC<AllProps> = (props: AllProps) => {
  const {item, subTitle, mode, onClick, onRemove, onAdd, isSelected, isOnHomepage, className} =
    props;
  const {t} = useTranslation();

  return (
    <div
      onClick={() => onClick()}
      className={classNames(
        classes.MetricItemWrapper,
        isSelected && classes.Selected,
        mode === HomepageListMode.EDIT && classes.EditMode,
        className
      )}
    >
      <div className={classes.MetricItem}>
        <div className={classes.Start}>
          {mode === HomepageListMode.EDIT && (
            <Tooltip
              title={t(TransKeys.HOMEPAGE.ACTIONS.DELETE)}
              interactive={false}
              placement={'top'}
            >
              <div onClick={withStopPropagation(() => onRemove())} className={classes.Delete}>
                <CloseRegularIcon className={classes.Icon} />
              </div>
            </Tooltip>
          )}
          {mode === HomepageListMode.SEARCH && (
            <Tooltip
              title={
                isOnHomepage
                  ? t(TransKeys.HOMEPAGE.ACTIONS.DELETE)
                  : t(TransKeys.GENERAL.ACTIONS.ADD_KPI)
              }
              interactive={false}
              placement={'top'}
            >
              <div
                onClick={() => (isOnHomepage ? onRemove() : onAdd())}
                className={classNames(classes.Attach, isOnHomepage && classes.Active)}
              >
                {isOnHomepage ? (
                  <CheckSolidIcon className={classes.Icon} />
                ) : (
                  <PlusSolidIcon className={classes.Icon} />
                )}
              </div>
            </Tooltip>
          )}
        </div>
        <div className={classes.Main}>
          <TooltipIfOverflow title={item.name}>
            <div className={classes.Title}>{item.name}</div>
          </TooltipIfOverflow>
          {subTitle}
        </div>
        <div className={classes.Chart}>
          {item.samples && (
            <Sparklines height={30} width={50} data={item.samples}>
              <SparklinesLine
                style={{fill: 'none', strokeWidth: 2}}
                color={isSelected ? 'rgba(52, 131, 255, 1)' : 'rgba(33, 33, 35, 1)'}
              />
            </Sparklines>
          )}
        </div>
        <div className={classes.Stats}>
          <div className={classes.Value}>{item.renderValue}</div>
          {item.trend && (
            <Tooltip
              title={t(TransKeys.HOMEPAGE.METRIC_TREND_HELPER_TEXT, {
                granularity: item.granularity,
              })}
              placement={'top'}
              interactive={false}
            >
              <TrendChip
                value={item.trend.value}
                isSignificant={item.trend.isSignificant}
                size={'xsmall'}
              />
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

HomepageListItem.defaultProps = {
  subTitle: null,
};

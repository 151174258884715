import * as React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {useCallback, useContext, useMemo, useState} from 'react';
import {
  AnomalyMode,
  HomepageSubscriptionVIA,
} from '../../../../../../objects/models/homepage.model';
import classes from '../../homepage-subscription-panel.module.scss';
import {useTranslation} from 'react-i18next';
import {Tooltip} from '@material-ui/core';
import TransKeys from 'translations';
import {ChevronDownRegularIcon, ToggleSwitch} from 'ui-components';
import classNames from 'classnames';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import {COMPANIES_LOGOS} from '../../../../../../constants/ui';
import {StandardCheckBox} from '../../../../../shared/components/general/standard-check-box/standard-check-box.component';
import {SubscribedSlackChannelsInput} from '../subscribed-slack-channels-input/subscribed-slack-channels-input.component';
import {useProductData} from '../../../../../../core/hooks/use-product-data.hook';
import {SlackChannel} from '../../../../../analyses/panels/share-resource-form-panel/components/share-direct-slack-form-fields/share-direct-slack-form-fields.component';
import {SignificancyDefinitionSelector} from '../significancy-definition-selector/significancy-definition-selector.component';
import {Team} from '../../../../../../objects/models/team.model';
import {SubscribedSegmentsSelector} from '../subscribed-segments-selector/subscribed-segments-selector.component';
import {PanelKey} from '../../../../../../constants/panels';
import {TEAM_ID_PATH_PARAM} from '../../../../../../constants/app-routes';
import {PanelsContext} from '../../../../../../core/contexts/panels.context';

interface OwnProps {
  isScrolled: boolean;
  channels: SlackChannel[];
  selectedTeam: Team;
}
type AllProps = OwnProps;

export const SubscriptionConfig: React.FC<AllProps> = (props: AllProps) => {
  const {isScrolled, channels, selectedTeam} = props;
  const [collapse, setCollapse] = useState(false);
  const {installedSlackToWorkspace} = useProductData();
  const {openSecondaryPanel} = useContext(PanelsContext);
  const {t} = useTranslation();
  const {
    formState: {errors},
    control,
    setValue,
    watch,
  } = useFormContext();
  const via = watch('via');
  const isActive = watch('isActive');
  const anomalyMode = watch('anomalyMode');
  const anomalyThreshold = watch('anomalyThreshold');
  const subscribedSegmentsType = watch('subscribedSegmentsType');

  const showActivateTooltip = useMemo(() => !isActive && !isScrolled, [isActive, isScrolled]);

  const onViaChange = useCallback(
    (viaChannel: HomepageSubscriptionVIA) => {
      let newVia;
      if (via.includes(viaChannel)) {
        newVia = via.filter(v => v !== viaChannel);
      } else {
        newVia = [...via, viaChannel];
      }
      setValue('via', newVia, {shouldValidate: true});
      if (viaChannel === HomepageSubscriptionVIA.SLACK) {
        setValue('slackChannels', [], {shouldValidate: true});
      }
    },
    [setValue, via]
  );
  const onTeamSegmentsClick = useCallback(() => {
    openSecondaryPanel(PanelKey.FAVORITES_SEGMENTS_PANEL, {
      [TEAM_ID_PATH_PARAM]: selectedTeam.id,
    });
  }, [openSecondaryPanel, selectedTeam]);
  const handleCollapse = useCallback(() => setCollapse(prev => !prev), [setCollapse]);

  return (
    <div
      className={classNames(
        classes.CollapsibleHomepageSubscriptionSettingsContainer,
        collapse && classes.Collapse,
        !collapse && classes.Expand
      )}
    >
      <div className={classNames(classes.Block, classes.HeadBlock)}>
        <div className={classes.Control}>
          <div className={classes.Activation}>
            <Controller
              render={({field}) => (
                <Tooltip
                  title={t(TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.ACTIVATE_TOOLTIP)}
                  placement={'left'}
                  open={showActivateTooltip}
                  arrow
                >
                  <div>
                    <ToggleSwitch
                      value={isActive}
                      onChange={() => setValue('isActive', !isActive)}
                    />
                  </div>
                </Tooltip>
              )}
              name={'isActive'}
              control={control}
            />
            <div className={classes.Title} onClick={handleCollapse}>
              {t(TransKeys.HOMEPAGE_SUBSCRIPTION_CONFIG.TITLE)}
            </div>
          </div>
          <ChevronDownRegularIcon
            className={classNames(
              classes.Chevron,
              !collapse && classes.Expand,
              collapse && classes.Collapse
            )}
            onClick={handleCollapse}
          />
        </div>
        <div className={classes.VIA}>
          <div className={classes.Description}>
            {t(TransKeys.HOMEPAGE_SUBSCRIPTION_CONFIG.DESCRIPTION)}
          </div>
          <div className={classes.OptionsContainer}>
            <div className={classes.Option}>
              <MailOutlineIcon />
            </div>
            <div className={classes.Option}>
              <img alt={'Slack icon'} src={COMPANIES_LOGOS.slack} className={classes.SlackIcon} />
            </div>
            <div className={classes.Option}>
              <StandardCheckBox
                onChange={() => onViaChange(HomepageSubscriptionVIA.MAIL)}
                checked={via.includes(HomepageSubscriptionVIA.MAIL)}
                disabled={!isActive}
                error={Boolean(errors.via)}
                size={'small'}
              />
            </div>
            <div className={classes.Option}>
              <StandardCheckBox
                onChange={() => onViaChange(HomepageSubscriptionVIA.SLACK)}
                checked={via.includes(HomepageSubscriptionVIA.SLACK)}
                disabled={!isActive || !installedSlackToWorkspace}
                error={Boolean(errors.via)}
                size={'small'}
              />
            </div>
          </div>
        </div>
        <SubscribedSlackChannelsInput className={classes.SlackChannels} channels={channels} />
      </div>
      <div className={classes.Block}>
        <div className={classes.Description}>What is significant change</div>
        <SignificancyDefinitionSelector
          anomalyMode={anomalyMode}
          anomalyThreshold={anomalyThreshold}
          onChange={(anomalyMode: AnomalyMode, threshold: number) => {
            setValue('anomalyThreshold', threshold);
            setValue('anomalyMode', anomalyMode);
          }}
          disabled={!isActive}
        />
        <SubscribedSegmentsSelector
          subscribedSegmentsType={subscribedSegmentsType}
          onChangeSubscribedSegmentsType={type => setValue('subscribedSegmentsType', type)}
          team={selectedTeam}
          onTeamSegmentsClick={onTeamSegmentsClick}
          disabled={!isActive}
          className={classes.SubscribedSegments}
        />
      </div>
    </div>
  );
};

import {NRC} from './index';
import {RequestType} from 'front-core';
import {toCamelCase, toSnakeCase} from 'front-core';
import {UserSettingsDTO} from 'src/objects/dto/user-settings.dto';

export const changeUserDefaultTeam: NRC<any, void> = (defaultTeamId: number) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/user/default-team`,
  body: {teamId: defaultTeamId},
  bodyTransformer: data => toSnakeCase(data),
  responseTransformer: res => toCamelCase(res.data),
});

export const getUserSettingsNetworkRequest: NRC<void, UserSettingsDTO> = () => ({
  method: RequestType.GET,
  relativeUrl: `/app/user/settings`,
  responseTransformer: res => toCamelCase(res.data),
});

export const updateUserSettingsNetworkRequest: NRC<UserSettingsDTO, void> = (
  data: UserSettingsDTO
) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/user/settings`,
  body: data,
  bodyTransformer: data => toSnakeCase(data),
  responseTransformer: res => toCamelCase(res.data),
});

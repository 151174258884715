import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import classes from './select-metric-tab.module.scss';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {sharedClasses} from '../../../../../shared';
import {Button, Select, SwitchActions} from 'ui-components';
import TransKeys from '../../../../../../constants/translation-keys';
import {FormStep} from '../../../../../shared/components/layout/form-step/form-step.component';
import classNames from 'classnames';
import {TabHeader} from '../../../../../shared/components/general/tab-header/tab-header.component';
import {preventSubmitOnEnter} from '../../../../../../utils/general.utils';
import {
  MonitoredMetricDTO,
  monitoredMetricDTOValidator,
} from '../../../../../../objects/dto/health-monitor.dto';
import {useProductData} from '../../../../../../core/hooks/use-product-data.hook';
import {TableEntity} from '../../../../../../objects/models/table.model';
import {ParameterInputWrapper} from '../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {FormHiddenInputs} from '../../../../../shared/form/components/form-hidden-inputs.component';
import {MonitoredMetricType} from '../../../../../../objects/models/health-monitor.model';
import {
  ANALYSIS_MODE_KEY,
  GOAL_KEY,
} from '../../../../../analyses/analysis-forms/analysis-parameters/analysis-101/analysis-101-form.component';
import {AnalysisTypeId} from '../../../../../../constants/analysis-type-id';
import {ExtendedParameters} from '../../../../../shared/form/form-layout/extended-parameters/extended-parameters.component';
import {SegmentFilterSelector} from '../../../../../analyses/analysis-forms/components/ui-selectors/segment-filter-selector/segment-filter-selector.component';
import {PanelKey} from '../../../../../../constants/panels';
import {PanelsContext} from '../../../../../../core/contexts/panels.context';
import {MonitoredMetricKPIDefinition} from './metric-definition/kpi-definition.component';
import {MonitoredMetricFunnelDefinition} from './metric-definition/funnel-definition.component';
import {FUNNEL_PARAMETER_KEY} from 'src/modules/analyses/analysis-forms/analysis-parameters/analysis-49/analysis-49-form.component';
import {MonitoredMetricSegmentDefinition} from './metric-definition/segment-definition.component';
import usePermissions from '../../../../../../core/hooks/use-permissions.hook';
import {Action, Subject} from '../../../../../../constants/permissions';
import {TimeGranularitySelector} from '../../../../../analyses/analysis-forms/components/ui-selectors/time-granularity-selector/time-granularity-selector.component';
import {getEntityIcon} from '../../../../../../constants/entity.consts';

interface OwnProps {
  data: Partial<MonitoredMetricDTO>;
  onSubmit: (data: Partial<MonitoredMetricDTO>) => void;
  onSignalInfo: (signalId: number) => void;
  onBack?: () => void;
  submitText?: string;
  className?: string;
}

type AllProps = OwnProps;

const METRIC_TYPE_OPTIONS = [
  {value: MonitoredMetricType.KPI, label: 'KPI'},
  {value: MonitoredMetricType.FUNNEL, label: 'Funnel'},
  {value: MonitoredMetricType.SEGMENT, label: 'Segment'},
];

const PARAMETER_KEYS = ['entity', 'analysisTypeId', 'metricType', 'parameters'];

const TIME_GRANULARITY_SCHEMA_MAPPING = {
  time_granularity: 'time_aggregation',
};

export const METRIC_ID_KEY = 'metric_id';

export const SelectMetricTab: React.FC<AllProps> = (props: AllProps) => {
  const {data, onSubmit, onBack, onSignalInfo, submitText, className} = props;
  const {t} = useTranslation();
  const {can} = usePermissions();
  const {openSecondaryPanel} = useContext(PanelsContext);
  const {productEntities} = useProductData();
  const [isOpenAdvancedParams, setIsOpenAdvancedParams] = useState(false);

  const formMethods = useForm({
    defaultValues: data as any,
    resolver: yupResolver(monitoredMetricDTOValidator),
  });
  const {
    handleSubmit,
    setValue,
    watch,
    formState: {errors},
  } = formMethods;
  const entity: TableEntity = watch('entity');
  const metricType: MonitoredMetricType = watch('metricType');
  const parameters: any = watch('parameters');
  const metricTypeOptions = useMemo(() => ({options: METRIC_TYPE_OPTIONS}), []);
  const metricTypeLabel = useMemo(
    () => METRIC_TYPE_OPTIONS.find(i => i.value === metricType)?.label,
    [metricType]
  );
  const setParameters = useCallback(
    (newParameters: any) => {
      setValue('parameters', {
        ...parameters,
        ...newParameters,
      });
    },
    [parameters, setValue]
  );
  const clearParameters = useCallback(() => {
    setValue('parameters', {});
  }, [setValue]);
  const onChangeEntity = useCallback(
    (entity: TableEntity) => {
      clearParameters();
      setValue('entity', entity);
    },
    [setValue, clearParameters]
  );
  const onMetricTypeChange = useCallback(
    metricType => {
      clearParameters();
      setValue('metricType', metricType);
    },
    [clearParameters, setValue]
  );
  const onCreateKPI = useCallback(() => {
    openSecondaryPanel(PanelKey.METRIC_FORM_PANEL, {
      onSuccess: metric => {
        setParameters({
          [GOAL_KEY]: metric.signalId,
          [ANALYSIS_MODE_KEY]: undefined,
          [METRIC_ID_KEY]: metric.id,
        });
        setValue('name', metric.name);
      },
    });
  }, [openSecondaryPanel, setParameters, setValue]);
  const onCreateFunnel = useCallback(() => {
    openSecondaryPanel(PanelKey.FUNNEL_FORM_PANEL, {
      onSuccess: funnel => {
        setParameters({
          [FUNNEL_PARAMETER_KEY]: funnel.id,
        });
        setValue('name', funnel.name);
      },
    });
  }, [openSecondaryPanel, setParameters, setValue]);
  const entityOptions = useMemo(
    () =>
      productEntities.map(p => ({
        key: p.key,
        label: p.name,
        onClick: () => onChangeEntity(p.key),
        isActive: entity === p.key,
        icon: getEntityIcon(p.key),
      })),
    [productEntities, entity, onChangeEntity]
  );

  // Responsible from setting analysis type id
  useEffect(() => {
    if (metricType === MonitoredMetricType.KPI) {
      setValue('analysisTypeId', AnalysisTypeId.KPI_ANALYSIS);
      return;
    }
    if (metricType === MonitoredMetricType.FUNNEL) {
      setValue('analysisTypeId', AnalysisTypeId.FUNNEL_ANALYSIS);
      return;
    }
    if (metricType === MonitoredMetricType.SEGMENT) {
      setValue('analysisTypeId', AnalysisTypeId.USERS_ANALYSIS);
      return;
    }
  }, [metricType, setValue]);

  const renderMetricDefinition = () => {
    const sharedProps = {
      parameters: parameters,
      setParameters: setParameters,
      onSignalInfo: onSignalInfo,
      onCreateKPI: onCreateKPI,
      errors: errors,
      entityContext: entity,
      className: classes.Parameter,
    };
    if (metricType === MonitoredMetricType.KPI) {
      return (
        <MonitoredMetricKPIDefinition
          {...sharedProps}
          onCreateKPI={can(Subject.METRIC, Action.CREATE) ? onCreateKPI : undefined}
        />
      );
    }
    if (metricType === MonitoredMetricType.FUNNEL) {
      return (
        <MonitoredMetricFunnelDefinition
          {...sharedProps}
          onCreateFunnel={can(Subject.FUNNEL, Action.CREATE) ? onCreateFunnel : undefined}
        />
      );
    }
    if (metricType === MonitoredMetricType.SEGMENT) {
      return <MonitoredMetricSegmentDefinition {...sharedProps} />;
    }
  };

  const renderAdvancedParameters = () => {
    const localErrors = errors['parameters'];
    const additional = [];
    if (metricType === MonitoredMetricType.KPI) {
      additional.push(
        <TimeGranularitySelector
          value={parameters}
          schemaKeysMapping={TIME_GRANULARITY_SCHEMA_MAPPING}
          onChange={setParameters}
          errors={localErrors}
        />
      );
    }

    return (
      <ExtendedParameters
        className={classes.SpaceBottom}
        label={t(TransKeys.GENERAL.LABELS.ADVANCED_PARAMETERS)}
        isOpen={isOpenAdvancedParams}
        onOpenChanged={() => setIsOpenAdvancedParams(!isOpenAdvancedParams)}
      >
        {entity && (
          <SegmentFilterSelector
            title={t(
              TransKeys.CREATE_MONITORED_METRIC_FORM.TABS.SELECT_METRIC.SEGMENT_FILTER.TITLE
            )}
            subTitle={t(
              TransKeys.CREATE_MONITORED_METRIC_FORM.TABS.SELECT_METRIC.SEGMENT_FILTER.SUB_TITLE,
              {type: metricTypeLabel}
            )}
            onChange={setParameters}
            value={parameters}
            className={classes.Parameter}
            entityContext={entity}
            errors={localErrors}
          />
        )}
        {additional}
      </ExtendedParameters>
    );
  };

  return (
    <FormProvider {...formMethods}>
      <form
        onKeyDown={preventSubmitOnEnter}
        className={classNames(sharedClasses.NoOverflow, className)}
      >
        <FormHiddenInputs names={PARAMETER_KEYS} />
        <FormStep
          footer={
            <>
              {onBack && (
                <Button variant={'outlined'} type={'button'} onClick={onBack}>
                  {t(TransKeys.GENERAL.ACTIONS.BACK)}
                </Button>
              )}
              <Button onClick={handleSubmit(onSubmit)}>{submitText}</Button>
            </>
          }
        >
          <div className={classes.SelectMetricTab}>
            <TabHeader
              className={classes.Header}
              title={t(TransKeys.CREATE_MONITORED_METRIC_FORM.STEPS.SELECT_METRIC_TITLE)}
              subTitle={t(TransKeys.CREATE_MONITORED_METRIC_FORM.STEPS.SELECT_METRIC_SUBTITLE)}
            />
            {entityOptions.length > 1 && (
              <ParameterInputWrapper
                title={t(
                  TransKeys.CREATE_MONITORED_METRIC_FORM.TABS.SELECT_METRIC.ENTITY_TYPE.TITLE
                )}
                subTitle={t(
                  TransKeys.CREATE_MONITORED_METRIC_FORM.TABS.SELECT_METRIC.ENTITY_TYPE.SUB_TITLE
                )}
                className={classes.Parameter}
              >
                <SwitchActions actions={entityOptions} showActionsLabel />
              </ParameterInputWrapper>
            )}
            <ParameterInputWrapper
              title={t(TransKeys.CREATE_MONITORED_METRIC_FORM.TABS.SELECT_METRIC.METRIC_TYPE.TITLE)}
              subTitle={t(
                TransKeys.CREATE_MONITORED_METRIC_FORM.TABS.SELECT_METRIC.METRIC_TYPE.SUB_TITLE
              )}
              className={classes.Parameter}
            >
              <Select
                value={metricType}
                onChange={onMetricTypeChange}
                options={metricTypeOptions}
                clearable={false}
                searchable={false}
              />
            </ParameterInputWrapper>

            {renderMetricDefinition()}
            {renderAdvancedParameters()}
          </div>
        </FormStep>
      </form>
    </FormProvider>
  );
};

SelectMetricTab.defaultProps = {
  submitText: 'Save',
};

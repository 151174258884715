import {RequestType} from 'front-core';
import {LoginDTO} from '../objects/dto/login.dto';
import {NRC} from './index';
import {MFADTO, ResendMFADTO} from '../objects/dto/mfa.dto';
import {toCamelCase, toSnakeCase} from 'front-core';
import {ForgotPasswordFormDto} from '../objects/dto/forgot-password-form.dto';
import {SetPasswordFormDto} from '../objects/dto/set-password-form.dto';
import {SignUpDto} from '../objects/dto/sign-up.dto';
import {InviteSignupFormDTO} from '../objects/dto/invite-signup-form.dto';

export const getAuthUserNetworkRequest: NRC<void, void> = () => ({
  method: RequestType.GET,
  relativeUrl: `/auth`,
  responseTransformer: res => toCamelCase(res.data),
});

export const getHubspotVisitorTokenNetworkRequest: NRC<void, string> = () => ({
  method: RequestType.GET,
  relativeUrl: `/auth/hubspot-visitor-token`,
  responseTransformer: res => toCamelCase(res.data),
});

export const loginNetworkRequest: NRC<LoginDTO, void> = (payload: LoginDTO) => ({
  method: RequestType.POST,
  relativeUrl: `/auth/login`,
  body: payload,
  bodyTransformer: toSnakeCase,
  responseTransformer: toCamelCase,
});

export const mfaNetworkRequest: NRC<MFADTO, void> = (payload: MFADTO) => ({
  method: RequestType.POST,
  relativeUrl: `/auth/mfa`,
  body: payload,
  bodyTransformer: toSnakeCase,
  responseTransformer: toCamelCase,
});

export const resendMFANetworkRequest: NRC<ResendMFADTO, void> = (payload: ResendMFADTO) => ({
  method: RequestType.POST,
  relativeUrl: `/auth/mfa/resend`,
  body: payload,
  bodyTransformer: toSnakeCase,
  responseTransformer: toCamelCase,
});

export const logoutNetworkRequest: NRC<void, void> = () => ({
  method: RequestType.GET,
  relativeUrl: `/auth/logout`,
});

export const forgotPasswordNetworkRequest: NRC<ForgotPasswordFormDto, void> = (
  payload: ForgotPasswordFormDto
) => ({
  method: RequestType.POST,
  relativeUrl: `/auth/forgot-password`,
  body: payload,
});

export const setPasswordNetworkRequest: NRC<SetPasswordFormDto, void> = (
  payload: SetPasswordFormDto
) => ({
  method: RequestType.POST,
  relativeUrl: `/auth/set-password`,
  body: payload,
  bodyTransformer: toSnakeCase,
});

export const getUserDataForSetPasswordNetworkRequest: NRC<void, string> = (payload: any) => ({
  method: RequestType.GET,
  relativeUrl: `/auth/user-data`,
  query: payload,
  responseTransformer: res => toCamelCase(res.data),
});

export const signUpNetworkRequest: NRC<SignUpDto, void> = (payload: SignUpDto) => ({
  method: RequestType.POST,
  relativeUrl: `auth/sign-up`,
  body: payload,
  bodyTransformer: toSnakeCase,
});

export const inviteSignupNetworkRequest: NRC<InviteSignupFormDTO, void> = (
  payload: InviteSignupFormDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/auth/invite-signup`,
  body: payload,
  bodyTransformer: toSnakeCase,
});

import * as React from 'react';
import {useMemo, useState} from 'react';
import classNames from 'classnames';
import classes from './analyses-navigation.module.scss';
import {
  BoxArchiveDuoToneIcon,
  ChevronDownRegularIcon,
  ClockRotateLeftDuoToneIcon,
  EyeDuotoneIcon,
  SkeletonLoading,
  StarSolidIcon,
  TeamIconChip,
  UserGroupDuotoneIcon,
  UserLockDuotoneIcon,
} from 'ui-components';
import {
  AnalysisFoldersTree,
  AnalysisFolderTreeNode,
} from '../../../../../../objects/models/folders-tree.model';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {AnalysesMainViews, DEFAULT_FOLDER_ICON} from '../../analysis-main.types';
import {useIsAdmin} from '../../../../../../core/hooks/use-is-admin.hook';
import {Tree} from 'antd';
import type {DataNode} from 'antd/es/tree';
import {isEmpty, keys, take, values} from 'lodash';
import {AnalysisFolderType} from '../../../../../../objects/models/analysis-folder.model';
import {translateAnalysisFolderName} from '../../analyses.utils';
import {exists} from 'front-core';

interface OwnProps {
  foldersTree: AnalysisFoldersTree;
  selectedFolder: string | number;
  onChangeFolder: (folder: string | number) => void;
  sharedAnalysesCount: number;
  isLoading?: boolean;
  className?: string;
}

type AllProps = OwnProps;

enum NavigationArea {
  PUBLIC = 'public',
  SHARED = 'shared',
  PRIVATE = 'private',
}

const ICON_FOR_TYPE = {
  [AnalysesMainViews.RECENT]: ClockRotateLeftDuoToneIcon,
  [AnalysesMainViews.FAVORITES]: StarSolidIcon,
  [AnalysesMainViews.ARCHIVED]: BoxArchiveDuoToneIcon,
};

const ICON_FOR_AREA = {
  [NavigationArea.PUBLIC]: EyeDuotoneIcon,
  [NavigationArea.SHARED]: UserGroupDuotoneIcon,
  [NavigationArea.PRIVATE]: UserLockDuotoneIcon,
};

const foldersToTree = (tree: AnalysisFolderTreeNode[]): DataNode[] => {
  const queue: any[] = [...tree];
  for (const t of queue) {
    t['key'] = t.id.toString();
    t['title'] = translateAnalysisFolderName(t.name);
    let Icon;
    if (t.type === AnalysisFolderType.PUBLIC && t.isRoot === true) {
      Icon = ICON_FOR_AREA[NavigationArea.PUBLIC];
    }
    if (t.type === AnalysisFolderType.PRIVATE && t.isRoot === true) {
      Icon = ICON_FOR_AREA[NavigationArea.PRIVATE];
    }
    t['icon'] = Icon ? (
      <Icon className={classes.RootNavIcon} />
    ) : (
      t['icon'] || <DEFAULT_FOLDER_ICON className={classes.NavIcon} />
    );
    if (t.team) {
      t['icon'] = <TeamIconChip {...t.team} />;
    }
    if (t.children) {
      queue.push(...t.children);
    }
  }
  return tree as any;
};

const INITIAL_DISPLAY_COUNT = 3;
const MAX_DISPLAY_COUNT = 1000;

const INITIAL_SEE_MORE_STATE = {
  [NavigationArea.PUBLIC]: INITIAL_DISPLAY_COUNT,
  [NavigationArea.SHARED]: INITIAL_DISPLAY_COUNT,
  [NavigationArea.PRIVATE]: INITIAL_DISPLAY_COUNT,
};

export const AnalysisNavigation: React.FC<AllProps> = (props: AllProps) => {
  const {foldersTree, selectedFolder, sharedAnalysesCount, onChangeFolder, className} = props;
  const {t} = useTranslation();
  const isAdmin = useIsAdmin();
  const [seeMoreState, setSeeMoreState] = useState(INITIAL_SEE_MORE_STATE);

  const rootItems = useMemo(() => {
    const items: any[] = [
      AnalysesMainViews.RECENT,
      AnalysesMainViews.FAVORITES,
      AnalysesMainViews.ARCHIVED,
    ].map(o => {
      const Icon = ICON_FOR_TYPE[o];
      return {
        key: o,
        title: t(TransKeys.ANALYSES_MAIN.FOLDERS_DEFAULT_NAME[o.toUpperCase()]['TITLE']),
        icon: <Icon className={classes.RootNavIcon} />,
        isLeaf: true,
      };
    });
    if (isAdmin) {
      items.push({
        key: AnalysesMainViews.ALL,
        title: t(TransKeys.ANALYSES_MAIN.FOLDERS_DEFAULT_NAME.ALL.TITLE),
        isLeaf: true,
      });
    }
    return items;
  }, [t, isAdmin]);

  const tree: any = useMemo(() => {
    if (!foldersTree) {
      return {};
    }
    return {
      [NavigationArea.PUBLIC]: foldersToTree(foldersTree.public),
      [NavigationArea.SHARED]: [
        {
          key: AnalysesMainViews.SHARED,
          value: AnalysesMainViews.SHARED,
          title: t(TransKeys.ANALYSES_MAIN.FOLDERS_DEFAULT_NAME.__SHARED.TITLE),
          children: foldersToTree(foldersTree.shared),
          icon: <UserGroupDuotoneIcon className={classes.RootNavIcon} />,
        },
      ],
      [NavigationArea.PRIVATE]: foldersToTree(foldersTree.private),
    };
  }, [foldersTree, t]);
  const renderTreeData = useMemo(() => {
    const res: any = {};
    if (isEmpty(tree)) {
      return {};
    }
    for (const k of keys(tree)) {
      if (!tree[k][0]) {
        continue;
      }
      res[k] = {
        total: tree[k][0].children.length,
        parent: [
          {
            ...tree[k][0],
            children: [],
          },
        ],
        data: take(tree[k][0].children, seeMoreState[k]),
      };
    }
    return res;
  }, [tree, seeMoreState]);
  const selectedKeys = useMemo(
    () => (exists(selectedFolder) ? [selectedFolder.toString()] : []),
    [selectedFolder]
  );
  const defaultExpandedKeys = useMemo(() => values(tree).map(t => t[0].key), [tree]);
  const renderTree = (k: NavigationArea, withSeparator?: boolean) => {
    if (renderTreeData[k] === undefined) {
      return (
        <div className={classes.SkeletonLoadingWrapper}>
          <SkeletonLoading />
          <SkeletonLoading />
          <SkeletonLoading />
          <SkeletonLoading />
        </div>
      );
    }
    if (
      k === NavigationArea.SHARED &&
      renderTreeData[k].data.length === 0 &&
      sharedAnalysesCount === 0
    ) {
      return null;
    }
    return (
      <React.Fragment key={k}>
        <Tree
          className={classes.ParentTree}
          showIcon
          treeData={renderTreeData[k].parent}
          onSelect={([v]) => v && onChangeFolder(v)}
          selectedKeys={selectedKeys}
          checkStrictly
          defaultExpandedKeys={defaultExpandedKeys}
        />
        <Tree
          className={classes.MainTree}
          showIcon
          treeData={renderTreeData[k].data}
          onSelect={([v]) => v && onChangeFolder(v)}
          selectedKeys={selectedKeys}
          checkStrictly
          defaultExpandedKeys={defaultExpandedKeys}
        />
        {renderTreeData[k].total > INITIAL_DISPLAY_COUNT && (
          <div
            className={classNames(
              classes.SeeAll,
              seeMoreState[k] === MAX_DISPLAY_COUNT && classes.Open
            )}
            onClick={() =>
              setSeeMoreState(seeMore => ({
                ...seeMore,
                [k]: seeMore[k] === MAX_DISPLAY_COUNT ? INITIAL_DISPLAY_COUNT : MAX_DISPLAY_COUNT,
              }))
            }
          >
            <div>
              {seeMoreState[k] === INITIAL_DISPLAY_COUNT
                ? t(TransKeys.GENERAL.ACTIONS.MORE_COUNT, {
                    count: renderTreeData[k].total - INITIAL_DISPLAY_COUNT,
                  })
                : t(TransKeys.GENERAL.ACTIONS.SEE_LESS)}
            </div>
            <ChevronDownRegularIcon className={classes.ChevronDownIcon} />
          </div>
        )}
        {withSeparator && <div className={classes.Separator} />}
      </React.Fragment>
    );
  };

  return (
    <div className={classNames(classes.AnalysisNavigation, className)}>
      <Tree
        className={classes.RootTree}
        showIcon
        treeData={rootItems}
        onSelect={([v]) => v && onChangeFolder(v)}
        selectedKeys={selectedKeys}
        checkStrictly
        selectable
        defaultExpandedKeys={defaultExpandedKeys}
      />
      <div className={classes.Separator} />
      {renderTree(NavigationArea.PUBLIC, true)}
      {renderTree(NavigationArea.SHARED, true)}
      {renderTree(NavigationArea.PRIVATE)}
    </div>
  );
};

AnalysisNavigation.defaultProps = {};

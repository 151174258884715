import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {getSelectedCronCheckbox} from './cron-exp/cron-exp.utils';
import {CRON_DEFAULT_VALUES, CRON_SCHEDULE_KEYS} from '../../../../../../constants/cron-exp.consts';
import ScheduleCronExpItem from './cron-exp/schedule-cron-exp/schedule-cron-exp-item/schedule-cron-exp-item.component';
import classes from './cron-exp/schedule-cron-exp/schedule-cron-exp-item/schedule-cron-exp-item.module.scss';
import TransKeys from '../../../../../../constants/translation-keys';
import CronExpSelectWeekDay from './cron-exp/schedule-cron-exp/schedule-cron-exp-item/components/cron-exp-selects/cron-exp-select-week-day.component';
import CronExpSelectHour from './cron-exp/schedule-cron-exp/schedule-cron-exp-item/components/cron-exp-selects/cron-exp-select-hour.component';
import CronExpSelectMonthDay from './cron-exp/schedule-cron-exp/schedule-cron-exp-item/components/cron-exp-selects/cron-exp-select-month-day.component';
import classNames from 'classnames';
import {getTimezone} from '../../../../../../utils/general.utils';

interface OwnProps {
  onChange: (value: any) => any;
  value: string;
  showWeekly?: boolean;
  showMonthly?: boolean;
  showDaily?: boolean;
  className?: string;
}

type AllProps = OwnProps;

const CronExpGroupControl: React.FC<AllProps> = (props: AllProps) => {
  const {value: cronExp, showWeekly, showMonthly, showDaily, className} = props;
  const {t} = useTranslation();
  const selectedCheckbox = useMemo(() => cronExp && getSelectedCronCheckbox(cronExp), [cronExp]);

  const checked = useMemo(
    () => ({
      isWeeklyActive: selectedCheckbox === CRON_SCHEDULE_KEYS.WEEKLY,
      isMonthlyActive: selectedCheckbox === CRON_SCHEDULE_KEYS.MONTHLY,
      isDailyActive: selectedCheckbox === CRON_SCHEDULE_KEYS.DAILY,
    }),
    [selectedCheckbox]
  );

  return (
    <div className={classNames(className)}>
      {showWeekly && (
        <ScheduleCronExpItem
          checked={checked.isWeeklyActive}
          defaultCronExp={CRON_DEFAULT_VALUES.WEEKLY}
          {...props}
        >
          <span className={classes.Span}>{t(TransKeys.CUSTOM_SCHEDULE.EVERY)}</span>
          <CronExpSelectWeekDay disabled={!checked.isWeeklyActive} />
          <span className={classes.Span}>{t(TransKeys.CUSTOM_SCHEDULE.AT)}</span>
          <CronExpSelectHour disabled={!checked.isWeeklyActive} />
          {checked.isWeeklyActive && (
            <span className={classNames(classes.Span, classes.Timezone)}>
              {t(TransKeys.GENERAL.LABELS.TIMEZONE, {timeZone: getTimezone()})}
            </span>
          )}
        </ScheduleCronExpItem>
      )}
      {showMonthly && (
        <ScheduleCronExpItem
          checked={checked.isMonthlyActive}
          defaultCronExp={CRON_DEFAULT_VALUES.MONTHLY}
          {...props}
        >
          <span className={classes.Span}>{t(TransKeys.CUSTOM_SCHEDULE.MONTHLY_ON_THE)}</span>
          <CronExpSelectMonthDay disabled={!checked.isMonthlyActive} />
          <span className={classes.Span}>{t(TransKeys.CUSTOM_SCHEDULE.AT)}</span>
          <CronExpSelectHour disabled={!checked.isMonthlyActive} />
          {checked.isMonthlyActive && (
            <span className={classNames(classes.Span, classes.Timezone)}>
              {t(TransKeys.GENERAL.LABELS.TIMEZONE, {timeZone: getTimezone()})}
            </span>
          )}
        </ScheduleCronExpItem>
      )}
      {showDaily && (
        <ScheduleCronExpItem
          checked={checked.isDailyActive}
          defaultCronExp={CRON_DEFAULT_VALUES.DAILY}
          {...props}
        >
          <span className={classes.Span}>
            {t(TransKeys.CUSTOM_SCHEDULE.EVERY_DAY)} {t(TransKeys.CUSTOM_SCHEDULE.AT)}
          </span>
          <CronExpSelectHour disabled={!checked.isDailyActive} />
          {checked.isDailyActive && (
            <span className={classNames(classes.Span, classes.Timezone)}>
              {t(TransKeys.GENERAL.LABELS.TIMEZONE, {timeZone: getTimezone()})}
            </span>
          )}
        </ScheduleCronExpItem>
      )}
    </div>
  );
};

export default CronExpGroupControl;

import * as React from 'react';
import {Route, Switch} from 'react-router';
import {AppRoutes} from '../../constants/app-routes';
import {MetricPage} from './pages/metric-page/metric-page.component';
import {KPITreePage} from './pages/kpi-tree-page/kpi-tree-page.component';

export const MetricsRouting: React.FC = props => {
  return (
    <Switch>
      <Route path={AppRoutes.kpiTree().pathname} component={KPITreePage} exact />
      <Route path={AppRoutes.viewMetric().pathname} component={MetricPage} exact />
    </Switch>
  );
};

import * as React from 'react';
import classNames from 'classnames';
import classes from './parameter-input-wrapper.module.scss';
import {HoverHelperTip} from 'ui-components';

interface OwnProps {
  children: any;
  title: string;
  subTitle?: any;
  helperText?: any;
  bottomHelper?: any;
  className?: string;
  error?: boolean;
}

type AllProps = OwnProps;

export const ParameterInputWrapper: React.FC<AllProps> = (props: AllProps) => {
  const {children, title, subTitle, helperText, bottomHelper, error, className} = props;
  return (
    <div className={classNames(classes.ParameterInputWrapper, error && classes.Error, className)}>
      <div className={classes.Header}>
        <div className={classes.Title}>
          <div className={classes.TitleText}>{title}</div>
          {helperText && <HoverHelperTip className={classes.HelperText} title={helperText || ''} />}
        </div>
        {subTitle && <div className={classes.SubTitle}>{subTitle}</div>}
      </div>
      <div className={classes.Content}>
        {children}
        {bottomHelper && <div className={classes.BottomHelper}>{bottomHelper}</div>}
      </div>
    </div>
  );
};

import {NRC} from './index';
import {RequestType} from 'front-core';
import {toCamelCase, toSnakeCase} from 'front-core';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';

export const searchSignalsNetworkRequest: NRC<void, any> = (value: any, payload: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/signals`,
  query: {q: value, ...payload},
  queryTransformer: toFiltersDTO,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data.data, ['definition']),
});

export const getSignalsNetworkRequest: NRC<void, any> = (filters: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/signals`,
  query: filters,
  queryTransformer: toFiltersDTO,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const getSignalNetworkRequest: NRC<void, any> = (id: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/signals/${id}`,
  responseTransformer: res => toCamelCase(res.data, ['definition']),
});

export const getSignalInfoNetworkRequest: NRC<void, any> = (id: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/signals/${id}/info`,
  responseTransformer: res => toCamelCase(res.data, ['definition']),
});

export const getSignalSQLNetworkRequest: NRC<void, any> = (id: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/signals/${id}/compile`,
  responseTransformer: res => res.data.data,
});

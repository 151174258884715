export enum NotificationType {
  RUN_ANALYSIS = 'run_analysis',
  SCAN_TABLE_EVENTS = 'scan_table_events',
  SCAN_ALL_TABLES_EVENTS = 'scan_all_tables_events',
  SCAN_TABLE_SCHEMA = 'scan_table_schema',
  SCAN_ALL_TABLES_SCHEMA = 'scan_all_tables_schema',
  SCAN_SOURCE = 'scan_source',
  SCAN_SEGMENT_VALUES = 'scan_segment_values',
  SCAN_CONTENT_VALUES = 'scan_content_values',
  SCAN_METRIC_SAMPLES = 'scan_metric_samples',
  SCAN_FUNNEL_SAMPLES = 'scan_funnel_samples',
}

export enum NotificationJobStatus {
  STARTING = 'starting',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  FAILED = 'failed',
  STOPPED = 'stopped',
}

export enum NotificationModelType {
  TABLE = 'table',
  SEGMENT = 'segment',
  CONTENT = 'content',
  METRIC = 'metric',
  ANALYSIS_RESULT = 'analysis_result',
  SOURCE = 'source',
  EXPERIMENT = 'experiment',
}

export interface Notification {
  id: number;
  productId: number;
  userId: number;
  type: NotificationType;
  modelId: number;
  modelType: NotificationModelType;
  jobTotalStepsCount: number;
  jobCurrentStepNumber: number;
  jobCurrentProgressText: string;
  jobStatus: NotificationJobStatus;
  jobErrorMessage: string;
  model: {
    id: number;
    name: string;
    analysisId?: number;
  };
  isSeen: boolean;
  isRead: boolean;
  createdOn: string;
  updatedOn: string;
}

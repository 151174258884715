import yup from '../../../../../config/yup.config';
import {PARAMETERS_METADATA_KEY} from '../../../../../constants/parameters-saved-keys';
import {values} from 'lodash';
import {TableEntity} from '../../../../../objects/models/table.model';
import {queryElementValidatorFactory} from '../../../../../objects/dto/query-builder.dto';
import {
  queryValidator,
  startEndDatesValidator,
  treatmentValidator,
  validateSignalBoundingDays,
  validateSignalTimeframeBoundingFor,
} from '../shared-validators';
import {ParameterType} from 'ui-components';
import {
  AnalysisParametersTransformer,
  baseAnalysisParametersTransformer,
} from '../shared-transformers';

export const analysis102ParametersValidator = yup.object().shape({
  parameters: yup
    .object()
    .shape({
      [PARAMETERS_METADATA_KEY]: yup.object().nullable(),
      entity: yup.string().oneOf(values(TableEntity)).required(),
      goal_query: queryValidator(),
      population_filter: queryElementValidatorFactory(false),
      treatments_tag: yup.string().nullable(),
      group_by: yup.number().nullable(),
      strategy: yup.string().oneOf(['retention', 'conversion', 'existing']).required(),
      ...treatmentValidator(),
      days_from_reference: yup
        .number()
        .min(1)
        .required()
        .test(
          validateSignalBoundingDays({
            signalQueryParameter: 'goal_query',
          })
        ),
    })
    .required(),
  runParameters: startEndDatesValidator.required().test(
    validateSignalTimeframeBoundingFor({
      signalQueryParameter: 'goal_query',
      parameterName: 'analysis goal',
    })
  ),
});

export const analysis102ParametersTransformer: AnalysisParametersTransformer = (
  values,
  schema,
  productEntities
) => {
  [values, schema] = baseAnalysisParametersTransformer(values, schema, productEntities);
  const newValues = {...values};

  if ('goal' in schema && !('goal_query' in values)) {
    schema['goal'].name = 'Goal';
    schema['goal'].order = 1;
  }

  if ('goal_query' in values && !('goal' in schema)) {
    schema['goal_query'].name = 'Goal';
    schema['goal_query'].order = 1;
  }

  if ('goal_bound_n' in schema && 'goal_bound_unit' in schema) {
    schema['goal_bound_unit'].order = 2;
    schema['goal_bound_unit'].name = 'Time window';
    schema['goal_bound_unit'].type = ParameterType.STRING;
    newValues[
      'goal_bound_unit'
    ] = `Within ${newValues['goal_bound_n']} ${newValues['goal_bound_unit']}s`;
    delete schema['goal_bound_n'];
  }
  if ('reference_date' in schema) {
    schema['reference_date'].order = 3;
    schema['reference_date'].name = 'Starting point';
  }

  delete schema['treatments_tag'];
  delete schema['treatments_signals'];
  schema['treatments_tag'] = {
    name: 'Scope',
    type: ParameterType.__MERGED_TREATMENTS,
    key: 'treatments_tag',
    order: 4,
  };
  newValues['treatments_tag'] = [
    ...(newValues['treatments_tag'] || []),
    ...(newValues['treatments_signals'] || []),
  ];

  if ('group_by' in values) {
    schema['group_by'].name = 'Group by';
    schema['group_by'].order = 5;
  }
  if ('days_from_reference' in schema) {
    schema['days_from_reference'].order = 100;
    schema['days_from_reference'].name = 'Preliminary behavior period';
  }

  return [newValues, schema];
};

import * as React from 'react';
import classNames from 'classnames';
import classes from './inline-edit-name.module.scss';
import {forwardRef, useCallback, useRef, useState} from 'react';
import {withStopPropagation} from "front-core";

interface OwnProps {
  value: string;
  onSubmit: (value: string) => void;
  className?: string;
  inputClassName?: string;
}

type AllProps = OwnProps;

export const InlineEditName: React.FC<AllProps> = forwardRef((props: AllProps, ref) => {
  const {value, onSubmit, inputClassName, className} = props;
  const [editMode, setEditMode] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const inputRef = useRef<HTMLInputElement>();

  const onEditMode = useCallback(() => {
    setEditMode(true);
    setInputValue(value);
    setTimeout(() => inputRef.current.focus(), 0);
  }, [setEditMode, value]);
  const onBlur = useCallback(() => {
    if (inputValue === '' || inputValue === value) {
      setEditMode(false);
      return;
    }
    onSubmit(inputValue);
    setEditMode(false);
  }, [inputValue, onSubmit, value]);

  if (!editMode) {
    return (
      <span
        ref={ref as any}
        onClick={withStopPropagation(() => onEditMode())}
        className={classNames(classes.InlineEditName, className)}
      >
        {value}
      </span>
    );
  }

  return (
    <input
      ref={inputRef}
      className={classNames(classes.InlineEditInput, inputClassName)}
      value={inputValue}
      onChange={e => setInputValue(e.target.value)}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
      }}
      onBlur={() => onBlur()}
    />
  );
});

import {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {timer} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';

interface UseIntervalConfig {
  callback: () => void;
  initial?: boolean;
  timeoutSec?: number;
  key?: string;
  isActive?: boolean;
}

const source = timer(0, 1000);

export const useInterval = function (config: UseIntervalConfig) {
  const {callback, initial = true, key, timeoutSec = 60} = config;
  const [isActive, setIsActive_] = useState(initial);
  const callback_ = useRef(callback);

  const setIsActive = useCallback((v: boolean) => {
    setIsActive_(v);
  }, []);

  useLayoutEffect(() => {
    callback_.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!isActive) {
      return;
    }

    const subs = source
      .pipe(
        map(i => timeoutSec - (i % (timeoutSec + 1))),
        filter(tSec => tSec === 0),
        tap(_ => callback_.current())
      )
      .subscribe();

    return () => {
      subs.unsubscribe();
    };
  }, [isActive, callback_, timeoutSec, key]);

  useEffect(() => {
    function onBlur() {
      setIsActive(false);
    }
    function onFocus() {
      setIsActive(true);
      callback_.current();
    }

    window.addEventListener('blur', onBlur);
    window.addEventListener('focus', onFocus);
    return () => {
      window.removeEventListener('blur', onBlur);
      window.removeEventListener('focus', onFocus);
    };
  }, [setIsActive, callback_]);

  return {
    isActive,
    setIsActive,
  };
};

import * as React from 'react';
import {useMemo} from 'react';
import classNames from 'classnames';
import classes from './metric-category-table.module.scss';
import {
  Metric,
  MetricCategory,
  MetricValueType,
  TeamMetric,
} from '../../../../../../objects/models/metric.model';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  Avatar,
  Button,
  CopyIcon,
  EditIcon,
  FlagIcon,
  HoverHelperTip,
  IconButton,
  KPIGoalProgress,
  Link,
  MoreIcon,
  RadarLightIcon,
  StarLightIcon,
  StarSolidIcon,
  TrashIcon,
  CircleCheckRegularIcon,
  ArrowRightIcon,
  TeamIconChip,
  PlusLightIcon,
} from 'ui-components';
import TransKeys from '../../../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {useProductData} from '../../../../../../core/hooks/use-product-data.hook';
import {Permission} from '../../../../../../core/components/permission.component';
import {Action, Subject} from '../../../../../../constants/permissions';
import {TitleWithIcon} from '../../../../../shared/components/general/title/title.component';
import {generateLastModified} from '../../../../../../utils/history.utils';
import moment from 'moment/moment';
import {withStopPropagation} from '../../../../../../utils/general.utils';
import usePermissions from '../../../../../../core/hooks/use-permissions.hook';
import {keys} from 'lodash';
import {UserSmartSelectorAvatar} from '../../../../../shared/core/smart-selector/user-smart-selector-avatar/user-smart-selector-avatar.component';
import {Tooltip} from '@material-ui/core';
import {exists, number2k} from 'front-core';
import {TIME_FORMATS} from '../../../../../../constants/time-formats';
import {
  GridTable,
  GridTableClasses,
} from '../../../../../shared/components/general/grid-table/grid-table.component';
import {TableColumn} from '../../../../../shared/components/general/grid-table/grid-table.types';
import {ValidationStatus} from '../../../../../../objects/models/signal.model';
import InvalidSignalWarning from '../../../../components/invalid-signal-warning/invalid-signal-warning.component';
import {useFeatureIsOn} from '@growthbook/growthbook-react';
import {FeatureFlag} from '../../../../../../constants/feature-flags';
import {ModelActionsDropdown} from '../../../../../shared/core/model-actions-dropdown/model-actions-dropdown.component';
import {getEntityIcon} from '../../../../../../constants/entity.consts';

interface OwnProps {
  category: MetricCategory;
  teamMetrics: TeamMetric[];
  onResetTeamFilter: () => void;
  showActions: boolean;
  showTeams?: boolean;
  onEditCategory: () => void;
  onDeleteCategory: () => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
  onCreateMetric: () => void;
  onAddToTeam: (segmentId: number, teamId: number) => void;
  onMetricClicked: (metric: Metric) => void;
  onViewKPIPage: (metric: Metric) => void;
  onEditMetric: (metric: Metric) => void;
  onDuplicateMetric: (metric: Metric) => void;
  onDeleteMetric: (metric: Metric) => void;
  onOwnerChange: (ownerId: number, metric: Metric) => void;
  onSetNorthStar: (metric: Metric) => void;
  onCreateEditGoal: (metric: Metric, goalId?: number) => void;
  onResample: (metric: Metric) => void;
  onValidate: (metric: Metric) => void;
  className?: string;
}

type AllProps = OwnProps;

export const MetricCategoryTable: React.FC<AllProps> = (props: AllProps) => {
  const {
    category,
    teamMetrics,
    showActions,
    onEditCategory,
    onDeleteCategory,
    onMoveUp,
    onMoveDown,
    onCreateMetric,
    onMetricClicked,
    onDuplicateMetric,
    onDeleteMetric,
    onEditMetric,
    onSetNorthStar,
    onOwnerChange,
    onCreateEditGoal,
    onResample,
    onValidate,
    onViewKPIPage,
    onResetTeamFilter,
    onAddToTeam,
    showTeams,
    className,
  } = props;
  const {t} = useTranslation();
  const {productEntitiesMap, actualTeams: teams} = useProductData();
  const {can} = usePermissions();
  const showKPIPage = useFeatureIsOn(FeatureFlag.KPI_PAGE as any);
  const metrics = useMemo(() => {
    if (teamMetrics) {
      const signalIdsSet = new Set(teamMetrics.map(s => s.signalId));
      return category.metrics.filter(s => signalIdsSet.has(s.signalId));
    }
    return category.metrics;
  }, [category, teamMetrics]);

  const columns: TableColumn[] = useMemo(
    () => [
      {
        key: 'name',
        title: t(TransKeys.GENERAL.HEADERS.NAME),
        width: '34rem',
        sortable: false,
        sticky: 'left',
        stretch: true,
        render: (metric: Metric) => (
          <div className={classes.NameColumn}>
            {metric.isNorthStar && (
              <Tooltip
                title={t(TransKeys.METRICS.LABELS.NORTH_STAR_KPI)}
                placement={'top'}
                interactive={false}
              >
                <StarSolidIcon className={classes.NorthStar} />
              </Tooltip>
            )}
            <span className={classes.Name}>{metric.name}</span>
            {metric.signalValidationStatus === ValidationStatus.ERROR && (
              <InvalidSignalWarning
                title={t(TransKeys.METRICS.SIGNAL_VALIDATION_STATUS_HELPER_TEXT)}
              />
            )}
          </div>
        ),
      },
      {
        key: 'last_sample',
        title: t(TransKeys.METRICS.HEADERS.AVERAGE_VALUE),
        width: '16rem',
        sortable: false,
        render: (metric: Metric) => {
          let lastSampleValue = metric.lastSample?.expectedValue;
          let unit = '';
          if (!exists(lastSampleValue)) {
            return (
              <div className={classes.EmptyState}>
                - <HoverHelperTip title={'Soon'} small className={classes.Helper} />
              </div>
            );
          }
          if (metric.valueType === MetricValueType.PERCENTAGE) {
            lastSampleValue = lastSampleValue * 100;
            unit = '%';
          }
          const date = moment
            .utc(metric.lastSample?.sampleDatetime, TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT)
            .format(TIME_FORMATS.READABLE_DATE);
          return (
            <div className={classes.LastSample}>
              {number2k(lastSampleValue)}
              {unit}
              <HoverHelperTip
                title={t(TransKeys.METRICS.AVERAGE_VALUE.DATA_FROM, {date})}
                className={classes.HelperTip}
                small
              />
            </div>
          );
        },
      },
      {
        key: 'goal',
        title: t(TransKeys.METRICS.HEADERS.GOAL),
        width: '18rem',
        sortable: false,
        render: (metric: Metric) => {
          const goal = metric.goal?.value;
          const lastSampleValue = metric.lastSample?.expectedValue;
          const startOfQuarterMetricSampleValue = metric.quarterStartingSample?.expectedValue;
          const isPercentage = metric.valueType === MetricValueType.PERCENTAGE;
          let unit = '';
          if (!exists(goal)) {
            return (
              <div className={classes.GoalColumn}>
                <Button
                  onClick={withStopPropagation(() => onCreateEditGoal(metric))}
                  variant={'outlined'}
                  className={GridTableClasses.ShowOnHover}
                >
                  {t(TransKeys.METRICS.ACTIONS.SET_GOAL)}
                </Button>
              </div>
            );
          }
          if (startOfQuarterMetricSampleValue === null || lastSampleValue === null) {
            return (
              <div className={classes.GoalColumn}>
                {number2k(isPercentage ? goal * 100 : goal)}
                {isPercentage ? '%' : ''}
                {unit}
              </div>
            );
          }
          return (
            <div className={classes.GoalColumn}>
              <KPIGoalProgress
                current={lastSampleValue}
                begin={startOfQuarterMetricSampleValue}
                goal={goal}
                isPercentage={isPercentage}
                prefix={'Quarter'}
                currentValueLabel={t(TransKeys.METRICS.HEADERS.AVERAGE_VALUE)}
              />
            </div>
          );
        },
      },
      {
        key: 'entity',
        title: t(TransKeys.GENERAL.HEADERS.ENTITY),
        width: '12rem',
        sortable: false,
        hidden: keys(productEntitiesMap).length < 2,
        render: (metric: Metric) => (
          <TitleWithIcon
            titleClassName={classes.EntityColumn}
            text={productEntitiesMap[metric.entity].name}
            icon={getEntityIcon(metric.entity)}
          />
        ),
      },
      {
        key: 'owner',
        title: t(TransKeys.GENERAL.HEADERS.OWNER),
        sortable: false,
        width: '8rem',
        render: (metric: Metric) => (
          <UserSmartSelectorAvatar
            userName={metric.ownerUserName}
            onChange={userId => onOwnerChange(userId, metric)}
            clearText={t(TransKeys.METRICS.ACTIONS.REMOVE_OWNER)}
            tooltipText={t(TransKeys.METRICS.ACTIONS.SET_OWNER)}
          />
        ),
      },
      {
        key: 'teams',
        title: t(TransKeys.GENERAL.HEADERS.TEAMS),
        sortable: false,
        width: '12rem',
        hidden: !showTeams,
        render: (metric: Metric) => {
          return (
            <div className={classes.Teams}>
              {metric.teams.map(t => (
                <TeamIconChip {...t} key={t.id} showTooltip />
              ))}
            </div>
          );
        },
      },
      {
        key: 'last_modified',
        title: t(TransKeys.GENERAL.HEADERS.UPDATED),
        sortable: false,
        width: '14rem',
        render: (metric: Metric) => {
          const modifier = generateLastModified(metric.history[0], true);
          return (
            <div className={classes.Modifier}>
              {modifier?.user && (
                <Avatar name={modifier.user} size={'small'} className={classes.Avatar} />
              )}
              {modifier?.date ? modifier.date : moment.utc(metric.updatedOn).local().fromNow()}
            </div>
          );
        },
      },
      {
        key: 'actions',
        title: '',
        width: '12rem',
        align: 'right',
        sticky: 'right',
        render: (metric: Metric) => {
          const teamOptions = teams.filter(
            t => metric.teams.find(t2 => t.id === t2.id) === undefined
          );

          return (
            <div className={classes.Actions}>
              <Permission subject={Subject.METRIC} action={Action.EDIT}>
                <IconButton
                  className={classes.Button}
                  tooltipText={t(TransKeys.GENERAL.ACTIONS.EDIT)}
                  icon={EditIcon}
                  onClick={withStopPropagation(() => onEditMetric(metric))}
                />
              </Permission>
              <Permission subject={Subject.METRIC} action={Action.CREATE}>
                <IconButton
                  className={classes.Button}
                  tooltipText={t(TransKeys.GENERAL.ACTIONS.DUPLICATE)}
                  icon={CopyIcon}
                  onClick={withStopPropagation(() => onDuplicateMetric(metric))}
                />
              </Permission>
              <ModelActionsDropdown
                className={classes.Button}
                actions={[
                  {
                    key: 'add-to-team',
                    title: t(TransKeys.GENERAL.ACTIONS.ADD_TO_TEAM),
                    icon: PlusLightIcon,
                    hide: teamOptions.length === 0,
                    children: teamOptions.map(t => ({
                      key: t.id,
                      title: t.name,
                      icon: ({className}) => <TeamIconChip {...t} className={className} />,
                      onClick: () => onAddToTeam(metric.id, t.id),
                    })),
                  },
                  {
                    key: 'goal',
                    hide: !can(Subject.METRIC, Action.CREATE),
                    title: metric.goal?.id
                      ? t(TransKeys.METRICS.ACTIONS.EDIT_GOAL)
                      : t(TransKeys.METRICS.ACTIONS.SET_GOAL),
                    onClick: () => onCreateEditGoal(metric, metric.goal?.id),
                    icon: FlagIcon,
                  },
                  {
                    key: 'set-north-star',
                    hide: !can(Subject.METRIC, Action.EDIT),
                    title: t(TransKeys.METRICS.ACTIONS.SET_NORTH_STAR),
                    onClick: () => onSetNorthStar(metric),
                    icon: StarLightIcon,
                  },
                  {
                    key: 'resample',
                    title: t(TransKeys.GENERAL.ACTIONS.RESCAN),
                    onClick: () => onResample(metric),
                    icon: RadarLightIcon,
                  },
                  {
                    key: 'validate',
                    title: t(TransKeys.GENERAL.ACTIONS.VALIDATE),
                    onClick: () => onValidate(metric),
                    icon: CircleCheckRegularIcon,
                  },
                  {
                    key: 'delete',
                    hide: !can(Subject.METRIC, Action.DELETE),
                    title: t(TransKeys.GENERAL.ACTIONS.DELETE),
                    onClick: () => onDeleteMetric(metric),
                    icon: TrashIcon,
                  },
                  {
                    key: 'deep_dive',
                    hide: !showKPIPage,
                    title: 'KPI Page',
                    onClick: () => onViewKPIPage(metric),
                    icon: ArrowRightIcon,
                  },
                ]}
                label={t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
                icon={MoreIcon}
                iconDropdown
              />
            </div>
          );
        },
      },
    ],
    [
      t,
      productEntitiesMap,
      teams,
      onDeleteMetric,
      onEditMetric,
      onDuplicateMetric,
      onOwnerChange,
      onSetNorthStar,
      onCreateEditGoal,
      onAddToTeam,
      onResample,
      onValidate,
      onViewKPIPage,
      can,
      showTeams,
      showKPIPage,
    ]
  );

  return (
    <div className={classNames(classes.MetricCategoryTable, className)}>
      <div className={classes.Header}>
        <div className={classes.Name}>{category.name}</div>
        <div className={classes.Count}>{metrics.length} KPIs</div>
        <div className={classes.Spacer} />
        {showActions && (
          <div className={classes.Actions}>
            {onMoveDown && (
              <IconButton
                icon={ArrowDownIcon}
                tooltipText={t(TransKeys.GENERAL.ACTIONS.MOVE_DOWN)}
                onClick={() => onMoveDown()}
              />
            )}
            {onMoveUp && (
              <IconButton
                icon={ArrowUpIcon}
                tooltipText={t(TransKeys.GENERAL.ACTIONS.MOVE_UP)}
                onClick={() => onMoveUp()}
              />
            )}
            <ModelActionsDropdown
              actions={[
                {
                  key: 'edit',
                  hide: !can(Subject.METRIC, Action.EDIT),
                  title: t(TransKeys.GENERAL.ACTIONS.EDIT),
                  onClick: withStopPropagation(() => onEditCategory()),
                  icon: EditIcon,
                },
                {
                  key: 'delete',
                  hide: !can(Subject.METRIC, Action.DELETE),
                  title: t(TransKeys.GENERAL.ACTIONS.DELETE),
                  onClick: withStopPropagation(() => onDeleteCategory()),
                  icon: TrashIcon,
                },
              ]}
              label={t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
              icon={MoreIcon}
              iconDropdown
            />
          </div>
        )}
      </div>
      <div className={classes.Table}>
        <GridTable
          data={metrics}
          dataKey={'id'}
          columns={columns}
          pagination={false}
          total={category.metrics.length}
          onRowClicked={(metric: Metric) => onMetricClicked(metric)}
          emptyStateRow={
            <div className={classes.EmptyState}>
              <span className={classes.Text}>
                {t(TransKeys.METRICS.NO_METRICS_EMPTY_STATE.TITLE)},
              </span>
              <Permission subject={Subject.METRIC} action={Action.CREATE}>
                <Link className={classes.Button} onClick={() => onCreateMetric()}>
                  {t(TransKeys.METRICS.NO_METRICS_EMPTY_STATE.BUTTON_TEXT)}
                </Link>
              </Permission>
            </div>
          }
          footer={
            teamMetrics && metrics.length < category.metrics.length ? (
              <div className={classes.TeamFooterMoreMetrics} onClick={onResetTeamFilter}>
                {t(TransKeys.METRICS.LIST.SHOW_ALL_METRICS, {
                  count: category.metrics.length - metrics.length,
                })}
              </div>
            ) : null
          }
        />
      </div>
    </div>
  );
};

MetricCategoryTable.defaultProps = {
  showActions: true,
};

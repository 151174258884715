import yup from '../../config/yup.config';
import {SchemaOf} from 'yup';
import {SchedulingDTO, schedulingDTOValidator} from './scheduling.dto';

export interface HealthMonitorSubscriptionDTO extends SchedulingDTO {
  metricHealthMonitorId: number;
}

export const healthMonitorSubscriptionDTOValidator: SchemaOf<HealthMonitorSubscriptionDTO> = yup
  .object()
  .shape({
    metricHealthMonitorId: yup.number().required(),
    ...schedulingDTOValidator,
  });

import * as React from 'react';
import classes from './title.module.scss';
import classNames from 'classnames';
import {FlexHorizontal} from '../../layout/flex-layout/general-flex-layouts.component.';
import {useMemo} from 'react';
import {Badge} from 'ui-components';
import {sanitize} from 'front-core';

interface TitleProps {
  text: string | JSX.Element;
  size?: 'large' | 'medium' | 'small';
  className?: string;
  onClick?: (e) => void;
  firstWordBold?: boolean;
  ellipsis?: boolean;
  caps?: boolean;
  upper?: boolean;
  bold?: boolean;
}

const defaultProps: Partial<TitleProps> = {
  size: 'large',
  firstWordBold: false,
  caps: true,
};

export const Title: React.FC<TitleProps> = (props: TitleProps) => {
  const {text, size, className, onClick, ellipsis, firstWordBold, upper, caps, bold} = props;
  const titleText = useMemo(() => {
    if (firstWordBold && typeof text === 'string') {
      const [first, ...rest] = text.split(' ');
      return (
        <>
          {first} <span style={{fontWeight: 400}}>{rest.join(' ')}</span>
        </>
      );
    }
    return text;
  }, [firstWordBold, text]);
  const isHtmlTitle = useMemo(() => typeof titleText === 'string', [titleText]);

  return (
    <span
      className={classNames(
        classes.Title,
        classes[size],
        onClick && classes.Clickable,
        ellipsis && classes.Ellipsis,
        upper && classes.Upper,
        bold && classes.Bold,
        caps && classes.Caps,
        className
      )}
      onClick={onClick}
      dangerouslySetInnerHTML={isHtmlTitle ? {__html: sanitize(titleText) as any} : undefined}
    >
      {!isHtmlTitle ? titleText : undefined}
    </span>
  );
};

Title.defaultProps = defaultProps;

interface TitleWithIconProps extends TitleProps {
  icon?: any;
  titleClassName?: string;
}

export const TitleWithIcon: React.FC<TitleWithIconProps> = (props: TitleWithIconProps) => {
  return (
    <FlexHorizontal className={props.className} verticalAlignCenter>
      {props.icon && <props.icon className={classNames(classes.TitleIcon, classes[props.size])} />}
      <Title {...props} className={props.titleClassName} />
    </FlexHorizontal>
  );
};

TitleWithIcon.defaultProps = defaultProps;

interface TitleWitNumberBadgeProps extends TitleProps {
  badgeContent?: number;
  titleClassName?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export const TitleWitNumberBadge: React.FC<TitleWitNumberBadgeProps> = (
  props: TitleWitNumberBadgeProps
) => {
  const {badgeContent, onClick} = props;
  const onBadgeClick = useMemo(() => (badgeContent ? onClick : () => {}), [onClick, badgeContent]);
  const onTitleClick = useMemo(() => (onClick ? onClick : () => {}), [onClick]);

  return (
    <FlexHorizontal className={props.className}>
      <Badge
        badgeContent={badgeContent}
        invisible={!badgeContent}
        onBadgeClick={onBadgeClick}
        alert
      >
        <Title {...props} onClick={onTitleClick} className={props.titleClassName} />
      </Badge>
    </FlexHorizontal>
  );
};

TitleWitNumberBadge.defaultProps = defaultProps;

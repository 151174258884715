import {Epic} from 'redux-observable';
import {createRequestEpic} from 'front-core';
import {ActionKey} from '../../constants/action-key';
import HttpClient from '../../services/http-client.service';
import {modelCreated, modelDeleted, modelUpdated, notifyEvent} from '../core/core.actions';
import {ModelKey} from '../../constants/model-key';
import {annotationToastCreator} from '../toasts.actions';
import {AnnotationsActionType} from './annotations.actions';
import {
  createAnnotationNetworkRequest,
  deleteAnnotationNetworkRequest,
  updateAnnotationNetworkRequest,
  updateAnnotationStatusNetworkRequest,
} from '../../http/annotations.network-requests';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {demoProductLimitedActionsFilter} from '../store.utils';

export const createAnnotationEpic: Epic = createRequestEpic(
  {
    types: [AnnotationsActionType.CREATE_ANNOTATION],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.CREATE_EXPERIMENT,
    request: createAnnotationNetworkRequest,
    onSuccess: annotation => [
      notifyEvent(AmplitudeEvent.ANNOTATION_CREATED, {id: annotation.id}),
      annotationToastCreator('CREATE_SUCCESS'),
      modelCreated(annotation, ModelKey.ANNOTATION),
    ],
    onError: err => [annotationToastCreator('CREATE_ERROR')],
  },
  HttpClient
);

export const updateAnnotationEpic: Epic = createRequestEpic(
  {
    types: [AnnotationsActionType.UPDATE_ANNOTATION],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.UPDATE_ANNOTATION,
    request: updateAnnotationNetworkRequest,
    onSuccess: annotation => [
      notifyEvent(AmplitudeEvent.ANNOTATION_UPDATED, {id: annotation.id}),
      annotationToastCreator('UPDATE_SUCCESS'),
      modelUpdated(annotation, ModelKey.ANNOTATION),
    ],
    onError: err => [annotationToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);

export const updateAnnotationStatusEpic: Epic = createRequestEpic(
  {
    types: [AnnotationsActionType.UPDATE_ANNOTATION_STATUS],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.UPDATE_ANNOTATION_STATUS,
    request: updateAnnotationStatusNetworkRequest,
    onSuccess: annotation => [
      notifyEvent(AmplitudeEvent.ANNOTATION_UPDATED, {id: annotation.id}),
      annotationToastCreator('UPDATE_SUCCESS'),
      modelUpdated(annotation, ModelKey.ANNOTATION),
    ],
    onError: err => [annotationToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);

export const deleteAnnotationEpic: Epic = createRequestEpic(
  {
    types: [AnnotationsActionType.DELETE_ANNOTATION],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.DELETE_ANNOTATION,
    request: deleteAnnotationNetworkRequest,
    onSuccess: (res, payload) => [
      notifyEvent(AmplitudeEvent.ANNOTATION_DELETED, {id: payload}),
      annotationToastCreator('DELETE_SUCCESS'),
      modelDeleted(payload, ModelKey.ANNOTATION),
    ],
    onError: err => [annotationToastCreator('DELETE_ERROR')],
  },
  HttpClient
);

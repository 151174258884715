import {Reducer} from 'redux';
import {SelectedConfig} from './selected.types';
import SelectedStoreService from './selected.service';

export const createSelectedReducer = (state, action): Reducer => {
  const config: SelectedConfig = action.payload;
  const updatedState = {
    ...state,
    selected: {...state.selected},
  };
  updatedState.selected[config.selectedKey] = undefined;
  SelectedStoreService.addList(config.selectedKey, config);
  return updatedState;
};

export const upsertSelectedReducer = (state, action): Reducer => {
  const {selectedKey, data, append} = action.payload;
  const updatedState = {
    ...state,
    selected: {...state.selected},
  };
  const current = updatedState.selected[selectedKey] || {};
  updatedState.selected[selectedKey] = append ? {...current, ...data} : data;
  return updatedState;
};

export const removeSelectedReducer = (state, action): Reducer => {
  const key = action.payload;
  const updatedState = {
    ...state,
    selected: {...state.selected},
  };
  delete updatedState.selected[key];
  SelectedStoreService.removeList(key);
  return updatedState;
};

export const modelChanged = (state, action): Reducer => {
  const {data, modelKey} = action.payload;

  const configs = SelectedStoreService.getConfigsByModelKey(modelKey);
  if (configs.length === 0) {
    return state;
  }

  const updatedState = {
    ...state,
    selected: {
      ...state.selected,
    },
  };

  for (const config of configs) {
    const {selectedKey, primaryKey} = config;
    if (!primaryKey) {
      continue;
    }
    let selected = updatedState.selected[selectedKey];
    if (!selected || selected[primaryKey] !== data[primaryKey]) {
      continue;
    }
    updatedState.selected[selectedKey] = {
      ...selected,
      ...data,
    };
  }

  return updatedState;
};

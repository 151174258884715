import {NRC} from './index';
import {RequestType} from 'front-core';
import {PhenomenaFiltersDTO} from '../objects/dto/phenomena-filters.dto';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';
import {toCamelCase} from 'front-core';

export const getPhenomenasNetworkRequest: NRC<void, PhenomenaFiltersDTO> = (
  payload: PhenomenaFiltersDTO
) => ({
  method: RequestType.GET,
  relativeUrl: `/app/phenomenas`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data.data),
});

export const getPhenomenaByIdNetworkRequest: NRC<void, PhenomenaFiltersDTO> = (id: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/phenomenas/${id}`,
  responseTransformer: res => toCamelCase(res.data),
});

export const getPhenomenaByDocIdNetworkRequest: NRC<void, void> = (docId: string) => ({
  method: RequestType.GET,
  relativeUrl: `/app/phenomenas/by-doc/${docId}`,
  responseTransformer: res => toCamelCase(res.data),
});

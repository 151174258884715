import {NRC} from './index';
import {RequestType, toCamelCase, toSnakeCase} from 'front-core';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';
import {AnalysisResultFiltersDTO} from '../objects/dto/analysis-result-filters.dto';
import {AnalysisResult} from '../objects/models/analysis-result.model';
import {AnalysisRerunDTO} from '../objects/dto/analysis.dto';
import appConfig from '../config/app.config';

export const searchAnalysisResultsNetworkRequest: NRC<void, AnalysisResultFiltersDTO> = (
  payload: AnalysisResultFiltersDTO
) => ({
  method: RequestType.GET,
  relativeUrl: `/app/analysis-results/search`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data, ['data.*.parameters', 'data.*.run_parameters']),
});

export const getAnalysisResultsNetworkRequest: NRC<void, AnalysisResultFiltersDTO> = (
  payload: AnalysisResultFiltersDTO
) => ({
  method: RequestType.GET,
  relativeUrl: `/app/analysis-results`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data, ['data.*.parameters', 'data.*.run_parameters']),
});

export const getExampleAnalysisResultsNetworkRequest: NRC<void, AnalysisResultFiltersDTO> = (
  payload: AnalysisResultFiltersDTO
) => ({
  method: RequestType.GET,
  relativeUrl: `/app/analysis-results/examples`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data, ['data.*.parameters', 'data.*.run_parameters']),
});

export const getAnalysisResultNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/analysis-results/${id}`,
  responseTransformer: res => toCamelCase(res.data, ['results', 'parameters', 'run_parameters']),
});

export const getAnalysisResultParametersNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/analysis-results/${id}/parameters`,
  responseTransformer: res => toCamelCase(res.data, ['schema', 'values']),
});

export const updateAnalysisResultNetworkRequest: NRC<Partial<AnalysisResult>, void> = (
  data: Partial<AnalysisResult>
) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/analysis-results/${data.id}`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const deleteAnalysisResultNetworkRequest: NRC<Partial<AnalysisResult>, void> = (
  id: number
) => ({
  method: RequestType.DELETE,
  relativeUrl: `/app/analysis-results/${id}`,
  responseTransformer: res => toCamelCase(res.data),
});

export const generateDefaultAnalysisResultNameNetworkRequest: NRC<void, any> = (payload: any) => ({
  method: RequestType.POST,
  relativeUrl: `/app/analysis-results/default-name`,
  body: payload,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data.data),
});

export const rerunAnalysisResultNetworkRequest: NRC<AnalysisRerunDTO, void> = payload => ({
  method: RequestType.POST,
  relativeUrl: `/app/analysis-results/${payload.analysisResultId}/re-run`,
  body: payload,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const stopAnalysisResultNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.POST,
  relativeUrl: `/app/analysis-results/${id}/stop`,
  responseTransformer: res => toCamelCase(res.data),
});

export const getAnalysisResultExecutedSqlNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/analysis-results/${id}/executed-sql`,
  responseTransformer: res => toCamelCase(res.data),
});

export const downloadAnalysisResultArtifactUrl = (
  analysisResultId: number,
  artifactId: number
) => ({
  method: RequestType.GET,
  relativeUrl: `/app/analysis-results/${analysisResultId}/artifacts/${artifactId}/download`,
  responseTransformer: res => res.data,
});

export const viewAnalysisResultNotebook = (id: number) =>
  `${appConfig.serverUrl}/admin/analysis-results/${id}/notebook`;

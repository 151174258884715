import './config/i18n.config';
import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import 'ui-components/dist/index.css';
import './index.scss';
import './utils/yup.utils';
import {store} from './store/app.store';
import App from './modules/root/app.component';
import {RootLoadingScreen} from './modules/root/components/root-loading-screen/root-loading-screen.component';
import reportWebVitals from './reportWebVitals';
import {StoreConnectProvider, HttpClientContext} from 'front-core';
import {MuiThemeProvider} from '@material-ui/core';
import {theme} from './config/mui.config';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {initApp} from './init-app';
import {Route} from 'react-router-dom';
import {QueryParamProvider} from 'use-query-params';
import HttpClient from './services/http-client.service';
import {GrowthBookProvider} from '@growthbook/growthbook-react';
import {growthbook} from './config/growthbook.config';

initApp();

ReactDOM.render(
  <Suspense fallback={<RootLoadingScreen />}>
    <HttpClientContext.Provider value={HttpClient}>
      <StoreConnectProvider store={store}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <MuiThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <GrowthBookProvider growthbook={growthbook}>
                <App />
              </GrowthBookProvider>
            </MuiPickersUtilsProvider>
          </MuiThemeProvider>
        </QueryParamProvider>
      </StoreConnectProvider>
    </HttpClientContext.Provider>
  </Suspense>,
  document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

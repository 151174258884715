import {AUTH_STORE_KEY} from './auth.store';
import {CORE_STORE_KEY} from '../core/core.store';
import {setScopeProduct} from '../core/core.actions';
import {getter} from '../store.utils';
import {User} from '../../objects/models/user.model';
import {upsert} from 'front-core';
import {get} from 'lodash';
import {AnyAction} from 'redux';

export const afterLoginEpicHandler = (user: User): AnyAction[] => {
  let currentScope = getter(CORE_STORE_KEY, 'scopeProduct');
  if (!user) {
    return [];
  }
  const defaultScope = get(user, 'products.0.id');
  const product = user.products.find(p => p.id === currentScope);
  if (!product) {
    currentScope = null;
  }

  return [setScopeProduct(currentScope || defaultScope), upsert(AUTH_STORE_KEY, 'user', user)];
};

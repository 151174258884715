import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {
  deleteWorkflowConfirmed,
  renameWorkflow,
  setWorkflowOwner,
  updateWorkflowStatus,
  updateWorkflowTeam,
} from '../../../../../store/workflows/workflows.actions';
import {WorkflowStatus} from '../../../../../objects/models/workflow.model';

export const useWorkflowActions = () => {
  const dispatch = useDispatch();

  const onDelete = useCallback(
    (workflowId: number) => dispatch(deleteWorkflowConfirmed(workflowId)),
    [dispatch]
  );

  const onChangeOwner = useCallback(
    (ownerId: number, workflowId: number) =>
      dispatch(
        setWorkflowOwner({
          workflowId: workflowId,
          userId: ownerId,
        })
      ),
    [dispatch]
  );

  const setWorkflowTeam = useCallback(
    (workflowId: number, teamId: number) => dispatch(updateWorkflowTeam({workflowId, teamId})),
    [dispatch]
  );

  const setWorkflowStatus = useCallback(
    (workflowId: number, status: WorkflowStatus) =>
      dispatch(updateWorkflowStatus({workflowId, status})),
    [dispatch]
  );

  const onRename = useCallback(
    (workflowId: number, name: string) => dispatch(renameWorkflow({workflowId, name})),
    [dispatch]
  );

  return {
    onDelete,
    onChangeOwner,
    setWorkflowTeam,
    setWorkflowStatus,
    onRename,
  };
};

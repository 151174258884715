import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useFormContext} from 'react-hook-form';
import classes from '../user-specific-kpis-step.module.scss';
import classNames from 'classnames';
import {StepTitle} from '../../../components/step-title/step-title.component';
import TransKeys from 'translations';
import Confetti from 'react-confetti';
import {Button} from 'ui-components';
import {useMemo} from 'react';

interface OwnProps {
  className?: string;
  onSubmit: (value: any) => void;
  showConfetti: boolean;
  disabled: boolean;
}

type AllProps = OwnProps;

export const EmptySpecificKpisGroups: React.FC<AllProps> = (props: AllProps) => {
  const {className, onSubmit, showConfetti, disabled} = props;
  const {t} = useTranslation();
  const {handleSubmit} = useFormContext();
  const confettiProps = useMemo(
    () => ({
      numberOfPieces: showConfetti ? 200 : 0,
      style: {zIndex: 1400},
    }),
    [showConfetti]
  );

  return (
    <div className={classNames(classes.UserKPIDeepDiveStep, className)}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.Form}>
        <StepTitle
          title={t(TransKeys.QUESTIONNAIRE.KPIS_DEEP_DIVE_STEP.SUCCESS_TITLE)}
          subTitle={t(TransKeys.QUESTIONNAIRE.KPIS_DEEP_DIVE_STEP.SUCCESS_SUB_TITLE)}
        />
        <Confetti numberOfPieces={confettiProps.numberOfPieces} style={confettiProps.style} />
        <div className={classes.Spacer} />
        <Button
          disabled={disabled}
          onClick={handleSubmit(onSubmit)}
          type={'submit'}
          className={classNames(classes.Submit, disabled && classes.Disabled)}
        >
          {t(TransKeys.QUESTIONNAIRE.ACTIONS.CONTINUE_LAST_STEP)}
        </Button>
      </form>
    </div>
  );
};

import {action} from 'front-core';
import {Notification} from '../../objects/models/notification.model';
import {IndexResponse} from '../../objects/models/index-response.model';

export enum NotificationsActionType {
  LOAD_NOTIFICATIONS = '@@notifications/LOAD_NOTIFICATIONS',
  LOAD_NOTIFICATIONS_SUCCESS = '@@notifications/LOAD_NOTIFICATIONS_SUCCESS',
  NEW_NOTIFICATION = '@@notifications/NEW_NOTIFICATION',
  MARK_NOTIFICATIONS_AS_SEEN = '@@notifications/MARK_NOTIFICATIONS_AS_SEEN',
  MARK_NOTIFICATIONS_AS_READ = '@@notifications/MARK_NOTIFICATIONS_AS_READ',
  MARK_NOTIFICATION_AS_READ = '@@notifications/MARK_NOTIFICATION_AS_READ',
}

const DEFAULT_NOTIFICATION_LIMIT = 10;

export const loadNotifications = (lastId?: number, limit: number = DEFAULT_NOTIFICATION_LIMIT) =>
  action(NotificationsActionType.LOAD_NOTIFICATIONS, {lastId, limit});
export const loadNotificationsSuccess = (res: IndexResponse<Notification>) =>
  action(NotificationsActionType.LOAD_NOTIFICATIONS_SUCCESS, res);
export const newNotification = (notification: Notification) =>
  action(NotificationsActionType.NEW_NOTIFICATION, notification);
export const markNotificationsAsSeen = () =>
  action(NotificationsActionType.MARK_NOTIFICATIONS_AS_SEEN);
export const markNotificationsAsRead = (notificationId?: number) =>
  action(NotificationsActionType.MARK_NOTIFICATIONS_AS_READ, notificationId);
export const markNotificationAsRead = (notificationId?: number) =>
  action(NotificationsActionType.MARK_NOTIFICATION_AS_READ, notificationId);

export const TIME_FORMATS = {
  PARAMETER_DATE_FORMAT: 'YYYY-MM-DD',
  DEFAULT_TIME_FORMAT: 'DD/MM/YYYY HH:mm',
  DEFAULT_DATE_FORMAT: 'DD/MM/YYYY',
  FULL_DATE_FORMAT: 'DD/MM/YYYY HH:mm:ss',
  DATE_CHART_SHORT: 'DD MMM YY',
  DEFAULT_INPUT_DATE_FORMAT: 'YYYY-MM-DDTHH:mm:ss',
  READABLE_DATE: 'DD MMM YY',
  READABLE_DATE_NO_YEAR: 'DD MMM',
  READABLE_DATE_TIME: 'DD MMM YY, HH:mm',
};

import * as React from 'react';
import classNames from 'classnames';
import classes from './root-loading-screen.module.scss';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';

interface OwnProps {
  className?: string;
}

type AllProps = OwnProps;

export const RootLoadingScreen: React.FC<AllProps> = (props: AllProps) => {
  const {className} = props;

  return (
    <div className={classNames(classes.RootLoadingScreen, className)}>
      <GenericLoading />
    </div>
  );
};

import yup from '../../../../../config/yup.config';
import {PARAMETERS_METADATA_KEY} from '../../../../../constants/parameters-saved-keys';
import {queryElementValidatorFactory} from '../../../../../objects/dto/query-builder.dto';
import {startEndDatesValidator, treatmentValidator} from '../shared-validators';
import {ParameterType} from 'ui-components';
import {
  AnalysisParametersTransformer,
  baseAnalysisParametersTransformer,
} from '../shared-transformers';

export const analysis89ParametersValidator = yup.object().shape({
  parameters: yup
    .object()
    .shape({
      [PARAMETERS_METADATA_KEY]: yup.object().nullable(),
      engagement_type: yup.string().oneOf(['L7', 'L28']).required(),
      adoption_days: yup.number().required(),
      population_filter: queryElementValidatorFactory(false),
      ...treatmentValidator('feature_tag'),
    })
    .required(),
  runParameters: startEndDatesValidator.required(),
});

export const analysis89ParametersTransformer: AnalysisParametersTransformer = (
  values,
  schema,
  productEntities
) => {
  [values, schema] = baseAnalysisParametersTransformer(values, schema, productEntities);
  const newValues = {...values};

  if ('engagement_type' in schema) {
    schema['engagement_type'].order = 1;
  }
  if ('adoption_days' in schema) {
    schema['adoption_days'].order = 2;
  }

  delete schema['feature_tag'];
  delete schema['treatments_signals'];
  schema['feature_tag'] = {
    name: 'Scope',
    type: ParameterType.__MERGED_TREATMENTS,
    key: 'feature_tag',
    order: 4,
  };
  newValues['feature_tag'] = [
    ...(newValues['feature_tag'] || []),
    ...(newValues['treatments_signals'] || []),
  ];

  return [newValues, schema];
};

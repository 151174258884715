import * as React from 'react';
import {Route, Switch} from 'react-router';
import {AppRoutes} from '../../constants/app-routes';
import {HealthMonitor} from './pages/health-monitor/health-monitor.component';
import ViewMetricSeriesResult from './pages/view-metric-series-result/view-metric-series-result.component';

export const HealthMonitorRouting: React.FC = props => {
  return (
    <Switch>
      <Route
        path={AppRoutes.viewMetricSeriesResult().pathname}
        component={ViewMetricSeriesResult}
        exact
      />
      <Route
        path={[AppRoutes.viewOtherHealthMonitor().pathname, AppRoutes.healthMonitor().pathname]}
        component={HealthMonitor}
      />
    </Switch>
  );
};

import * as React from 'react';
import {useCallback, useEffect, useMemo} from 'react';
import classes from './analyses-main.module.scss';
import PageLayout from '../../../shared/components/layout/page-layout';
import TransKeys from '../../../../constants/translation-keys';
import {AnalysesMenuIcon, Button, Filters, LocalStorageManager} from 'ui-components';
import {useTranslation} from 'react-i18next';
import {AnalysisNavigation} from './components/analyses-navigation/analyses-navigation.component';
import {AnalysesFolderView} from './components/analyses-views/analyses-folder-view.component';
import {useDispatch, useSelector} from 'react-redux';
import {AnalysisTypeId} from '../../../../constants/analysis-type-id';
import {JsonParam, useQueryParam, useQueryParams} from 'use-query-params';
import {
  getReducedLoadingStateSelector,
  getSingleErrorSelector,
  getSingleSelectedSelector,
} from '../../../../store/store.selectors';
import {EmptyState} from '../../../shared/components/general/override';
import {useAnalysisResultActions} from '../../hooks/use-analysis-result-actions.hook';
import {useAnalysisFolderActions} from '../../hooks/use-analysis-folder-actions.hook';
import {ANALYSIS_FOLDER_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {AnalysesMainViews} from './analysis-main.types';
import {
  createSelected,
  getSelected,
  removeSelected,
  upsertSelected,
} from '../../../../store/selected/selected.actions';
import {ModelKey} from '../../../../constants/model-key';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {
  getAnalysisFolderNetworkRequest,
  getAnalysisFoldersTreeNetworkRequest,
} from '../../../../http/analysis-folders.network-requests';
import {CoreActionsType} from '../../../../store/core/core.actions';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {del, set} from 'object-path-immutable';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {RecentAnalysesView} from './components/analyses-views/recent-analyses-view.component';
import {BaseAnalysesView} from './components/analyses-views/base-analyses-view.component';
import {analysisResultsSingleAnalysisTypeFilter, queryFilter} from '../../../../constants/filters';
import {SearchAnalysesView} from './components/analyses-views/search-analyses-view.component';
import {isEmpty} from 'lodash';
import {ShareResourceType} from '../../../../objects/models/share.model';
import {AnalysisFolder} from '../../../../objects/models/analysis-folder.model';
import {LocalStorageKey} from '../../../../constants/local-storage-key';
import {useIsAdmin} from '../../../../core/hooks/use-is-admin.hook';

interface OwnProps {}

type AllProps = OwnProps;

const isActualFolder = (folderId: any) => !isNaN(parseInt(folderId));

export const EXCLUDE_ANALYSIS_TYPE_IDS = [AnalysisTypeId.A_B_TEST, AnalysisTypeId.RELEASE_IMPACT];
const MOCK_FOLDER_IDS = [
  AnalysesMainViews.SHARED,
  AnalysesMainViews.FAVORITES,
  AnalysesMainViews.ARCHIVED,
];
const SELECTED_FOLDER_KEY = SharedSelectionKeys.ANALYSES_MAIN__ANALYSIS_FOLDER;
const SELECTED_FOLDERS_TREE_KEY = SharedSelectionKeys.ANALYSES_MAIN__FOLDERS_TREE;

const LISTENER_ACTIONS = [
  CoreActionsType.MODEL_CREATED,
  CoreActionsType.MODEL_UPDATED,
  CoreActionsType.MODEL_DELETED,
];

const DEFAULT_ADMIN_FILTERS = {
  excludeAnalysisTypeId: [
    AnalysisTypeId.DATA_VALIDATION,
    AnalysisTypeId.A_B_TEST,
    AnalysisTypeId.RELEASE_IMPACT,
  ],
};

export const AnalysesMain: React.FC<AllProps> = (props: AllProps) => {
  // Hooks
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {rootPublicAnalysisFolder, rootPrivateAnalysisFolder} = useProductData();
  const [folderIdParam, setFolderId] = useQueryParam<string | number>('folder');
  const analysisResultActions = useAnalysisResultActions();
  const analysisFolderActions = useAnalysisFolderActions();
  const {id: scopeProductId, sharedAnalysesCount} = useProductData();
  const isAdmin = useIsAdmin();
  const [query, setQuery] = useQueryParams({search: JsonParam});
  // Selectors
  const analysisFolder = useSelector(state =>
    getSingleSelectedSelector(SELECTED_FOLDER_KEY, state)
  );
  const analysisFolderError = useSelector(state =>
    getSingleErrorSelector(SELECTED_FOLDER_KEY, state)
  );
  const isLoadingFolder = useSelector(state =>
    getReducedLoadingStateSelector(SELECTED_FOLDER_KEY)(state)
  );
  const foldersTree = useSelector(state =>
    getSingleSelectedSelector(SELECTED_FOLDERS_TREE_KEY, state)
  );
  const isLoadingTree = useSelector(state =>
    getReducedLoadingStateSelector(SELECTED_FOLDERS_TREE_KEY)(state)
  );
  const filtersDef = useMemo(() => [queryFilter(), analysisResultsSingleAnalysisTypeFilter()], []);
  // Computed
  const folderId = useMemo(() => {
    if (isActualFolder(folderIdParam)) {
      return typeof folderIdParam === 'number' ? folderIdParam : parseInt(folderIdParam);
    }
    return (
      folderIdParam ||
      LocalStorageManager.getItem(LocalStorageKey.SELECTED_ANALYSIS_FOLDER_ID) ||
      AnalysesMainViews.RECENT
    );
  }, [folderIdParam]);
  // Handlers
  const onFiltersChange = useCallback(
    search => {
      if (isEmpty(search)) {
        setQuery({search: undefined});
        return;
      }
      setQuery({search});
    },
    [setQuery]
  );
  const onChangeFolder = useCallback(
    (folderId: number | string) => {
      setFolderId(folderId);
      LocalStorageManager.setItem(LocalStorageKey.SELECTED_ANALYSIS_FOLDER_ID, folderId);
      query.search && setQuery({search: undefined});
    },
    [setFolderId, setQuery, query.search]
  );
  const getFolderForCreate = useCallback(() => {
    if (isActualFolder(folderId)) {
      return folderId;
    }
    if (isAdmin) {
      return rootPrivateAnalysisFolder;
    }
    return rootPublicAnalysisFolder;
  }, [folderId, rootPublicAnalysisFolder, rootPrivateAnalysisFolder, isAdmin]);
  const onCreateAnalysis = useCallback(
    (parameters: any = {}) => {
      const modalParameters = {...parameters};
      modalParameters[ANALYSIS_FOLDER_ID_PATH_PARAM] = getFolderForCreate();
      analysisResultActions.onRequest(modalParameters);
    },
    [analysisResultActions, getFolderForCreate]
  );
  const onCreateFolder = useCallback(() => {
    const parameters: any = {
      onSuccess: (folder: AnalysisFolder) => {
        onChangeFolder(folder.id);
      },
    };
    parameters.parentId = getFolderForCreate();
    analysisFolderActions.onCreate(parameters);
  }, [analysisFolderActions, getFolderForCreate, onChangeFolder]);
  // Effects
  useEffect(() => {
    dispatch(
      createSelected({
        selectedKey: SELECTED_FOLDER_KEY,
        actionKey: SELECTED_FOLDER_KEY,
        request: getAnalysisFolderNetworkRequest,
        modelKey: ModelKey.ANALYSIS_FOLDER,
      })
    );
    dispatch(
      createSelected({
        selectedKey: SELECTED_FOLDERS_TREE_KEY,
        actionKey: SELECTED_FOLDERS_TREE_KEY,
        request: getAnalysisFoldersTreeNetworkRequest,
      })
    );
    return () => {
      dispatch(removeSelected(SELECTED_FOLDER_KEY));
      dispatch(removeSelected(SELECTED_FOLDERS_TREE_KEY));
    };
  }, [dispatch]);
  useEffect(() => {
    dispatch(getSelected(SELECTED_FOLDERS_TREE_KEY));
  }, [dispatch]);
  useEffect(() => {
    if (isActualFolder(folderId) || MOCK_FOLDER_IDS.indexOf(folderId as any) > -1) {
      dispatch(getSelected(SELECTED_FOLDER_KEY, folderId));
    } else {
      dispatch(upsertSelected(SELECTED_FOLDER_KEY, undefined));
    }
  }, [dispatch, folderId]);
  useEffect(() => {
    if (isActualFolder(analysisFolder?.id) && analysisFolder?.productId !== scopeProductId) {
      setFolderId(AnalysesMainViews.RECENT);
    }
  }, [scopeProductId, analysisFolder, setFolderId]);
  useEffect(() => {
    const listener = action => {
      let {modelKey, data} = action.payload;
      if ([ModelKey.ANALYSIS_FOLDER, ModelKey.SHARE].indexOf(modelKey) === -1) {
        return;
      }
      let actionType = action.type;
      switch (modelKey) {
        case ModelKey.ANALYSIS_FOLDER:
          // sync tree
          dispatch(getSelected(SELECTED_FOLDERS_TREE_KEY));
          if (!analysisFolder) {
            return;
          }
          // small patch for unarchive folder in archived view
          if (analysisFolder.id === AnalysesMainViews.ARCHIVED && data.deletedOn === null) {
            actionType = CoreActionsType.MODEL_DELETED;
            data = data.id;
          }
          // check if a folder children was updated
          switch (actionType) {
            case CoreActionsType.MODEL_UPDATED:
              {
                let childrenIndex = analysisFolder.children.findIndex(c => c.id === data.id);
                // updated folder is a child
                if (childrenIndex > -1) {
                  if (data.deletedOn !== null) {
                    const updatedFolder = del(analysisFolder, `children.${childrenIndex}`);
                    dispatch(upsertSelected(SELECTED_FOLDER_KEY, updatedFolder));
                  } else {
                    const updatedFolder = set(analysisFolder, `children.${childrenIndex}`, {
                      ...analysisFolder.children[childrenIndex],
                      ...data,
                    });
                    dispatch(upsertSelected(SELECTED_FOLDER_KEY, updatedFolder));
                  }
                  return;
                }
                // check if moved to sibling
                childrenIndex = analysisFolder.children.findIndex(c => c.id === data.parentId);
                if (childrenIndex > -1) {
                  // remove the moved folder
                  childrenIndex = analysisFolder.children.findIndex(c => c.id === data.id);
                  const updatedFolder = del(analysisFolder, `children.${childrenIndex}`);
                  dispatch(upsertSelected(SELECTED_FOLDER_KEY, updatedFolder));
                }
              }
              break;
            case CoreActionsType.MODEL_DELETED:
              {
                const childrenIndex = analysisFolder.children.findIndex(c => c.id === data);
                if (childrenIndex > -1) {
                  const updatedFolder = del(analysisFolder, `children.${childrenIndex}`);
                  dispatch(upsertSelected(SELECTED_FOLDER_KEY, updatedFolder));
                }
              }
              break;
          }
          break;
        case ModelKey.SHARE:
          const {modelId, type} = data;
          if (type !== ShareResourceType.ANALYSIS_FOLDER) {
            return;
          }
          // sync tree
          dispatch(getSelected(SELECTED_FOLDERS_TREE_KEY));
          if (analysisFolder.id === modelId) {
            dispatch(getSelected(SELECTED_FOLDER_KEY, analysisFolder.id));
          }
          break;
      }
    };
    dispatch(registerActionListener(LISTENER_ACTIONS, listener));
    return () => {
      dispatch(removeActionListener(LISTENER_ACTIONS, listener));
    };
  }, [dispatch, analysisFolder]);

  const renderActions = () => {
    return (
      <>
        <PageLayout.Actions>
          <Button onClick={() => onCreateFolder()} variant={'outlined'} size={'large'}>
            {t(TransKeys.GENERAL.ACTIONS.CREATE_FOLDER)}
          </Button>
          <Button onClick={() => onCreateAnalysis()} size={'large'}>
            {t(TransKeys.GENERAL.ACTIONS.REQUEST_ANALYSIS)}
          </Button>
        </PageLayout.Actions>
        <PageLayout.Filters>
          <Filters
            selected={query.search || {}}
            onChange={onFiltersChange}
            onClearAll={() => onFiltersChange(undefined)}
            freeSearchFilterKey={'q'}
            filters={filtersDef}
          />
        </PageLayout.Filters>
      </>
    );
  };
  const renderMain = () => {
    if (query.search) {
      return <SearchAnalysesView onFolderClicked={onChangeFolder} search={query.search} />;
    }
    if (folderId === AnalysesMainViews.RECENT) {
      return <RecentAnalysesView onFolderClicked={onChangeFolder} />;
    }
    if (folderId === AnalysesMainViews.ALL) {
      return (
        <BaseAnalysesView
          onFolderClicked={onChangeFolder}
          onCreateAnalysis={onCreateAnalysis}
          showFilters
          extraFilters={DEFAULT_ADMIN_FILTERS}
          disableEditing={true}
          header={
            <div style={{fontSize: '2.4rem', fontWeight: 600}}>
              {t(TransKeys.ANALYSES_MAIN.FOLDERS_DEFAULT_NAME.ALL.TITLE)}
            </div>
          }
        />
      );
    }
    if (analysisFolderError) {
      return (
        <div className={classes.EmptyStateWrapper}>
          <EmptyState title={analysisFolderError.message} />
        </div>
      );
    }
    return (
      <AnalysesFolderView
        key={folderId}
        analysisFolder={analysisFolder}
        isLoading={isLoadingFolder}
        onFolderClicked={onChangeFolder}
        onCreateAnalysis={onCreateAnalysis}
      />
    );
  };

  return (
    <div className={classes.AnalysesMain}>
      <PageLayout.Layout>
        <PageLayout.Title title={t(TransKeys.ANALYSES.HEADER.TITLE)} icon={AnalysesMenuIcon} />
        {renderActions()}
        <PageLayout.Body className={classes.Body} noPadding>
          <div className={classes.Navigation}>
            <AnalysisNavigation
              selectedFolder={folderId}
              onChangeFolder={onChangeFolder}
              sharedAnalysesCount={sharedAnalysesCount}
              foldersTree={foldersTree}
              isLoading={isLoadingTree}
            />
          </div>
          <div className={classes.MainView}>{renderMain()}</div>
        </PageLayout.Body>
      </PageLayout.Layout>
    </div>
  );
};

import * as React from 'react';
import classNames from 'classnames';
import classes from './query-counter.module.scss';
import {AddIcon} from 'ui-components';
import {exists} from 'front-core';

interface OwnProps {
  number?: number;
  plus?: boolean;
  className?: string;
  numberClassName?: string;
}

type AllProps = OwnProps;

export const QueryCounter: React.FC<AllProps> = (props: AllProps) => {
  const {number, plus, className, numberClassName} = props;

  return (
    <div className={classNames(classes.QueryCounter, className)}>
      <div className={classNames(classes.Number, numberClassName)}>
        {plus && <AddIcon className={classes.Plus} />}
        {exists(number) ? number : null}
      </div>
    </div>
  );
};

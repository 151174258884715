import {action, ActionHooks, OnSuccessActionHook} from 'front-core';
import {showConfirmationMessage} from '../interface/interface.actions';
import i18n from '../../config/i18n.config';
import TransKeys from '../../constants/translation-keys';
import {
  RenameWorkflowDTO,
  SetWorkflowOwnerDTO,
  SetWorkflowTeamDTO,
  UpdateWorkflowTeamDTO,
  WorkflowDTO,
} from '../../objects/dto/workflow.dto';

export enum WorkflowsActionType {
  CREATE_WORKFLOW = '@@workflows/CREATE_WORKFLOW',
  DELETE_WORKFLOW = '@@workflows/DELETE_WORKFLOW',
  SET_WORKFLOW_OWNER = '@@workflows/SET_WORKFLOW_OWNER',
  UPDATE_WORKFLOW_TEAM = '@@workflows/UPDATE_WORKFLOW_TEAM',
  UPDATE_WORKFLOW_STATUS = '@@workflows/UPDATE_WORKFLOW_STATUS',
  RENAME_WORKFLOW = '@@workflows/RENAME_WORKFLOW',
}

export const createWorkflow = (data: WorkflowDTO, onSuccess?: OnSuccessActionHook) =>
  action(WorkflowsActionType.CREATE_WORKFLOW, data, {onSuccess});

export const deleteWorkflowConfirmed = (id: number, onSuccess?: OnSuccessActionHook) =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.DELETE_WORKFLOW.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.DELETE_WORKFLOW.CONTENT),
    },
    () => [deleteWorkflow(id, onSuccess)]
  );

export const deleteWorkflow = (id: number, onSuccess?: OnSuccessActionHook) =>
  action(WorkflowsActionType.DELETE_WORKFLOW, id, {onSuccess});

export const setWorkflowOwner = (data: SetWorkflowOwnerDTO, onSuccess?: OnSuccessActionHook) =>
  action(WorkflowsActionType.SET_WORKFLOW_OWNER, data, {onSuccess});

export const updateWorkflowTeam = (data: SetWorkflowTeamDTO, hooks?: ActionHooks) =>
  action(WorkflowsActionType.UPDATE_WORKFLOW_TEAM, data, hooks);

export const updateWorkflowStatus = (data: UpdateWorkflowTeamDTO, hooks?: ActionHooks) =>
  action(WorkflowsActionType.UPDATE_WORKFLOW_STATUS, data, hooks);

export const renameWorkflow = (data: RenameWorkflowDTO, onSuccess?: OnSuccessActionHook) =>
  action(WorkflowsActionType.RENAME_WORKFLOW, data, {onSuccess});

import React, {createContext, useContext, useState, useCallback, ReactNode, useMemo} from 'react';
import {
  CRON_DEFAULT_VALUES,
  CronUnitsPositions,
  CronUnits,
} from '../../../../../../../../constants/cron-exp.consts';

type CronExpBuilderType = {
  cronExpValue: string;
  cronExpValueAsArray: string[];
  setUnit: (unit: CronUnits, value: string) => void;
};

const CronExpBuilderContext = createContext<CronExpBuilderType | null>(null);
CronExpBuilderContext.displayName = 'CronExpBuilderContext';

interface OwnProps {
  children: ReactNode;
  defaultCronExp: string;
  onChange?: (value: string) => void;
}

type AllProps = OwnProps;

export const CronExpBuilderContextProvider: React.FC<AllProps> = (props: AllProps) => {
  const {children, defaultCronExp, onChange} = props;
  const [cronExpValue, setCronExpValue] = useState(defaultCronExp || CRON_DEFAULT_VALUES.DAILY);
  const cronExpValueAsArray = useMemo(() => cronExpValue.split(' '), [cronExpValue]);
  const setUnit = useCallback(
    (unit: CronUnits, value: string) => {
      cronExpValueAsArray[CronUnitsPositions[unit]] = value;
      const updatedValue = cronExpValueAsArray.join(' ');
      setCronExpValue(updatedValue);
      onChange && onChange(updatedValue);
    },
    [cronExpValueAsArray, setCronExpValue, onChange]
  );

  const value = {
    cronExpValue,
    cronExpValueAsArray,
    setUnit,
  };

  return <CronExpBuilderContext.Provider value={value}>{children}</CronExpBuilderContext.Provider>;
};

export function useCronExpBuilder() {
  const context = useContext(CronExpBuilderContext);
  if (context === undefined) {
    throw new Error('useCronExpBuilder must be used within a <CronExpBuilderContextProvider />');
  }
  return context;
}

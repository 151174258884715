import appConfig from './app.config';
import * as amplitude from '@amplitude/analytics-browser';
import {sessionReplayPlugin} from '@amplitude/plugin-session-replay-browser';

export const initAmplitude = () => {
  amplitude.init(appConfig.amplitudeApiKey, {
    // if dev no change in server url
    serverUrl: process.env.NODE_ENV === 'development' ? undefined : appConfig.amplitudeApiEndpoint,
    minIdLength: 1,
  });
  amplitude.add(
    sessionReplayPlugin({
      sampleRate: 1,
    })
  );
};

export const setAmplitudeUserId = userId => {
  amplitude.setUserId(userId?.toString());
};

export const sendAmplitudeData = (eventType, eventProperties) => {
  amplitude.logEvent(eventType, eventProperties);
};

export const setAmplitudeUserProperties = properties => {
  const identifyEvent = new amplitude.Identify();
  Object.keys(properties).forEach(key => {
    identifyEvent.set(key, properties[key]);
  });
  amplitude.identify(identifyEvent);
};

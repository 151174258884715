import {SearchInput, SwitchActions, TeamSelector} from 'ui-components';
import {FlexHorizontal} from '../../../../../shared/components/layout/flex-layout/general-flex-layouts.component.';
import classes from './workflows-list-filters.module.scss';
import {useProductData} from '../../../../../../core/hooks/use-product-data.hook';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {debounce} from 'lodash';
import {WorkflowFiltersDTO} from '../../../../../../objects/dto/workflow.dto';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {
  WorkflowCounts,
  WorkflowStatus,
  WorkflowType,
} from '../../../../../../objects/models/workflow.model';

type WorkflowsListFiltersProps = {
  filters: WorkflowFiltersDTO;
  onChangeFilter: (newFilters: WorkflowFiltersDTO) => void;
  onSort: (key: string) => void;
  counts: WorkflowCounts;
  disabled?: boolean;
};

export const WorkflowsListFilters = (props: WorkflowsListFiltersProps) => {
  const {counts, filters, onChangeFilter, disabled} = props;
  const {actualTeams: teams} = useProductData();
  const {t} = useTranslation();
  const [searchValue, setSearchValue] = useState(filters.q || '');
  const {status, type, teamId} = filters;
  const onChangeFilterRef = useRef<any>(onChangeFilter);
  onChangeFilterRef.current = onChangeFilter;

  const changeSearchInFilters = useMemo(
    () => debounce((value: string) => onChangeFilterRef.current({q: value}), 200),
    [onChangeFilterRef]
  );

  const handleChangeFilter = useCallback(
    (filters: Partial<WorkflowFiltersDTO>) => onChangeFilter(filters),
    [onChangeFilter]
  );

  useEffect(() => {
    changeSearchInFilters(searchValue);
  }, [changeSearchInFilters, searchValue]);

  return (
    <FlexHorizontal fullWidth spaceBetween>
      <div className={classes.FiltersContainer}>
        {teams.length > 0 && (
          <TeamSelector
            disabled={disabled}
            teams={teams}
            value={teamId}
            onChange={(teamId: number) => handleChangeFilter({teamId})}
            clearable
            size="small"
            withPrefix
            hideButtonLabel
          />
        )}
        <SwitchActions
          label="Status"
          disabled={disabled}
          showActionsLabel
          actions={[
            {
              label: 'All',
              count: counts.status.all ?? 0,
              onClick: _ => handleChangeFilter({status: null}),
              isActive: !status,
            },
            {
              label: t(TransKeys.WORKFLOWS.STATUS.ACTIVE),
              count: counts.status.active ?? 0,
              isActive: status === WorkflowStatus.ACTIVE,
              onClick: _ => handleChangeFilter({status: WorkflowStatus.ACTIVE}),
            },
            {
              label: t(TransKeys.WORKFLOWS.STATUS.STOPPED),
              count: counts.status.stopped ?? 0,
              isActive: status === WorkflowStatus.STOPPED,
              onClick: _ => handleChangeFilter({status: WorkflowStatus.STOPPED}),
            },
          ]}
        />
        <SwitchActions
          label="Type"
          disabled={disabled}
          showActionsLabel
          actions={[
            {
              label: 'All',
              count: counts.type.all ?? 0,
              onClick: _ => handleChangeFilter({type: null}),
              isActive: !type,
            },
            {
              label: t(TransKeys.WORKFLOWS.TYPE.KPI_REACH.LABEL),
              count: counts.type.kpi_reach ?? 0,
              isActive: type === WorkflowType.KPI_REACH,
              onClick: _ => handleChangeFilter({type: WorkflowType.KPI_REACH}),
            },
            {
              label: t(TransKeys.WORKFLOWS.TYPE.CUSTOM_SQL.LABEL),
              count: counts.type.custom_sql ?? 0,
              isActive: type === WorkflowType.CUSTOM_SQL,
              onClick: _ => handleChangeFilter({type: WorkflowType.CUSTOM_SQL}),
            },
          ]}
        />
      </div>
      <div>
        <SearchInput
          placeholder={'Search'}
          value={searchValue}
          onChange={setSearchValue}
          className={classes.SearchInput}
        />
      </div>
    </FlexHorizontal>
  );
};

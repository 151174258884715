import {NotSignificantChangeKPIAndUsers, RCAInsightSentence} from './objects';
import sharedClasses from '../insights.module.scss';
import {
  ChangeInTotalEntitiesSentencePart,
  ChangeInValueSentencePart,
  SegmentNameSentencePart,
} from '../components/shared-sentence-parts/shared-sentence-parts.components';

interface OwnProps extends RCAInsightSentence {
  formattedSentenceParts: NotSignificantChangeKPIAndUsers;
}

type AllProps = OwnProps;

const NotSignificantChangeKPIAndUsersFormatter: React.FC<AllProps> = (props: AllProps) => {
  const {formattedSentenceParts, sentenceParts} = props;
  const {
    presentTensePerformance,
    changeInShareOfEntitiesDescription,
    changeInTotalEntities,
    entity,
  } = formattedSentenceParts;
  const {segmentGroupName, segmentName, changeInValue} = sentenceParts;

  return (
    <div className={sharedClasses.InsightSentenceContainer}>
      <SegmentNameSentencePart segmentGroupName={segmentGroupName} segmentName={segmentName} />
      <span
        className={sharedClasses.InsightSentencePart}
      >{`${presentTensePerformance} vs the analyzed period by`}</span>
      <ChangeInValueSentencePart changeInValue={changeInValue} />
      <span
        className={sharedClasses.InsightSentencePart}
      >{`and the share of ${entity} ${changeInShareOfEntitiesDescription} by`}</span>
      <ChangeInTotalEntitiesSentencePart changeInTotalEntities={changeInTotalEntities} />
    </div>
  );
};

export {NotSignificantChangeKPIAndUsersFormatter};

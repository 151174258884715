import yup from '../../config/yup.config';
import {Goal} from '../models/goal.model';

export type GoalDTO = Partial<Goal>;

export const goalDTOValidator = yup.object().shape({
  id: yup.number().optional().nullable(),
  metricId: yup.number().required(),
  value: yup.number().required(),
});

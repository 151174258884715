import {NRC} from './index';
import {RequestType} from 'front-core';
import {toCamelCase} from 'front-core';

export const getAppStatusCountersNetworkRequest: NRC<void, any> = () => ({
  method: RequestType.GET,
  relativeUrl: `/app/status/counters`,
  responseTransformer: res => toCamelCase(res.data),
});

export const getProductDataNetworkRequest: NRC<void, any> = () => ({
  method: RequestType.GET,
  relativeUrl: `/app/status/product-data`,
  responseTransformer: res => toCamelCase(res.data, ['product_entities']),
});

import {ParameterType} from 'ui-components';
import {searchMetricsNetworkRequest} from '../http/metrics.network-requests';
import {searchFeaturesNetworkRequest} from '../http/features.network-requests';
import {searchSignalsNetworkRequest} from '../http/signals.network-requests';
import {searchSegmentsNetworkRequest} from '../http/segments.network-requests';
import {searchFunnelsNetworkRequest} from '../http/funnels.network-requests';
import {searchContentsNetworkRequest} from '../http/contents.network-requests';

export const parametersFormModelConfig = {
  [ParameterType.METRIC]: {
    networkRequest: searchMetricsNetworkRequest,
    labelAttributePath: 'name',
    valueAttributePath: 'id',
  },
  [ParameterType.FEATURE]: {
    networkRequest: searchFeaturesNetworkRequest,
    labelAttributePath: 'name',
    valueAttributePath: 'id',
  },
  [ParameterType.CONTENT]: {
    networkRequest: searchContentsNetworkRequest,
    labelAttributePath: 'name',
    valueAttributePath: 'id',
  },
  [ParameterType.SIGNAL]: {
    networkRequest: searchSignalsNetworkRequest,
    labelAttributePath: 'name',
    valueAttributePath: 'id',
  },
  [ParameterType.FUNNEL]: {
    networkRequest: searchFunnelsNetworkRequest,
    labelAttributePath: 'name',
    valueAttributePath: 'id',
  },
  [ParameterType.SEGMENT]: {
    networkRequest: searchSegmentsNetworkRequest,
    labelAttributePath: 'name',
    valueAttributePath: 'id',
  },
};

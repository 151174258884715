import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import TransKeys from 'src/constants/translation-keys';
import humanizeDuration from 'humanize-duration';
import {AnalysisTypeId} from '../../../constants/analysis-type-id';
import {analysisRuntimeMinutes} from '../../../constants/analysis-type-runtime';

export const getAnalysisEstimatedRuntime = (analysisTypeId: number) => {
  const totalMinutes = analysisRuntimeMinutes[analysisTypeId];
  if (!totalMinutes) {
    return null;
  }
  const totalMilliseconds = totalMinutes * 60000;
  return humanizeDuration(totalMilliseconds, {delimiter: ' and '});
};

export function useAnalysisRuntime() {
  const {t} = useTranslation();

  const getEstimatedRuntimeFormatted = useCallback(
    (analysisTypeId: AnalysisTypeId) => {
      const estimatedRuntime = getAnalysisEstimatedRuntime(analysisTypeId);
      if (!estimatedRuntime) {
        return t(TransKeys.CREATE_ANALYSIS_FORM.SUCCESS_MESSAGE.COMPLETION_TIME_DEFAULT);
      }
      return t(TransKeys.CREATE_ANALYSIS_FORM.SUCCESS_MESSAGE.COMPLETION_TIME, {estimatedRuntime});
    },
    [t]
  );

  return {
    getEstimatedRuntimeFormatted,
  };
}

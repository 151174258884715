import * as yup from 'yup';
import {SchemaOf} from 'yup';

export interface InviteSignupFormDTO {
  firstName: string;
  lastName: string;
  email: string;
  token: string;
  inviterUserId: number;
}

export const inviteSignupFormDTOValidator: SchemaOf<InviteSignupFormDTO> = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  token: yup.string().required(),
  inviterUserId: yup.number().required(),
});

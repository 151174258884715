import {NRC} from './index';
import {RequestType, toCamelCase, toSnakeCase} from 'front-core';

export const getAnomalyGameNetworkRequest: NRC<void, any> = () => ({
  method: RequestType.GET,
  relativeUrl: `app/homepage/anomaly-game`,
  responseTransformer: res => toCamelCase(res.data),
});

export const addLabelAnomalyGameNetworkRequest: NRC<any, void> = data => ({
  method: RequestType.POST,
  relativeUrl: `app/homepage/anomaly-game`,
  body: data,
  bodyTransformer: toSnakeCase,
});

import {Epic} from 'redux-observable';
import {createRequestEpic} from 'front-core';
import {ActionKey} from '../../constants/action-key';
import HttpClient from '../../services/http-client.service';
import {SegmentCategoriesActionType} from './segment-categories.actions';
import {modelCreated, modelDeleted, modelUpdated, notifyEvent} from '../core/core.actions';
import {ModelKey} from '../../constants/model-key';
import {segmentCategoryToastCreator} from '../toasts.actions';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {showToastMessage} from '../interface/interface.actions';
import {ToastType} from '../../objects/system/toast-type.enum';
import {demoProductLimitedActionsFilter, errorExtractor} from '../store.utils';
import {
  createSegmentCategoryNetworkRequest,
  editSegmentCategoryNetworkRequest,
  deleteSegmentCategoryNetworkRequest,
  swapSegmentCategoryNetworkRequest,
} from '../../http/segment-categories.network-requests';

export const createSegmentCategoryEpic: Epic = createRequestEpic(
  {
    types: [SegmentCategoriesActionType.CREATE_SEGMENT_CATEGORY],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.CREATE_SEGMENT_CATEGORY,
    request: createSegmentCategoryNetworkRequest,
    onSuccess: segmentCategory => [
      notifyEvent(AmplitudeEvent.SEGMENT_CATEGORY_CREATED, {id: segmentCategory.id}),
      segmentCategoryToastCreator('CREATE_SUCCESS'),
      modelCreated(segmentCategory, ModelKey.SEGMENT_CATEGORY),
    ],
    onError: err_ => {
      const err = errorExtractor(err_);
      if (err.errorCode === 5002) {
        return [
          showToastMessage('Category name already is already taken', ToastType.ERROR),
          notifyEvent(AmplitudeEvent.CREATE_SEGMENT_CATEGORY_ERROR, err),
        ];
      }
      return [segmentCategoryToastCreator('CREATE_ERROR')];
    },
  },
  HttpClient
);

export const updateSegmentCategoryEpic: Epic = createRequestEpic(
  {
    types: [SegmentCategoriesActionType.UPDATE_SEGMENT_CATEGORY],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.UPDATE_SEGMENT_CATEGORY,
    request: editSegmentCategoryNetworkRequest,
    onSuccess: segmentCategory => [
      notifyEvent(AmplitudeEvent.SEGMENT_CATEGORY_UPDATED, {id: segmentCategory.id}),
      segmentCategoryToastCreator('UPDATE_SUCCESS'),
      modelUpdated(segmentCategory, ModelKey.SEGMENT_CATEGORY),
    ],
    onError: err_ => {
      const err = errorExtractor(err_);
      if (err.errorCode === 5002) {
        return [
          showToastMessage('Category name already is already taken', ToastType.ERROR),
          notifyEvent(AmplitudeEvent.UPDATE_SEGMENT_CATEGORY_ERROR, err),
        ];
      }
      return [segmentCategoryToastCreator('UPDATE_ERROR')];
    },
  },
  HttpClient
);

export const swapSegmentCategoryEpic: Epic = createRequestEpic(
  {
    types: [SegmentCategoriesActionType.SWAP_SEGMENT_CATEGORY],
    actionKey: ActionKey.SWAP_SEGMENT_CATEGORY,
    request: swapSegmentCategoryNetworkRequest,
    onSuccess: (res, payload) => [
      notifyEvent(AmplitudeEvent.SEGMENT_CATEGORY_UPDATED, {id: payload.segmentCategoryId}),
      segmentCategoryToastCreator('UPDATE_SUCCESS'),
      modelUpdated({id: payload.segmentCategoryId}, ModelKey.SEGMENT_CATEGORY),
    ],
    onError: err_ => {
      return [segmentCategoryToastCreator('UPDATE_ERROR')];
    },
  },
  HttpClient
);

export const deleteSegmentCategoryEpic: Epic = createRequestEpic(
  {
    types: [SegmentCategoriesActionType.DELETE_SEGMENT_CATEGORY],
    actionKey: ActionKey.DELETE_SEGMENT_CATEGORY,
    request: deleteSegmentCategoryNetworkRequest,
    onSuccess: (res, payload) => [
      notifyEvent(AmplitudeEvent.SEGMENT_CATEGORY_DELETED, {id: payload}),
      segmentCategoryToastCreator('DELETE_SUCCESS'),
      modelDeleted(payload, ModelKey.SEGMENT_CATEGORY),
    ],
    onError: err => [segmentCategoryToastCreator('DELETE_ERROR')],
  },
  HttpClient
);

import {BaseModel} from './base.model';
import {HistorifyModel} from './historify-model.model';

export enum AnnotationType {
  BUG = 'bug',
  RELEASE = 'release',
  OTHER = 'other',
}

export interface Annotation extends BaseModel, HistorifyModel {
  id: number;
  title: string;
  type: AnnotationType;
  timestamp: string;
  relatedExperimentId: number;
}

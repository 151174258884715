import {useCallback, useContext, useEffect, useMemo, useRef} from 'react';
import {
  ANALYSIS_MODE_KEY,
  GOAL_KEY,
} from '../analysis-parameters/analysis-101/analysis-101-form.component';
import {exists, HttpClientContext} from 'front-core';
import {get} from 'lodash';
import {METADATA_KEY, PARAMETERS_METADATA_KEY} from '../../../../constants/parameters-saved-keys';
import {useRemoteSourceStated} from 'ui-components';
import {
  getMetricNetworkRequest,
  searchMetricsNetworkRequest,
} from '../../../../http/metrics.network-requests';
import {ParametersFormContext} from '../../../shared/core/parameters-form/parameters-form.context';
import {MetricType} from '../../../../objects/models/metric.model';
import {useMetricAggregationModeSwitchActions} from './use-metric-aggregation-mode-switch-actions.hook';
import {AggregationMode} from '../../../../objects/models/signal.model';

const METRIC_TYPE_KEY = 'metric_type';

export const useMetricKPIAnalysis = () => {
  const {parameters, changeParametersValue} = useContext(ParametersFormContext);
  const http = useContext(HttpClientContext);
  const changeParametersValueRef = useRef<any>();
  changeParametersValueRef.current = changeParametersValue;

  const {
    source: metric,
    exec: getMetric,
    clear: clearMetric,
  } = useRemoteSourceStated({
    type: 'source',
    networkRequest: getMetricNetworkRequest,
  });

  const switchActions = useMetricAggregationModeSwitchActions({
    metricType: metric?.type,
    mode: parameters[ANALYSIS_MODE_KEY],
    onChange: mode => changeParametersValueRef.current({[ANALYSIS_MODE_KEY]: mode}),
  });

  const showSelectMode = useMemo(() => switchActions.length > 0, [switchActions]);

  const metricId = useMemo(
    () => get(parameters, `${PARAMETERS_METADATA_KEY}.${METADATA_KEY.METRIC_ID_KEY}`),
    [parameters]
  );
  const analysisGoalId = useMemo(() => get(parameters, GOAL_KEY), [parameters]);

  const onMetricSelected = useCallback(
    (signalId: number, metric: any) => {
      let defaultAnalysisMode: AggregationMode = undefined;
      if (metric.aggregationMode) {
        defaultAnalysisMode = metric.aggregationMode;
      } else if (metric.type === MetricType.USAGE) {
        defaultAnalysisMode = AggregationMode.COUNT_DISTINCT_FROM_X;
      } else if (metric.type === MetricType.REVENUE || metric.type === MetricType.BOUNDED_REVENUE) {
        defaultAnalysisMode = AggregationMode.SUM;
      }
      changeParametersValueRef.current({
        [GOAL_KEY]: signalId,
        [ANALYSIS_MODE_KEY]: defaultAnalysisMode,
        [PARAMETERS_METADATA_KEY]: {
          [METADATA_KEY.METRIC_ID_KEY]: metric.id,
          [METRIC_TYPE_KEY]: metric.type,
        },
      });
    },
    [changeParametersValueRef]
  );

  const getMetricBySignalId = useCallback(
    async (signalId: number) => {
      const metrics = await http.exec(
        searchMetricsNetworkRequest(undefined, {
          signalId,
        })
      );
      const metric = metrics[0];
      if (!exists(metrics)) {
        return;
      }
      onMetricSelected(signalId, metric);
    },
    [onMetricSelected, http]
  );

  // Effects
  useEffect(() => {
    if (exists(metricId) && metric?.id !== metricId) {
      getMetric(metricId);
    }
  }, [metricId, getMetric, metric]);
  useEffect(() => {
    if (exists(analysisGoalId) && !exists(metricId)) {
      getMetricBySignalId(analysisGoalId);
    }
  }, [getMetricBySignalId, analysisGoalId, metricId]);
  useEffect(() => {
    if (!exists(metricId) && !exists(analysisGoalId) && exists(metric)) {
      clearMetric();
    }
  }, [metricId, analysisGoalId, metric, clearMetric]);

  return {
    metric,
    metricId,
    showSelectMode,
    usageActions: switchActions as any,
    onMetricSelected,
  };
};

import {useCallback, useEffect, useMemo} from 'react';
import {useRemoteSourceStated} from 'ui-components';
import {isEmpty} from 'lodash';
import {validateSqlNetworkRequest} from '../../../../http/validations.network-requests';

export enum SqlValidationStatus {
  EMPTY = 'EMPTY',
  PENDING = 'PENDING',
  LOADING = 'LOADING',
  VALID = 'VALID',
  INVALID = 'INVALID',
  UNKNOWN = 'UNKNOWN',
}

export interface SqlValidationResponse {
  validate: () => void;
  status: SqlValidationStatus;
  isLoading: boolean;
  isValid: boolean;
  columns: string[];
}

export function useSqlValidation(query: string): SqlValidationResponse {
  const {
    source: validation,
    exec: execValidation,
    clear: clearValidation,
    isLoading,
  } = useRemoteSourceStated({
    networkRequest: validateSqlNetworkRequest,
    initialValue: {},
  });

  const isValid = useMemo(() => validation?.success || false, [validation]);

  const status = useMemo(() => {
    if (!query) {
      return SqlValidationStatus.EMPTY;
    }

    if (isLoading) {
      return SqlValidationStatus.LOADING;
    }

    if (isEmpty(validation)) {
      return SqlValidationStatus.PENDING;
    }

    if (validation?.success === false) {
      if (validation?.error_code === '6113') {
        return SqlValidationStatus.UNKNOWN;
      }

      return SqlValidationStatus.INVALID;
    }

    if (validation?.success === true) {
      return SqlValidationStatus.VALID;
    }

    return SqlValidationStatus.UNKNOWN;
  }, [isLoading, query, validation]);

  const validate = useCallback(() => {
    if (query && status === SqlValidationStatus.PENDING) {
      execValidation({sql: query});
    }
  }, [query, status, execValidation]);

  useEffect(() => {
    clearValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(
    () => validate(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    validate,
    status,
    isLoading,
    isValid,
    columns: validation?.columns || {},
  };
}

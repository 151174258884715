import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo} from 'react';
import classes from './experiment-automations-panel.module.scss';
import {
  ActionsDropdown,
  Button,
  EditIcon,
  FancyHeader,
  FlaskGearLightIcon,
  InteractionContext,
  InteractionType,
  ModalLayout,
  ModelType,
  MoreIcon,
  PlusLightIcon,
  RocketLaunchLightIcon,
  ScaleUnbalancedLightIcon,
  TextButton,
  TrashIcon,
  useRemoteSourceStated,
} from 'ui-components';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {TableColumn} from '../../../shared/components/general/grid-table/grid-table.types';
import {GridTable} from '../../../shared/components/general/grid-table/grid-table.component';
import {getExperimentAutomationsNetworkRequest} from '../../../../http/experiment-automations.network-requests';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {
  ExperimentAutomation,
  ExperimentAutomationType,
} from '../../../../objects/models/experiment-automation.model';
import {useDispatch} from 'react-redux';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {ModelKey} from '../../../../constants/model-key';
import {CoreActionsType} from '../../../../store/core/core.actions';
import {SystemDescriptionBlock} from '../../../shared/components/general/system-description-block/system-description-block.component';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {PanelKey} from '../../../../constants/panels';
import {ModelActionsDropdown} from '../../../shared/core/model-actions-dropdown/model-actions-dropdown.component';
import {deleteExperimentAutomationConfirmed} from '../../../../store/experiment-automations/experiment-automations.actions';
import {EXPERIMENT_AUTOMATION_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {SqlElementToTextUtils} from '../../../../utils/sql-element-to-text.utils';

interface OwnProps {}

type AllProps = OwnProps;

const DEFAULT_QUERY = {
  limit: 1000,
  orderBy: 'created_on',
  order: 'desc',
};
const ACTION_KEYS = [
  CoreActionsType.MODEL_CREATED,
  CoreActionsType.MODEL_UPDATED,
  CoreActionsType.MODEL_DELETED,
];

export const ExperimentAutomationsPanel: React.FC<AllProps> = (props: AllProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {openSecondaryPanel} = useContext(PanelsContext);
  const {postMessage} = useContext(InteractionContext);
  const {
    source: automationsRes,
    exec: getAutomations,
    isLoading,
  } = useRemoteSourceStated({
    networkRequest: getExperimentAutomationsNetworkRequest,
    initialValue: {data: []},
  });

  const {data: automations} = automationsRes;
  const byType = useMemo(
    () => ({
      [ExperimentAutomationType.AB_TEST]: automations.filter(
        (automation: ExperimentAutomation) => automation.type === ExperimentAutomationType.AB_TEST
      ),
      [ExperimentAutomationType.RELEASE]: automations.filter(
        (automation: ExperimentAutomation) => automation.type === ExperimentAutomationType.RELEASE
      ),
    }),
    [automations]
  );
  const onDelete = useCallback(
    (id: number) => dispatch(deleteExperimentAutomationConfirmed(id)),
    [dispatch]
  );
  const onEdit = useCallback(
    (id: number) =>
      openSecondaryPanel(PanelKey.EXPERIMENT_AUTOMATION_FORM_PANEL, {
        [EXPERIMENT_AUTOMATION_ID_PATH_PARAM]: id,
      }),
    [openSecondaryPanel]
  );
  const onKPIClicked = useCallback(
    (signalId: number) =>
      postMessage({
        type: InteractionType.REFERENCE,
        payload: {modelId: signalId, modelType: ModelType.SIGNAL},
      }),
    [postMessage]
  );
  const columns: TableColumn[] = useMemo(
    () => [
      {
        key: 'name',
        title: t(TransKeys.EXPERIMENT_AUTOMATIONS_PANEL.TABLE.HEADERS.NAME.TITLE),
        helperText: t(TransKeys.EXPERIMENT_AUTOMATIONS_PANEL.TABLE.HEADERS.NAME.HELPER_TEXT),
        render: (item: ExperimentAutomation) => item.name,
        width: '40%',
      },
      {
        key: 'kpis',
        title: t(TransKeys.EXPERIMENT_AUTOMATIONS_PANEL.TABLE.HEADERS.KPIS.TITLE),
        helperText: t(TransKeys.EXPERIMENT_AUTOMATIONS_PANEL.TABLE.HEADERS.KPIS.HELPER_TEXT),
        width: '25%',
        render: (item: ExperimentAutomation) => (
          <div className={classes.Cell}>
            <TextButton onClick={() => onKPIClicked(item.primaryGoal.id)}>
              {item.primaryGoal.name}
            </TextButton>
          </div>
        ),
      },
      {
        key: 'population_filter',
        title: t(TransKeys.EXPERIMENT_AUTOMATIONS_PANEL.TABLE.HEADERS.POPULATION.TITLE),
        helperText: t(TransKeys.EXPERIMENT_AUTOMATIONS_PANEL.TABLE.HEADERS.POPULATION.HELPER_TEXT),
        render: (item: ExperimentAutomation) => (
          <div>
            {item.populationFilter
              ? SqlElementToTextUtils.translate(item.populationFilter)
              : 'All Population'}
          </div>
        ),
      },
      {
        key: 'actions',
        title: '',
        align: 'right',
        render: (item: ExperimentAutomation) => (
          <ModelActionsDropdown
            className={classes.Button}
            actions={[
              {
                key: 'edit',
                title: t(TransKeys.GENERAL.ACTIONS.EDIT),
                onClick: () => onEdit(item.id),
                icon: EditIcon,
              },
              {
                key: 'delete',
                title: t(TransKeys.GENERAL.ACTIONS.DELETE),
                onClick: () => onDelete(item.id),
                icon: TrashIcon,
              },
            ]}
            label={t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
            icon={MoreIcon}
            iconDropdown
          />
        ),
      },
    ],
    [t, onEdit, onDelete, onKPIClicked]
  );
  const createActions = useMemo(
    () => [
      {
        title: t(TransKeys.EXPERIMENT_AUTOMATIONS_PANEL.ACTIONS.CREATE_RELEASE_AUTOMATION),
        icon: RocketLaunchLightIcon,
        onClick: () =>
          openSecondaryPanel(PanelKey.EXPERIMENT_AUTOMATION_FORM_PANEL, {
            type: ExperimentAutomationType.RELEASE,
          }),
      },
      {
        title: t(TransKeys.EXPERIMENT_AUTOMATIONS_PANEL.ACTIONS.CREATE_AB_TEST_AUTOMATION),
        icon: ScaleUnbalancedLightIcon,
        onClick: () =>
          openSecondaryPanel(PanelKey.EXPERIMENT_AUTOMATION_FORM_PANEL, {
            type: ExperimentAutomationType.AB_TEST,
          }),
      },
    ],
    [openSecondaryPanel, t]
  );

  useEffect(() => {
    getAutomations(DEFAULT_QUERY);
  }, [getAutomations]);
  useEffect(() => {
    const listener = action => {
      if (action.payload.modelKey === ModelKey.EXPERIMENT_AUTOMATION) {
        getAutomations(DEFAULT_QUERY);
      }
    };
    dispatch(registerActionListener(ACTION_KEYS, listener));
    return () => {
      dispatch(removeActionListener(ACTION_KEYS, listener));
    };
  }, [getAutomations, dispatch]);

  return (
    <div className={classes.ExperimentAutomationsPanelContainer}>
      <ModalLayout>
        {isLoading && <GenericLoading />}
        <FancyHeader
          icon={FlaskGearLightIcon}
          title={t(TransKeys.EXPERIMENT_AUTOMATIONS_PANEL.TITLE)}
        />
        <div className={classes.Content}>
          <SystemDescriptionBlock>
            {t(TransKeys.EXPERIMENT_AUTOMATIONS_PANEL.DESCRIPTION)}
          </SystemDescriptionBlock>
          <div className={classes.Actions}>
            <ActionsDropdown
              buttonComponent={props => (
                <Button onClick={props.onClick} icon={PlusLightIcon}>
                  {t(TransKeys.EXPERIMENT_AUTOMATIONS_PANEL.ACTIONS.ADD_AUTOMATION)}
                </Button>
              )}
              actions={createActions}
            />
          </div>
          <div className={classes.SectionHeader}>
            {t(TransKeys.EXPERIMENT_AUTOMATIONS_PANEL.SECTIONS.RELEASE.TITLE)}
          </div>
          <div className={classes.Table}>
            <GridTable
              dataKey={'id'}
              data={byType[ExperimentAutomationType.RELEASE] || []}
              columns={columns}
              emptyStateRow={
                <div className={classes.EmptyStateRow}>
                  {t(TransKeys.EXPERIMENT_AUTOMATIONS_PANEL.EMPTY_STATE)}
                </div>
              }
            />
          </div>
          <div className={classes.SectionHeader}>
            {t(TransKeys.EXPERIMENT_AUTOMATIONS_PANEL.SECTIONS.AB_TESTS.TITLE)}
          </div>
          <div className={classes.Table}>
            <GridTable
              dataKey={'id'}
              data={byType[ExperimentAutomationType.AB_TEST] || []}
              columns={columns}
              emptyStateRow={
                <div className={classes.EmptyStateRow}>
                  {t(TransKeys.EXPERIMENT_AUTOMATIONS_PANEL.EMPTY_STATE)}
                </div>
              }
            />
          </div>
        </div>
      </ModalLayout>
    </div>
  );
};

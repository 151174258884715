import * as React from 'react';
import classNames from 'classnames';
import classes from './parameter.module.scss';
import {NavLink} from 'react-router-dom';

interface OwnProps {
  label?: string;
  value: any;
  onClick?: () => void;
  link?: string;
  className?: string;
}

type AllProps = OwnProps;

export const Parameter: React.FC<AllProps> = (props: AllProps) => {
  const {label, value, onClick, link, className} = props;

  const renderContent = () => {
    if (link) {
      return (
        <NavLink to={link} className={classNames(classes.Value, classes.Clickable)}>
          {value}
        </NavLink>
      );
    }
    return (
      <div onClick={onClick} className={classNames(classes.Value, onClick && classes.Clickable)}>
        {value}
      </div>
    );
  };

  return (
    <div className={classNames(classes.Parameter, className)}>
      {label && <div className={classes.Label}>{label}:</div>}
      {renderContent()}
    </div>
  );
};

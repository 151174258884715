import * as React from 'react';
import classNames from 'classnames';
import classes from './empty-state-simple-mode.module.scss';
import {ImagesResource} from '../../../../../../assets/images';
import {Button} from 'ui-components';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';

interface OwnProps {
  onDefineTree: () => void;
  className?: string;
}

type AllProps = OwnProps;

export const EmptyStateSimpleMode: React.FC<AllProps> = (props: AllProps) => {
  const {onDefineTree, className} = props;
  const {t} = useTranslation();

  return (
    <div className={classNames(classes.EmptyStateSimpleMode, className)}>
      <img src={ImagesResource.app.kpiTree} alt={''} />
      <div className={classes.Main}>{t(TransKeys.KPI_TREE.EMPTY_STATE_SIMPLE_MODE.TEXT)}</div>
      <Button onClick={onDefineTree} size={'large'}>
        {t(TransKeys.KPI_TREE.EMPTY_STATE_SIMPLE_MODE.BUTTON)}
      </Button>
    </div>
  );
};

import {FunnelsActionType} from '../store/funnels/funnels.actions';
import {MetricsActionType} from '../store/metrics/metrics.actions';
import {MetricCategoriesActionType} from '../store/metric-categories/metric-categories.actions';
import {SegmentsActionType} from '../store/segments/segments.actions';
import {FeaturesActionType} from '../store/features/features.actions';
import {ContentActionType} from '../store/content/content.actions';
import {EventGroupActionType} from '../store/event-groups/event-groups.actions';
import {MilestonesActionType} from '../store/milestones/milestones.actions';
import {TablesActionType} from '../store/tables/tables.actions';
import {UserSettingsActionType} from '../store/settings/user-settings.actions';
import {SegmentCategoriesActionType} from '../store/segment-categories/segment-categories.actions';
import {AnalysisFolderActionType} from '../store/analysis-folders/analysis-folders.actions';
import {AnnotationsActionType} from '../store/annotations/annotations.actions';
import {FollowUpActionsActionType} from '../store/follow-up-actions/follow-up-actions.actions';
import {GoalsActionType} from '../store/goals/goals.actions';
import {HealthMonitorActionType} from '../store/health-monitor/health-monitor.actions';
import {ModelSamplesActionType} from '../store/model-samples/model-samples.actions';
import {SourceTableActionType} from '../store/source-tables/source-tables.actions';
import {UserReactionsActionType} from '../store/user-reactions/user-reactions.actions';
import {AnalysesActionType} from '../store/analyses/analyses.actions';
import {ExperimentsActionType} from '../store/experiments/experiments.actions';
import {MetricTreeActionType} from '../store/metric-tree/metric-tree.actions';

export const DISABLED_ACTIONS_FOR_DEMO_PRODUCT = [
  ExperimentsActionType.CREATE_EXPERIMENT,
  ExperimentsActionType.PATCH_EXPERIMENT,
  ExperimentsActionType.UPDATE_EXPERIMENT,
  ExperimentsActionType.DELETE_EXPERIMENT,
  ExperimentsActionType.CREATE_EXPERIMENT_SUBSCRIPTION,
  ExperimentsActionType.EDIT_EXPERIMENT_SUBSCRIPTION,
  ExperimentsActionType.DELETE_EXPERIMENT_SUBSCRIPTION,
  ExperimentsActionType.RERUN_EXPERIMENT_LAST_COMPLETED_ANALYSIS_RESULT,
  AnalysesActionType.REQUEST_ANALYSIS,
  AnalysesActionType.EDIT_ANALYSIS,
  AnalysesActionType.UPDATE_ANALYSIS_RESULT,
  AnalysesActionType.DELETE_ANALYSIS_RESULT,
  AnalysesActionType.CREATE_SCHEDULE_ANALYSIS,
  AnalysesActionType.EDIT_SCHEDULE_ANALYSIS,
  AnalysesActionType.DELETE_SCHEDULE_ANALYSIS,
  AnalysesActionType.CHANGE_ANALYSIS_PARAMETERS,
  AnalysesActionType.RERUN_ANALYSIS_RESULT,
  AnalysesActionType.STOP_ANALYSIS_RESULT,
  AnalysesActionType.MOVE_ANALYSIS_TO_FOLDER,
  FunnelsActionType.UPDATE_FUNNEL,
  FunnelsActionType.CREATE_FUNNEL,
  FunnelsActionType.DELETE_FUNNEL,
  FunnelsActionType.PUBLISH_AD_HOC_FUNNEL,
  MetricsActionType.CREATE_METRIC,
  MetricsActionType.UPDATE_METRIC,
  MetricsActionType.DELETE_METRIC,
  MetricsActionType.PUBLISH_AD_HOC_METRIC,
  MetricsActionType.SET_METRIC_OWNER,
  MetricsActionType.SET_METRIC_NORTH_STAR,
  MetricsActionType.RESAMPLE_METRIC,
  MetricsActionType.VALIDATE_METRIC,
  MetricTreeActionType.ADD_INPUT_METRIC,
  MetricTreeActionType.REMOVE_INPUT_METRIC,
  MetricsActionType.UPDATE_TEAM_METRICS,
  MetricsActionType.ADD_METRIC_TO_TEAM,
  MetricCategoriesActionType.CREATE_METRIC_CATEGORY,
  MetricCategoriesActionType.UPDATE_METRIC_CATEGORY,
  MetricCategoriesActionType.SWAP_METRIC_CATEGORY,
  MetricCategoriesActionType.DELETE_METRIC_CATEGORY,
  SegmentsActionType.CREATE_SEGMENT,
  SegmentsActionType.UPDATE_SEGMENT,
  SegmentsActionType.RESCAN_SEGMENT,
  SegmentsActionType.DELETE_SEGMENT,
  SegmentsActionType.VALIDATE_SEGMENT,
  SegmentsActionType.UPDATE_FAVORITES_SEGMENTS,
  FeaturesActionType.CREATE_FEATURE,
  FeaturesActionType.UPDATE_FEATURE,
  FeaturesActionType.DELETE_FEATURE,
  ContentActionType.CREATE_CONTENT,
  ContentActionType.UPDATE_CONTENT,
  ContentActionType.DELETE_CONTENT,
  ContentActionType.RESCAN_CONTENT,
  EventGroupActionType.CREATE_EVENT_GROUP,
  EventGroupActionType.UPDATE_EVENT_GROUP,
  EventGroupActionType.DELETE_EVENT_GROUP,
  EventGroupActionType.VALIDATE_EVENT_GROUP,
  MilestonesActionType.CREATE_MILESTONE,
  MilestonesActionType.UPDATE_MILESTONE,
  MilestonesActionType.DELETE_MILESTONE,
  MilestonesActionType.VALIDATE_MILESTONE,
  TablesActionType.CREATE_TABLE,
  TablesActionType.UPDATE_TABLE,
  TablesActionType.TRIGGER_TABLE_DISCOVERY,
  TablesActionType.TRIGGER_ALL_TABLES_DISCOVERY,
  TablesActionType.TRIGGER_SCAN_ALL_TYPES_OF_TABLES,
  UserSettingsActionType.UPDATE_USER_SETTINGS,
  SegmentCategoriesActionType.CREATE_SEGMENT_CATEGORY,
  SegmentCategoriesActionType.UPDATE_SEGMENT_CATEGORY,
  SegmentCategoriesActionType.DELETE_SEGMENT_CATEGORY,
  // ??? Should we add Swap here? or we want to enable it for demo product?
  AnalysisFolderActionType.CREATE_ANALYSIS_FOLDER,
  AnalysisFolderActionType.UPDATE_ANALYSIS_FOLDER,
  AnalysisFolderActionType.CHANGE_ANALYSIS_FOLDER_ICON,
  AnalysisFolderActionType.DELETE_ANALYSIS_FOLDER,
  AnalysisFolderActionType.MOVE_ANALYSIS_FOLDER,
  AnnotationsActionType.CREATE_ANNOTATION,
  AnnotationsActionType.UPDATE_ANNOTATION,
  AnnotationsActionType.UPDATE_ANNOTATION_STATUS,
  AnnotationsActionType.DELETE_ANNOTATION,
  FollowUpActionsActionType.RCA_FROM_LINE_CHART_FOLLOW_UP_ACTIONS,
  FollowUpActionsActionType.CREATE_FUNNEL_ANALYSIS_CONVERSION_FOLLOW_UP_ACTIONS,
  FollowUpActionsActionType.CREATE_GOAL_ANALYSIS_DRIVERS_IMPROVE_FEATURE_ADOPTION_FOLLOW_UP_ACTIONS,
  FollowUpActionsActionType.CREATE_RETENTION_ANALYSIS_INVESTIGATE_BUCKET_FOLLOW_UP_ACTIONS,
  GoalsActionType.CREATE_GOAL,
  GoalsActionType.UPDATE_GOAL,
  GoalsActionType.DELETE_GOAL,
  // Not sure about health monitor, If I remember correctly it should be hidden in any way
  HealthMonitorActionType.INITIALIZE_HEALTH_MONITOR,
  HealthMonitorActionType.CREATE_HEALTH_MONITOR_GROUP,
  HealthMonitorActionType.EDIT_HEALTH_MONITOR_GROUP,
  HealthMonitorActionType.REMOVE_HEALTH_MONITOR_GROUP,
  HealthMonitorActionType.CREATE_MONITORED_METRIC,
  HealthMonitorActionType.EDIT_MONITORED_METRIC,
  HealthMonitorActionType.REMOVE_MONITORED_METRIC,
  HealthMonitorActionType.CREATE_HEALTH_MONITOR_SUBSCRIPTION,
  HealthMonitorActionType.EDIT_HEALTH_MONITOR_SUBSCRIPTION,
  HealthMonitorActionType.DELETE_HEALTH_MONITOR_SUBSCRIPTION,
  ModelSamplesActionType.RESAMPLE_MODEL,
  SourceTableActionType.TRIGGER_SOURCE_TABLE_DISCOVERY,
  UserReactionsActionType.SHARE_RESOURCE,
  UserReactionsActionType.DELETE_SHARE_RESOURCE,
];

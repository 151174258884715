import classes from '../app-navigator.module.scss';
import {RocketLaunchRegularIcon} from 'ui-components';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {useUserOnboarding} from '../../../../../core/hooks/use-user-onboarding.hook';
import {useCallback} from 'react';
import classNames from 'classnames';
import {OnboardingProgress} from '../../app-user-onboarding/components/onboarding-progress.component';

interface OwnProps {}

type AllProps = OwnProps;

const OnboardingLauncher: React.FC<AllProps> = () => {
  const {t} = useTranslation();
  const {tasks, completedTasks, isDismissed, isCompleted, isDrawerCollapsed, setDrawerCollapsed} =
    useUserOnboarding();

  const toggleDrawerCollapsed = useCallback(() => {
    setDrawerCollapsed(!isDrawerCollapsed);
  }, [isDrawerCollapsed, setDrawerCollapsed]);

  if (isDismissed) {
    return null;
  }

  return (
    <div
      className={classNames(classes.OnboardingNavItem, !isCompleted && classes.Ongoing)}
      onClick={toggleDrawerCollapsed}
    >
      <div className={classes.NavItem}>
        {!isCompleted && (
          <OnboardingProgress
            variant={'circular'}
            count={tasks.length}
            completedCount={completedTasks.length}
          />
        )}
        {isCompleted && <RocketLaunchRegularIcon className={classes.Icon} />}
        <span className={classes.Label}>{t(TransKeys.NAVIGATOR.ONBOARDING)}</span>
      </div>
    </div>
  );
};

export default OnboardingLauncher;

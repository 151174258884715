import * as React from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import {ANALYSIS_RESULT_ID_PATH_PARAM, AppRoutes} from '../../../../../../../constants/app-routes';
import {ArrowRightLightIcon} from 'ui-components';
import classes from './document-fragment-footer.module.scss';
import {LightBulbOnRegularIcon} from 'ui-components';

interface OwnProps {
  analysisResultId: number;
  analysisId: number;
  buttonText: string;
  text?: any;
  className?: string;
}

type AllProps = OwnProps;

export const DocumentFragmentFooter: React.FC<AllProps> = (props: AllProps) => {
  const {buttonText, analysisId, analysisResultId, text, className} = props;

  return (
    <Link
      className={classes.Link}
      to={AppRoutes.viewAnalysis(analysisId, {
        [ANALYSIS_RESULT_ID_PATH_PARAM]: analysisResultId,
      })}
    >
      <div className={classNames(classes.DocumentFragmentFooter, className)}>
        <div className={classes.IconWrapper}>
          <LightBulbOnRegularIcon />
        </div>
        <div className={classes.Text}>{text}</div>
        <div className={classes.GoToAnalysis}>
          <span>{buttonText}</span>
          <ArrowRightLightIcon />
        </div>
      </div>
    </Link>
  );
};

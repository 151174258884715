import * as React from 'react';
import {composition} from 'front-core';
import {Backdrop, Dialog} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../constants/translation-keys';
import {ConfirmationMessage} from '../../../../objects/system/confirmation-message.model';
import {Button, ModalLayout} from 'ui-components';
import classes from './app-confirmation.module.scss';
import {sharedClasses} from '../../../shared';

interface OwnProps extends ConfirmationMessage {
  onApprove: () => void;
  onReject: () => void;
}

type AllProps = OwnProps;

const AppConfirmationComponent: React.FC<AllProps> = (props: AllProps) => {
  const {title, content, approveBtn, rejectBtn, onApprove, onReject} = props;
  const {t} = useTranslation();

  return (
    <Dialog
      classes={{
        paper: sharedClasses.BlankPaper,
      }}
      BackdropComponent={Backdrop}
      BackdropProps={{
        transitionDuration: 250,
      }}
      maxWidth={'md'}
      transitionDuration={0}
      open={Boolean(title)}
      onClose={_ => onReject()}
    >
      <ModalLayout
        className={classes.Confirmation}
        footer={
          <div className={classes.Actions}>
            <Button className={classes.Action} variant={'outlined'} onClick={_ => onReject()}>
              {t(rejectBtn)}
            </Button>
            <Button className={classes.Action} onClick={_ => onApprove()} variant={'contained'}>
              {t(approveBtn)}
            </Button>
          </div>
        }
      >
        <div className={classes.Body}>
          {title && <div className={classes.Title}>{title}</div>}
          <div className={classes.Content}>{content}</div>
        </div>
      </ModalLayout>
    </Dialog>
  );
};

AppConfirmationComponent.defaultProps = {
  approveBtn: TransKeys.GENERAL.ACTIONS.YES,
  rejectBtn: TransKeys.GENERAL.ACTIONS.CANCEL,
};

const AppConfirmation = composition<OwnProps>(AppConfirmationComponent);

export default AppConfirmation;

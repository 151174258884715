import {TextInput, Select} from 'ui-components';
import {SelectorModelType} from '../../smart-selector/advanced-smart-selector.component';
import {TemplateItemQueryBuilder} from '../template-item-query-builder/template-item-query-builder.component';
import classes from './behavioral-churn-query-builder.module.scss';
import {TableEntity, TableEntityBinding} from '../../../../../objects/models/table.model';
import {SignalDataType, SignalType} from '../../../../../objects/models/signal.model';
import {TIME_UNIT_PLURAL_OPTIONS} from '../../../../../constants/options';
import {useMemo} from 'react';
import classNames from 'classnames';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';
import {SignalSmartSelectorKey} from '../../smart-selector/signal-smart-selector.component';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';

interface SchemaKeysMapping {
  signal_id: string;
  units: string;
  n_units: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  signal_id: 'signal_id',
  units: 'units',
  n_units: 'n_units',
};

export interface BaseBehavioralChurnQueryBuilderOwnProps {
  query: any;
  onChange?: (query: any) => void;
  schemaKeysMapping?: SchemaKeysMapping;
  onSignalInfo: (value: string | number) => void;
  entityContext: TableEntity;
  signalFilters?: any;
  errors?: any;
  disabled?: boolean;
  className?: string;
}

type AllProps = BaseBehavioralChurnQueryBuilderOwnProps;

const createSignalFilters = (entityContext = undefined) => ({
  type: SignalType.MEASURE,
  data_type: [SignalDataType.BOOLEAN],
  entity_binding: TableEntityBinding.TWO_WAY,
  entityContext,
});
const createEventFilters = (entityContext = undefined) => ({
  entity_binding: TableEntityBinding.TWO_WAY,
  entityContext,
});

const INCLUDE_ACTIONS = [
  SignalSmartSelectorKey.FUNNELS,
  SignalSmartSelectorKey.FEATURES,
  SignalSmartSelectorKey.MILESTONES,
  SignalSmartSelectorKey.SIGNALS,
];

export const BaseBehavioralChurnBuilder: React.FC<AllProps> = (props: AllProps) => {
  const {
    errors,
    onChange,
    schemaKeysMapping,
    onSignalInfo,
    query,
    entityContext,
    signalFilters: signalFiltersFromProps,
    disabled,
    className,
  } = props;
  const {t} = useTranslation();
  const {getEntityName} = useProductData();
  const signalFilters = useMemo(
    () => signalFiltersFromProps || createSignalFilters(entityContext),
    [signalFiltersFromProps, entityContext]
  );
  const eventFilters = useMemo(() => createEventFilters(entityContext), [entityContext]);

  return (
    <div className={classNames(classes.BehavioralChurnBuilder, className)}>
      <div className={classes.Row}>
        <div className={classes.Item}>
          {t(TransKeys.QUERY_BUILDERS.BEHAVIORAL_CHURN_QUERY_BUILDER.CHURNED_SIGNAL_LABEL, {
            entityName: getEntityName(entityContext, false, true),
          })}
        </div>
        <div className={classes.Item}>
          <TemplateItemQueryBuilder
            onChange={v => onChange({[schemaKeysMapping.signal_id]: v})}
            query={query[schemaKeysMapping.signal_id]}
            errors={errors?.[schemaKeysMapping.signal_id]}
            signalFilters={signalFilters}
            signalsInclude={INCLUDE_ACTIONS}
            allowTypes={[SelectorModelType.SIGNAL, SelectorModelType.EVENT]}
            eventFilters={eventFilters}
            disabled={disabled}
            onSignalInfo={onSignalInfo}
          />
        </div>
      </div>
      <div className={classes.Row}>
        <div className={classes.Item}>
          {t(TransKeys.QUERY_BUILDERS.BEHAVIORAL_CHURN_QUERY_BUILDER.TIMEFRAME_LABEL)}
        </div>
        <div className={classes.Item}>
          <TextInput
            className={classes.Input}
            value={query[schemaKeysMapping.n_units]}
            onChange={v => onChange({[schemaKeysMapping.n_units]: v})}
            error={Boolean(errors?.[schemaKeysMapping.n_units])}
            type={'number'}
            fullWidth={false}
            disabled={disabled}
          />
        </div>
        <div className={classes.Item}>
          <Select
            value={query[schemaKeysMapping.units]}
            onChange={v => onChange({[schemaKeysMapping.units]: v})}
            error={Boolean(errors?.[schemaKeysMapping.units])}
            options={TIME_UNIT_PLURAL_OPTIONS}
            searchable={false}
            clearable={false}
            capitalize={false}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

BaseBehavioralChurnBuilder.defaultProps = {
  schemaKeysMapping: DEFAULT_SCHEMA_KEYS_MAPPING,
};

import {AnalysisTypeId} from '../../../../../../constants/analysis-type-id';
import {exists} from 'front-core';
import {QueryBuilderFactory} from 'ui-components';
import {isArray, isEmpty, isNumber} from 'lodash';
import {ExperimentScopeMode} from '../../../../analysis-forms/components/ui-selectors/experiment-scope-selector/experiment-scope-selector.component';

const transformToSignalColumn = (param: any) => {
  if (isArray(param)) {
    return param.map(a => (isNumber(a) ? QueryBuilderFactory.createSignalColumn(a) : a));
  }
  return isNumber(param) ? QueryBuilderFactory.createSignalColumn(param) : param;
};

// This function is used for backward compatibility when analysis parameters was changed
export const refactorAnalysisParameters = (analysisTypeId: number, parameters: any): any => {
  if (!exists(parameters) || isEmpty(parameters)) {
    return parameters;
  }

  if (analysisTypeId === AnalysisTypeId.KPI_ANALYSIS && exists(parameters['goal_query'])) {
    delete parameters['goal_query'];
  }

  if (analysisTypeId === AnalysisTypeId.GOAL_DRIVERS) {
    if (exists(parameters['goal_query'])) {
      return parameters;
    }
    const {model_version} = parameters;

    if (model_version === 'conversion') {
      const {goal, ref_date, goal_bound_unit, goal_bound_n} = parameters;
      // create query
      const q = QueryBuilderFactory.createTemplate();
      q.template = 'bounded_action_ts';
      q.parameters[0] = QueryBuilderFactory.createSignalColumn(goal);
      q.parameters[1] = 0;
      q.parameters[2] = goal_bound_n;
      q.parameters[3] = goal_bound_unit;
      q.parameters[4] = QueryBuilderFactory.createSignalColumn(ref_date);
      parameters['goal_query'] = q;
      // delete keys
      delete parameters['goal'];
      delete parameters['ref_date'];
      delete parameters['goal_bound_unit'];
      delete parameters['goal_bound_n'];
    }
    if (model_version === 'retention') {
      const {actions, ref_date, goal_bound_unit, goal_bound_n} = parameters;
      // create query
      const q = QueryBuilderFactory.createTemplate();
      q.template = 'bounded_actions_ts';
      q.parameters[0] = actions.map(a => QueryBuilderFactory.createSignalColumn(a));
      q.parameters[1] = goal_bound_n - 1;
      q.parameters[2] = goal_bound_n;
      q.parameters[3] = goal_bound_unit;
      q.parameters[4] = QueryBuilderFactory.createSignalColumn(ref_date);
      parameters['goal_query'] = q;
      // delete keys
      delete parameters['actions'];
      delete parameters['ref_date'];
      delete parameters['goal_bound_unit'];
      delete parameters['goal_bound_n'];
    }
    if (model_version === 'payment_retention') {
      const {plan, goal_bound_unit, goal_bound_n} = parameters;
      // create query
      const query = QueryBuilderFactory.createTemplate();
      query.template = 'subscription_retention';
      query.parameters[0] = goal_bound_unit;
      query.parameters[1] = goal_bound_n;
      query.parameters[2] = plan;
      // delete keys
      delete parameters['plan'];
      delete parameters['goal_bound_unit'];
      delete parameters['goal_bound_n'];
    }
  }

  if (analysisTypeId === AnalysisTypeId.CORRELATION_DRIVERS) {
    if (exists(parameters['goal_query'])) {
      return parameters;
    }
    const {goal, reference_date, goal_bound_unit, goal_bound_n} = parameters;
    // create query
    const q = QueryBuilderFactory.createTemplate();
    q.template = 'bounded_action_ts';
    q.parameters[0] = QueryBuilderFactory.createSignalColumn(goal);
    q.parameters[1] = 0;
    q.parameters[2] = goal_bound_n;
    q.parameters[3] = goal_bound_unit;
    q.parameters[4] = QueryBuilderFactory.createSignalColumn(reference_date);
    parameters['goal_query'] = q;
    // delete keys
    delete parameters['goal'];
    delete parameters['ref_date'];
    delete parameters['goal_bound_unit'];
    delete parameters['goal_bound_n'];
  }

  if (analysisTypeId === AnalysisTypeId.RETENTION_COHORTS) {
    const {actions, ref_date} = parameters;
    parameters['actions'] = transformToSignalColumn(actions);
    parameters['ref_date'] = transformToSignalColumn(ref_date);
  }

  if (analysisTypeId === AnalysisTypeId.HABIT_MOMENTS) {
    if (!exists(parameters['goal_query'])) {
      return parameters;
    }
    const q = parameters['goal_query'];
    const template = q['template'];
    if (template === 'bounded_action_ts' || template === 'bounded_actions_ts') {
      q.parameters[0] = transformToSignalColumn(q.parameters[0]);
      q.parameters[4] = transformToSignalColumn(q.parameters[4]);
    }
  }

  if (analysisTypeId === AnalysisTypeId.USER_JOURNEY) {
    if (exists(parameters['reference_date'])) {
      delete parameters['reference_date'];
      delete parameters['goal'];
    }
  }

  if (analysisTypeId === AnalysisTypeId.A_B_TEST) {
    const {assignment_mode, start_event_query} = parameters;
    if (!exists(assignment_mode)) {
      parameters['assignment_mode'] = exists(start_event_query)
        ? ExperimentScopeMode.EVENTS
        : ExperimentScopeMode.NEW_USERS;
    }
  }

  return parameters;
};

import {Epic} from 'redux-observable';
import {createRequestEpic} from 'front-core';
import {ActionKey} from '../../constants/action-key';
import HttpClient from '../../services/http-client.service';
import {EventGroupActionType} from './event-groups.actions';
import {modelCreated, modelDeleted, modelUpdated, notifyEvent} from '../core/core.actions';
import {ModelKey} from '../../constants/model-key';
import {eventGroupToastCreator} from '../toasts.actions';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {showToastMessage} from '../interface/interface.actions';
import {ToastType} from '../../objects/system/toast-type.enum';
import {
  demoProductLimitedActionsFilter,
  errorExtractor,
  getDMPToastErrorMessage,
} from '../store.utils';
import {
  createEventGroupNetworkRequest,
  deleteEventGroupNetworkRequest,
  editEventGroupNetworkRequest,
  validateEventGroupNetworkRequest,
} from '../../http/event-groups.network-requests';

export const createEventGroupEpic: Epic = createRequestEpic(
  {
    types: [EventGroupActionType.CREATE_EVENT_GROUP],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.CREATE_EVENT_GROUP,
    request: createEventGroupNetworkRequest,
    onSuccess: eventGroup => [
      notifyEvent(AmplitudeEvent.EVENT_GROUP_CREATED, {id: eventGroup.id}),
      eventGroupToastCreator('CREATE_SUCCESS'),
      modelCreated(eventGroup, ModelKey.EVENT_GROUP),
    ],
    onError: err_ => {
      const err = errorExtractor(err_);
      const toastMessage = getDMPToastErrorMessage(err, 'event_group');
      if (toastMessage) {
        return [
          showToastMessage(toastMessage, ToastType.ERROR),
          notifyEvent(AmplitudeEvent.CREATE_EVENT_GROUP_ERROR, err),
        ];
      }
      return [eventGroupToastCreator('CREATE_ERROR')];
    },
  },
  HttpClient
);

export const updateEventGroupEpic: Epic = createRequestEpic(
  {
    types: [EventGroupActionType.UPDATE_EVENT_GROUP],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.UPDATE_EVENT_GROUP,
    request: editEventGroupNetworkRequest,
    onSuccess: eventGroup => [
      notifyEvent(AmplitudeEvent.EVENT_GROUP_UPDATED, {id: eventGroup.id}),
      eventGroupToastCreator('UPDATE_SUCCESS'),
      modelUpdated(eventGroup, ModelKey.EVENT_GROUP),
    ],
    onError: err_ => {
      const err = errorExtractor(err_);
      const toastMessage = getDMPToastErrorMessage(err, 'event_group');
      if (toastMessage) {
        return [
          showToastMessage(toastMessage, ToastType.ERROR),
          notifyEvent(AmplitudeEvent.UPDATE_EVENT_GROUP_ERROR, err),
        ];
      }
      return [eventGroupToastCreator('CREATE_ERROR')];
    },
  },
  HttpClient
);

export const deleteEventGroupEpic: Epic = createRequestEpic(
  {
    types: [EventGroupActionType.DELETE_EVENT_GROUP],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.DELETE_EVENT_GROUP,
    request: deleteEventGroupNetworkRequest,
    onSuccess: (res, payload) => [
      notifyEvent(AmplitudeEvent.EVENT_GROUP_DELETED, {id: payload}),
      eventGroupToastCreator('DELETE_SUCCESS'),
      modelDeleted(payload, ModelKey.EVENT_GROUP),
    ],
    onError: err => [eventGroupToastCreator('DELETE_ERROR')],
  },
  HttpClient
);

export const validateEventGroupEpic: Epic = createRequestEpic(
  {
    types: [EventGroupActionType.VALIDATE_EVENT_GROUP],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.VALIDATE_EVENT_GROUP,
    request: validateEventGroupNetworkRequest,
    onSuccess: eventGroup => [
      notifyEvent(AmplitudeEvent.EVENT_GROUP_VALIDATED, {id: eventGroup.id}),
      eventGroupToastCreator('UPDATE_SUCCESS'),
      modelUpdated(eventGroup, ModelKey.EVENT_GROUP),
    ],
    onError: err => [eventGroupToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);

import * as React from 'react';
import classNames from 'classnames';
import classes from './analysis-forms.module.scss';
import {
  ParametersFormProps,
  DatePickerInput,
  LabelWrapper,
  QueryBuilder,
  Checkbox,
  ParameterSchema,
  ParameterType,
  Select,
  TextInput,
} from 'ui-components';
import {ParameterInputWrapper} from '../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {useCallback, useContext, useEffect, useMemo} from 'react';
import {get, isArray, isEmpty, isObject, keys} from 'lodash';
import {exists} from 'front-core';
import {AddIcon, ArrowDownIcon, ArrowUpIcon, CloseIcon, IconButton} from 'ui-components';
import {SignalSmartSelector} from '../../shared/core/smart-selector/signal-smart-selector.component';
import {TimeFrameSelector} from './components/ui-selectors/time-frame-selector/time-frame-selector.component';
import {ParametersFormContext} from '../../shared/core/parameters-form/parameters-form.context';
import {startEndDatesDefaultHandler} from '../../shared/core/parameters-form/parameters-form-default-handlers.utils';
import {SegmentFilterSelector} from './components/ui-selectors/segment-filter-selector/segment-filter-selector.component';

interface OwnProps extends ParametersFormProps {
  onSignalInfo?: (value: string | number) => void;
}

type AllProps = OwnProps;

const isRangePickerParameter = (k: string) => k === 'start_date' || k === 'end_date';

export const GenericParametersForm: React.FC<AllProps> = (props: AllProps) => {
  const {advancedOptions, allowFutureDates, requestPayload, onSignalInfo, className} = props;
  const {
    errors,
    parameters: data,
    schema,
    schemaConfig,
    changeParametersValue,
    registerDefaultHandler,
    removeDefaultHandler,
    disabled,
  } = useContext(ParametersFormContext);

  useEffect(() => {
    registerDefaultHandler('start_end_dates', startEndDatesDefaultHandler);
    return () => {
      removeDefaultHandler('start_end_dates');
    };
  }, [registerDefaultHandler, removeDefaultHandler]);

  const maxDate = useMemo(() => new Date(), []);
  const showRangePicker = useMemo(
    () => keys(schema).filter(isRangePickerParameter).length > 0,
    [schema]
  );
  const displayed = useMemo(() => keys(schema).filter(k => !isRangePickerParameter(k)), [schema]);
  const requiredKeys = useMemo(
    () => displayed.filter(k => schema[k].required),
    [displayed, schema]
  );
  const optionalKeys = useMemo(
    () => displayed.filter(k => !schema[k].required),
    [displayed, schema]
  );

  const onParameterChange = useCallback(
    (key: string, value: any, index?: number) => {
      let newParameters;
      const newValue = exists(value) ? value : null;
      if (index !== undefined) {
        newParameters = {
          ...data,
          [key]: [...(data[key] || [])],
        };
        newParameters[key][index] = newValue;
      } else {
        newParameters = {...data};
        newParameters[key] = newValue;
      }
      changeParametersValue(newParameters);
    },
    [data, changeParametersValue]
  );
  const removeIndex = useCallback(
    (key: string, index: number) => {
      const arr = [...data[key]];
      arr.splice(index, 1);
      onParameterChange(key, arr);
    },
    [data, onParameterChange]
  );
  const addIndex = useCallback(
    key => {
      const parameterValue = data[key];
      onParameterChange(key, null, parameterValue?.length || 0);
    },
    [onParameterChange, data]
  );
  const switchIndex = useCallback(
    (key: string, index: number, newIndex: number) => {
      const arr = [...data[key]];
      arr[index] = arr.splice(newIndex, 1, arr[index])[0];
      onParameterChange(key, arr);
    },
    [data, onParameterChange]
  );

  const getParameterFilters = (p: ParameterSchema) => {
    if (!p?.filters) {
      return {};
    }
    if (isArray(p.filters) && p.filters.length) {
      return {orFilterEntries: p.filters};
    } else if (isObject(p.filters) && !isEmpty(p.filters)) {
      return {orFilterEntries: [p.filters]};
    }
    return {};
  };

  const renderInput = (key, p: ParameterSchema, index?: number) => {
    const error = get(errors, `${key}.message`);
    let value = exists(data[key]) ? data[key] : null;
    const parameterSchemaConfig = schemaConfig ? schemaConfig[p.type] : undefined;

    if (index !== undefined && isArray(value)) {
      value = value[index];
    }

    let networkRequest;
    let render = null;
    switch (p.type) {
      case ParameterType.NUMBER:
      case ParameterType.STRING:
        render = (
          <TextInput
            value={value}
            onChange={value => onParameterChange(key, value, index)}
            disabled={disabled}
            type={p.type as any}
            placeholder={'Enter Value'}
            error={Boolean(error)}
            unique={false}
          />
        );
        break;
      case ParameterType.SELECT:
        const parameters = {
          error: Boolean(error),
          placeholder: 'Select',
          value: value,
          onChange: value => onParameterChange(key, value, index),
          options: {
            options: p.options
              ? p.options.map(p => ({
                  value: p,
                  label: p,
                }))
              : [],
          },
          searchable: false,
          disabled: disabled,
        };
        // render = p.list ? <MultiSelect {...parameters} /> : <Select {...parameters} />;
        render = <Select {...parameters} />;
        break;
      case ParameterType.DATE:
        render = (
          <DatePickerInput
            error={Boolean(error)}
            placeholder={'Select Date'}
            value={value}
            onChange={value => onParameterChange(key, value, index)}
            dateFormat={'DD/MM/YYYY'}
            dateInputFormat={'YYYY-MM-DD'}
            disabled={disabled}
            maxDate={allowFutureDates ? maxDate : undefined}
            utc
          />
        );
        break;
      case ParameterType.BOOLEAN:
        render = (
          <div className={classNames(classes.BooleanSelector, disabled && classes.Disabled)}>
            <Checkbox
              checked={value === true}
              className={classes.Checkbox}
              disabled={disabled}
              onChange={e => onParameterChange(key, true, index)}
            />
            <div
              className={classes.BooleanLabel}
              onClick={e => !disabled && onParameterChange(key, true, index)}
            >
              Yes
            </div>
            <Checkbox
              checked={value === false}
              className={classes.Checkbox}
              disabled={disabled}
              onChange={e => onParameterChange(key, false, index)}
            />
            <div
              className={classes.BooleanLabel}
              onClick={e => !disabled && onParameterChange(key, false, index)}
            >
              No
            </div>
          </div>
        );
        break;
      case ParameterType.QUERY_BUILDER:
        render = (
          <QueryBuilder
            onChange={value => onParameterChange(key, value, index)}
            query={value}
            errors={errors ? errors[key] : {}}
            disabled={disabled}
            placeholder={p.placeholder}
          />
        );
        break;
      case ParameterType.SIGNAL:
        render = (
          <SignalSmartSelector
            placeholder={'Select'}
            onChange={value => onParameterChange(key, value, index)}
            value={value}
            error={Boolean(error)}
            filters={schema[key]?.filters}
            onSignalInfo={onSignalInfo}
          />
        );
        break;
      default:
        networkRequest = parameterSchemaConfig?.networkRequest;
        break;
    }

    if (networkRequest) {
      const actions = advancedOptions ? advancedOptions(p, value, index) : undefined;

      const sharedAttributes = {
        error: Boolean(error),
        placeholder: 'Search',
        value,
        disabled,
        onChange: value => onParameterChange(key, value, index),
        options: {
          ...parameterSchemaConfig,
          requestPayload: {
            ...(requestPayload || {}),
            ...(parameterSchemaConfig.requestPayload || {}),
            ...getParameterFilters(p),
          },
        },
      };
      render = <Select actions={actions} {...sharedAttributes} />;
    }

    return render;
  };
  const renderParameter = (key: string) => {
    const parameter = schema[key];
    if (!parameter.collection && !parameter.list) {
      return renderInput(key, parameter);
    }
    const value = exists(data[key]) ? data[key] : [];

    return (
      <div className={classes.Collection}>
        {value.map((_, idx) => (
          <div key={idx} className={classes.CollectionItem}>
            <div className={classes.Index}>{idx + 1}.</div>
            <div className={classes.Input}>{renderInput(key, parameter, idx)}</div>
            <div className={classes.Actions}>
              {idx < value.length - 1 && (
                <IconButton
                  className={classes.Action}
                  icon={ArrowDownIcon}
                  onClick={_ => switchIndex(key, idx, idx + 1)}
                />
              )}
              {idx > 0 && (
                <IconButton
                  className={classes.Action}
                  icon={ArrowUpIcon}
                  onClick={_ => switchIndex(key, idx, idx - 1)}
                />
              )}
              <IconButton
                className={classes.Action}
                onClick={_ => removeIndex(key, idx)}
                icon={CloseIcon}
              />
            </div>
          </div>
        ))}
        <div className={classes.CollectionItem}>
          <IconButton className={classes.Action} icon={AddIcon} onClick={_ => addIndex(key)} />
        </div>
      </div>
    );
  };

  const renderParameterWrapper = (key: string) => {
    if (key === 'population_filter') {
      return (
        <SegmentFilterSelector
          title={'Segment filter (Optional)'}
          subTitle={'Filter this analysis for a certain group of users.'}
          onChange={changeParametersValue}
          value={data}
          className={classes.Parameter}
          errors={errors}
        />
      );
    }
    const error = get(errors, `${key}.message`);
    const parameter = schema[key];

    return (
      <ParameterInputWrapper
        key={key}
        title={`${parameter.name}${!parameter.required ? ' (optional)' : ''}`}
        subTitle={parameter.description}
        className={classes.Parameter}
        error={Boolean(error)}
      >
        <LabelWrapper
          required={parameter.required}
          label={parameter.name}
          error={Boolean(error)}
          helperText={error}
          className={classes.FitContent}
          fullWidth={false}
        >
          {renderParameter(key)}
        </LabelWrapper>
      </ParameterInputWrapper>
    );
  };
  const renderDatePicker = () => {
    if ('start_date' in schema && 'end_date' in schema) {
      return (
        <TimeFrameSelector
          key={'time_frame'}
          className={classes.Parameter}
          onChange={changeParametersValue}
          value={data}
          errors={errors}
          title={'Time frame'}
          subTitle={'Set the time frame for this analysis.'}
        />
      );
    }
    return (
      <ParameterInputWrapper
        key={'time_frame'}
        title={'Time frame'}
        subTitle={'Set the time frame for this analysis.'}
        className={classes.Parameter}
        error={Boolean(errors['start_date']) || Boolean(errors['end_date'])}
      >
        <div className={classes.Inputs}>
          {'start_date' in schema && (
            <LabelWrapper
              required
              label={'Start Date'}
              error={Boolean(errors['start_date'])}
              helperText={errors['start_date']?.message}
              className={classes.FitContent}
            >
              <DatePickerInput
                placeholder={'Select Date'}
                value={data['start_date']}
                onChange={value => onParameterChange('start_date', value)}
                dateFormat={'DD/MM/YYYY'}
                dateInputFormat={'YYYY-MM-DD'}
                maxDate={maxDate}
                error={Boolean(errors['start_date'])}
                utc
              />
            </LabelWrapper>
          )}
          {'end_date' in schema && (
            <LabelWrapper
              required
              label={'End Date'}
              error={Boolean(errors['end_date'])}
              helperText={errors['end_date']?.message}
              className={classes.FitContent}
            >
              <DatePickerInput
                placeholder={'Select Date'}
                value={data['end_date']}
                onChange={value => onParameterChange('end_date', value)}
                dateFormat={'DD/MM/YYYY'}
                dateInputFormat={'YYYY-MM-DD'}
                maxDate={maxDate}
                error={Boolean(errors['end_date'])}
                utc
              />
            </LabelWrapper>
          )}
        </div>
      </ParameterInputWrapper>
    );
  };

  return (
    <div className={classNames(classes.AnalysisForm, className)}>
      {requiredKeys.map(k => renderParameterWrapper(k))}
      {showRangePicker && renderDatePicker()}
      {optionalKeys.map(k => renderParameterWrapper(k))}
    </div>
  );
};

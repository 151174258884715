import * as React from 'react';
import classes from './analysis-results-panel.module.scss';
import {
  ANALYSIS_ID_PATH_PARAM,
  ANALYSIS_RESULT_ID_PATH_PARAM,
  AppRoutes,
} from '../../../../constants/app-routes';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {SharedListKeys} from '../../../../constants/shared-list-keys';
import {
  getListsSelector,
  getReducedLoadingStateSelector,
  getSingleSelectedSelector,
} from '../../../../store/store.selectors';
import {useEffect, useMemo} from 'react';
import {
  appendList,
  createList,
  removeList,
  replaceList,
} from '../../../../store/remote-lists/remote-list.actions';
import {getAnalysisResultsNetworkRequest} from '../../../../http/analysis-results.network-requests';
import {composition} from 'front-core';
import {withPanel} from '../../../../core/hoc/with-panel.hoc';
import {AnalysisResultCard} from '../../components/analysis-result-card/analysis-result-card.component';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {Link} from 'ui-components';
import TransKeys from '../../../../constants/translation-keys';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {NumberParam, useQueryParam} from 'use-query-params';
import {useHistory} from 'react-router';

interface OwnProps {
  [ANALYSIS_ID_PATH_PARAM]: number;
}

type AllProps = OwnProps;

const RESULTS_LIST_KEY = SharedListKeys.VIEW_ANALYSIS__ANALYSIS_RESULTS;
const SELECTED_ANALYSIS_RESULT_KEY = SharedSelectionKeys.VIEW_ANALYSIS__RESULT;

export const AnalysisResultsPanelComponent: React.FC<AllProps> = (props: AllProps) => {
  const {[ANALYSIS_ID_PATH_PARAM]: analysisId} = props;
  const [analysisResultId] = useQueryParam(ANALYSIS_RESULT_ID_PATH_PARAM, NumberParam);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const listsData = useSelector(state => getListsSelector(RESULTS_LIST_KEY)(state));
  const results = useMemo(() => listsData[RESULTS_LIST_KEY]?.list || [], [listsData]);
  const meta = useMemo(() => listsData[RESULTS_LIST_KEY]?.meta || [], [listsData]);
  const selectedAnalysisResult = useSelector(state =>
    getSingleSelectedSelector(SELECTED_ANALYSIS_RESULT_KEY, state)
  );
  // const changeAnalysisResult = (id) => analysisResultId !== id && setAnalysisResultId(id, 'replaceIn');
  const changeAnalysisResult = id =>
    analysisResultId !== id && history.push(AppRoutes.viewAnalysis(analysisId, {resultId: id}));
  const onLoadMore = () =>
    meta?.hasNext && dispatch(appendList(RESULTS_LIST_KEY, {page: meta?.page + 1}, 'append'));
  const isLoading = useSelector(state => getReducedLoadingStateSelector(RESULTS_LIST_KEY)(state));
  // Effects
  useEffect(() => {
    dispatch(
      createList({
        listKey: RESULTS_LIST_KEY,
        actionKey: RESULTS_LIST_KEY,
        request: getAnalysisResultsNetworkRequest,
      })
    );
    // clean up
    return () => {
      dispatch(removeList(RESULTS_LIST_KEY));
    };
  }, [dispatch]);

  useEffect(() => {
    analysisId &&
      dispatch(
        replaceList(RESULTS_LIST_KEY, {
          analysisId,
          orderBy: ['created_on'],
          order: ['desc'],
        } as any)
      );
  }, [analysisId, dispatch]);

  return (
    <div className={classes.AnalysisResultsPanel}>
      <div className={classes.List}>
        {results?.map(r => (
          <div className={classes.AnalysisResultCardWrapper} key={r.id}>
            <AnalysisResultCard
              className={classes.AnalysisResultCard}
              key={r.id}
              onClick={() => changeAnalysisResult(r.id)}
              analysisResult={r}
              selected={r.id === selectedAnalysisResult?.id}
            />
          </div>
        ))}
        {meta.hasNext && (
          <div className={classes.LoadMore}>
            <Link disabled={isLoading} onClick={onLoadMore}>
              {t(TransKeys.GENERAL.ACTIONS.LOAD_MORE)}
            </Link>
          </div>
        )}
        {isLoading && <GenericLoading />}
      </div>
    </div>
  );
};

const AnalysisResultsPanel = composition<OwnProps>(AnalysisResultsPanelComponent, withPanel);
export default AnalysisResultsPanel;

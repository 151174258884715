import {SignalDataType, ValidationStatus} from './signal.model';

export enum ExperimentAutomationType {
  RELEASE = 'release',
  AB_TEST = 'ab_test',
}

export interface ExperimentAutomation {
  id: number;
  name: string;
  type: ExperimentAutomationType;
  keySignalId: number;
  threshold: number;
  keySignalDefinition: any;
  keySignalDataType: SignalDataType;
  keySignalValidationStatus: ValidationStatus;
  variantSignalId: number;
  variantSignalDefinition: any;
  variantSignalDataType: SignalDataType;
  variantSignalValidationStatus: ValidationStatus;
  populationFilter: any;
  defaultGoalId: number;
  defaultSecondaryGoalIds: number[];
  primaryGoal: {
    id: number;
    name: string;
  };
  createdOn: string;
  updatedOn: string;
}

import {Feedback, FeedbackModelType} from '../models/feedback.model';
import yup from '../../config/yup.config';
import {values} from 'lodash';

export type FeedbackDTO = Partial<Feedback>;

export const feedbackDTOValidator = yup.object().shape({
  modelType: yup.string().oneOf(values(FeedbackModelType)).required(),
  modelId: yup.number().required(),
  key: yup.string().required(),
  isPositive: yup.boolean().required(),
  description: yup.string().nullable(),
  additionalData: yup.object().nullable(),
});

import * as React from 'react';
import {Tooltip} from '@material-ui/core';
import {TriangleExclamationLightIcon} from 'ui-components';
import classes from './invalid-signal-warning.module.scss';
import {useDemoProduct} from '../../../../core/hooks/use-demo-product.hook';

interface OwnProps {
  title: string;
}

type AllProps = OwnProps;

const InvalidSignalWarning: React.FC<AllProps> = (props: AllProps) => {
  const {title} = props;
  const {isDemoProduct} = useDemoProduct();

  if (isDemoProduct) {
    return null;
  }

  return (
    <Tooltip title={title} placement={'top'} interactive={false}>
      <TriangleExclamationLightIcon className={classes.Warning} />
    </Tooltip>
  );
};

export default InvalidSignalWarning;

import {values} from 'lodash';
import {
  UsefulResourceModel,
  NotUsefulReasonsOptions,
  UsefulResourceType,
} from '../models/useful.model';
import yup from '../../config/yup.config';

export interface UsefulDTO extends UsefulResourceModel {}

export const usefulResourceDTOValidator = yup.object().shape({
  type: yup.string().oneOf(values(UsefulResourceType)).required(),
  reason: yup.string().oneOf(values(NotUsefulReasonsOptions)).required(),
  status: yup.boolean().nullable(),
  more: yup.string(),
});

import * as React from 'react';
import classes from './sample-classes-column.module.scss';
import {SampleClass} from '../../../../objects/models/sample-classes-model.model';
import moment from 'moment/moment';
import {useMemo} from 'react';
import {SystemText} from '../../../shared/components/general/system-text/system-text.component';
import TransKeys from '../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {take} from 'lodash';
import {CounterChip} from 'ui-components';
import {withStopPropagation} from '../../../../utils/general.utils';
import classNames from 'classnames';

interface OwnProps {
  sampleClasses: SampleClass[];
  count: number;
  onMoreClick: () => void;
  entityCreatedOn: string;
  className?: string;
}

type AllProps = OwnProps;

const MAX_CLASSES_TO_RENDER = 4;

const SampleClassesColumn: React.FC<AllProps> = (props: AllProps) => {
  const {sampleClasses, count, onMoreClick, entityCreatedOn, className} = props;
  const {t} = useTranslation();
  const isJustCreated = useMemo(
    () => moment.utc(entityCreatedOn).add(1, 'hour').isAfter(moment.utc()),
    [entityCreatedOn]
  );

  if (isJustCreated && sampleClasses.length === 0) {
    return <SystemText>{t(TransKeys.GENERAL.LABELS.WORKING_ON_IT)}</SystemText>;
  }

  return (
    <div className={classNames(classes.SampleClassesColumn, className)}>
      {take(sampleClasses, MAX_CLASSES_TO_RENDER).map((sampleClass, index) => (
        <div className={classes.ChipWrapper} key={sampleClass.value}>
          <CounterChip
            className={classes.Chip}
            label={sampleClass.name}
            count={sampleClass.count}
          />
        </div>
      ))}
      {count > MAX_CLASSES_TO_RENDER && (
        <span className={classes.MoreButton} onClick={withStopPropagation(() => onMoreClick())}>
          {t(TransKeys.GENERAL.LABELS.X_MORE_DOTS, {
            count: count - MAX_CLASSES_TO_RENDER,
          })}
        </span>
      )}
    </div>
  );
};

export default SampleClassesColumn;

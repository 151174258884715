import * as React from 'react';
import {useMemo} from 'react';
import classNames from 'classnames';
import classes from './analysis-result-status-display.module.scss';
import {
  AnalysisResult,
  AnalysisResultStatus,
} from '../../../../objects/models/analysis-result.model';
import {
  BanRegularIcon,
  HourGlassHalfRegularIcon,
  TriangleExclamationSolidIcon,
} from 'ui-components';
import {get} from 'lodash';
import TransKeys from '../../../../constants/translation-keys';
import {
  getAnalysisStepProgress,
  isAnalysisResultProcessing,
} from '../../../../utils/analysis-result.utils';
import {useTranslation} from 'react-i18next';
import {useIsAdmin} from '../../../../core/hooks/use-is-admin.hook';
import {useAnalysisRuntime} from '../../hooks/use-analysis-runtime.hook';
import {Box, CircularProgress, makeStyles, Tooltip} from '@material-ui/core';
import {exists} from 'front-core';

interface OwnProps {
  analysisResult: AnalysisResult;
  className?: string;
}

type AllProps = OwnProps;

const CompletedIcon = props => (
  <div {...props} className={classNames(classes.Circle, props.className)} />
);

const RunningIcon = props => {
  const classes = makeStyles(() => ({
    bottom: {
      color: 'rgba(49, 77, 146, 0.2)',
      borderRadius: '50%',
      position: 'absolute',
      left: 0,
      top: 1,
    },
    top: {
      animationDuration: '550ms',
      position: 'absolute',
      color: 'rgba(49, 77, 146, 1)',
      left: 0,
    },
    circle: {
      strokeLinecap: 'round',
    },
  }))();

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={16}
        thickness={2.5}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        disableShrink
        size={18}
        thickness={7.5}
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        {...props}
      />
    </Box>
  );
};
export const AnalysisResultStatusDisplay: React.FC<AllProps> = (props: AllProps) => {
  const {analysisResult, className} = props;
  const {t} = useTranslation();
  const isAdmin = useIsAdmin();
  const {getEstimatedRuntimeFormatted} = useAnalysisRuntime();

  const info = useMemo(() => {
    let helperText = '';
    let icon = undefined;
    let iconProps = {};
    let status = analysisResult.status || '';
    let type;

    let errorText = get(
      analysisResult,
      'debugInfo.error.message',
      analysisResult.status === AnalysisResultStatus.FAILED
        ? t(TransKeys.ANALYSIS_ERRORS.ERROR_1000.SUB_TITLE)
        : ''
    );
    if (get(analysisResult, 'debugInfo.error.code') === 1000) {
      errorText = t(TransKeys.ANALYSIS_ERRORS.ERROR_1000.SUB_TITLE);
    }

    if (isAnalysisResultProcessing(analysisResult, !isAdmin)) {
      helperText = getEstimatedRuntimeFormatted(analysisResult.analysisTypeId);
      const analysisStepProgress = getAnalysisStepProgress(analysisResult);
      if (analysisStepProgress > 0) {
        icon = RunningIcon;
        iconProps = {
          value: Math.min(analysisStepProgress * 100, 100),
        };
      } else {
        icon = HourGlassHalfRegularIcon;
      }
      status = AnalysisResultStatus.RUNNING;
      type = 'default';
    } else if (status === AnalysisResultStatus.FAILED) {
      icon = TriangleExclamationSolidIcon;
      helperText = errorText;
      type = 'error';
    } else if (status === AnalysisResultStatus.COMPLETED && errorText) {
      helperText = errorText;
      icon = TriangleExclamationSolidIcon;
      type = 'default';
    } else if (status === AnalysisResultStatus.COMPLETED) {
      icon = CompletedIcon;
      type = 'success';
    } else if (status === AnalysisResultStatus.STOPPED) {
      icon = BanRegularIcon;
      type = 'default';
    }

    return {
      helperText,
      icon,
      iconProps,
      status,
      type,
      hasError: exists(errorText),
    };
  }, [analysisResult, isAdmin, getEstimatedRuntimeFormatted, t]);

  const tooltipContent = useMemo(() => {
    let title = '';
    if (info.status === AnalysisResultStatus.RUNNING) {
      title = t(TransKeys.GENERAL.LABELS.ANALYSIS_STATUS.RUNNING);
    } else if (info.status === AnalysisResultStatus.COMPLETED && info.hasError) {
      title = t(TransKeys.GENERAL.LABELS.ANALYSIS_STATUS.COMPLETED_WITH_ERROR);
    } else if (info.status === AnalysisResultStatus.COMPLETED) {
      title = t(TransKeys.GENERAL.LABELS.ANALYSIS_STATUS.COMPLETED);
    } else if (info.status === AnalysisResultStatus.FAILED) {
      title = t(TransKeys.GENERAL.LABELS.ANALYSIS_STATUS.ERROR);
    } else if (info.status === AnalysisResultStatus.STOPPED) {
      title = t(TransKeys.GENERAL.LABELS.ANALYSIS_STATUS.STOPPED);
    }
    return (
      <div className={classes.StatusTooltip}>
        <div className={classes.Title}>{title}</div>
        {info.helperText && <div>{info.helperText}</div>}
      </div>
    );
  }, [info, t]);

  return (
    <Tooltip title={tooltipContent} placement={'top'} interactive={false}>
      <div
        className={classNames(classes.AnalysisResultStatusDisplay, classes[info.type], className)}
      >
        <info.icon className={classes.Icon} {...info.iconProps} />
      </div>
    </Tooltip>
  );
};

import * as yup from 'yup';
import {SchemaOf} from 'yup';

export interface SetPasswordFormDto {
  password: string;
  verifyPassword: string;
  firstName: string;
  lastName: string;
  token: string;
}

const passwordValidator = yup
  .string()
  .required()
  .min(8)
  .matches(/^.*[a-zA-Z]+.*$/, 'Password should contain at least 1 letter')
  .matches(/^.*[0-9]+.*$/, 'Password should contain at least 1 digit')
  .matches(/^.*[!@#$%^&*)(+=._-]+.*$/, 'Password should contain at least 1 special character');

export const SetPasswordFormDTOValidator: SchemaOf<SetPasswordFormDto> = yup.object().shape({
  password: passwordValidator,
  verifyPassword: yup
    .string()
    .required()
    .oneOf([yup.ref('password')], 'Passwords do not match'),
  token: yup.string(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
});

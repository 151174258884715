import {action, OnSuccessActionHook} from 'front-core';
import {UserSettingsDTO} from 'src/objects/dto/user-settings.dto';

export enum UserSettingsActionType {
  GET_USER_SETTINGS = '@@USER_SETTINGS/GET_USER_SETTINGS',
  UPDATE_USER_SETTINGS = '@@USER_SETTINGS/UPDATE_USER_SETTINGS',
}

export const getUserSettings = () => action(UserSettingsActionType.GET_USER_SETTINGS);

export const updateUserSettings = (data: UserSettingsDTO, onSuccess: OnSuccessActionHook) =>
  action(UserSettingsActionType.UPDATE_USER_SETTINGS, data, {onSuccess});

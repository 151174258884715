import * as React from 'react';
import classes from './analysis-action-box.module.scss';
import {StandardCheckBox} from '../../../shared/components/general/standard-check-box/standard-check-box.component';
import classNames from 'classnames';
import {Chip, ChipVariant, SparklesSolidIcon} from 'ui-components';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';

export interface AnalysisActionBoxProps {
  selected: boolean;
  icon: () => any;
  description: () => any;
  name: string;
  onClick: () => void;
  isCausal?: boolean;
}

const AnalysisActionBox: React.FC<AnalysisActionBoxProps> = props => {
  const {selected, icon, description, name, onClick, isCausal} = props;
  const {t} = useTranslation();

  return (
    <div className={classNames(classes.ActionBox, selected && classes.Selected)} onClick={onClick}>
      <div className={classes.Header}>
        <StandardCheckBox checked={selected} onChange={onClick} className={classes.Checkbox} />
        <div className={classes.IconWrapper}>{icon}</div>
      </div>
      <div className={classes.Body}>{description}</div>
      <div className={classes.Footer}>
        <div>{name}</div>
        {isCausal && (
          <Chip
            variant={ChipVariant.CAUSAL}
            className={classes.CausalTag}
            icon={SparklesSolidIcon}
            label={t(TransKeys.GENERAL.LABELS.CAUSAL)}
          />
        )}
      </div>
    </div>
  );
};

export default AnalysisActionBox;

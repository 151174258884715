import {useCallback, useContext, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import classes from './milestones-main.module.scss';
import {
  milestoneTypeFilter,
  queryFilter,
  tableEntityFilterGenerator,
} from '../../../../constants/filters';
import {milestoneToastCreator} from '../../../../store/toasts.actions';
import {ModelKey} from '../../../../constants/model-key';
import TransKeys from '../../../../constants/translation-keys';
import {TableColumn} from '../../../shared/components/general/table/table.component';
import {
  FlexHorizontal,
  FlexVertical,
} from '../../../shared/components/layout/flex-layout/general-flex-layouts.component.';
import {Title, TitleWithIcon} from '../../../shared/components/general/title/title.component';
import {
  Button,
  Chip,
  CircleCheckRegularIcon,
  EditIcon,
  HoverHelperTip,
  IconButton,
  MapPinDuotoneIcon,
  MoreIcon,
  TrashIcon,
} from 'ui-components';
import moment from 'moment';
import {MainTableStructure} from '../../../shared/infrastracture/main-table-structure/main-table-structure.component';
import {EDITABLE_MILESTONE_TYPES, Milestone} from '../../../../objects/models/milestone.model';
import {getMilestonesNetworkRequest} from '../../../../http/milestones.network-requests';
import {PanelKey} from '../../../../constants/panels';
import {MILESTONE_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {PanelType} from '../../../../objects/system/panel-type.enum';
import {capitalize, isNumber, keys} from 'lodash';
import {
  deleteMilestoneConfirmed,
  validateMilestone,
} from '../../../../store/milestones/milestones.actions';
import {replaceList} from '../../../../store/remote-lists/remote-list.actions';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {CoreActionsType} from '../../../../store/core/core.actions';
import {generateLastModified} from '../../../../utils/history.utils';
import {getEntityIcon} from '../../../../constants/entity.consts';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {withStopPropagation} from '../../../../utils/general.utils';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {Action, Subject} from '../../../../constants/permissions';
import {Permission} from '../../../../core/components/permission.component';
import usePermissions from '../../../../core/hooks/use-permissions.hook';
import {useCurrentUser} from '../../../../core/hooks/use-user.hook';
import {getListCount} from '../../../../store/store.selectors';
import {ValidationStatus} from '../../../../objects/models/signal.model';
import {QuerySqlTabsNames} from '../../../shared/core/query-sql-tabs/query-sql-tabs.component';
import InvalidSignalWarning from '../../components/invalid-signal-warning/invalid-signal-warning.component';
import {ModelActionsDropdown} from '../../../shared/core/model-actions-dropdown/model-actions-dropdown.component';
import {useDemoProduct} from '../../../../core/hooks/use-demo-product.hook';

interface OwnProps {}

type AllProps = OwnProps;

const createListKey = () => `MILESTONES_MAIN/MILESTONES`;

export const MilestonesMain: React.FC<AllProps> = (props: AllProps) => {
  const {t} = useTranslation();
  const {can} = usePermissions();
  const {openPrimaryPanel} = useContext(PanelsContext);
  const dispatch = useDispatch();
  const notify = useAmplitude();
  const {productEntities, productEntitiesMap} = useProductData();
  const {isDemoProduct} = useDemoProduct();
  const user = useCurrentUser();
  const defaultFilters = useMemo(
    () => ({
      orderBy: 'updatedOn',
      order: 'desc',
      itemsPerPage: 50,
    }),
    []
  );
  const total = useSelector(state => getListCount(createListKey())(state));
  const title = useMemo(
    () => `${t(TransKeys.MILESTONES.HEADER.TITLE)} ${isNumber(total) ? ` (${total})` : ''}`,
    [t, total]
  );
  const filtersDef = useMemo(() => {
    const filters = [queryFilter(), milestoneTypeFilter()];
    if (keys(productEntities).length > 1) {
      filters.push(tableEntityFilterGenerator(productEntities));
    }
    return filters;
  }, [productEntities]);
  const config = useMemo(
    () => ({
      listKey: createListKey(),
      actionKey: createListKey(),
      request: getMilestonesNetworkRequest,
      onError: err => [milestoneToastCreator('GET_ERROR')],
      modelKey: ModelKey.MILESTONE,
    }),
    []
  );
  const showMilestone = useCallback(
    (milestone: Milestone) => {
      const parameters = {[MILESTONE_ID_PATH_PARAM]: milestone.id};
      if (milestone.signalValidationStatus === ValidationStatus.ERROR && !isDemoProduct) {
        parameters['initialTab'] = QuerySqlTabsNames.SQL;
      }
      openPrimaryPanel(PanelKey.VIEW_MILESTONE_PANEL, parameters, PanelType.MODAL);
    },
    [openPrimaryPanel, isDemoProduct]
  );
  const onValidateMilestone = useCallback(
    (milestone: Milestone) => dispatch(validateMilestone(milestone.id)),
    [dispatch]
  );
  const onCreateEdit = useCallback(
    (milestone?: Milestone, parameters: any = {}) => {
      openPrimaryPanel(
        PanelKey.MILESTONE_FORM_PANEL,
        {
          [MILESTONE_ID_PATH_PARAM]: milestone?.id,
          ...parameters,
        },
        PanelType.MODAL
      );

      const eventType = milestone
        ? AmplitudeEvent.MILESTONE_EDIT_CLICKED
        : AmplitudeEvent.MILESTONE_CREATE_CLICKED;

      notify(eventType, {
        userId: user.id,
      });
    },
    [openPrimaryPanel, user, notify]
  );

  const onDelete = useCallback(
    (milestone: Milestone) => dispatch(deleteMilestoneConfirmed(milestone.id)),
    [dispatch]
  );

  const columns: TableColumn[] = useMemo(
    () => [
      {
        key: 'name',
        title: t(TransKeys.GENERAL.HEADERS.NAME),
        width: '36rem',
        sticky: 'left',
        stretch: true,
        sortable: true,
        render: (milestone: Milestone) => (
          <FlexVertical spacing verticalAlignCenter className={classes.TitleWrapper}>
            <FlexHorizontal spacing verticalAlignCenter>
              <Title className={classes.Title} text={milestone.name} caps={false} />
              {milestone.shortDescription && <HoverHelperTip title={milestone.shortDescription} />}
              {milestone.signalValidationStatus === ValidationStatus.ERROR && (
                <InvalidSignalWarning
                  title={t(TransKeys.MILESTONE.SIGNAL_VALIDATION_STATUS_HELPER_TEXT)}
                />
              )}
            </FlexHorizontal>
          </FlexVertical>
        ),
      },
      {
        key: 'type',
        title: t(TransKeys.GENERAL.HEADERS.TYPE),
        sortable: true,
        width: '16rem',
        render: (milestone: Milestone) => (
          <FlexHorizontal spacing verticalAlignCenter className={classes.TitleWrapper}>
            <Chip label={capitalize(milestone.type.replace(/_/g, ' '))} />
          </FlexHorizontal>
        ),
      },
      {
        key: 'entity',
        title: t(TransKeys.GENERAL.HEADERS.ENTITY),
        sortable: true,
        width: '12rem',
        hidden: keys(productEntitiesMap).length < 2,
        render: (milestone: Milestone) => (
          <FlexHorizontal spacing verticalAlignCenter className={classes.TitleWrapper}>
            <TitleWithIcon
              className={classes.Entity}
              titleClassName={classes.Entity}
              text={productEntitiesMap[milestone.entity].name}
              icon={getEntityIcon(milestone.entity)}
            />
          </FlexHorizontal>
        ),
      },
      {
        key: 'updatedOn',
        title: t(TransKeys.GENERAL.HEADERS.UPDATED),
        sortable: true,
        width: '16rem',
        render: (milestone: Milestone) => {
          const modifier = generateLastModified(milestone.history[0], true);
          if (modifier) {
            return (
              <FlexVertical spacing verticalAlignCenter>
                {modifier.user ? t(TransKeys.GENERAL.LABELS.MODIFIED_BY, modifier) : modifier.date}
              </FlexVertical>
            );
          }
          return (
            <FlexVertical spacing verticalAlignCenter>
              {moment.utc(milestone.updatedOn).local().fromNow()}
            </FlexVertical>
          );
        },
      },
      {
        key: 'actions',
        title: '',
        width: '12rem',
        sticky: 'right',
        align: 'right',
        render: (milestone: Milestone) => {
          const editable = EDITABLE_MILESTONE_TYPES.includes(milestone.type);
          return (
            <div className={classes.Actions}>
              <Permission subject={Subject.MILESTONE} action={Action.EDIT}>
                <IconButton
                  className={classes.Button}
                  tooltipText={
                    editable
                      ? t(TransKeys.GENERAL.ACTIONS.EDIT)
                      : t(TransKeys.MILESTONES.EDIT_NOT_SUPORTED)
                  }
                  icon={EditIcon}
                  onClick={withStopPropagation(() => onCreateEdit(milestone))}
                  disabled={!editable}
                />
              </Permission>
              <ModelActionsDropdown
                className={classes.Button}
                actions={[
                  {
                    key: 'delete',
                    hide: !can(Subject.MILESTONE, Action.DELETE),
                    title: t(TransKeys.GENERAL.ACTIONS.DELETE),
                    onClick: () => onDelete(milestone),
                    icon: TrashIcon,
                  },
                  {
                    key: 'validate',
                    title: t(TransKeys.GENERAL.ACTIONS.VALIDATE),
                    onClick: () => onValidateMilestone(milestone),
                    icon: CircleCheckRegularIcon,
                  },
                ]}
                label={t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
                icon={MoreIcon}
                iconDropdown
              />
            </div>
          );
        },
      },
    ],
    [t, can, onCreateEdit, onDelete, onValidateMilestone, productEntitiesMap]
  );

  useEffect(() => {
    const listener = action => {
      if (action.payload.modelKey === ModelKey.MILESTONE) {
        dispatch(replaceList(config.listKey, {page: 1}, 'append'));
      }
    };
    dispatch(registerActionListener(CoreActionsType.MODEL_CREATED, listener));
    return () => {
      dispatch(removeActionListener(CoreActionsType.MODEL_CREATED, listener));
    };
  }, [dispatch, config]);

  return (
    <>
      <MainTableStructure
        className={classes.MilestonesMain}
        title={title}
        icon={MapPinDuotoneIcon}
        columns={columns}
        config={config}
        defaultFilters={defaultFilters}
        filtersDef={filtersDef}
        onRowClicked={milestone => showMilestone(milestone)}
        helperTextTitle={t(TransKeys.MILESTONES.HEADER.HELPER_TEXT)}
        headerRenderRight={
          <Permission subject={Subject.MILESTONE} action={Action.CREATE}>
            <Button onClick={() => onCreateEdit()}>
              {t(TransKeys.GENERAL.ACTIONS.CREATE_MILESTONE)}
            </Button>
          </Permission>
        }
        emptyStateTranslationPath={TransKeys.MILESTONES.EMPTY_STATE}
        itemsPerPage={50}
      />
    </>
  );
};

import * as React from 'react';
import {useRemoteSourceStated} from 'ui-components';
import {getSignalSQLNetworkRequest} from '../../../../../http/signals.network-requests';
import {useEffect} from 'react';
import {GenericLoading} from '../generic-loading/generic-loading.component';
import {SQLViewer} from '../sql-viewer/sql-viewer.component';

interface SignalSqlProps {
  signalId?: number;
  border?: boolean;
}

const SignalSql: React.FC<SignalSqlProps> = (props: SignalSqlProps) => {
  const {border, signalId} = props;
  const {
    source: sql,
    exec: getSQL,
    isLoading: isLoadingSQL,
  } = useRemoteSourceStated({
    networkRequest: getSignalSQLNetworkRequest,
    initialValue: null,
  });

  useEffect(() => {
    if (!sql) {
      getSQL(signalId);
    }
  }, [sql, getSQL, signalId]);

  return (
    <>
      {isLoadingSQL && <GenericLoading />}
      {sql && <SQLViewer sql={sql} border={border} />}
    </>
  );
};

export default SignalSql;

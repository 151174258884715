import {TreatmentSchemaKeysMapping} from '../components/ui-selectors/treatment-selector/treatment-selector.component';
import {useRemoteSourceStated} from 'ui-components';
import {multiLoaderNetworkRequest} from '../../../../http/multi-loader.network-requests';
import {useEffect, useMemo} from 'react';
import {exists} from 'front-core';
import {values} from 'lodash';

export const useTreatmentSignals = (parameters: any, mapping: TreatmentSchemaKeysMapping) => {
  const {
    source: multiLoadResponse,
    exec: getSelectedTreatmentSignals,
    clear,
  } = useRemoteSourceStated({
    networkRequest: multiLoaderNetworkRequest,
  });
  const treatments_signals_cached = useMemo(
    () => (parameters[mapping.treatments_signals] || []).join(','),
    [parameters, mapping.treatments_signals]
  );
  const treatmentsTags = parameters[mapping.treatments_tag];
  const multiLoadPayloadForTreatmentSignals = useMemo(() => {
    if (!exists(treatments_signals_cached) && !exists(treatmentsTags)) {
      return null;
    }
    const res: any = {
      load_tags_as_signals: true,
    };
    if (exists(treatments_signals_cached)) {
      res.signals = treatments_signals_cached.split(',').map(s => Number(s));
    }
    if (exists(treatmentsTags)) {
      res.tags = treatmentsTags.split(',');
    }
    return res;
  }, [treatments_signals_cached, treatmentsTags]);

  useEffect(() => {
    if (multiLoadPayloadForTreatmentSignals) {
      getSelectedTreatmentSignals(multiLoadPayloadForTreatmentSignals);
    } else {
      clear();
    }
  }, [multiLoadPayloadForTreatmentSignals, getSelectedTreatmentSignals, clear]);

  return [
    ...(multiLoadResponse?.tags ? values(multiLoadResponse.tags) : []),
    ...(multiLoadResponse?.signals ? values(multiLoadResponse.signals) : []),
  ];
};

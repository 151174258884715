import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/browser';
import {ExtraErrorData as ExtraErrorDataIntegration} from '@sentry/integrations';
import {CaptureConsole as CaptureConsoleIntegration} from '@sentry/integrations';
import {HttpClient as HttpClientIntegration} from '@sentry/integrations';
import appConfig from './app.config';

export const initSentry = () => {
  if (appConfig.sentryDsn) {
    Sentry.init({
      dsn: appConfig.sentryDsn,
      integrations: [
        new BrowserTracing(),
        new ExtraErrorDataIntegration(),
        new HttpClientIntegration(),
        new CaptureConsoleIntegration({
          // array of methods that should be captured
          // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
          levels: ['error'],
        }),
      ],

      tracesSampleRate: 1,
    });
  }
};

export const setSentryUser = (id, isInternalUser, scopeProductId) => {
  Sentry.setUser({id, isInternalUser, scopeProductId});
};

import {AnalysisTypeSubject} from './components/analysis-type-selection-sentence-wizard/analysis-type-selection-sentence-wizard.component';
import {AnalysisTypeId} from '../../../../../../constants/analysis-type-id';
import {get} from 'lodash';

export function analysisTypeSubjectParametersFiller(
  analysisTypeId: number,
  subject: AnalysisTypeSubject
): any {
  if (!subject) {
    return;
  }
  const {model} = subject;
  const baseParameters = {
    entity: model.entity,
  };

  switch (analysisTypeId) {
    case AnalysisTypeId.FUNNEL_ANALYSIS: {
      // Assuming modelType is Funnel
      return {
        ...baseParameters,
        funnel: model.id,
      };
    }
    case AnalysisTypeId.KPI_ANALYSIS: {
      // Assuming modelType is Metric
      return {
        ...baseParameters,
        goal: model.signalId,
      };
    }
    case AnalysisTypeId.GOAL_DRIVERS: {
      // Assuming modelType is Metric
      let ui_goal_type = undefined;
      let goal_query = undefined;
      const templateName = get(model.signalDefinition, 'template');
      const validTemplate = ['bounded_action_ts', 'bounded_actions_ts'].indexOf(templateName) > -1;
      if (validTemplate) {
        goal_query = model.signalDefinition;
        ui_goal_type = model.type;
      }
      return {
        ...baseParameters,
        ui_goal_type,
        goal_query,
      };
    }
    case AnalysisTypeId.CORRELATION_DRIVERS: {
      // Assuming modelType is Metric
      let ui_goal_type = undefined;
      let goal_query = undefined;
      const templateName = get(model.signalDefinition, 'template');
      const validTemplate = ['bounded_action_ts', 'bounded_actions_ts'].indexOf(templateName) > -1;
      if (validTemplate) {
        goal_query = model.signalDefinition;
        ui_goal_type = model.type;
      }
      return {
        ...baseParameters,
        ui_goal_type,
        goal_query,
      };
    }
    case AnalysisTypeId.ENGAGEMENT_DRIVERS: {
      // Assuming modelType is Metric
      return {
        ...baseParameters,
        goal: model.signalId,
      };
    }
    case AnalysisTypeId.FIRST_SESSION: {
      // Assuming modelType is Metric
      return {
        ...baseParameters,
        group_by_signal: model.signalId,
      };
    }
    case AnalysisTypeId.USER_JOURNEY: {
      // Assuming modelType is Metric
      let ui_goal_type = undefined;
      let goal_query = undefined;
      const templateName = get(model.signalDefinition, 'template');
      const validTemplate = ['bounded_action_ts', 'bounded_actions_ts'].indexOf(templateName) > -1;
      if (validTemplate) {
        ui_goal_type = model.type;
        goal_query = model.signalDefinition;
      }
      return {
        ...baseParameters,
        ui_goal_type,
        goal_query,
      };
    }
    case AnalysisTypeId.HABIT_MOMENTS:
      // Assuming modelType is Metric
      let goal_query = undefined;
      const templateName = get(model.signalDefinition, 'template');
      const validTemplate = templateName === 'bounded_actions_ts'; // retention
      if (validTemplate) {
        goal_query = model.signalDefinition;
      }
      return {
        ...baseParameters,
        goal_query,
      };
  }
}

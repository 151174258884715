import React, {useCallback, useMemo} from 'react';
import {Select} from 'ui-components';
import {useCronExpBuilder} from '../../../../cron-exp-builder-context-provider/cron-exp-builder-context-provider.component';
import {
  CronUnits,
  CronUnitsPositions,
} from '../../../../../../../../../../../constants/cron-exp.consts';
import classNames from 'classnames';
import sharedClasses from './shared-cron-exp.module.scss';

export const WEEK_DAY_OPTIONS = [
  {label: 'Mon', value: '1'},
  {label: 'Tue', value: '2'},
  {label: 'Wed', value: '3'},
  {label: 'Thu', value: '4'},
  {label: 'Fri', value: '5'},
  {label: 'Sat', value: '6'},
  {label: 'Sun', value: '0'},
];

interface OwnProps {
  disabled: boolean;
}

type AllProps = OwnProps;

const CronExpSelectWeekDay: React.FC<AllProps> = (props: AllProps) => {
  const {disabled} = props;
  const {setUnit, cronExpValueAsArray} = useCronExpBuilder();
  const weekDayOptions = useMemo(() => ({options: WEEK_DAY_OPTIONS}), []);
  const onDayOfTheWeekChange = useCallback(
    value => setUnit(CronUnits.DAY_OF_THE_WEEK, value),
    [setUnit]
  );

  return (
    <Select
      className={classNames(disabled && sharedClasses.DisabledSelect)}
      clearable={false}
      disabled={disabled}
      onChange={onDayOfTheWeekChange}
      options={weekDayOptions}
      sortValues={false}
      value={cronExpValueAsArray[CronUnitsPositions[CronUnits.DAY_OF_THE_WEEK]]}
    />
  );
};

export default CronExpSelectWeekDay;

import {Reducer} from 'redux';
import {keyBy, max, values} from 'lodash';

export const loadNotificationsReducer = (state, action): Reducer => {
  const {data, meta} = action.payload;

  return {
    ...state,
    notifications: {
      ...state.notifications,
      ...keyBy(data, 'id'),
    },
    total: meta.page === 0 ? meta.total : state.total,
    hasNext: meta.hasNext,
  };
};

export const markNotificationsAsSeenReducer = (state, action): Reducer => {
  return {
    ...state,
    notifications: keyBy(
      values(state.notifications).map(notification => ({
        ...notification,
        isSeen: true,
      })),
      'id'
    ),
  };
};

export const markNotificationAsReadReducer = (state, action): Reducer => {
  const notificationId = action.payload;
  // update all as read
  if (!notificationId) {
    return {
      ...state,
      notifications: keyBy(
        values(state.notifications).map(notification => ({
          ...notification,
          isRead: true,
        })),
        'id'
      ),
    };
  }
  // update single notification as read
  const notification = state.notifications[notificationId];
  if (!notification) {
    return state;
  }
  notification.isRead = true;
  return {
    ...state,
    notifications: {
      ...state.notifications,
      [notificationId]: notification,
    },
  };
};

export const newNotificationReducer = (state, action): Reducer => {
  const notification = action.payload;
  const maxNotificationId = max(values(state.notifications).map(n => n.id));
  return {
    ...state,
    notifications: {
      ...state.notifications,
      [notification.id]: notification,
    },
    total: notification.id < maxNotificationId ? state.total : state.total + 1,
  };
};

import {useRemoteList} from '../../../../../../core/hooks/use-remote-list.hook';
import {useCallback, useContext, useEffect, useMemo} from 'react';
import {exists} from 'front-core';
import {ModelKey} from '../../../../../../constants/model-key';
import {opportunityToastCreator} from '../../../../../../store/toasts.actions';
import {OpportunitiesTabs} from '../../opportunities-main.component';
import {Opportunity} from '../../../../../../objects/models/opportunity.model';
import {
  ActionsDropdown,
  AnalysisFileIcon,
  ArchiveIcon,
  Chip,
  Filters,
  IconButton,
  KPIIcon,
  MoreIcon,
  ShareIcon,
  SnoozeIcon,
  StarIcon,
  ToggleIconButton,
} from 'ui-components';
import {
  SimpleTable,
  TableColumn,
} from '../../../../../shared/components/general/table/table.component';
import TransKeys from '../../../../../../constants/translation-keys';
import {
  FlexHorizontal,
  FlexVertical,
} from '../../../../../shared/components/layout/flex-layout/general-flex-layouts.component.';
import {useTranslation} from 'react-i18next';
import {AppSentence} from '../../../../../shared/core/app-sentence/app-sentence.component';
import {TitleWithIcon} from '../../../../../shared/components/general/title/title.component';
import {AnalysisParameters} from '../../../../../analyses/components/analysis-parameters/analysis-parameters.component';
import moment from 'moment/moment';
import {getOpportunitiesNetworkRequest} from '../../../../../../http/opportunities.network-requests';
import {useDispatch} from 'react-redux';
import {replaceList} from '../../../../../../store/remote-lists/remote-list.actions';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../../../store/actions-listener/actions-listener.actions';
import {CoreActionsType} from '../../../../../../store/core/core.actions';
import {PanelsContext} from '../../../../../../core/contexts/panels.context';
import {useHistory} from 'react-router';
import {useAmplitude} from '../../../../../../core/hooks/amplitude.hook';
import {
  useIsDismissed,
  useIsExplored,
  useIsSnoozed,
} from '../../../../../../core/hooks/user-reactions.hook';
import {ModelDiscriminatorType} from '../../../../../../objects/models/user-reaction.model';
import {PanelKey} from '../../../../../../constants/panels';
import {ShareResourceType} from '../../../../../../objects/models/share.model';
import {AppRoutes, METRIC_ID_PATH_PARAM} from '../../../../../../constants/app-routes';
import {PanelType} from '../../../../../../objects/system/panel-type.enum';
import {AmplitudeEvent} from '../../../../../../constants/amplitude-event';
import {toLocalTime} from '../../../../../../utils/general.utils';
import {TIME_FORMATS} from '../../../../../../constants/time-formats';
import {NavLink} from '../../../../../shared/components/navigation/left-navigation/left-navigation.component';
import {sharedClasses} from '../../../../../shared';
import PageLayout from '../../../../../shared/components/layout/page-layout/index';

interface OwnProps {
  tabName: OpportunitiesTabs;
  filtersDef?: any[];
  defaultFilters?: any;
}

type AllProps = OwnProps;

const IS_NEW_DIFF_DAYS = 7;
const createListKey = (tab: string) => `OPPORTUNITIES_MAIN/TAB_${tab.toUpperCase()}`;
export const OpportunitySimpleTab: React.FC<AllProps> = (props: AllProps) => {
  const {tabName, filtersDef, defaultFilters} = props;
  // Hooks
  const {openPrimaryPanel, openSecondaryPanel} = useContext(PanelsContext);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const notify = useAmplitude();
  const onDismiss = useIsDismissed(ModelDiscriminatorType.OPPORTUNITY);
  const onSnooze = useIsSnoozed(ModelDiscriminatorType.OPPORTUNITY);
  const onExplore = useIsExplored(ModelDiscriminatorType.OPPORTUNITY);
  // Callbacks
  const onShare = useCallback(
    (oppo: Opportunity) => {
      openPrimaryPanel(
        PanelKey.SHARE_RESOURCE_PANEL,
        {
          modelId: oppo.id,
          type: ShareResourceType.OPPORTUNITY,
          copyLink: AppRoutes.asUrl(AppRoutes.viewOpportunity(oppo.id)),
        },
        PanelType.MODAL
      );
      notify(AmplitudeEvent.RESOURCE_SHARE_CLICKED, {
        type: ShareResourceType.OPPORTUNITY,
        id: oppo.id,
      });
    },
    [openPrimaryPanel, notify]
  );
  const viewOpportunity = useCallback(
    (oppo: Opportunity) => history.push(AppRoutes.viewOpportunity(oppo.id)),
    [history]
  );
  const onViewMetric = useCallback(
    (opportunity: Opportunity) =>
      openSecondaryPanel(PanelKey.VIEW_METRIC_PANEL, {
        [METRIC_ID_PATH_PARAM]: opportunity.metricId,
      }),
    [openSecondaryPanel]
  );
  // Memos
  const config = useMemo(
    () => ({
      listKey: createListKey(tabName),
      actionKey: createListKey(tabName),
      request: getOpportunitiesNetworkRequest,
      onError: err => [opportunityToastCreator('GET_ERROR')],
      modelKey: ModelKey.OPPORTUNITY,
    }),
    [tabName]
  );
  const contentWrapper = useCallback(
    (opportunity: Opportunity, children: React.ReactNode) => (
      <NavLink
        key={opportunity.id}
        className={sharedClasses.UnstyledLink}
        to={AppRoutes.viewOpportunity(opportunity.id)}
      >
        {children}
      </NavLink>
    ),
    []
  );

  const columns: TableColumn[] = useMemo(
    () => [
      {
        key: 'fav',
        title: '',
        sortable: false,
        width: '0',
        render: (opportunity: Opportunity) => (
          <FlexHorizontal horizontalAlignCenter fullWidth>
            <ToggleIconButton
              titleChecked={t(TransKeys.GENERAL.ACTIONS.UNEXPLORE)}
              titleUnchecked={t(TransKeys.GENERAL.ACTIONS.EXPLORE)}
              onChange={_ => onExplore(opportunity)}
              isChecked={opportunity.isExplored}
              icon={StarIcon}
            />
          </FlexHorizontal>
        ),
      },
      {
        key: 'main',
        title: t(TransKeys.GENERAL.LABELS.OPPORTUNITY),
        sortable: false,
        width: '70%',
        render: (opportunity: Opportunity) => {
          const isNew = moment(opportunity.createdOn).add(IS_NEW_DIFF_DAYS, 'd').isAfter(moment());
          return (
            <FlexVertical spacing={Boolean(isNew || opportunity.metric?.id)}>
              <div onClick={() => viewOpportunity(opportunity)}>
                <AppSentence text={opportunity.title || ''} disabled />
              </div>
              {(opportunity.metric?.id || isNew) && (
                <FlexHorizontal spacing verticalAlignCenter>
                  {opportunity.metric?.id && (
                    <TitleWithIcon
                      onClick={() => onViewMetric(opportunity)}
                      text={opportunity.metric.title || opportunity.metric.name}
                      icon={KPIIcon}
                      size={'medium'}
                    />
                  )}
                  {isNew && <Chip label={t(TransKeys.GENERAL.LABELS.NEW)} />}
                </FlexHorizontal>
              )}
            </FlexVertical>
          );
        },
      },
      {
        key: 'analysis',
        title: t(TransKeys.GENERAL.LABELS.ANALYSIS),
        sortable: false,
        width: '20%',
        render: (opportunity: Opportunity) => (
          <FlexVertical spacing>
            <TitleWithIcon
              text={opportunity.analysis.staticName}
              icon={AnalysisFileIcon}
              size={'medium'}
            />
          </FlexVertical>
        ),
      },
      {
        key: 'createdOn',
        title: t(TransKeys.GENERAL.LABELS.CREATED),
        sortable: false,
        width: '14rem',
        render: (opportunity: Opportunity) => (
          <div>{toLocalTime(opportunity.createdOn, TIME_FORMATS.READABLE_DATE)}</div>
        ),
      },
      {
        key: 'parameters',
        title: 'Parameters',
        sortable: false,
        width: '10%',
        render: (opportunity: Opportunity) => (
          <FlexVertical spacing>
            <AnalysisParameters
              label={t(TransKeys.GENERAL.LABELS.PARAMETERS)}
              analysisResultId={opportunity.analysisResultId}
            />
          </FlexVertical>
        ),
      },
      {
        key: 'actions',
        title: '',
        sortable: false,
        width: '0',
        render: (opportunity: Opportunity) => {
          return (
            <FlexHorizontal verticalAlignCenter spacing>
              <IconButton
                tooltipText={t(TransKeys.GENERAL.ACTIONS.SHARE)}
                icon={ShareIcon}
                onClick={() => onShare(opportunity)}
              />
              <ActionsDropdown
                actions={[
                  {
                    key: 'dismiss',
                    title: opportunity.isDismissed
                      ? t(TransKeys.GENERAL.ACTIONS.UNARCHIVE)
                      : t(TransKeys.GENERAL.ACTIONS.ARCHIVE),
                    onClick: () => onDismiss(opportunity),
                    hide: !onDismiss,
                    icon: ArchiveIcon,
                  },
                  {
                    key: 'snooze',
                    title: opportunity.snoozeUntil
                      ? t(TransKeys.GENERAL.ACTIONS.UN_SNOOZE)
                      : t(TransKeys.GENERAL.ACTIONS.SNOOZE),
                    onClick: () => onSnooze(opportunity),
                    hide: !onSnooze,
                    icon: SnoozeIcon,
                  },
                ]}
                label={t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
                icon={MoreIcon}
                iconDropdown
              />
            </FlexHorizontal>
          );
        },
      },
    ],
    [onExplore, onShare, onDismiss, onSnooze, viewOpportunity, onViewMetric, t]
  );
  const emptyStateProps = useMemo(
    () => ({
      title: t(TransKeys.OPPORTUNITIES.EMPTY_STATE.TITLE),
      subTitle: t(TransKeys.OPPORTUNITIES.EMPTY_STATE.SUB_TITLE),
      buttonText: t(TransKeys.OPPORTUNITIES.EMPTY_STATE.BUTTON_TEXT),
    }),
    [t]
  );

  const {isLoading, listsData, onFiltersChange, onPageChange, onSort, filters} = useRemoteList({
    config,
    filtersDef,
    defaultFilters,
    syncQueryFilters: true,
  });
  // todo: find a better way to do so - the problem: multiple orders
  const onSortTable = useCallback(
    (key: string) => {
      const newSort: any = onSort(key, false);
      if (exists(newSort.order) && newSort.order !== 'createdOn') {
        newSort.order = [newSort.order, 'desc'];
        newSort.orderBy = [newSort.orderBy, 'createdOn'];
      }
      onFiltersChange({...filters, ...newSort});
    },
    [onSort, onFiltersChange, filters]
  );
  // Effects
  useEffect(() => {
    const listener = action => {
      if (action.payload.modelKey === ModelKey.OPPORTUNITY) {
        dispatch(replaceList(config.listKey, {page: 1}, 'append', true));
      }
    };
    dispatch(registerActionListener(CoreActionsType.MODEL_UPDATED, listener));
    return () => {
      dispatch(removeActionListener(CoreActionsType.MODEL_UPDATED, listener));
    };
  }, [dispatch, config.listKey]);

  return (
    <>
      <PageLayout.Filters>
        <Filters
          selected={filters}
          defaultFilters={defaultFilters}
          hideDefaultFilters
          onChange={onFiltersChange}
          onClearAll={() => onFiltersChange(defaultFilters)}
          freeSearchFilterKey={'userQuery'}
          filters={filtersDef}
        />
      </PageLayout.Filters>
      <PageLayout.Body>
        {listsData && (
          <SimpleTable
            scrollable={false}
            data={listsData.list}
            dataKey={'id'}
            columns={columns}
            pagination={true}
            paginationMode={'pages'}
            onPageChange={(_, page) => onPageChange(page + 1)}
            total={listsData.meta?.total}
            page={listsData.meta?.page - 1}
            perPage={listsData.meta?.numPerPage}
            onSort={(_, key) => onSortTable(key)}
            order={filters?.order?.[0]}
            orderBy={filters?.orderBy?.[0]}
            isLoading={isLoading}
            emptyStateProps={emptyStateProps}
            contentWrapper={contentWrapper}
          />
        )}
      </PageLayout.Body>
    </>
  );
};

import {useCallback, useEffect} from 'react';
import {
  createUserReaction,
  deleteUserReaction,
} from '../../store/user-reactions/user-reactions.actions';
import {
  IsUsefulReactionPayload,
  ModelDiscriminatorType,
  UserReactionType,
} from '../../objects/models/user-reaction.model';
import {get} from 'lodash';
import {useDispatch} from 'react-redux';
import {ActionHooks, removeUndefinedKeys} from 'front-core';
import {notifyEvent} from '../../store/core/core.actions';
import {
  modelDiscriminatorTypeToModelKeyMap,
  userReactionToAmplitudeEvent,
  userReactionTypeToKeyMap,
} from '../../utils/amplitude.utils';
import {usefulResourceToDiscriminator, UsefulResourceType} from '../../objects/models/useful.model';

export function useUserReaction(
  reactionType: UserReactionType,
  discriminator: ModelDiscriminatorType,
  hooks?: ActionHooks
) {
  const dispatch = useDispatch();
  const key = userReactionTypeToKeyMap[reactionType];

  return useCallback(
    (model: any, payload: any = undefined) => {
      const modelProperty = get(model, key);
      const whichAction = modelProperty ? deleteUserReaction : createUserReaction;
      dispatch(
        whichAction(
          reactionType,
          removeUndefinedKeys({
            parentId: model?.id,
            discriminator: discriminator,
            payload,
          }) as any,
          {
            ...hooks,
            onSuccess: (...args) => {
              const actions = [];
              const res = args[0];
              if (hooks?.onSuccess) {
                actions.push(...(hooks.onSuccess(...args) || []));
              }
              actions.push(
                notifyEvent(userReactionToAmplitudeEvent[reactionType], {
                  model_id: model?.id,
                  model_type: modelDiscriminatorTypeToModelKeyMap[discriminator],
                  value: res?.data?.reactionValue,
                })
              );
              return actions;
            },
          }
        )
      );
    },
    [discriminator, dispatch, key, reactionType, hooks]
  );
}

export function useIsExplored(discriminator: ModelDiscriminatorType, hooks?: ActionHooks) {
  return useUserReaction(UserReactionType.EXPLORE, discriminator, hooks);
}

export function useIsDismissed(discriminator: ModelDiscriminatorType, hooks?: ActionHooks) {
  return useUserReaction(UserReactionType.DISMISS, discriminator, hooks);
}

export function useIsSnoozed(discriminator: ModelDiscriminatorType, hooks?: ActionHooks) {
  return useUserReaction(UserReactionType.SNOOZE, discriminator, hooks);
}

export function useIsViewed(discriminator: ModelDiscriminatorType, hooks?: ActionHooks) {
  return useUserReaction(UserReactionType.VIEW, discriminator, hooks);
}

export function useSyncIsViewed(model, discriminator) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (model && !get(model, userReactionTypeToKeyMap[UserReactionType.VIEW])) {
      dispatch(
        createUserReaction(
          UserReactionType.VIEW,
          {
            parentId: model.id,
            discriminator: discriminator,
          },
          {
            onSuccess: () => [
              notifyEvent(userReactionToAmplitudeEvent[UserReactionType.VIEW], {
                model_id: model?.id,
                model_type: discriminator,
                ...(discriminator === ModelDiscriminatorType.EXPERIMENT ? {type: model?.type} : {}),
              }),
            ],
          }
        )
      );
    }
  }, [model, dispatch, discriminator]);
}

export function useIsUseful(usefulResourceType: UsefulResourceType) {
  const dispatch = useDispatch();
  const mappedDiscriminator = usefulResourceToDiscriminator[usefulResourceType];
  return useCallback(
    (model: any, payload: IsUsefulReactionPayload, hooks?: ActionHooks) => {
      dispatch(
        createUserReaction(
          UserReactionType.USEFUL,
          {
            parentId: model.id,
            discriminator: mappedDiscriminator,
            payload,
          },
          {
            ...hooks,
            onSuccess: (...args) => {
              const actions = [];
              if (hooks?.onSuccess) {
                actions.push(...(hooks.onSuccess(...args) || []));
              }
              actions.push(
                notifyEvent(userReactionToAmplitudeEvent[UserReactionType.USEFUL], {
                  model_id: model?.id,
                  model_type: modelDiscriminatorTypeToModelKeyMap[mappedDiscriminator],
                  status: payload?.status,
                  reason: payload?.reason,
                })
              );
              return actions;
            },
            onError: (...args) => {
              const actions = [];
              if (hooks?.onError) {
                actions.push(...(hooks.onError(...args) || []));
              }
              return actions;
            },
          }
        )
      );
    },
    [dispatch, mappedDiscriminator]
  );
}

import yup from '../../../../../config/yup.config';
import {PARAMETERS_METADATA_KEY} from '../../../../../constants/parameters-saved-keys';
import {get, values} from 'lodash';
import {TableEntity} from '../../../../../objects/models/table.model';
import {
  queryElementValidatorFactory,
  validateSignalIds,
} from '../../../../../objects/dto/query-builder.dto';
import {exists} from 'front-core';
import {startEndDatesValidator, validateSignalTimeframeBoundingFor} from '../shared-validators';
import {ANALYSIS_MODE_KEY, GOAL_KEY, GOAL_TYPE_KEY} from './analysis-101-form.component';
import {ParameterType} from 'ui-components';
import i18n from 'i18next';
import TransKeys from '../../../../../constants/translation-keys';
import {
  AnalysisParametersTransformer,
  baseAnalysisParametersTransformer,
  SPECIAL_TAGS_TRANSLATIONS,
} from '../shared-transformers';

export const analysis101ParametersValidator = yup.object().shape({
  parameters: yup
    .object()
    .shape({
      [PARAMETERS_METADATA_KEY]: yup.object().nullable(),
      entity: yup.string().oneOf(values(TableEntity)).required(),
      goal: yup.number().required().test(validateSignalIds()),
      time_aggregation: yup.string().oneOf(['day', 'week', 'month']).nullable(),
      excluded_segments: yup.array().of(yup.number()).nullable(),
      included_segments_tag: yup.string().test({
        name: 'required_if_included_segment_signals_not_exists',
        test: function (v, context) {
          const included_segments_signals = context.parent.included_segments_signals;
          if (exists(v)) {
            return true;
          }
          if (!exists(included_segments_signals) || included_segments_signals.length === 0) {
            return this.createError({
              message: 'Required',
            });
          }
          return true;
        },
      }),
      included_segments_signals: yup
        .array()
        .of(yup.number())
        .when('included_segments_tag', {
          is: s => !exists(s),
          then: schema => schema.required().min(1),
          otherwise: schema => schema.nullable(),
        }),
      analysis_mode: yup.string().nullable(),
      population_filter: queryElementValidatorFactory(false),
    })
    .required(),
  runParameters: startEndDatesValidator.required().test(
    validateSignalTimeframeBoundingFor({
      signalIdParameter: 'goal',
      parameterName: 'analysis goal',
      timeAggregationParameterName: 'time_aggregation',
    })
  ),
});

export const analysis101ParametersTransformer: AnalysisParametersTransformer = (
  values,
  schema,
  productEntities
) => {
  [values, schema] = baseAnalysisParametersTransformer(values, schema, productEntities);
  const newValues = {...values};

  if (GOAL_TYPE_KEY in schema) {
    delete schema[GOAL_TYPE_KEY];
  }

  if (GOAL_KEY in schema) {
    schema[GOAL_KEY].name = 'Goal Definition';
    schema[GOAL_KEY].order = 1;
  }

  if ('time_aggregation' in schema) {
    schema['time_aggregation'].name = 'Time granularity';
    schema['time_aggregation'].order = 4;
  }

  schema['included_segments_tag'] = {
    name: 'Segment Groups',
    type: ParameterType.__MERGED_SEGMENT_GROUPS,
    key: 'included_segments_tag',
  };
  newValues['included_segments_tag'] = [
    ...(
      (newValues['included_segments_tag'] || '')
        .split(',')
        .map(v => get(SPECIAL_TAGS_TRANSLATIONS, v, v)) || []
    ).filter(i => i),
    ...(newValues['included_segments_signals'] || []),
  ];
  delete schema['included_segments_signals'];

  if (ANALYSIS_MODE_KEY in schema && exists(newValues[ANALYSIS_MODE_KEY])) {
    newValues[ANALYSIS_MODE_KEY] = i18n.t(
      i18n.t(
        TransKeys.ANALYSIS_FORMS.ANALYSIS_101.ANALYSIS_MODES[
          newValues[ANALYSIS_MODE_KEY].toUpperCase()
        ]?.LABEL
      )
    );
  }

  return [newValues, schema];
};

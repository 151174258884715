import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import classes from '../../analysis-forms.module.scss';
import {ExtendedParameters} from '../../../../shared/form/form-layout/extended-parameters/extended-parameters.component';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../../constants/translation-keys';
import {TimeFrameSelector} from '../../components/ui-selectors/time-frame-selector/time-frame-selector.component';
import {SegmentFilterSelector} from '../../components/ui-selectors/segment-filter-selector/segment-filter-selector.component';
import {ParametersFormContext} from '../../../../shared/core/parameters-form/parameters-form.context';
import {startEndDatesDefaultHandler} from '../../../../shared/core/parameters-form/parameters-form-default-handlers.utils';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';
import {SimpleBooleanSelector} from '../../components/ui-selectors/simple-boolean-selector/simple-boolean-selector.component';
import {EntitySelector} from '../../components/ui-selectors/entity-selector/entity-selector.component';
import {TableEntity} from '../../../../../objects/models/table.model';
import {values} from 'lodash';
import {createUndefinedObject, hasError} from '../../../../../utils/general.utils';
import {AnalysisFormProps} from '../../analysis-forms.types';
import {SimpleNumberSelector} from '../../components/ui-selectors/simple-number-selector/simple-number-selector.component';
import pluralize from 'pluralize';
import {ExcludeEventsSelector} from '../../components/ui-selectors/exclude-events-selector/exclude-events-selector.component';
import {TreatmentSelector} from '../../components/ui-selectors/treatment-selector/treatment-selector.component';
import GoalQueryStrategySelector, {
  createInitialGoalQueryByStrategy,
  GoalStrategyPickerSchemaKeysMapping,
  UIGoalType,
} from '../../components/ui-selectors/goal-query-strategy-selector/goal-query-strategy-selector.component';
import {SignalDataType, SignalType} from '../../../../../objects/models/signal.model';
import {ConfoundersSelector} from '../../components/ui-selectors/confounders-selector/confounders-selector.component';

const eventsToExcludeSchemaMapping = {excluded_events: 'excluded_events'};

const treatmentsSelectorMap = {
  treatments_tag: 'event_tag',
  treatments_signals: 'treatments_signals',
};
const confoundersSelectorMap = {
  confounders_tag: 'confounders_tag',
  confounders_signals: 'confounders_signals',
};
const EVENTS_ORDER_SCHEMA_MAPPING = {
  boolean_key: 'is_order_matter',
};
const USE_UNIQUE_SCHEMA_MAPPING = {
  boolean_key: 'is_unique',
};
const N_DAYS_SCHEMA_MAPPING = {
  number_key: 'n_days_after_ref_date',
};
const entitySchemaMapping = {
  entity: 'entity',
};
const segmentFilterSchemaMapping = {
  population_filter: 'population_filter',
};
const FILTER_NOT_ENGAGED_USERS_SCHEMA_MAPPING = {
  boolean_key: 'filter_not_engaging_users',
};
const MAX_LENGTH_SCHEMA_MAPPING = {
  number_key: 'max_length',
};
const FILTER_MIN_ADOPTION_SCHEMA_MAPPING = {
  number_key: 'filter_min_adoption',
};
export const goalQueryStrategySchemaMapping: GoalStrategyPickerSchemaKeysMapping = {
  goal_query: 'goal',
  ui_goal_type: 'ui_goal_type',
};

const TREATMENT_SIGNAL_FILTERS = [
  {type: SignalType.MEASURE, data_type: [SignalDataType.BOOLEAN, SignalDataType.STRING]},
];
const CONFOUNDERS_SIGNAL_FILTERS = [
  {type: SignalType.MEASURE, data_type: [SignalDataType.BOOLEAN, SignalDataType.STRING]},
  {
    type: SignalType.DIMENSION,
    data_type: [
      SignalDataType.STRING,
      SignalDataType.INTEGER,
      SignalDataType.DECIMAL,
      SignalDataType.BOOLEAN,
    ],
  },
];

const ENABLED_STRATEGIES = [UIGoalType.CONVERSION, UIGoalType.RETENTION];

const EXPOSED_PRODUCT_IDS_FOR_OPTIONAL_ADVANCED_PARAMS = [3, 48, 58, 61, 71, 73];

const ACCEPTED_ENTITIES = [TableEntity.USER, TableEntity.TEAM, TableEntity.LEAD];

export const Analysis107Form: React.FC<AnalysisFormProps> = (props: AnalysisFormProps) => {
  const {onSignalInfo, className} = props;
  const [isOpenAdvancedParams, setIsOpenAdvancedParams] = useState(false);
  const {t} = useTranslation();
  const {errors, parameters, changeParametersValue, registerDefaultHandler, removeDefaultHandler} =
    useContext(ParametersFormContext);
  const {getSignalByTag, productEntities, productEntitiesMap, defaultTableEntity, productId} =
    useProductData();
  const entityContext = useMemo(() => parameters[entitySchemaMapping.entity], [parameters]);
  const goalType = useMemo(
    () => parameters[goalQueryStrategySchemaMapping.ui_goal_type],
    [parameters]
  );
  const showOptionalAdvancedParams = useMemo(
    () => EXPOSED_PRODUCT_IDS_FOR_OPTIONAL_ADVANCED_PARAMS.includes(productId),
    [productId]
  );

  useEffect(() => {
    registerDefaultHandler('start_end_dates', startEndDatesDefaultHandler);
    registerDefaultHandler('analysis_107', parameters => {
      const defaults = {};
      defaults[entitySchemaMapping.entity] = defaultTableEntity;
      defaults[entitySchemaMapping.entity] = TableEntity.USER;
      defaults[EVENTS_ORDER_SCHEMA_MAPPING.boolean_key] = true;
      defaults[N_DAYS_SCHEMA_MAPPING.number_key] = 10;
      defaults[USE_UNIQUE_SCHEMA_MAPPING.boolean_key] = false;
      defaults[FILTER_NOT_ENGAGED_USERS_SCHEMA_MAPPING.boolean_key] = false;
      defaults[goalQueryStrategySchemaMapping.ui_goal_type] = UIGoalType.CONVERSION;
      defaults[goalQueryStrategySchemaMapping.goal_query] = createInitialGoalQueryByStrategy(
        goalType,
        getSignalByTag,
        entityContext
      );

      if (showOptionalAdvancedParams) {
        defaults[MAX_LENGTH_SCHEMA_MAPPING.number_key] = 3;
        defaults[FILTER_MIN_ADOPTION_SCHEMA_MAPPING.number_key] = 1;
      }
      return defaults;
    });

    return () => {
      removeDefaultHandler('start_end_dates');
      removeDefaultHandler('analysis_107');
    };
  }, [
    registerDefaultHandler,
    removeDefaultHandler,
    getSignalByTag,
    entityContext,
    goalType,
    defaultTableEntity,
    showOptionalAdvancedParams,
  ]);

  const onChangeEntityContext = useCallback(
    (entity: TableEntity) => {
      const resetKeys = Array.from(
        new Set(['goal', ...values(treatmentsSelectorMap), ...values(segmentFilterSchemaMapping)])
      );
      const resetParameters = createUndefinedObject(resetKeys);
      changeParametersValue({
        [entitySchemaMapping.entity]: entity,
        ...resetParameters,
      });
    },
    [changeParametersValue]
  );
  const pluralEntity = useMemo(
    () => pluralize(productEntitiesMap[entityContext]?.name || '')?.toLowerCase(),
    [productEntitiesMap, entityContext]
  );
  const isOpenAdvancedParamsOrError = useMemo(() => {
    return (
      isOpenAdvancedParams ||
      hasError(errors, [
        ...values(FILTER_NOT_ENGAGED_USERS_SCHEMA_MAPPING),
        ...values(USE_UNIQUE_SCHEMA_MAPPING),
        ...values(N_DAYS_SCHEMA_MAPPING),
        ...values(MAX_LENGTH_SCHEMA_MAPPING),
        ...values(FILTER_MIN_ADOPTION_SCHEMA_MAPPING),
        eventsToExcludeSchemaMapping.excluded_events,
      ])
    );
  }, [isOpenAdvancedParams, errors]);

  return (
    <div className={classNames(classes.AnalysisForm, className)}>
      <EntitySelector
        value={parameters}
        productEntities={productEntities}
        schemaKeysMapping={entitySchemaMapping}
        onChange={v => onChangeEntityContext(v[entitySchemaMapping.entity])}
        accept={ACCEPTED_ENTITIES}
        className={classes.Parameter}
      />
      <GoalQueryStrategySelector
        schemaKeysMapping={goalQueryStrategySchemaMapping}
        title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_107.GOAL.TITLE)}
        subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_107.GOAL.SUB_TITLE)}
        helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_107.GOAL.HELPER_TEXT)}
        errors={errors}
        value={parameters}
        onChange={changeParametersValue}
        onSignalInfo={onSignalInfo}
        entityContext={entityContext}
        enabledStrategies={ENABLED_STRATEGIES}
      />
      <TreatmentSelector
        value={parameters}
        onChange={changeParametersValue}
        className={classes.Parameter}
        schemaKeysMapping={treatmentsSelectorMap}
        errors={errors}
        entityContext={entityContext}
        signalFilters={TREATMENT_SIGNAL_FILTERS}
      />
      <TimeFrameSelector className={classes.Parameter} errors={errors} />
      <SimpleBooleanSelector
        title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_107.IS_ORDER_MATTER.TITLE)}
        subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_107.IS_ORDER_MATTER.SUB_TITLE)}
        helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_107.IS_ORDER_MATTER.HELPER_TEXT)}
        display={'vertical'}
        trueLabel={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_107.IS_ORDER_MATTER.TRUE_LABEL)}
        falseLabel={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_107.IS_ORDER_MATTER.FALSE_LABEL)}
        className={classes.Parameter}
        onChange={changeParametersValue}
        schemaKeysMapping={EVENTS_ORDER_SCHEMA_MAPPING}
        value={parameters}
        errors={errors}
      />
      {entityContext && (
        <SegmentFilterSelector
          onChange={changeParametersValue}
          value={parameters}
          className={classes.Parameter}
          schemaKeysMapping={segmentFilterSchemaMapping}
          entityContext={entityContext}
          errors={errors}
        />
      )}
      <ExtendedParameters
        className={classes.SpaceBottom}
        label={t(TransKeys.GENERAL.LABELS.ADVANCED_PARAMETERS)}
        isOpen={isOpenAdvancedParamsOrError}
        onOpenChanged={() => setIsOpenAdvancedParams(!isOpenAdvancedParams)}
      >
        <ConfoundersSelector
          onChange={changeParametersValue}
          value={parameters}
          schemaKeysMapping={confoundersSelectorMap}
          entityContext={entityContext}
          signalFilters={CONFOUNDERS_SIGNAL_FILTERS}
          className={classes.Parameter}
        />
        <SimpleBooleanSelector
          title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_107.FILTER_NOT_ENGAGING_USERS.TITLE, {
            entity: pluralEntity,
          })}
          subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_107.FILTER_NOT_ENGAGING_USERS.SUB_TITLE, {
            entity: pluralEntity,
          })}
          helperText={t(
            TransKeys.ANALYSIS_FORMS.ANALYSIS_107.FILTER_NOT_ENGAGING_USERS.HELPER_TEXT,
            {entity: pluralEntity}
          )}
          className={classes.Parameter}
          onChange={changeParametersValue}
          schemaKeysMapping={FILTER_NOT_ENGAGED_USERS_SCHEMA_MAPPING}
          value={parameters}
          errors={errors}
        />
        <SimpleBooleanSelector
          title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_107.IS_UNIQUE.TITLE)}
          subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_107.IS_UNIQUE.SUB_TITLE)}
          helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_107.IS_UNIQUE.HELPER_TEXT)}
          className={classes.Parameter}
          onChange={changeParametersValue}
          schemaKeysMapping={USE_UNIQUE_SCHEMA_MAPPING}
          value={parameters}
          errors={errors}
        />
        <SimpleNumberSelector
          title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_107.N_DAYS_AFTER_REF_DATE.TITLE)}
          subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_107.N_DAYS_AFTER_REF_DATE.SUB_TITLE)}
          helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_107.N_DAYS_AFTER_REF_DATE.HELPER_TEXT)}
          className={classes.Parameter}
          onChange={changeParametersValue}
          schemaKeysMapping={N_DAYS_SCHEMA_MAPPING}
          value={parameters}
          errors={errors}
          minValue={1}
          postText={'Days'}
        />
        <ExcludeEventsSelector
          title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_107.EXCLUDED_EVENTS.TITLE)}
          subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_107.EXCLUDED_EVENTS.SUB_TITLE)}
          helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_107.EXCLUDED_EVENTS.HELPER_TEXT)}
          value={parameters}
          onChange={changeParametersValue}
          schemaKeysMapping={eventsToExcludeSchemaMapping}
        />
        {/* if productId is one of taboola's or Ergatta productIds, include the following parameter */}
        {showOptionalAdvancedParams && (
          <>
            <SimpleNumberSelector
              title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_107.MAX_LENGTH.TITLE)}
              subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_107.MAX_LENGTH.SUB_TITLE)}
              helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_107.MAX_LENGTH.HELPER_TEXT)}
              className={classes.Parameter}
              value={parameters}
              onChange={changeParametersValue}
              schemaKeysMapping={MAX_LENGTH_SCHEMA_MAPPING}
              errors={errors}
              minValue={2}
              maxValue={10}
            />
            <SimpleNumberSelector
              title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_107.FILTER_MIN_ADOPTION.TITLE)}
              subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_107.FILTER_MIN_ADOPTION.SUB_TITLE)}
              helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_107.FILTER_MIN_ADOPTION.HELPER_TEXT)}
              className={classes.Parameter}
              value={parameters}
              onChange={changeParametersValue}
              schemaKeysMapping={FILTER_MIN_ADOPTION_SCHEMA_MAPPING}
              errors={errors}
              minValue={0}
              maxValue={100}
            />
          </>
        )}
      </ExtendedParameters>
    </div>
  );
};

import {useCallback, useState} from 'react';
import {
  ANALYSIS_RESULT_ID_PATH_PARAM,
  EXPERIMENT_ID_PATH_PARAM,
} from '../../../../constants/app-routes';
import {useDispatch, useSelector} from 'react-redux';
import {FancyHeader, FunnelIcon, ModalLayout} from 'ui-components';
import classes from './rename-experiment-panel.module.scss';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors';
import {ActionKey} from '../../../../constants/action-key';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {composition} from 'front-core';
import {withDisableDemoProduct} from '../../../../core/hoc/with-disable-demo-product.hoc';
import {Experiment} from '../../../../objects/models/experiment.model';
import {patchExperiment} from 'src/store/experiments/experiments.actions';
import {ExperimentInfoTab} from '../experiment-form-panel/components/experiment-info-tab/experiment-info-tab.component';
import {getExperimentNetworkRequest} from 'src/http/experiments.network-requests';

interface OwnProps {
  onClose?: () => void;
  panelId?: string;
  disabled?: boolean;
  experiment: Experiment;
  [ANALYSIS_RESULT_ID_PATH_PARAM]: string;
}

type AllProps = OwnProps;

const RENAME_EXPERIMENT_PANEL__EXPERIMENT = SharedSelectionKeys.RENAME_EXPERIMENT_PANEL__EXPERIMENT;

const RenameExperimentPanelComponent = (props: OwnProps) => {
  const {onClose, experiment, disabled} = props;
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [formData] = useState({
    ...experiment,
  });
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(ActionKey.REQUEST_ANALYSIS)(state)
  );

  const onSubmit = useCallback(
    data => {
      dispatch(patchExperiment({id: experiment.id, ...data}, {onSuccess: () => onClose()}));
    },
    [dispatch, onClose, experiment]
  );

  return (
    <div className={classes.RenameExperimentPanelContainer}>
      <ModalLayout>
        {isLoading && <GenericLoading />}
        <div className={classes.RenameExperimentPanel}>
          <FancyHeader
            title={'Rename Experiment'}
            icon={FunnelIcon}
            onClose={onClose}
            className={classes.Header}
          />
          <div className={classes.Content}>
            <ExperimentInfoTab
              submitText="Save"
              disabled={disabled}
              data={formData}
              onSubmit={data => onSubmit(data)}
            />
          </div>
        </div>
      </ModalLayout>
    </div>
  );
};

export const RenameExperimentPanel = composition<AllProps>(
  RenameExperimentPanelComponent,
  withDisableDemoProduct,
  withLoadBefore({
    experiment: {
      selectedKey: RENAME_EXPERIMENT_PANEL__EXPERIMENT,
      actionKey: RENAME_EXPERIMENT_PANEL__EXPERIMENT,
      request: getExperimentNetworkRequest,
      mapPayloadFromProps: props => props[EXPERIMENT_ID_PATH_PARAM],
      shouldCall: props => props[EXPERIMENT_ID_PATH_PARAM] !== undefined,
    },
  })
);

import {action, OnSuccessActionHook} from 'front-core';
import {AnalysisFolderDTO, MoveAnalysisFolderDTO} from '../../objects/dto/analysis-folder.dto';

export enum AnalysisFolderActionType {
  CREATE_ANALYSIS_FOLDER = '@@analysis-folders/CREATE_ANALYSIS_FOLDER',
  UPDATE_ANALYSIS_FOLDER = '@@analysis-folders/UPDATE_ANALYSIS_FOLDER',
  DELETE_ANALYSIS_FOLDER = '@@analysis-folders/DELETE_ANALYSIS_FOLDER',
  MOVE_ANALYSIS_FOLDER = '@@analysis-folders/MOVE_ANALYSIS_FOLDER',
  CHANGE_ANALYSIS_FOLDER_ICON = '@@analysis-folders/CHANGE_ANALYSIS_FOLDER_ICON',
}

export const createAnalysisFolder = (data: AnalysisFolderDTO, onSuccess?: OnSuccessActionHook) =>
  action(AnalysisFolderActionType.CREATE_ANALYSIS_FOLDER, data, {onSuccess});

export const updateAnalysisFolder = (data: AnalysisFolderDTO, onSuccess?: OnSuccessActionHook) =>
  action(AnalysisFolderActionType.UPDATE_ANALYSIS_FOLDER, data, {onSuccess});

export const deleteAnalysisFolder = (id: number) =>
  action(AnalysisFolderActionType.DELETE_ANALYSIS_FOLDER, id);

export const moveAnalysisFolder = (data: MoveAnalysisFolderDTO, onSuccess?: OnSuccessActionHook) =>
  action(AnalysisFolderActionType.MOVE_ANALYSIS_FOLDER, data, {onSuccess});

export const changeAnalysisFolderIcon = (id: number, icon: string) =>
  action(AnalysisFolderActionType.CHANGE_ANALYSIS_FOLDER_ICON, {id, icon});

import * as React from 'react';
import {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {getSlackChannelsNetworkRequest} from '../../../../http/slack-channels.network-requests';
import classes from './homepage-subscription-panel.module.scss';
import {Button, FancyHeader, ModalLayout, RSSIcon, useRemoteSourceStated} from 'ui-components';
import {FormProvider, useForm} from 'react-hook-form';
import yup from '../../../../config/yup.config';
import {
  AnomalyMode,
  HomepageSubscriptionUnit,
  HomepageSettings,
  HomepageSubscribedSegmentsType,
  HomepageSubscription,
  HomepageSubscriptionPushType,
  HomepageSubscriptionVIA,
} from '../../../../objects/models/homepage.model';
import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {SlackChannel} from '../../../analyses/panels/share-resource-form-panel/components/share-direct-slack-form-fields/share-direct-slack-form-fields.component';
import {useDispatch, useSelector} from 'react-redux';
import {updateHomepageSubscription} from '../../../../store/homepage/homepage.actions';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors';
import {ActionKey} from '../../../../constants/action-key';
import {
  getHomepageSettingsNetworkRequest,
  getHomepageSubscriptionNetworkRequest,
} from '../../../../http/homepage.network-requests';
import {composition} from 'front-core';
import {withModalInactiveSourceHandler} from '../../../../core/hoc/with-modal-inactive-source-handler.hoc';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {HOMEPAGE_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {keys, values} from 'lodash';
import {multiLoaderNetworkRequest} from '../../../../http/multi-loader.network-requests';
import {withDisableDemoProduct} from '../../../../core/hoc/with-disable-demo-product.hoc';
import {Team} from '../../../../objects/models/team.model';
import {HomepageName} from './components/homepage-name/homepage-name.component';
import {
  SubscriptionMetricsConfig,
  SubscriptionMetricsConfigProps,
} from './components/subscription-metrics-config/subscription-metrics-config.component';
import {useFeatureIsOn} from '@growthbook/growthbook-react';
import {FeatureFlag} from '../../../../constants/feature-flags';
import {SubscriptionConfig} from './components/subscription-config/subscription-config.component';

interface OwnProps {
  onClose: () => void;
  panelId?: string;
  [HOMEPAGE_ID_PATH_PARAM]: number;
  homepageSettings: HomepageSettings;
  data: HomepageSubscription;
  channels?: SlackChannel[];
  disabled?: boolean;
}

type AllProps = OwnProps;

const HOMEPAGE_PRODUCT_SLACK_CHANNELS_SELECTED_KEY = 'HOMEPAGE/PRODUCT_SLACK_CHANNELS';
const HOMEPAGE_SUBSCRIPTION_SELECTED_KEY = 'HOMEPAGE/HOMEPAGE_SUBSCRIPTION';
const HOMEPAGE_SETTINGS_SELECTED_KEY = 'HOMEPAGE/HOMEPAGE_SETTINGS';

const homepageSubscriptionPanelDTOValidator = yup.object().shape({
  isActive: yup.boolean().required(),
  via: yup
    .array()
    .of(yup.string().oneOf(values(HomepageSubscriptionVIA)))
    .when('isActive', {
      is: true,
      then: yup.array().min(1),
      otherwise: yup.array(),
    }),
  slackChannels: yup
    .array()
    .of(yup.string())
    .test({
      name: 'slack_channels_required',
      test: function (value, context) {
        const via = context.parent.via;
        if (!via.includes(HomepageSubscriptionVIA.SLACK)) {
          return true;
        }
        if (value.length === 0) {
          return this.createError({
            message: 'Required when Slack is selected',
          });
        }
        return true;
      },
    }),
  anomalyMode: yup.string().required().oneOf(values(AnomalyMode)),
  anomalyThreshold: yup.number().nullable(),
  subscribedSegmentsType: yup.string().required().oneOf(values(HomepageSubscribedSegmentsType)),
  weeklyMetricSettings: yup.lazy(obj => {
    const validationSchema = {};
    for (const metricId of keys(obj)) {
      validationSchema[metricId] = yup
        .string()
        .required()
        .oneOf(values(HomepageSubscriptionPushType));
    }
    return yup.object().shape(validationSchema).required();
  }),
  dailyMetricSettings: yup.lazy(obj => {
    const validationSchema = {};
    for (const metricId of keys(obj)) {
      validationSchema[metricId] = yup
        .string()
        .required()
        .oneOf([
          HomepageSubscriptionPushType.SIGNIFICANT_CHANGE,
          HomepageSubscriptionPushType.NEVER,
        ]);
    }
    return yup.object().shape(validationSchema).required();
  }),
});

const HomepageSubscriptionPanelComponent: React.FC<AllProps> = (props: AllProps) => {
  const {
    [HOMEPAGE_ID_PATH_PARAM]: homepageId,
    data,
    channels,
    homepageSettings,
    onClose,
    disabled,
  } = props;
  const showSubscriptionDailyConfig = useFeatureIsOn(
    FeatureFlag.SUBSCRIPTION_DAILY_ALERT as string
  );
  const {teams} = useProductData();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const contentRef = useRef<HTMLDivElement>(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const {
    source: metrics,
    exec: getMetrics,
    isLoading: isLoadingMetrics,
  } = useRemoteSourceStated({
    networkRequest: multiLoaderNetworkRequest,
    transformer: response => response.metrics,
  });
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: data,
    resolver: yupResolver(homepageSubscriptionPanelDTOValidator),
  });
  const {handleSubmit} = formMethods;
  const selectedTeam: Team = useMemo(
    () => teams.find(t => t.homepageId === homepageId),
    [homepageId, teams]
  );

  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(ActionKey.UPDATE_HOMEPAGE_SUBSCRIPTION)(state)
  );

  const onSubmit = useCallback(
    data => dispatch(updateHomepageSubscription({...data, homepageId}, onClose)),
    [dispatch, onClose, homepageId]
  );

  const subscriptionConfigSharedProps: Omit<
    SubscriptionMetricsConfigProps,
    'homepageSubscriptionUnit'
  > = useMemo(
    () => ({
      isLoadingMetrics,
      metrics,
      homepageSettings,
    }),
    [isLoadingMetrics, metrics, homepageSettings]
  );

  useEffect(() => {
    getMetrics({
      metrics: homepageSettings.metrics,
    });
  }, [getMetrics, homepageSettings]);
  const containerRefCurrent = contentRef.current;
  useLayoutEffect(() => {
    if (containerRefCurrent) {
      const onScroll = () => {
        setIsScrolled(containerRefCurrent.scrollTop > 0);
      };
      containerRefCurrent.addEventListener('scroll', onScroll);
      return () => {
        containerRefCurrent.removeEventListener('scroll', onScroll);
      };
    }
  }, [containerRefCurrent]);

  return (
    <div className={classes.HomepageSubscriptionPanelContainer}>
      <ModalLayout
        footer={
          <div className={classes.Footer}>
            <Button onClick={onClose} variant={'outlined'}>
              {t(TransKeys.GENERAL.ACTIONS.CANCEL)}
            </Button>
            <Button disabled={disabled} onClick={handleSubmit(onSubmit)}>
              {t(TransKeys.GENERAL.ACTIONS.SAVE)}
            </Button>
          </div>
        }
      >
        {isLoading && <GenericLoading />}
        <FancyHeader icon={RSSIcon} title={t(TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.HEADER_TITLE)} />
        <FormProvider {...formMethods}>
          <div className={classes.Content} ref={contentRef}>
            <HomepageName selectedTeam={selectedTeam} />
            <div className={classes.ContentBody}>
              <SubscriptionConfig
                isScrolled={isScrolled}
                channels={channels}
                selectedTeam={selectedTeam}
              />
              <SubscriptionMetricsConfig
                {...subscriptionConfigSharedProps}
                homepageSubscriptionUnit={HomepageSubscriptionUnit.WEEKLY}
              />
              {showSubscriptionDailyConfig && (
                <SubscriptionMetricsConfig
                  {...subscriptionConfigSharedProps}
                  homepageSubscriptionUnit={HomepageSubscriptionUnit.DAILY}
                />
              )}
            </div>
          </div>
        </FormProvider>
      </ModalLayout>
    </div>
  );
};

export const HomepageSubscriptionPanel = composition<AllProps>(
  HomepageSubscriptionPanelComponent,
  withModalInactiveSourceHandler,
  withDisableDemoProduct,
  withLoadBefore({
    channels: {
      selectedKey: HOMEPAGE_PRODUCT_SLACK_CHANNELS_SELECTED_KEY,
      actionKey: HOMEPAGE_PRODUCT_SLACK_CHANNELS_SELECTED_KEY,
      request: getSlackChannelsNetworkRequest,
    },
    homepageSettings: {
      selectedKey: HOMEPAGE_SETTINGS_SELECTED_KEY,
      actionKey: HOMEPAGE_SETTINGS_SELECTED_KEY,
      request: getHomepageSettingsNetworkRequest,
      mapPayloadFromProps: props => props[HOMEPAGE_ID_PATH_PARAM],
    },
    data: {
      selectedKey: HOMEPAGE_SUBSCRIPTION_SELECTED_KEY,
      actionKey: HOMEPAGE_SUBSCRIPTION_SELECTED_KEY,
      request: getHomepageSubscriptionNetworkRequest,
      mapPayloadFromProps: props => props[HOMEPAGE_ID_PATH_PARAM],
    },
  })
);

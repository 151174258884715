import * as React from 'react';
import classNames from 'classnames';
import classes from './threads-list.module.scss';
import {AIConversationThread} from '../../../../../../objects/models/ai-chat.model';
import {AddRegularIcon, CommentRegularIcon, TooltipIfOverflow} from 'ui-components';
import {useMemo} from 'react';
import moment from 'moment';
import {TIME_FORMATS} from '../../../../../../constants/time-formats';
import {exists} from 'front-core';

interface OwnProps {
  threads: AIConversationThread[];
  onSelectThread: (threadId: number) => void;
  onNewSession: () => void;
  selectedThreadId?: number;
  isLoading?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const ThreadsList: React.FC<AllProps> = (props: AllProps) => {
  const {threads, selectedThreadId, onSelectThread, onNewSession, className} = props;
  const sessionGroups = useMemo(() => {
    const res = {};
    threads.forEach(t => {
      const date = moment(t.createdOn).format('YYYY-MM-DD');
      if (!res[date]) {
        res[date] = [];
      }
      res[date].push(t);
    });
    const groups = [];
    Object.keys(res).forEach(k => {
      const date = moment(k, 'YYYY-MM-DD');
      let groupLabel = '';
      if (date.isSame(moment(), 'day')) {
        groupLabel = 'today';
      } else if (date.isSame(moment().subtract(1, 'day'), 'day')) {
        groupLabel = 'yesterday';
      }
      groups.push({
        date: groupLabel ? groupLabel : date.format(TIME_FORMATS.READABLE_DATE_NO_YEAR),
        threads: res[k],
      });
    });
    return groups;
  }, [threads]);

  return (
    <div className={classNames(classes.ThreadsList, className)}>
      <div
        className={classNames(
          classes.CreateNewSession,
          !exists(selectedThreadId) && classes.Disabled
        )}
        onClick={!exists(selectedThreadId) ? undefined : () => onNewSession()}
      >
        <AddRegularIcon className={classes.AddIcon} />
        New Session
      </div>
      {sessionGroups.map((g, idx) => (
        <div key={idx} className={classes.Group}>
          <div className={classes.GroupTitle}>{g.date}</div>
          {g.threads.map(t => (
            <div
              key={t.id}
              onClick={() => onSelectThread(t.id)}
              className={classNames(classes.Thread, selectedThreadId === t.id && classes.Selected)}
            >
              <CommentRegularIcon className={classes.Icon} />
              <TooltipIfOverflow title={t.title}>
                <span className={classes.Title}>{t.title || 'New session'}</span>
              </TooltipIfOverflow>
            </div>
          ))}
        </div>
      ))}
      {/*{threads.map(t => (*/}
      {/*  */}
      {/*))}*/}
    </div>
  );
};

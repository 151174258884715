import yup from '../../../../../config/yup.config';
import {PARAMETERS_METADATA_KEY} from '../../../../../constants/parameters-saved-keys';
import {queryElementValidatorFactory} from '../../../../../objects/dto/query-builder.dto';
import {startEndDatesValidator} from '../shared-validators';

export const analysis111ParametersValidator = yup.object().shape({
  parameters: yup
    .object()
    .shape({
      [PARAMETERS_METADATA_KEY]: yup.object().nullable(),
      funnel: yup.number().required(),
      population_filter: queryElementValidatorFactory(false),
      linearity: yup.boolean().required(),
    })
    .required(),
  runParameters: startEndDatesValidator.required(),
});

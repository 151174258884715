import {Select, TextInput} from 'ui-components';
import classes from './base-payment-retention-builder.module.scss';
import classNames from 'classnames';
import {limitNumberValue} from '../../../../../utils/general.utils';
import {TableEntity} from '../../../../../objects/models/table.model';
import {MilestoneType} from 'src/objects/models/milestone.model';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';
import {MilestoneInput} from '../components/milestone-input/milestone-input.component';
import {EDITABLE_MILESTONE_TYPES} from '../../../../../objects/models/milestone.model';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';

interface SchemaKeysMapping {
  plan: string;
  goal_bound_unit: string;
  goal_bound_n: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  plan: 'plan',
  goal_bound_unit: 'goal_bound_unit',
  goal_bound_n: 'goal_bound_n',
};

export interface SignalDependenciesMapping {
  planSignalId: any;
  firstPaymentSignalId: any;
  churnSignalId: any;
}

interface OwnProps {
  value: any;
  entityContext: TableEntity;
  schemaKeysMapping?: SchemaKeysMapping;
  onChange?: (parameters: any) => void;
  onMilestoneCreate?: (milestoneType: MilestoneType) => void;
  signalDependencies?: SignalDependenciesMapping;
  signalDependenciesErrors?: SignalDependenciesMapping;
  errors?: any;
  disabled?: any;
  className?: string;
}

type AllProps = OwnProps;

const unitOptions = {
  options: [
    {
      value: 'month',
      label: 'Months',
    },
    {
      value: 'year',
      label: 'Years',
    },
  ],
};
const planOptions = {
  options: [
    {
      value: '1y',
      label: 'Yearly',
    },
    {
      value: '1m',
      label: 'Monthly',
    },
    {
      value: 'otp',
      label: 'One-time payment',
    },
  ],
};
const MIN_COUNT_VALUE = 1;
const MAX_COUNT_VALUE = 50;

export const BasePaymentRetentionBuilder: React.FC<AllProps> = (props: AllProps) => {
  const {
    value,
    errors,
    onChange,
    onMilestoneCreate,
    signalDependencies,
    signalDependenciesErrors,
    entityContext,
    schemaKeysMapping,
    disabled,
    className,
  } = props;
  const {t} = useTranslation();
  const {getEntityName} = useProductData();

  const planMilestoneId = signalDependencies.planSignalId;
  const firstPaymentMilestoneId = signalDependencies.firstPaymentSignalId;
  const churnMilestoneId = signalDependencies.churnSignalId;

  const hasUnavailableMilestones =
    (!planMilestoneId && !EDITABLE_MILESTONE_TYPES.includes(MilestoneType.PLAN)) ||
    (!firstPaymentMilestoneId && !EDITABLE_MILESTONE_TYPES.includes(MilestoneType.FIRST_PAYMENT)) ||
    (!churnMilestoneId && !EDITABLE_MILESTONE_TYPES.includes(MilestoneType.CHURN));

  return (
    <div className={classNames(classes.BasePaymentRetentionBuilder, className)}>
      {hasUnavailableMilestones && (
        <div className={classes.MissingRequiredMilestones}>
          {t(TransKeys.QUERY_BUILDERS.PAYMENT_RETENTION.REQUIRED_MILESTONES_MISSING)}
        </div>
      )}
      <div className={classes.Row}>
        <span>{getEntityName(entityContext, true)} with a </span>
        <Select
          error={Boolean(errors?.[schemaKeysMapping.plan])}
          value={value?.[schemaKeysMapping.plan]}
          onChange={value => onChange({[schemaKeysMapping.plan]: value})}
          options={planOptions}
          capitalize={false}
          searchable={false}
          clearable={false}
          disabled={disabled}
        />
        <MilestoneInput
          entityContext={entityContext}
          milestoneType={MilestoneType.PLAN}
          milestoneId={planMilestoneId}
          onCreate={() => onMilestoneCreate(MilestoneType.PLAN)}
          disabled={disabled || !EDITABLE_MILESTONE_TYPES.includes(MilestoneType.PLAN)}
          error={Boolean(signalDependenciesErrors.planSignalId)}
        />
      </div>
      <div className={classes.Row}>
        <span>
          who did <b>not</b>
        </span>
        <MilestoneInput
          entityContext={entityContext}
          milestoneType={MilestoneType.CHURN}
          milestoneId={churnMilestoneId}
          onCreate={() => onMilestoneCreate(MilestoneType.CHURN)}
          disabled={disabled || !EDITABLE_MILESTONE_TYPES.includes(MilestoneType.CHURN)}
          error={Boolean(signalDependenciesErrors.churnSignalId)}
        />
      </div>
      <div className={classes.Row}>
        <div className={classes.Row}>
          within
          <TextInput
            className={classes.Input}
            error={Boolean(errors?.[schemaKeysMapping.goal_bound_n])}
            value={value?.[schemaKeysMapping.goal_bound_n]}
            onChange={value =>
              onChange({
                [schemaKeysMapping.goal_bound_n]: limitNumberValue(
                  value as any,
                  MIN_COUNT_VALUE,
                  MAX_COUNT_VALUE
                ),
              })
            }
            disabled={disabled}
            type={'number'}
          />
          <Select
            error={Boolean(errors?.[schemaKeysMapping.goal_bound_unit])}
            value={value?.[schemaKeysMapping.goal_bound_unit]}
            onChange={value => onChange({[schemaKeysMapping.goal_bound_unit]: value})}
            options={unitOptions}
            capitalize={false}
            searchable={false}
            clearable={false}
            disabled={disabled}
          />
        </div>
        since
        <MilestoneInput
          entityContext={entityContext}
          milestoneType={MilestoneType.FIRST_PAYMENT}
          milestoneId={firstPaymentMilestoneId}
          onCreate={() => onMilestoneCreate(MilestoneType.FIRST_PAYMENT)}
          disabled={disabled || !EDITABLE_MILESTONE_TYPES.includes(MilestoneType.FIRST_PAYMENT)}
          error={Boolean(signalDependenciesErrors.firstPaymentSignalId)}
        />
      </div>
    </div>
  );
};

BasePaymentRetentionBuilder.defaultProps = {
  schemaKeysMapping: DEFAULT_SCHEMA_KEYS_MAPPING,
};

import classNames from 'classnames';
import {ReactNode} from 'react';
import classes from './list-row-item-cell.module.scss';

type ListRowItemCellProps = {
  title?: ReactNode;
  content: ReactNode;
  className?: string;
};

export const ListRowItemCell = (props: ListRowItemCellProps) => {
  const {title, content, className} = props;
  return (
    <div className={classNames(classes.ListRowItemCell, className)}>
      {title && <div className={classes.Title}>{title}</div>}
      <div className={classes.Content}>{content}</div>
    </div>
  );
};

import React, {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useForm, FormProvider, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import Yup from 'yup';
import type Lazy from 'yup/lib/Lazy';
import {ActionKey} from '../../../../../constants/action-key';
import {getReducedLoadingStateSelector} from '../../../../../store/store.selectors';
import {AnyAction} from 'redux';
import {ActionHooks} from 'front-core';
import classes from './cron-panel-form.module.scss';
import {Button, FancyHeader, ModalLayout} from 'ui-components';
import classNames from 'classnames';
import {getTimezone, preventSubmitOnEnter} from '../../../../../utils/general.utils';
import {FormHiddenInputs} from '../../../form/components/form-hidden-inputs.component';
import {GenericLoading} from '../generic-loading/generic-loading.component';
import CronExpGroupControl from './cron-exp-group-control/cron-exp-group-control.component';
import {isArray} from 'lodash';

export interface DeleteScheduleAnalysisDataParam {
  analysisId: number;
  analysisResultId: number;
}

export interface DeleteExperimentSubscriptionDataParam {
  experimentId: number;
}

export interface DeleteHealthMonitorSubscriptionDataParam {
  metricHealthMonitorId: number;
}

type onActionHandler = (data: any, hooks?: ActionHooks) => AnyAction;

interface ActionsHandlers {
  edit: onActionHandler;
  create: onActionHandler;
  delete: onActionHandler;
}

interface Texts {
  title: string;
  subTitleCta: string | string[];
  deleteBtn: string;
  submitBtn: string;
  modalBody?: string;
}

interface OwnProps {
  onClose: () => void;
  panelId?: string;
  defaultValues: Record<string, any>;
  resolverSchema: Yup.AnyObjectSchema | Lazy<any>;
  loadingKeys: ActionKey[];
  editMode: boolean;
  actionsHandlers: ActionsHandlers;
  onDeleteDataParam:
    | DeleteScheduleAnalysisDataParam
    | DeleteExperimentSubscriptionDataParam
    | DeleteHealthMonitorSubscriptionDataParam;
  texts: Texts;
  showModalFooter: boolean;
  showForm: boolean;
  formBodyClasses?: string;
  containerClasses?: string;
  formHiddenInputsNames: string[];
  additionalInfo?: JSX.Element;
  icon?: any;
  showWeekly?: boolean;
  showMonthly?: boolean;
  showDaily?: boolean;
  disabled?: boolean;
}

type AllProps = OwnProps;

const CronPanelFormComponent: React.FC<AllProps> = (props: AllProps) => {
  const {
    onClose,
    defaultValues: defaultValuesFromProps,
    resolverSchema,
    loadingKeys,
    editMode,
    actionsHandlers,
    onDeleteDataParam,
    texts,
    showModalFooter,
    showForm,
    formBodyClasses,
    containerClasses,
    formHiddenInputsNames,
    additionalInfo,
    icon,
    showWeekly,
    showMonthly,
    showDaily,
    disabled,
  } = props;
  const dispatch = useDispatch();
  const defaultValues = useMemo(
    () => ({...defaultValuesFromProps, timezone: getTimezone()}),
    [defaultValuesFromProps]
  );
  const formMethods = useForm({
    defaultValues,
    resolver: yupResolver(resolverSchema, {abortEarly: false}),
  });

  const {handleSubmit} = formMethods;

  const isLoading = useSelector(state => getReducedLoadingStateSelector(...loadingKeys)(state));

  const onSubmit = useCallback(
    data => {
      const action = editMode ? actionsHandlers.edit : actionsHandlers.create;

      return dispatch(
        action(data, {
          onSuccess: () => {
            onClose();
          },
        })
      );
    },
    [dispatch, onClose, editMode, actionsHandlers]
  );

  const onDelete = useCallback(
    () =>
      dispatch(
        actionsHandlers.delete(onDeleteDataParam, {
          onSuccess: () => {
            onClose();
          },
        })
      ),
    [dispatch, onClose, actionsHandlers, onDeleteDataParam]
  );

  const renderModalFooter = () => {
    if (!showModalFooter) {
      return null;
    }

    return (
      <div className={classes.Footer}>
        {editMode && (
          <Button
            disabled={disabled}
            className={classes.DeleteBtn}
            variant={'outlined'}
            onClick={onDelete}
          >
            {texts.deleteBtn}
          </Button>
        )}
        <Button
          disabled={disabled}
          className={classNames(editMode && classes.Btn)}
          onClick={handleSubmit(onSubmit)}
        >
          {texts.submitBtn}
        </Button>
      </div>
    );
  };

  const renderModalBody = () => {
    if (!showForm) {
      return (
        <div className={classNames(classes.Body, formBodyClasses)}>
          <span>{texts.modalBody}</span>
        </div>
      );
    }

    const renderSubTitleCtas = () => {
      const ctasToRender = !isArray(texts.subTitleCta) ? [texts.subTitleCta] : texts.subTitleCta;
      return (
        <div className={classes.SubTitlesCtasContainer}>
          {ctasToRender.map((cta, idx) => (
            <div key={`${cta}_${idx}`}>{cta}</div>
          ))}
        </div>
      );
    };

    return (
      <>
        {isLoading && <GenericLoading />}
        <FormProvider {...formMethods}>
          <form
            className={classes.Body}
            onKeyDown={preventSubmitOnEnter}
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormHiddenInputs names={formHiddenInputsNames} />
            {renderSubTitleCtas()}
            <Controller
              render={({field}) => (
                <CronExpGroupControl
                  value={field.value}
                  onChange={field.onChange}
                  showWeekly={showWeekly}
                  showMonthly={showMonthly}
                  showDaily={showDaily}
                  className={classes.CronExpGroupControl}
                />
              )}
              name={'cronExp'}
            />
            {additionalInfo && additionalInfo}
          </form>
        </FormProvider>
      </>
    );
  };

  return (
    <div
      className={classNames(
        classes.Container,
        !showModalFooter && classes.NoFooter,
        containerClasses
      )}
    >
      <ModalLayout footer={renderModalFooter()}>
        <div className={classes.CronForm}>
          <FancyHeader
            title={texts.title}
            icon={icon}
            onClose={onClose}
            className={classes.CronFormHeader}
          />
          <div className={classes.ModalBodyWrapper}>{renderModalBody()}</div>
        </div>
      </ModalLayout>
    </div>
  );
};

export default CronPanelFormComponent;

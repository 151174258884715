import {useContext} from 'react';
import {composition} from 'front-core';
import {connector, ConnectorInjectedProps} from 'front-core';
import {coreLinker, CoreViewModel} from '../../core.linker';
import AppConfirmation from './app-confirmation.component';
import {resolveConfirmationMessage} from '../../../../store/interface/interface.actions';
import {ConfirmationDialogContext} from '../../../../core/contexts/confirmation-dialog.context';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';

interface OwnProps {}

type AllProps = OwnProps & CoreViewModel & ConnectorInjectedProps;

const AppConfirmationManagerComponent: React.FC<AllProps> = (props: AllProps) => {
  const {dispatch, confirmationMessage: cmFromStore} = props;

  const notify = useAmplitude();
  const {confirmationDialog: cmFromContext, setConfirmationDialog} =
    useContext(ConfirmationDialogContext);

  // Display confirmation dialog from store
  if (cmFromStore) {
    return (
      <AppConfirmation
        title={cmFromStore.title}
        content={cmFromStore.content}
        approveBtn={cmFromStore.approveBtn}
        rejectBtn={cmFromStore.rejectBtn}
        onApprove={() => {
          notify(AmplitudeEvent.CONFIRMATION_DIALOG_APPROVED, {
            title: cmFromStore.title,
            content: cmFromStore.content,
          });
          dispatch(resolveConfirmationMessage(true));
        }}
        onReject={() => {
          notify(AmplitudeEvent.CONFIRMATION_DIALOG_REJECTED, {
            title: cmFromStore.title,
            content: cmFromStore.content,
          });
          dispatch(resolveConfirmationMessage(false));
        }}
      />
    );
  }

  // Display confirmation dialog from context
  if (cmFromContext) {
    return (
      <AppConfirmation
        title={cmFromContext.title}
        content={cmFromContext.content}
        approveBtn={cmFromContext.approveBtn}
        rejectBtn={cmFromContext.rejectBtn}
        onApprove={() => {
          notify(AmplitudeEvent.CONFIRMATION_DIALOG_APPROVED, {
            title: cmFromContext.title,
            content: cmFromContext.content,
          });
          cmFromContext.onApprove && cmFromContext.onApprove();
          setConfirmationDialog(undefined);
        }}
        onReject={() => {
          notify(AmplitudeEvent.CONFIRMATION_DIALOG_REJECTED, {
            title: cmFromContext.title,
            content: cmFromContext.content,
          });
          cmFromContext.onReject && cmFromContext.onReject();
          setConfirmationDialog(undefined);
        }}
      />
    );
  }

  // No confirmation dialog
  return null;
};

export const AppConfirmationManager = composition<OwnProps>(
  AppConfirmationManagerComponent,
  connector<CoreViewModel>(coreLinker)
);

import * as React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import classes from '../analysis-types-follow-up-panel.module.scss';
import {Signal} from '../../../../../../objects/models/signal.model';
import TransKeys from 'translations';
import {Select} from 'ui-components';
import {useTranslation} from 'react-i18next';
import {useMemo} from 'react';

const DEFAULT_SEGMENTS_OPTIONS = [
  {
    label: 'All Segments',
    value: null,
  },
];

interface OwnProps {
  segment: Signal;
  segmentClass?: string[];
  isLoading: boolean;
}

type AllProps = OwnProps;

const AnalysisTypesFollowUpPanelSelectSegment: React.FC<AllProps> = (props: AllProps) => {
  const {segment, segmentClass, isLoading} = props;
  const {t} = useTranslation();
  const {control} = useFormContext();
  const segmentsOptions = useMemo(() => {
    const segmentClassString = segmentClass
      ? `→ ${(segmentClass || []).map(s => `${s}`).join(' or ')}`
      : '';
    return {
      options: [
        {
          label: `${segment.name} ${segmentClassString}`,
          value: segment.id,
        },
        ...DEFAULT_SEGMENTS_OPTIONS,
      ],
    };
  }, [segment, segmentClass]);
  return (
    <>
      <span>{t(TransKeys.GENERAL.LABELS.FOR)}</span>
      <Controller
        name={'segment'}
        control={control}
        render={({field}) => (
          <Select
            value={field.value}
            onChange={field.onChange}
            options={segmentsOptions}
            disabled={isLoading}
            dropdownButtonClassName={classes.DropdownButtonClassName}
            searchable={false}
            clearable={false}
            sortValues={false}
          />
        )}
      />
    </>
  );
};

export default AnalysisTypesFollowUpPanelSelectSegment;

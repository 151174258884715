import * as React from 'react';
import {ParameterInputWrapper} from '../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../../../constants/translation-keys';
import {AnalysisSelectorProps} from '../analysis-selector.types';
import {TableEntity, TableEntityBinding} from '../../../../../../objects/models/table.model';
import {useCallback, useMemo} from 'react';
import {get} from 'lodash';
import {useQueryArray} from 'ui-components';
import {ItemList} from '../../../../../shared/core/query-builders/components/item-list/item-list.component';
import {
  SignalSmartSelector,
  SignalSmartSelectorKey,
} from '../../../../../shared/core/smart-selector/signal-smart-selector.component';

interface SchemaKeysMapping {
  attributes: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  attributes: 'attributes',
};

interface OwnProps extends AnalysisSelectorProps {
  schemaKeysMapping?: SchemaKeysMapping;
  entityContext: TableEntity;
  onSignalInfo?: (value: number) => void;
}

type AllProps = OwnProps;

const createGroupByFilters = entityContext => ({
  tag: ['entity_property', 'segment'],
  entityContext: entityContext,
  entityBinding: TableEntityBinding.ONE_WAY,
});

export const AttributesSelector: React.FC<AllProps> = (props: AllProps) => {
  const {
    title,
    subTitle,
    helperText,
    onChange,
    value,
    schemaKeysMapping,
    entityContext,
    onSignalInfo,
    errors,
    className,
  } = props;
  const filters = useMemo(() => createGroupByFilters(entityContext), [entityContext]);
  const {t} = useTranslation();
  const {addElement, removeElement} = useQueryArray(
    get(value, schemaKeysMapping.attributes, []),
    data => onChange({[schemaKeysMapping.attributes]: data}),
    () => null
  );
  const onSegmentsChange = useCallback(
    (pValue: any, idx: number) => {
      const existing = get(value, schemaKeysMapping.attributes, []) || [];
      const actions = [...existing];
      actions[idx] = pValue;
      onChange({[schemaKeysMapping.attributes]: actions});
    },
    [onChange, schemaKeysMapping, value]
  );

  return (
    <ParameterInputWrapper
      title={title || t(TransKeys.ANALYSIS_FORMS.SHARED.ATTRIBUTED_SELECTOR.TITLE)}
      subTitle={subTitle || t(TransKeys.ANALYSIS_FORMS.SHARED.ATTRIBUTED_SELECTOR.SUB_TITLE)}
      helperText={helperText || t(TransKeys.ANALYSIS_FORMS.SHARED.ATTRIBUTED_SELECTOR.HELPER_TEXT)}
      className={className}
    >
      <ItemList
        items={value[schemaKeysMapping.attributes]}
        renderItem={(item, idx) => (
          <SignalSmartSelector
            placeholder={'Select'}
            onChange={value => onSegmentsChange(value, idx)}
            value={value[schemaKeysMapping.attributes]?.[idx]}
            error={Boolean(errors?.[schemaKeysMapping.attributes]?.[idx])}
            onSignalInfo={onSignalInfo as any}
            include={[SignalSmartSelectorKey.SIGNALS]}
            filters={filters}
          />
        )}
        onRemoveItem={removeElement}
        onAddItem={addElement}
        min1Item={true}
      />
    </ParameterInputWrapper>
  );
};

AttributesSelector.defaultProps = {
  schemaKeysMapping: DEFAULT_SCHEMA_KEYS_MAPPING,
};

import {MetricSampleSentences} from '../../../../../../../../../insights/model-sample/objects';
import sharedClasses from '../../rca-executive-summery.module.scss';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {TrendModelSampleInsightFormatter} from '../../../../../../../../../insights/model-sample/components/trend-model-sample-insight.component';
import {PeriodAllTimeHighOrLowModelSampleInsightFormatter} from '../../../../../../../../../insights/model-sample/components/period-all-time-high-or-low-model-sample-insight.component';
import {NumberOfUsersModelSampleInsightFormatter} from '../../../../../../../../../insights/model-sample/components/number-of-users-model-sample-insight.component';
import {ProductEntities} from '../../../../../../../../../../objects/models/product-data.model';
import * as React from 'react';

interface OwnProps extends MetricSampleSentences {
  productEntities: ProductEntities;
}

type AllProps = OwnProps;

export const MetricSamplesSection: React.FC<AllProps> = (props: AllProps) => {
  const {trendInsight, periodAllTimeHighOrLowInsight, numberOfUsersInsight, productEntities} =
    props;
  const {t} = useTranslation();

  if (
    [trendInsight, periodAllTimeHighOrLowInsight, numberOfUsersInsight].every(insight => !insight)
  ) {
    return null;
  }

  return (
    <div className={sharedClasses.ExecutiveSummarySection}>
      <div className={sharedClasses.SubTitle}>
        {t(TransKeys.DOCUMENT_VIEWER.RCA_EXECUTIVE_SUMMARY.SAMPLE_SENTENCES_TITLE)}
      </div>
      <ul className={sharedClasses.MetricModelSampleSentencesList}>
        {trendInsight && (
          <li>
            <TrendModelSampleInsightFormatter {...trendInsight} />
          </li>
        )}
        {periodAllTimeHighOrLowInsight && (
          <li>
            <PeriodAllTimeHighOrLowModelSampleInsightFormatter {...periodAllTimeHighOrLowInsight} />
          </li>
        )}
        {numberOfUsersInsight && (
          <li>
            <NumberOfUsersModelSampleInsightFormatter
              {...numberOfUsersInsight}
              productEntities={productEntities as ProductEntities}
              sentenceKey={numberOfUsersInsight.key}
            />
          </li>
        )}
      </ul>
    </div>
  );
};

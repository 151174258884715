import * as React from 'react';
import {useCallback, useContext, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import classes from './feedback-viewer.module.scss';
import TransKeys from '../../../../../../constants/translation-keys';
import {
  ActionsDropdown,
  ChildRendererInjectedProps,
  FeedbackFigure,
  ThumbButton,
} from 'ui-components';
import {PanelKey} from '../../../../../../constants/panels';
import {PanelsContext} from '../../../../../../core/contexts/panels.context';
import {values} from 'lodash';
import {useDispatch} from 'react-redux';
import {FeedbackModelType} from '../../../../../../objects/models/feedback.model';
import {createFeedback} from '../../../../../../store/feedbacks/feedbacks.actions';
import {useAmplitude} from '../../../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../../../constants/amplitude-event';

enum FeedbackNotHelpfulReasons {
  DONT_UNDERSTAND = 'DONT_UNDERSTAND',
  DONT_SEE_VALUE = 'DONT_SEE_VALUE',
  OTHER = 'OTHER',
}

export interface OwnProps extends FeedbackFigure, Partial<ChildRendererInjectedProps> {
  modelId: number;
  modelType: FeedbackModelType;
}

export const FeedbackViewer: React.FC<OwnProps> = (props: OwnProps) => {
  const {data, modelId, modelType} = props;
  const {key} = data;
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const notify = useAmplitude();
  const {openSecondaryPanel} = useContext(PanelsContext);
  const [showThankForFeedback, setShowThankForFeedback] = useState(false);

  const sharedFeedbackValues = useMemo(
    () => ({
      modelType,
      modelId,
      key,
    }),
    [modelType, modelId, key]
  );

  const sendFeedback = useCallback(
    feedbackData =>
      dispatch(
        createFeedback(feedbackData, () => {
          setShowThankForFeedback(true);
          return [];
        })
      ),
    [dispatch, setShowThankForFeedback]
  );

  const onHelpfulFeedbackClick = useCallback(() => {
    const feedbackData = {
      ...sharedFeedbackValues,
      isPositive: true,
    };
    notify(AmplitudeEvent.FEEDBACK_BUTTON_CLICKED, feedbackData);
    sendFeedback(feedbackData);
  }, [sharedFeedbackValues, sendFeedback, notify]);

  const onNotHelpfulItemClicked = useCallback(
    key => {
      const feedbackData = {
        ...sharedFeedbackValues,
        isPositive: false,
      };
      notify(AmplitudeEvent.FEEDBACK_BUTTON_CLICKED, feedbackData);
      if (key === FeedbackNotHelpfulReasons.OTHER) {
        return openSecondaryPanel(PanelKey.FEEDBACK_FORM_PANEL, {
          feedback: feedbackData,
          onSuccess: () => {
            setShowThankForFeedback(true);
            return [];
          },
        });
      }
      return sendFeedback({
        ...feedbackData,
        description: t(TransKeys.FEEDBACK.NOT_HELPFUL_REASONS[key]),
      });
    },
    [sharedFeedbackValues, notify, openSecondaryPanel, setShowThankForFeedback, sendFeedback, t]
  );

  const notHelpfulActions = useMemo(
    () =>
      values(FeedbackNotHelpfulReasons).map(o => ({
        title: t(TransKeys.FEEDBACK.NOT_HELPFUL_REASONS[o]),
        key: o as any,
        onClick: () => onNotHelpfulItemClicked(o),
      })),
    [t, onNotHelpfulItemClicked]
  );

  const renderFeedbackContent = () => {
    if (showThankForFeedback) {
      return (
        <div className={classes.ThankForFeedback}>
          <span>{t(TransKeys.FEEDBACK.SUCCESS.THANK_YOU.EMOJI)}</span>
          <span>{t(TransKeys.FEEDBACK.SUCCESS.THANK_YOU.LABEL)}</span>
        </div>
      );
    }
    return (
      <>
        <div>{t(TransKeys.FEEDBACK.IS_HELPFUL_LABEL)}</div>
        <div className={classes.ActionButtons}>
          <ThumbButton
            variant={'up'}
            onClick={onHelpfulFeedbackClick}
            text={t(TransKeys.GENERAL.LABELS.YES)}
          />
          <ActionsDropdown
            popoverDirection={'left'}
            buttonComponent={props => (
              <ThumbButton
                variant={'down'}
                onClick={props.onClick}
                text={t(TransKeys.GENERAL.LABELS.NO)}
                isActive={props.isOpen}
              />
            )}
            actions={notHelpfulActions}
          />
        </div>
      </>
    );
  };

  return <div className={classes.FeedbackViewer}>{renderFeedbackContent()}</div>;
};

import {InteractionContext, InteractionType, ModelType} from 'ui-components';
import {Experiment} from '../../../../../../../objects/models/experiment.model';
import classes from './experiment-list-row-item.module.scss';
import {ExperimentsListRowItemActions} from './cells/experiments-actions-cell/experiments-actions-cell.component';
import {useCallback, useContext} from 'react';
import {ExperimentPrimaryKPICell} from './cells/experiment-primary-kpi-cell/experiment-primary-kpi-cell.component';
import {ExperimentProgressCell} from './cells/experiment-progress-cell/experiment-progress-cell.component';
import {ExperimentDescriptionCell} from './cells/experiment-description-cell/experiment-description-cell.component';
import {ExperimentOwnerCell} from './cells/experiment-owner-cell/experiment-owner-cell.component';
import {useExperimentActions} from '../../../../../hooks/use-experiment-actions.hook';
import {ExperimentTeamCell} from './cells/experiment-team-cell/experiment-team-cell.component';
import {useProductData} from 'src/core/hooks/use-product-data.hook';
import {PanelKey} from 'src/constants/panels';
import {PanelsContext} from 'src/core/contexts/panels.context';
import {EXPERIMENT_AUTOMATION_ID_PATH_PARAM} from 'src/constants/app-routes';

type ExperimentListRowItemProps = {
  experiment: Experiment;
};

export const ExperimentListRowItem = (props: ExperimentListRowItemProps) => {
  const {experiment} = props;
  const {postMessage} = useContext(InteractionContext);
  const {onChangeOwner} = useExperimentActions();
  const {actualTeams: teams} = useProductData();
  const {openPrimaryPanel} = useContext(PanelsContext);

  const onMainKpiClick = useCallback(
    signalId =>
      postMessage({
        type: InteractionType.REFERENCE,
        payload: {
          modelId: signalId,
          modelType: ModelType.SIGNAL,
        },
      }),
    [postMessage]
  );

  const viewExperimentAutomation = useCallback(
    (id: number) =>
      openPrimaryPanel(PanelKey.EXPERIMENT_AUTOMATION_FORM_PANEL, {
        [EXPERIMENT_AUTOMATION_ID_PATH_PARAM]: id,
      }),
    [openPrimaryPanel]
  );

  return (
    <div className={classes.ExperimentListRowItem}>
      <ExperimentDescriptionCell
        experiment={experiment}
        onViewExperimentAutomation={viewExperimentAutomation}
        className={classes.ExperimentDescriptionCell}
      />
      <ExperimentPrimaryKPICell
        experiment={experiment}
        onClick={mainKpiId => onMainKpiClick(mainKpiId)}
        className={classes.ExperimentPrimaryKPICell}
      />
      <ExperimentOwnerCell
        experiment={experiment}
        onChangeOwner={onChangeOwner}
        className={classes.ExperimentOwnerCell}
      />
      {teams.length > 0 && (
        <ExperimentTeamCell team={experiment.team} className={classes.ExperimentTeamCell} />
      )}
      <ExperimentProgressCell
        experiment={experiment}
        className={
          teams.length > 0 ? classes.ExperimentProgressCell : classes.WideExperimentProgressCell
        }
      />
      <ExperimentsListRowItemActions
        experiment={experiment}
        className={classes.ExperimentActions}
      />
    </div>
  );
};

import {
  AnomalyMode,
  HomepageFunnel,
  HomepageMetric,
  HomepageModel,
} from '../../../../../objects/models/homepage.model';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {
  ModelSample,
  ModelSampleSeries,
  ModelSampleSeriesModel,
  ModelSampleSeriesType,
  ModelSeriesGranularity,
} from '../../../../../objects/models/model-sample-series.model';
import {capitalize, keyBy, last, range, takeRight} from 'lodash';
import {exists, HttpClientContext, number2k} from 'front-core';
import {MetricType, MetricValueType} from '../../../../../objects/models/metric.model';
import {extractXLabelFromMetric, getSampleWoWChange} from '../homepage-summary.utils';
import moment from 'moment';
import {TIME_FORMATS} from '../../../../../constants/time-formats';
import {ChartConfig, MetricGoalAtTime} from '../homepage-summary.types';
import {useTranslation} from 'react-i18next';
import {PanelsContext} from '../../../../../core/contexts/panels.context';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import {DateRange, RCAMode, useLocalStorage} from 'ui-components';
import {
  ANALYSIS_RESULT_ID_PATH_PARAM,
  AppRoutes,
  TEAM_ID_PATH_PARAM,
} from '../../../../../constants/app-routes';
import {METRIC_PAGE_DATE_QUERY_PARAM} from '../../../../metrics/pages/metric-page/metric-page.component';
import {researchSampleNetworkRequest} from '../../../../../http/model-sample-series.network-requests';
import {notifyEvent} from '../../../../../store/core/core.actions';
import {AmplitudeEvent} from '../../../../../constants/amplitude-event';
import {captureException} from '@sentry/react';
import {getMinimumDate, isSignificantValue} from '../../../../../utils/general.utils';
import TransKeys from 'translations';
import {AggregationMode} from '../../../../../objects/models/signal.model';
import {RCAPanelFormParameters} from '../../../../shared/follow-ups/panels/rca-follow-up-panel/rca-follow-up-panel.component';
import {PanelKey} from '../../../../../constants/panels';
import {ConfidenceIntervalConfig} from '../../../../../objects/models/user-settings.model';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';
import {
  HomepageModelSamplesViewerFilters,
  TimeframeOption,
} from '../components/homepage-model-samples-viewer/homepage-model-samples-viewer.types';
import {getDateFromTimeframeOption} from '../components/homepage-model-samples-viewer/homepage-model-samples-viewer.utils';
import {
  DEFAULT_CHART_CONFIG,
  DEFAULT_TIMEFRAME,
  HOMEPAGE_CHART_CONFIG,
  SHOW_ALL_POINTS_END_DATE,
} from '../components/homepage-model-samples-viewer/homepage-model-samples-viewer.consts';

export const DEFAULT_GRANULARITY = ModelSeriesGranularity.WEEK;

export const useDefaultSeries = (
  model: HomepageModel,
  granularity = DEFAULT_GRANULARITY
): ModelSampleSeries => {
  return useMemo(() => {
    if (!model) {
      return;
    }
    let series: ModelSampleSeries;
    switch (model.modelType) {
      case ModelSampleSeriesModel.METRIC:
        series = model.series.find(s => s.granularity === granularity);
        break;
      case ModelSampleSeriesModel.FUNNEL: {
        series = (model as HomepageFunnel).series.find(
          s =>
            s.granularity === granularity &&
            s.seriesType === ModelSampleSeriesType.FUNNEL_COMPLETION_RATE
        );
      }
    }
    return series || model.series[0];
  }, [model, granularity]);
};

export const useHomepageModelData = (model: HomepageModel, granularity = DEFAULT_GRANULARITY) => {
  const reviewedSeries = useDefaultSeries(model, granularity);
  const nonPartialSamples = useMemo(
    () => (reviewedSeries?.samples || []).filter(s => s.isPartial === false) || [],
    [reviewedSeries]
  );
  const [prevSample, lastSample] = useMemo(
    () => takeRight(nonPartialSamples || [], 2),
    [nonPartialSamples]
  );
  const lastSampleValues = useMemo(
    () =>
      takeRight(
        (nonPartialSamples || []).map(s => s.value),
        5
      ),
    [nonPartialSamples]
  );
  const renderValue = useMemo(() => {
    if (!model) {
      return;
    }
    if (!lastSample) {
      return;
    }
    switch (model.modelType) {
      case ModelSampleSeriesModel.FUNNEL:
        return `${number2k(lastSample.value * 100)}%`;
      case ModelSampleSeriesModel.METRIC:
        if ((model as HomepageMetric).valueType === MetricValueType.PERCENTAGE) {
          return `${number2k(lastSample.value * 100)}%`;
        }
        return number2k(lastSample.value);
    }
  }, [lastSample, model]);
  const trend = useMemo(() => getSampleWoWChange(lastSample, prevSample), [lastSample, prevSample]);
  const {minSampleDate, maxSampleDate} = useMemo(
    () => ({
      minSampleDate: model?.minSampleDate ? moment.utc(model.minSampleDate).toDate() : undefined,
      maxSampleDate: model?.maxSampleDate ? moment.utc(model.maxSampleDate).toDate() : undefined,
    }),
    [model]
  );

  return {
    reviewedSeries,
    lastSample,
    prevSample,
    lastSampleValues,
    renderValue,
    trend,
    minSampleDate,
    maxSampleDate,
  };
};

export const useRequestRCAForSample = (
  metric: HomepageMetric,
  configuration: ConfidenceIntervalConfig,
  granularity: ModelSeriesGranularity,
  teamId?: number
) => {
  const {t} = useTranslation();
  const {openSecondaryPanel} = useContext(PanelsContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const http = useContext(HttpClientContext);
  const [isLoading, setIsLoading] = useState(false);

  const rcaMode = useMemo(
    () =>
      configuration.anomalyMode === AnomalyMode.LOOPS_ALGO
        ? RCAMode.LOOPS_ALGO
        : RCAMode.COMPARE_TO_DATE,
    [configuration]
  );

  const rerouteToAnalysisResultPage = useCallback(
    (rcaAnalysisId: number, rcaAnalysisResultId: number) => {
      return history.push(
        AppRoutes.viewAnalysis(rcaAnalysisId, {
          [ANALYSIS_RESULT_ID_PATH_PARAM]: rcaAnalysisResultId,
          rcaMode,
        })
      );
    },
    [history, rcaMode]
  );

  const rerouteToMetricPage = useCallback(
    (sample: ModelSample) => {
      const metricPageQueryParams = {
        rcaMode,
        [METRIC_PAGE_DATE_QUERY_PARAM]: moment
          .utc(sample.datetime)
          .format(TIME_FORMATS.PARAMETER_DATE_FORMAT),
        [TEAM_ID_PATH_PARAM]: teamId,
      };

      return history.push(AppRoutes.viewMetric(metric.id, metricPageQueryParams));
    },
    [history, rcaMode, metric, teamId]
  );

  const openRCAFollowUpPanel = useCallback(
    (sample: ModelSample) => {
      const increased = sample.value > sample.upper;
      const isSignificant = isSignificantValue(sample.value, sample.lower, sample.upper);
      const change = isSignificant
        ? t(TransKeys.GENERAL.LABELS[increased ? 'INCREASED' : 'DECREASED'])
        : 'changed';
      let analysisMode = undefined;
      if (metric.type === MetricType.USAGE || metric.type === MetricType.REVENUE) {
        analysisMode = AggregationMode.SUM;
      }

      const rcaPanelFormParams: RCAPanelFormParameters = {
        goal: metric.signalId,
        timeAggregation: granularity,
        startDateAnomaly: sample.datetime,
        useLoopsAnomalyDetectionAlgo: true,
        entity: metric.entity,
        higherIsBetter: metric.higherIsBetter,
        analysisMode: analysisMode,
      };

      openSecondaryPanel(PanelKey.RCA_FOLLOW_UP_PANEL, {
        fromToLabel: moment(sample.datetime).format(TIME_FORMATS.DEFAULT_DATE_FORMAT),
        title: t(TransKeys.HOMEPAGE.LINE_CHART_WITH_FOLLOW_UP_RCA.PANEL.TITLE, {
          change,
          timeGranularity: t(TransKeys.GENERAL.LABELS.TIME_GRANULARITY[granularity.toUpperCase()]),
        }),
        notifyAmplitudeSubmitted:
          AmplitudeEvent.CREATE_RCA_FOLLOW_UP_ACTION_FROM_HOMEPAGE_LINE_CHART_CLICKED,
        analysisFormParameters: rcaPanelFormParams,
      });

      dispatch(
        notifyEvent(AmplitudeEvent.RCA_MODAL_TRIGGERED_FROM_CHART_HOMEPAGE, {
          metric_id: metric.id,
        })
      );
    },
    [t, dispatch, metric, granularity, openSecondaryPanel]
  );

  const requestRCAForSample = useCallback(
    async (sample: ModelSample) => {
      if (exists(sample.rcaAnalysisId) && exists(sample.rcaAnalysisResultId)) {
        if (granularity === ModelSeriesGranularity.DAY) {
          return rerouteToAnalysisResultPage(sample.rcaAnalysisId, sample.rcaAnalysisResultId);
        } else {
          return rerouteToMetricPage(sample);
        }
      }
      try {
        setIsLoading(true);
        const rcaRes: any = await http.exec(researchSampleNetworkRequest(sample.id));
        setIsLoading(false);

        dispatch(
          notifyEvent(AmplitudeEvent.RCA_TRIGGERED_FROM_CHART_HOMEPAGE, {metric_id: metric.id})
        );

        if (granularity === ModelSeriesGranularity.DAY) {
          return rerouteToAnalysisResultPage(rcaRes.analysisId, rcaRes.analysisResultId);
        } else {
          return rerouteToMetricPage(sample);
        }
      } catch (error) {
        captureException(error);
        return openRCAFollowUpPanel(sample);
      }
    },
    [
      granularity,
      rerouteToAnalysisResultPage,
      rerouteToMetricPage,
      setIsLoading,
      http,
      dispatch,
      metric,
      openRCAFollowUpPanel,
    ]
  );

  return {
    requestRCAForSample,
    isLoading,
  };
};

export const useMetricGoalData = (metric: HomepageMetric): MetricGoalAtTime[] => {
  const defaultSeries = useDefaultSeries(metric);
  return useMemo(() => {
    if (!metric || !defaultSeries || !metric.goal) {
      return [];
    }
    const lastSample = last(defaultSeries.samples);
    const nonPartialSamples = defaultSeries.samples.filter(s => s.isPartial === false);
    const metricTimeDelta = moment
      .utc(lastSample.sampleDatetime)
      .diff(moment.utc(lastSample.datetime), 'days');
    const sampleByDate = keyBy(nonPartialSamples, 'datetime');
    const goalStart = moment.utc(metric.goal.startDate).subtract(metricTimeDelta, 'days');
    const goalEnd = moment.utc(metric.goal.endDate).subtract(metricTimeDelta, 'days');
    let startOfGoalSample = nonPartialSamples.find(s =>
      moment.utc(s.datetime).isSameOrAfter(goalStart)
    );
    if (!exists(startOfGoalSample)) {
      startOfGoalSample = nonPartialSamples[nonPartialSamples.length - 1];
    }
    const goalValue = startOfGoalSample.expectedValue;
    const diff = goalEnd.diff(goalStart, defaultSeries.granularity);
    const totalRange = metric.goal.value - goalValue;
    const dailyGoal = totalRange / diff;
    return range(diff + 1).map(i => {
      const datetime = goalStart.format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT);
      const x = {
        datetime,
        value: goalValue + dailyGoal * i,
        matchingSampleValue: sampleByDate[datetime]?.value,
      };
      goalStart.add(1, defaultSeries.granularity);
      return x;
    });
  }, [defaultSeries, metric]);
};

export const useChartXLabelForMetric = (
  metric: HomepageMetric,
  granularity: ModelSeriesGranularity
) => {
  const {productEntitiesMap} = useProductData();
  const [xLabel, setXLabel] = useState<string>('');
  const getXLabel = useCallback(async () => {
    if (!metric) {
      return;
    }
    const xLabel = await extractXLabelFromMetric(
      metric,
      productEntitiesMap[metric.entity].name,
      granularity
    );
    setXLabel(capitalize(xLabel));
  }, [metric, productEntitiesMap, granularity]);

  useEffect(() => {
    getXLabel();
  }, [getXLabel]);

  return xLabel;
};

export const useModelGranularity = (metric: HomepageMetric) => {
  const [granularity, setGranularity] = useState<ModelSeriesGranularity>(
    ModelSeriesGranularity.WEEK
  );
  const granularityOptions = useMemo(() => {
    if (!metric) {
      return [];
    }
    return Array.from(new Set(metric.series.map(s => s.granularity)));
  }, [metric]);

  return {
    granularity,
    setGranularity,
    granularityOptions,
  };
};

interface UseModelSampleTimeframeConfig {
  toDate?: string;
}

export const useModelSampleTimeframe = (config?: UseModelSampleTimeframeConfig) => {
  const {toDate} = config || {};
  const {defaultSource} = useProductData();
  const [filters, setFilters] = useState<HomepageModelSamplesViewerFilters>(() => ({
    fromDate: getDateFromTimeframeOption(
      getMinimumDate([toDate, defaultSource.lastValidDate]),
      DEFAULT_TIMEFRAME
    ),
    toDate: toDate || getDateFromTimeframeOption(defaultSource.lastValidDate),
  }));
  const [isCustomRange, setIsCustomRange] = useState(false);
  const [showAllPartialPoints, setShowAllPartialPoints] = useState(false);

  const changeFilters = useCallback(
    (newFilters: HomepageModelSamplesViewerFilters) =>
      setFilters(filters => ({
        ...filters,
        ...newFilters,
      })),
    [setFilters]
  );
  const onTimeframeOptionSelected = useCallback(
    (option: TimeframeOption) => {
      setFilters(filters => ({
        ...filters,
        fromDate: getDateFromTimeframeOption(defaultSource.lastValidDate, option),
        toDate: showAllPartialPoints
          ? SHOW_ALL_POINTS_END_DATE
          : getDateFromTimeframeOption(defaultSource.lastValidDate),
      }));
      setIsCustomRange(false);
    },
    [setFilters, setIsCustomRange, showAllPartialPoints, defaultSource.lastValidDate]
  );
  const onCustomDatesSelected = useCallback(
    (value: DateRange) => {
      changeFilters({
        fromDate: moment
          .utc(value.from)
          .startOf('d')
          .format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT),
        toDate: moment.utc(value.to).startOf('d').format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT),
      });
      setIsCustomRange(true);
    },
    [changeFilters]
  );
  const onChangeShowPartialPoints = useCallback(
    (show: boolean) => {
      setShowAllPartialPoints(show);
      changeFilters({
        toDate: show
          ? SHOW_ALL_POINTS_END_DATE
          : getDateFromTimeframeOption(defaultSource.lastValidDate),
      });
    },
    [setShowAllPartialPoints, changeFilters, defaultSource.lastValidDate]
  );
  const isTimeframeSelected = useCallback(
    (option: TimeframeOption) => {
      const value = getDateFromTimeframeOption(defaultSource.lastValidDate, option);
      return value === filters.fromDate && !isCustomRange;
    },
    [defaultSource.lastValidDate, filters, isCustomRange]
  );

  return {
    filters,
    isCustomRange,
    showAllPartialPoints,
    onTimeframeOptionSelected,
    onCustomDatesSelected,
    onChangeShowPartialPoints,
    isTimeframeSelected,
  };
};

export const useHomepageChartConfig = () => {
  const [chartConfig, setChartConfig] = useLocalStorage<ChartConfig>(
    HOMEPAGE_CHART_CONFIG,
    DEFAULT_CHART_CONFIG
  );

  return {
    chartConfig,
    setChartConfig,
  };
};

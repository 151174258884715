import {NRC} from './index';
import {RequestType} from 'front-core';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';
import {toCamelCase, toSnakeCase} from 'front-core';
import {AnalysisTypeFiltersDTO} from '../objects/dto/analysis-type-filters.dto';

export const getAnalysisTypesNetworkRequest: NRC<void, AnalysisTypeFiltersDTO> = (
  payload: AnalysisTypeFiltersDTO
) => ({
  method: RequestType.GET,
  relativeUrl: `/app/analysis-types`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data),
});

export const getAnalysisTypesCatalogNetworkRequest: NRC<void, AnalysisTypeFiltersDTO> = (
  payload: AnalysisTypeFiltersDTO
) => ({
  method: RequestType.GET,
  relativeUrl: `/app/analysis-types/catalog`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data),
});

export const searchAnalysisTypesNetworkRequest: NRC<void, any> = (value: any, payload: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/analysis-types`,
  query: {q: value, ...payload},
  queryTransformer: toFiltersDTO,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data.data),
});

export const getAnalysisTypeNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/analysis-types/${id}`,
  responseTransformer: res => toCamelCase(res.data),
});

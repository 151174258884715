import {
  CronUnitsPositions,
  CRON_SCHEDULE_KEYS,
} from '../../../../../../../constants/cron-exp.consts';

export const getSelectedCronCheckbox = (cronExp: string) => {
  const cronExpAsArray = cronExp.split(' ');
  if (
    cronExpAsArray[CronUnitsPositions.DAY_OF_THE_MONTH] === '*' &&
    cronExpAsArray[CronUnitsPositions.MONTH] === '*'
  ) {
    return cronExpAsArray[CronUnitsPositions.DAY_OF_THE_WEEK] === '*'
      ? CRON_SCHEDULE_KEYS.DAILY
      : CRON_SCHEDULE_KEYS.WEEKLY;
  }

  return CRON_SCHEDULE_KEYS.MONTHLY;
};

import {NRC} from './index';
import {RequestType, toSnakeCase} from 'front-core';
import {toCamelCase} from 'front-core';
import {MetricCategoryDTO, SwapCategoriesDTO} from '../objects/dto/metric.dto';

export const getMetricCategoriesNetworkRequest: NRC<void, any> = () => ({
  method: RequestType.GET,
  relativeUrl: `/app/metric-categories`,
  responseTransformer: res => toCamelCase(res.data),
});

export const getMetricCategoryNetworkRequest: NRC<void, any> = (id: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/metric-categories/${id}`,
  responseTransformer: res => toCamelCase(res.data),
});

export const createMetricCategoryNetworkRequest: NRC<MetricCategoryDTO, void> = (
  data: MetricCategoryDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/metric-categories`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const editMetricCategoryNetworkRequest: NRC<MetricCategoryDTO, void> = (
  data: MetricCategoryDTO
) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/metric-categories/${data.id}`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const deleteMetricCategoryNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.DELETE,
  relativeUrl: `/app/metric-categories/${id}`,
  responseTransformer: res => toCamelCase(res.data),
});

export const swapMetricCategoryNetworkRequest: NRC<SwapCategoriesDTO, void> = (
  data: SwapCategoriesDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/metric-categories/swap`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

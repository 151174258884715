import {NRC} from './index';
import {RequestType, toSnakeCase} from 'front-core';
import {toCamelCase} from 'front-core';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';
import {FeatureDTO} from 'src/objects/dto/feature.dto';

const exceptKeys = [
  'usage_signal_definition',
  'exposure_signal_definition',
  'intent_signal_definition',
  'activation_signal_definition',
  'churn_signal_definition',
];

export const searchFeaturesNetworkRequest: NRC<void, any> = (value: any, payload: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/features`,
  query: {q: value, ...payload},
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data.data),
});

export const getFeaturesNetworkRequest: NRC<void, any> = query => ({
  method: RequestType.GET,
  relativeUrl: `/app/features`,
  query: query,
  queryTransformer: toFiltersDTO,
  responseTransformer: res =>
    toCamelCase(
      res.data,
      exceptKeys.map(k => `data.*.${k}`)
    ),
});

export const getFeatureNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/features/${id}`,
  responseTransformer: res => toCamelCase(res.data, exceptKeys),
});

export const createFeatureNetworkRequest: NRC<FeatureDTO, void> = (data: FeatureDTO) => ({
  method: RequestType.POST,
  relativeUrl: `/app/features`,
  body: data,
  bodyTransformer: data => toSnakeCase(data, exceptKeys),
  responseTransformer: res => toCamelCase(res.data, exceptKeys),
});

export const editFeatureNetworkRequest: NRC<FeatureDTO, void> = (data: FeatureDTO) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/features/${data.id}`,
  body: data,
  bodyTransformer: data => toSnakeCase(data, exceptKeys),
  responseTransformer: res => toCamelCase(res.data, exceptKeys),
});

export const deleteFeatureNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.DELETE,
  relativeUrl: `/app/features/${id}`,
  responseTransformer: toCamelCase,
});

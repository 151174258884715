import {QueryBuilder, QueryBuilderConfig} from 'ui-components';
import {useMemo} from 'react';
import {queryBuilderModelConfig} from '../../../../../constants/query-builder';
import {getComponentForQuery} from '../../../core/query-builders/query-builders.utils';
import {TableEntity} from '../../../../../objects/models/table.model';

interface OwnProps {
  query: any;
  entityContext?: TableEntity;
}

type AllProps = OwnProps;

const QueryDefinition: React.FC<AllProps> = (props: AllProps) => {
  const {query, entityContext} = props;
  const queryBuilderConfig: QueryBuilderConfig = useMemo(
    () => ({
      modelConfig: queryBuilderModelConfig,
    }),
    []
  );
  const QueryComponent = useMemo(() => getComponentForQuery(query) || QueryBuilder, [query]);
  return (
    <>
      {[query].flat().map((q, idx) => (
        <QueryComponent
          key={idx}
          viewMode
          config={queryBuilderConfig}
          query={q}
          entityContext={entityContext}
          disabled
        />
      ))}
    </>
  );
};

export default QueryDefinition;

import {action, OnSuccessActionHook} from 'front-core';
import {MetricCategoryDTO, SwapCategoriesDTO} from '../../objects/dto/metric.dto';
import {showConfirmationMessage} from '../interface/interface.actions';
import i18n from '../../config/i18n.config';
import TransKeys from '../../constants/translation-keys';

export enum MetricCategoriesActionType {
  CREATE_METRIC_CATEGORY = '@@metric-categories/CREATE_METRIC_CATEGORY',
  UPDATE_METRIC_CATEGORY = '@@metric-categories/UPDATE_METRIC_CATEGORY',
  DELETE_METRIC_CATEGORY = '@@metric-categories/DELETE_METRIC_CATEGORY',
  SWAP_METRIC_CATEGORY = '@@metric-categories/SWAP_METRIC_CATEGORY',
}

export const createMetricCategory = (data: MetricCategoryDTO, onSuccess: OnSuccessActionHook) =>
  action(MetricCategoriesActionType.CREATE_METRIC_CATEGORY, data, {onSuccess});

export const updateMetricCategory = (data: MetricCategoryDTO, onSuccess: OnSuccessActionHook) =>
  action(MetricCategoriesActionType.UPDATE_METRIC_CATEGORY, data, {onSuccess});

export const swapMetricCategory = (data: SwapCategoriesDTO) =>
  action(MetricCategoriesActionType.SWAP_METRIC_CATEGORY, data);

export const deleteMetricCategory = (id: number) =>
  action(MetricCategoriesActionType.DELETE_METRIC_CATEGORY, id);

export const deleteMetricCategoryConfirmed = (id: number) =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.DELETE_METRIC_CATEGORY.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.DELETE_METRIC_CATEGORY.CONTENT),
    },
    () => [deleteMetricCategory(id)]
  );

export const SOURCE_META_KEY = 'source';

export enum AppSources {
  TABLE_EVENTS_MAIN = 'table_events_main',
  CONTENTS_MAIN = 'contents_main',
  ANALYSIS_RESULT_VIEW__DUPLICATE = 'analysis_result_view.duplicate',
  VIEW_ANALYSIS_RESULT__VIEW_PARAMETERS = 'view_analysis_result.view_parameters',
  ANALYSIS_RESULTS_MAIN__EMPTY_STATE = 'analysis_results_main.empty_state',
  ANALYSIS_RESULTS_MAIN__VIEW_PARAMETERS = 'analysis_results_main.view_parameters',
  ANALYSIS_RESULTS_MAIN__DUPLICATE = 'analysis_results_main.duplicate',
  ANALYSIS_RESULTS_MAIN__CREATE = 'analysis_results_main.create',
  OPPORTUNITIES_MAIN__VIEW_PARAMETERS = 'opportunities_main.view_parameters',
  TABLE_FORM_PANEL = 'table_form_panel',
  TABLES_MAIN = 'tables_main',
}

import {NRC} from './index';
import {UserReactionDTO} from '../objects/dto/user-reaction.dto';
import {RequestType} from 'front-core';
import {toCamelCase, toSnakeCase} from 'front-core';
import {UserReactionType} from '../objects/models/user-reaction.model';
import {ShareDTO} from '../objects/dto/share.dto';

export const createUserReactionNetworkRequest: NRC<UserReactionDTO, void> = (
  type: UserReactionType,
  data: UserReactionDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/user-reactions/${type}`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: toCamelCase,
});

export const deleteUserReactionNetworkRequest: NRC<UserReactionDTO, void> = (
  type: UserReactionType,
  data: UserReactionDTO
) => ({
  method: RequestType.DELETE,
  relativeUrl: `/app/user-reactions/${type}`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: toCamelCase,
});

export const shareUserReactionNetworkRequest: NRC<ShareDTO, void> = (data: ShareDTO) => ({
  method: RequestType.POST,
  relativeUrl: `/app/user-reactions/share`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: toCamelCase,
});

export const deleteShareUserReactionNetworkRequest: NRC<Partial<ShareDTO>, void> = (
  data: Partial<ShareDTO>
) => ({
  method: RequestType.DELETE,
  relativeUrl: `/app/user-reactions/share`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: toCamelCase,
});

import {useCallback, useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import {
  LiteralValueType,
  QueryBuilder,
  QueryBuilderFactory,
  QueryBuilderConfig,
  SqlElementType,
  HoverHelperTip,
} from 'ui-components';
import {cloneDeep, get} from 'lodash';
import {
  METADATA_KEY,
  PARAMETERS_METADATA_KEY,
} from '../../../../../constants/parameters-saved-keys';
import {SelectorModelType} from '../../smart-selector/advanced-smart-selector.component';
import {TemplateItemQueryBuilder} from '../template-item-query-builder/template-item-query-builder.component';
import classes from './funnel-step-query-builder.module.scss';
import {
  TableEntity,
  TableEntityBinding,
  TableType,
} from '../../../../../objects/models/table.model';
import {SignalDataType, SignalType} from '../../../../../objects/models/signal.model';
import {CustomQueryWarning} from '../../../../dmp/components/custom-query-warning/custom-query-warning.component';
import {queryBuilderModelConfig} from '../../../../../constants/query-builder';
import {StandardCheckBox} from '../../../components/general/standard-check-box/standard-check-box.component';
import {
  TableEventsQueryBuilder,
  TableEventsQueryBuilderName,
} from '../table-events-query-builder/table-events-query-builder.component';
import {
  createTableEventsValueInitialQuery,
  TableEventsValueQueryBuilder,
} from '../table-events-value-query-builder/table-events-value-query-builder.component';
import {exists} from 'front-core';
import TransKeys from 'translations';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

const queryBuilderConfig: QueryBuilderConfig = {
  modelConfig: queryBuilderModelConfig,
};

interface OwnProps {
  query: any;
  onChange?: (query: any) => void;
  errors?: any;
  disabled?: boolean;
  isFirstStep: boolean;
  entity: TableEntity;
  onSignalInfo: (value: string | number) => void;
  className?: string;
}

type AllProps = OwnProps;

enum StepStructureType {
  UNSET = 'unset',
  EVENT = 'event',
  TABLE = 'table',
  SIGNAL = 'signal',
}

function getStepStructureType(condition) {
  if (condition?.type === SqlElementType.SIGNAL_COLUMN) {
    return StepStructureType.SIGNAL;
  }
  if (condition?.type === SqlElementType.TABLE_COLUMN) {
    return StepStructureType.TABLE;
  }
  if (
    [SqlElementType.AND_CONDITION, SqlElementType.OR_CONDITION, SqlElementType.CASES].includes(
      condition?.type
    )
  ) {
    return StepStructureType.EVENT;
  }
  return StepStructureType.UNSET;
}

export function createFunnelStepInitialQuery(initialCondition: any) {
  let q = initialCondition;

  if (getStepStructureType(initialCondition) === StepStructureType.EVENT) {
    q = QueryBuilderFactory.createOrCondition();
    q.conditions = [initialCondition];
  }

  return q;
}

function createSignalFilters(entity: TableEntity, entityBinding: TableEntityBinding) {
  return [
    {
      data_type: SignalDataType.TIMESTAMP,
      type: SignalType.DIMENSION,
      entityBinding: entityBinding,
      entityContext: entity,
    },
    {
      data_type: SignalDataType.BOOLEAN,
      type: SignalType.MEASURE,
      entityBinding: entityBinding,
      entityContext: entity,
    },
  ];
}

function createEventFilters(entity: TableEntity, entityBinding: TableEntityBinding) {
  return {
    entityContext: entity,
    entityBinding: entityBinding,
  };
}

function createColumnFilters(entity: TableEntity, entityBinding: TableEntityBinding) {
  return {
    entityContext: entity,
    literalType: LiteralValueType.DATE,
    tableType: TableType.ENTITY_PROPERTIES,
    entityBinding: entityBinding,
  };
}

const HOLD_BY_PROPERTY_COLUMN_FILTERS = {
  literalType: [LiteralValueType.INTEGER, LiteralValueType.FLOAT, LiteralValueType.STRING],
};

const _FUNNEL_STEP_QUERY_CONVERTERS = {
  [SqlElementType.CASES]: (query: any) => {
    const queryCopy = cloneDeep(query);
    const stepName = get(
      queryCopy,
      `${PARAMETERS_METADATA_KEY}.${METADATA_KEY.DISPLAY_NAME_KEY}`,
      ''
    );
    const allOrConditions = queryCopy.conditions.map(andCondition => {
      const orCondition = QueryBuilderFactory.createOrCondition();
      orCondition.conditions = [andCondition];
      orCondition[PARAMETERS_METADATA_KEY] = {
        [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: TableEventsQueryBuilderName,
        [METADATA_KEY.BUILDER_VERSION]: 1,
      };
      return orCondition;
    });
    const tableEventsValueQuery = createTableEventsValueInitialQuery();
    tableEventsValueQuery.cases = allOrConditions.map(orCondition => {
      const tableColumn = get(orCondition, 'conditions.0.conditions.0.left');
      return [orCondition, tableColumn];
    });
    tableEventsValueQuery[PARAMETERS_METADATA_KEY][METADATA_KEY.DISPLAY_NAME_KEY] = stepName;
    return tableEventsValueQuery;
  },
  [SqlElementType.OR_CONDITION]: (query: any) => {
    const queryCopy = cloneDeep(query);
    const orCondition = QueryBuilderFactory.createOrCondition();
    orCondition.conditions = queryCopy.cases.reduce((acc, case_) => {
      const andCondition = get(case_, '0.conditions.0');
      return andCondition ? [...acc, andCondition] : acc;
    }, []);
    return orCondition;
  },
};

const setMetadataDisplayNameForCasesQuery = (newQuery: any) => {
  if (newQuery?.type !== SqlElementType.CASES) {
    return newQuery;
  }
  const queryCopy = cloneDeep(newQuery);
  const stepName = get(
    queryCopy,
    `cases.0.0.conditions.0.${PARAMETERS_METADATA_KEY}.${METADATA_KEY.DISPLAY_NAME_KEY}`,
    ''
  );

  if (!stepName) {
    return queryCopy;
  }
  queryCopy[PARAMETERS_METADATA_KEY] = {
    ...queryCopy[PARAMETERS_METADATA_KEY],
    [METADATA_KEY.DISPLAY_NAME_KEY]: stepName,
  };
  return queryCopy;
};

const transformQuery = (query: any) => {
  switch (query?.type) {
    case SqlElementType.OR_CONDITION: {
      // convert from or conditions to cases
      return _FUNNEL_STEP_QUERY_CONVERTERS[SqlElementType.CASES](query);
    }
    case SqlElementType.CASES: {
      // convert from cases to or conditions
      return _FUNNEL_STEP_QUERY_CONVERTERS[SqlElementType.OR_CONDITION](query);
    }
    default:
      return query;
  }
};

export const FunnelStepQueryBuilder: React.FC<AllProps> = (props: AllProps) => {
  const {
    query,
    onChange: onChangeFromProps,
    errors,
    disabled,
    isFirstStep,
    entity,
    onSignalInfo,
    className,
  } = props;
  const {t} = useTranslation();
  const [holdByPropertyCheckbox, setHoldByPropertyCheckbox] = useState(() => {
    if (getStepStructureType(query) !== StepStructureType.EVENT) {
      return false;
    }
    return query?.type === SqlElementType.CASES;
  });

  const onChange = useCallback(
    query => {
      if (query) {
        const initialCondition = query.conditions?.[0] || query;
        query[PARAMETERS_METADATA_KEY] = {
          ...query[PARAMETERS_METADATA_KEY],
          [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: 'FunnelStepQueryBuilder',
          [METADATA_KEY.DISPLAY_NAME_KEY]:
            initialCondition[PARAMETERS_METADATA_KEY][METADATA_KEY.DISPLAY_NAME_KEY],
        };
      }
      onChangeFromProps && onChangeFromProps(query);
    },
    [onChangeFromProps]
  );

  const entityBinding = useMemo(
    () => (isFirstStep ? TableEntityBinding.DEFAULT : TableEntityBinding.TWO_WAY),
    [isFirstStep]
  );
  const stepStructureType = useMemo(() => getStepStructureType(query), [query]);
  const isCustomQuery = useMemo(() => {
    if (!query) {
      return false;
    }

    if (stepStructureType === StepStructureType.UNSET) {
      return true;
    }

    if (stepStructureType === StepStructureType.EVENT) {
      if (get(query, 'conditions[0].type') === SqlElementType.CONDITION) {
        return true;
      }
    }

    return false;
  }, [query, stepStructureType]);

  const signalFilters = useMemo(
    () => createSignalFilters(entity, entityBinding),
    [entityBinding, entity]
  );
  const eventFilters = useMemo(
    () => createEventFilters(entity, entityBinding),
    [entityBinding, entity]
  );
  const columnFilters = useMemo(
    () => createColumnFilters(entity, entityBinding),
    [entityBinding, entity]
  );

  const onQueryAdd = useCallback(
    condition => {
      let newQuery = null;
      if (stepStructureType === StepStructureType.EVENT) {
        newQuery = cloneDeep(query);
        newQuery.conditions.push(condition);
      } else {
        newQuery = createFunnelStepInitialQuery(condition);
      }
      onChange(newQuery);
    },
    [query, onChange, stepStructureType]
  );
  const onQueryChange = useCallback(
    (condition: any) => {
      const newQuery = createFunnelStepInitialQuery(condition);
      onChange(newQuery);
    },
    [onChange]
  );
  const allowedTypes = useMemo(() => {
    return stepStructureType === StepStructureType.EVENT
      ? [SelectorModelType.EVENT]
      : [SelectorModelType.EVENT, SelectorModelType.SIGNAL, SelectorModelType.COLUMN];
  }, [stepStructureType]);
  const clearCustomQuery = useCallback(() => {
    onChange(null);
  }, [onChange]);

  const onHoldByPropertyChange = useCallback(() => {
    setHoldByPropertyCheckbox(prevState => !prevState);
    onChange(transformQuery(query));
  }, [setHoldByPropertyCheckbox, query, onChange]);

  useEffect(() => {
    if (holdByPropertyCheckbox && stepStructureType === StepStructureType.UNSET && !exists(query)) {
      setHoldByPropertyCheckbox(false);
    }
  }, [holdByPropertyCheckbox, stepStructureType, query, setHoldByPropertyCheckbox]);

  if (isCustomQuery) {
    return (
      <div className={classes.FunnelStepQueryBuilder}>
        <CustomQueryWarning
          modelName={'Funnel Step'}
          clearSignalDefinition={!disabled && clearCustomQuery}
        />
        <QueryBuilder config={queryBuilderConfig} query={query} errors={errors} disabled />
      </div>
    );
  }

  return (
    <div
      className={classNames(
        classes.FunnelStepQueryBuilder,
        disabled && classes.Disabled,
        className
      )}
    >
      <div className={classes.Builder}>
        {stepStructureType === StepStructureType.EVENT && (
          <>
            {!disabled && (
              <div className={classes.HoldByCheckbox}>
                <StandardCheckBox
                  className={classes.Checkbox}
                  checked={holdByPropertyCheckbox}
                  onChange={onHoldByPropertyChange}
                />
                <span className={classes.Description}>
                  {t(TransKeys.FUNNEL_STEP_QUERY_BUILDER.HOLD_BY_PROPERTY.DESCRIPTION, {
                    action: t(TransKeys.GENERAL.ACTIONS[holdByPropertyCheckbox ? 'REMOVE' : 'ADD']),
                  })}
                </span>
                <HoverHelperTip
                  title={t(TransKeys.FUNNEL_STEP_QUERY_BUILDER.HOLD_BY_PROPERTY.HELPER_TEXT)}
                />
              </div>
            )}
            {holdByPropertyCheckbox && (
              <TableEventsValueQueryBuilder
                query={query}
                errors={errors}
                onChange={query => onChange(setMetadataDisplayNameForCasesQuery(query))}
                disabled={disabled}
                filters={eventFilters}
                columnFilters={HOLD_BY_PROPERTY_COLUMN_FILTERS}
                thenText={t(TransKeys.FUNNEL_STEP_QUERY_BUILDER.HOLD_BY_PROPERTY.THEN_TEXT)}
                multiEvents={false}
                multiSelection
                sameTypeThen
              />
            )}
            {!holdByPropertyCheckbox && (
              <TableEventsQueryBuilder
                query={query}
                errors={errors}
                onChange={query => onChange(setMetadataDisplayNameForCasesQuery(query))}
                disabled={disabled}
                filters={eventFilters}
                multiEvents={true}
              />
            )}
          </>
        )}
        {[StepStructureType.SIGNAL, StepStructureType.TABLE].includes(stepStructureType) && (
          <TemplateItemQueryBuilder
            query={query}
            errors={errors}
            onChange={onQueryChange}
            disabled={disabled}
            allowTypes={allowedTypes}
            eventFilters={eventFilters}
            signalFilters={signalFilters}
            columnFilters={columnFilters}
            onSignalInfo={onSignalInfo}
          />
        )}
        {!query && (
          <div className={classes.AddEvent}>
            <TemplateItemQueryBuilder
              query={null}
              onChange={onQueryAdd}
              eventFilters={eventFilters}
              signalFilters={signalFilters}
              columnFilters={columnFilters}
              allowTypes={allowedTypes}
              errors={errors}
              disabled={disabled}
              onSignalInfo={onSignalInfo}
            />
          </div>
        )}
      </div>
    </div>
  );
};

import {StoreDefinition, StorePropertyType} from 'front-core';
import {CoreActionsType} from './core.actions';
import {LocalStorageKey} from '../../constants/local-storage-key';
import {changeAdminPrivilegesReducer, setScopeProductReducer} from './core.reducers';
import {LocalStorageManager} from 'ui-components';

export const CORE_STORE_KEY = 'CORE_STORE';

export const coreStore: StoreDefinition = {
  key: CORE_STORE_KEY,
  properties: {
    appReady: {
      propertyType: StorePropertyType.BOOLEAN,
      defaultValue: false,
    },
    initialLoadingCompleted: {
      propertyType: StorePropertyType.BOOLEAN,
      defaultValue: false,
    },
    scopeProduct: {
      propertyType: StorePropertyType.NUMBER,
      defaultValue: Number(localStorage.getItem(LocalStorageKey.SCOPE_PRODUCT) || 0) || undefined,
    },
    appStatusCounters: {
      propertyType: StorePropertyType.OBJECT,
      defaultValue: undefined,
    },
    productData: {
      propertyType: StorePropertyType.OBJECT,
      defaultValue: undefined,
    },
    adminPrivileges: {
      propertyType: StorePropertyType.BOOLEAN,
      defaultValue: LocalStorageManager.getItem(LocalStorageKey.ADMIN_PRIVILEGES),
    },
  },
  reducer: {
    [CoreActionsType.SET_SCOPE_PRODUCT]: setScopeProductReducer,
    [CoreActionsType.CHANGE_ADMIN_PRIVILEGES]: changeAdminPrivilegesReducer,
  },
};

import * as React from 'react';
import classes from './simple-text-panel.module.scss';
import {Button, ModalLayout} from 'ui-components';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';

interface OwnProps {
  content: string;
  title?: string;
  onClose?: () => void;
}

type AllProps = OwnProps;

export const SimpleTextPanel: React.FC<AllProps> = (props: AllProps) => {
  const {title, content, onClose} = props;
  const {t} = useTranslation();

  return (
    <div className={classes.Container}>
      <ModalLayout
        footer={
          onClose && (
            <Button className={classes.Footer} onClick={onClose}>
              {t(TransKeys.GENERAL.ACTIONS.CLOSE)}
            </Button>
          )
        }
      >
        <div className={classes.Body}>
          {title && <div className={classes.Title}>{title}</div>}
          <div className={classes.Content}>{content}</div>
        </div>
      </ModalLayout>
    </div>
  );
};

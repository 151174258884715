import * as React from 'react';
import {useMemo} from 'react';
import classNames from 'classnames';
import classes from './parameters-viewer.module.scss';
import {get, isArray, keys} from 'lodash';
import {
  AnalysisFileIcon,
  Chip,
  Link,
  ParameterSchema,
  ParametersSchema,
  ParameterType,
  SingleUseIcon,
  TextButton,
} from 'ui-components';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../constants/translation-keys';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {
  PARAMETER_TYPE_TO_MULTI_LOAD_KEY,
  useParametersMultiLoad,
} from '../../../../core/hooks/parameters-multi-load.hook';
import {exists, toArray} from 'front-core';

interface OwnProps {
  analysisTypeName: string;
  values: any;
  schema: ParametersSchema;
  className?: string;
  onParameterClick?: (value: any, parameterSchema: any) => void;
  onDuplicate?: () => void;
}

type AllProps = OwnProps;

const PARAMETER_TYPE_MODEL_CLICKABLE = [
  ParameterType.SIGNAL,
  ParameterType.METRIC,
  ParameterType.FUNNEL,
  ParameterType.FEATURE,
  ParameterType.CONTENT,
];

export const ParametersViewer: React.FC<AllProps> = (props: AllProps) => {
  const {analysisTypeName, values, schema, onParameterClick, onDuplicate, className} = props;
  const {t} = useTranslation();
  const {response: multiLoad, isLoading} = useParametersMultiLoad(values, schema);

  const renderValue = (parameter: ParameterSchema) => {
    let btnText = null;
    let icon;
    let value = values[parameter.key];

    if (parameter.type === ParameterType.QUERY_BUILDER) {
      btnText = 'View';
    }
    if (parameter.type === ParameterType.SQL) {
      btnText = 'View SQL';
    }
    if (PARAMETER_TYPE_MODEL_CLICKABLE.indexOf(parameter.type) > -1) {
      const multiLoadKey = PARAMETER_TYPE_TO_MULTI_LOAD_KEY[parameter.type];
      if (isArray(value)) {
        return value.map(v => (
          <div key={v} className={classes.ButtonWrapper}>
            <TextButton
              className={classes.Button}
              onClick={onParameterClick ? e => onParameterClick(v, parameter) : undefined}
              icon={
                get(multiLoad as any, `${multiLoadKey}.${v}.isAdHoc`) === true
                  ? SingleUseIcon
                  : undefined
              }
            >
              {multiLoad?.[multiLoadKey]?.[v]?.name}
            </TextButton>
          </div>
        ));
      } else {
        btnText = multiLoad?.[multiLoadKey]?.[value]?.name;
        icon =
          get(multiLoad as any, `${multiLoadKey}.${value}.isAdHoc`) === true
            ? SingleUseIcon
            : undefined;
      }
    }

    if (btnText) {
      return (
        <div className={classes.ButtonWrapper}>
          <TextButton
            onClick={onParameterClick ? e => onParameterClick(value, parameter) : undefined}
            icon={icon}
          >
            {btnText}
          </TextButton>
        </div>
      );
    }

    if (parameter.type === ParameterType.SELECT) {
      value = toArray(value);
      return value.map(v => <Chip className={classes.Chip} label={v} key={v} />);
    }

    if (
      [ParameterType.__MERGED_SEGMENT_GROUPS, ParameterType.__MERGED_TREATMENTS].indexOf(
        parameter.type
      ) > -1
    ) {
      value = toArray(value);
      return value.map(v => {
        if (typeof v === 'string') {
          return (
            <Chip className={classes.Chip} label={multiLoad?.['tags']?.[v]?.name || v} key={v} />
          );
        }
        return (
          <TextButton
            key={v}
            onClick={onParameterClick ? e => onParameterClick(v, parameter) : undefined}
            icon={icon}
            className={classes.Chip}
          >
            {multiLoad?.['signals']?.[v]?.name}
          </TextButton>
        );
      });
    }

    if (typeof value === ParameterType.STRING) {
      return value;
    }

    if (typeof value === ParameterType.BOOLEAN) {
      return <Chip className={classes.Chip} label={value ? 'Yes' : 'No'} />;
    }

    if (isArray(value)) {
      return value.map(v => `"${v}"`).join(', ');
    }

    return JSON.stringify(value);
  };

  const keysList = useMemo(
    () =>
      keys(schema)
        .sort((a, b) => schema[a].order - schema[b].order)
        .filter(s => {
          const v = values[schema[s].key];
          if (!exists(v)) {
            return false;
          }
          if (isArray(v) && v.length === 0) {
            return false;
          }
          return true;
        }),
    [schema, values]
  );

  return (
    <div
      className={classNames(classes.ParametersViewer, className)}
      onClick={e => e.stopPropagation()}
    >
      <div className={classes.Header}>
        <AnalysisFileIcon className={classes.HeaderIcon} />
        {analysisTypeName}
      </div>
      <div className={classes.Parameters}>
        {isLoading && <GenericLoading />}
        {keysList.map(k => (
          <div key={k} className={classes.Parameter}>
            <div className={classes.Info}>
              <div className={classes.Name} title={schema[k].name}>
                {schema[k].name}
              </div>
            </div>
            <div className={classes.Value}>{renderValue(schema[k])}</div>
          </div>
        ))}
      </div>
      <div className={classes.Actions}>
        {onDuplicate && (
          <Link className={classes.Action} onClick={onDuplicate}>
            {t(TransKeys.GENERAL.ACTIONS.RUN_WITH_OTHER_PARAMETERS)}
          </Link>
        )}
      </div>
    </div>
  );
};

ParametersViewer.defaultProps = {};

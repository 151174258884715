import classNames from 'classnames';
import classes from './layout.module.scss';

interface OwnProps {
  className?: string;
  children?: React.ReactNode;
}

type AllProps = OwnProps;

export const Filters: React.FC<AllProps> = (props: AllProps) => {
  const {children, className} = props;

  return <div className={classNames(classes.Filters, className)}>{children}</div>;
};

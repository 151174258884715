import {action, OnSuccessActionHook} from 'front-core';
import {showConfirmationMessage} from '../interface/interface.actions';
import i18n from '../../config/i18n.config';
import TransKeys from '../../constants/translation-keys';
import {GoalDTO} from '../../objects/dto/goal.dto';

export enum GoalsActionType {
  CREATE_GOAL = '@@GOALS/CREATE_GOAL',
  UPDATE_GOAL = '@@GOALS/UPDATE_GOAL',
  DELETE_GOAL = '@@GOALS/DELETE_GOAL',
}

export const createGoal = (data: GoalDTO, onSuccess?: OnSuccessActionHook) =>
  action(GoalsActionType.CREATE_GOAL, data, {onSuccess});

export const updateGoal = (data: GoalDTO, onSuccess?: OnSuccessActionHook) =>
  action(GoalsActionType.UPDATE_GOAL, data, {onSuccess});

export const deleteGoalConfirmed = (id: number, onSuccess?: OnSuccessActionHook) =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.DELETE_GOAL.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.DELETE_GOAL.CONTENT),
    },
    () => [deleteGoal(id, onSuccess)]
  );

export const deleteGoal = (id: number, onSuccess?: OnSuccessActionHook) =>
  action(GoalsActionType.DELETE_GOAL, id, {onSuccess});

import {createContext, useCallback, useEffect, useMemo, useState} from 'react';
import * as React from 'react';
import {SeriesDatetimeAttribute} from '../../../../objects/models/model-sample-series.model';
import {TreeMode} from './kpi-tree.consts';
import {UpdateMetricPositionDTO} from '../../../../objects/dto/metric-tree.dto';
import {MetricTree, NodePosition} from '../../../../objects/models/metric-tree.model';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import {useRemoteSourceStated} from 'ui-components';
import {getMetricTreeNetworkRequest} from '../../../../http/metric-tree.network-requests';
import {AppRoutes} from '../../../../constants/app-routes';
import {
  addInputMetric,
  addMetricToTree,
  removeInputMetric,
  removeMetricFromTree,
  setKPITreeMetricPositions,
} from '../../../../store/metric-tree/metric-tree.actions';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import moment from 'moment/moment';
import {TIME_FORMATS} from '../../../../constants/time-formats';
import {StringParam, useQueryParam} from 'use-query-params';
import {ModelKey} from '../../../../constants/model-key';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {CoreActionsType} from '../../../../store/core/core.actions';

export interface IKPITreeContext {
  metricTree: MetricTree;
  toDate?: string;
  setToDate: (date: string) => void;
  mode: TreeMode;
  setMode: (mode: TreeMode) => void;
  datetimeAttribute?: SeriesDatetimeAttribute;
  onAddKPI: (metricId: number, position?: NodePosition) => void;
  onRemoveKPI: (metricId: number) => void;
  onAddInputKPI: (parentMetricId: number, inputMetricId: number, position?: NodePosition) => void;
  onRemoveInputKPI: (parentMetricId: number, inputMetricId: number) => void;
  onKPIClicked: (metricId: number) => void;
  onChangeMetricPositions: (metricPositions: UpdateMetricPositionDTO[]) => void;
  onViewKPIGroup: (parentId: number) => void;
  onExpandTree: (query?: any) => void;
  teamId: number;
  onChangeTeam: (teamId: number | null) => void;
  containerRef: React.RefObject<HTMLDivElement>;
}

export const KPITreeContext = createContext<IKPITreeContext>({
  metricTree: undefined,
  toDate: undefined,
  setToDate: undefined,
  mode: undefined,
  setMode: undefined,
  datetimeAttribute: undefined,
  onAddKPI: undefined,
  onRemoveKPI: undefined,
  onAddInputKPI: undefined,
  onRemoveInputKPI: undefined,
  onChangeMetricPositions: undefined,
  onKPIClicked: undefined,
  onViewKPIGroup: undefined,
  onExpandTree: undefined,
  teamId: undefined,
  onChangeTeam: undefined,
  containerRef: undefined,
});

interface OwnProps {
  metricId?: number;
  toDate?: string;
  onViewKPIGroup: (parentId: number) => void;
  onExpandTree: () => void;
  datetimeAttribute?: SeriesDatetimeAttribute;
  containerRef: React.RefObject<HTMLDivElement>;
  syncDateInParams?: boolean;
  teamId?: number;
  onChangeTeam?: (teamId: number) => void;
  children: any;
}

const formatDateForAPI = (date: string) =>
  moment.utc(date, 'YYYY-MM-DD').format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT);

export const KPITreeContextProvider: React.FC<OwnProps> = (props: OwnProps) => {
  const {
    metricId,
    toDate: toDateFromProps,
    datetimeAttribute,
    containerRef,
    onViewKPIGroup,
    onExpandTree,
    syncDateInParams = false,
    teamId,
    onChangeTeam,
    children,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const defaultDate = useMemo(
    () => moment.utc().isoWeekday(1).format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT),
    []
  );
  const [toDateFromParam, setToDateToParam] = useQueryParam('date', StringParam);
  const [toDate, setToDate_] = useState<string>(toDateFromProps || toDateFromParam || defaultDate);
  const setToDate = useCallback(
    toDate => {
      setToDate_(toDate);
      if (syncDateInParams) {
        setToDateToParam(toDate, 'replaceIn');
      }
    },
    [setToDate_, syncDateInParams, setToDateToParam]
  );
  const [mode, setMode] = useState(TreeMode.VIEW);
  const {source: metricTree, exec: getMetricTreeNR} = useRemoteSourceStated({
    networkRequest: getMetricTreeNetworkRequest,
  });
  const getMetricTree = useCallback(() => {
    const query = {
      toDate: formatDateForAPI(toDate),
      datetimeAttribute,
      teamId,
    };
    if (metricId) {
      query['rootMetricId'] = metricId;
    }
    getMetricTreeNR(query);
  }, [getMetricTreeNR, metricId, toDate, datetimeAttribute, teamId]);
  const onKPIClicked = useCallback(
    metricId => history.push(AppRoutes.viewMetric(metricId)),
    [history]
  );
  const onAddKPI = useCallback(
    (metricId: number, position?: NodePosition) => {
      dispatch(
        addMetricToTree({
          metricId,
          teamId,
          position: position || {x: 0, y: 0},
        })
      );
    },
    [dispatch, teamId]
  );
  const onRemoveKPI = useCallback(
    (metricId: number) => dispatch(removeMetricFromTree({metricId, teamId})),
    [dispatch, teamId]
  );
  const onAddInputKPI = useCallback(
    (parentMetricId: number, inputMetricId: number, position?: NodePosition) =>
      dispatch(
        addInputMetric({
          teamId,
          rootMetricId: parentMetricId,
          inputMetricId,
          position,
        })
      ),
    [dispatch, teamId]
  );
  const onRemoveInputKPI = useCallback(
    (parentMetricId: number, inputMetricId: number) =>
      dispatch(
        removeInputMetric({
          teamId,
          rootMetricId: parentMetricId,
          inputMetricId,
        })
      ),
    [dispatch, teamId]
  );
  const onChangeMetricPositions = useCallback(
    (metricPositions: UpdateMetricPositionDTO[]) =>
      dispatch(
        setKPITreeMetricPositions({
          items: metricPositions,
          teamId,
        })
      ),
    [dispatch, teamId]
  );

  useEffect(() => {
    getMetricTree();
  }, [getMetricTree]);
  useEffect(() => {
    const listener = action => {
      const {modelKey} = action.payload;
      if (modelKey === ModelKey.INPUT_METRIC || modelKey === ModelKey.METRIC_TREE_NODE) {
        getMetricTree();
      }
    };
    dispatch(registerActionListener(CoreActionsType.MODEL_UPDATED, listener));
    return () => {
      dispatch(removeActionListener(CoreActionsType.MODEL_UPDATED, listener));
    };
  }, [dispatch, getMetricTree]);

  const contextValue = useMemo(
    () => ({
      metricTree,
      mode,
      toDate: formatDateForAPI(toDate),
      datetimeAttribute,
      setMode,
      onAddInputKPI,
      onAddKPI,
      onRemoveKPI,
      onRemoveInputKPI,
      onChangeMetricPositions,
      onKPIClicked,
      onViewKPIGroup,
      onExpandTree,
      setToDate,
      teamId,
      onChangeTeam,
      containerRef,
    }),
    [
      metricTree,
      mode,
      toDate,
      datetimeAttribute,
      onAddInputKPI,
      onRemoveInputKPI,
      onKPIClicked,
      onAddKPI,
      onRemoveKPI,
      onViewKPIGroup,
      onExpandTree,
      setToDate,
      onChangeMetricPositions,
      setMode,
      teamId,
      onChangeTeam,
      containerRef,
    ]
  );

  return (
    <KPITreeContext.Provider value={contextValue}>
      {!metricTree && <GenericLoading />}
      {metricTree && children}
    </KPITreeContext.Provider>
  );
};

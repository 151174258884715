import yup from '../config/yup.config';
import {ValidationError} from 'yup';
import {values} from 'lodash';

export const nullableValues = (value: any): any[] => [null, ...values(value)];

function validateArrayByPosition(...args): any {
  const validators = args[0];
  // @ts-ignore
  return this.test({
    name: 'byPosition',
    test: async (value: any[], context) => {
      const errors = [];
      const rootPath = context.path;
      for (const index in value) {
        const validator = validators[index];
        if (!validator) {
          throw new Error(`byPosition: Missing validator at index ${index}`);
        }
        try {
          await validator.validate(value[index], {abortEarly: false});
        } catch (e: any) {
          for (const err of e.inner) {
            errors.push(
              new ValidationError(
                err?.message,
                null,
                err?.path ? `${rootPath}.${index}.${err.path}` : `${rootPath}.${index}`
              )
            );
          }
        }
      }
      if (errors.length > 0) {
        return new ValidationError(errors);
      }
      return true;
    },
  });
}

function eachOneArrayValidator(...args): any {
  const getValidator = args[0];
  // @ts-ignore
  return this.test({
    name: 'eachOne',
    test: async (value: any[], context) => {
      for (const index in value) {
        try {
          const validator = getValidator(value[index]);
          await validator.validate(value[index]);
          return true;
        } catch (e: any) {
          return context.createError({
            message: e?.message,
            path: e?.path ? `${context.path}.${index}${e.path}` : `${context.path}.${index}`,
          });
        }
      }
      return true;
    },
  });
}

function literalValueValidator(...args): any {
  const validationMap = args[0];
  // @ts-ignore
  return this.test({
    name: 'literalValue',
    test: async (fieldValue: any, context) => {
      const {
        createError,
        parent: {value_type, value},
      } = context;

      try {
        const validator = validationMap[value_type];
        if (!validator) {
          return true;
        }
        await validator.validate(value);
        return true;
      } catch (e: any) {
        return createError(e.message);
      }
    },
  });
}

// based on - https://github.com/jquense/yup/issues/248#issuecomment-745591507
function atLeastOneRequired(list, message) {
  // @ts-ignore
  return this.test({
    name: 'atLeastOneOf',
    message: message,
    exclusive: true,
    params: {keys: list.join(', ')},
    test: value => value == null || list.some(f => value[f] != null),
  });
}

yup.addMethod(yup.array, 'byPosition', validateArrayByPosition);
yup.addMethod(yup.array, 'eachOne', eachOneArrayValidator);
yup.addMethod(yup.mixed, 'literalValue', literalValueValidator);
yup.addMethod(yup.object, 'atLeastOneRequired', atLeastOneRequired);

import {Reducer} from 'redux';
import {LocalStorageKey} from '../../constants/local-storage-key';
import HttpClient from '../../services/http-client.service';
import {resetHubSpotConversation} from '../../config/hubspot.config';

export const logoutRequestReducer: Reducer = (state: any, action: any) => {
  localStorage.removeItem(LocalStorageKey.USER_TOKEN);
  localStorage.removeItem(LocalStorageKey.SCOPE_PRODUCT);
  resetHubSpotConversation();
  return state;
};

export const updateToken: Reducer = (state: any, action: any) => {
  const token = action.payload ? action.payload : state.token;

  if (!token) {
    return state;
  }

  localStorage.setItem(LocalStorageKey.USER_TOKEN, token);
  HttpClient.setHeader('Authorization', `Bearer ${token}`);
  return state;
};

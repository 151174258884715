import * as React from 'react';
import {MetricsRouting} from './metrics-routing.component';

interface OwnProps {}

type AllProps = OwnProps;

const Metrics: React.FC<AllProps> = (props: AllProps) => {
  return <MetricsRouting />;
};

export default Metrics;

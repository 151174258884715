import {useCallback, useMemo, useState, useContext} from 'react';
import {
  Button,
  FancyHeader,
  LabelWrapper,
  ModalLayout,
  QueryBuilder,
  QueryBuilderConfig,
  SwitchActions,
  UsersIcon,
} from 'ui-components';
import {
  CATEGORY_ID_PATH_PARAM,
  SEGMENT_ID_PATH_PARAM,
  SIGNAL_ID_PATH_PARAM,
  TABLE_COLUMN_ID_PATH_PARAM,
  TABLE_EVENT_ID_PATH_PARAM,
} from '../../../../constants/app-routes';
import classes from './segment-form-panel.module.scss';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../constants/translation-keys';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {Segment} from '../../../../objects/models/segment.model';
import {segmentDTOValidator} from '../../../../objects/dto/segment.dto';
import {createSegment, updateSegment} from '../../../../store/segments/segments.actions';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {useDispatch, useSelector} from 'react-redux';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors';
import {sharedClasses} from '../../../shared';
import {yupResolver} from '@hookform/resolvers/yup';
import {getSegmentNetworkRequest} from '../../../../http/segments.network-requests';
import {ActionKey} from '../../../../constants/action-key';
import {OnSuccessActionHook, composition, exists} from 'front-core';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {FormHiddenInputs} from '../../../shared/form/components/form-hidden-inputs.component';
import {
  TextareaFormInput,
  TextFormInput,
} from '../../../shared/form/components/shared-form-input.component';
import {contactSupport, preventSubmitOnEnter} from '../../../../utils/general.utils';
import {queryBuilderModelConfig} from '../../../../constants/query-builder';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {METADATA_KEY, PARAMETERS_METADATA_KEY} from '../../../../constants/parameters-saved-keys';
import {
  OptionTab,
  OptionTabs,
} from '../../../shared/components/general/options-tabs/options-tabs.component';
import {
  UserPropertyColumnQueryBuilder,
  createUserPropertyColumnInitialQuery,
  USER_PROPERTY_COLUMN_QUERY_BUILDER_NAME,
} from '../../../shared/core/query-builders/user-property-column-query-builder/user-property-column-query-builder.component';
import {TabHeader} from '../../../shared/components/general/tab-header/tab-header.component';
import {
  USER_PROPERTY_CONDITIONS_QUERY_BUILDER_NAME,
  UserPropertyConditionsQueryBuilder,
} from '../../../shared/core/query-builders/user-property-conditions-query-builder/user-property-conditions-query-builder.component';
import {SignalDataType, SignalType} from '../../../../objects/models/signal.model';
import {NoteText} from '../../../shared/components/general/note-text/note-text.component';
import {getComponentForQuery} from '../../../shared/core/query-builders/query-builders.utils';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {SegmentCategorySmartSelector} from '../../../shared/core/smart-selector/segment-category-smart-selector.component';
import classNames from 'classnames';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {TableEntity, TableEntityBinding} from '../../../../objects/models/table.model';
import {TitleWithIcon} from '../../../shared/components/general/title/title.component';
import {Subject, Action} from '../../../../constants/permissions';
import usePermissions from '../../../../core/hooks/use-permissions.hook';
import {ConfirmationDialogContext} from '../../../../core/contexts/confirmation-dialog.context';
import {StandardCheckBox} from '../../../shared/components/general/standard-check-box/standard-check-box.component';
import {LiteralValueType, HoverHelperTip} from 'ui-components';
import {useCurrentUser} from '../../../../core/hooks/use-user.hook';
import {PanelKey} from '../../../../constants/panels';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {getTableColumnNetworkRequest} from '../../../../http/table-columns.network-requests';
import {TableColumn} from '../../../../objects/models/table-column.model';
import {get, startCase, toLower} from 'lodash';
import {withModalInactiveSourceHandler} from '../../../../core/hoc/with-modal-inactive-source-handler.hoc';
import {
  BEHAVIORAL_FIRST_OR_LAST_VALUE_QUERY_BUILDER_NAME,
  BehavioralFirstOrLastValueQueryBuilder,
  createInitialBehavioralFirstOrLastValueQuery,
} from '../../../shared/core/query-builders/behavioral-first-or-last-value-query-builder/behavioral-first-or-last-value-query-builder.component';
import {
  SIGNAL_QUERY_BUILDER_NAME,
  SignalQueryBuilder,
} from '../../../shared/core/query-builders/signal-builder/signal-query-builder.component';
import {SignalSmartSelectorKey} from '../../../shared/core/smart-selector/signal-smart-selector.component';
import {BEHAVIORAL_FIRST_OR_LAST_VALUE_PARAMETER_MAPPING} from '../../../shared/core/query-builders/query-builders.config';
import {useFeatureIsOn} from '@growthbook/growthbook-react';
import {FeatureFlag} from '../../../../constants/feature-flags';
import {useDemoProduct} from '../../../../core/hooks/use-demo-product.hook';
import {withDisableDemoProduct} from '../../../../core/hoc/with-disable-demo-product.hoc';
import {getEntityIcon} from '../../../../constants/entity.consts';
import {TableEvent} from '../../../../objects/models/table-event.model';
import {getTableEventNetworkRequest} from '../../../../http/table-events.network-requests';

interface OwnProps {
  tableColumn?: TableColumn;
  tableEvent?: TableEvent;
  segment?: Segment;
  onSubmit?: (data: Partial<Segment>) => void;
  onSuccess?: OnSuccessActionHook;
  onClose?: () => void;
  panelId?: string;
  cloneMode?: boolean;
  disabled?: boolean;
  [SEGMENT_ID_PATH_PARAM]?: number;
  [CATEGORY_ID_PATH_PARAM]?: number;
  [TABLE_COLUMN_ID_PATH_PARAM]?: number;
  [TABLE_EVENT_ID_PATH_PARAM]?: number;
}

type AllProps = OwnProps;

const createInitialSegment = (params: {
  tableColumn?: TableColumn;
  tableEvent?: TableEvent;
}): Partial<Segment> => {
  const {tableColumn, tableEvent} = params;
  let name = '';
  let signalDefinition = null;
  let signalDataType = null;
  let entity = null;

  if (!exists(tableColumn) && !exists(tableEvent)) {
    return {};
  }

  if (exists(tableColumn)) {
    name = startCase(toLower(tableColumn.name));
    signalDefinition = createUserPropertyColumnInitialQuery(tableColumn);
    signalDataType =
      COLUMN_TYPE_TO_SIGNAL_DATA_TYPE_MAP[
        signalDefinition[PARAMETERS_METADATA_KEY]?.[METADATA_KEY.TABLE_COLUMN_TYPE_KEY]
      ];
    entity = tableColumn.tableEntity;
  }

  if (exists(tableEvent)) {
    name = startCase(toLower(tableEvent.name));
    signalDefinition = createInitialBehavioralFirstOrLastValueQuery(tableEvent);
    entity = tableEvent.tableEntity;
  }

  return {
    name,
    signalDefinition,
    signalDataType,
    entity,
    shortDescription: '',
    signalType: SignalType.DIMENSION,
  };
};

const SELECTED_SEGMENT_KEY = SharedSelectionKeys.SEGMENT_FORM__SEGMENT;
const SELECTED_TABLE_COLUMN_KEY = SharedSelectionKeys.SEGMENT_FORM__TABLE_COLUMN;
const SELECTED_TABLE_EVENT_KEY = SharedSelectionKeys.SEGMENT_FORM__TABLE_EVENT;

const COLUMN_TYPE_TO_SIGNAL_DATA_TYPE_MAP = {
  string: SignalDataType.STRING,
  integer: SignalDataType.INTEGER,
  boolean: SignalDataType.BOOLEAN,
  float: SignalDataType.DECIMAL,
  date: SignalDataType.TIMESTAMP,
};

const isCustomQueryBuilder = query => {
  if (!query) {
    return false;
  }
  return getComponentForQuery(query) === undefined;
};

enum TabOptions {
  COLUMN_VALUE = 'column_value',
  CASES_CONDITION = 'cases_condition',
  FIRST_OR_LAST_EVENT_VALUE = 'first_or_last_event_aggregation',
  KPI_ACHIEVEMENT = 'kpi_achievement',
}

const getInitialTabFromSignalDefinition = query => {
  const builder = query?.[PARAMETERS_METADATA_KEY]?.[METADATA_KEY.BUILDER_COMPONENT_NAME_KEY];
  if (builder === USER_PROPERTY_CONDITIONS_QUERY_BUILDER_NAME) {
    return TabOptions.CASES_CONDITION;
  } else if (builder === BEHAVIORAL_FIRST_OR_LAST_VALUE_QUERY_BUILDER_NAME) {
    return TabOptions.FIRST_OR_LAST_EVENT_VALUE;
  } else if (builder === USER_PROPERTY_COLUMN_QUERY_BUILDER_NAME) {
    return TabOptions.COLUMN_VALUE;
  } else if (builder === SIGNAL_QUERY_BUILDER_NAME) {
    return TabOptions.KPI_ACHIEVEMENT;
  }
  // default
  return TabOptions.COLUMN_VALUE;
};

const TABS = {
  [TabOptions.COLUMN_VALUE]: {
    key: TabOptions.COLUMN_VALUE,
    title: 'Property value based',
    helperText:
      'Classify users to segments based on the value in a specific column. E.g. country. <a href="#" data-beacon-article-sidebar="37">Learn more</a>',
  },
  [TabOptions.CASES_CONDITION]: {
    key: TabOptions.CASES_CONDITION,
    title: 'Condition based',
    helperText:
      'Classify users to segments based on a condition. E.g. if a source is like %google% then add the user to Google segment. <a href="#" data-beacon-article-sidebar="37">Learn more</a>',
  },
  [TabOptions.KPI_ACHIEVEMENT]: {
    key: TabOptions.KPI_ACHIEVEMENT,
    title: 'KPI Achievement',
    helperText:
      'Classify users to segments based on the achievement of a KPI. E.g. users who have made a purchase in the last 30 days. <a href="#" data-beacon-article-sidebar="37">Learn more</a>',
  },
  [TabOptions.FIRST_OR_LAST_EVENT_VALUE]: {
    key: TabOptions.FIRST_OR_LAST_EVENT_VALUE,
    title: 'Event Property',
    helperText:
      'Classify users to segments based on the first or last value of an event property. E.g. the first value of the event property "purchase type" <a href="#" data-beacon-article-sidebar="37">Learn more</a>',
  },
};

const SegmentFormPanelComponent: React.FC<AllProps> = (props: AllProps) => {
  const {
    segment,
    tableColumn,
    tableEvent,
    onClose,
    onSuccess,
    cloneMode,
    onSubmit: onSubmitFromProps,
    disabled,
    [CATEGORY_ID_PATH_PARAM]: categoryId,
  } = props;
  const showBehavioralSegments = useFeatureIsOn(FeatureFlag.BEHAVIORAL_SEGMENTS as string);
  const showHiddenCalculateBuckets = useFeatureIsOn(FeatureFlag.SEGMENT_BUCKETING as string);
  const {t} = useTranslation();
  const notify = useAmplitude();
  const dispatch = useDispatch();
  const {can} = usePermissions();
  const user = useCurrentUser();

  const {setConfirmationDialog} = useContext(ConfirmationDialogContext);
  const {openSecondaryPanel} = useContext(PanelsContext);
  const {productEntities, productEntitiesMap, defaultTableEntity} = useProductData();
  const {demoProductValidator} = useDemoProduct();
  const overrideData = useMemo(() => (cloneMode ? {id: undefined} : {}), [cloneMode]);

  const formMethods = useForm({
    defaultValues: {
      categoryId,
      entity: tableColumn?.tableEntity || defaultTableEntity,
      signalType: SignalType.DIMENSION,
      useAsExplainer: true,
      ...(segment || createInitialSegment({tableColumn, tableEvent})),
      ...overrideData,
    } as any,
    resolver: yupResolver(demoProductValidator || segmentDTOValidator.noUnknown()),
  });

  const {
    handleSubmit,
    formState: {errors, isSubmitting, isValidating},
    watch,
    control,
    setValue,
  } = formMethods;
  const editMode = Boolean(watch('id'));
  const signalDefinition = watch('signalDefinition');
  const entity = watch('entity');
  const isConfounder = watch('isConfounder');
  const useAsExplainer = watch('useAsExplainer');
  const [selectedTab, setSelectedTab_] = useState<string>(
    getInitialTabFromSignalDefinition(signalDefinition)
  );
  const setSelectedTab = useCallback(
    (tab: string) => {
      setSelectedTab_(tab);
      setValue('signalDefinition', null);
      setValue(
        'isComputed',
        tab === TabOptions.FIRST_OR_LAST_EVENT_VALUE || tab === TabOptions.KPI_ACHIEVEMENT
      );
    },
    [setSelectedTab_, setValue]
  );
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(ActionKey.CREATE_SEGMENT, ActionKey.UPDATE_SEGMENT)(state)
  );
  // only takes from the initial data - for backward compatibility
  const isCustomQuery = useMemo(() => isCustomQueryBuilder(signalDefinition), [signalDefinition]);
  const queryBuilderConfig: QueryBuilderConfig = useMemo(
    () => ({
      modelConfig: queryBuilderModelConfig,
    }),
    []
  );
  const onSwitchEntity = useCallback(
    (entity: TableEntity) => {
      setValue('entity', entity);
      setValue('signalDefinition', null);
      setValue('calculateBuckets', false);
    },
    [setValue]
  );
  const entityOptions = useMemo(
    () =>
      productEntities.map(p => ({
        key: p.key,
        label: p.name,
        onClick: () => onSwitchEntity(p.key),
        isActive: entity === p.key,
        icon: getEntityIcon(p.key),
      })),
    [productEntities, entity, onSwitchEntity]
  );
  const onSignalInfo = useCallback(
    signalId =>
      openSecondaryPanel(PanelKey.SIGNAL_DEFINITION_PANEL, {
        [SIGNAL_ID_PATH_PARAM]: signalId,
      }),
    [openSecondaryPanel]
  );

  const onSubmit = useCallback(
    data => {
      const confounderAdded = isConfounder && !segment?.isConfounder;
      const onApprove = () => {
        if (onSubmitFromProps) {
          onSubmitFromProps(data);
          return;
        }
        const onActionSuccess = (res, action) => {
          onSuccess && onSuccess(res, action);
          onClose();
        };
        const action = editMode
          ? updateSegment(data, onActionSuccess)
          : createSegment(data, onActionSuccess);
        dispatch(action);
        notify(AmplitudeEvent.SEGMENT_SAVE_CLICKED, {
          userId: user.id,
        });
      };

      if (confounderAdded) {
        setConfirmationDialog({
          title: t(TransKeys.SEGMENT_FORM.CONFOUNDER_WARNING_DIALOG.TITLE),
          content: t(TransKeys.SEGMENT_FORM.CONFOUNDER_WARNING_DIALOG.CONTENT),
          onApprove,
        });
      } else {
        onApprove();
      }
    },
    [
      dispatch,
      editMode,
      onSubmitFromProps,
      onClose,
      onSuccess,
      user,
      notify,
      isConfounder,
      segment,
      setConfirmationDialog,
      t,
    ]
  );
  const onSignalDefinitionChanged = useCallback(
    (query: any) => {
      setValue('signalDefinition', query);
      if (query === null) {
        setSelectedTab_(getInitialTabFromSignalDefinition(query));
        return;
      }
      switch (selectedTab) {
        case TabOptions.COLUMN_VALUE:
          setValue(
            'signalDataType',
            COLUMN_TYPE_TO_SIGNAL_DATA_TYPE_MAP[
              query[PARAMETERS_METADATA_KEY]?.[METADATA_KEY.TABLE_COLUMN_TYPE_KEY]
            ]
          );
          break;
        case TabOptions.CASES_CONDITION:
          setValue('signalDataType', SignalDataType.STRING);
          break;
        case TabOptions.FIRST_OR_LAST_EVENT_VALUE:
          setValue(
            'signalDataType',
            COLUMN_TYPE_TO_SIGNAL_DATA_TYPE_MAP[
              get(query, BEHAVIORAL_FIRST_OR_LAST_VALUE_PARAMETER_MAPPING.table_column_literal_type)
            ]
          );
          break;
        case TabOptions.KPI_ACHIEVEMENT:
          setValue('signalDataType', SignalDataType.BOOLEAN);
          break;
      }
    },
    [setValue, selectedTab, setSelectedTab_]
  );
  const onCreateCategory = useCallback(() => {
    openSecondaryPanel(PanelKey.SEGMENT_CATEGORY_FORM_PANEL, {
      onSuccess: category => {
        setValue('categoryId', category.id);
      },
    });
  }, [openSecondaryPanel, setValue]);
  const tabWrapper = useCallback(
    (tabKey: string, children: any) => (
      <div className={classes.SegmentTab} key={tabKey}>
        <TabHeader
          size={'small'}
          title={TABS[tabKey]?.title}
          subTitle={t(TABS[tabKey]?.helperText)}
        />
        {children}
        <NoteText
          text={t(TransKeys.SUPPORT.MISSING_PROPERTY)}
          buttonText={t(TransKeys.SUPPORT.SUPPORT_BUTTON_TEXT)}
          onButtonClicked={contactSupport}
        />
      </div>
    ),
    [t]
  );
  const tabs: OptionTab[] = useMemo(() => {
    const localErrors = errors['signalDefinition'];
    const filters = {
      entityBinding: TableEntityBinding.DEFAULT,
      entityContext: entity,
    };
    return [
      {
        key: TabOptions.COLUMN_VALUE,
        title: TABS[TabOptions.COLUMN_VALUE].title,
        helperText: t(TABS[TabOptions.COLUMN_VALUE].helperText),
        render: () => {
          const tableColumnType =
            signalDefinition?.[PARAMETERS_METADATA_KEY]?.[METADATA_KEY.TABLE_COLUMN_TYPE_KEY];
          const showCalculateBuckets = [LiteralValueType.INTEGER, LiteralValueType.FLOAT].includes(
            tableColumnType
          );
          return tabWrapper(
            TabOptions.COLUMN_VALUE,
            <>
              <Controller
                render={({field}) => (
                  <UserPropertyColumnQueryBuilder
                    query={field.value}
                    onChange={v => onSignalDefinitionChanged(v || null)}
                    errors={localErrors}
                    filters={filters}
                  />
                )}
                name={'signalDefinition'}
                control={control}
              />
              {showHiddenCalculateBuckets && showCalculateBuckets && (
                <Controller
                  render={({field}) => (
                    <div className={classes.CalculateBuckets}>
                      <StandardCheckBox checked={field.value} onChange={field.onChange} />
                      <span className={classes.Label}>
                        {t(TransKeys.SEGMENT_FORM.INPUTS.CALCULATE_BUCKETS.LABEL)}
                      </span>
                      <HoverHelperTip
                        className={classes.HoverHelperTip}
                        title={t(TransKeys.SEGMENT_FORM.INPUTS.CALCULATE_BUCKETS.HELPER_TEXT)}
                      />
                    </div>
                  )}
                  name={'calculateBuckets'}
                  control={control}
                />
              )}
            </>
          );
        },
      },
      {
        key: TabOptions.CASES_CONDITION,
        title: TABS[TabOptions.CASES_CONDITION].title,
        helperText: t(TABS[TabOptions.CASES_CONDITION].helperText),
        render: () =>
          tabWrapper(
            TabOptions.CASES_CONDITION,
            <Controller
              render={({field}) => (
                <UserPropertyConditionsQueryBuilder
                  query={field.value}
                  onChange={v => onSignalDefinitionChanged(v || null)}
                  errors={localErrors}
                  filters={filters}
                />
              )}
              name={'signalDefinition'}
              control={control}
            />
          ),
      },
      showBehavioralSegments
        ? {
            key: TabOptions.FIRST_OR_LAST_EVENT_VALUE,
            title: TABS[TabOptions.FIRST_OR_LAST_EVENT_VALUE].title,
            helperText: TABS[TabOptions.FIRST_OR_LAST_EVENT_VALUE].helperText,
            render: () => {
              return tabWrapper(
                TabOptions.FIRST_OR_LAST_EVENT_VALUE,
                <Controller
                  render={({field}) => (
                    <BehavioralFirstOrLastValueQueryBuilder
                      query={field.value}
                      onChange={v => onSignalDefinitionChanged(v || null)}
                      errors={localErrors}
                      filters={{
                        entityContext: entity,
                        entityBinding: TableEntityBinding.TWO_WAY,
                      }}
                    />
                  )}
                  name={'signalDefinition'}
                  control={control}
                />
              );
            },
          }
        : null,
      showBehavioralSegments
        ? {
            key: TabOptions.KPI_ACHIEVEMENT,
            title: TABS[TabOptions.KPI_ACHIEVEMENT].title,
            helperText: TABS[TabOptions.KPI_ACHIEVEMENT].helperText,
            render: () => {
              return tabWrapper(
                TabOptions.KPI_ACHIEVEMENT,
                <Controller
                  render={({field}) => (
                    <SignalQueryBuilder
                      query={field.value}
                      onChange={v => onSignalDefinitionChanged(v || null)}
                      errors={localErrors}
                      include={[SignalSmartSelectorKey.METRICS]}
                      onSignalInfo={onSignalInfo}
                      filters={{
                        entityContext: entity,
                        entityBinding: TableEntityBinding.TWO_WAY,
                        templates: ['bounded_action_ts', 'bounded_actions_ts', 'bounded_action_nu'],
                      }}
                    />
                  )}
                  name={'signalDefinition'}
                  control={control}
                />
              );
            },
          }
        : null,
    ].filter(Boolean);
  }, [
    onSignalDefinitionChanged,
    control,
    errors,
    tabWrapper,
    onSignalInfo,
    entity,
    t,
    signalDefinition,
    showHiddenCalculateBuckets,
    showBehavioralSegments,
  ]);
  const isDisabled = useMemo(
    () => isLoading || isSubmitting || isValidating || disabled,
    [isLoading, isSubmitting, isValidating, disabled]
  );

  return (
    <div className={classes.Container}>
      <ModalLayout
        footer={
          <Button disabled={isDisabled} onClick={handleSubmit(onSubmit)}>
            {t(TransKeys.GENERAL.ACTIONS.SAVE)}
          </Button>
        }
      >
        {isLoading && <GenericLoading />}
        <div className={classes.CreateSegment}>
          <FancyHeader
            icon={UsersIcon}
            title={t(
              editMode ? TransKeys.SEGMENT_FORM.TITLE_EDIT : TransKeys.SEGMENT_FORM.TITLE_CREATE
            )}
            subTitle={t(TransKeys.SEGMENT_FORM.MAIN_TITLE)}
            onClose={onClose}
            className={classes.CreateSegmentHeader}
          />
          <FormProvider {...formMethods}>
            <form
              className={sharedClasses.Form}
              onKeyDown={preventSubmitOnEnter}
              onSubmit={e => {
                e.stopPropagation();
                handleSubmit(onSubmit)(e);
              }}
            >
              <FormHiddenInputs names={['id', 'signalType', 'signalDataType']} />
              <div className={sharedClasses.FormContent}>
                <div className={sharedClasses.Block}>
                  <div className={sharedClasses.Input}>
                    <TextFormInput
                      label={t(TransKeys.SEGMENT_FORM.INPUTS.NAME.LABEL)}
                      placeholder={t(TransKeys.SEGMENT_FORM.INPUTS.NAME.PLACEHOLDER)}
                      name={'name'}
                      required
                    />
                  </div>
                </div>
                <div className={sharedClasses.Block}>
                  <div className={sharedClasses.Input}>
                    <TextareaFormInput
                      placeholder={t(TransKeys.SEGMENT_FORM.INPUTS.DESCRIPTION.PLACEHOLDER)}
                      label={t(TransKeys.SEGMENT_FORM.INPUTS.DESCRIPTION.LABEL)}
                      name={'shortDescription'}
                    />
                  </div>
                </div>
                <div className={classNames(sharedClasses.Block, sharedClasses.InlineInputs)}>
                  <div className={sharedClasses.Input}>
                    <LabelWrapper label={t(TransKeys.GENERAL.LABELS.CATEGORY)}>
                      <Controller
                        render={({field}) => (
                          <SegmentCategorySmartSelector
                            placeholder={t(TransKeys.GENERAL.LABELS.SELECT)}
                            value={field.value}
                            onChange={field.onChange}
                            onCreate={onCreateCategory}
                            clearable
                          />
                        )}
                        name={'categoryId'}
                        control={control}
                      />
                    </LabelWrapper>
                  </div>
                  <div className={sharedClasses.Input}>
                    {entityOptions.length > 1 && (
                      <LabelWrapper label={t(TransKeys.SEGMENT_FORM.INPUTS.ENTITY)}>
                        {!editMode && (
                          <SwitchActions
                            actions={entityOptions}
                            showActionsLabel
                            disabled={editMode}
                          />
                        )}
                        {editMode && (
                          <TitleWithIcon
                            text={productEntitiesMap[entity].name}
                            icon={getEntityIcon(entity)}
                            size={'medium'}
                          />
                        )}
                      </LabelWrapper>
                    )}
                  </div>
                  <div className={sharedClasses.Input}>
                    <LabelWrapper
                      label={t(TransKeys.SEGMENT_FORM.INPUTS.USE_AS_EXPLAINER.LABEL)}
                      tooltipContent={t(TransKeys.SEGMENT_FORM.INPUTS.USE_AS_EXPLAINER.HELPER_TEXT)}
                    >
                      <StandardCheckBox
                        checked={useAsExplainer}
                        onChange={value => setValue('useAsExplainer', value)}
                      />
                    </LabelWrapper>
                  </div>
                  <div className={sharedClasses.Input}>
                    <LabelWrapper
                      label={t(TransKeys.SEGMENT_FORM.INPUTS.CONFOUNDER.LABEL)}
                      tooltipContent={t(TransKeys.SEGMENT_FORM.INPUTS.CONFOUNDER.HELPER_TEXT)}
                    >
                      <StandardCheckBox
                        checked={isConfounder}
                        onChange={value => setValue('isConfounder', value)}
                        disabled={!can(Subject.SEGMENT, Action.TOGGLE_SEGMENT_CONFOUNDER)}
                      />
                    </LabelWrapper>
                  </div>
                </div>

                <div className={sharedClasses.Block}>
                  <div className={sharedClasses.Input}>
                    <LabelWrapper label={t(TransKeys.GENERAL.LABELS.DEFINE)} fullWidth>
                      {!isCustomQuery && (
                        <OptionTabs
                          tabs={tabs}
                          selectedKey={selectedTab}
                          onTabChange={setSelectedTab}
                        />
                      )}
                      {isCustomQuery && (
                        <Controller
                          render={({field}) => (
                            <QueryBuilder
                              config={queryBuilderConfig}
                              query={field.value}
                              onChange={v => onSignalDefinitionChanged(v || null)}
                              errors={errors['signalDefinition']}
                            />
                          )}
                          name={'signalDefinition'}
                          control={control}
                        />
                      )}
                    </LabelWrapper>
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </ModalLayout>
    </div>
  );
};

const SegmentFormPanel = composition<AllProps>(
  SegmentFormPanelComponent,
  withModalInactiveSourceHandler,
  withDisableDemoProduct,
  withLoadBefore({
    segment: {
      selectedKey: SELECTED_SEGMENT_KEY,
      actionKey: SELECTED_SEGMENT_KEY,
      request: getSegmentNetworkRequest,
      mapPayloadFromProps: props => props[SEGMENT_ID_PATH_PARAM],
      shouldCall: props => props[SEGMENT_ID_PATH_PARAM] !== undefined,
    },
    tableColumn: {
      selectedKey: SELECTED_TABLE_COLUMN_KEY,
      actionKey: SELECTED_TABLE_COLUMN_KEY,
      request: getTableColumnNetworkRequest,
      mapPayloadFromProps: props => props[TABLE_COLUMN_ID_PATH_PARAM],
      shouldCall: props => props[TABLE_COLUMN_ID_PATH_PARAM] !== undefined,
    },
    tableEvent: {
      selectedKey: SELECTED_TABLE_EVENT_KEY,
      actionKey: SELECTED_TABLE_EVENT_KEY,
      request: getTableEventNetworkRequest,
      mapPayloadFromProps: props => props[TABLE_EVENT_ID_PATH_PARAM],
      shouldCall: props => props[TABLE_EVENT_ID_PATH_PARAM] !== undefined,
    },
  })
);

export default SegmentFormPanel;

import {NRC} from './index';
import {RequestType} from 'front-core';
import {toCamelCase, toSnakeCase} from 'front-core';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';
import {AnnotationDTO} from '../objects/dto/annotation.dto';

export const getAnnotationsNetworkRequest: NRC<void, any> = (payload: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/annotations`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data),
});

export const getAnnotationNetworkRequest: NRC<void, any> = (id: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/annotations/${id}`,
  responseTransformer: res => toCamelCase(res.data),
});

export const createAnnotationNetworkRequest: NRC<AnnotationDTO, any> = (data: AnnotationDTO) => ({
  method: RequestType.POST,
  relativeUrl: `/app/annotations`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const updateAnnotationNetworkRequest: NRC<AnnotationDTO, any> = (data: AnnotationDTO) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/annotations/${data.id}`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const updateAnnotationStatusNetworkRequest: NRC<AnnotationDTO, any> = (
  data: AnnotationDTO
) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/annotations/${data.id}/status`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const deleteAnnotationNetworkRequest: NRC<AnnotationDTO, any> = (id: number) => ({
  method: RequestType.DELETE,
  relativeUrl: `/app/annotations/${id}`,
  responseTransformer: res => toCamelCase(res.data),
});

import yup from '../../config/yup.config';

export interface SchedulingDTO {
  cronExp: string;
  timezone: string;
}

export const schedulingDTOValidator = {
  cronExp: yup.string().required(),
  timezone: yup.string().required(),
};

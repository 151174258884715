import {Box, CircularProgress, LinearProgress, makeStyles, withStyles} from '@material-ui/core';
import classes from './onboarding-progress.module.scss';

const BorderLinearProgress = withStyles(theme => ({
  root: {
    flex: 1,
    height: 16,
    borderRadius: 8,
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.1)',
  },
  colorPrimary: {
    backgroundColor: 'rgba(229,239,255,0.3)',
  },
  bar: {
    borderRadius: 8,
    backgroundColor: '#fff',
  },
}))(LinearProgress);

function CircularProgressWithLabel(props) {
  const classes = makeStyles(() => ({
    root: {
      position: 'relative',
    },
    bottom: {
      color: '#C5C9DB',
      borderRadius: '50%',
    },
    top: {
      animationDuration: '550ms',
      position: 'absolute',
      left: 0,
    },
    circle: {
      color: '#47D28F',
      strokeLinecap: 'round',
    },
  }))();

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={42}
        thickness={5}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={42}
        thickness={5}
        {...props}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {props.label}
      </Box>
    </Box>
  );
}

interface OwnProps {
  variant: 'circular' | 'linear';
  count: number;
  completedCount: number;
}

type AllProps = OwnProps;

export const OnboardingProgress: React.FC<AllProps> = (props: AllProps) => {
  const {count, completedCount, variant} = props;
  const progressValue = Math.floor((completedCount / count) * 100);

  if (variant === 'linear')
    return (
      <div className={classes.Container}>
        <BorderLinearProgress variant="determinate" value={progressValue} />
        <span className={classes.Progress}>{`${progressValue}%`}</span>
      </div>
    );
  else {
    return (
      <div className={classes.Container}>
        <CircularProgressWithLabel
          value={progressValue}
          label={
            <div className={classes.LabelContainer}>
              <div className={classes.Label}>{`${completedCount}/${count}`}</div>
            </div>
          }
        />
      </div>
    );
  }
};

import {AnalysisFolderTreeNode} from '../../../objects/models/folders-tree.model';
import {TreeData, useRemoteSourceStated, SmartTreeSelectorGroup} from 'ui-components';
import i18n from 'i18next';
import TransKeys from 'translations';
import {useEffect, useMemo} from 'react';
import {exists} from 'front-core';
import {AnalysisFolderType} from '../../../objects/models/analysis-folder.model';
import {useTranslation} from 'react-i18next';
import {getAnalysisFoldersTreeNetworkRequest} from '../../../http/analysis-folders.network-requests';

const foldersToTree = (tree: AnalysisFolderTreeNode[]): TreeData[] => {
  const queue = [...tree];
  for (const t of queue) {
    t['key'] = t.id.toString();
    t['value'] = t.id;
    t['label'] = i18n.t(TransKeys.ANALYSES_MAIN.FOLDERS_DEFAULT_NAME[t.name]?.['TITLE']) || t.name;
    if (t.children) {
      queue.push(...t.children);
    }
  }
  return tree as any;
};

interface Config {
  folderTypes?: AnalysisFolderType[];
  excludeId?: number[];
}

export const useAnalysisFoldersTree = (config: Config = {}) => {
  const {t} = useTranslation();
  const {
    source: analysisFoldersTree,
    exec: getAnalysisFolderTree,
    isLoading,
  } = useRemoteSourceStated({
    type: 'source',
    networkRequest: getAnalysisFoldersTreeNetworkRequest,
    initialValue: null,
  });

  useEffect(() => {
    getAnalysisFolderTree({
      excludeId: config.excludeId,
    });
  }, [getAnalysisFolderTree, config.excludeId]);
  const groups: SmartTreeSelectorGroup[] = useMemo(() => {
    if (!exists(analysisFoldersTree)) {
      return [];
    }
    return [
      {
        key: AnalysisFolderType.PUBLIC,
        name: '',
        label: '',
        tab: t(TransKeys.ANALYSES_MAIN.FOLDERS_DEFAULT_NAME.__PUBLIC.TITLE),
        data: foldersToTree(analysisFoldersTree.public),
        startOpen: true,
      },
      {
        key: AnalysisFolderType.SHARED,
        name: '',
        tab: t(TransKeys.ANALYSES_MAIN.FOLDERS_DEFAULT_NAME.__SHARED.TITLE),
        data: foldersToTree(analysisFoldersTree.shared),
        startOpen: true,
      },
      {
        key: AnalysisFolderType.PRIVATE,
        name: '',
        tab: t(TransKeys.ANALYSES_MAIN.FOLDERS_DEFAULT_NAME.__PRIVATE.TITLE),
        data: foldersToTree(analysisFoldersTree.private),
        startOpen: true,
      },
    ].filter(g => {
      if (g.data.length === 0) {
        return false;
      }
      if (!config.folderTypes || config.folderTypes.length === 0) {
        return true;
      }
      return config.folderTypes.indexOf(g.key) > -1;
    });
  }, [analysisFoldersTree, config.folderTypes, t]);

  return {
    groups,
    isLoading,
  };
};

import {useMemo} from 'react';
import {values} from 'lodash';
import {AggregationStrategy} from '../../../../objects/models/signal.model';
import {Select} from 'ui-components';

interface OwnProps {
  value: AggregationStrategy;
  onChange: (aggretation: AggregationStrategy) => void;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  fullWidth?: boolean;
  safeMode?: boolean;
}

const UNSAFE_AGGREGATION_STRATEGIES = [
  AggregationStrategy.FIRST_VALUE,
  AggregationStrategy.LAST_VALUE,
  AggregationStrategy.AVERAGE,
];

const AggregationStrategyNameMap = {
  [AggregationStrategy.SUM]: 'Sum',
  [AggregationStrategy.AVERAGE]: 'Average',
  [AggregationStrategy.MIN]: 'Min',
  [AggregationStrategy.MAX]: 'Max',
  [AggregationStrategy.WEIGHTED_AVERAGE]: 'Weighted Average',
  [AggregationStrategy.FIRST_VALUE]: 'First value',
  [AggregationStrategy.LAST_VALUE]: 'Last value',
};

type AllProps = OwnProps;

export const AggregationStrategyInput: React.FC<AllProps> = (props: AllProps) => {
  const {value, onChange, placeholder, disabled, error, fullWidth, safeMode} = props;

  const options = useMemo(
    () =>
      values(AggregationStrategy)
        .filter(s => !safeMode || !UNSAFE_AGGREGATION_STRATEGIES.includes(s))
        .map(o => ({
          label: AggregationStrategyNameMap[o],
          value: o,
        })),
    [safeMode]
  );

  return (
    <Select
      placeholder={placeholder}
      value={value as unknown as string}
      error={error}
      onChange={v => onChange(v as unknown as AggregationStrategy)}
      searchable={false}
      clearable={false}
      options={{options}}
      disabled={disabled}
      fullWidth={fullWidth}
    />
  );
};

AggregationStrategyInput.defaultProps = {
  safeMode: true,
};

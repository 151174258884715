import {QueryBuilderFactory} from 'ui-components';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';
import {MetricType} from '../../../../../objects/models/metric.model';
import {
  PARAMETERS_METADATA_KEY,
  METADATA_KEY,
} from '../../../../../constants/parameters-saved-keys';
import {
  ActiveUsersType,
  createActiveUsersInitialQuery,
} from '../../../../shared/core/query-builders/active-users-query-builder/active-users-query-builder.component';
import {createLevelOfActiveInitialQuery} from '../../../../shared/core/query-builders/level-of-active-query-builder/level-of-active-query-builder.component';
import {createRateInitialQuery} from '../../../../shared/core/query-builders/rate-query-builder/rate-query-builder.component';
import {createRevenueInitialQuery} from '../../../../shared/core/query-builders/revenue-query-builder/revenue-query-builder.component';
import {createRevenueChurnInitialQuery} from '../../../../shared/core/query-builders/revenue-churn-query-builder/revenue-churn-query-builder.component';
import {createPaymentRetentionInitialQuery} from '../../../../shared/core/query-builders/payment-retention-builder/payment-retention-query-builder.component';
import {createHabitMomentInitialQuery} from '../../../../shared/core/query-builders/habit-moment-query-builder/habit-moment-query-builder.component';
import {createSqlPropertyInitialQuery} from '../../../../shared/core/query-builders/custom-sql-query-builder/custom-sql-query-builder.component';
import {createBehavioralChurnInitialQuery} from '../../../../shared/core/query-builders/behavioral-churn-query-builder/behavioral-churn-query-builder.component';
import {createBoundedRevenueInitialQuery} from '../../../../shared/core/query-builders/bounded-revenue-query-builder/bounded-revenue-query-builder.component';
import {
  PeriodType,
  createPeriodOverPeriodInitialQuery,
} from '../../../../shared/core/query-builders/period-over-period-query-builder/period-over-period-query-builder.component';

export function useSignalInitialQueryFactory() {
  const {getSignalByTag} = useProductData();

  function createInitialQuery(type, entity) {
    const joinDateSignal = getSignalByTag('join_date', entity);
    const activeSignal = getSignalByTag('active', entity);
    let signalDefinition: any = {};

    switch (type) {
      case MetricType.RETENTION:
        signalDefinition = QueryBuilderFactory.createTemplate();
        signalDefinition.template = 'bounded_actions_ts';
        signalDefinition.parameters[0] = [QueryBuilderFactory.createSignalColumn(activeSignal?.id)];
        signalDefinition.parameters[1] = 1;
        signalDefinition.parameters[2] = 2;
        signalDefinition.parameters[3] = 'week';
        signalDefinition.parameters[4] = QueryBuilderFactory.createSignalColumn(joinDateSignal?.id);
        signalDefinition[PARAMETERS_METADATA_KEY] = {
          [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: 'RetentionQueryBuilder',
        };
        break;
      case MetricType.DOD:
        signalDefinition = createPeriodOverPeriodInitialQuery(PeriodType.DAILY);
        // PARAMETER_METADATA_KEY is defined by createPeriodOverPeriodInitialQuery
        break;
      case MetricType.WOW:
        signalDefinition = createPeriodOverPeriodInitialQuery(PeriodType.WEEKLY);
        // PARAMETER_METADATA_KEY is defined by createPeriodOverPeriodInitialQuery
        break;
      case MetricType.MOM:
        signalDefinition = createPeriodOverPeriodInitialQuery(PeriodType.MONTHLY);
        // PARAMETER_METADATA_KEY is defined by createPeriodOverPeriodInitialQuery
        break;
      case MetricType.CONVERSION:
        signalDefinition = QueryBuilderFactory.createTemplate();
        signalDefinition.template = 'bounded_action_ts';
        signalDefinition.parameters[0] = null;
        signalDefinition.parameters[1] = 0;
        signalDefinition.parameters[2] = 2;
        signalDefinition.parameters[3] = 'week';
        signalDefinition.parameters[4] = QueryBuilderFactory.createSignalColumn(joinDateSignal?.id);
        signalDefinition[PARAMETERS_METADATA_KEY] = {
          [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: 'ConversionQueryBuilder',
        };
        break;
      case MetricType.REVENUE:
        signalDefinition = createRevenueInitialQuery();
        // PARAMETER_METADATA_KEY is defined by createRevenueInitialQuery
        break;
      case MetricType.BOUNDED_REVENUE:
        signalDefinition = createBoundedRevenueInitialQuery(getSignalByTag, entity);
        // PARAMETER_METADATA_KEY is defined by createTotalRevenueInitialQuery
        break;
      case MetricType.PAYMENT_RETENTION:
        signalDefinition = createPaymentRetentionInitialQuery(getSignalByTag, entity);
        // PARAMETER_METADATA_KEY is defined by createPaymentRetentionInitialQuery
        break;
      case MetricType.REVENUE_CHURN:
        signalDefinition = createRevenueChurnInitialQuery();
        // PARAMETER_METADATA_KEY is defined by createRevenueChurnInitialQuery
        break;
      case MetricType.BEHAVIORAL_CHURN:
        signalDefinition = createBehavioralChurnInitialQuery(getSignalByTag, entity);
        // PARAMETER_METADATA_KEY is defined by createBehavioralChurnInitialQuery
        break;
      case MetricType.USAGE:
        signalDefinition = {};
        signalDefinition[PARAMETERS_METADATA_KEY] = {
          [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: 'UsageQueryBuilder',
        };
        break;
      case MetricType.DAU:
        signalDefinition = createActiveUsersInitialQuery(ActiveUsersType.DAILY);
        // PARAMETER_METADATA_KEY is defined by createActiveUsersInitialQuery
        break;
      case MetricType.WAU:
        signalDefinition = createActiveUsersInitialQuery(ActiveUsersType.WEEKLY);
        // PARAMETER_METADATA_KEY is defined by createActiveUsersInitialQuery
        break;
      case MetricType.MAU:
        signalDefinition = createActiveUsersInitialQuery(ActiveUsersType.MONTHLY);
        // PARAMETER_METADATA_KEY is defined by createActiveUsersInitialQuery
        break;
      case MetricType.L7:
        signalDefinition = createLevelOfActiveInitialQuery('l7');
        // PARAMETER_METADATA_KEY is defined by createLevelOfActiveInitialQuery
        break;
      case MetricType.L28:
        signalDefinition = createLevelOfActiveInitialQuery('l28');
        // PARAMETER_METADATA_KEY is defined by createLevelOfActiveInitialQuery
        break;
      case MetricType.RATE:
        signalDefinition = createRateInitialQuery();
        // PARAMETER_METADATA_KEY is defined by createRateInitialQuery
        break;
      case MetricType.HABIT_MOMENT:
        signalDefinition = createHabitMomentInitialQuery(getSignalByTag, entity);
        // PARAMETER_METADATA_KEY is defined by createHabitMomentInitialQuery
        break;
      case MetricType.CUSTOM_SQL:
        signalDefinition = createSqlPropertyInitialQuery();
      // PARAMETER_METADATA_KEY is defined by createSQLInitialQuery
    }

    return signalDefinition;
  }

  return {
    createInitialQuery,
  };
}

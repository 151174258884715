import * as React from 'react';
import classNames from 'classnames';
import classes from './goal-status.module.scss';
import {useMemo} from 'react';
import TransKeys from '../../../../../../constants/translation-keys';
import {Tooltip} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {HomepageMetric} from '../../../../../../objects/models/homepage.model';
import {last} from 'lodash';
import {CircleCheckSolidIcon, TriangleExclamationSolidIcon} from 'ui-components';
import i18n from 'i18next';
import {MetricValueType} from '../../../../../../objects/models/metric.model';
import {number2k} from 'front-core';
import {useDefaultSeries, useMetricGoalData} from '../../hooks/homepage-summary.hooks';

interface OwnProps {
  metric: HomepageMetric;
  small?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const GoalStatus: React.FC<AllProps> = (props: AllProps) => {
  const {metric, small, className} = props;
  const {t} = useTranslation();
  const goalData = useMetricGoalData(metric);
  const series = useDefaultSeries(metric);

  const goalParameters = useMemo(() => {
    if (!metric.goal) {
      return;
    }
    const lastSample = last(series.samples);
    let compareTo = lastSample.expectedValue;
    const lastSampleGoalValue = goalData?.find(g => g.datetime === lastSample.datetime)?.value;
    const isBehind = lastSampleGoalValue && lastSampleGoalValue > compareTo;

    return {
      isBehind,
      icon: isBehind ? TriangleExclamationSolidIcon : CircleCheckSolidIcon,
      helperText: isBehind
        ? i18n.t(TransKeys.HOMEPAGE.LABELS.GOAL_BEHIND_HELPER)
        : i18n.t(TransKeys.HOMEPAGE.LABELS.GOAL_AHEAD_HELPER),
      goalValue:
        metric.valueType === MetricValueType.PERCENTAGE
          ? `${number2k(metric.goal.value * 100)}%`
          : number2k(metric.goal.value),
      lastSampleGoalValue:
        metric.valueType === MetricValueType.PERCENTAGE
          ? `${number2k(lastSampleGoalValue * 100)}%`
          : number2k(lastSampleGoalValue),
      averageValue:
        metric.valueType === MetricValueType.PERCENTAGE
          ? `${number2k(compareTo * 100)}%`
          : number2k(compareTo),
      text: isBehind
        ? i18n.t(TransKeys.HOMEPAGE.LABELS.GOAL_BEHIND)
        : i18n.t(TransKeys.HOMEPAGE.LABELS.GOAL_AHEAD),
    };
  }, [metric, series, goalData]);

  if (!goalParameters) {
    return null;
  }

  return (
    <Tooltip
      title={
        <div className={classes.GoalTooltip}>
          {goalParameters.helperText}
          <div className={classes.Values}>
            {t(TransKeys.HOMEPAGE.LABELS.KPI_AVERAGE_VALUE)}: <b>{goalParameters.averageValue}</b>
          </div>
          <div>
            {t(TransKeys.HOMEPAGE.LABELS.QUARTERLY_TARGET)}: <b>{goalParameters.goalValue}</b>
          </div>
          <div>
            {t(TransKeys.HOMEPAGE.LABELS.CURRENT_TARGET_EOQ, {granularity: series.granularity})}:{' '}
            <b>{goalParameters.lastSampleGoalValue}</b>
          </div>
        </div>
      }
      placement={'top'}
      interactive={false}
    >
      <div
        className={classNames(
          classes.GoalStatus,
          goalParameters.isBehind && classes.Warn,
          small && classes.Small,
          className
        )}
      >
        <goalParameters.icon className={classes.Icon} />
        <span className={classes.Text}>{goalParameters.text}</span>
      </div>
    </Tooltip>
  );
};

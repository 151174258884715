import * as React from 'react';
import classes from './card.module.scss';
import classNames from 'classnames';
import {Tooltip} from '@material-ui/core';
import {Badge, IconButton} from 'ui-components';
import {Title} from '../title/title.component';
import {ImageViewer} from '../image-viewer/image-viewer.component';
import {isArray} from 'lodash';

export interface CardAction {
  icon: any;
  label: string;
  onClick: (e) => void;
  count?: number;
  hide?: boolean;
}

interface Block {
  render: () => JSX.Element;
  hide?: boolean;
}

interface OwnProps {
  icon?: any;
  subTitle?: string;
  title: any;
  size?: 'small' | 'default';
  clickable?: boolean;
  blocks?: Block[];
  actions?: Array<CardAction>;
  onClick?: (e) => void;
  bottomInfo?: string | JSX.Element;
  color?: string;
  image?: any;
  imageModal?: boolean;
  vertical?: boolean;
  className?: string;
  id?: string;
}

type AllProps = OwnProps;

export const Card: React.FC<AllProps> = (props: AllProps) => {
  const {
    icon: Icon,
    subTitle,
    title,
    blocks,
    actions,
    bottomInfo,
    color,
    className,
    image: Image,
    onClick,
    imageModal,
    vertical,
    clickable,
    size,
  } = props;

  return (
    <div
      style={{borderColor: color}}
      className={classNames(
        classes.Card,
        color && classes.Border,
        vertical && classes.Vertical,
        classes[size],
        className
      )}
    >
      {(typeof Image === 'string' || isArray(Image)) && (
        <ImageViewer
          onClick={onClick}
          modal={imageModal}
          className={classes.ImageWrapper}
          classes={{image: classes.Image}}
          src={Image}
        />
      )}
      {typeof Image === 'object' && !isArray(Image) && (
        <div className={classNames(classes.ImageWrapper, classes.ImagePlaceholder)}>
          <Image className={classes.PlaceholderIcon} />
        </div>
      )}
      <div className={classes.CardMain}>
        {subTitle && (
          <div className={classes.SubTitle}>
            {Icon && <Icon className={classes.Icon} style={{color}} />}
            <div className={classes.Text}>{subTitle}</div>
          </div>
        )}
        {typeof title === 'string' && (
          <Title text={title} onClick={clickable ? onClick : undefined} className={classes.Title} />
        )}
        {typeof title !== 'string' && title}
        <div className={classes.Blocks}>
          {(blocks || [])
            .filter(b => !b.hide)
            .map((b, idx) => (
              <div className={classes.Block} key={idx}>
                {b.render()}
              </div>
            ))}
        </div>
        {(bottomInfo || actions?.length > 0) && (
          <div className={classes.Bottom}>
            <div className={classes.BottomInfo}>{bottomInfo}</div>
            <div className={classes.Actions}>
              {(actions || [])
                .filter(a => !a.hide)
                .map((a, idx) => (
                  <Tooltip key={idx} title={a.label} placement={'top'}>
                    <div className={classes.Action}>
                      <Badge
                        badgeContent={a.count}
                        invisible={a.count === undefined || a.count === 0}
                      >
                        <IconButton icon={a.icon} className={classes.IconBtn} onClick={a.onClick} />
                      </Badge>
                    </div>
                  </Tooltip>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

Card.defaultProps = {
  clickable: true,
  size: 'default',
};

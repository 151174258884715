import * as React from 'react';
import classes from '../../../analysis-forms.module.scss';
import {ParameterInputWrapper} from '../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {TextInput} from 'ui-components';

interface SchemaKeysMapping {
  excluded_events: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  excluded_events: 'excluded_events',
};

interface OwnProps {
  title: string;
  onChange: (parameters: any) => void;
  value: any;
  schemaKeysMapping?: SchemaKeysMapping;
  onSignalInfo?: (value: string | number) => void;
  subTitle?: string;
  bottomHelper?: any;
  helperText?: any;
  errors?: any;
  filters?: any;
  className?: string;
}

type AllProps = OwnProps;

export const ExcludeEventsSelector: React.FC<AllProps> = (props: AllProps) => {
  const {title, subTitle, helperText, errors, value, onChange, schemaKeysMapping} = props;

  return (
    <ParameterInputWrapper
      title={title}
      subTitle={subTitle}
      helperText={helperText}
      className={classes.Parameter}
      error={errors?.[schemaKeysMapping.excluded_events]}
    >
      <TextInput
        className={classes.FitContent}
        value={value[schemaKeysMapping.excluded_events]}
        placeholder={'Enter event names'}
        onChange={v => onChange({[schemaKeysMapping.excluded_events]: v})}
        multiple
      />
    </ParameterInputWrapper>
  );
};

ExcludeEventsSelector.defaultProps = {
  schemaKeysMapping: DEFAULT_SCHEMA_KEYS_MAPPING,
};

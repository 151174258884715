import {Epic} from 'redux-observable';
import {createRequestEpic} from 'front-core';
import {ActionKey} from '../../constants/action-key';
import HttpClient from '../../services/http-client.service';
import {FunnelsActionType} from './funnels.actions';
import {
  createFunnelNetworkRequest,
  deleteFunnelNetworkRequest,
  editFunnelNetworkRequest,
  publishFunnelNetworkRequest,
} from '../../http/funnels.network-requests';
import {modelCreated, modelDeleted, modelUpdated, notifyEvent} from '../core/core.actions';
import {ModelKey} from '../../constants/model-key';
import {funnelToastCreator} from '../toasts.actions';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {
  errorExtractor,
  demoProductLimitedActionsFilter,
  getDMPToastErrorMessage,
} from '../store.utils';
import {showToastMessage} from '../interface/interface.actions';
import {ToastType} from '../../objects/system/toast-type.enum';

export const createFunnelEpic: Epic = createRequestEpic(
  {
    types: [FunnelsActionType.CREATE_FUNNEL],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.CREATE_FUNNEL,
    request: createFunnelNetworkRequest,
    onSuccess: (funnel, _, {metadata}) => [
      notifyEvent(AmplitudeEvent.FUNNEL_CREATED, {
        id: funnel.id,
        is_ad_hoc: funnel.isAdHoc,
        ...metadata,
      }),
      funnelToastCreator('CREATE_SUCCESS'),
      modelCreated(funnel, ModelKey.FUNNEL),
    ],
    onError: err_ => {
      const err = errorExtractor(err_);
      if (err.errorCode === 5002) {
        return [showToastMessage('Funnel name already exists', ToastType.ERROR)];
      }
      if (err.errorCode === 1001) {
        return [showToastMessage(err.message, ToastType.ERROR)];
      }
      const toastMessage = getDMPToastErrorMessage(err, 'funnel');
      if (toastMessage) {
        return [
          showToastMessage(toastMessage, ToastType.ERROR),
          notifyEvent(AmplitudeEvent.CREATE_FUNNEL_ERROR, err),
        ];
      }
      return [funnelToastCreator('CREATE_ERROR')];
    },
  },
  HttpClient
);

export const updateFunnelEpic: Epic = createRequestEpic(
  {
    types: [FunnelsActionType.UPDATE_FUNNEL],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.UPDATE_FUNNEL,
    request: editFunnelNetworkRequest,
    onSuccess: (funnel, _, {metadata}) => [
      notifyEvent(AmplitudeEvent.FUNNEL_UPDATED, {
        id: funnel.id,
        is_ad_hoc: funnel.isAdHoc,
        ...metadata,
      }),
      funnelToastCreator('UPDATE_SUCCESS'),
      modelUpdated(funnel, ModelKey.FUNNEL),
    ],
    onError: err_ => {
      const err = errorExtractor(err_);
      const toastMessage = getDMPToastErrorMessage(err, 'funnel');
      if (toastMessage) {
        return [
          showToastMessage(toastMessage, ToastType.ERROR),
          notifyEvent(AmplitudeEvent.UPDATE_FUNNEL_ERROR, err),
        ];
      }
      return [funnelToastCreator('UPDATE_ERROR')];
    },
  },
  HttpClient
);

export const deleteFunnelEpic: Epic = createRequestEpic(
  {
    types: [FunnelsActionType.DELETE_FUNNEL],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.DELETE_FUNNEL,
    request: deleteFunnelNetworkRequest,
    onSuccess: (res, payload) => [
      notifyEvent(AmplitudeEvent.FUNNEL_DELETED, {id: payload}),
      funnelToastCreator('DELETE_SUCCESS'),
      modelDeleted(payload, ModelKey.FUNNEL),
    ],
    onError: err => [funnelToastCreator('DELETE_ERROR')],
  },
  HttpClient
);

export const publishFunnelEpic: Epic = createRequestEpic(
  {
    types: [FunnelsActionType.PUBLISH_AD_HOC_FUNNEL],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.PUBLISH_FUNNEL,
    request: publishFunnelNetworkRequest,
    onSuccess: funnel => [
      notifyEvent(AmplitudeEvent.FUNNEL_PUBLISHED, {id: funnel.id}),
      funnelToastCreator('UPDATE_SUCCESS'),
      modelUpdated(funnel, ModelKey.FUNNEL),
    ],
    onError: err => [funnelToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);

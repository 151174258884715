export enum AnalysisSelectorVariant {
  DEFAULT = 'DEFAULT',
  INLINE = 'INLINE',
}

export interface AnalysisSelectorProps {
  title?: string;
  subTitle?: string;
  helperText?: string;
  onChange: (parameters: any) => void;
  value: any;
  errors?: any;
  schemaKeysMapping?: any;
  variant?: AnalysisSelectorVariant;
  className?: string;
}

import {values} from 'lodash';
import {Annotation, AnnotationType} from '../models/annotation.model';
import yup from '../../config/yup.config';

export type AnnotationDTO = Partial<Annotation>;

export const annotationDTOValidator = yup.object().shape({
  id: yup.number().optional().nullable(),
  title: yup.string().required(),
  type: yup.string().required().oneOf(values(AnnotationType)),
  timestamp: yup.string().required(),
});

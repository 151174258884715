import * as React from 'react';
import {useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import {
  GRANULARITY_ORDER,
  ModelSampleSeries,
  ModelSampleSeriesModel,
  ModelSampleSeriesType,
} from '../../../../objects/models/model-sample-series.model';
import {
  AnalysisFileIcon,
  Button,
  FancyBlock,
  GraphIcon,
  LineChart,
  Select,
  useRemoteSourceStated,
} from 'ui-components';
import {getModelSampleSeriesNetworkRequest} from '../../../../http/model-sample-series.network-requests';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {TIME_FORMATS} from '../../../../constants/time-formats';
import classes from './view-model-series.module.scss';
import {capitalize, sum} from 'lodash';
import pluralize from 'pluralize';
import {exists, number2k, safeDivision} from 'front-core';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {ModelSeriesWarningTable} from './components/model-series-warnining-table/model-series-warnining-table.component';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import moment from 'moment/moment';
import i18n from 'i18next';

interface OwnProps {
  modelId: number;
  modelType: ModelSampleSeriesModel;
  createdOn?: string;
  signalId?: number;
  entity?: string;
  isPercentageValue?: boolean;
  onRunKPIAnalysis?: () => void;
  className?: string;
}

type AllProps = OwnProps;

const generateOptionsLabel = (s: ModelSampleSeries) => {
  let prefix = '';
  let model = `${i18n.t(TransKeys.MODELS[s.modelType.toUpperCase()])}`;
  let granularity = `${i18n
    .t(TransKeys.GENERAL.LABELS.GRANULARITY[s.granularity.toUpperCase()])
    .toLowerCase()}`;
  if (model !== 'KPI') {
    model = capitalize(model);
  }
  let name = i18n.t(TransKeys.MODEL_SAMPLE_TYPE[s.seriesType.toUpperCase()]).toLowerCase();

  if (s.discriminator) {
    prefix = `${s.discriminator} `;
    model = '';
  }
  return `${model} ${prefix}${name} (${granularity})`;
};

const WEEKLY_SERIES_INDEX = 1;

export const ViewModelSeries: React.FC<AllProps> = (props: AllProps) => {
  const {
    modelId,
    modelType,
    signalId,
    entity,
    createdOn,
    onRunKPIAnalysis,
    isPercentageValue,
    className,
  } = props;
  const {productEntitiesMap} = useProductData();
  const {t} = useTranslation();
  const {
    source: modelSampleSeries,
    exec: getModelSampleSeries,
    isLoading,
  } = useRemoteSourceStated({
    networkRequest: getModelSampleSeriesNetworkRequest,
    transformer: data =>
      data
        .map(s => ({
          ...s,
          order: (s.discriminator ? 1 : 0) + GRANULARITY_ORDER.indexOf(s.granularity),
        }))
        .sort((a, b) => a.order - b.order),
  });
  const [selectedSeriesIndex, setSelectedSeriesIndex] = useState(WEEKLY_SERIES_INDEX);
  const options = useMemo(() => {
    if (!modelSampleSeries || modelSampleSeries.length === 0) {
      return [];
    }
    return modelSampleSeries.map((s, idx) => ({
      label: generateOptionsLabel(s),
      value: idx,
    }));
  }, [modelSampleSeries]);
  const selectedSeries = useMemo(
    () => modelSampleSeries?.[selectedSeriesIndex],
    [modelSampleSeries, selectedSeriesIndex]
  );
  const chartData = useMemo(() => {
    if (!selectedSeries) {
      return undefined;
    }
    const valuesDataset = selectedSeries.samples.map(
      s =>
        ({
          x: s.datetime,
          y: isPercentageValue ? (s.value || 0) * 100 : s.value || 0,
          dashed: s.isPartial,
        }) as any
    );

    let avgLine = undefined;
    let denominatorDataset = undefined;
    if (
      [
        ModelSampleSeriesType.MEASURE_OVER_TIME,
        ModelSampleSeriesType.FEATURE_USAGE_OVER_TIME,
      ].indexOf(selectedSeries.seriesType) > -1 &&
      selectedSeries.hasDenominator
    ) {
      denominatorDataset = selectedSeries.samples.map(
        s =>
          ({
            x: s.datetime,
            y: s.denominator || 0,
            dashed: s.isPartial,
          }) as any
      );
      const total = sum(
        selectedSeries.samples.map(s => s.value * s.denominator * (isPercentageValue ? 100 : 1))
      );
      const avg = safeDivision(total, sum(selectedSeries.samples.map(s => s.denominator)), true);
      avgLine = {
        position: avg,
        title: `Average ${number2k(avg)}${isPercentageValue ? '%' : ''}`,
        direction: 'horizontal',
        color: '#B8ADF9',
      };
    }

    const datasets: any[] = [
      {
        id: 'series',
        label: 'Values',
        data: valuesDataset,
        dashedDescription: 'Incomplete data',
      },
    ];
    if (denominatorDataset) {
      datasets.push({
        id: `denominator`,
        label: entity ? pluralize(productEntitiesMap[entity].name) : 'denominator',
        data: denominatorDataset,
        yAxis: 'secondary',
      });
    }

    return {
      datasets,
      displayedDatasetIds: ['series'],
      options: {
        labels: {
          dateFormat: TIME_FORMATS.READABLE_DATE,
          type: 'date',
        },
        yLabelSuffix: isPercentageValue ? '%' : undefined,
        yAxisMaxTicks: 5,
        lines: avgLine ? [avgLine] : undefined,
      },
    } as any;
  }, [selectedSeries, entity, productEntitiesMap, isPercentageValue]);
  const warnings = useMemo(() => {
    if (!selectedSeries) {
      return undefined;
    }
    return selectedSeries.samples.filter(s => exists(s.warning)).reverse();
  }, [selectedSeries]);
  const isJustCreated = useMemo(() => {
    if (!exists(createdOn)) {
      return false;
    }
    const createdOnDate = moment.utc(createdOn, TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT);
    return moment.utc().diff(createdOnDate, 'hour') < 12 && modelSampleSeries?.length === 0;
  }, [createdOn, modelSampleSeries]);

  useEffect(() => {
    getModelSampleSeries({
      modelId,
      modelType,
      signalId,
    });
  }, [modelId, modelType, signalId, getModelSampleSeries]);

  if (isLoading) {
    return <GenericLoading />;
  }

  if (isJustCreated) {
    return (
      <div className={classes.EmptyState}>
        {t(TransKeys.VIEW_MODEL_SERIES.NOT_READY_YET_EMPTY_STATE)}
      </div>
    );
  }

  if (modelSampleSeries?.length === 0) {
    return <div className={classes.EmptyState}>No samples were found</div>;
  }

  return (
    <div className={classNames(classes.ViewModelSeries, className)}>
      <div className={classes.Head}>
        <div className={classes.SelectSeries}>
          {options.length > 1 && (
            <Select
              prefix={'Data'}
              icon={GraphIcon}
              dropdownButtonClassName={classes.SelectButton}
              options={{options}}
              value={selectedSeriesIndex}
              onChange={setSelectedSeriesIndex as any}
              searchable={options.length > 5}
              clearable={false}
              capitalize={false}
              sortValues={false}
            />
          )}
        </div>
        <div className={classes.Actions}>
          <Button
            onClick={() => onRunKPIAnalysis()}
            variant={'outlined'}
            icon={AnalysisFileIcon}
            caps={false}
            disabled={!onRunKPIAnalysis}
            helperText={onRunKPIAnalysis ? '' : 'Soon'}
          >
            {t(TransKeys.VIEW_MODEL_SERIES.ACTIONS.RUN_KPI_ANALYSIS)}
          </Button>
        </div>
      </div>
      {chartData && (
        <FancyBlock
          title={t(TransKeys.MODEL_SAMPLE_TYPE[selectedSeries.seriesType.toUpperCase()])}
          addPadding
          className={classes.ChartBlock}
        >
          <div className={classes.Chart}>
            <LineChart {...chartData} />
          </div>
        </FancyBlock>
      )}
      {warnings && (
        <div className={classes.Warnings}>
          <div className={classes.Title}>
            <b>Warnings</b>
          </div>
          <ModelSeriesWarningTable warnings={warnings} />
        </div>
      )}
    </div>
  );
};

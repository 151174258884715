import {ModelKey} from '../../constants/model-key';

export enum ModelSampleSeriesModel {
  METRIC = 'metric',
  FEATURE = 'feature',
  FUNNEL = 'funnel',
}

export enum ModelSeriesGranularity {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export enum SeriesDatetimeAttribute {
  SAMPLE_DATETIME = 'sample_datetime',
  DATETIME = 'datetime',
}

export const GRANULARITY_ORDER = [
  ModelSeriesGranularity.DAY,
  ModelSeriesGranularity.WEEK,
  ModelSeriesGranularity.MONTH,
  ModelSeriesGranularity.YEAR,
];

export const MODEL_SAMPLE_SERIES_MODEL_TO_MODEL_KEY = {
  [ModelSampleSeriesModel.METRIC]: ModelKey.METRIC,
  [ModelSampleSeriesModel.FEATURE]: ModelKey.FEATURE,
};

export enum ModelSampleSeriesType {
  MEASURE_OVER_TIME = 'measure_over_time',
  FEATURE_USAGE_OVER_TIME = 'feature_usage_over_time',
  LINEAR_FUNNEL_STEP_COUNT_OVER_TIME = 'linear_funnel_step_count_over_time',
  FUNNEL_CONVERSION_RATE_FROM_PREV_STEP = 'funnel_conversion_rate_from_prev_step',
  FUNNEL_CONVERSION_RATE_FROM_INITIAL_STEP = 'funnel_conversion_rate_from_initial_step',
  FUNNEL_COMPLETION_RATE = 'funnel_completion_rate',
}

export interface ModelSample {
  id: number;
  value: number;
  expectedValue: number;
  upper: number;
  lower: number;
  denominator: number;
  sampleDatetime: string;
  datetime: string;
  warning: string;
  isPartial: boolean;
  rcaAnalysisId: number;
  rcaAnalysisResultId: number;
}

export interface PredictedSample {
  value: number;
  upper: number;
  lower: number;
  denominator: number;
  sampleDatetime: string;
  datetime: string;
}

export interface ModelSampleSeries {
  id?: number;
  modelId: number;
  modelType: ModelSampleSeriesModel;
  seriesType: ModelSampleSeriesType;
  samples: ModelSample[];
  predictedSamples: PredictedSample[];
  discriminator?: string;
  granularity: ModelSeriesGranularity;
  signalId?: number;
  hasDenominator: boolean;
}

import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import {Button, FancyHeader, ModalLayout, WandMagicSparklesSolidIcon} from 'ui-components';
import TransKeys from '../../../../constants/translation-keys';
import classes from './how-to-improve-kpi-panel.module.scss';
import {useTranslation} from 'react-i18next';
import {AnalysisTypeId} from '../../../../constants/analysis-type-id';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {PanelKey} from '../../../../constants/panels';
import {ANALYSIS_TYPE_ID_PATH_PARAM} from '../../../../constants/app-routes';

interface OwnProps {
  onClose?: () => void;
}

type AllProps = OwnProps;

const ANALYSIS_IDS = [
  AnalysisTypeId.GOAL_DRIVERS,
  AnalysisTypeId.KPI_ANALYSIS,
  AnalysisTypeId.HABIT_MOMENTS,
  AnalysisTypeId.USER_JOURNEY,
];

export const HowToImproveKPIPanel: React.FC<AllProps> = (props: AllProps) => {
  const {onClose} = props;
  const {t} = useTranslation();
  const containerStyle = useMemo(() => ({minHeight: '20rem', width: '70rem'}), []);
  const {openPrimaryPanel} = useContext(PanelsContext);

  const onSelect = useCallback(
    (analysisTypeId: number) => {
      onClose();
      openPrimaryPanel(PanelKey.ANALYSIS_FORM_PANEL, {
        [ANALYSIS_TYPE_ID_PATH_PARAM]: analysisTypeId,
      });
    },
    [onClose, openPrimaryPanel]
  );
  const options = useMemo(
    () =>
      ANALYSIS_IDS.map(id => ({
        analysisTypeId: id,
        text: t(TransKeys.HOW_TO_IMPROVE_KPI_PANEL[id.toString()].SENTENCE),
        buttonText: t(TransKeys.HOW_TO_IMPROVE_KPI_PANEL[id.toString()].BUTTON),
      })),
    [t]
  );

  return (
    <div style={containerStyle}>
      <ModalLayout>
        <div className={classes.HowToImproveKPIPanel}>
          <FancyHeader
            title={t(TransKeys.HOW_TO_IMPROVE_KPI_PANEL.TITLE)}
            icon={WandMagicSparklesSolidIcon}
            onClose={onClose}
            className={classes.Header}
          />
          <div className={classes.Content}>
            <div className={classes.Options}>
              {options.map(option => (
                <div key={option.analysisTypeId} className={classes.Option}>
                  <div className={classes.Sentence}>{option.text}</div>
                  <Button
                    className={classes.Button}
                    onClick={() => onSelect(option.analysisTypeId)}
                  >
                    {option.buttonText}
                  </Button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </ModalLayout>
    </div>
  );
};

import {Reducer} from 'redux';
import {ToastType} from '../../objects/system/toast-type.enum';
import {toast} from 'react-toastify';
import i18next from 'i18next';

export const confirmationMessageResolvedReducer: Reducer = (state, action) => {
  return {
    ...state,
    confirmationMessage: undefined,
  };
};

export const autoCloseGrowthMapReducer: Reducer = (state, action) => {
  if (action.payload?.action === 'REPLACE') {
    return state;
  }
  return {
    ...state,
    isGrowthMapOpen: false,
  };
};

export const showToastMessageReducer: Reducer = (state, action) => {
  const {message, type} = action.payload;
  let func = null;

  switch (type) {
    case ToastType.SUCCESS:
      func = toast.success;
      break;
    case ToastType.ERROR:
      func = toast.error;
      break;
  }

  func(i18next.t(message));
  return state;
};

import {Epic} from 'redux-observable';
import {createRequestEpic} from 'front-core';
import {ActionKey} from '../../constants/action-key';
import HttpClient from '../../services/http-client.service';
import {MilestonesActionType} from './milestones.actions';
import {
  createMilestoneNetworkRequest,
  deleteMilestoneNetworkRequest,
  editMilestoneNetworkRequest,
  validateMilestoneNetworkRequest,
} from '../../http/milestones.network-requests';
import {
  getProductData,
  modelCreated,
  modelDeleted,
  modelUpdated,
  notifyEvent,
} from '../core/core.actions';
import {ModelKey} from '../../constants/model-key';
import {milestoneToastCreator} from '../toasts.actions';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {showToastMessage} from '../interface/interface.actions';
import {ToastType} from '../../objects/system/toast-type.enum';
import {
  demoProductLimitedActionsFilter,
  errorExtractor,
  getDMPToastErrorMessage,
} from '../store.utils';

export const createMilestoneEpic: Epic = createRequestEpic(
  {
    types: [MilestonesActionType.CREATE_MILESTONE],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.CREATE_MILESTONE,
    request: createMilestoneNetworkRequest,
    onSuccess: milestone => [
      notifyEvent(AmplitudeEvent.MILESTONE_CREATED, {id: milestone.id}),
      milestoneToastCreator('CREATE_SUCCESS'),
      modelCreated(milestone, ModelKey.MILESTONE),
      getProductData(),
    ],
    onError: err_ => {
      const err = errorExtractor(err_);
      const toastMessage = getDMPToastErrorMessage(err, 'milestone');
      if (toastMessage) {
        return [
          showToastMessage(toastMessage, ToastType.ERROR),
          notifyEvent(AmplitudeEvent.CREATE_MILESTONE_ERROR, err),
        ];
      }
      return [milestoneToastCreator('CREATE_ERROR')];
    },
  },
  HttpClient
);

export const updateMilestoneEpic: Epic = createRequestEpic(
  {
    types: [MilestonesActionType.UPDATE_MILESTONE],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.UPDATE_MILESTONE,
    request: editMilestoneNetworkRequest,
    onSuccess: milestone => [
      notifyEvent(AmplitudeEvent.MILESTONE_UPDATED, {id: milestone.id}),
      milestoneToastCreator('UPDATE_SUCCESS'),
      modelUpdated(milestone, ModelKey.MILESTONE),
      getProductData(),
    ],
    onError: err_ => {
      const err = errorExtractor(err_);
      const toastMessage = getDMPToastErrorMessage(err, 'milestone');
      if (toastMessage) {
        return [
          showToastMessage(toastMessage, ToastType.ERROR),
          notifyEvent(AmplitudeEvent.UPDATE_MILESTONE_ERROR, err),
        ];
      }
      return [milestoneToastCreator('CREATE_ERROR')];
    },
  },
  HttpClient
);

export const deleteMilestoneEpic: Epic = createRequestEpic(
  {
    types: [MilestonesActionType.DELETE_MILESTONE],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.DELETE_MILESTONE,
    request: deleteMilestoneNetworkRequest,
    onSuccess: (res, payload) => [
      notifyEvent(AmplitudeEvent.MILESTONE_DELETED, {id: payload}),
      milestoneToastCreator('DELETE_SUCCESS'),
      modelDeleted(payload, ModelKey.MILESTONE),
      getProductData(),
    ],
    onError: err => [milestoneToastCreator('DELETE_ERROR')],
  },
  HttpClient
);

export const validateMilestoneEpic: Epic = createRequestEpic(
  {
    types: [MilestonesActionType.VALIDATE_MILESTONE],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.VALIDATE_MILESTONE,
    request: validateMilestoneNetworkRequest,
    onSuccess: milestone => [
      notifyEvent(AmplitudeEvent.MILESTONE_VALIDATED, {id: milestone.id}),
      milestoneToastCreator('UPDATE_SUCCESS'),
      modelUpdated(milestone, ModelKey.MILESTONE),
    ],
    onError: err => [milestoneToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);

import classNames from 'classnames';
import classes from './layout.module.scss';
import {GenericLoading} from '../../general/generic-loading/generic-loading.component';

interface OwnProps {
  className?: string;
  children?: React.ReactNode;
  noPadding?: boolean;
  isLoading?: boolean;
}

type AllProps = OwnProps;

export const Body: React.FC<AllProps> = (props: AllProps) => {
  const {children, noPadding, isLoading, className} = props;

  return (
    <>
      <div className={classNames(classes.BodyDivider, noPadding && classes.NoPadding)} />
      <div className={classNames(classes.Body, noPadding && classes.NoPadding, className)}>
        {isLoading && <GenericLoading />}
        {children}
      </div>
    </>
  );
};

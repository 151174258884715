import {useEffect, useCallback, useRef, FC} from 'react';
import {AnalysisTypeCard} from '../../../../../../components/analysis-type-card/analysis-type-card.component';
import {useScroll} from '../../hooks/use-scroll.hook';
import classes from './analysis-type-selection-category.module.scss';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {exists} from 'front-core';

interface OwnProps {
  id: number;
  name: string;
  displayOrder: number;
  analyses: any[];
  selectedCategoryId: number;
  containerRef: any;
  onPreview: (analysisType: any) => void;
  onCategoryFocus: (id: number) => void;
  onSelect: (analysisType: any) => void;
}

type AllProps = OwnProps;

export const AnalysisTypeSelectionCategory: FC<AllProps> = (props: AllProps) => {
  const {
    id,
    name,
    analyses,
    selectedCategoryId,
    containerRef,
    onPreview,
    onCategoryFocus,
    onSelect,
  } = props;
  const ref = useRef(null);
  const {t} = useTranslation();
  const onTargetEnterView = useCallback(() => {
    onCategoryFocus(id);
  }, [id, onCategoryFocus]);
  const {scrollTargetIntoView} = useScroll(ref, containerRef, onTargetEnterView);

  useEffect(() => {
    if (!exists(id) || selectedCategoryId === id) {
      scrollTargetIntoView();
    }
  }, [id, selectedCategoryId, scrollTargetIntoView]);

  return (
    <div className={classes.Category} ref={ref}>
      {name && <h3 className={classes.Title}>{name}</h3>}
      <div className={classes.AnalysesCards}>
        {analyses.length === 0 && <span>{t(TransKeys.CREATE_ANALYSIS_FORM.NO_ANALYSES)}</span>}
        {analyses.map(analysis => (
          <AnalysisTypeCard
            key={analysis.id}
            analysisType={analysis}
            onClick={onSelect}
            onPreview={onPreview}
          />
        ))}
      </div>
    </div>
  );
};

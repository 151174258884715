import {NRC} from './index';
import {RequestType, toCamelCase} from 'front-core';

export const getTagBySlugNetworkRequest: NRC<void, any> = (slug: string) => ({
  method: RequestType.GET,
  relativeUrl: `/app/tags/${slug}`,
  responseTransformer: res => toCamelCase(res.data),
});

export const getFeatureTagGroupsNetworkRequest: NRC<void, any> = () => ({
  method: RequestType.GET,
  relativeUrl: `/app/tags/groups/feature`,
  responseTransformer: res => toCamelCase(res.data),
});

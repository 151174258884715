import * as React from 'react';
import classes from './metric-segmentation-overtime-panel.module.scss';
import {ModalLayout} from 'ui-components';
import TransKeys from 'translations';
import {composition} from 'front-core';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {METRIC_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {getMetricMaterialNetworkRequest} from '../../../../http/metrics.network-requests';
import {MetricMaterial} from '../../../../objects/models/metric.model';
import {KPISegmentation} from '../../pages/metric-page/components/document-fragments/kpi-segmentation/kpi-segmentation.component';
import {useTranslation} from 'react-i18next';

interface OwnProps {
  metricMaterial: MetricMaterial;
  [METRIC_ID_PATH_PARAM]: number;
}

type AllProps = OwnProps;

const SELECTED_METRIC_KEY = 'METRIC_SEGMENTATION_OVERTIME_PANEL/SELECTED_METRIC';

export const MetricSegmentationOvertimePanelComponent: React.FC<AllProps> = (props: AllProps) => {
  const {metricMaterial} = props;
  const {t} = useTranslation();

  return (
    <div className={classes.Container}>
      <ModalLayout>
        <div className={classes.MetricSegmentationOvertimePanel}>
          <div className={classes.Header}>
            <div className={classes.Title}>
              {t(TransKeys.METRIC_SEGMENTATION_OVERTIME_PANEL.HEADER.TITLE, {
                metric_name: metricMaterial.metricName,
              })}
            </div>
            <div className={classes.SubTitle}>
              {t(TransKeys.METRIC_SEGMENTATION_OVERTIME_PANEL.HEADER.SUB_TITLE)}
            </div>
          </div>
          <div className={classes.Wrapper}>
            <KPISegmentation analysisResultId={metricMaterial?.kpiAnalysisResultId} />
          </div>
        </div>
      </ModalLayout>
    </div>
  );
};

export const MetricSegmentationOvertimePanel = composition<AllProps>(
  MetricSegmentationOvertimePanelComponent,
  withLoadBefore({
    metricMaterial: {
      selectedKey: SELECTED_METRIC_KEY,
      actionKey: SELECTED_METRIC_KEY,
      request: getMetricMaterialNetworkRequest,
      mapPayloadFromProps: props => props[METRIC_ID_PATH_PARAM],
      shouldCall: props => props[METRIC_ID_PATH_PARAM] !== undefined,
    },
  })
);

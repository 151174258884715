import * as React from 'react';
import classNames from 'classnames';
import {ArrowUpIcon, Avatar} from 'ui-components';
import {Tooltip} from '@material-ui/core';
import {useCallback} from 'react';
import classes from './user-text-input.module.scss';

interface OwnProps {
  username: string;
  value: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
  disabled?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const UserTextInput: React.FC<AllProps> = (props: AllProps) => {
  const {username, value, onChange, onSubmit, disabled, className} = props;

  const onKeyUp = useCallback(
    e => {
      if (e.key === 'Enter') {
        onSubmit();
      }
    },
    [onSubmit]
  );

  return (
    <div className={classNames(classes.UserTextInput, disabled && classes.Disabled, className)}>
      <Avatar name={username} className={classes.Avatar} />
      <input
        disabled={disabled}
        className={classes.Input}
        value={value}
        onChange={e => onChange(e.target.value)}
        placeholder={'Ask a question...'}
        onKeyUp={onKeyUp}
      />
      <Tooltip title={'Send message'} placement={'top'} interactive={false}>
        <div
          onClick={disabled ? undefined : () => onSubmit()}
          className={classNames(classes.SendButton, value && classes.Active)}
        >
          <ArrowUpIcon className={classes.Icon} />
        </div>
      </Tooltip>
    </div>
  );
};

import classes from './page-header.module.scss';
import {useHistory} from 'react-router';
import {ReactNode, useCallback} from 'react';
import PageLayout from '../page-layout';
import {Sentence} from 'ui-components';
import {Tooltip} from '@material-ui/core';

export interface CrumbNavItem {
  name: string;
  icon?: ReactNode;
  navigateTo: any;
}

interface OwnProps {
  title?: string;
  description?: string;
  crumbs?: CrumbNavItem[];
  subHeaderItems?: React.ReactNode[];
  actions?: any;
}

type AllProps = OwnProps;

export const PageHeader: React.FC<AllProps> = (props: AllProps) => {
  const {title, description, crumbs, subHeaderItems, actions} = props;
  const history = useHistory();

  const renderSubHeader = useCallback(() => {
    const items = subHeaderItems.filter(Boolean);
    if (!items.length) {
      return null;
    }

    return (
      <div className={classes.SubHeader}>
        {items.map((item, idx) => (
          <span className={classes.SubHeaderItem} key={idx}>
            {item}
          </span>
        ))}
      </div>
    );
  }, [subHeaderItems]);

  const renderTitle = useCallback(() => {
    if (title) {
      return (
        <Sentence text={title} className={classes.Sentence} partClassName={classes.SentencePart} />
      );
    }
  }, [title]);

  return (
    <>
      <PageLayout.Title className={classes.PageHeader}>
        <div className={classes.Header}>
          {crumbs && (
            <>
              {crumbs.map((c, idx) => (
                <div className={classes.Crumb} onClick={() => history.push(c.navigateTo)} key={idx}>
                  {c.icon && c.icon}
                  <Tooltip title={c.name}>
                    <div className={classes.Label}>{c.name}</div>
                  </Tooltip>
                </div>
              ))}
            </>
          )}
          <Tooltip title={renderTitle()}>
            <div className={classes.Title}>{renderTitle()}</div>
          </Tooltip>
        </div>
        {description && <div className={classes.Description}>{description}</div>}
        {renderSubHeader()}
      </PageLayout.Title>
      <PageLayout.Actions>{actions}</PageLayout.Actions>
    </>
  );
};

PageHeader.defaultProps = {
  crumbs: [],
  subHeaderItems: [],
};

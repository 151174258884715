import {action, ActionHooks, upsert} from 'front-core';
import {showConfirmationMessage} from '../interface/interface.actions';
import i18n from 'i18next';
import TransKeys from 'translations';
import {SelectSimpleAnalysisPickerPanelProps} from '../../modules/analyses/panels/select-simple-analysis-picker-panel/select-simple-analysis-picker-panel.component';
import {UserQuestionnaireDTO} from '../../objects/dto/user-questionnaire.dto';
import {USER_ONBOARDING_STORE_KEY} from './user-onboarding.store';

export enum UserOnboardingActionsType {
  GET_USER_ONBOARDING = '@@user_onboarding/GET_USER_ONBOARDING',
  SET_DRAWER_COLLAPSE = '@@user_onboarding/SET_DRAWER_COLLAPSE',
  DISMISS_USER_ONBOARDING = '@@user_onboarding/DISMISS_USER_ONBOARDING',
  RESET_USER_ONBOARDING = '@@user_onboarding/RESET_USER_ONBOARDING',
  CHECK_ONBOARDING_COMPLETION = '@@user_onboarding/CHECK_ONBOARDING_COMPLETION',
  EXPOSE_DRAWER_ON_PAGE_LOAD = '@@user_onboarding/EXPOSE_DRAWER_ON_PAGE_LOAD',
  EXPOSE_DRAWER_ON_TASK_COMPLETED = '@@user_onboarding/EXPOSE_DRAWER_ON_TASK_COMPLETED',
  UPDATE_USER_ONBOARDING_QUESTIONNAIRE = '@@user_onboarding/UPDATE_USER_ONBOARDING_QUESTIONNAIRE',
  ON_USER_ONBOARDING_QUESTIONNAIRE_COMPLETED = '@@user_onboarding/ON_USER_ONBOARDING_QUESTIONNAIRE_COMPLETED',
}

export const getUserOnboarding = () => action(UserOnboardingActionsType.GET_USER_ONBOARDING);
export const setDrawerCollapse = (drawerCollapsed: boolean = true) =>
  action(UserOnboardingActionsType.SET_DRAWER_COLLAPSE, drawerCollapsed);
export const resetUserOnboarding = (resettingOnboarding: boolean = true) =>
  action(UserOnboardingActionsType.RESET_USER_ONBOARDING, resettingOnboarding);
export const dismissUserOnboarding = (isDismissed: boolean = true) =>
  action(UserOnboardingActionsType.DISMISS_USER_ONBOARDING, {isDismissed});
export const checkOnboardingCompletion = (data: any) =>
  action(UserOnboardingActionsType.CHECK_ONBOARDING_COMPLETION, data);
export const exposeDrawerOnPageLoad = (data: any) =>
  action(UserOnboardingActionsType.EXPOSE_DRAWER_ON_PAGE_LOAD, data);
export const exposeDrawerOnTaskCompleted = (data: any) =>
  action(UserOnboardingActionsType.EXPOSE_DRAWER_ON_TASK_COMPLETED, data);

export const updateUserOnboardingQuestionnaire = (
  data: UserQuestionnaireDTO,
  hooks?: ActionHooks
) => action(UserOnboardingActionsType.UPDATE_USER_ONBOARDING_QUESTIONNAIRE, data, hooks);

export const onUserOnboardingQuestionnaireCompleted = (
  data: UserQuestionnaireDTO,
  hooks?: ActionHooks
) => action(UserOnboardingActionsType.ON_USER_ONBOARDING_QUESTIONNAIRE_COMPLETED, data, hooks);
export const setSelectSimpleAnalysisPickerPanelData = (
  data?: Pick<SelectSimpleAnalysisPickerPanelProps, 'signalId' | 'analysisTypeIds'>
) => upsert(USER_ONBOARDING_STORE_KEY, 'selectSimpleAnalysisPickerPanelData', data);

export const dismissUserOnboardingConfirmed = (isDismissed: boolean = true) =>
  isDismissed
    ? showConfirmationMessage(
        {
          title: i18n.t(TransKeys.APP_USER_ONBOARDING.DRAWER_DISMISS_CONFIRMATION_TITLE),
          content: i18n.t(TransKeys.APP_USER_ONBOARDING.DRAWER_DISMISS_CONFIRMATION_CONTENT),
        },
        () => [dismissUserOnboarding(isDismissed)]
      )
    : dismissUserOnboarding(isDismissed);

import * as yup from 'yup';
import {SchemaOf} from 'yup';

export interface ForgotPasswordFormDto {
  email: string;
}

export const ForgotPasswordFormDTOValidator: SchemaOf<ForgotPasswordFormDto> = yup.object().shape({
  email: yup.string().email().required(),
});

import * as React from 'react';
import {
  useRemoteSourceStated,
  SmartTreeSelector,
  createTreeNodesInfo,
  TreeSelectorValue,
} from 'ui-components';
import {useEffect, useMemo, useState} from 'react';
import {exists} from 'front-core';
import {segmentCategoriesForAnalysisSmartSelectorNetworkRequest} from '../../../../http/smart-selector.network-requests';
import {SmartSelectorLayout} from './smart-selector-layout.component';
import {merge, sum, values} from 'lodash';
import classes from './smart-selector.module.scss';
import {useTeamFilter} from '../../../../core/contexts/team-filter.context';
import {TeamFilterHelper} from './components/helpers/team-filter-helper.component';

export const SELECT_ALL_SEGMENT_CATEGORIES_TAG_KEY = '__all_segments__';
interface OwnProps {
  label: string;
  value: TreeSelectorValue;
  onChange: (value: TreeSelectorValue) => void;
  className?: string;
  disabled?: boolean;
  filters?: any;
  selectedText?: string;
  clearable?: boolean;
  error?: boolean;
}

type AllProps = OwnProps;

export const SegmentCategoriesForAnalysisSmartSelector: React.FC<AllProps> = (props: AllProps) => {
  const {
    label: labelFromProps,
    value,
    onChange,
    filters,
    className,
    disabled,
    clearable,
    selectedText,
    error,
  } = props;
  const {
    teamId,
    teamName,
    ignore: ignoreTeamFilter,
    isActive: isActiveTeamFilter,
  } = useTeamFilter();
  const [count, setCount] = useState(undefined);
  const {source: options, exec} = useRemoteSourceStated({
    type: 'source',
    networkRequest: segmentCategoriesForAnalysisSmartSelectorNetworkRequest,
    initialValue: null,
  });
  const label = useMemo(
    () => (count === undefined || count === 0 ? undefined : `${count} ${selectedText}`),
    [count, selectedText]
  );
  const groups = useMemo(() => {
    if (!exists(options)) {
      return [];
    }
    const groupOptions = [];
    const info: any = createTreeNodesInfo(options);
    groupOptions.push({
      key: 1,
      name: '',
      data: options,
      startOpen: true,
      helperText: isActiveTeamFilter ? (
        <TeamFilterHelper teamName={teamName} onIgnore={ignoreTeamFilter} />
      ) : undefined,
      // internal use
      info,
    });
    return groupOptions;
  }, [options, isActiveTeamFilter, teamName, ignoreTeamFilter]);
  useEffect(() => {
    exec({signalFilters: [filters], teamId: isActiveTeamFilter ? teamId : undefined});
  }, [exec, filters, teamId, isActiveTeamFilter]);
  useEffect(() => {
    if (!groups) {
      return;
    }
    // @ts-ignore
    const allInfo = merge(...values(groups.map(g => g.info)));
    return setCount(
      sum((value as TreeSelectorValue[]).map(v => allInfo[v as any]?.leafsValues?.length))
    );
  }, [groups, value]);

  return (
    <SmartSelectorLayout
      id={'segment-categories-selector'}
      label={label}
      disabled={disabled}
      error={error}
      clearable={clearable && exists(value)}
      onClear={() => onChange(null)}
      placeholder={labelFromProps}
      className={className}
      withPreview={false}
    >
      <SmartTreeSelector
        className={classes.Selector}
        value={value}
        onChange={(value, originalValue: any) => {
          onChange(value);
          setCount(originalValue.length);
        }}
        groups={groups}
        startRootOpen={true}
        selectParentValue={true}
        multi={true}
      />
    </SmartSelectorLayout>
  );
};

SegmentCategoriesForAnalysisSmartSelector.defaultProps = {
  selectedText: 'selected',
};

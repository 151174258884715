import {useMemo, useCallback, useContext} from 'react';
import {QueryBuilderConfig, QueryBuilder, LabelWrapper} from 'ui-components';
import {queryBuilderModelConfig} from '../../../../../../constants/query-builder';
import {getComponentForQuery} from '../../../../../shared/core/query-builders/query-builders.utils';
import {TableEntity, TableEntityBinding} from '../../../../../../objects/models/table.model';
import {CustomQueryWarning} from '../../../../components/custom-query-warning/custom-query-warning.component';
import {SIGNAL_ID_PATH_PARAM} from '../../../../../../constants/app-routes';
import {PanelsContext} from '../../../../../../core/contexts/panels.context';
import {PanelKey} from '../../../../../../constants/panels';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {BuilderWrapper} from '../../../../../shared/components/general/builder-wrapper/builder-wrapper.component';

const queryBuilderConfig: QueryBuilderConfig = {
  modelConfig: queryBuilderModelConfig,
};

interface OwnProps {
  entity: TableEntity;
  signalDefinition: any;
  errors?: any;
  disabled?: boolean;
  onResetSignalDefinition: (options?: any) => void;
  onChangeSignalDefinition: (signalDefinition: any) => void;
  withWrapper?: boolean;
}

type AllProps = OwnProps;

const MetricQueryBuilder: React.FC<AllProps> = (props: AllProps) => {
  const {
    entity,
    signalDefinition,
    errors,
    disabled,
    onChangeSignalDefinition,
    onResetSignalDefinition,
    withWrapper,
  } = props;
  const {t} = useTranslation();

  const {openSecondaryPanel} = useContext(PanelsContext);
  const onSignalInfo = useCallback(
    signalId =>
      openSecondaryPanel(PanelKey.SIGNAL_DEFINITION_PANEL, {
        [SIGNAL_ID_PATH_PARAM]: signalId,
      }),
    [openSecondaryPanel]
  );

  const Builder = getComponentForQuery(signalDefinition);
  const signalFilters = useMemo(
    () => ({
      entityContext: entity,
      entityBinding: TableEntityBinding.ONE_WAY,
    }),
    [entity]
  );

  if (!Builder)
    return (
      <>
        <CustomQueryWarning
          modelName={'KPI'}
          clearSignalDefinition={!disabled && onResetSignalDefinition}
        />
        <QueryBuilder
          config={queryBuilderConfig}
          query={signalDefinition}
          errors={errors}
          disabled
        />
      </>
    );

  const builder = (
    <Builder
      query={signalDefinition}
      onChange={onChangeSignalDefinition}
      errors={errors}
      onSignalInfo={onSignalInfo}
      filters={signalFilters}
      entityContext={signalFilters.entityContext}
      hideEngagementPeriod={true}
      disabled={disabled}
    />
  );

  if (withWrapper === false) {
    return builder;
  }
  return (
    <LabelWrapper label={t(TransKeys.METRIC_FORM_PANEL.INPUTS.DEFINITION.LABEL)}>
      <BuilderWrapper>{builder}</BuilderWrapper>
    </LabelWrapper>
  );
};

MetricQueryBuilder.defaultProps = {
  withWrapper: true,
};

export {MetricQueryBuilder};

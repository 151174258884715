import * as React from 'react';
import {useMemo} from 'react';
import usePermissions from '../hooks/use-permissions.hook';
import {Action, Subject} from 'src/constants/permissions';
import {useDemoProduct} from '../hooks/use-demo-product.hook';
import {IconButton} from 'ui-components';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';

interface OwnProps {
  children: any;
  subject: Subject;
  action: Action;
}

type AllProps = OwnProps;

export const Permission: React.FC<AllProps> = (props: AllProps) => {
  const {children, subject, action} = props;
  const {t} = useTranslation();
  const {isDemoProduct} = useDemoProduct();
  const {can} = usePermissions();
  const hasPermission = useMemo(() => can(subject, action), [can, subject, action]);

  if (isDemoProduct) {
    return React.Children.map(children, child => {
      // Currently Permission is being used only for create / edit
      // dmp objects. And in all of them we're using IconButton
      // for this functionality. In for demo we still want to show
      // the buttons but disable them.
      if (React.isValidElement(child) && child.type === IconButton) {
        const injectedProps = {
          disabled: true,
          tooltipText: t(TransKeys.DEMO.DISABLED_ACTION_TOOLTIP),
        };
        return React.cloneElement(child, injectedProps);
      }
      return child;
    });
  }

  return hasPermission ? children : null;
};

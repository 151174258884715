import * as React from 'react';
import {composition} from 'front-core';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {EVENT_GROUP_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {useMemo} from 'react';
import {FancyHeader, ModalLayout, LabelWrapper, FolderIcon} from 'ui-components';
import TransKeys from '../../../../constants/translation-keys';
import {Metric} from '../../../../objects/models/metric.model';
import classes from './event-group-view-panel.module.scss';
import {useTranslation} from 'react-i18next';
import {
  QuerySqlTabs,
  QuerySqlTabsNames,
} from '../../../shared/core/query-sql-tabs/query-sql-tabs.component';
import {Modifiers} from '../../../shared/components/general/modifiers/modifiers.component';
import {TitleWithIcon} from '../../../shared/components/general/title/title.component';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {withModalErrorHandler} from '../../../../core/hoc/with-model-error-handler.hoc';
import {get} from 'lodash';
import {getEventGroupNetworkRequest} from '../../../../http/event-groups.network-requests';
import {getEntityIcon} from '../../../../constants/entity.consts';

interface OwnProps {
  eventGroup: Metric;
  onClose?: () => void;
  initialTab?: QuerySqlTabsNames;
}

type AllProps = OwnProps;

const SELECTED_EVENT_GROUP_KEY = SharedSelectionKeys.EVENT_GROUP_VIEW__EVENT_GROUP;

export const EventGroupViewPanelComponent: React.FC<AllProps> = (props: AllProps) => {
  const {onClose, eventGroup, initialTab} = props;
  const {t} = useTranslation();
  const containerStyle = useMemo(() => ({height: '70vh', width: '50vw'}), []);
  const {productEntitiesMap} = useProductData();

  return (
    <div style={containerStyle}>
      <ModalLayout>
        <div className={classes.EventGroupViewPanel}>
          <FancyHeader
            className={classes.Header}
            icon={FolderIcon}
            title={t(TransKeys.MODELS.EVENT_GROUP)}
            onClose={onClose}
            caps
          />
          <div className={classes.Body}>
            <div className={classes.NameBlock}>
              <div className={classes.Name}>{eventGroup.name}</div>
              {eventGroup.shortDescription && (
                <div className={classes.Description}>{eventGroup.shortDescription}</div>
              )}
            </div>
            {eventGroup.entity && (
              <div className={classes.Block}>
                <LabelWrapper label={t(TransKeys.GENERAL.LABELS.ENTITY)}>
                  <TitleWithIcon
                    text={productEntitiesMap[eventGroup.entity].name}
                    icon={getEntityIcon(eventGroup.entity)}
                  />
                </LabelWrapper>
              </div>
            )}
            {eventGroup.signalDefinition && (
              <div className={classes.Block}>
                <QuerySqlTabs
                  query={eventGroup.signalDefinition}
                  signalId={eventGroup.signalId}
                  stickyHeader
                  initialTab={initialTab}
                />
              </div>
            )}
            {eventGroup.history.length > 0 && (
              <div className={classes.Block}>
                <Modifiers history={eventGroup.history} />
              </div>
            )}
          </div>
        </div>
      </ModalLayout>
    </div>
  );
};

const EventGroupViewPanel = composition<AllProps>(
  EventGroupViewPanelComponent,
  withModalErrorHandler({
    modalTitle: TransKeys.MODELS.EVENT_GROUP,
    modalIcon: FolderIcon,
    extractErrorCodeFromProps: props => get(props.errors, 'eventGroup.errorCode'),
  }),
  withLoadBefore({
    eventGroup: {
      selectedKey: SELECTED_EVENT_GROUP_KEY,
      actionKey: SELECTED_EVENT_GROUP_KEY,
      request: getEventGroupNetworkRequest,
      mapPayloadFromProps: props => props[EVENT_GROUP_ID_PATH_PARAM],
      shouldCall: props => props[EVENT_GROUP_ID_PATH_PARAM] !== undefined,
    },
  })
);

export default EventGroupViewPanel;

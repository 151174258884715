import {NRC} from './index';
import {RequestType} from 'front-core';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';
import {toCamelCase, toSnakeCase} from 'front-core';
import {AnalysisFiltersDTO} from '../objects/dto/analysis-filters.dto';
import {
  ChangeAnalysisParametersDTO,
  MoveAnalysisToFolderDTO,
  AnalysisDTO,
  QuickRunForAdminsDTO,
} from '../objects/dto/analysis.dto';
import {ScheduleAnalysisDTO} from '../objects/dto/schedule-analysis.dto';

export const getAnalysisNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/analyses/${id}`,
  responseTransformer: res => toCamelCase(res.data, ['analysis_type', 'parameters']),
});

export const getSimilarAnalysesNetworkRequest: NRC<void, void> = (id: number, query) => ({
  method: RequestType.GET,
  relativeUrl: `/app/analyses/${id}/similar`,
  query,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data, ['parameters', 'run_parameters']),
});

export const getAnalysesNetworkRequest: NRC<void, AnalysisFiltersDTO> = (
  payload: AnalysisFiltersDTO
) => ({
  method: RequestType.GET,
  relativeUrl: `/app/analyses`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data),
});

export const searchAnalysesNetworkRequest: NRC<void, any> = (value: any, payload: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/analyses`,
  query: {q: value, ...payload},
  queryTransformer: toFiltersDTO,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data.data),
});

export const deleteAnalysisNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.DELETE,
  relativeUrl: `/app/analyses/${id}`,
  responseTransformer: toCamelCase,
});

export const requestAnalysisNetworkRequest: NRC<AnalysisDTO, void> = (payload: AnalysisDTO) => ({
  method: RequestType.POST,
  relativeUrl: `/app/analyses`,
  body: payload,
  bodyTransformer: body => toSnakeCase(body, ['parameters', 'runParameters']),
  responseTransformer: res => toCamelCase(res.data, ['analysisType']),
});

export const getAnalysisParametersNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/analyses/${id}/parameters`,
  responseTransformer: res => toCamelCase(res.data, ['schema', 'values']),
});

export const createScheduleAnalysisNetworkRequest: NRC<Partial<ScheduleAnalysisDTO>, any> = (
  payload: Partial<ScheduleAnalysisDTO>
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/analyses/${payload.analysisId}/schedule`,
  body: payload,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const editScheduleAnalysisNetworkRequest: NRC<Partial<ScheduleAnalysisDTO>, any> = (
  payload: Partial<ScheduleAnalysisDTO>
) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/analyses/${payload.analysisId}/schedule`,
  body: payload,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const deleteScheduleAnalysisNetworkRequest: NRC<void, void> = (
  payload: Partial<ScheduleAnalysisDTO>
) => ({
  method: RequestType.DELETE,
  relativeUrl: `/app/analyses/${payload.analysisId}/schedule`,
  responseTransformer: res => toCamelCase(res.data),
});

export const changeAnalysisParametersNetworkRequest: NRC<ChangeAnalysisParametersDTO, void> = (
  payload: ChangeAnalysisParametersDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/analyses/${payload.analysisId}/change-parameters`,
  body: payload,
  bodyTransformer: body => toSnakeCase(body, ['parametersChange']),
});

export const moveAnalysisToFolderNetworkRequest: NRC<Partial<MoveAnalysisToFolderDTO>, void> = (
  payload: Partial<MoveAnalysisToFolderDTO>
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/analyses/${payload.id}/change-folder`,
  body: {analysisFolderId: payload.analysisFolderId},
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const editAnalysisNetworkRequest: NRC<Partial<AnalysisDTO>, void> = (
  payload: Partial<AnalysisDTO>
) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/analyses/${payload.analysisId}/edit`,
  body: payload,
  bodyTransformer: body => toSnakeCase(body, ['parameters', 'runParameters']),
  responseTransformer: res => toCamelCase(res.data),
});

export const quickRunForAdminsNetworkRequest: NRC<QuickRunForAdminsDTO, void> = (
  payload: QuickRunForAdminsDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/analyses/quick-run`,
  body: payload,
  bodyTransformer: body => toSnakeCase(body),
  responseTransformer: res => toCamelCase(res.data),
});

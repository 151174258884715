import {useProductData} from './use-product-data.hook';
import {useCallback, useMemo} from 'react';
import {AnalysisTypeId} from '../../constants/analysis-type-id';
import yup from '../../config/yup.config';
import {isEmpty} from 'lodash';

const demoProductValidator = yup.object().shape({});

export function useDemoProduct() {
  const {isDemoProduct, demoResources} = useProductData();
  const analysisTypeIdsAvailableForDemo = useMemo(() => {
    if (!demoResources || isEmpty(demoResources)) {
      return [];
    }
    return Object.keys(demoResources).map(Number);
  }, [demoResources]);

  const getDemoAnalysisResultResource = useCallback(
    (analysisTypeId: AnalysisTypeId): string | null => {
      if (!demoResources) {
        return null;
      }
      return demoResources[analysisTypeId] || null;
    },
    [demoResources]
  );

  return {
    analysisTypeIdsAvailableForDemo,
    getDemoAnalysisResultResource,
    isDemoProduct,
    demoProductValidator: isDemoProduct ? demoProductValidator : null,
  };
}

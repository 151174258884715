import {useContext, useMemo} from 'react';
import {
  ModelSample,
  ModelSampleSeries,
  ModelSampleSeriesType,
  ModelSeriesGranularity,
} from '../../../../../../../../objects/models/model-sample-series.model';
import {
  HomepageAnnotation,
  HomepageFunnel,
} from '../../../../../../../../objects/models/homepage.model';
import {HomepageMetricChart} from '../../homepage-metric-viewer/homepage-metric-chart/homepage-metric-chart.component';
import {ChartConfig, ChartMode} from '../../../../homepage-summary.types';
import {ConfidenceIntervalConfig} from '../../../../../../../../objects/models/user-settings.model';
import {invert, keyBy, take, takeRight, zip} from 'lodash';
import {FunnelChartMode} from 'ui-components';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {HomepageChartEmptyState} from '../../components/homepage-chart-empty-state/homepage-chart-empty-state.component';
import {useHistory} from 'react-router';
import {
  ANALYSIS_RESULT_ID_PATH_PARAM,
  AppRoutes,
} from '../../../../../../../../constants/app-routes';
import {
  FUNNEL_PAGE_DATE_QUERY_PARAM,
  FUNNEL_PAGE_TAB_QUERY_PARAM,
  SELECTED_RCA_FUNNEL_STEP_TARGET_SIGNAL_ID,
} from '../../../../../../../funnels/pages/funnel-page/funnel-page.consts';
import {exists, HttpClientContext} from 'front-core';
import moment from 'moment';
import {TIME_FORMATS} from 'src/constants/time-formats';
import {getRCAForFunnelByDate} from 'src/http/funnels.network-requests';

interface OwnProps {
  funnel: HomepageFunnel;
  reviewedSignalId?: number;
  granularity: ModelSeriesGranularity;
  chartConfig: ChartConfig;
  annotations: HomepageAnnotation[];
  onAnnotationsClicked?: (annotations: HomepageAnnotation[]) => void;
  configuration: ConfidenceIntervalConfig;
  funnelChartMode: FunnelChartMode;
  className?: string;
}

type AllProps = OwnProps;

const generateIdForSeries = (s: ModelSampleSeries) =>
  `${s.signalId}_${s.seriesType === ModelSampleSeriesType.FUNNEL_COMPLETION_RATE ? 'c' : ''}`;
const FUNNEL_CHART_MODE_TO_SERIES_TYPE = {
  [FunnelChartMode.TOTALS]: ModelSampleSeriesType.LINEAR_FUNNEL_STEP_COUNT_OVER_TIME,
  [FunnelChartMode.CONVERSION_FROM_INITIAL_STEP]:
    ModelSampleSeriesType.FUNNEL_CONVERSION_RATE_FROM_INITIAL_STEP,
  [FunnelChartMode.CONVERSION_FROM_PREVIOUS_STEP]:
    ModelSampleSeriesType.FUNNEL_CONVERSION_RATE_FROM_PREV_STEP,
};

export const HomepageFunnelOvertimeChart: React.FC<AllProps> = (props: AllProps) => {
  const {
    funnel,
    reviewedSignalId,
    granularity,
    chartConfig,
    configuration,
    annotations,
    funnelChartMode,
    onAnnotationsClicked,
    className,
  } = props;
  const {t} = useTranslation();

  const history = useHistory();
  const http = useContext(HttpClientContext);
  const funnelStepsMap = useMemo(() => keyBy(funnel.steps, 'id'), [funnel]);
  const funnelConversionSteps = useMemo(() => {
    const stepSignalIds = funnel.steps.map(s => s.id);
    const zipped = zip(
      take(stepSignalIds, stepSignalIds.length - 1),
      takeRight(stepSignalIds, stepSignalIds.length - 1)
    );
    let map = {};
    zipped.forEach(([from, to]) => {
      map[from] = to;
    });
    map = invert(map);
    return map;
  }, [funnel]);
  const isPercentage = useMemo(() => funnelChartMode !== FunnelChartMode.TOTALS, [funnelChartMode]);
  const chartData = useMemo(() => {
    const entry = funnel.series
      .filter(s => {
        const isSameGranularity = s.granularity === granularity;
        const isRelevantType = s.seriesType === FUNNEL_CHART_MODE_TO_SERIES_TYPE[funnelChartMode];
        const isFunnelCompilationRate =
          s.seriesType === ModelSampleSeriesType.FUNNEL_COMPLETION_RATE;
        if (funnelChartMode === FunnelChartMode.TOTALS) {
          return isSameGranularity && isRelevantType;
        }
        return isSameGranularity && (isRelevantType || isFunnelCompilationRate);
      })
      .map(s => ({
        ...s,
        order: funnel.steps.findIndex(step => step.id === s.signalId),
        isFunnelCompletionRate: s.seriesType === ModelSampleSeriesType.FUNNEL_COMPLETION_RATE,
      }))
      .sort((a, b) => {
        if (a.isFunnelCompletionRate) {
          return -1;
        }
        if (b.isFunnelCompletionRate) {
          return 1;
        }
        return a.order - b.order;
      });
    const reviewedSeries =
      funnel.series.find(
        s =>
          s.signalId === reviewedSignalId &&
          s.seriesType === FUNNEL_CHART_MODE_TO_SERIES_TYPE[funnelChartMode]
      ) || entry[0];
    const reviewedSeriesId = reviewedSeries ? generateIdForSeries(reviewedSeries) : undefined;
    let series = [];
    if (funnelChartMode === FunnelChartMode.TOTALS) {
      series = entry.map(s => ({
        ...s,
        name: funnelStepsMap[s.signalId].name,
        id: generateIdForSeries(s),
      }));
    } else {
      series = entry.map(s => ({
        ...s,
        name:
          s.seriesType === ModelSampleSeriesType.FUNNEL_COMPLETION_RATE
            ? t(TransKeys.HOMEPAGE.CHART.FUNNEL_COMPLETION_RATE_NAME)
            : `${funnelStepsMap[funnelConversionSteps[s.signalId]]?.name} → ${funnelStepsMap[
                s.signalId
              ]?.name}`,
        id: generateIdForSeries(s),
      }));
    }
    return {
      series,
      reviewedSeriesId,
    };
  }, [
    funnel,
    granularity,
    funnelStepsMap,
    funnelConversionSteps,
    funnelChartMode,
    reviewedSignalId,
    t,
  ]);

  if (chartData.series.length === 0) {
    return <HomepageChartEmptyState text={t(TransKeys.HOMEPAGE.METRIC_NO_SAMPLES_EMPTY_STATE)} />;
  }

  const onRCAFollowUp = async (sample: ModelSample) => {
    const relevantSeries = chartData.series.find(series =>
      exists(series.samples.find(s => s.id === sample.id))
    );

    const targetSignalId =
      exists(relevantSeries) && !relevantSeries.isFunnelCompletionRate
        ? relevantSeries.signalId
        : undefined;

    const date = moment
      .utc(sample.datetime, TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT)
      .format(TIME_FORMATS.PARAMETER_DATE_FORMAT);

    if (relevantSeries.granularity === ModelSeriesGranularity.WEEK) {
      history.push(
        AppRoutes.viewFunnel(funnel.id, {
          [FUNNEL_PAGE_TAB_QUERY_PARAM]: 'RCA',
          [SELECTED_RCA_FUNNEL_STEP_TARGET_SIGNAL_ID]: targetSignalId,
          [FUNNEL_PAGE_DATE_QUERY_PARAM]: date,
        })
      );
    }

    if (relevantSeries.granularity === ModelSeriesGranularity.DAY) {
      const result = await http.exec(
        getRCAForFunnelByDate({
          funnelId: funnel.id,
          granularity: ModelSeriesGranularity.DAY,
          date: moment.utc(date, 'YYYY-MM-DD').format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT),
        })
      );
      const {analysisResultId, analysisId} = result as unknown as {
        analysisResultId: number;
        analysisId: number;
      };

      if (analysisResultId) {
        history.push(
          AppRoutes.viewAnalysis(analysisId, {
            [ANALYSIS_RESULT_ID_PATH_PARAM]: analysisResultId,
          })
        );
      }
    }
  };

  return (
    <HomepageMetricChart
      series={chartData.series}
      reviewedSeriesId={chartData.reviewedSeriesId}
      chartConfig={chartConfig}
      confidenceIntervalConfig={configuration}
      isPercentage={isPercentage}
      entity={funnel.entity}
      xLabel={granularity}
      hasCountEntities={false}
      annotations={annotations}
      onAnnotationsClicked={onAnnotationsClicked}
      onSampleClicked={onRCAFollowUp}
      showLegend={true}
      chartMode={ChartMode.PREDICTED}
      className={className}
    />
  );
};

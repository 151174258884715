import * as React from 'react';
import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {StringParam, NumberParam, useQueryParams} from 'use-query-params';
import {
  InviteSignupFormDTO,
  inviteSignupFormDTOValidator,
} from '../../../../objects/dto/invite-signup-form.dto';
import AuthScopeFormContainer from '../auth-scope/auth-scope-form-container/auth-scope-form-container.component';
import TransKeys from 'translations';
import {inviteSignupNetworkRequest} from '../../../../http/auth.network-requests';
import {showToastMessage} from '../../../../store/interface/interface.actions';
import {ToastType} from '../../../../objects/system/toast-type.enum';
import AuthForm from '../auth-scope/auth-scope-form/auth-form.component';
import sharedClasses from '../shared-forms.module.scss';
import {AuthFormTextInput} from '../auth-scope/inputs/auth-text-input/auth-text-input.component';
import {useRemoteSource} from 'ui-components';
import {useDispatch} from 'react-redux';
import {useDemoProduct} from '../../../../core/hooks/use-demo-product.hook';

interface OwnProps {}

type AllProps = OwnProps;

const InviteSignupForm: React.FC<AllProps> = (props: AllProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {isDemoProduct} = useDemoProduct();
  const [{token, inviterUserId}] = useQueryParams({
    token: StringParam,
    inviterUserId: NumberParam,
  });
  const [requestSucceeded, setRequestSucceeded] = useState(false);

  const formMethods = useForm<InviteSignupFormDTO>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      token,
      inviterUserId,
    },
    resolver: yupResolver(inviteSignupFormDTOValidator),
  });
  const {handleSubmit} = formMethods;

  const {exec: execSignup, isLoading} = useRemoteSource({
    networkRequest: inviteSignupNetworkRequest,
    type: null,
    onSuccess: () => setRequestSucceeded(true),
    onError: () => {
      setRequestSucceeded(false);
      dispatch(
        showToastMessage(t(TransKeys.AUTH_FORMS.SIGNUP.TOASTS_MESSAGES.ERROR), ToastType.ERROR)
      );
    },
  });

  const onSubmit = useCallback(
    async (data: InviteSignupFormDTO) => {
      if (isDemoProduct) {
        return;
      }
      await execSignup(data);
    },
    [isDemoProduct, execSignup]
  );

  return (
    <AuthScopeFormContainer>
      <FormProvider {...formMethods}>
        <AuthForm
          disableOnSubmit={isLoading || requestSucceeded || isDemoProduct}
          onSubmit={handleSubmit(onSubmit)}
          onSubmitText={t(TransKeys.AUTH_FORMS.SIGNUP.LABELS.SUBMIT)}
        >
          {requestSucceeded && !isLoading && (
            <div className={sharedClasses.SuccessMessage}>
              {t(TransKeys.AUTH_FORMS.SIGNUP.SUCCESSS_MESSAGE)}
            </div>
          )}
          <div className={sharedClasses.FormBlock}>
            <AuthFormTextInput
              required
              label={t(TransKeys.AUTH_FORMS.SHARED_LABELS.FIRST_NAME)}
              name={'firstName'}
              type={'text'}
            />
          </div>
          <div className={sharedClasses.FormBlock}>
            <AuthFormTextInput
              required
              label={t(TransKeys.AUTH_FORMS.SHARED_LABELS.LAST_NAME)}
              name={'lastName'}
              type={'text'}
            />
          </div>
          <div className={sharedClasses.FormBlock}>
            <AuthFormTextInput
              required
              label={t(TransKeys.AUTH_FORMS.SHARED_LABELS.EMAIL)}
              name={'email'}
              type={'text'}
            />
          </div>
        </AuthForm>
      </FormProvider>
    </AuthScopeFormContainer>
  );
};

export default InviteSignupForm;

import * as React from 'react';
import {useContext, useEffect} from 'react';
import {isEmpty} from 'lodash';
import {PanelKey} from '../../../../constants/panels';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {useUserOnboarding} from '../../../../core/hooks/use-user-onboarding.hook';

interface OwnProps {}
type AllProps = OwnProps;

export const AppUserOnboardingSimpleAnalysisPanel: React.FC<AllProps> = () => {
  const {openSecondaryPanel} = useContext(PanelsContext);
  const {selectSimpleAnalysisPickerPanelData} = useUserOnboarding();

  useEffect(() => {
    if (isEmpty(selectSimpleAnalysisPickerPanelData)) {
      return;
    }
    const timer = setTimeout(() => {
      openSecondaryPanel(
        PanelKey.SIMPLE_ANALYSIS_PICKER_PANEL,
        selectSimpleAnalysisPickerPanelData
      );
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [selectSimpleAnalysisPickerPanelData, openSecondaryPanel]);

  return null;
};

import HttpClient from './services/http-client.service';
import appConfig from './config/app.config';
import Cookies from 'js-cookie';
import {initAmplitude} from './config/amplitude.config';
import {loadPendo} from './config/pendo.config';
import {initHelpscout} from './config/helpscout.config';
import {initSentry} from './config/sentry.config';
import {initHotjar} from './config/hotjar.config';
import {initHubspot} from './config/hubspot.config';
import {store} from './store/app.store';
import {getUserOnboarding} from './store/user-onboarding/user-onboarding.actions';
import {initGrowthbook} from './config/growthbook.config';

export function initApp() {
  initAmplitude();
  loadPendo();
  initHelpscout();
  initSentry();
  initHotjar();
  initHubspot();
  initGrowthbook();

  HttpClient.setBaseUrl(appConfig.serverUrl);

  // CSRF INTERCEPTOR
  HttpClient.getHttpDriver().interceptors.request.use(async request => {
    request.headers.common['X-Source'] = 'App';
    try {
      const csrf = Cookies.get('csrf_access_token');
      if (csrf) {
        request.headers.common['X-CSRF-TOKEN'] = csrf;
      }
      return request;
    } catch (err) {
      throw new Error(`axios# Problem with request during pre-flight phase: ${err}.`);
    }
  });

  HttpClient.getHttpDriver().interceptors.response.use(
    function (response) {
      if (response.headers['x-user-onboarding-modified'] === 'true') {
        store.dispatch(getUserOnboarding());
      }
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
}

import {Reducer} from 'redux';
import {RemoteListConfig} from './remote-list.types';
import RemoteListsStoreService from './remote-lists.service';

export const createRemoteListReducer = (state, action): Reducer => {
  const config: RemoteListConfig = action.payload;
  const updatedState = {
    ...state,
    lists: {...state.lists},
  };
  updatedState.lists[config.listKey] = {
    list: [],
    meta: {},
    query: {},
  };
  RemoteListsStoreService.addList(config.listKey, config);
  return updatedState;
};

export const removeRemoteListReducer = (state, action): Reducer => {
  const key = action.payload;
  const updatedState = {
    ...state,
    lists: {...state.lists},
  };
  delete updatedState.lists[key];
  RemoteListsStoreService.removeList(key);
  return updatedState;
};

export const updateListSuccessReducer = (state, action): Reducer => {
  const {listKey, data, meta, query, strategy} = action.payload;

  const updatedState = {
    ...state,
    lists: {
      ...state.lists,
    },
  };
  const listEntry = {
    ...(updatedState.lists[listKey] || {}),
    query,
    meta,
  };

  if (strategy === 'append') {
    listEntry.list = [...(listEntry.list || []), ...data];
  } else if (strategy === 'replace') {
    listEntry.list = [...data];
  }
  updatedState.lists[listKey] = listEntry;

  return updatedState;
};

export const modelChanged = (state, action): Reducer => {
  const {data, modelKey} = action.payload;

  const configs = RemoteListsStoreService.getConfigsByModelKey(modelKey);
  if (configs.length === 0) {
    return state;
  }

  const updatedState = {
    ...state,
    lists: {
      ...state.lists,
    },
  };

  for (const config of configs) {
    const {listKey, primaryKey} = config;
    if (!primaryKey) {
      continue;
    }
    let listEntry = updatedState.lists[listKey];
    if (!listEntry) {
      continue;
    }
    const index = listEntry.list.findIndex(i => i[primaryKey] === data[primaryKey]);
    if (index > -1) {
      listEntry = {
        ...updatedState.lists[config.listKey],
      };
      const list = [...listEntry.list];
      list[index] = {
        ...list[index],
        ...data,
      };
      listEntry.list = list;
      updatedState.lists[listKey] = listEntry;
    }
  }

  return updatedState;
};

export const modelDeleted = (state, action): Reducer => {
  const {data, modelKey} = action.payload;
  const configs = RemoteListsStoreService.getConfigsByModelKey(modelKey);
  if (configs.length === 0) {
    return state;
  }

  const updatedState = {
    ...state,
    lists: {
      ...state.lists,
    },
  };

  for (const config of configs) {
    const {listKey, primaryKey} = config;
    if (!primaryKey) {
      continue;
    }
    let listEntry = updatedState.lists[listKey];
    if (!listEntry) {
      continue;
    }
    const modelKeyValue = typeof data === 'number' ? data : data[primaryKey];
    const index = listEntry.list.findIndex(i => i[primaryKey] === modelKeyValue);
    if (index > -1) {
      listEntry = {
        ...updatedState.lists[config.listKey],
      };
      const list = [...listEntry.list];
      list.splice(index, 1);
      listEntry.list = list;
      listEntry.meta = {...listEntry.meta, total: listEntry.meta.total - 1};
      updatedState.lists[listKey] = listEntry;
    }
  }

  return updatedState;
};

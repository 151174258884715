import {action} from 'front-core';
import {RemoteListConfig} from './remote-list.types';

export type ListStrategy = 'replace' | 'append';

export enum RemoteListActionType {
  CREATE_LIST = '@@remote_list/CREATE_LIST',
  REMOVE_LIST = '@@remove_list/REMOVE_LIST',
  APPEND_LIST = '@@remote_list/APPEND_LIST',
  REPLACE_LIST = '@@remote_list/REPLACE_LIST',
  // internal use for using switchMap version
  __APPEND_LIST_SWITCHED = '@@remote_list/__APPEND_LIST_SWITCHED',
  __REPLACE_LIST_SWITCHED = '@@remote_list/__REPLACE_LIST_SWITCHED',
  // internal use for list reducing
  __REMOTE_LIST_REQUEST_COMPLETED = '@@remote_list/__REMOTE_LIST_REQUEST_COMPLETED',
}

// internal use in epics, used to switch the type to its actual type
export const _REMOTE_LIST_SWAP_TYPES = {
  [RemoteListActionType.__APPEND_LIST_SWITCHED]: RemoteListActionType.APPEND_LIST,
  [RemoteListActionType.__REPLACE_LIST_SWITCHED]: RemoteListActionType.REPLACE_LIST,
};

export const createList = (config: RemoteListConfig) =>
  action(RemoteListActionType.CREATE_LIST, config);
export const removeList = (listKey: string) => action(RemoteListActionType.REMOVE_LIST, listKey);

export const appendList = (
  listKey: string,
  query: any = {},
  mergeQueryStrategy: ListStrategy = 'replace',
  // if true - the epic will use the switchMap version
  switchRequest: boolean = false
) =>
  action(
    switchRequest ? RemoteListActionType.__APPEND_LIST_SWITCHED : RemoteListActionType.APPEND_LIST,
    {listKey, query, mergeQueryStrategy}
  );
export const replaceList = (
  listKey: string,
  query: any = {},
  mergeQueryStrategy: ListStrategy = 'replace',
  // if true - the epic will use the switchMap version
  switchRequest: boolean = false
) =>
  action(
    switchRequest
      ? RemoteListActionType.__REPLACE_LIST_SWITCHED
      : RemoteListActionType.REPLACE_LIST,
    {listKey, query, mergeQueryStrategy}
  );
export const __remote_list_request_completed = (payload: {
  listKey: string;
  data: any[];
  meta: any;
  query: any;
  strategy: 'replace' | 'append';
}) => action(RemoteListActionType.__REMOTE_LIST_REQUEST_COMPLETED, payload);

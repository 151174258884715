export enum Role {
  OWNER = 'owner',
  MANAGER = 'manager',
  CONTRIBUTOR = 'contributor',
}

export enum Subject {
  TABLE = 'table',
  FUNNEL = 'funnel',
  FEATURE = 'feature',
  SEGMENT = 'segment',
  METRIC = 'metric',
  MILESTONE = 'milestone',
  EVENT_GROUP = 'eventGroup',
  CONTENT = 'content',
}

export enum Action {
  // Common actions
  VIEW = 'view',
  CREATE = 'create',
  EDIT = 'edit',
  DELETE = 'delete',
  // Topic specific actions
  TOGGLE_SEGMENT_CONFOUNDER = 'toggle_segment_confounder',
}

export type PermissionSet = {
  [subject in Subject]?: Action[];
};

export type ProductPermissions = {
  [productId: number]: {
    role: Role;
    permissions: PermissionSet;
  };
};

import * as React from 'react';
import {useCallback, useMemo} from 'react';
import moment from 'moment/moment';
import classNames from 'classnames';
import classes from './anomaly-period-selector.module.scss';
import analysisFormsClasses from '../../../analysis-forms.module.scss';
import {
  Checkbox,
  DatePickerInput,
  HoverHelperTip,
  LabelWrapper,
  LoopsIcon,
  Select,
} from 'ui-components';
import {get} from 'lodash';
import TransKeys from '../../../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {ParameterInputWrapper} from '../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {useProductData} from '../../../../../../core/hooks/use-product-data.hook';
import {createStartDateComparingProps} from '../../../../../../utils/dates.utils';

const TIME_GRANULARITY_OPTIONS = [
  {
    value: 'day',
    label: 'Day',
  },
  {
    value: 'week',
    label: 'Calendaric week',
  },
  {
    value: 'month',
    label: 'Month',
  },
];

interface SchemaKeysMapping {
  start_date_anomaly: string;
  start_date_comparing: string;
  use_loops_anomaly_detection_algo: string;
  time_granularity: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  start_date_anomaly: 'start_date_anomaly',
  start_date_comparing: 'start_date_comparing',
  use_loops_anomaly_detection_algo: 'use_loops_anomaly_detection_algo',
  time_granularity: 'time_aggregation',
};

export const getMaxDate = (
  timeGranularity: 'day' | 'week' | 'month',
  referenceDate?: string
): Date => {
  let maxDate = referenceDate ? moment(referenceDate) : moment();
  switch (timeGranularity) {
    case TIME_GRANULARITY_OPTIONS[0].value:
      maxDate.subtract(1, 'days');
      break;
    case TIME_GRANULARITY_OPTIONS[1].value:
      maxDate.subtract(7, 'days');
      // const date = maxDate.subtract(7, 'days');
      // // don't allow select partial weeks (set back to the beginning of the week)
      // const weekday = date.isoWeekday();
      // if (weekday !== 1) {
      //   maxDate = maxDate.weekday(0);
      // }
      break;
    case TIME_GRANULARITY_OPTIONS[2].value:
      maxDate.subtract(1, 'months');
      break;
  }
  return maxDate.startOf('day').toDate();
};

interface OwnProps {
  value: any;
  onChange: (parameters: any) => void;
  schemaKeysMapping?: SchemaKeysMapping;
  errors?: any;
  className?: string;
}

type AllProps = OwnProps;

const AnomalyPeriodSelector: React.FC<AllProps> = (props: AllProps) => {
  const {value, onChange, schemaKeysMapping, errors} = props;
  const {t} = useTranslation();
  const {defaultSource} = useProductData();
  const timeGranularity = value[schemaKeysMapping.time_granularity];
  const useArgoForCompare = value[schemaKeysMapping.use_loops_anomaly_detection_algo];
  const errorMessages = useMemo(
    () => ({
      [schemaKeysMapping.start_date_anomaly]: get(errors, schemaKeysMapping.start_date_anomaly)
        ?.message,
      [schemaKeysMapping.start_date_comparing]: get(errors, schemaKeysMapping.start_date_comparing)
        ?.message,
      [schemaKeysMapping.time_granularity]: get(errors, schemaKeysMapping.time_granularity)
        ?.message,
    }),
    [errors, schemaKeysMapping]
  );

  const startDateAnomalyMaxDate = useMemo(() => {
    const timeGranularity = value[schemaKeysMapping.time_granularity];
    if (!timeGranularity) {
      return undefined;
    }
    return getMaxDate(timeGranularity, defaultSource?.lastValidDate);
  }, [value, schemaKeysMapping, defaultSource]);

  const anomalyComparingToDateMaxDate = useMemo(() => {
    // Ensure that the anomaly start date and the anomaly comparing
    // to date do not intersect whilst taking into account the time
    // granularity
    const anomalyStartDate = value[schemaKeysMapping.start_date_anomaly];
    const timeGranularity = value[schemaKeysMapping.time_granularity];
    if (!anomalyStartDate || !timeGranularity) {
      return undefined;
    }
    return getMaxDate(timeGranularity, anomalyStartDate);
  }, [value, schemaKeysMapping]);

  const datePickersSharedProps = useMemo(
    () => createStartDateComparingProps(timeGranularity),
    [timeGranularity]
  );

  const onLoopsAnomalyDetectionModelChange = useCallback(
    v => {
      let updatedParameters = {
        [schemaKeysMapping.use_loops_anomaly_detection_algo]: v,
      };
      if (v) {
        updatedParameters[schemaKeysMapping.start_date_comparing] = undefined;
      }
      onChange(updatedParameters);
    },
    [schemaKeysMapping, onChange]
  );

  const onComparingToDateChange = useCallback(
    value => {
      onChange({
        [schemaKeysMapping.start_date_comparing]: value,
        [schemaKeysMapping.use_loops_anomaly_detection_algo]: false,
      });
    },
    [schemaKeysMapping, onChange]
  );
  const onChangeTimeGranularity = useCallback(
    value =>
      onChange({
        [schemaKeysMapping.time_granularity]: value,
        [schemaKeysMapping.start_date_anomaly]: undefined,
        [schemaKeysMapping.start_date_comparing]: undefined,
      }),
    [onChange, schemaKeysMapping]
  );

  return (
    <>
      <ParameterInputWrapper
        title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_139.ANOMALY_PERIOD.TITLE)}
        subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_139.ANOMALY_PERIOD.SUB_TITLE)}
        helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_139.ANOMALY_PERIOD.HELPER_TEXT)}
        className={analysisFormsClasses.Parameter}
        error={errorMessages[schemaKeysMapping.start_date_anomaly]}
      >
        <div className={analysisFormsClasses.ContentWrapper}>
          <LabelWrapper
            label={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_139.ANOMALY_PERIOD.TIME_GRANULARITY.LABEL)}
            className={analysisFormsClasses.MarginBottom}
            error={Boolean(errors?.[schemaKeysMapping.time_granularity])}
            helperText={errorMessages[schemaKeysMapping.time_granularity]}
          >
            <Select
              value={timeGranularity}
              onChange={onChangeTimeGranularity}
              options={{options: TIME_GRANULARITY_OPTIONS}}
              searchable={false}
              sortValues={false}
              clearable={false}
            />
          </LabelWrapper>
          <LabelWrapper
            label={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_139.ANOMALY_PERIOD.START_DATE.LABEL, {
              time_period: timeGranularity,
            })}
            error={Boolean(errors?.[schemaKeysMapping.start_date_anomaly])}
            helperText={errorMessages[schemaKeysMapping.start_date_anomaly]}
          >
            <DatePickerInput
              className={analysisFormsClasses.FitContent}
              placeholder={'Select Date'}
              value={value[schemaKeysMapping.start_date_anomaly]}
              onChange={value => onChange({[schemaKeysMapping.start_date_anomaly]: value})}
              error={Boolean(errors?.[schemaKeysMapping.start_date_anomaly])}
              maxDate={startDateAnomalyMaxDate}
              {...datePickersSharedProps}
            />
          </LabelWrapper>
        </div>
      </ParameterInputWrapper>
      <ParameterInputWrapper
        title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_139.START_DATE_COMPARING.TITLE)}
        subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_139.START_DATE_COMPARING.SUB_TITLE)}
        helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_139.START_DATE_COMPARING.HELPER_TEXT)}
        className={analysisFormsClasses.Parameter}
        error={errorMessages[schemaKeysMapping.start_date_comparing]}
      >
        <div className={analysisFormsClasses.ContentWrapper}>
          <Checkbox
            checked={useArgoForCompare}
            onChange={() => onLoopsAnomalyDetectionModelChange(true)}
            className={classes.CompareOption}
            size={'large'}
            label={
              <div className={classes.ArgoTextWrapper}>
                <LoopsIcon className={classes.Icon} />
                <span className={classes.ArgoText}>
                  {t(TransKeys.ANALYSIS_FORMS.ANALYSIS_139.ANOMALY_DETECTION_CHECKBOX_TEXT)}
                </span>
                <HoverHelperTip
                  title={t(
                    TransKeys.ANALYSIS_FORMS.ANALYSIS_139.ANOMALY_DETECTION_CHECKBOX_HELPER_TEXT
                  )}
                />
              </div>
            }
          />
          <Checkbox
            checked={!useArgoForCompare}
            onChange={() => useArgoForCompare === true && onLoopsAnomalyDetectionModelChange(false)}
            className={classes.CompareOption}
            size={'large'}
            label={
              <div>
                <DatePickerInput
                  className={classNames(classes.SelectCompareDate, analysisFormsClasses.FitContent)}
                  placeholder={'Select Date'}
                  value={value[schemaKeysMapping.start_date_comparing]}
                  onChange={onComparingToDateChange}
                  error={Boolean(errors?.[schemaKeysMapping.start_date_comparing])}
                  maxDate={anomalyComparingToDateMaxDate}
                  {...datePickersSharedProps}
                />
                {errorMessages[schemaKeysMapping.start_date_comparing] && (
                  <div className={classes.ErrorText}>
                    {errorMessages[schemaKeysMapping.start_date_comparing]}
                  </div>
                )}
              </div>
            }
          />
        </div>
      </ParameterInputWrapper>
    </>
  );
};

AnomalyPeriodSelector.defaultProps = {
  schemaKeysMapping: DEFAULT_SCHEMA_KEYS_MAPPING,
};

export default AnomalyPeriodSelector;

import {ModelDiscriminatorType} from './user-reaction.model';

export enum UsefulResourceType {
  PHENOMENA = 'phenomena',
}

export enum NotUsefulReasonsOptions {
  TOO_OBVIOUS = 'TOO_OBVIOUS',
  NOT_ACTIONABLE = 'NOT_ACTIONABLE',
  SIMILAR_TO_ANOTHER = 'SIMILAR_TO_ANOTHER',
  NOT_IMPACTFUL_ENOUGH = 'NOT_IMPACTFUL_ENOUGH',
  NOT_MY_RESPONSIBILITY = 'NOT_MY_RESPONSIBILITY',
  WRONG = 'WRONG',
  OTHER = 'OTHER',
}

export interface UsefulResourceModel {
  type: UsefulResourceType;
  reason: NotUsefulReasonsOptions;
  status: boolean;
  more: string;
}

export const usefulResourceToDiscriminator = {
  [UsefulResourceType.PHENOMENA]: ModelDiscriminatorType.PHENOMENA,
};

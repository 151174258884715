import {useMemo} from 'react';
import {useProductData} from '../../../core/hooks/use-product-data.hook';
import {useIsAdmin} from '../../../core/hooks/use-is-admin.hook';

export const useDefaultAnalysisFolderId = (defaultFolderId?: number) => {
  const isAdmin = useIsAdmin();
  const {rootPublicAnalysisFolder, rootPrivateAnalysisFolder} = useProductData();

  return useMemo(() => {
    if (defaultFolderId) {
      return defaultFolderId;
    }
    return isAdmin ? rootPrivateAnalysisFolder : rootPublicAnalysisFolder;
  }, [defaultFolderId, rootPublicAnalysisFolder, rootPrivateAnalysisFolder, isAdmin]);
};

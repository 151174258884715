import * as React from 'react';
import {ActionsDropdown, SettingsIcon, ActionItem} from 'ui-components';
import {ChartConfig} from '../../../../homepage-summary.types';
import {useMemo} from 'react';

interface OwnProps {
  chartConfig: ChartConfig;
  onChange: (config: ChartConfig) => void;
  extraActions?: ActionItem[];
  hasTarget?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const HomepageChartOptions: React.FC<AllProps> = (props: AllProps) => {
  const {chartConfig, onChange, extraActions = [], hasTarget, className} = props;
  const chartActions = useMemo(() => {
    return [
      {
        title: `${chartConfig.showAnnotations ? 'Hide' : 'Show'} annotations`,
        onClick: () => onChange({...chartConfig, showAnnotations: !chartConfig.showAnnotations}),
      },
      {
        title: `${chartConfig.showTarget ? 'Hide' : 'Show'} target`,
        onClick: () => onChange({...chartConfig, showTarget: !chartConfig.showTarget}),
        hide: !hasTarget,
      },
      {
        title: `${chartConfig.showCI ? 'Hide' : 'Show'} confidence interval`,
        onClick: () => onChange({...chartConfig, showCI: !chartConfig.showCI}),
      },
      {
        title: `${chartConfig.showQuarters ? 'Hide' : 'Show'} quarters`,
        onClick: () => onChange({...chartConfig, showQuarters: !chartConfig.showQuarters}),
      },
      ...extraActions,
    ];
  }, [chartConfig, onChange, hasTarget, extraActions]);

  return (
    <ActionsDropdown
      className={className}
      actions={chartActions}
      label={'Options'}
      icon={SettingsIcon}
      iconDropdown
    />
  );
};

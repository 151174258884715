import * as React from 'react';
import {CSSProperties, useContext, useMemo} from 'react';
import {Handle, Position, useConnection, useNodeId} from '@xyflow/react';
import {HandleProps} from '@xyflow/react/dist/esm/components/Handle';
import {KPITreeContext} from '../../kpi-tree.context';
import {TreeMode} from '../../kpi-tree.consts';

type CustomProps = {
  hide?: boolean;
};
type Props = CustomProps & HandleProps & import('react').RefAttributes<HTMLDivElement>;

// @ts-ignore
const HandleComponent = Handle as any;

export const DEFAULT_HANDLE_COLOR = 'rgba(197, 201, 219, 1)';
export const CONNECTABLE_HANDLE_COLOR = 'rgba(52, 131, 255, 1)';
export const DEFAULT_HANDLE_STYLE = {
  background: DEFAULT_HANDLE_COLOR,
  height: '0.8rem',
  width: '0.8rem',
  transition: 'all 0.25s',
};
export const EDIT_MODE_HANDLE_STYLE = {
  background: DEFAULT_HANDLE_COLOR,
  height: '1.2rem',
  width: '1.2rem',
};

const oppositeDirection = (position: Position) =>
  position === Position.Top ? Position.Bottom : Position.Top;

export const TreeHandle: React.FC<Props> = (props: Props) => {
  const {hide, position} = props;
  const {mode} = useContext(KPITreeContext);
  const {inProgress, fromNode, fromPosition} = useConnection();

  const nodeId = useNodeId();

  const style: CSSProperties = useMemo(() => {
    const base = {
      ...(mode === TreeMode.VIEW ? DEFAULT_HANDLE_STYLE : EDIT_MODE_HANDLE_STYLE),
    };
    if (hide) {
      base['visibility'] = 'hidden';
    }

    const shouldChangeColorOther =
      inProgress && position === oppositeDirection(fromPosition) && fromNode.id !== nodeId;
    const shouldChangeColorSelf = inProgress && position === fromPosition && fromNode.id === nodeId;

    if (shouldChangeColorOther || shouldChangeColorSelf) {
      base['background'] = CONNECTABLE_HANDLE_COLOR;
    }
    return base;
  }, [mode, inProgress, fromPosition, hide, fromNode?.id, nodeId, position]);

  return <HandleComponent style={style} {...props} />;
};

import {useRemoteSourceStated} from 'ui-components';
import {getSimilarAnalysesNetworkRequest} from '../../../http/analyses.network-requests';
import {useEffect} from 'react';

export const useSimilarAnalyses = (analysisId: number, limit?: number) => {
  const {
    source: similarAnalyses,
    exec,
    isLoading,
  } = useRemoteSourceStated({
    type: 'source-list',
    networkRequest: getSimilarAnalysesNetworkRequest,
  });

  useEffect(() => {
    if (analysisId) {
      exec(analysisId, {limit});
    }
  }, [exec, analysisId, limit]);

  return {
    similarAnalyses,
    isLoading,
  };
};

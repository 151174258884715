import * as React from 'react';
import {useCallback, useContext} from 'react';
import classes from '../app-announcement-bar.module.scss';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {PanelsContext} from '../../../../../core/contexts/panels.context';
import {PanelKey} from '../../../../../constants/panels';

interface OwnProps {}

type AllProps = OwnProps;

export const DemoEnvironmentAnnouncement: React.FC<AllProps> = (props: AllProps) => {
  const {t} = useTranslation();
  const {openSecondaryPanel} = useContext(PanelsContext);

  const onClick = useCallback(() => {
    openSecondaryPanel(PanelKey.BOOK_A_DEMO_PANEL, {});
  }, [openSecondaryPanel]);

  return (
    <div className={classes.AnnouncementBar}>
      <span>{t(TransKeys.APP_ANNOUNCEMENT.DEMO_ENVIRONMENT)}</span>
      <span>-</span>
      <span className={classes.DemoButton} onClick={onClick}>
        {t(TransKeys.APP_ANNOUNCEMENT.DEMO_ENVIRONMENT_SCHEDULE_DEMO)}
      </span>
    </div>
  );
};

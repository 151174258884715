import {ActionItem, ActionsDropdown, ActionsDropdownProps} from 'ui-components';
import {useDemoProduct} from '../../../../core/hooks/use-demo-product.hook';
import {useMemo} from 'react';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';

export interface ModelActionItem extends ActionItem {
  showEnabled?: boolean;
}

interface ModelActionsDropdownProps extends Omit<ActionsDropdownProps, 'actions'> {
  actions: ModelActionItem[];
}
type AllProps = ModelActionsDropdownProps;

export const ModelActionsDropdown: React.FC<AllProps> = props => {
  const {actions: actionsFromProps} = props;
  const {isDemoProduct} = useDemoProduct();
  const {t} = useTranslation();

  const actions = useMemo(() => {
    if (!isDemoProduct) {
      return actionsFromProps;
    }

    // Relevant only for Demo product
    return actionsFromProps.map(action => {
      if (action.showEnabled) {
        return {
          ...action,
          disabled: false,
          hide: false,
        };
      }
      return {
        ...action,
        disabled: isDemoProduct,
        helperText: t(TransKeys.DEMO.DISABLED_ACTION_TOOLTIP),
      };
    });
  }, [isDemoProduct, actionsFromProps, t]);

  return <ActionsDropdown {...props} actions={actions} />;
};

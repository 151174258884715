import {EventGroup, EventGroupType} from '../models/event-group.model';
import yup from '../../config/yup.config';
import httpClientService from '../../services/http-client.service';
import {validateNameNetworkRequest} from '../../http/validations.network-requests';
import {values} from 'lodash';
import {TableEntity} from '../models/table.model';
import {queryElementValidatorFactory} from './query-builder.dto';
import {SAVED_SIGNAL_TAGS} from '../../constants/saved-signal-slugs';
import {SignalDataType} from '../models/signal.model';

export type EventGroupDTO = Partial<EventGroup>;

export const eventGroupDTOValidator = yup.object().shape({
  id: yup.number().optional().nullable(),
  name: yup
    .string()
    .required()
    .test('not_allowed_name', 'Sorry, this name is not allowed', name => {
      const slugifiedName = name.trim().replace(' ', '_').toLowerCase();
      return SAVED_SIGNAL_TAGS.indexOf(slugifiedName) === -1;
    })
    .test('unique_name', 'Name already exists', async function (name: string) {
      const {id} = this.parent;
      try {
        await httpClientService.exec(
          validateNameNetworkRequest({
            name,
            existingId: id,
            modelType: 'event-group',
          })
        );
        return true;
      } catch (e) {
        return false;
      }
    }),
  shortDescription: yup.string().nullable(),
  entity: yup.string().oneOf(values(TableEntity)).required(),
  type: yup.string().oneOf(values(EventGroupType)).required(),
  signalId: yup.number().nullable(),
  signalDataType: yup.string().oneOf(values(SignalDataType)).required(),
  signalDefinition: queryElementValidatorFactory(true),
});

import * as React from 'react';
import {useCallback, useEffect, useLayoutEffect, useMemo, useRef} from 'react';
import classNames from 'classnames';
import classnames from 'classnames';
import classes from './homepage-list.module.scss';
import {ModelSampleSeriesModel} from '../../../../../../objects/models/model-sample-series.model';
import {PlusSolidIcon, PopoverWrapper, SearchIcon, TextInput} from 'ui-components';
import {exists} from 'front-core';
import TransKeys from '../../../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {
  HomepageItemChangeEventData,
  HomepageItemSelector,
} from '../homepage-item-selector/homepage-item-selector.component';
import {
  AttachedHomepageModel,
  HomepageFunnel,
  HomepageMetric,
  HomepageModel,
} from '../../../../../../objects/models/homepage.model';
import {HomepageMetricListItem} from './components/homepage-metric-list-item.component';
import {HomepageFunnelListItem} from './components/homepage-funnel-list-item.component';

interface OwnProps {
  searchValue: string;
  onSearchValueChange: (search: string | null) => void;
  items: HomepageModel[];
  selectedModelId?: number;
  selectedModelType?: ModelSampleSeriesModel;
  homepageItems: AttachedHomepageModel[];
  onSelectItem: (id: number, modelType: ModelSampleSeriesModel) => void;
  onAddModel: (modelId: number, modelType: ModelSampleSeriesModel) => void;
  onRemoveModel: (modelId: number, modelType: ModelSampleSeriesModel) => void;
  onCreateMetric: (categoryId?: number) => void;
  onCreateFunnel: () => void;
  onReorderMetrics: (ids: number[]) => void;
  addMetricButtonRef: any;
  className?: string;
}

type AllProps = OwnProps;

const generateListItemId = (modelId: number, modelType: ModelSampleSeriesModel) =>
  `${modelType}-${modelId}`;

export enum HomepageListMode {
  DEFAULT,
  EDIT,
  SEARCH,
}

const HOMEPAGE_FIRST_METRIC_ITEM_FAKE_CLASS = 'homepage-first-metric-item';

export const HomepageList: React.FC<AllProps> = (props: AllProps) => {
  const {
    items: itemsFromProps,
    homepageItems,
    selectedModelId,
    selectedModelType,
    onSelectItem,
    onAddModel,
    onRemoveModel,
    onCreateMetric: onCreateMetricFromProps,
    onCreateFunnel: onCreateFunnelFromProps,
    onSearchValueChange: onSearchValueChangeFromProps,
    searchValue,
    onReorderMetrics,
    addMetricButtonRef,
    className,
  } = props;
  const {t} = useTranslation();
  const selectorRef = useRef<any>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const isListItemsReady = useRef<boolean>(false);
  const [mode, setMode] = React.useState(HomepageListMode.DEFAULT);
  const [itemsOrder, setItemsOrder] = React.useState<number[]>(homepageItems.map(m => m.pivotId));
  const homepageItemsMap = useMemo(
    () =>
      homepageItems.reduce((acc, item) => {
        acc[`${item.modelType}_${item.modelId}`] = item;
        return acc;
      }, {}),
    [homepageItems]
  );
  const items = useMemo(() => {
    if (searchValue) {
      return itemsFromProps;
    }
    return itemsFromProps
      .map(i => {
        const pivotId = homepageItemsMap[`${i.modelType}_${i.id}`]?.pivotId;
        return {
          ...i,
          order: pivotId ? itemsOrder.indexOf(pivotId) : 1000,
        };
      })
      .sort((a, b) => a.order - b.order) as HomepageModel[];
  }, [searchValue, itemsFromProps, homepageItemsMap, itemsOrder]);
  const homepageItemsSet: {[key in ModelSampleSeriesModel]?: Set<number>} = useMemo(
    () => ({
      [ModelSampleSeriesModel.FUNNEL]: new Set(
        homepageItems.filter(i => i.modelType === ModelSampleSeriesModel.FUNNEL).map(i => i.modelId)
      ),
      [ModelSampleSeriesModel.METRIC]: new Set(
        homepageItems.filter(i => i.modelType === ModelSampleSeriesModel.METRIC).map(i => i.modelId)
      ),
    }),
    [homepageItems]
  );
  const excludeIds = useMemo(
    () => ({
      [ModelSampleSeriesModel.FUNNEL]: Array.from(
        homepageItemsSet[ModelSampleSeriesModel.FUNNEL] || []
      ),
      [ModelSampleSeriesModel.METRIC]: Array.from(
        homepageItemsSet[ModelSampleSeriesModel.METRIC] || []
      ),
    }),
    [homepageItemsSet]
  );
  const onAdd = useCallback(
    (data: HomepageItemChangeEventData) => {
      if (selectorRef.current) {
        selectorRef.current.close();
      }
      onAddModel(data.value, data.type);
    },
    [selectorRef, onAddModel]
  );
  const onCreateMetric = useCallback(
    categoryId => {
      if (selectorRef.current) {
        selectorRef.current.close();
      }
      onCreateMetricFromProps(categoryId);
    },
    [onCreateMetricFromProps, selectorRef]
  );
  const onCreateFunnel = useCallback(() => {
    if (selectorRef.current) {
      selectorRef.current.close();
    }
    onCreateFunnelFromProps();
  }, [onCreateFunnelFromProps, selectorRef]);
  const onEdit = useCallback(
    () =>
      setMode(mode =>
        mode !== HomepageListMode.EDIT ? HomepageListMode.EDIT : HomepageListMode.DEFAULT
      ),
    [setMode]
  );
  const onSearchValueChange = useCallback(
    (term: string) => {
      onSearchValueChangeFromProps(term);
      if (!exists(term)) {
        setMode(HomepageListMode.DEFAULT);
        return;
      }
      setMode(HomepageListMode.SEARCH);
    },
    [onSearchValueChangeFromProps]
  );
  const onDragEnd = useCallback(
    data => {
      if (!data.destination) {
        return;
      }
      const startIndex = data.source.index;
      const endIndex = data.destination.index;
      const result = [...itemsOrder];
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      setItemsOrder(result);
      onReorderMetrics(result);
    },
    [itemsOrder, setItemsOrder, onReorderMetrics]
  );
  useEffect(() => {
    setItemsOrder(homepageItems.map(m => m.pivotId));
  }, [homepageItems, setItemsOrder]);
  // this is used to bypass the issue when Draggable items are rendered long after the list is ready!
  const isListItemsReadyCurrent = isListItemsReady.current;
  useLayoutEffect(() => {
    if (listRef.current && selectedModelId && isListItemsReadyCurrent) {
      const elem = listRef.current.querySelector(
        `#${generateListItemId(selectedModelId, selectedModelType)}`
      );
      if (elem === null) {
        return;
      }
      // @ts-ignore
      elem.scrollIntoViewIfNeeded?.({behavior: 'smooth'});
    }
  }, [selectedModelId, selectedModelType, isListItemsReadyCurrent]);

  const renderListItem = (item: HomepageModel, idx: number) => {
    const sharedProps = {
      mode,
      onClick: () => onSelectItem(item.id, item.modelType),
      onAdd: () => onAddModel(item.id, item.modelType),
      onRemove: () => onRemoveModel(item.id, item.modelType),
      isSelected: selectedModelId === item.id && selectedModelType === item.modelType,
      isOnHomepage: homepageItemsSet[item.modelType]?.has(item.id) || false,
      className: classNames(idx === 0 && HOMEPAGE_FIRST_METRIC_ITEM_FAKE_CLASS),
    };
    if (item.modelType === ModelSampleSeriesModel.METRIC) {
      return <HomepageMetricListItem item={item as HomepageMetric} {...sharedProps} />;
    }
    if (item.modelType === ModelSampleSeriesModel.FUNNEL) {
      return <HomepageFunnelListItem item={item as HomepageFunnel} {...sharedProps} />;
    }
  };

  return (
    <div className={classNames(classes.HomepageList, className)}>
      <div className={classes.Search}>
        <TextInput
          value={searchValue}
          onChange={v => onSearchValueChange((v || '').toString())}
          className={classnames(classes.SearchBox, className)}
          iconClassName={classes.SearchIcon}
          inputClassName={classes.SearchInput}
          placeholder={'Search'}
          icon={SearchIcon}
          clearable
        />
        {mode !== HomepageListMode.SEARCH && (
          <div onClick={() => onEdit()} className={classes.EditButton}>
            {mode === HomepageListMode.EDIT
              ? t(TransKeys.GENERAL.ACTIONS.DONE)
              : t(TransKeys.GENERAL.ACTIONS.EDIT)}
          </div>
        )}
      </div>
      {/*BASED ON: https://codesandbox.io/p/sandbox/k260nyxq9v?file=%2Findex.js%3A57%2C24 */}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {provided => (
            <div
              {...provided.droppableProps}
              className={classes.List}
              ref={ref => {
                listRef.current = ref;
                provided.innerRef(ref);
              }}
            >
              {exists(searchValue) && items.length === 0 && (
                <div className={classes.EmptyState}>
                  {t(TransKeys.HOMEPAGE.NO_RESULTS_FOR_SEARCH_EMPTY_STATE, {
                    search_value: searchValue,
                  })}
                </div>
              )}
              {items.map((item, idx) => (
                <Draggable
                  index={idx}
                  key={generateListItemId(item.id, item.modelType)}
                  draggableId={generateListItemId(item.id, item.modelType)}
                  isDragDisabled={mode === HomepageListMode.SEARCH}
                >
                  {provided => (
                    <div
                      id={generateListItemId(item.id, item.modelType)}
                      ref={ref => {
                        provided.innerRef(ref);
                        isListItemsReady.current = true;
                      }}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {renderListItem(item, idx)}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {mode === HomepageListMode.DEFAULT && (
        <PopoverWrapper
          ref={selectorRef}
          placement={'top'}
          buttonRenderer={({onClick, isOpen}) => (
            <div className={classes.AddMetric} onClick={onClick} ref={addMetricButtonRef}>
              <PlusSolidIcon className={classes.AddIcon} />
              {t(TransKeys.GENERAL.ACTIONS.ADD)}
            </div>
          )}
        >
          <HomepageItemSelector
            onChange={onAdd}
            excludeMetricIds={excludeIds[ModelSampleSeriesModel.METRIC]}
            excludeFunnelIds={excludeIds[ModelSampleSeriesModel.FUNNEL]}
            onCreateMetric={onCreateMetric}
            onCreateFunnel={onCreateFunnel}
          />
        </PopoverWrapper>
      )}
    </div>
  );
};

import {NRC} from './index';
import {RequestType} from 'front-core';
import {toCamelCase} from 'front-core';
import {GrowthMapFiltersDTO} from '../objects/dto/growth-map-filters.dto';
import {toGrowthMapFilters} from '../objects/dto/to-filters.dto';

export const getGrowthMapNetworkRequest: NRC<void, any> = (payload: GrowthMapFiltersDTO) => ({
  method: RequestType.GET,
  relativeUrl: `/app/growth-map`,
  query: payload,
  queryTransformer: toGrowthMapFilters,
  responseTransformer: res => {
    const data = toCamelCase(res.data);
    data['fromDate'] = new Date(data['fromDate']);
    data['toDate'] = new Date(data['toDate']);
    return data;
  },
});

import {exists, withStopPropagation} from 'front-core';
import {useTranslation} from 'react-i18next';
import TransKeys from 'src/constants/translation-keys';
import {useDemoProduct} from 'src/core/hooks/use-demo-product.hook';
import {useRerunExperiment} from 'src/core/hooks/use-rerun.hook';
import {ModelActionsDropdown} from 'src/modules/shared/core/model-actions-dropdown/model-actions-dropdown.component';
import {
  CircleCheckRegularIcon,
  CopyIcon,
  EditIcon,
  MoreIcon,
  RenameIcon,
  RerunIcon,
  RSSIcon,
  ShareIcon,
  TeamIconChip,
  TrashIcon,
  UserGroupLightIcon,
} from 'ui-components';

import {
  Experiment,
  ExperimentStatus,
} from '../../../../../../../../../objects/models/experiment.model';
import {useExperimentActions} from '../../../../../../../hooks/use-experiment-actions.hook';
import classes from './experiments-actions-cell.module.scss';
import classNames from 'classnames';
import {useProductData} from 'src/core/hooks/use-product-data.hook';
import {useMemo} from 'react';

interface Props {
  experiment: Experiment;
  className?: string;
}

export const ExperimentsListRowItemActions = (props: Props) => {
  const {experiment: exp, className} = props;

  const {t} = useTranslation();
  const {isDemoProduct} = useDemoProduct();
  const {actualTeams: teams} = useProductData();

  const {onRerun, getExperimentRerunHelperText, shouldDisableRerun} = useRerunExperiment();

  const {
    onMarkComplete,
    setExperimentTeam,
    onEditDuplicate,
    onSubscribe,
    onShare,
    onDelete,
    onRename,
  } = useExperimentActions();

  const params: any = {};
  if (exp.lastCompletedAnalysisResult) {
    params.analysisResultId = exp.lastCompletedAnalysisResult.id;
  } else if (exp.lastAnalysisResultId) {
    params.analysisResultId = exp.lastAnalysisResultId;
  } else {
    params.analysisId = exp.analysisId;
  }

  const teamOptions = useMemo(
    () => (exists(exp.team?.id) ? teams.filter(t => exp.team.id === t.id) : teams),
    [teams, exp]
  );

  return (
    <div className={classNames(classes.Actions, className)}>
      <ModelActionsDropdown
        border={false}
        rounded={false}
        actions={[
          {
            key: 'add-to-team',
            title: t(TransKeys.GENERAL.ACTIONS.ASSIGN_TO_TEAM),
            icon: UserGroupLightIcon,
            hide: teamOptions.length === 0,
            children: teamOptions.map(team => ({
              key: team.id.toString(),
              title: team.name,
              icon: ({className}) => <TeamIconChip {...team} className={className} />,
              onClick: _ => setExperimentTeam(exp.id, team.id),
            })),
          },
          {
            key: 'complete',
            icon: CircleCheckRegularIcon,
            title: t(TransKeys.GENERAL.ACTIONS.COMPLETE),
            hide: exp.status === ExperimentStatus.DONE,
            onClick: _ => onMarkComplete(exp),
          },
          {
            key: 'rename',
            title: t(TransKeys.GENERAL.ACTIONS.RENAME),
            onClick: _ => onRename(exp.id),
            icon: RenameIcon,
            showEnabled: true,
          },
          {
            key: 'edit',
            title: t(TransKeys.GENERAL.ACTIONS.EDIT),
            onClick: () => onEditDuplicate(exp),
            disabled: exp.status === ExperimentStatus.DONE,
            icon: EditIcon,
            showEnabled: true,
          },
          {
            key: 'duplicate',
            title: t(TransKeys.GENERAL.ACTIONS.DUPLICATE),
            onClick: () => onEditDuplicate(exp, true),
            icon: CopyIcon,
            showEnabled: true,
          },
          {
            key: 'delete',
            title: t(TransKeys.GENERAL.ACTIONS.DELETE),
            onClick: () => onDelete(exp),
            icon: TrashIcon,
          },
          {
            key: 'subscribe',
            title: t(TransKeys.GENERAL.ACTIONS.SUBSCRIBE),
            onClick: () => onSubscribe(exp),
            disabled: exp.status === ExperimentStatus.DONE,
            icon: RSSIcon,
            showEnabled: true,
          },
          {
            key: 'share',
            title: t(TransKeys.GENERAL.ACTIONS.SHARE),
            onClick: _ => onShare(exp),
            icon: ShareIcon,
            showEnabled: true,
          },
          {
            key: 'rerun',
            title: t(TransKeys.GENERAL.ACTIONS.RERUN),
            onClick: () => onRerun(exp),
            disabled: shouldDisableRerun(exp),
            helperText: getExperimentRerunHelperText(exp),
            icon: RerunIcon,
          },
          {
            key: 'complete',
            title: t(TransKeys.GENERAL.ACTIONS.COMPLETE),
            hide: !isDemoProduct && exp?.status === ExperimentStatus.IN_PROGRESS,
            onClick: withStopPropagation(() => onMarkComplete(exp)),
          },
        ]}
        label={t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
        iconDropdown
        icon={MoreIcon}
      />
    </div>
  );
};

export enum AIConversationRole {
  USER = 'user',
  SYSTEM = 'system',
  ASSISTANT = 'assistant',
}

export enum AICMessageFeedback {
  CORRECT = 'correct',
  INCORRECT = 'incorrect',
}

export enum AIModelType {
  METRIC = 'metric',
  SEGMENT = 'segment',
  FEATURE = 'feature',
  FUNNEL = 'funnel',
}

export interface RunAnalysisAction {
  type: 'run_analysis';
  text?: string;
  analysisTypeId: number;
  parameters: any;
}

export interface ModelsListAction {
  type: 'models_list';
  text?: string;
  modelType: AIModelType;
  items: Array<{id: number; name: string}>;
}

export type Action = RunAnalysisAction | ModelsListAction;

export interface AIConversationMessage {
  id: number;
  role: AIConversationRole;
  content: string;
  message?: any;
  feedback?: AICMessageFeedback;
  action?: Action;
  createdOn: string;
}

export interface AIConversationThread {
  id: number;
  title?: string;
  messages?: AIConversationMessage[];
  completedOn: string;
  createdOn?: string;
  isActive?: boolean;
}

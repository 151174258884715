import yup from '../../../../../config/yup.config';
import {PARAMETERS_METADATA_KEY} from '../../../../../constants/parameters-saved-keys';
import {get, values} from 'lodash';
import {TableEntity} from '../../../../../objects/models/table.model';
import {queryElementValidatorFactory} from '../../../../../objects/dto/query-builder.dto';
import {exists} from 'front-core';
import moment from 'moment/moment';
import {TIME_FORMATS} from '../../../../../constants/time-formats';
import {queryValidator, startDateBeforeEndDateTest} from '../shared-validators';
import {ParameterType} from 'ui-components';
import {
  AnalysisParametersTransformer,
  baseAnalysisParametersTransformer,
} from '../shared-transformers';
import {growthbook} from '../../../../../config/growthbook.config';
import {FeatureFlag} from '../../../../../constants/feature-flags';

export const analysis91ParametersValidator = yup.object().shape({
  parameters: yup
    .object()
    .shape({
      [PARAMETERS_METADATA_KEY]: yup.object().nullable(),
      entity: yup.string().oneOf(values(TableEntity)).required(),
      assignment_mode: yup.string().oneOf(['new_users', 'events', 'as_assignment']).required(),
      variant_query: queryValidator(),
      start_event_query: yup.object().when('assignment_mode', {
        is: 'events',
        then: schema => queryValidator(true),
        otherwise: schema => queryValidator(false),
      }),
      conversion_event: yup.number().required(),
      secondary_conversion_events: yup.array().of(yup.number().required()).nullable(),
      bound_kpis_to_assignment: yup.boolean().nullable(),
      bound_kpi: yup.boolean().nullable(),
      population_filter: queryElementValidatorFactory(false),
      minimum_running_days: yup.number().min(0).nullable(),
      experiment_threshold: yup.number().min(0).max(1).required(),
      user_end_date: yup.string().nullable(),
      filter_users_who_converted_before_start_event: yup.boolean().required(),
      filter_users_who_did_not_reach_kpi_reference_date: yup.boolean().required(),
      pre_release_date: yup
        .string()
        .nullable()
        .test(
          'is_less_then_start_date',
          'Gradual release must be before start date',
          function (pre_release_date: string) {
            // @ts-ignore
            const [, root] = this.from;
            const start_date = root.value.runParameters.start_date;
            if (!start_date) {
              return true;
            }
            if (!exists(pre_release_date)) {
              return true;
            }
            const startDate = moment(start_date, TIME_FORMATS.PARAMETER_DATE_FORMAT);
            const preReleaseDate = moment(pre_release_date, TIME_FORMATS.PARAMETER_DATE_FORMAT);
            return startDate.isAfter(preReleaseDate);
          }
        ),
    })
    .required(),
  runParameters: yup
    .object()
    .shape({
      start_date: yup.string().required(),
      end_date: yup.string().nullable(),
    })
    .test(startDateBeforeEndDateTest(true)),
});

export const analysis91ParametersTransformer: AnalysisParametersTransformer = (
  initialValues,
  initialSchema,
  productEntities
) => {
  const [values, schema] = baseAnalysisParametersTransformer(
    initialValues,
    initialSchema,
    productEntities
  );
  const newValues = {...values};
  const ASSIGNMENT_TYPE_TO_STRING = {
    column: 'Property based',
    event: 'Specific Event Property',
    sql: 'SQL',
  };

  schema['assignment_type'] = {
    name: 'Assignment type',
    key: 'assignment_type',
    type: ParameterType.STRING,
    order: 0,
  };
  newValues['assignment_type'] =
    ASSIGNMENT_TYPE_TO_STRING[get(newValues[PARAMETERS_METADATA_KEY], 'strategy') || 'column'];

  schema['variant_query'] = {
    ...schema['variant_query'],
    name: 'Assignment definition',
    order: 1,
  };
  if (!exists(newValues['variant_query'])) {
    delete schema['variant_query'];
  }
  if ('assignment_mode' in schema) {
    delete schema['assignment_mode'];
  }
  if ('control_group_name' in schema && 'control_group_name' in values) {
    schema['control_group_name'] = {
      ...schema['control_group_name'],
      name: 'Control group',
      order: 2,
    };
  }
  if ('start_date' in schema && !('user_end_date' in newValues)) {
    schema['start_date'] = initialSchema['start_date'];
    delete schema['end_date'];
    schema['start_date'].order = 3;
    schema['start_date'].name = 'Start date';
    newValues['start_date'] = moment(
      initialValues['start_date'],
      TIME_FORMATS.PARAMETER_DATE_FORMAT
    ).format(TIME_FORMATS.READABLE_DATE);
  }
  // remove user_end_date
  delete schema['user_end_date'];
  delete newValues['user_end_date'];

  if ('start_event_query' in schema) {
    schema['start_event_query'].name = 'Experiment scope';
    schema['start_event_query'].order = 4;
  }
  if ('conversion_event' in schema) {
    schema['conversion_event'].name = 'Primary KPI';
    schema['conversion_event'].order = 5;
  }
  if ('secondary_conversion_events' in schema) {
    schema['secondary_conversion_events'].name = 'Secondary KPIs';
    schema['secondary_conversion_events'].order = 6;
  }
  if ('experiment_threshold' in schema) {
    schema['experiment_threshold'].name = 'Statistical significance';
    schema['experiment_threshold'].order = 7;
    schema['experiment_threshold'].type = ParameterType.STRING;
    newValues['experiment_threshold'] = `${newValues['experiment_threshold'] * 100}%`;
  }
  if ('population_filter' in schema) {
    schema['population_filter'].name = 'Experiment population';
    schema['population_filter'].order = 8;
  }
  if ('minimum_running_days' in schema) {
    schema['minimum_running_days'].order = 9;
  }
  if ('pre_release_date' in schema) {
    schema['pre_release_date'].order = 10;
    schema['pre_release_date'].name = 'Gradual release date';
  }
  if ('filter_users_who_converted_before_start_event' in schema) {
    schema['filter_users_who_converted_before_start_event'].order = 11;
    schema['filter_users_who_converted_before_start_event'].name =
      'Exclude conversion before assignment';
  }
  if ('bound_kpis' in schema) {
    schema['bound_kpis'].order = 12;
    schema['bound_kpis'].name = 'Exclude non-mature users';
  }
  if (!growthbook.isOn(FeatureFlag.AB_TEST_KPI_BOUND)) {
    delete schema['bound_kpis'];
  }

  return [newValues, schema];
};

import {RemoteListConfig} from './remote-list.types';
import {ModelKey} from '../../constants/model-key';
import {values} from 'lodash';

const DEFAULT_CONFIG: Partial<RemoteListConfig> = {
  primaryKey: 'id',
};

class RemoteListsStoreService {
  private lists: {[listKey: string]: RemoteListConfig} = {};

  addList(listKey: string, config: RemoteListConfig) {
    if (listKey in this.lists) {
      throw new Error(`${listKey} already exists`);
    }
    this.lists[listKey] = {
      ...DEFAULT_CONFIG,
      ...config,
    };
  }

  removeList(listKey: string) {
    delete this.lists[listKey];
  }

  getListConfig(listKey: string): RemoteListConfig {
    return this.lists[listKey];
  }

  getConfigsByModelKey(modelKey: ModelKey): RemoteListConfig[] {
    return values(this.lists).filter(l => l.modelKey === modelKey);
  }
}

export default new RemoteListsStoreService();

import {Linker} from 'front-core';
import {AUTH_STORE_KEY} from '../../store/auth/auth.store';
import {ActionKey} from '../../constants/action-key';
import {getReducedLoadingStateSelector} from '../../store/store.selectors';
import {CORE_STORE_KEY} from '../../store/core/core.store';
import {ConfirmationMessage} from '../../objects/system/confirmation-message.model';
import {INTERFACE_STORE_KEY} from '../../store/interface/interface.store';

export interface AppStatusCounters {
  newAnalyses?: number;
}

export interface CoreViewModel {
  user: any;
  isLoading: boolean;
  scope: any;
  confirmationMessage: ConfirmationMessage;
  appStatusCounters: AppStatusCounters;
}

export const coreLinker: Linker<CoreViewModel> = {
  user: {
    store: AUTH_STORE_KEY,
    property: 'user',
  },
  isLoading: {
    selector: getReducedLoadingStateSelector(ActionKey.INITIAL_LOADING),
  },
  scope: {
    store: CORE_STORE_KEY,
    property: 'scopeProduct',
  },
  confirmationMessage: {
    store: INTERFACE_STORE_KEY,
    property: 'confirmationMessage',
  },
  appStatusCounters: {
    store: CORE_STORE_KEY,
    property: 'appStatusCounters',
  },
};

import {Reducer} from 'redux';
import {LocalStorageManager} from 'ui-components';
import {LocalStorageKey} from '../../constants/local-storage-key';

const DRAWER_COLLAPSE_TTL = 1000 * 60 * 60 * 12; // 12 hours

export const setDrawerCollapseReducer = (state, action): Reducer => {
  const drawerCollapsed = action.payload;

  if (drawerCollapsed) {
    LocalStorageManager.setItem(
      LocalStorageKey.USER_ONBOARDING_FORCE_COLLAPSE,
      true,
      DRAWER_COLLAPSE_TTL
    );
  } else {
    LocalStorageManager.removeItem(LocalStorageKey.USER_ONBOARDING_FORCE_COLLAPSE);
  }

  return {
    ...state,
    drawerCollapsed,
  };
};

export const setResettingOnboardingReducer = (state, action): Reducer => {
  return {
    ...state,
    resettingOnboarding: action.payload,
  };
};

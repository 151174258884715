import {action, OnSuccessActionHook} from 'front-core';
import {showConfirmationMessage} from '../interface/interface.actions';
import i18n from '../../config/i18n.config';
import TransKeys from '../../constants/translation-keys';
import {EventGroupDTO} from '../../objects/dto/event-group.dto';

export enum ExperimentAutomationActionType {
  CREATE_EXPERIMENT_AUTOMATION = '@@experiment-automations/CREATE_EXPERIMENT_AUTOMATION',
  UPDATE_EXPERIMENT_AUTOMATION = '@@experiment-automations/UPDATE_EXPERIMENT_AUTOMATION',
  DELETE_EXPERIMENT_AUTOMATION = '@@experiment-automations/DELETE_EXPERIMENT_AUTOMATION',
}

export const createExperimentAutomation = (data: EventGroupDTO, onSuccess?: OnSuccessActionHook) =>
  action(ExperimentAutomationActionType.CREATE_EXPERIMENT_AUTOMATION, data, {onSuccess});

export const updateExperimentAutomation = (data: EventGroupDTO, onSuccess?: OnSuccessActionHook) =>
  action(ExperimentAutomationActionType.UPDATE_EXPERIMENT_AUTOMATION, data, {onSuccess});

export const deleteExperimentAutomation = (id: number) =>
  action(ExperimentAutomationActionType.DELETE_EXPERIMENT_AUTOMATION, id);

export const deleteExperimentAutomationConfirmed = (id: number) =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.DELETE_EXPERIMENT_AUTOMATION.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.DELETE_EXPERIMENT_AUTOMATION.CONTENT),
    },
    () => [deleteExperimentAutomation(id)]
  );

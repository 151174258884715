import {BaseModel} from './base.model';
import {HistorifyModel} from './historify-model.model';
import {Team} from './team.model';

export enum ExperimentType {
  AB = 'ab',
  PRE_POST = 'pre_post', // displayed at Release Impact
  COMMUNICATION_AB = 'communication_ab',
}

export enum ExperimentStatus {
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  DONE = 'done',
  STOPPED = 'stopped',
}

// Currently not in use - will be in use in experiment drilldown
// export interface ExperimentVariant {
//   isControl: boolean;
//   name: string;
//   lift: number;
//   size: number;
//   probabilityToWin: number;
// }
//
// export interface ExperimentMetricSeries {
//   lastSampleDate: string;
//   signalId: number;
//   name: string;
//   experimentThreshold: number;
//   variants: Array<ExperimentVariant>;
// }

export interface Experiment extends BaseModel, HistorifyModel {
  productId: number;
  loopId?: number;
  name: string;
  shortDescription: string;
  lastAnalysisResultId?: number;
  isFetchingData?: boolean;
  reRunningAnalysisResult: boolean;
  lastAnalyzedOn?: string;
  ownerUserName?: string;
  team: Team;
  lastCompletedAnalysisResult?: {
    id: number;
    outputs: any;
    createdOn: string;
    updatedOn: string;
    executedSqlsCount: number;
  };
  analysisId?: number;
  analysis?: {
    id: number;
    title: string;
    lastAnalysisResultId?: number;
    analysisTypeId: number;
    analysisName: string;
    resultsCount: number;
    parameters?: {
      secondaryGoal?: number[] | null;
      secondaryConversionEvents?: number[] | null;
    };
  };
  experimentAutomation?: {
    id: number;
    name: string;
  };
  releaseDate: string;
  related?: Array<Partial<Experiment>>;
  externalIdExperiment: string;
  type: ExperimentType;
  status: ExperimentStatus;
  preview?: any;
  stoppingCriteria?: any;
  finalResults?: any;
  learnings?: string;
  nextDescription?: string;
  startedOn?: string;
  endedOn?: string;
  cronExp?: string;
  isViewed: boolean;
  subscribers?: Array<{id: number; fullName: string}>;
  mainKpi: {id: number; name: string};
}

export type ExperimentStatusCountsMap = Record<ExperimentStatus | 'all', number | undefined>;
export type ExperimentTypeCountsMap = Record<ExperimentType | 'all', number | undefined>;

export type ExperimentsCounts = {
  status: ExperimentStatusCountsMap;
  type: ExperimentTypeCountsMap;
};

import {PeriodAllTimeHighOrLowInsight} from '../objects';
import {useMemo} from 'react';
import {ChangeDescription} from '../../rca/objects';
import {
  SentenceContainer,
  SentencePart,
} from '../../components/shared-sentence-parts/shared-sentence-parts.components';

export const PeriodAllTimeHighOrLowModelSampleInsightFormatter: React.FC<
  PeriodAllTimeHighOrLowInsight
> = (props: PeriodAllTimeHighOrLowInsight) => {
  const {sentenceParts} = props;

  const trendDescription = useMemo(
    () =>
      sentenceParts.valueBasedChangeDescription === ChangeDescription.INCREASE
        ? 'highest'
        : 'lowest',
    [sentenceParts]
  );

  return (
    <SentenceContainer>
      <SentencePart text={'This is the'} />
      <SentencePart bold text={trendDescription} />
      <SentencePart text={'KPI value over the last 6 weeks.'} />
    </SentenceContainer>
  );
};

import * as React from 'react';
import {
  MultiLoadResponse,
  SmartSelectorParameters,
  SmartSelectorSource,
  SmartSelector,
  useRemoteSourceStated,
} from 'ui-components';
import {useCallback, useContext, useEffect, useMemo} from 'react';
import {exists, HttpClientContext} from 'front-core';
import classes from './homepage-item-selector.module.scss';
import {groupBy} from 'lodash';
import {getMetricCategoriesNetworkRequest} from '../../../../../../http/metric-categories.network-requests';
import {useTeamFilter} from '../../../../../../core/contexts/team-filter.context';
import {
  funnelSmartSelectorNetworkRequest,
  metricSmartSelectorNetworkRequest,
} from 'src/http/smart-selector.network-requests';
import {ModelSampleSeriesModel} from '../../../../../../objects/models/model-sample-series.model';
import {useFeatureIsOn} from '@growthbook/growthbook-react';
import {FeatureFlag} from '../../../../../../constants/feature-flags';

export interface HomepageItemChangeEventData {
  value: number;
  item: any;
  type: ModelSampleSeriesModel;
}

interface OwnProps {
  onChange: (data: HomepageItemChangeEventData) => void;
  excludeMetricIds?: number[];
  excludeFunnelIds?: number[];
  emptyState?: any;
  onCreateMetric?: (categoryId?: number) => void;
  onCreateFunnel?: () => void;
}

type AllProps = OwnProps;

const generateMetricCategoryKey = (categoryId?: number | string) => {
  if (!exists(categoryId) || categoryId === 'null') {
    categoryId = 'general';
  }
  return `metric_category_${categoryId}`;
};

export const HomepageItemSelector: React.FC<AllProps> = (props: AllProps) => {
  const {onChange, excludeMetricIds, excludeFunnelIds, emptyState, onCreateFunnel, onCreateMetric} =
    props;
  const http = useContext(HttpClientContext);
  const showSelectFunnels = useFeatureIsOn(FeatureFlag.HOMEPAGE_FUNNEL as any);
  const {source: metricCategories, exec: getMetricCategories} = useRemoteSourceStated({
    networkRequest: getMetricCategoriesNetworkRequest,
    initialValue: [],
  });
  const {teamId} = useTeamFilter();
  const sources: SmartSelectorSource[] = useMemo(() => {
    const res = [
      ...metricCategories
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map(category => ({
          key: generateMetricCategoryKey(category.id),
          name: category.name,
          tab: 'KPI',
          onSelect: item =>
            onChange({
              value: item.id,
              item,
              type: ModelSampleSeriesModel.METRIC,
            }),
          onCreate: onCreateMetric ? () => onCreateMetric(category.id) : undefined,
          createLabel: `Create ${category.name} KPI`,
        })),
      {
        key: generateMetricCategoryKey(),
        name: 'General KPIs',
        tab: 'KPI',
        onSelect: item =>
          onChange({
            value: item.id,
            item,
            type: ModelSampleSeriesModel.METRIC,
          }),
        onCreate: onCreateMetric ? () => onCreateMetric() : undefined,
        createLabel: 'Create General KPI',
      },
    ];
    if (showSelectFunnels) {
      res.push({
        key: 'funnels',
        name: 'Funnels',
        tab: 'Funnel',
        onSelect: item =>
          onChange({
            value: item.id,
            item,
            type: ModelSampleSeriesModel.FUNNEL,
          }),
        onCreate: onCreateFunnel ? () => onCreateFunnel() : undefined,
        createLabel: 'Create Funnel',
      });
    }
    return res;
  }, [onChange, onCreateMetric, onCreateFunnel, metricCategories, showSelectFunnels]);
  const load = useCallback(
    async (keys: string[], parameters: SmartSelectorParameters): Promise<MultiLoadResponse> => {
      // @ts-ignore
      const res = {};
      const metrics = await http.exec(
        metricSmartSelectorNetworkRequest({
          page: parameters.page,
          q: parameters.query,
          limit: 1000,
          excludeId: excludeMetricIds,
          teamId,
        })
      );
      if (showSelectFunnels) {
        const funnels = await http.exec(
          funnelSmartSelectorNetworkRequest({
            page: parameters.page,
            q: parameters.query,
            excludeId: excludeFunnelIds,
            limit: 1000,
          })
        );
        res['funnels'] = {
          data: funnels.data,
          total: funnels.data.length,
          hasNext: false,
        };
      }
      const groups = groupBy(metrics.data, 'categoryId');
      Object.keys(groups).forEach(key => {
        res[generateMetricCategoryKey(key)] = {
          data: groups[key],
          total: groups[key].length,
          hasNext: false,
        };
      });
      return res;
    },
    [http, excludeFunnelIds, excludeMetricIds, teamId, showSelectFunnels]
  );

  useEffect(() => {
    getMetricCategories();
  }, [getMetricCategories]);

  return (
    <div className={classes.MetricSelector}>
      <SmartSelector
        className={classes.Selector}
        sources={sources}
        load={load}
        emptyState={emptyState}
        withPreview={false}
      />
    </div>
  );
};

HomepageItemSelector.defaultProps = {};

import {useEffect, useMemo} from 'react';
import classNames from 'classnames';
import classes from './custom-sql-query-builder.module.scss';
import {LabelWrapper, Select} from 'ui-components';
import {useCallback} from 'react';
import {
  TableType,
  TableEntity,
  GroupByTimeStrategy,
} from '../../../../../objects/models/table.model';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {useProductData} from 'src/core/hooks/use-product-data.hook';
import {TIME_UNIT_PERIODIC_OPTIONS} from '../../../../../constants/options';
import {AggregationStrategyInput} from '../../../form/components/aggregation-strategy-input.component';
import {isEmpty} from 'lodash';
import {SignalDataType} from '../../../../../objects/models/signal.model';
import {SqlInput} from '../../../form/components/sql-input/sql-input.component';
import {SqlValidationStatus, useSqlValidation} from '../../../form/hooks/use-sql-validation.hook';
import {ColumnInput} from './components/column-input.component';
import TableTypeInput, {
  InputVariant,
} from '../../../form/components/table-type-input/table-type-field.component';
import i18n from 'i18next';

interface SchemaKeysMapping {
  table_type: string;
  table_granularity: string;
  sql_definition: string;
  entity_id_column: string;
  value_column: string;
  event_name_column: string;
  timestamp_column: string;
  dimension_aggregation: string;
  time_aggregation: string;
  data_type: string;
  slug: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  table_type: 'table_type',
  table_granularity: 'table_granularity',
  sql_definition: 'sql_definition',
  entity_id_column: 'entity_id_column',
  value_column: 'value_column',
  event_name_column: 'event_name_column',
  timestamp_column: 'timestamp_column',
  dimension_aggregation: 'dimension_aggregation',
  time_aggregation: 'time_aggregation',
  data_type: 'data_type',
  slug: 'slug',
};

interface OwnProps {
  query: any;
  onChange?: (query: any) => void;
  errors?: any;
  entityContext: TableEntity;
  schemaKeysMapping?: SchemaKeysMapping;
  disabled?: boolean;
  className?: string;
}

type AllProps = OwnProps;

const getColumnOptions = (sqlValidationColumns: any, dataTypes = Object.values(SignalDataType)) => {
  if (isEmpty(sqlValidationColumns)) {
    return {options: []};
  }
  const options = Object.values(sqlValidationColumns)
    .filter(c => dataTypes.includes(c['signal_data_type']))
    .map(c => ({
      label: c['name'],
      value: c['name'],
    }));
  return {options};
};

const getTranslations = (
  tableType: TableType,
  entityName: string,
  validationStatus: SqlValidationStatus
) => {
  const tableTypeTransKey = (tableType || 'empty').toUpperCase();
  const isValidQuery = [SqlValidationStatus.VALID, SqlValidationStatus.UNKNOWN].includes(
    validationStatus
  );
  const unvalidatedQueryPlaceholder = i18n.t(
    TransKeys.QUERY_BUILDERS.SQL_PROPERTY.UNVALIDATED_QUERY_PLACEHOLDER
  );

  return {
    labels: {
      table_type: i18n.t(TransKeys.QUERY_BUILDERS.SQL_PROPERTY.TABLE_TYPE.LABEL),
      data_type: i18n.t(TransKeys.QUERY_BUILDERS.SQL_PROPERTY.DATA_TYPE.LABEL),
      table_granularity: i18n.t(TransKeys.QUERY_BUILDERS.SQL_PROPERTY.TABLE_GRANULARITY.LABEL),
      sql_definition: i18n.t(TransKeys.QUERY_BUILDERS.SQL_PROPERTY.SQL_DEFINITION.LABEL),
      entity_id_column: i18n.t(TransKeys.QUERY_BUILDERS.SQL_PROPERTY.ENTITY_ID_COLUMN.LABEL, {
        entityName,
      }),
      value_column: i18n.t(TransKeys.QUERY_BUILDERS.SQL_PROPERTY.VALUE_COLUMN.LABEL),
      event_name_column: i18n.t(TransKeys.QUERY_BUILDERS.SQL_PROPERTY.EVENT_NAME_COLUMN.LABEL),
      timestamp_column: i18n.t(
        TransKeys.QUERY_BUILDERS.SQL_PROPERTY.TIMESTAMP_COLUMN[`${tableTypeTransKey}_LABEL`]
      ),
      dimension_aggregation: i18n.t(TransKeys.QUERY_BUILDERS.SQL_PROPERTY.DIM_AGG_COLUMN.LABEL),
      time_aggregation: i18n.t(TransKeys.QUERY_BUILDERS.SQL_PROPERTY.TIME_AGG_COLUMN.LABEL),
    },
    placeholders: {
      data_type: i18n.t(TransKeys.QUERY_BUILDERS.SQL_PROPERTY.DATA_TYPE.PLACEHOLDER),
      sql_definition: i18n.t(
        TransKeys.QUERY_BUILDERS.SQL_PROPERTY.SQL_DEFINITION[`${tableTypeTransKey}_PLACEHOLDER`]
      ),
      entity_id_column: isValidQuery
        ? i18n.t(TransKeys.QUERY_BUILDERS.SQL_PROPERTY.ENTITY_ID_COLUMN.PLACEHOLDER, {
            entityName,
          })
        : unvalidatedQueryPlaceholder,
      value_column: isValidQuery
        ? i18n.t(TransKeys.QUERY_BUILDERS.SQL_PROPERTY.VALUE_COLUMN.PLACEHOLDER)
        : unvalidatedQueryPlaceholder,
      event_name_column: isValidQuery
        ? i18n.t(TransKeys.QUERY_BUILDERS.SQL_PROPERTY.EVENT_NAME_COLUMN.PLACEHOLDER)
        : unvalidatedQueryPlaceholder,
      timestamp_column: isValidQuery
        ? i18n.t(
            TransKeys.QUERY_BUILDERS.SQL_PROPERTY.TIMESTAMP_COLUMN[
              `${tableTypeTransKey}_PLACEHOLDER`
            ]
          )
        : unvalidatedQueryPlaceholder,
      dimension_aggregation: isValidQuery
        ? i18n.t(TransKeys.QUERY_BUILDERS.SQL_PROPERTY.DIM_AGG_COLUMN.PLACEHOLDER)
        : unvalidatedQueryPlaceholder,
      time_aggregation: isValidQuery
        ? i18n.t(TransKeys.QUERY_BUILDERS.SQL_PROPERTY.TIME_AGG_COLUMN.PLACEHOLDER)
        : unvalidatedQueryPlaceholder,
    },
    tooltips: {
      table_type: i18n.t(TransKeys.QUERY_BUILDERS.SQL_PROPERTY.TABLE_TYPE.TOOLTIP),
      dimension_aggregation: i18n.t(TransKeys.QUERY_BUILDERS.SQL_PROPERTY.DIM_AGG_COLUMN.TOOLTIP),
      time_aggregation: i18n.t(TransKeys.QUERY_BUILDERS.SQL_PROPERTY.TIME_AGG_COLUMN.TOOLTIP),
    },
  };
};

const DATA_TYPE_OPTIONS = {options: Object.values(SignalDataType).map(v => ({value: v, label: v}))};

export const BaseSqlBuilder: React.FC<AllProps> = (props: AllProps) => {
  const {query, onChange, errors, schemaKeysMapping, entityContext, disabled, className} = props;
  const {t} = useTranslation();
  const {getEntityName} = useProductData();
  const sqlValidation = useSqlValidation(query[schemaKeysMapping.sql_definition]);
  const entityName = getEntityName(entityContext);
  const tableType = query[schemaKeysMapping.table_type];
  const translations = useMemo(
    () => getTranslations(tableType, entityName, sqlValidation.status),
    [tableType, entityName, sqlValidation]
  );
  const allowFreeInput = sqlValidation.status === SqlValidationStatus.UNKNOWN;
  const disableInputs =
    disabled ||
    ![SqlValidationStatus.VALID, SqlValidationStatus.UNKNOWN].includes(sqlValidation.status);

  const allColumnOptions = useMemo(
    () => getColumnOptions(sqlValidation.columns),
    [sqlValidation.columns]
  );
  const dateColumnOptions = useMemo(
    () => getColumnOptions(sqlValidation.columns, [SignalDataType.TIMESTAMP]),
    [sqlValidation.columns]
  );
  const stringColumnOptions = useMemo(
    () => getColumnOptions(sqlValidation.columns, [SignalDataType.STRING]),
    [sqlValidation.columns]
  );

  const onTableTypeChange = useCallback(
    (tableType: TableType) => {
      onChange({
        [schemaKeysMapping.table_type]: tableType,
        [schemaKeysMapping.table_granularity]:
          tableType === TableType.STATE ? GroupByTimeStrategy.DAY : undefined,
      });
    },
    [onChange, schemaKeysMapping.table_type, schemaKeysMapping.table_granularity]
  );

  useEffect(() => {
    const selectedValueColumn = query[schemaKeysMapping.value_column];
    const selectedColumnDataType = sqlValidation.columns[selectedValueColumn]?.['signal_data_type'];
    if (selectedColumnDataType && selectedColumnDataType !== query[schemaKeysMapping.data_type]) {
      onChange({[schemaKeysMapping.data_type]: selectedColumnDataType});
    }
  }, [
    query,
    onChange,
    sqlValidation.columns,
    schemaKeysMapping.data_type,
    schemaKeysMapping.value_column,
  ]);

  return (
    <div className={classNames(classes.SqlPropertyQueryBuilder, className)}>
      <div className={classes.Section}>
        <div>
          <h3 className={classes.Header}>
            {t(TransKeys.QUERY_BUILDERS.SQL_PROPERTY.QUERY_DEFINITION.HEADER)}
          </h3>
          <div className={classes.Description}>
            {t(TransKeys.QUERY_BUILDERS.SQL_PROPERTY.QUERY_DEFINITION.DESCRIPTION)}
          </div>
        </div>
        <LabelWrapper
          label={translations.labels[schemaKeysMapping.table_type]}
          tooltipContent={translations.tooltips[schemaKeysMapping.table_type]}
        >
          <TableTypeInput
            variant={InputVariant.SELECT}
            value={tableType}
            onChange={onTableTypeChange}
            disabled={disabled}
            error={Boolean(errors?.[schemaKeysMapping.table_type])}
            placeholder={translations.placeholders[schemaKeysMapping.table_type]}
          />
        </LabelWrapper>
        {tableType === TableType.STATE && (
          <LabelWrapper label={translations.labels[schemaKeysMapping.table_granularity]}>
            <Select
              value={query[schemaKeysMapping.table_granularity]}
              onChange={v =>
                onChange({[schemaKeysMapping.table_granularity]: v as unknown as string})
              }
              options={TIME_UNIT_PERIODIC_OPTIONS}
              searchable={false}
              clearable={false}
              sortValues={false}
              disabled={disabled}
              error={Boolean(errors?.[schemaKeysMapping.table_granularity])}
              fullWidth
            />
          </LabelWrapper>
        )}
        <LabelWrapper label={translations.labels[schemaKeysMapping.sql_definition]}>
          <SqlInput
            value={query[schemaKeysMapping.sql_definition]}
            placeholder={translations.placeholders[schemaKeysMapping.sql_definition]}
            onChange={v => onChange({[schemaKeysMapping.sql_definition]: v})}
            disabled={disabled || !Boolean(tableType)}
            validation={sqlValidation}
            onBlur={sqlValidation.validate}
            minHeight="18rem"
          />
        </LabelWrapper>
      </div>
      {tableType && (
        <div className={classes.Section}>
          <div>
            <h3 className={classes.Header}>
              {t(TransKeys.QUERY_BUILDERS.SQL_PROPERTY.COLUMNS.HEADER)}
            </h3>
            <div className={classes.Description}>
              {t(TransKeys.QUERY_BUILDERS.SQL_PROPERTY.COLUMNS.DESCRIPTION)}
            </div>
          </div>
          <div className={classes.Fields}>
            <LabelWrapper label={translations.labels[schemaKeysMapping.entity_id_column]}>
              <ColumnInput
                allowFreeInput={allowFreeInput}
                placeholder={translations.placeholders[schemaKeysMapping.entity_id_column]}
                name={'entity_id_column'}
                onChange={v => onChange({[schemaKeysMapping.entity_id_column]: v})}
                value={query[schemaKeysMapping.entity_id_column]}
                error={Boolean(errors?.[schemaKeysMapping.entity_id_column])}
                options={allColumnOptions}
                disabled={disableInputs}
              />
            </LabelWrapper>
            <LabelWrapper label={translations.labels[schemaKeysMapping.value_column]}>
              <ColumnInput
                allowFreeInput={allowFreeInput}
                placeholder={translations.placeholders[schemaKeysMapping.value_column]}
                name={'value_column'}
                onChange={v => onChange({[schemaKeysMapping.value_column]: v})}
                error={Boolean(errors?.[schemaKeysMapping.value_column])}
                value={query[schemaKeysMapping.value_column]}
                options={allColumnOptions}
                disabled={disableInputs}
              />
            </LabelWrapper>
            {sqlValidation.status === SqlValidationStatus.UNKNOWN && (
              <LabelWrapper label={translations.labels[schemaKeysMapping.data_type]}>
                <Select
                  value={query[schemaKeysMapping.data_type]}
                  onChange={v => onChange({[schemaKeysMapping.data_type]: v as unknown as string})}
                  placeholder={translations.placeholders[schemaKeysMapping.data_type]}
                  options={DATA_TYPE_OPTIONS}
                  searchable={false}
                  clearable={false}
                  sortValues={false}
                  error={Boolean(errors?.[schemaKeysMapping.data_type])}
                  disabled={disableInputs}
                  fullWidth
                />
              </LabelWrapper>
            )}
            {[TableType.EVENTS, TableType.STATE].includes(tableType) && (
              <LabelWrapper label={translations.labels[schemaKeysMapping.timestamp_column]}>
                <ColumnInput
                  allowFreeInput={allowFreeInput}
                  placeholder={translations.placeholders[schemaKeysMapping.timestamp_column]}
                  name={'timestamp_column'}
                  onChange={v => onChange({[schemaKeysMapping.timestamp_column]: v})}
                  error={Boolean(errors?.[schemaKeysMapping.timestamp_column])}
                  value={query[schemaKeysMapping.timestamp_column]}
                  options={dateColumnOptions}
                  disabled={disableInputs}
                />
              </LabelWrapper>
            )}
            {tableType === TableType.EVENTS && (
              <LabelWrapper label={translations.labels[schemaKeysMapping.event_name_column]}>
                <ColumnInput
                  allowFreeInput={allowFreeInput}
                  placeholder={translations.placeholders[schemaKeysMapping.event_name_column]}
                  name={'event_name_column'}
                  onChange={v => onChange({[schemaKeysMapping.event_name_column]: v})}
                  error={Boolean(errors?.[schemaKeysMapping.event_name_column])}
                  value={query[schemaKeysMapping.event_name_column]}
                  options={stringColumnOptions}
                  disabled={disableInputs}
                />
              </LabelWrapper>
            )}
          </div>
        </div>
      )}
      {tableType && (
        <div className={classes.Section}>
          <div>
            <h3 className={classes.Header}>
              {t(TransKeys.QUERY_BUILDERS.SQL_PROPERTY.AGGREGATIONS.HEADER)}
            </h3>
            <div className={classes.Description}>
              {t(TransKeys.QUERY_BUILDERS.SQL_PROPERTY.AGGREGATIONS.DESCRIPTION)}
            </div>
          </div>
          <div className={classes.Fields}>
            <LabelWrapper
              label={translations.labels[schemaKeysMapping.dimension_aggregation]}
              tooltipContent={translations.tooltips[schemaKeysMapping.dimension_aggregation]}
              fullWidth
            >
              <AggregationStrategyInput
                value={query[schemaKeysMapping.dimension_aggregation]}
                placeholder={translations.placeholders[schemaKeysMapping.dimension_aggregation]}
                onChange={v => onChange({[schemaKeysMapping.dimension_aggregation]: v})}
                error={Boolean(errors?.[schemaKeysMapping.dimension_aggregation])}
                disabled={disableInputs}
                fullWidth
              />
            </LabelWrapper>
            {[TableType.EVENTS, TableType.STATE].includes(tableType) && (
              <LabelWrapper
                label={translations.labels[schemaKeysMapping.time_aggregation]}
                tooltipContent={translations.tooltips[schemaKeysMapping.time_aggregation]}
                fullWidth
              >
                <AggregationStrategyInput
                  value={query[schemaKeysMapping.time_aggregation]}
                  placeholder={translations.placeholders[schemaKeysMapping.time_aggregation]}
                  onChange={v => onChange({[schemaKeysMapping.time_aggregation]: v})}
                  error={Boolean(errors?.[schemaKeysMapping.time_aggregation])}
                  disabled={disableInputs}
                  fullWidth
                />
              </LabelWrapper>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

BaseSqlBuilder.defaultProps = {
  schemaKeysMapping: DEFAULT_SCHEMA_KEYS_MAPPING,
};

import * as React from 'react';
import classNames from 'classnames';
import classes from './item-list.module.scss';
import {CloseIcon, IconButton} from 'ui-components';
import {useMemo} from 'react';

interface OwnProps {
  items: any[];
  renderItem: (item: any, idx: number) => any;
  onRemoveItem: (idx: number) => void;
  onAddItem?: () => void;
  min1Item?: boolean;
  disabled?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const ItemList: React.FC<AllProps> = (props: AllProps) => {
  const {items: items_, renderItem, onAddItem, onRemoveItem, disabled, min1Item, className} = props;

  const items = useMemo(() => items_ || [], [items_]);
  const showRemove = useMemo(() => {
    if (!min1Item) {
      return true;
    }
    return items.length > 1 && !disabled;
  }, [items, min1Item, disabled]);

  return (
    <div className={classNames(classes.ItemList, className)}>
      {items.map((item, idx) => (
        <div key={idx} className={classNames(classes.Row, items.length <= 1 && classes.NoHover)}>
          {renderItem(item, idx)}
          <div className={classes.Actions}>
            {showRemove && (
              <IconButton
                icon={CloseIcon}
                onClick={_ => onRemoveItem(idx)}
                className={classes.Action}
                tooltipText={'Remove'}
              />
            )}
          </div>
        </div>
      ))}
      {!disabled && onAddItem && (
        <div className={classNames(classes.NoHover)}>
          <div className={classes.AddButton} onClick={_ => onAddItem()}>
            + Add another
          </div>
        </div>
      )}
    </div>
  );
};

ItemList.defaultProps = {
  min1Item: true,
};

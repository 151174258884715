import * as React from 'react';
import {Chip, Link, SmartSelectorPanel, TriangleExclamationLightIcon} from 'ui-components';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../../../constants/translation-keys';
import classes from '../../smart-selector.module.scss';
import classNames from 'classnames';
import {ValidationStatus} from '../../../../../../objects/models/signal.model';

interface OwnProps {
  label?: string;
  data: any;
  onInfo: () => void;
  className?: string;
}

type AllProps = OwnProps;

export const SignalPreview: React.FC<AllProps> = (props: AllProps) => {
  const {label, data, onInfo, className} = props;
  const {t} = useTranslation();

  const hasError = data.signalValidationStatus === ValidationStatus.ERROR;

  return (
    <SmartSelectorPanel
      className={className}
      label={label}
      title={data.name}
      description={data.description}
    >
      {hasError && (
        <div className={classes.ValidationError}>
          <TriangleExclamationLightIcon className={classes.Warn} />
          {t(TransKeys.GENERAL.ERRORS.MODEL_SIGNAL_VALIDATION_BROKEN, {model: label})}
        </div>
      )}
      {data.type && (
        <div className={classes.InfoRow}>
          <Chip label={data.type} />
        </div>
      )}
      <div className={classNames(classes.InfoRow, classes.toRight)}>
        {onInfo && <Link onClick={onInfo}>{t(TransKeys.GENERAL.LABELS.DEFINITION)}</Link>}
      </div>
    </SmartSelectorPanel>
  );
};

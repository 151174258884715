import Confetti from 'react-confetti';
import {useUserOnboarding} from '../../../../core/hooks/use-user-onboarding.hook';

interface OwnProps {}

type AllProps = OwnProps;

export const AppUserOnboardingConfetti: React.FC<AllProps> = () => {
  const {showConfetti} = useUserOnboarding();
  return <Confetti numberOfPieces={showConfetti ? 200 : 0} style={{zIndex: 1400}} />;
};

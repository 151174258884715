import * as React from 'react';
import classes from './experiment-final-info.module.scss';
import {Experiment} from '../../../../objects/models/experiment.model';
import TransKeys from '../../../../constants/translation-keys';
import {BlockHeader, Button, LearningIcon, Textarea, ActionIcon} from 'ui-components';
import {useTranslation} from 'react-i18next';
import {AnalysisResult} from '../../../../objects/models/analysis-result.model';
import {useEffect, useRef, useState} from 'react';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';

interface OwnProps {
  experiment: Experiment;
  onViewOpportunity?: () => void;
  onViewResults?: () => void;
  analysisResult?: AnalysisResult;
  onLearningChange: (text: string) => void;
  onNextDescriptionChange: (text: string) => void;
  isLoadingLearnings?: boolean;
  isLoadingNextDescription?: boolean;
}

type AllProps = OwnProps;

export const ExperimentFinalInfo: React.FC<AllProps> = (props: AllProps) => {
  const {
    experiment,
    onLearningChange,
    onNextDescriptionChange,
    isLoadingLearnings,
    isLoadingNextDescription,
  } = props;
  const {t} = useTranslation();
  const learningsRef = useRef<any>();
  // const winner = useMemo(
  //   () => (analysisResult ? values(analysisResult.outputs).find(i => i.isWinner) : null),
  //   [analysisResult]
  // );
  const [learnings, setLearnings] = useState(experiment?.learnings || '');
  const [nextDescription, setNextDescription] = useState(experiment?.nextDescription || '');
  const [focusedInput, setFocusedInput] = useState(null);

  useEffect(() => {
    setLearnings(experiment?.learnings || '');
    setNextDescription(experiment?.nextDescription || '');
  }, [experiment]);

  const onInputFocused = (inputKey: string) => {
    if (focusedInput === 'learnings') {
      setLearnings(experiment.learnings);
    }
    if (focusedInput === 'nextDescription') {
      setNextDescription(experiment.nextDescription);
    }
    setFocusedInput(inputKey);
  };

  const onSave = () => {
    if (focusedInput === 'learnings') {
      onLearningChange(learnings);
    }
    if (focusedInput === 'nextDescription') {
      onNextDescriptionChange(nextDescription);
    }
    setFocusedInput(null);
  };

  const renderTextAreaButtons = () => (
    <div className={classes.TextareaButtons}>
      <Button className={classes.Btn} variant={'outlined'} onClick={() => onInputFocused(null)}>
        Cancel
      </Button>
      <Button className={classes.Btn} onClick={onSave}>
        Save
      </Button>
    </div>
  );

  return (
    <div className={classes.ExperimentFinalInfo}>
      <div className={classes.Info}>
        <div className={classes.InfoRow}>
          <div className={classes.InfoBox}>
            {isLoadingLearnings && <GenericLoading />}
            <BlockHeader
              icon={LearningIcon}
              title={t(TransKeys.EXPERIMENT_FORM.INPUTS.LEARNINGS.LABEL)}
            />
            <Textarea
              fullWidth
              value={learnings}
              onChange={e => setLearnings(e.target.value)}
              inputRef={learningsRef}
              placeholder={t(TransKeys.EXPERIMENT_FORM.INPUTS.LEARNINGS.PLACEHOLDER)}
              onFocus={() => onInputFocused('learnings')}
              rows={6}
              className={classes.Textarea}
            />
            {focusedInput === 'learnings' && renderTextAreaButtons()}
          </div>
        </div>
      </div>
      <div className={classes.Info}>
        <div className={classes.InfoRow}>
          <div className={classes.InfoBox}>
            {isLoadingNextDescription && <GenericLoading />}
            <BlockHeader
              icon={ActionIcon}
              title={t(TransKeys.EXPERIMENT_FORM.INPUTS.WHATS_NEXT.LABEL)}
            />
            <Textarea
              fullWidth
              value={nextDescription}
              onChange={e => setNextDescription(e.target.value)}
              placeholder={t(TransKeys.EXPERIMENT_FORM.INPUTS.WHATS_NEXT.PLACEHOLDER)}
              className={classes.Textarea}
              onFocus={() => onInputFocused('nextDescription')}
              rows={6}
            />
            {focusedInput === 'nextDescription' && renderTextAreaButtons()}
          </div>
        </div>
      </div>
    </div>
  );
};

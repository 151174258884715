import appConfig from './app.config';
import {GrowthBook} from '@growthbook/growthbook-react';
import {UserRole} from '../objects/models/user.model';

const API_HOST = 'https://cdn.growthbook.io';

export const growthbook = new GrowthBook({
  apiHost: API_HOST,
  clientKey: appConfig.growthbookApiKey,
  enableDevMode: appConfig.growthbookDevMode,
  subscribeToChanges: true,
});

export const initGrowthbook = () => {
  growthbook.loadFeatures();
};

export function setGrowthbookProperties(user, product) {
  growthbook.setAttributes({
    id: user.id,
    userId: user.id,
    isInternalUser: user.isInternalUser,
    isAdmin: user.role === UserRole.ADMIN,
    productId: product.id,
    productSlug: product.slug,
  });
}

import * as React from 'react';
import {useCallback, useMemo} from 'react';
import classNames from 'classnames';
import classes from './event-group-query-builder.module.scss';
import {CloseIcon, IconButton, QueryBuilderFactory} from 'ui-components';
import {
  METADATA_KEY,
  PARAMETERS_METADATA_KEY,
} from '../../../../../constants/parameters-saved-keys';
import {get} from 'lodash';
import {del, set} from 'object-path-immutable';
import {TemplateItemQueryBuilder} from '../template-item-query-builder/template-item-query-builder.component';
import {SelectorModelType} from '../../smart-selector/advanced-smart-selector.component';

interface OwnProps {
  query: any;
  onChange?: (query: any) => void;
  errors?: any;
  disabled?: boolean;
  multiEvents?: boolean;
  filters?: any;
  className?: string;
}

type AllProps = OwnProps;

const creatCase = () => [null, null];

const createEventGroupInitialQuery = () => {
  const q = QueryBuilderFactory.createCases();
  q.else_value = {
    type: 'Literal',
    value: null,
    value_type: 'null',
  };
  q.cases = [creatCase()];
  q[PARAMETERS_METADATA_KEY] = {
    [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: 'EventGroupQueryBuilder',
  };
  return q;
};

const QUERY_INDEX = 0;

export const EventGroupQueryBuilder: React.FC<AllProps> = (props: AllProps) => {
  const {query, onChange, errors = {}, disabled, filters, className} = props;

  const cases = useMemo(() => query?.cases || [], [query]);
  const numberOfCases = useMemo(() => {
    if (!query) {
      return 0;
    }
    return cases.filter(c => c[0] !== null).length;
  }, [cases, query]);
  const onDeleteCase = useCallback(
    (caseIdx: number) => {
      const newQ = del(query, `cases.${caseIdx}`);
      onChange && onChange(newQ);
    },
    [query, onChange]
  );
  const onItemChanged = useCallback(
    (caseIdx: number, v) => {
      let newQ = query;
      if (!newQ) {
        newQ = createEventGroupInitialQuery();
      }
      const eventName = get(v, 'conditions.0.right.value');
      const literal = QueryBuilderFactory.createLiteral();
      literal.value = eventName;
      literal.value_type = 'string';
      newQ = set(newQ, `cases.${caseIdx}`, [v, literal]);
      onChange && onChange(newQ);
    },
    [query, onChange]
  );

  return (
    <div
      className={classNames(
        classes.EventGroupQueryBuilder,
        numberOfCases > 1 && classes.MultiSelection,
        className
      )}
    >
      <div className={classes.Builder}>
        {cases.map((c, cIdx) => (
          <div className={classes.TemplateItemQuery}>
            <TemplateItemQueryBuilder
              // prefix={cIdx > 0 ? 'or' : undefined}
              key={`case_${cIdx}`}
              query={get(c, QUERY_INDEX)}
              errors={get(errors, `cases.${cIdx}.${QUERY_INDEX}`)}
              onChange={v => onItemChanged(cIdx, v)}
              disabled={disabled}
              allowTypes={[SelectorModelType.EVENT]}
              eventFilters={filters}
            />
            {!disabled && (
              <div className={classes.RemoveConditionBtn}>
                <IconButton
                  tooltipText={'Remove event'}
                  icon={CloseIcon}
                  className={classes.ShowOnHoverBtn}
                  onClick={() => onDeleteCase(cIdx)}
                />
              </div>
            )}
          </div>
        ))}
        {!disabled && (
          <div className={classes.AddEvent}>
            <TemplateItemQueryBuilder
              // prefix={numberOfCases > 0 ? 'or' : undefined}
              query={null}
              errors={errors}
              onChange={v => onItemChanged(numberOfCases, v)}
              disabled={disabled}
              allowTypes={[SelectorModelType.EVENT]}
              eventFilters={filters}
            />
          </div>
        )}
      </div>
    </div>
  );
};

import {useCallback} from 'react';
import CodeMirror from '@uiw/react-codemirror';
import {materialLightInit} from '@uiw/codemirror-theme-material';
import {sql} from '@codemirror/lang-sql';
import {SqlValidationResponse, SqlValidationStatus} from '../../hooks/use-sql-validation.hook';
import classes from './sql-input.module.scss';
import {Tooltip} from '@material-ui/core';
import classNames from 'classnames';
import {CopyIcon, IconButton} from 'ui-components';

interface OwnProps {
  value: string;
  onChange?: (query: string) => void;
  onBlur?: () => void;
  placeholder?: string;
  disabled?: boolean;
  border?: boolean;
  copyButton?: boolean;
  validation?: SqlValidationResponse;
  minHeight?: string;
  error?: boolean;
  className?: string;
}

type AllProps = OwnProps;

const ValidationStatusConfig = {
  [SqlValidationStatus.EMPTY]: {
    label: 'Empty query',
    tooltip: '',
    className: classes.Empty,
  },
  [SqlValidationStatus.PENDING]: {
    label: 'Validate query',
    tooltip: '',
    className: classes.Pending,
  },
  [SqlValidationStatus.LOADING]: {
    label: 'Validating...',
    tooltip: '',
    className: classes.Loading,
  },
  [SqlValidationStatus.VALID]: {
    label: 'Valid query',
    tooltip: '',
    className: classes.Valid,
  },
  [SqlValidationStatus.INVALID]: {
    label: 'Invalid query',
    tooltip: 'Query is invalid. Modify the query and click to validate again',
    className: classes.Invalid,
  },
  [SqlValidationStatus.UNKNOWN]: {
    label: 'Unable to validate query',
    tooltip: 'Unable to validate query. This is ',
    className: classes.Unknown,
  },
};

export const SqlInput: React.FC<AllProps> = (props: AllProps) => {
  const {
    value,
    onChange,
    onBlur,
    placeholder,
    disabled,
    validation,
    copyButton,
    minHeight,
    border,
    error,
    className,
  } = props;
  const validationConfig = ValidationStatusConfig[validation?.status];

  const onCopy = useCallback(() => navigator.clipboard.writeText(value), [value]);

  return (
    <div className={classNames(classes.Container, error && classes.Error, className)}>
      <CodeMirror
        className={classNames(classes.Editor, border && classes.Border)}
        value={value}
        placeholder={placeholder}
        extensions={[sql()]}
        theme={materialLightInit()}
        onChange={onChange}
        editable={!disabled}
        readOnly={disabled}
        onBlur={onBlur}
        minHeight={minHeight}
      />
      {validation && (
        <Tooltip title={validationConfig.tooltip} placement="top">
          <div
            className={classNames(classes.Validation, validationConfig.className)}
            onClick={validation.validate}
          >
            <span className={classes.Label}>{validationConfig.label}</span>
          </div>
        </Tooltip>
      )}
      {copyButton && (
        <IconButton
          className={classes.CopyBtn}
          tooltipText={'Copy SQL statement to clipboard'}
          icon={CopyIcon}
          onClick={onCopy}
        />
      )}
    </div>
  );
};

SqlInput.defaultProps = {
  minHeight: '10rem',
  border: true,
};

import yup from '../../../../../config/yup.config';
import {PARAMETERS_METADATA_KEY} from '../../../../../constants/parameters-saved-keys';
import {queryElementValidatorFactory} from '../../../../../objects/dto/query-builder.dto';
import moment from 'moment/moment';
import {TIME_FORMATS} from '../../../../../constants/time-formats';
import {confoundersValadator, segmentsGroupsValidator} from '../shared-validators';
import {ParameterType} from 'ui-components';
import {get} from 'lodash';
import {
  AnalysisParametersTransformer,
  baseAnalysisParametersTransformer,
  confoundersAnalysisParametersTransformer,
  SPECIAL_TAGS_TRANSLATIONS,
} from '../shared-transformers';
import {closestMonday} from '../../../../../utils/general.utils';

export const analysis49ParametersValidator = yup.object().shape({
  parameters: yup
    .object()
    .shape({
      [PARAMETERS_METADATA_KEY]: yup.object().nullable(),
      funnel: yup.number().required(),
      filter_new_users: yup.boolean().nullable(),
      max_days_for_conversion: yup.number().nullable(),
      event_tag: yup.string().nullable(),
      ...segmentsGroupsValidator(),
      ...confoundersValadator,
      population_filter: queryElementValidatorFactory(false),
    })
    .required(),
  runParameters: yup
    .object()
    .shape({
      start_date: yup.string().required(),
      end_date: yup.string().required(),
      linearity: yup.boolean().required(),
    })
    .test({
      name: 'max_days_for_conversion_in_time_frame',
      test: function (obj: any, context) {
        const {start_date, end_date} = obj;
        const maxDaysForConversion = context.parent.parameters.max_days_for_conversion;
        const startDate = moment.utc(start_date, TIME_FORMATS.PARAMETER_DATE_FORMAT);
        const endDate = moment.utc(end_date, TIME_FORMATS.PARAMETER_DATE_FORMAT);
        if (maxDaysForConversion === null) {
          return true;
        }
        const adjustedStartDate = closestMonday(startDate);
        const adjustedEndDate = closestMonday(endDate, true);
        if (adjustedEndDate.diff(adjustedStartDate, 'd') <= maxDaysForConversion) {
          return this.createError({
            message: `Timeframe must have at least ${maxDaysForConversion} days (maximum days to complete) between the first and last Mondays in the period`,
            path: 'runParameters.end_date',
          });
        }

        return true;
      },
    }),
});

export const analysis49ParametersTransformer: AnalysisParametersTransformer = (
  values,
  schema,
  productEntities
) => {
  [values, schema] = baseAnalysisParametersTransformer(values, schema, productEntities);
  [values, schema] = confoundersAnalysisParametersTransformer(values, schema);
  const newValues = {...values};

  schema['included_segments_tag'] = {
    name: 'Segment Groups',
    type: ParameterType.__MERGED_SEGMENT_GROUPS,
    key: 'included_segments_tag',
  };
  newValues['included_segments_tag'] = [
    ...(
      (newValues['included_segments_tag'] || '')
        .split(',')
        .map(v => get(SPECIAL_TAGS_TRANSLATIONS, v, v)) || []
    ).filter(i => i),
    ...(newValues['included_segments_signals'] || []),
  ];
  delete schema['included_segments_signals'];

  return [newValues, schema];
};

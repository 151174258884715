import * as React from 'react';
import {useCallback, useContext, useMemo, useRef, useState} from 'react';
import classes from './kpi-node.module.scss';
import {Metric, MetricValueType} from '../../../../../../objects/models/metric.model';
import {
  ActionsDropdown,
  ArrowUpRightFromSquareRegularIcon,
  EditIcon,
  PlusLightIcon,
  TooltipIfOverflow,
  TrashIcon,
  TrendChip,
} from 'ui-components';
import {
  ModelSample,
  ModelSeriesGranularity,
} from '../../../../../../objects/models/model-sample-series.model';
import {AnomalyMode} from '../../../../../../objects/models/homepage.model';
import {getSampleWoWChange} from '../../../../../homepage/components/homepage-summary/homepage-summary.utils';
import {Position, useReactFlow} from '@xyflow/react';
import type {NodeProps} from '@xyflow/react/dist/esm/types';
import classNames from 'classnames';
import {formatMetricValue, generateIdForMetric} from '../../kpi-tree.utils';
import {KPINodePopover} from './kpi-node-popover/kpi-node-popover.component';
import {KPITreeContext} from '../../kpi-tree.context';
import {TreeMode} from '../../kpi-tree.consts';
import {TreeHandle} from '../tree-handle/tree-handle.component';
import {NavLink} from 'react-router-dom';
import {AppRoutes, TEAM_ID_PATH_PARAM} from '../../../../../../constants/app-routes';
import {sharedClasses} from '../../../../../shared';
import {Connection} from '@xyflow/system/dist/esm/types/general';
import {Popover} from '@material-ui/core';
import {MetricSelector} from '../../../metric-selector/metric-selector.component';
import {KPIPositionPlaceholder} from '../kpi-position-placeholder/kpi-position-placeholder.component';
import {NodePosition} from '../../../../../../objects/models/metric-tree.model';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';

export type KPINodeData = {
  id: number;
  name: string;
  signalId: number;
  valueType: MetricValueType;
  granularity: ModelSeriesGranularity;
  anomalyMode: AnomalyMode;
  anomalyThreshold: number;
  samples: ModelSample[];
  numberOfChildren: number;
  numberOfParents: number;
  isTemporary: boolean;
};

interface OwnProps extends NodeProps {
  data: KPINodeData;
}

const isValidConnection = (connection: Connection) => {
  return connection.target !== connection.source;
};

export const KPINode: React.FC<OwnProps> = React.memo((props: OwnProps) => {
  const {data} = props;
  const {id, name, valueType, granularity, samples = [], isTemporary} = data;
  const {
    onAddInputKPI: onAddInputKPIFromContext,
    onRemoveKPI,
    mode,
    datetimeAttribute,
    teamId,
  } = useContext(KPITreeContext);
  const {t} = useTranslation();
  const {getNode} = useReactFlow();
  const [showInputKPISelector, setShowInputKPISelector] = useState(false);
  const [inputMetricToAdd, setInputMetricToAdd] = useState<Metric>(null);
  const kpiNodeRef = useRef<HTMLDivElement>(null);

  const isPercentageValue = valueType === MetricValueType.PERCENTAGE;
  const nonPartialSamples = useMemo(() => samples.filter(s => !s.isPartial), [samples]);
  const lastSample = useMemo(() => {
    if (nonPartialSamples.length === 0) {
      return;
    }
    return nonPartialSamples[nonPartialSamples.length - 1];
  }, [nonPartialSamples]);
  const previousSample = useMemo(() => {
    if (nonPartialSamples.length < 2) {
      return;
    }
    if (granularity === ModelSeriesGranularity.DAY) {
      return nonPartialSamples[nonPartialSamples.length - 8];
    }
    return nonPartialSamples[nonPartialSamples.length - 2];
  }, [nonPartialSamples, granularity]);
  const trend = useMemo(
    () => getSampleWoWChange(lastSample, previousSample),
    [lastSample, previousSample]
  );
  const onSelectInputMetric = useCallback(
    (metric: Metric) => {
      const existing = getNode(generateIdForMetric(metric.id));
      // metric already exists in the tree
      if (existing) {
        onAddInputKPIFromContext(id, metric.id);
        setShowInputKPISelector(false);
        return;
      }
      setInputMetricToAdd(metric);
      setShowInputKPISelector(false);
    },
    [setInputMetricToAdd, setShowInputKPISelector, getNode, onAddInputKPIFromContext, id]
  );
  const onSelectInputMetricLocation = useCallback(
    (position: NodePosition) => {
      setInputMetricToAdd(null);
      onAddInputKPIFromContext(id, inputMetricToAdd.id, position);
    },
    [onAddInputKPIFromContext, inputMetricToAdd, id]
  );

  const hideBottomHandle = mode === TreeMode.VIEW && data.numberOfChildren === 0;
  const hideTopHandle = mode === TreeMode.VIEW && data.numberOfParents === 0;
  const nameWrapper = inner => {
    if (mode === TreeMode.EDIT) {
      return inner;
    }
    return (
      <NavLink
        className={classNames(sharedClasses.UnstyledLink, classes.Link)}
        to={AppRoutes.viewMetric(id, {
          [TEAM_ID_PATH_PARAM]: teamId,
        })}
      >
        {inner}
      </NavLink>
    );
  };

  const content = (
    <div className={classNames(classes.KPINode, isTemporary && classes.Temporary)} ref={kpiNodeRef}>
      <TreeHandle
        type="source"
        id="source"
        position={Position.Top}
        hide={hideTopHandle}
        isValidConnection={isValidConnection as any}
      />
      <TreeHandle
        type="target"
        id="target"
        position={Position.Bottom}
        hide={hideBottomHandle}
        isValidConnection={isValidConnection as any}
      />
      {mode === TreeMode.EDIT && (
        <>
          <ActionsDropdown
            dropdownClassName={classes.ActionsDropdown}
            popoverDirection={'left'}
            buttonComponent={({onClick, isOpen}) => (
              <EditIcon
                onClick={onClick}
                className={classNames(classes.Options, isOpen && classes.Open)}
              />
            )}
            iconDropdown
            actions={[
              {
                title: t(TransKeys.GENERAL.ACTIONS.REMOVE),
                onClick: () => onRemoveKPI(id),
                icon: TrashIcon,
              },
              {
                title: t(TransKeys.KPI_TREE.ACTIONS.ADD_INPUT_KPI),
                onClick: () => setShowInputKPISelector(true),
                icon: PlusLightIcon,
              },
            ]}
          />
          <Popover
            open={showInputKPISelector}
            classes={{
              paper: sharedClasses.BlankPaper,
            }}
            anchorEl={kpiNodeRef.current}
            onClose={() => setShowInputKPISelector(false)}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
          >
            <div className={classes.MetricSelectorWrapper}>
              <MetricSelector onChange={(metricId, metric) => onSelectInputMetric(metric)} />
            </div>
          </Popover>
        </>
      )}
      <div className={classes.Info}>
        <div className={classes.Value}>
          {lastSample?.value ? formatMetricValue(lastSample.value, isPercentageValue) : '-'}
        </div>
        {trend && (
          <TrendChip
            value={trend.value}
            isSignificant={trend.isSignificant}
            size={'small'}
            className={classes.Trend}
          />
        )}
      </div>
      {nameWrapper(
        <div className={classNames(classes.KPIName, mode === TreeMode.VIEW && classes.Clickable)}>
          <TooltipIfOverflow title={name}>
            <span className={classes.Name}>{name}</span>
          </TooltipIfOverflow>
          {mode === TreeMode.VIEW && <ArrowUpRightFromSquareRegularIcon className={classes.Icon} />}
        </div>
      )}
    </div>
  );

  if (mode === TreeMode.EDIT) {
    return (
      <>
        {content}
        {inputMetricToAdd && (
          <KPIPositionPlaceholder metric={inputMetricToAdd} onClick={onSelectInputMetricLocation} />
        )}
      </>
    );
  }

  return (
    <>
      <KPINodePopover
        metricId={id}
        name={name}
        isPercentageValue={isPercentageValue}
        granularity={granularity}
        lastSample={lastSample}
        previousSample={previousSample}
        datetimeAttribute={datetimeAttribute}
      >
        {content}
      </KPINodePopover>
    </>
  );
});

KPINode.defaultProps = {};

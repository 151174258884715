import * as React from 'react';
import {useProductData} from '../../../../../../core/hooks/use-product-data.hook';
import {useMemo} from 'react';
import {TeamSelector, UserGroupLightIcon} from 'ui-components';

interface OwnProps {
  teamId: number;
  onChangeTeam: (teamId: number | null) => void;
  className?: string;
}

type AllProps = OwnProps;

export const TeamTreeSelector: React.FC<AllProps> = (props: AllProps) => {
  const {teamId, onChangeTeam, className} = props;

  const {actualTeams: teams} = useProductData();
  const teamOptions = useMemo(
    () => [
      {
        id: null,
        name: 'All',
        icon: UserGroupLightIcon,
      },
      ...teams,
    ],
    [teams]
  );

  if (teams.length === 0) {
    return null;
  }

  return (
    <TeamSelector
      teams={teamOptions}
      value={teamId}
      onChange={onChangeTeam}
      clearable={false}
      className={className}
      withPrefix
    />
  );
};

import {useTranslation} from 'react-i18next';
import TransKeys from 'src/constants/translation-keys';
import {AnalysisResult} from 'src/objects/models/analysis-result.model';
import {BarChartIcon, EmptyState} from 'ui-components';
import {Funnel} from '../../../../../objects/models/funnel.model';
import {FunnelPageTab} from '../funnel-page.types';
import {FunnelPageDefinitionTab} from './funnel-page-definition-tab/funnel-page-definition-tab.component';
import {FunnelPageRCATab} from './funnel-page-rca-tab/funnel-page-rca-tab.component';

type FunnelPageTabsProps = {
  selectedTab: FunnelPageTab;
  funnel: Funnel;
  analysisResult: AnalysisResult;
  targetSignalId?: number;
  onSelectRCATargetFunnelStep?: (stepIndex: number) => void;
  onRCADocumentResolved?: () => void;
  isLoadingRCAResult?: boolean;
};

export const FunnelPageTabs = (props: FunnelPageTabsProps) => {
  const {
    selectedTab,
    funnel,
    onRCADocumentResolved,
    analysisResult,
    targetSignalId,
    isLoadingRCAResult,
    onSelectRCATargetFunnelStep,
  } = props;

  const {t} = useTranslation();

  if (selectedTab === FunnelPageTab.DEFINITION) {
    return <FunnelPageDefinitionTab funnel={funnel} />;
  }

  if (selectedTab === FunnelPageTab.RCA) {
    return (
      <FunnelPageRCATab
        onDocumentResolved={onRCADocumentResolved}
        isLoadingResult={isLoadingRCAResult}
        onSelectRCATargetFunnelStep={onSelectRCATargetFunnelStep}
        targetSignalId={targetSignalId}
        analysisResult={analysisResult}
      />
    );
  }

  if (selectedTab === FunnelPageTab.PERFORMANCE) {
    return (
      <EmptyState
        title={t(TransKeys.FUNNEL_PAGE.TABS.PERFORMANCE.EMPTY_STATE.TITLE)}
        image={BarChartIcon}
      />
    );
  }

  return null;
};

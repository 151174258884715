import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import classes from './kpi-edge.module.scss';
import {BaseEdge, Edge, EdgeLabelRenderer, EdgeProps, getBezierPath} from '@xyflow/react';
import {exists, number2k} from 'front-core';
import {
  CloseRegularIcon,
  colorAlphaTransformer,
  HoverHelperTip,
  HtmlTooltip,
  TooltipIfOverflow,
} from 'ui-components';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {MetricNode} from '../../../../../../objects/models/metric-tree.model';
import {KPITreeContext} from '../../kpi-tree.context';
import {TreeMode} from '../../kpi-tree.consts';
import {Tooltip} from '@material-ui/core';

export type KPIEdgeData = {
  correlation: number;
  isSignificantCorrelation: boolean;
  sourceMetric: MetricNode;
  targetMetric: MetricNode;
};

interface OwnProps extends EdgeProps<Edge<KPIEdgeData>> {}

const STYLE = {
  strokeWidth: 3,
};

const MAX_GOOD_COLOR = '#01C366';
const MAX_BAD_COLOR = '#F13E3E';
const INSIGNIFICANT_TEXT_COLOR = 'rgba(137, 142, 168, 1)';
export const GRAY_COLOR = 'rgba(197, 201, 219, 1)';

const correlationToAlpha = (correlation: number) => {
  return Math.min(1, Math.max(0.4, Math.abs(correlation)));
};

export const KPIEdge: React.FC<OwnProps> = React.memo((props: OwnProps) => {
  const {id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, data} = props;
  const {sourceMetric, targetMetric, correlation, isSignificantCorrelation} = data;
  const {t} = useTranslation();
  const {mode, onRemoveInputKPI} = useContext(KPITreeContext);
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const onDeleteEdge = useCallback(
    () => onRemoveInputKPI(targetMetric.id, sourceMetric.id),
    [onRemoveInputKPI, targetMetric, sourceMetric]
  );
  const bgColor = useMemo(() => {
    const alpha = correlationToAlpha(correlation);
    if (correlation === 0 || !isSignificantCorrelation) {
      return GRAY_COLOR;
    }
    if (correlation < 0) {
      return colorAlphaTransformer(MAX_BAD_COLOR, alpha, true);
    }
    if (correlation > 0) {
      return colorAlphaTransformer(MAX_GOOD_COLOR, alpha, true);
    }
  }, [correlation, isSignificantCorrelation]);
  const numberTextColor = useMemo(() => {
    if (isSignificantCorrelation) {
      return correlation > 0 ? MAX_GOOD_COLOR : MAX_BAD_COLOR;
    }
    return INSIGNIFICANT_TEXT_COLOR;
  }, [isSignificantCorrelation, correlation]);
  const style = useMemo(() => ({...STYLE, stroke: bgColor}), [bgColor]);
  const correlationText = useMemo(() => {
    if (exists(correlation)) {
      return `${correlation > 0 ? '+' : ''}${number2k(correlation)}`;
    }
    return null;
  }, [correlation]);
  const textColor = useMemo(() => {
    if (isSignificantCorrelation) {
      return Math.abs(correlation) > 0.6 ? '#fff' : '#000';
    }
    return '#000';
  }, [isSignificantCorrelation, correlation]);

  const renderTooltipTitle = () => {
    if (mode === TreeMode.EDIT) {
      return '';
    }
    return (
      <div className={classes.CorrelationHelper}>
        <div className={classes.Info}>
          <span className={classes.Label}>
            {t(TransKeys.KPI_TREE.CORRELATION_EDGE.CORRELATION_TOOLTIP.SOURCE_LABEL)}:
          </span>
          <TooltipIfOverflow title={sourceMetric.name}>
            <span className={classes.Value}>{sourceMetric.name}</span>
          </TooltipIfOverflow>
          <span className={classes.Label}>
            {t(TransKeys.KPI_TREE.CORRELATION_EDGE.CORRELATION_TOOLTIP.TARGET_LABEL)}:
          </span>
          <TooltipIfOverflow title={targetMetric.name}>
            <span className={classes.Value}>{targetMetric.name}</span>
          </TooltipIfOverflow>
          <span className={classes.Label}>
            {t(TransKeys.KPI_TREE.CORRELATION_EDGE.CORRELATION_TOOLTIP.CORRELATION_LABEL)}:
          </span>
          <span
            className={classes.CorrelationValue}
            style={{
              color: numberTextColor,
            }}
          >
            <span>{correlationText}</span>
            {isSignificantCorrelation === false && (
              <span className={classes.Insignificant}>(insignificant)</span>
            )}
            <HoverHelperTip
              small
              title={t(TransKeys.KPI_TREE.CORRELATION_EDGE.CORRELATION_HELPER_TEXT)}
            />
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      <BaseEdge id={id} {...props} path={edgePath} style={style} />
      {(exists(correlation) || mode === TreeMode.EDIT) && (
        <EdgeLabelRenderer>
          <HtmlTooltip title={renderTooltipTitle()} placement={'top'}>
            <div
              style={{
                position: 'absolute',
                transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                background: bgColor,
                color: textColor,
              }}
              className={classes.KPIEdge}
            >
              {correlationText}
              {mode === TreeMode.EDIT && (
                <Tooltip
                  title={t(TransKeys.KPI_TREE.ACTIONS.REMOVE_KPI_CONNECTION)}
                  placement={'top'}
                  interactive={false}
                >
                  <div
                    onClick={() => onDeleteEdge()}
                    className={classes.RemoveEdge}
                    style={{color: textColor}}
                  >
                    <CloseRegularIcon />
                  </div>
                </Tooltip>
              )}
            </div>
          </HtmlTooltip>
        </EdgeLabelRenderer>
      )}
    </>
  );
});

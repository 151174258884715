import * as React from 'react';
import classes from './app-mobile-screen-message.module.scss';
import {useTranslation} from 'react-i18next';
import {useDemoProduct} from '../../../../core/hooks/use-demo-product.hook';
import TransKeys from 'translations';

export const AppMobileScreenMessage: React.FC = () => {
  const {t} = useTranslation();
  const {isDemoProduct} = useDemoProduct();

  if (isDemoProduct) {
    return (
      <div className={classes.MobileViewDisabledMessageContainer}>
        <h1 className={classes.MobileViewDisabledMessage}>
          {t(TransKeys.WITH_DISABLE_MOBILE_VIEW_HOC.TITLE)}
        </h1>
        <p className={classes.MobileViewDisabledMessage}>
          {t(TransKeys.WITH_DISABLE_MOBILE_VIEW_HOC.SUB_TITLE)}
        </p>
        <iframe
          width="300"
          height="168"
          src="https://www.youtube.com/embed/Z5ExDWg93Hw?si=KVBXFw8F1KPGYTgQ"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
    );
  }

  return null;
};

import yup from '../../../../../config/yup.config';
import {PARAMETERS_METADATA_KEY} from '../../../../../constants/parameters-saved-keys';
import {capitalize, values} from 'lodash';
import {EventsDiscoveryMode} from './analysis-138-form.component';
import {TableEntity} from '../../../../../objects/models/table.model';
import {queryElementValidatorFactory} from '../../../../../objects/dto/query-builder.dto';
import moment from 'moment/moment';
import {TIME_FORMATS} from '../../../../../constants/time-formats';
import {exists} from 'front-core';
import {
  startEndDatesValidator,
  TIME_UNIT_OPTIONS,
  timeRangeValidator,
  treatmentValidator,
} from '../shared-validators';
import {ParameterType} from 'ui-components';
import {
  AnalysisParametersTransformer,
  baseAnalysisParametersTransformer,
} from '../shared-transformers';

export const analysis138ParametersValidator = yup.object().shape({
  parameters: yup
    .object()
    .shape({
      [PARAMETERS_METADATA_KEY]: yup.object().nullable(),
      analysis_mode: yup.string().oneOf(values(EventsDiscoveryMode)).required(),
      entity: yup.string().oneOf(values(TableEntity)).required(),
      goal: yup.object().when('analysis_mode', {
        is: v =>
          [
            EventsDiscoveryMode.BEFORE_GOAL,
            EventsDiscoveryMode.AFTER_GOAL,
            EventsDiscoveryMode.BETWEEN,
          ].indexOf(v) > -1,
        then: schema => queryElementValidatorFactory(true),
        otherwise: schema => schema.nullable(),
      }),
      reference_date: yup.object().when('analysis_mode', {
        is: EventsDiscoveryMode.BETWEEN,
        then: schema => queryElementValidatorFactory(true),
        otherwise: schema => schema.nullable(),
      }),
      churn_bound_n: yup
        .number()
        .min(1)
        .when('analysis_mode', {
          is: EventsDiscoveryMode.BEFORE_CHURN,
          then: schema => schema.required(),
          otherwise: schema => schema.nullable(),
        }),
      churn_bound_unit: yup
        .string()
        .oneOf(TIME_UNIT_OPTIONS)
        .when('analysis_mode', {
          is: EventsDiscoveryMode.BEFORE_CHURN,
          then: schema => schema.required(),
          otherwise: schema => schema.nullable(),
        }),
      is_order_matter: yup.boolean().nullable(),
      ...treatmentValidator('event_tag'),
      excluded_events: yup.array().of(yup.string()).nullable(),
      n_days_after_ref_date: yup.number().min(1).required(),
      population_filter: queryElementValidatorFactory(false),
    })
    .required(),
  runParameters: startEndDatesValidator
    .required()
    .test(
      timeRangeValidator(
        'n_days_after_ref_date',
        null, // default day
        'time frame can’t be shorter than maximum days to analyze parameter'
      )
    )
    .test(
      timeRangeValidator(
        'churn_bound_n',
        'churn_bound_unit',
        'time frame can’t be shorter than churn definition'
      )
    )
    .test({
      name: 'time_range_validation_of_churn_and_max_days',
      test: function (runParameters: any, context: any) {
        const {parameters} = context.parent;
        const startDate = moment(runParameters.start_date, TIME_FORMATS.PARAMETER_DATE_FORMAT);
        const endDate = moment(runParameters.end_date, TIME_FORMATS.PARAMETER_DATE_FORMAT);
        const totalRunDuration = Math.floor(endDate.diff(startDate, 'day'));
        const {churn_bound_n, churn_bound_unit, n_days_after_ref_date} = parameters;
        if (!exists(churn_bound_n) || !exists(churn_bound_unit)) {
          return true;
        }
        const churnDuration = Math.floor(moment.duration(churn_bound_n, churn_bound_unit).asDays());
        const totalDuration = churnDuration + n_days_after_ref_date;
        const hasError = totalRunDuration <= totalDuration;
        if (hasError) {
          return this.createError({
            message:
              'time frame can’t be shorter than churn definition + maximum days to analyze parameter',
            path: 'runParameters.start_date',
          });
        }
        return true;
      },
    }),
});

export const analysis138ParametersTransformer: AnalysisParametersTransformer = (
  values,
  schema,
  productEntities
) => {
  [values, schema] = baseAnalysisParametersTransformer(values, schema, productEntities);
  const newValues = {...values};

  if ('analysis_mode' in schema) {
    schema['analysis_mode'].name = 'Mode';
    schema['analysis_mode'].order = 1;
    newValues['analysis_mode'] = capitalize(newValues['analysis_mode'].replace('_', ' '));
  }
  if ('goal' in schema) {
    schema['goal'].name = 'Goal';
    schema['goal'].order = 2;
  }
  if ('reference_date' in schema) {
    schema['reference_date'].order = 3;
  }

  delete schema['event_tag'];
  delete schema['treatments_signals'];
  schema['event_tag'] = {
    name: 'Scope',
    type: ParameterType.__MERGED_TREATMENTS,
    key: 'event_tag',
    order: 4,
  };
  newValues['event_tag'] = [
    ...(newValues['event_tag'] || []),
    ...(newValues['treatments_signals'] || []),
  ];

  if ('is_order_matter' in schema) {
    schema['is_order_matter'].name = 'Event sequence';
    schema['is_order_matter'].order = 5;
  }
  if ('n_days_after_ref_date' in schema) {
    schema['n_days_after_ref_date'].name = 'Maximum days to analyze';
    schema['n_days_after_ref_date'].order = 5;
  }
  return [newValues, schema];
};

import {Epic} from 'redux-observable';
import {createRequestEpic} from 'front-core';
import {ActionKey} from '../../constants/action-key';
import HttpClient from '../../services/http-client.service';
import {FeaturesActionType} from './features.actions';
import {
  createFeatureNetworkRequest,
  deleteFeatureNetworkRequest,
  editFeatureNetworkRequest,
} from '../../http/features.network-requests';
import {modelCreated, modelDeleted, modelUpdated, notifyEvent} from '../core/core.actions';
import {ModelKey} from '../../constants/model-key';
import {featureToastCreator} from '../toasts.actions';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {SOURCE_META_KEY} from '../../constants/app-sources';
import {
  demoProductLimitedActionsFilter,
  errorExtractor,
  getDMPToastErrorMessage,
} from '../store.utils';
import {showToastMessage} from '../interface/interface.actions';
import {ToastType} from '../../objects/system/toast-type.enum';

export const createFeatureEpic: Epic = createRequestEpic(
  {
    types: [FeaturesActionType.CREATE_FEATURE],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.CREATE_FEATURE,
    request: createFeatureNetworkRequest,
    onSuccess: (feature, _, {metadata}) => [
      notifyEvent(AmplitudeEvent.FEATURE_CREATED, {
        id: feature.id,
        source: metadata?.[SOURCE_META_KEY],
      }),
      featureToastCreator('CREATE_SUCCESS'),
      modelCreated(feature, ModelKey.FEATURE),
    ],
    onError: err_ => {
      const err = errorExtractor(err_);
      const toastMessage = getDMPToastErrorMessage(err, 'feature');
      if (toastMessage) {
        return [
          showToastMessage(toastMessage, ToastType.ERROR),
          notifyEvent(AmplitudeEvent.CREATE_FEATURE_ERROR, err),
        ];
      }
      return [featureToastCreator('CREATE_ERROR')];
    },
  },
  HttpClient
);

export const updateFeatureEpic: Epic = createRequestEpic(
  {
    types: [FeaturesActionType.UPDATE_FEATURE],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.UPDATE_FEATURE,
    request: editFeatureNetworkRequest,
    onSuccess: feature => [
      notifyEvent(AmplitudeEvent.FEATURE_UPDATED, {id: feature.id}),
      featureToastCreator('UPDATE_SUCCESS'),
      modelUpdated(feature, ModelKey.FEATURE),
    ],
    onError: err_ => {
      const err = errorExtractor(err_);
      const toastMessage = getDMPToastErrorMessage(err, 'feature');
      if (toastMessage) {
        return [
          showToastMessage(toastMessage, ToastType.ERROR),
          notifyEvent(AmplitudeEvent.UPDATE_FEATURE_ERROR, err),
        ];
      }
      return [featureToastCreator('UPDATE_ERROR')];
    },
  },
  HttpClient
);

export const deleteFeatureEpic: Epic = createRequestEpic(
  {
    types: [FeaturesActionType.DELETE_FEATURE],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.DELETE_FEATURE,
    request: deleteFeatureNetworkRequest,
    onSuccess: (res, payload) => [
      notifyEvent(AmplitudeEvent.FEATURE_DELETED, {id: payload}),
      featureToastCreator('DELETE_SUCCESS'),
      modelDeleted(payload, ModelKey.FEATURE),
    ],
    onError: err => [featureToastCreator('DELETE_ERROR')],
  },
  HttpClient
);

import {InputType} from 'ui-components';
import TransKeys from './translation-keys';
import i18n from '../config/i18n.config';
import {searchMetricsNetworkRequest} from '../http/metrics.network-requests';
import {
  ANALYSIS_RESULT_STATUS_OPTIONS,
  ANNOTATION_TYPE_OPTIONS,
  EVENT_GROUP_TYPE_OPTIONS,
  METRIC_TYPE_OPTIONS,
  METRIC_VALUE_TYPE_OPTIONS,
  MILESTONE_TYPE_OPTIONS,
  TABLE_ENTITY_OPTIONS,
  TABLE_TYPE_OPTIONS,
} from './options';
import {searchAnalysisTypesNetworkRequest} from '../http/analysis-types.network-requests';
import {AnalysisTypeId} from './analysis-type-id';
import {OrderedProductEntities} from '../objects/models/product-data.model';
import {searchTablesNetworkRequest} from '../http/tables.network-requests';
import {capitalize} from 'lodash';
import {getFeatureTagGroupsNetworkRequest} from '../http/tags.network-requests';

export const queryFilter = () => ({
  type: InputType.FREE_TEXT,
  key: 'q',
  label: i18n.t(TransKeys.GENERAL.FILTERS.QUERY),
});

export const annotationType = () => ({
  type: InputType.ENUM,
  label: i18n.t(TransKeys.GENERAL.FILTERS.TYPE),
  key: 'type',
  options: {
    options: ANNOTATION_TYPE_OPTIONS(),
  },
});

export const experimentTypeAnalysisTypeFilter = () => ({
  type: InputType.API_CALL,
  key: 'analysisTypeId',
  label: i18n.t(TransKeys.GENERAL.FILTERS.EXPERIMENT_TYPE),
  options: {
    networkRequest: searchAnalysisTypesNetworkRequest,
    labelAttributePath: 'staticName',
    valueAttributePath: 'id',
    requestPayload: {
      id: [AnalysisTypeId.A_B_TEST, AnalysisTypeId.RELEASE_IMPACT],
      limit: 1000,
    },
  },
});

export const metricValueTypeFilter = () => ({
  type: InputType.ENUM,
  label: i18n.t(TransKeys.GENERAL.FILTERS.VALUE_TYPE),
  key: 'value_type',
  options: {
    options: METRIC_VALUE_TYPE_OPTIONS(),
  },
});

export const metricTypeFilter = () => ({
  type: InputType.ENUM,
  // todo translate
  label: 'KPI Type',
  key: 'type',
  options: {
    options: METRIC_TYPE_OPTIONS(),
  },
});

export const milestoneTypeFilter = () => ({
  type: InputType.ENUM,
  label: i18n.t(TransKeys.GENERAL.FILTERS.TYPE),
  key: 'type',
  options: {
    options: MILESTONE_TYPE_OPTIONS(),
  },
});

export const eventGroupTypeFilter = () => ({
  type: InputType.ENUM,
  label: i18n.t(TransKeys.GENERAL.FILTERS.TYPE),
  key: 'type',
  options: {
    options: EVENT_GROUP_TYPE_OPTIONS(),
  },
});

export const singleMetricFilter = () => ({
  type: InputType.API_CALL,
  key: 'metricId',
  label: i18n.t(TransKeys.GENERAL.FILTERS.METRIC),
  options: {
    networkRequest: searchMetricsNetworkRequest,
    labelAttributePath: 'name',
    valueAttributePath: 'id',
  },
});

export const singleAnalysisTypeFilter = () => ({
  type: InputType.API_CALL,
  key: 'analysisTypeId',
  label: i18n.t(TransKeys.GENERAL.FILTERS.ANALYSIS_TYPE),
  options: {
    networkRequest: searchAnalysisTypesNetworkRequest,
    labelAttributePath: 'staticName',
    valueAttributePath: 'id',
    requestPayload: {
      limit: 1000,
      isHiddenFromCatalog: false,
    },
  },
});

export const analysisResultsSingleAnalysisTypeFilter = () => ({
  type: InputType.API_CALL,
  key: 'analysisTypeId',
  label: i18n.t(TransKeys.GENERAL.FILTERS.ANALYSIS_TYPE),
  options: {
    networkRequest: searchAnalysisTypesNetworkRequest,
    labelAttributePath: 'staticName',
    valueAttributePath: 'id',
    requestPayload: {
      exclude_id: [AnalysisTypeId.A_B_TEST, AnalysisTypeId.RELEASE_IMPACT],
      isHiddenFromCatalog: false,
      limit: 1000,
    },
  },
});

export const featureGroupFilterFilter = () => ({
  type: InputType.API_CALL,
  key: 'tagGroupId',
  label: 'Group',
  options: {
    networkRequest: getFeatureTagGroupsNetworkRequest,
    labelAttributePath: 'name',
    valueAttributePath: 'id',
    requestPayload: {
      limit: 1000,
    },
  },
});

export const dateRangeFilter = () => ({
  key: 'dates',
  type: InputType.DATE_RANGE,
  label: 'Dates',
});

export const isExploredFilter = () => ({
  type: InputType.BOOLEAN,
  key: 'isExplored',
  label: i18n.t(TransKeys.GENERAL.FILTERS.IS_EXPLORED),
});

export const isSnoozedFilter = () => ({
  type: InputType.BOOLEAN,
  key: 'isSnoozed',
  label: i18n.t(TransKeys.GENERAL.FILTERS.IS_SNOOZED),
});

export const isProductLevelFilter = () => ({
  type: InputType.BOOLEAN,
  key: 'isProductLevel',
  label: i18n.t(TransKeys.GENERAL.FILTERS.IS_PRODUCT_LEVEL),
});

export const analysisResultStatusFilter = () => ({
  type: InputType.ENUM,
  key: 'statusForUser',
  label: i18n.t(TransKeys.GENERAL.FILTERS.STATUS),
  options: {
    options: ANALYSIS_RESULT_STATUS_OPTIONS(),
  },
});

export const tableEntityFilterGenerator = (productEntities: OrderedProductEntities) => ({
  type: InputType.ENUM,
  label: i18n.t(TransKeys.GENERAL.FILTERS.ENTITY),
  key: 'entity',
  options: {
    options: productEntities.map(p => ({
      label: p.name,
      value: p.key,
    })),
  },
});

export const tableTypeFilter = () => ({
  type: InputType.ENUM,
  label: i18n.t(TransKeys.GENERAL.FILTERS.TYPE),
  key: 'type',
  options: {
    options: TABLE_TYPE_OPTIONS(),
  },
});

export const tableEntityFilter = () => ({
  type: InputType.ENUM,
  label: i18n.t(TransKeys.GENERAL.FILTERS.ENTITY),
  key: 'entity',
  options: {
    options: TABLE_ENTITY_OPTIONS(),
  },
});

export const tableEventsFirstSeenFilter = () => ({
  type: InputType.DATE_RANGE,
  label: i18n.t(TransKeys.TABLE_EVENTS.FILTERS.FIRST_SEEN),
  key: 'firstSeen',
});

export const tableEventsLastSeenFilter = () => ({
  type: InputType.DATE_RANGE,
  label: i18n.t(TransKeys.TABLE_EVENTS.FILTERS.LAST_SEEN),
  key: 'lastSeen',
});

export const singleTableFilter = () => ({
  type: InputType.API_CALL,
  key: 'tableId',
  label: capitalize(i18n.t(TransKeys.MODELS.TABLE)),
  options: {
    networkRequest: searchTablesNetworkRequest,
    labelAttributePath: 'name',
    valueAttributePath: 'id',
    requestPayload: {
      limit: 1000,
    },
  },
});

import {AnalysisFolder} from '../models/analysis-folder.model';
import yup from '../../config/yup.config';

export type AnalysisFolderDTO = Partial<AnalysisFolder>;

export interface MoveAnalysisFolderDTO {
  id: number;
  analysisFolderId: number;
}

export const analysisFolderDTOValidator = yup.object().shape({
  id: yup.number().optional().nullable(),
  name: yup.string().required(),
  shortDescription: yup.string().nullable(),
  parentId: yup.number().required(),
  icon: yup.string().nullable(),
});

import {action} from 'front-core';

export enum ActionsListenerType {
  REGISTER_ACTION_LISTENER = '@@core/REGISTER_ACTION_LISTENER',
  REMOVE_ACTION_LISTENER = '@@core/REMOVE_ACTION_LISTENER',
}

export const registerActionListener = (actionKey: string | string[], listener: (action) => void) =>
  action(ActionsListenerType.REGISTER_ACTION_LISTENER, {actionKey, listener});

export const removeActionListener = (actionKey: string | string[], listener: (action) => void) =>
  action(ActionsListenerType.REMOVE_ACTION_LISTENER, {actionKey, listener});

import {LiteralValueType} from 'ui-components';
import {SignalDataType} from '../objects/models/signal.model';

export const LITERAL_TYPE_TO_SIGNAL_DATA_TYPE = {
  [LiteralValueType.STRING]: SignalDataType.STRING,
  [LiteralValueType.INTEGER]: SignalDataType.INTEGER,
  [LiteralValueType.FLOAT]: SignalDataType.DECIMAL,
  [LiteralValueType.BOOLEAN]: SignalDataType.BOOLEAN,
  [LiteralValueType.DATE]: SignalDataType.TIMESTAMP,
};

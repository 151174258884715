import {NRC} from './index';
import {RequestType, toSnakeCase, toCamelCase} from 'front-core';
import {FeedbackDTO} from '../objects/dto/feedback.dto';

export const createFeedbackNetworkRequest: NRC<FeedbackDTO, void> = (data: FeedbackDTO) => ({
  method: RequestType.POST,
  relativeUrl: `/app/feedbacks`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

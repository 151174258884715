import yup from '../../config/yup.config';
import {values, isArray} from 'lodash';
import {
  AggregationFunction,
  BinaryOperator,
  ConditionOperator,
  DateBinaryOperator,
  LiteralValueType,
  NumericFunctionType,
  Part,
  SqlElementType,
  WindowFunctionType,
} from 'ui-components';
import {exists, toArray} from 'front-core';
import {TestConfig} from 'yup/lib/util/createValidation';
import httpClientService from '../../services/http-client.service';
import {validateSignalIdsNetworkRequest} from '../../http/validations.network-requests';

export const validateSignalIds = (): TestConfig<any, any> => ({
  name: 'validate_signal_ids',
  test: async function (signalIds: any, context) {
    const data = toArray(signalIds);
    if (data.length === 0) {
      return true;
    }
    try {
      await httpClientService.exec(validateSignalIdsNetworkRequest(data));
      return true;
    } catch (e: any) {
      return this.createError({
        message: 'Invalid parameter',
        path: context.path,
      });
    }
  },
});

export const queryElementValidatorFactory = (required = true): any =>
  yup.lazy(obj => {
    if (exists(obj)) {
      return obj?.type ? queryBuilderValidators[obj.type] : _queryElementValidator(required);
    }
    return required ? yup.object().required() : yup.object().nullable();
  });

const _queryElementValidator = (required = true) => {
  if (required) {
    return yup
      .object()
      .shape({
        type: yup.string().required(),
      })
      .required();
  }
  return yup
    .object()
    .shape({
      type: yup.string(),
    })
    .nullable();
};

export const _queryElementValidatorByType = (type: SqlElementType) =>
  yup.lazy(obj => {
    return queryBuilderValidators[type];
  });

const parametersValidator = v => {
  switch (typeof v) {
    case 'string':
      return yup.string().required();
    case 'number':
      return yup.number().required();
    case 'boolean':
      return yup.boolean().required();
    case 'object':
      if (isArray(v)) {
        if (typeof v[0] === 'number') {
          return yup.array().of(yup.number()).min(1);
        }
        if (typeof v[0] === 'string') {
          return yup.array().of(yup.string()).min(1);
        }
        if (typeof v[0] === 'object') {
          return yup.array().of(queryElementValidatorFactory());
        }
      }
      if (v === null) {
        return yup.mixed().nullable();
      }
      return queryElementValidatorFactory();
    default:
      return yup.mixed().required();
  }
};

const literalValidationMap = {
  [LiteralValueType.STRING]: yup.string().required(),
  [LiteralValueType.INTEGER]: yup.number().required(),
  [LiteralValueType.FLOAT]: yup.number().required(),
  [LiteralValueType.DATE]: yup.string().required(),
  [LiteralValueType.BOOLEAN]: yup.boolean().required(),
  [LiteralValueType.STRING_LIST]: yup.array().of(yup.mixed().nullable()).min(1),
  [LiteralValueType.INTEGER_LIST]: yup.array().of(yup.number()).min(1),
  [LiteralValueType.FLOAT_LIST]: yup.array().of(yup.number()).min(1),
  [LiteralValueType.NULL]: yup.mixed().nullable(),
};

export const queryBuilderValidators = {
  [SqlElementType.CASES]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.CASES]).required(),
    cases: yup.array().of(yup.array().of(queryElementValidatorFactory()).min(2)).min(1),
    else_value: queryElementValidatorFactory(),
  }),
  [SqlElementType.CONDITION]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.CONDITION]).required(),
    left: queryElementValidatorFactory(),
    right: yup.mixed().when('op', {
      is: ConditionOperator.BETWEEN,
      then: yup.array().of(queryElementValidatorFactory()).min(2),
      otherwise: queryElementValidatorFactory(),
    }),
    op: yup.string().oneOf(values(ConditionOperator)).required(),
  }),
  [SqlElementType.TABLE_COLUMN]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.TABLE_COLUMN]).required(),
    table_id: yup.number().required(),
    column: yup.string().required(),
  }),
  [SqlElementType.IMPLICIT_COLUMN]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.IMPLICIT_COLUMN]).required(),
    column: yup.string().required(),
    dependencies: yup.array().of(queryElementValidatorFactory()).min(1),
  }),
  [SqlElementType.LITERAL]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.LITERAL]).required(),
    // @ts-ignore
    value: yup.mixed().literalValue(literalValidationMap),
    value_type: yup.string().oneOf(values(LiteralValueType)),
  }),
  [SqlElementType.OR_CONDITION]: yup.object().shape({
    type: yup
      .string()
      .oneOf([SqlElementType.AND_CONDITION, SqlElementType.OR_CONDITION])
      .required(),
    conditions: yup.array().of(queryElementValidatorFactory()).min(1),
  }),
  [SqlElementType.AND_CONDITION]: yup.object().shape({
    type: yup
      .string()
      .oneOf([SqlElementType.AND_CONDITION, SqlElementType.OR_CONDITION])
      .required(),
    conditions: yup.array().of(queryElementValidatorFactory()).min(1),
  }),
  [SqlElementType.SIGNAL_COLUMN]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.SIGNAL_COLUMN]).required(),
    signal_id: yup.number().required().test(validateSignalIds()),
  }),
  [SqlElementType.NULLIF]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.NULLIF]).required(),
    expression1: queryElementValidatorFactory(),
    expression2: queryElementValidatorFactory(),
  }),
  [SqlElementType.AGGREGATION]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.AGGREGATION]).required(),
    function: yup.string().oneOf(values(AggregationFunction)).required(),
    element: queryElementValidatorFactory(),
  }),
  [SqlElementType.NUMERIC_FUNCTION]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.NUMERIC_FUNCTION]).required(),
    function: yup.string().oneOf(values(NumericFunctionType)).required(),
    elements: yup.array().of(queryElementValidatorFactory()).min(1),
  }),
  [SqlElementType.TEMPLATE]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.TEMPLATE]).required(),
    template: yup.string().required(),
    parameters: yup
      .array()
      .transform(a => a.filter(i => i !== undefined))
      // @ts-ignore (eachOne is added in yup.utils)
      .eachOne(parametersValidator),
  }),
  [SqlElementType.BINARY_OPERATION]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.BINARY_OPERATION]).required(),
    left: queryElementValidatorFactory(),
    right: queryElementValidatorFactory(),
    op: yup.string().oneOf(values(BinaryOperator)).required(),
  }),
  [SqlElementType.COALESCE]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.COALESCE]).required(),
    elements: yup.array().of(queryElementValidatorFactory()).min(1),
  }),
  [SqlElementType.FILL_NULL]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.FILL_NULL]).required(),
    elements: yup.array().of(queryElementValidatorFactory()).min(1),
    default: _queryElementValidatorByType(SqlElementType.LITERAL),
  }),
  [SqlElementType.DATE_PART]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.DATE_PART]).required(),
    value: queryElementValidatorFactory(),
    part: yup.string().oneOf(values(Part)).required(),
  }),
  [SqlElementType.DATE_BINARY_OPERATION]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.DATE_BINARY_OPERATION]).required(),
    left: queryElementValidatorFactory(),
    right: queryElementValidatorFactory(),
    op: yup.string().oneOf(values(DateBinaryOperator)).required(),
    part: yup.string().oneOf(values(Part)).required(),
  }),
  [SqlElementType.WINDOW]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.WINDOW]).required(),
    function: yup.string().oneOf(values(WindowFunctionType)).required(),
    partition_by: queryElementValidatorFactory(),
    // @ts-ignore (byPosition is added in yup.utils)
    order_by: yup
      .array()
      .of(yup.mixed())
      .length(2)
      // @ts-ignore
      .byPosition([queryElementValidatorFactory(), yup.boolean()]),
    parameters: yup
      .array()
      .transform(a => a.filter(i => i !== undefined))
      // @ts-ignore (eachOne is added in yup.utils)
      .eachOne(parametersValidator),
  }),
  [SqlElementType.TRANSFORMER]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.TRANSFORMER]).required(),
    transformer: yup.string().required(),
    value: queryElementValidatorFactory(),
    parameters: yup
      .array()
      .transform(a => a.filter(i => i !== undefined))
      // @ts-ignore (eachOne is added in yup.utils)
      .eachOne(parametersValidator),
  }),
  [SqlElementType.RAW_QUERY]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.RAW_QUERY]).required(),
    value: yup.string().required(),
    dependencies: yup.array().of(queryElementValidatorFactory()).min(0),
  }),
};

import {MultiLoadResponse, ParameterType, useRemoteSourceStated} from 'ui-components';
import {multiLoaderNetworkRequest} from '../../http/multi-loader.network-requests';
import {useEffect} from 'react';
import {get, isArray, isEmpty, keys} from 'lodash';

const LOAD_SCHEMA_TYPES = [
  ParameterType.SIGNAL,
  ParameterType.METRIC,
  ParameterType.FUNNEL,
  ParameterType.FEATURE,
  ParameterType.CONTENT,
];

export const PARAMETER_TYPE_TO_MULTI_LOAD_KEY = {
  [ParameterType.SIGNAL]: 'signals',
  [ParameterType.METRIC]: 'metrics',
  [ParameterType.FUNNEL]: 'funnels',
  [ParameterType.FEATURE]: 'features',
  [ParameterType.CONTENT]: 'contents',
};

// utility function to add an element to an array of a given object.
// if the array does not exist (at the given key), it will create a new one containing the given value.
// note! this function mutates the object.
const addToArray = (obj: any, key: string, value: number | number[]) => {
  obj[key] = get(obj, key, []);
  if (isArray(value)) {
    obj[key].push(...value);
    return;
  }
  obj[key].push(value);
};

export const useParametersMultiLoad = (
  values,
  schema
): {
  isLoading: boolean;
  response: MultiLoadResponse;
} => {
  const {
    source: loadedResponse,
    exec: callMultiLoad,
    isLoading,
  } = useRemoteSourceStated({
    type: 'source',
    networkRequest: multiLoaderNetworkRequest,
    initialValue: {},
  });

  useEffect(() => {
    const query = {};
    for (const key of keys(values)) {
      const parameter = schema[key];
      if (!parameter) {
        continue;
      }
      if (!values[key]) {
        continue;
      }
      if (LOAD_SCHEMA_TYPES.indexOf(parameter.type) > -1) {
        values[key] &&
          addToArray(query, PARAMETER_TYPE_TO_MULTI_LOAD_KEY[parameter.type], values[key]);
      }
      // support for __MERGED_TREATMENTS
      if (
        [ParameterType.__MERGED_TREATMENTS, ParameterType.__MERGED_SEGMENT_GROUPS].indexOf(
          parameter.type
        ) > -1
      ) {
        addToArray(
          query,
          'signals',
          values[key].filter(i => typeof i === 'number')
        );
        addToArray(
          query,
          'tags',
          values[key].filter(i => typeof i === 'string')
        );
      }
    }
    !isEmpty(query) && callMultiLoad(query);
  }, [values, schema, callMultiLoad]);

  return {
    isLoading,
    response: loadedResponse,
  };
};

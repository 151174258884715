import {searchSignalsNetworkRequest} from '../http/signals.network-requests';
import {searchTablesNetworkRequest} from '../http/tables.network-requests';
import {SqlElementModel} from 'ui-components';
import {searchSegmentsNetworkRequest} from '../http/segments.network-requests';

export const queryBuilderModelConfig = {
  [SqlElementModel.TABLE]: {
    networkRequest: searchTablesNetworkRequest,
    labelAttributePath: 'name',
    valueAttributePath: 'id',
  },
  [SqlElementModel.SIGNAL]: {
    networkRequest: searchSignalsNetworkRequest,
    labelAttributePath: 'name',
    valueAttributePath: 'id',
  },
  [SqlElementModel.SEGMENT]: {
    networkRequest: searchSegmentsNetworkRequest,
    labelAttributePath: 'name',
    valueAttributePath: 'id',
  },
};

export enum ComputedTemplatesNames {
  FIRST_VALUE = 'first_value',
  LAST_VALUE = 'last_value',
  SIMPLE_FIRST_TIME = 'simple_first_time',
  SIMPLE_LAST_TIME = 'simple_last_time',
}

import {NRC} from './index';
import {UserReactionDTO} from '../objects/dto/user-reaction.dto';
import {RequestType, toSnakeCase} from 'front-core';
import {toCamelCase} from 'front-core';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';
import {InviteUsersDTO} from '../objects/dto/invite-users.dto';

export const searchUsersNetworkRequest: NRC<UserReactionDTO, void> = (
  value: any,
  payload: any
) => ({
  method: RequestType.GET,
  relativeUrl: `/app/users`,
  query: {q: value, ...payload},
  bodyTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data.data),
});

export const inviteUsersNetworkRequest: NRC<InviteUsersDTO, void> = (data: InviteUsersDTO) => ({
  method: RequestType.POST,
  relativeUrl: `/app/users/invites`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: toCamelCase,
});

import {useContext, useEffect} from 'react';
import {AnyAction} from 'redux';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {useDispatch} from 'react-redux';
import {PanelKey} from '../../../../constants/panels';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {DISABLED_ACTIONS_FOR_DEMO_PRODUCT} from '../../../../constants/demo-disabled-actions';
import {useDemoProduct} from '../../../../core/hooks/use-demo-product.hook';

interface OwnProps {}
type AllProps = OwnProps;

// This is an additional guard to prevent the user from performing certain actions when the product is in demo mode.
// Predominantly, we're using withDisableDemoProduct HOC to disable components throughout the app.
export const AppDemoProductActionsSubscriber: React.FC<AllProps> = (props: AllProps) => {
  const dispatch = useDispatch();
  const {isDemoProduct} = useDemoProduct();
  const {openSecondaryPanel} = useContext(PanelsContext);

  useEffect(() => {
    if (!isDemoProduct) {
      return;
    }
    const listener = (action: AnyAction) => {
      openSecondaryPanel(PanelKey.BOOK_A_DEMO_PANEL, {});
    };
    dispatch(registerActionListener(DISABLED_ACTIONS_FOR_DEMO_PRODUCT, listener));
    return () => {
      dispatch(removeActionListener(DISABLED_ACTIONS_FOR_DEMO_PRODUCT, listener));
    };
  }, [isDemoProduct, openSecondaryPanel, dispatch]);
  return null;
};

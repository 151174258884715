import {Epic} from 'redux-observable';
import {createRequestEpic} from 'front-core';
import {ActionKey} from '../../constants/action-key';
import HttpClient from '../../services/http-client.service';
import {modelUpdated} from '../core/core.actions';
import {ModelSamplesActionType} from './model-samples.actions';
import {resampleModelNetworkRequest} from '../../http/model-sample-series.network-requests';
import {MODEL_SAMPLE_SERIES_MODEL_TO_MODEL_KEY} from '../../objects/models/model-sample-series.model';
import {showToastMessage} from '../interface/interface.actions';
import {createToastMessage, demoProductLimitedActionsFilter} from '../store.utils';

export const resampleModelEpic: Epic = createRequestEpic(
  {
    types: [ModelSamplesActionType.RESAMPLE_MODEL],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.RESAMPLE_MODEL,
    request: resampleModelNetworkRequest,
    onSuccess: (_, payload) => {
      const modelKey = MODEL_SAMPLE_SERIES_MODEL_TO_MODEL_KEY[payload.modelType];
      const message = createToastMessage(modelKey, 'UPDATE_SUCCESS');
      return [
        showToastMessage(message.message, message.type),
        modelUpdated({id: payload.modelId}, modelKey),
      ];
    },
    onError: (err, payload) => {
      const modelKey = MODEL_SAMPLE_SERIES_MODEL_TO_MODEL_KEY[payload.modelType];
      const message = createToastMessage(modelKey, 'UPDATE_SUCCESS');
      return [showToastMessage(message.message, message.type)];
    },
  },
  HttpClient
);

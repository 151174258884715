import {useCallback, useEffect, useState} from 'react';
import classes from './questionnaire.module.scss';
import {ImagesResource} from '../../../../assets/images';
import {
  UserRoleStep,
  userRoleStepValidations,
} from './fragments/user-role-step/user-role-step.component';
import {
  UserInterestStep,
  userInterestStepValidations,
} from './fragments/user-interest-step/user-interest-step.component';
import {
  UserKPIStep,
  userKPIStepValidations,
} from './fragments/user-kpi-step/user-kpi-step.component';
import {ArrowRightIcon, IndexDisplay} from 'ui-components';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import classNames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {updateUserOnboardingQuestionnaire} from '../../../../store/user-onboarding/user-onboarding.actions';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {useUserOnboarding} from '../../../../core/hooks/use-user-onboarding.hook';
import {
  loadHubspotConversation,
  removeHubSpotConversation,
} from '../../../../config/hubspot.config';
import {
  UserSpecificKpisStep,
  userSpecificKpisStepValidations,
} from './fragments/user-specific-kpis-step/user-specific-kpis-step.component';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors';
import {ActionKey} from '../../../../constants/action-key';
import {getProductData} from '../../../../store/core/core.actions';

interface OwnProps {}

type AllProps = OwnProps;

const steps = [
  {component: UserRoleStep, validations: userRoleStepValidations, key: 'userRoleStep'},
  {component: UserInterestStep, validations: userInterestStepValidations, key: 'userInterestStep'},
  {component: UserKPIStep, validations: userKPIStepValidations, key: 'userKPIStep'},
  {
    component: UserSpecificKpisStep,
    validations: userSpecificKpisStepValidations,
    key: 'userSpecificKpisStep',
  },
];

export const Questionnaire: React.FC<AllProps> = (props: AllProps) => {
  const {t} = useTranslation();
  const {setResetting} = useUserOnboarding();
  const [stepIndex, setStepIndex] = useState(0);
  const [questionnaireData, setQuestionnaireData] = useState<any>({});
  const dispatch = useDispatch();
  const notify = useAmplitude();
  const isSubmitting = useSelector(state =>
    getReducedLoadingStateSelector(ActionKey.UPDATE_USER_ONBOARDING_QUESTIONNAIRE)(state)
  );
  // actual index starts from 0
  const StepFormComponent = steps[stepIndex]?.component;

  const onStepCompleted = useCallback(
    (formData: any) => {
      const newData = {...questionnaireData, ...formData};
      if (stepIndex < steps.length - 1) {
        setStepIndex(i => i + 1);
        setQuestionnaireData(newData);
        notify(AmplitudeEvent.USER_COMPLETED_QUESTIONNAIRE_STEP, {step_index: stepIndex});
        return;
      }
      notify(AmplitudeEvent.USER_COMPLETED_QUESTIONNAIRE);
      const hooks = {
        onSuccess: () => {
          setResetting(false);
        },
      };
      dispatch(updateUserOnboardingQuestionnaire(newData, hooks));
    },
    [questionnaireData, stepIndex, setQuestionnaireData, dispatch, notify, setResetting]
  );

  useEffect(() => {
    notify(AmplitudeEvent.USER_STARTED_QUESTIONNAIRE);
    removeHubSpotConversation();
    return () => {
      loadHubspotConversation();
    };
  }, [notify]);

  // If the user switches tabs and comes back, we want to recheck if they've already completed the questionnaire
  const onVisibilityChange = useCallback(() => {
    if (document.visibilityState === 'visible') {
      dispatch(getProductData());
    }
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('visibilitychange', onVisibilityChange);
    return () => {
      window.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [onVisibilityChange]);

  return (
    <>
      {isSubmitting && <GenericLoading />}
      <div className={classes.Questionnaire}>
        <div className={classes.Header}>
          <img src={ImagesResource.app.logoGradient} alt={'Loops'} className={classes.Logo} />
        </div>
        <div className={classes.Main}>
          <div className={classes.Container}>
            <div className={classes.Stepper}>
              <div
                onClick={() => setStepIndex(i => i - 1)}
                className={classNames(classes.Back, stepIndex === 0 && classes.Hide)}
              >
                <ArrowRightIcon className={classes.Arrow} /> {t(TransKeys.GENERAL.ACTIONS.BACK)}
              </div>
              <IndexDisplay current={stepIndex} total={steps.length} />
            </div>
            <div className={classes.FormComponent}>
              <StepFormComponent
                key={stepIndex}
                data={questionnaireData}
                onChange={onStepCompleted}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import * as React from 'react';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import classes from '../app-announcement-bar.module.scss';

interface OwnProps {}

type AllProps = OwnProps;
export const InactiveSourceAnnouncement: React.FC<AllProps> = (props: AllProps) => {
  const {t} = useTranslation();
  return (
    <div className={classes.AnnouncementBar}>
      <span>{t(TransKeys.APP_ANNOUNCEMENT.INACTIVE_SOURCE)}</span>
    </div>
  );
};

import * as React from 'react';
import {useCallback, useContext} from 'react';
import {
  CreationInteractionMessage,
  InteractionMessage,
  InteractionPostHandler,
  InteractionProvider,
  InteractionType,
  ModelType,
  ReferenceInteractionMessage,
  FollowUpInteractionMessage,
  CreationInteractionPayload,
} from 'ui-components';
import {FOLLOW_UP_TYPE_TO_PANEL_KEY, PanelKey} from '../../../../constants/panels';
import {
  ANALYSIS_RESULT_ID_PATH_PARAM,
  AppRoutes,
  CONTENT_ID_PATH_PARAM,
  EVENT_GROUP_ID_PATH_PARAM,
  EXPERIMENT_ID_PATH_PARAM,
  FEATURE_ID_PATH_PARAM,
  FUNNEL_ID_PATH_PARAM,
  METRIC_ID_PATH_PARAM,
  MILESTONE_ID_PATH_PARAM,
  SEGMENT_ID_PATH_PARAM,
  SIGNAL_ID_PATH_PARAM,
} from '../../../../constants/app-routes';
import {get, isArray} from 'lodash';
import {HttpClientContext} from 'front-core';
import {getSignalInfoNetworkRequest} from '../../../../http/signals.network-requests';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {Beacon} from '../../../../config/helpscout.config';
import {useHistory} from 'react-router';
import {getPhenomenaByDocIdNetworkRequest} from '../../../../http/phenomenas.network-requests';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {useDispatch} from 'react-redux';
import {createPowerUsersSegmentConfirmed} from '../../../../store/segments/segments.actions';

interface OwnProps {
  children: any;
}

type AllProps = OwnProps;

export const RootModelInteractionProvider: React.FC<AllProps> = (props: AllProps) => {
  const {children} = props;
  const dispatch = useDispatch();
  const notify = useAmplitude();
  const http = useContext(HttpClientContext);
  const {openSecondaryPanel} = useContext(PanelsContext);
  const history = useHistory();

  const getSignalInfo = useCallback(
    async (signalId: number) => {
      return (await http.exec(getSignalInfoNetworkRequest(signalId))) as any;
    },
    [http]
  );
  const getPhenomenaByDocId = useCallback(
    async (docId: string) => {
      return (await http.exec(getPhenomenaByDocIdNetworkRequest(docId))) as any;
    },
    [http]
  );
  const onFollowupInteraction = useCallback(
    (interaction: FollowUpInteractionMessage, context) => {
      notify(AmplitudeEvent.FOLLOW_UP_INTERACTION_CLICKED, interaction);
      const {type, parameters} = interaction.payload;
      openSecondaryPanel(FOLLOW_UP_TYPE_TO_PANEL_KEY[type], {
        parameters,
        [ANALYSIS_RESULT_ID_PATH_PARAM]: context.analysisResultId,
      });
    },
    [notify, openSecondaryPanel]
  );

  const onCreatePowerUsersSegmentInteraction = useCallback(
    (payload: CreationInteractionPayload) => {
      dispatch(createPowerUsersSegmentConfirmed(payload));
    },
    [dispatch]
  );

  const onRefInteraction = useCallback(
    async (interaction: ReferenceInteractionMessage) => {
      const payload = interaction.payload;
      let modelId = isArray(payload.modelId) ? payload.modelId[0] : payload.modelId;
      let modelType = payload.modelType;
      let signalId = undefined;

      if (payload.modelType === ModelType.SIGNAL) {
        const signalInfo = await getSignalInfo(modelId);
        signalId = Number(modelId);
        modelType = signalInfo.relatedTo.type.toUpperCase();
        modelId = signalInfo.relatedTo.id;
      }
      if (payload.modelType === ModelType.PHENOMENA_DOC) {
        const phenomena = await getPhenomenaByDocId(modelId);
        modelType = ModelType.PHENOMENA;
        modelId = phenomena.id;
      }

      switch (modelType) {
        case ModelType.SEGMENT:
          return openSecondaryPanel(PanelKey.VIEW_SEGMENT_PANEL, {
            [SEGMENT_ID_PATH_PARAM]: modelId,
          });
        case ModelType.FEATURE:
          return openSecondaryPanel(PanelKey.VIEW_FEATURE_PANEL, {
            [FEATURE_ID_PATH_PARAM]: modelId,
            [SIGNAL_ID_PATH_PARAM]: signalId,
          });
        case ModelType.FUNNEL:
          return openSecondaryPanel(PanelKey.VIEW_FUNNEL_PANEL, {
            [FUNNEL_ID_PATH_PARAM]: modelId,
            [SIGNAL_ID_PATH_PARAM]: signalId,
          });
        case ModelType.CONTENT:
          return openSecondaryPanel(PanelKey.VIEW_CONTENT_PANEL, {
            [CONTENT_ID_PATH_PARAM]: modelId,
            [SIGNAL_ID_PATH_PARAM]: signalId,
          });
        case ModelType.METRIC:
          return openSecondaryPanel(PanelKey.VIEW_METRIC_PANEL, {
            [METRIC_ID_PATH_PARAM]: modelId,
          });
        case ModelType.MILESTONE:
          return openSecondaryPanel(PanelKey.VIEW_MILESTONE_PANEL, {
            [MILESTONE_ID_PATH_PARAM]: modelId,
          });
        case ModelType.EVENT_GROUP:
          return openSecondaryPanel(PanelKey.VIEW_EVENT_GROUP_PANEL, {
            [EVENT_GROUP_ID_PATH_PARAM]: modelId,
          });
        case ModelType.EXPERIMENT:
          return openSecondaryPanel(PanelKey.VIEW_EXPERIMENT_PANEL, {
            [EXPERIMENT_ID_PATH_PARAM]: modelId,
          });
        case ModelType.SIGNAL:
          return openSecondaryPanel(PanelKey.SIGNAL_DEFINITION_PANEL, {
            [SIGNAL_ID_PATH_PARAM]: modelId,
          });
        case ModelType.PHENOMENA:
          return history.push(AppRoutes.viewPhenomena(modelId));
        case ModelType.ANALYSIS_RESULT:
          return history.push(
            AppRoutes.viewAnalysis(get(payload, 'parameters.analysisId'), {
              [ANALYSIS_RESULT_ID_PATH_PARAM]: modelId,
            })
          );
      }
    },
    [openSecondaryPanel, getSignalInfo, getPhenomenaByDocId, history]
  );

  const onCreationInteraction = useCallback(
    (interaction: CreationInteractionMessage) => {
      const payload = interaction.payload;
      switch (payload.modelType) {
        case ModelType.SEGMENT:
          return openSecondaryPanel(PanelKey.SEGMENT_FORM_PANEL, payload.parameters);
        case ModelType.FUNNEL:
          return openSecondaryPanel(PanelKey.FUNNEL_FORM_PANEL, payload.parameters);
        case ModelType.FEATURE:
          return openSecondaryPanel(PanelKey.FEATURE_FORM_PANEL, payload.parameters);
        case ModelType.EXPERIMENT:
          return openSecondaryPanel(PanelKey.EXPERIMENT_FORM_PANEL, payload.parameters);
        case ModelType.METRIC:
          return openSecondaryPanel(PanelKey.METRIC_FORM_PANEL, payload.parameters);
        case ModelType.MILESTONE:
          return openSecondaryPanel(PanelKey.MILESTONE_FORM_PANEL, payload.parameters);
        case ModelType.POWER_USERS_SEGMENT:
          return onCreatePowerUsersSegmentInteraction(payload.parameters);
        case ModelType.SEGMENT_PREFERENCES:
          return openSecondaryPanel(PanelKey.FAVORITES_SEGMENTS_PANEL, payload.parameters);
      }
    },
    [openSecondaryPanel, onCreatePowerUsersSegmentInteraction]
  );

  const onPostMessage: InteractionPostHandler = useCallback(
    (interaction: InteractionMessage, context: any) => {
      switch (interaction.type) {
        case InteractionType.REFERENCE:
          return onRefInteraction(interaction as any);
        case InteractionType.CREATION:
          return onCreationInteraction(interaction as any);
        case InteractionType.VIEW_ARTICLE:
          return Beacon('article', interaction.payload.articleId, {type: 'sidebar'});
        case InteractionType.FOLLOW_UP:
          return onFollowupInteraction(interaction as any, context);
      }
    },
    [onRefInteraction, onCreationInteraction, onFollowupInteraction]
  );

  return <InteractionProvider handler={onPostMessage}>{children}</InteractionProvider>;
};

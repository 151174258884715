import React, {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {groupBy} from 'lodash';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import {Button} from 'ui-components';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import classes from './user-notifications.module.scss';
import {
  removeSlackFromProductConfirmed,
  updateUserNotificationsConfig,
} from '../../../../store/notifications-config/notifications-config.actions';
import {getSelected, upsertSelected} from '../../../../store/selected/selected.actions';
import {CoreActionsType} from '../../../../store/core/core.actions';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors';
import {updateUserSettings} from '../../../../store/settings/user-settings.actions';
import TransKeys from '../../../../constants/translation-keys';
import {ModelKey} from '../../../../constants/model-key';
import {ActionKey} from '../../../../constants/action-key';
import {UserSettingsDTO} from '../../../../objects/dto/user-settings.dto';
import {NotificationsConfigDTO} from '../../../../objects/dto/notifications-config.dto';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {getUserSettingsNetworkRequest} from '../../../../http/user-settings.network-requests';
import {getUserNotificationsConfigNetworkRequest} from '../../../../http/user-notification-config.network-request';
import {StandardCheckBox} from '../../../shared/components/general/standard-check-box/standard-check-box.component';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {COMPANIES_LOGOS} from '../../../../constants/ui';
import {SlackBtn} from '../../../analyses/panels/share-resource-form-panel/components/slack-btn/add-slack-btn.component';

const USER_SETTINGS_SELECTED_KEY = 'USER_SETTINGS_SELECTED_KEY';
const USER_NOTIFICATIONS_SELECTED_KEY = 'USER_NOTIFICATIONS_SELECTED_KEY';

interface OwnProps {
  className?: string;
  settings: UserSettingsDTO;
  notificationsConfig: NotificationsConfigDTO;
}

type AllProps = OwnProps;

const UserNotificationsComponent: React.FC<AllProps> = props => {
  const {settings, notificationsConfig} = props;

  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {installedSlackToWorkspace} = useProductData();
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(
      ActionKey.UPDATE_USER_NOTIFICATIONS_CONFIG,
      ActionKey.UPDATE_USER_SETTINGS
    )(state)
  );

  const onRemoveSlack = useCallback(() => {
    dispatch(removeSlackFromProductConfirmed());
  }, [dispatch]);
  const resubscribeToEmails = useCallback(
    () => dispatch(updateUserSettings({receiveEmailNotifications: true})),
    [dispatch]
  );
  const onNotificationChange = useCallback(
    (name: string, value: boolean) => dispatch(updateUserNotificationsConfig({name, value})),
    [dispatch]
  );
  const getNotificationSettingDescription = useCallback(
    (section: string, notificationName: string) => {
      return (
        t(
          TransKeys.USER_SETTINGS.SETTINGS[`${section.toUpperCase()}_NOTIFICATIONS`]?.[
            notificationName.toUpperCase()
          ]?.DESCRIPTION
        ) || ''
      );
    },
    [t]
  );

  const notificationsBySection = useMemo(
    () =>
      Object.entries(notificationsConfig).reduce((acc, [name, value]) => {
        const [section, notificationName, channel] = name.split('.');
        const notificationConfig = {
          notificationKey: name,
          notificationName,
          [channel]: value,
        };
        return {
          ...acc,
          [section]: section in acc ? [...acc[section], notificationConfig] : [notificationConfig],
        };
      }, {}),
    [notificationsConfig]
  );
  const notificationsData = useMemo(
    () =>
      Object.entries(notificationsBySection).map(([section, sectionNotifications]) => {
        const groupedByNameNotifications = Object.entries(
          groupBy(sectionNotifications as any, 'notificationName')
        );
        return {
          title: t(TransKeys.GENERAL.LABELS[section.toUpperCase()]),
          notifications: groupedByNameNotifications.reduce((acc, curr) => {
            const [notificationName, notificationsConfigs] = curr;
            return [
              ...acc,
              {
                description: getNotificationSettingDescription(section, notificationName),
                channels: notificationsConfigs.map(config => {
                  let disabled;
                  let checked;
                  if ('slack' in config) {
                    disabled = settings.enabledSlack === false || !installedSlackToWorkspace;
                    checked = config.slack;
                  }
                  if ('mail' in config) {
                    disabled = settings.receiveEmailNotifications === false;
                    checked = config.mail;
                  }

                  return {
                    checked,
                    disabled: isLoading || disabled,
                    onChange: value => onNotificationChange(config.notificationKey, value),
                    key: config.notificationKey,
                  };
                }),
              },
            ];
          }, []),
        };
      }),
    [
      notificationsBySection,
      settings,
      isLoading,
      t,
      onNotificationChange,
      getNotificationSettingDescription,
      installedSlackToWorkspace,
    ]
  );

  useEffect(() => {
    const listener = action => {
      if (action.payload.modelKey === ModelKey.UPDATE_USER_NOTIFICATIONS_CONFIG) {
        dispatch(upsertSelected(USER_NOTIFICATIONS_SELECTED_KEY, action.payload.data));
        dispatch(getSelected(USER_SETTINGS_SELECTED_KEY));
      }
      if (action.payload.modelKey === ModelKey.USER_SETTINGS) {
        dispatch(upsertSelected(USER_SETTINGS_SELECTED_KEY, action.payload.data));
      }
    };

    dispatch(registerActionListener(CoreActionsType.MODEL_UPDATED, listener));

    return () => {
      dispatch(removeActionListener(CoreActionsType.MODEL_UPDATED, listener));
    };
  }, [dispatch]);

  const renderNotifications = () =>
    notificationsData.map(data => (
      <div key={data.title} className={classes.StructuredNotification}>
        <div className={classes.Title}>{data.title}</div>
        <div className={classes.NotificationConfigGroup}>
          {data.notifications.map((n, idx) => (
            <div key={`${n.description}_${idx}`} className={classes.NotificationConfig}>
              {n.description}
              <div className={classes.Channels}>
                {n.channels.map(c => (
                  <StandardCheckBox
                    key={c.key}
                    checked={c.checked}
                    disabled={c.disabled || isLoading}
                    onChange={c.onChange}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    ));

  const renderSlackInstallation = () => {
    let title = t(TransKeys.USER_SETTINGS.SETTINGS.SLACK_NOTIFICATIONS.CONNECT.TITLE);
    let description = t(TransKeys.USER_SETTINGS.SETTINGS.SLACK_NOTIFICATIONS.CONNECT.DESCRIPTION);

    if (installedSlackToWorkspace) {
      title = t(TransKeys.USER_SETTINGS.SETTINGS.SLACK_NOTIFICATIONS.REMOVE.TITLE);
      description = t(TransKeys.USER_SETTINGS.SETTINGS.SLACK_NOTIFICATIONS.REMOVE.DESCRIPTION);
    }

    return (
      <div className={classes.SlackInstallation}>
        <div className={classes.StructuredNotification}>
          <div className={classes.Title}>{title}</div>
          <div className={classes.NotificationConfigGroup}>
            <div className={classes.NotificationConfig}>
              <div className={classes.SlackDescription}>{description}</div>
              {installedSlackToWorkspace && (
                <div>
                  <Button onClick={() => onRemoveSlack()} variant={'outlined'}>
                    {t(TransKeys.GENERAL.ACTIONS.REMOVE)}
                  </Button>
                </div>
              )}
              {!installedSlackToWorkspace && <SlackBtn showText={false} />}
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderResubscribeToEmails = () => {
    if (settings.receiveEmailNotifications) {
      return null;
    }
    return (
      <div className={classes.Resubscribe}>
        <div className={classes.StructuredNotification}>
          <div className={classes.NotificationConfigGroup}>
            <div className={classes.NotificationConfig}>
              {t(TransKeys.USER_SETTINGS.SETTINGS.RESUBSCRIBE.DESCRIPTION)}
              <Button type={'button'} onClick={resubscribeToEmails}>
                {t(TransKeys.USER_SETTINGS.SETTINGS.RESUBSCRIBE.TITLE)}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {isLoading && <GenericLoading />}
      <section className={classes.Notifications}>
        <header className={classes.Header}>
          <div>{t(TransKeys.USER_SETTINGS.MENU.NOTIFICATIONS)}</div>
          <div className={classes.Icons}>
            <MailOutlineIcon />
            <img alt={'Slack icon'} src={COMPANIES_LOGOS.slack} className={classes.SlackIcon} />
          </div>
        </header>
        {renderResubscribeToEmails()}
        {renderSlackInstallation()}
        {renderNotifications()}
      </section>
    </>
  );
};

export const UserNotifications = withLoadBefore<AllProps>({
  settings: {
    selectedKey: USER_SETTINGS_SELECTED_KEY,
    actionKey: USER_SETTINGS_SELECTED_KEY,
    request: getUserSettingsNetworkRequest,
  },
  notificationsConfig: {
    selectedKey: USER_NOTIFICATIONS_SELECTED_KEY,
    actionKey: USER_NOTIFICATIONS_SELECTED_KEY,
    request: getUserNotificationsConfigNetworkRequest,
  },
})(UserNotificationsComponent);

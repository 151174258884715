import {AnalysisTypeId} from './analysis-type-id';

export const analysisRuntimeMinutes = {
  [AnalysisTypeId.RETENTION_DRIVERS]: 90,
  [AnalysisTypeId.HABIT_MOMENTS]: 150,
  [AnalysisTypeId.RETENTION_COHORTS]: 10,
  [AnalysisTypeId.GOAL_DRIVERS]: 30,
  [AnalysisTypeId.KPI_ANALYSIS]: 25,
  [AnalysisTypeId.CORRELATION_DRIVERS]: 10,
  [AnalysisTypeId.FEATURE_FUNNEL]: 10,
  [AnalysisTypeId.FEATURES_ANALYSIS]: 35,
  [AnalysisTypeId.FUNNEL_ANALYSIS]: 15,
  [AnalysisTypeId.CONTENT_FUNNEL]: 20,
  [AnalysisTypeId.A_B_TEST]: 15,
  [AnalysisTypeId.RELEASE_IMPACT]: 15,
  [AnalysisTypeId.USER_JOURNEY]: 30,
  [AnalysisTypeId.FIRST_SESSION]: 25,
  [AnalysisTypeId.POWER_USERS]: 10,
  [AnalysisTypeId.USERS_ANALYSIS]: 25,
  [AnalysisTypeId.PRE_CHURN]: 15,
  [AnalysisTypeId.PRE_GOAL]: 15,
  [AnalysisTypeId.POST_GOAL]: 15,
  [AnalysisTypeId.ENGAGEMENT_DRIVERS]: 180,
  [AnalysisTypeId.ERRORS_ANALYSIS]: 10,
  [AnalysisTypeId.ENGAGEMENT_ANALYSIS]: 15,
};

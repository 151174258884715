import {NRC} from './index';
import {RequestType} from 'front-core';
import {toCamelCase, toSnakeCase} from 'front-core';

export const createUserActivityNetworkRequest: NRC<any, void> = (data: any) => ({
  method: RequestType.POST,
  relativeUrl: `/app/user/activity`,
  body: data,
  bodyTransformer: data => toSnakeCase(data),
  responseTransformer: res => toCamelCase(res.data),
});

import * as React from 'react';
import {useCallback, useContext, useMemo, useRef} from 'react';
import classes from './homepage.module.scss';
import {useCurrentUser} from '../../core/hooks/use-user.hook';
import {greetings, splitAt} from '../../utils/general.utils';
import {RecentAnalyses} from './components/recent-analyses/recent-analyses.component';
import {
  AnalysesMenuIcon,
  ArrowRightIcon,
  DialogLayout,
  ExperimentsMenuIcon,
  MoreIcon,
  NetworkWiredRegularIcon,
  PopoverWrapper,
} from 'ui-components';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {useHistory} from 'react-router';
import {AppRoutes, TEAM_ID_PATH_PARAM} from '../../constants/app-routes';
import {RecentExperiments} from './components/recent-experiments/recent-experiments.component';
import {HomepageSummary} from './components/homepage-summary/homepage-summary.component';
import {
  HOMEPAGE_QUICK_ACTION_ICON,
  HOMEPAGE_QUICK_ACTION_PANEL_PARAMETERS,
  HomepageQuickAction,
} from './homepage.config';
import {values} from 'lodash';
import {PanelsContext} from '../../core/contexts/panels.context';
import {Tooltip} from '@material-ui/core';
import classNames from 'classnames';
import {useDispatch} from 'react-redux';
import {notifyEvent} from '../../store/core/core.actions';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {KPITree} from '../metrics/components/kpi-tree/kpi-tree.component';
import {TreeVariant} from '../metrics/components/kpi-tree/kpi-tree.consts';

interface OwnProps {}

type AllProps = OwnProps;

const MAX_QUICK_ACTIONS_TO_SHOW = 4;

export const Homepage: React.FC<AllProps> = (props: AllProps) => {
  const user = useCurrentUser();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {openPrimaryPanel} = useContext(PanelsContext);
  const moreOptionsRef = useRef<any>(null);

  const title = useMemo(() => {
    return `${greetings()}, ${user.firstName}`;
  }, [user]);
  const quickActionOptions = useMemo(
    () =>
      values(HomepageQuickAction).map(v => ({
        key: v,
        label: t(TransKeys.HOMEPAGE.QUICK_ACTIONS[v.toUpperCase()].LABEL),
        helperText: t(TransKeys.HOMEPAGE.QUICK_ACTIONS[v.toUpperCase()].HELPER_TEXT),
        icon: HOMEPAGE_QUICK_ACTION_ICON[v],
        onClick: () => {
          openPrimaryPanel(
            HOMEPAGE_QUICK_ACTION_PANEL_PARAMETERS[v].panelKey,
            HOMEPAGE_QUICK_ACTION_PANEL_PARAMETERS[v].parameters
          );
          moreOptionsRef.current?.close();
          dispatch(notifyEvent(AmplitudeEvent.HOMEPAGE_QUICK_ACTION_CLICKED, {action: v}));
        },
      })),
    [openPrimaryPanel, t, moreOptionsRef, dispatch]
  );
  const [mainQuickActions, otherActions] = useMemo(
    () => splitAt(quickActionOptions, MAX_QUICK_ACTIONS_TO_SHOW),
    [quickActionOptions]
  );
  const onExpandTree = useCallback(
    (query?: any) =>
      history.push(
        AppRoutes.kpiTree({
          ...query,
          // change null to undefined, to remove the param from the URL
          [TEAM_ID_PATH_PARAM]: query[TEAM_ID_PATH_PARAM] || undefined,
        })
      ),
    [history]
  );

  return (
    <div className={classes.HomepageContainer}>
      {/*HEADER*/}
      <div className={classes.Header}>
        <div className={classes.Title}>{title}</div>
        <div className={classes.SubTitle}>{t(TransKeys.HOMEPAGE.HEADER.SUB_TITLE)}</div>
        <div className={classes.Actions}>
          {mainQuickActions.map(option => (
            <React.Fragment key={option.key}>
              <Tooltip title={option.helperText || ''} placement={'top'} interactive={false}>
                <div className={classes.Button} onClick={() => option.onClick()}>
                  <option.icon className={classes.Icon} />
                  <div className={classes.QuickActionLabel}>{option.label}</div>
                </div>
              </Tooltip>
              <div className={classes.Spacer} />
            </React.Fragment>
          ))}
          <PopoverWrapper
            key={'more'}
            ref={moreOptionsRef}
            buttonRenderer={({onClick, isOpen}) => (
              <div
                onClick={onClick}
                className={classNames(classes.Button, classes.MoreButton, isOpen && classes.Active)}
              >
                <MoreIcon />
              </div>
            )}
          >
            <DialogLayout className={classes.DialogLayout}>
              <div className={classes.MoreActions}>
                {otherActions.map(option => (
                  <div className={classes.Button} onClick={option.onClick} key={option.key}>
                    <option.icon className={classes.Icon} />
                    <div className={classes.QuickActionLabel}>{option.label}</div>
                  </div>
                ))}
              </div>
            </DialogLayout>
          </PopoverWrapper>
        </div>
      </div>
      <div className={classes.HomepageContent}>
        <HomepageSummary className={classes.HomepageSummary} />
        <div className={classes.Section}>
          <div className={classes.SectionHeader}>
            <div className={classes.TitleWrapper}>
              <NetworkWiredRegularIcon className={classes.Icon} />
              <div className={classes.Title}>KPI Tree</div>
            </div>
          </div>
          <KPITree variant={TreeVariant.SIMPLE} showHeader={false} onExpandTree={onExpandTree} />
        </div>
        {/*RECENT ANALYSES*/}
        <div className={classes.Section}>
          <div className={classes.SectionHeader}>
            <div className={classes.TitleWrapper}>
              <AnalysesMenuIcon className={classes.Icon} />
              <div className={classes.Title}>
                {t(TransKeys.HOMEPAGE.SECTIONS.RECENT_ANALYSES.TITLE)}
              </div>
            </div>
            <div className={classes.Actions}>
              <div
                className={classes.SeeAllButton}
                onClick={() => history.push(AppRoutes.analyses())}
              >
                {t(TransKeys.HOMEPAGE.SECTIONS.RECENT_ANALYSES.SEE_ALL)}
                <ArrowRightIcon className={classes.Icon} />
              </div>
            </div>
          </div>
          <RecentAnalyses />
        </div>
        {/*RECENT EXPERIMENTS*/}
        <div className={classes.Section}>
          <div className={classes.SectionHeader}>
            <div className={classes.TitleWrapper}>
              <ExperimentsMenuIcon className={classes.Icon} />
              <div className={classes.Title}>
                {t(TransKeys.HOMEPAGE.SECTIONS.RECENT_EXPERIMENTS.TITLE)}
              </div>
            </div>
            <div className={classes.Actions}>
              <div
                className={classes.SeeAllButton}
                onClick={() => history.push(AppRoutes.experiments())}
              >
                {t(TransKeys.HOMEPAGE.SECTIONS.RECENT_EXPERIMENTS.SEE_ALL)}
                <ArrowRightIcon className={classes.Icon} />
              </div>
            </div>
          </div>
          <RecentExperiments />
        </div>
      </div>
    </div>
  );
};

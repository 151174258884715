import * as React from 'react';
import {composition} from 'front-core';
import {ExperimentRouting} from './experiments-routing.component';

interface OwnProps {}

type AllProps = OwnProps;

const ExperimentsComponent: React.FC<AllProps> = (props: AllProps) => {
  return <ExperimentRouting />;
};

const Experiments = composition<OwnProps>(ExperimentsComponent);

export default Experiments;

import * as React from 'react';
import classNames from 'classnames';
import classes from './table-column-query-builder.module.scss';
import {LiteralValueType, QueryBuilderFactory} from 'ui-components';
import {
  METADATA_KEY,
  PARAMETERS_METADATA_KEY,
} from '../../../../../constants/parameters-saved-keys';
import {useCallback, useMemo} from 'react';
import {TableSmartSelector} from '../../smart-selector/table-smart-selector.component';
import {TableColumnSmartSelector} from '../../smart-selector/table-column-smart-selector.component';
import {Tooltip} from '@material-ui/core';

interface OwnProps {
  query: any;
  onChange?: (query: any) => void;
  errors?: any;
  disabled?: boolean;
  className?: string;
  tableFilters?: any;
  columnFilters?: any;
}

type AllProps = OwnProps;

export const createTableColumnInitialQuery = () => {
  const q = QueryBuilderFactory.createTableColumn();
  q[PARAMETERS_METADATA_KEY] = {
    [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: 'TableColumnQueryBuilder',
  };
  return q;
};

const {TABLE_COLUMN_ID_KEY} = METADATA_KEY;

const DEFAULT_TABLE_COLUMN_FILTERS = {
  literalType: [
    LiteralValueType.INTEGER,
    LiteralValueType.FLOAT,
    LiteralValueType.STRING,
    LiteralValueType.BOOLEAN,
    LiteralValueType.DATE,
  ],
};

export const TableColumnQueryBuilder: React.FC<AllProps> = (props: AllProps) => {
  const {query, onChange, errors, disabled, tableFilters, columnFilters, className} = props;

  const onTableChange = useCallback(
    (tableId: number) => {
      const newQuery = createTableColumnInitialQuery();
      newQuery.table_id = tableId;
      onChange(newQuery);
    },
    [onChange]
  );

  const onTableColumnChange = useCallback(
    (tableColumnId: number, tableColumn: any) => {
      const newQuery = query ? {...query} : createTableColumnInitialQuery();
      newQuery.column = tableColumn.name;
      newQuery[PARAMETERS_METADATA_KEY] = {
        ...newQuery[PARAMETERS_METADATA_KEY],
        [TABLE_COLUMN_ID_KEY]: tableColumnId,
      };
      onChange(newQuery);
    },
    [query, onChange]
  );

  const tableColumnFilters = useMemo(
    () => ({
      literalType: columnFilters?.literalType || DEFAULT_TABLE_COLUMN_FILTERS.literalType,
      tableId: query?.table_id,
    }),
    [query, columnFilters]
  );

  return (
    <div className={classNames(classes.TableColumnQueryBuilder, className)}>
      <div className={classes.Row}>
        <div className={classes.Item}>
          <TableSmartSelector
            value={query?.table_id}
            placeholder={'Table'}
            onChange={onTableChange}
            error={Boolean(errors?.table_id)}
            disabled={disabled}
            filters={tableFilters}
          />
        </div>
        <Tooltip title={!Boolean(query?.table_id) ? 'Select table first' : ''} placement={'top'}>
          <div className={classes.Item}>
            <TableColumnSmartSelector
              value={query?.[PARAMETERS_METADATA_KEY]?.[TABLE_COLUMN_ID_KEY]}
              placeholder={'Property'}
              onChange={onTableColumnChange}
              error={Boolean(errors?.column)}
              disabled={disabled || !Boolean(query?.table_id)}
              filters={tableColumnFilters}
            />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

import {useMemo} from 'react';
import {GenericLoading} from '../../../../../shared/components/general/generic-loading/generic-loading.component';
import {AnalysisResults} from '../../../../../shared/core/document-viewer/analysis-results.component';
import {DocumentElementType, RCAFunnelFigureViewer} from 'ui-components';

import {
  AnalysisResult,
  AnalysisResultStatus,
} from '../../../../../../objects/models/analysis-result.model';
import {ViewAnalysisEmptyState} from 'src/modules/analyses/components/view-analysis-empty-state/view-analysis-empty-state.component';
import classes from './funnel-page-rca-tab-empty-state.module.scss';

type FunnelPageRCATabProps = {
  analysisResult: AnalysisResult;
  targetSignalId?: number;
  onSelectRCATargetFunnelStep?: (targetSignalId: number) => void;
  isLoadingResult?: boolean;
  onDocumentResolved?: () => void;
};

export const FunnelPageRCATab = (props: FunnelPageRCATabProps) => {
  const {
    analysisResult,
    onDocumentResolved,
    isLoadingResult,
    targetSignalId,
    onSelectRCATargetFunnelStep,
  } = props;

  const componentsMap = useMemo(() => {
    return {
      [DocumentElementType.RCA_FUNNEL_FIGURE]: props => (
        <RCAFunnelFigureViewer
          {...props}
          targetSignalId={targetSignalId}
          onSelectRCATargetFunnelStep={onSelectRCATargetFunnelStep}
        />
      ),
    };
  }, [targetSignalId, onSelectRCATargetFunnelStep]);

  if (!analysisResult) {
    return <GenericLoading />;
  }

  const showResult =
    analysisResult.status === AnalysisResultStatus.COMPLETED && analysisResult.rootDocumentId;

  if (!showResult) {
    return (
      <div className={classes.RunningEmptyStateWrapper}>
        <ViewAnalysisEmptyState analysisResult={analysisResult} />
      </div>
    );
  }

  return (
    <>
      {isLoadingResult && <GenericLoading />}
      <AnalysisResults
        key={analysisResult.id}
        analysisResult={analysisResult}
        isLoading={isLoadingResult}
        componentsMap={componentsMap}
        onDocumentResolvedSuccess={onDocumentResolved}
        scrollable={false}
        navigationDisabled
      />
    </>
  );
};

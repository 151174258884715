import classNames from 'classnames';
import sharedClasses from '../../insights.module.scss';
import {formatPercentageValueWithPrefix} from '../../insights.utils';

interface SentencePartProps {
  text: string;
  bold?: boolean;
  italic?: boolean;
  positive?: boolean;
  negative?: boolean;
  className?: string;
}

export const SentencePart: React.FC<SentencePartProps> = (props: SentencePartProps) => {
  const {text, bold, italic, positive, negative, className} = props;

  return (
    <span
      className={classNames(
        sharedClasses.InsightSentencePart,
        bold && sharedClasses.Bold,
        italic && sharedClasses.Italic,
        positive && sharedClasses.Positive,
        negative && sharedClasses.Negative,
        className
      )}
    >
      {text}
    </span>
  );
};

interface SentenceContainerProps {
  children: React.ReactElement<typeof SentencePart>[] | React.ReactElement<typeof SentencePart>;
  className?: string;
}

export const SentenceContainer: React.FC<SentenceContainerProps> = (
  props: SentenceContainerProps
) => {
  const {children, className} = props;
  return <div className={classNames(sharedClasses.SentenceContainer, className)}>{children}</div>;
};

export const ChangeInTotalEntitiesSentencePart = (props: {changeInTotalEntities: string}) => {
  const {changeInTotalEntities} = props;

  return (
    <SentenceContainer>
      <SentencePart bold text={changeInTotalEntities} />
    </SentenceContainer>
  );
};

export const ChangeInShareOfEntitiesSentencePart = (props: {changeInShareOfEntities: string}) => {
  const {changeInShareOfEntities} = props;

  return (
    <SentenceContainer>
      <SentencePart bold text={changeInShareOfEntities} />
    </SentenceContainer>
  );
};
export const ChangeInValueSentencePart = (props: {changeInValue: number}) => {
  const {changeInValue} = props;

  return (
    <SentenceContainer>
      <SentencePart bold text={formatPercentageValueWithPrefix(changeInValue)} />
    </SentenceContainer>
  );
};
export const SegmentNameSentencePart = (props: {segmentGroupName: string; segmentName: string}) => {
  const {segmentGroupName, segmentName} = props;

  return (
    <SentenceContainer>
      <SentencePart bold text={`${segmentGroupName} → ${segmentName}`} />
    </SentenceContainer>
  );
};

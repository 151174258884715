import * as React from 'react';
import {useCallback, useEffect, useMemo} from 'react';
import {cloneDeep, get, keys, set} from 'lodash';
import {exists} from 'front-core';
import {QueryBuilderFactory} from 'ui-components';
import {BaseRetentionBuilder} from './base-retention-builder.component';
import {BOUNDED_ACTIONS_ANALYSIS_PARAMETER_MAPPING} from '../query-builders.config';
import {
  METADATA_KEY,
  PARAMETERS_METADATA_KEY,
} from '../../../../../constants/parameters-saved-keys';
import {TableEntity} from '../../../../../objects/models/table.model';
import {SelectorModelType} from '../../smart-selector/advanced-smart-selector.component';

interface OwnProps {
  query: any;
  onChange?: (parameters: any) => void;
  onSignalInfo?: (value: string | number) => void;
  entityContext?: TableEntity;
  refDateSignalFilters?: any;
  refDateAllowTypes?: SelectorModelType[];
  actionsSignalFilters?: any;
  errors?: any;
  disabled?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const retentionQueryMapper = (value: any) => ({
  actions: get(value, BOUNDED_ACTIONS_ANALYSIS_PARAMETER_MAPPING.actions),
  ref_date: get(value, BOUNDED_ACTIONS_ANALYSIS_PARAMETER_MAPPING.ref_date),
  actions_retention_unit: get(
    value,
    BOUNDED_ACTIONS_ANALYSIS_PARAMETER_MAPPING.actions_retention_unit
  ),
  actions_retention_n: get(value, BOUNDED_ACTIONS_ANALYSIS_PARAMETER_MAPPING.actions_retention_n),
});

export const RetentionQueryBuilder: React.FC<AllProps> = (props: AllProps) => {
  const {
    errors,
    onChange,
    onSignalInfo,
    query,
    entityContext,
    refDateSignalFilters,
    refDateAllowTypes,
    actionsSignalFilters,
    disabled,
    className,
  } = props;
  const mappedValue = useMemo(() => retentionQueryMapper(query), [query]);
  const mappedErrors = useMemo(() => retentionQueryMapper(errors), [errors]);
  const mappedOnChange = useCallback(
    changes => {
      let newValue = cloneDeep(query);
      if (!exists(newValue)) {
        newValue = QueryBuilderFactory.createTemplate();
        newValue.template = 'bounded_actions_ts';
        newValue.parameters[1] = 0;
      }
      for (const k of keys(changes)) {
        set(newValue, BOUNDED_ACTIONS_ANALYSIS_PARAMETER_MAPPING[k], changes[k]);
      }
      newValue.parameters[1] =
        (get(newValue, BOUNDED_ACTIONS_ANALYSIS_PARAMETER_MAPPING.actions_retention_n) || 1) - 1;
      newValue[PARAMETERS_METADATA_KEY] = {
        [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: 'RetentionQueryBuilder',
      };
      onChange && onChange(newValue);
    },
    [onChange, query]
  );

  useEffect(() => {
    if (!exists(query)) {
      return;
    }
    if (!(PARAMETERS_METADATA_KEY in query)) {
      const newQuery = set(query, PARAMETERS_METADATA_KEY, {
        [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: 'RetentionQueryBuilder',
      });
      onChange && onChange(newQuery);
    }
  }, [query, onChange]);

  return (
    <BaseRetentionBuilder
      value={mappedValue}
      onChange={mappedOnChange}
      errors={mappedErrors}
      onSignalInfo={onSignalInfo}
      className={className}
      entityContext={entityContext}
      refDateSignalFilters={refDateSignalFilters}
      refDateAllowTypes={refDateAllowTypes}
      actionsSignalFilters={actionsSignalFilters}
      disabled={disabled}
    />
  );
};

import * as React from 'react';
import {Redirect, Route, Switch} from 'react-router';
import {Guard} from 'front-core';
import {AppRoutes} from '../../constants/app-routes';
import Experiments from '../experiments/experiments.component';
import Analyses from '../analyses/analyses.component';
import Opportunities from '../opportunities/opportunities.component';
import DMP from '../dmp/dmp.component';
import {Settings} from '../settings/settings.component';
import HealthMonitor from '../health-monitor/health-monitor.component';
import {useProductData} from '../../core/hooks/use-product-data.hook';
import {IntegrationStatus} from '../../objects/models/product-data.model';
import {useCallback, useMemo} from 'react';
import {useIsAdmin} from '../../core/hooks/use-is-admin.hook';
import {useLocation} from 'react-router-dom';
import {Homepage} from '../homepage/homepage.component';
import Metrics from '../metrics/metrics.component';
import {Funnels} from '../funnels/funnels.component';
import Workflows from '../workflows/workflows.component';

const CoreRouting: React.FC<{}> = (props: {}) => {
  const location = useLocation();
  const {integrationStatus} = useProductData();
  const isAdmin = useIsAdmin();
  const isAnalysisResultPage = useMemo(
    () => location.pathname.includes('/analyses') && location.search.includes('result'),
    [location]
  );
  const isActiveRoute = useMemo(
    () => isAdmin || integrationStatus === IntegrationStatus.ACTIVE,
    [isAdmin, integrationStatus]
  );
  const onIntegrationStatusNotActive = useCallback(
    () => <Redirect to={AppRoutes.dmp().pathname} />,
    []
  );

  return (
    <Switch>
      <Route path={AppRoutes.homepage().pathname}>
        <Guard isActive={isActiveRoute} onNotActive={onIntegrationStatusNotActive}>
          <Homepage />
        </Guard>
      </Route>
      <Route path={AppRoutes.experiments().pathname}>
        <Guard isActive={isActiveRoute} onNotActive={onIntegrationStatusNotActive}>
          <Experiments />
        </Guard>
      </Route>
      <Route path={AppRoutes.analyses().pathname}>
        <Guard
          isActive={isActiveRoute || isAnalysisResultPage}
          onNotActive={onIntegrationStatusNotActive}
        >
          <Analyses />
        </Guard>
      </Route>
      <Route path={AppRoutes.opportunities().pathname}>
        <Guard isActive={isActiveRoute} onNotActive={onIntegrationStatusNotActive}>
          <Opportunities />
        </Guard>
      </Route>
      <Route path={AppRoutes.workflows().pathname}>
        <Guard isActive={isActiveRoute} onNotActive={onIntegrationStatusNotActive}>
          <Workflows />
        </Guard>
      </Route>
      <Route path={AppRoutes.healthMonitor().pathname}>
        <Guard isActive={isActiveRoute} onNotActive={onIntegrationStatusNotActive}>
          <HealthMonitor />
        </Guard>
      </Route>
      <Route path={AppRoutes.metrics().pathname}>
        <Guard isActive={isActiveRoute} onNotActive={onIntegrationStatusNotActive}>
          <Metrics />
        </Guard>
      </Route>
      <Route path={AppRoutes.funnelPage().pathname}>
        <Guard isActive={isActiveRoute} onNotActive={onIntegrationStatusNotActive}>
          <Funnels />
        </Guard>
      </Route>
      <Route path={AppRoutes.dmp().pathname} component={DMP} />
      <Route path={AppRoutes.settings().pathname} component={Settings} />
      <Route
        path={AppRoutes.root().pathname}
        render={() => <Redirect to={AppRoutes.homepage().pathname} />}
      />
    </Switch>
  );
};

export default CoreRouting;

import TransKeys from 'translations';
import i18n from 'i18next';
import {
  Notification,
  NotificationJobStatus,
  NotificationType,
} from '../../../../../objects/models/notification.model';
import classes from './notification-item.module.scss';
import {capitalize} from 'lodash';
import classNames from 'classnames';
import * as React from 'react';
import {
  AppRoutes,
  CONTENT_ID_PATH_PARAM,
  FUNNEL_ID_PATH_PARAM,
  METRIC_ID_PATH_PARAM,
  SEGMENT_ID_PATH_PARAM,
  TABLE_ID_PATH_PARAM,
} from '../../../../../constants/app-routes';
import {PanelKey} from '../../../../../constants/panels';

export enum NotificationActionType {
  PANEL = 'PANEL',
  REDIRECT = 'REDIRECT',
}

export interface NotificationAction {
  type: NotificationActionType;
  payload: any;
}

abstract class BaseNotificationStringifier {
  notification: Notification;

  constructor(notification: Notification) {
    this.notification = notification;
  }

  getModel(): string {
    const modelName = i18n.t(TransKeys.MODELS[this.notification.modelType.toUpperCase()]);
    if (modelName === 'KPI') {
      return modelName;
    }
    return capitalize(modelName);
  }

  abstract stringify(): JSX.Element;

  abstract getAction(): NotificationAction;
}

abstract class UpdateNotificationStringifier extends BaseNotificationStringifier {
  stringify() {
    const model = this.getModel();
    let action = 'is updating';
    if (this.notification.jobStatus === NotificationJobStatus.COMPLETED) {
      action = 'updated successfully';
    }
    if (this.notification.jobStatus === NotificationJobStatus.FAILED) {
      action = 'could not be updated';
    }

    return (
      <>
        {model && <span className={classes.Part}>{model}</span>}
        {this.notification.model && (
          <span className={classNames(classes.Part, classes.Name)}>
            {this.notification.model.name}
          </span>
        )}
        {action && <span className={classes.Part}>{action}</span>}
      </>
    );
  }
}

class AnalysisResultRunningNotificationStringifier extends BaseNotificationStringifier {
  stringify() {
    const model = this.getModel();
    let action = 'is running';
    if (this.notification.jobStatus === NotificationJobStatus.COMPLETED) {
      action = 'is ready';
    }
    if (this.notification.jobStatus === NotificationJobStatus.FAILED) {
      action = 'failed';
    }
    if (this.notification.jobStatus === NotificationJobStatus.STOPPED) {
      action = 'stopped';
    }

    return (
      <>
        {model && <span className={classes.Part}>{model}</span>}
        {this.notification.model && (
          <span className={classNames(classes.Part, classes.Name)}>
            {this.notification.model.name}
          </span>
        )}
        {action && <span className={classes.Part}>{action}</span>}
      </>
    );
  }

  getAction(): NotificationAction {
    if (!this.notification.model) {
      return;
    }
    return {
      type: NotificationActionType.REDIRECT,
      payload: AppRoutes.viewAnalysis(this.notification.model.analysisId, {
        resultId: this.notification.modelId,
      }),
    };
  }
}

class ScanSourceNotificationStringifier extends BaseNotificationStringifier {
  stringify() {
    const model = this.getModel();
    let action = 'is scanning';
    if (this.notification.jobStatus === NotificationJobStatus.COMPLETED) {
      action = 'scanned successfully';
    }
    if (this.notification.jobStatus === NotificationJobStatus.FAILED) {
      action = 'could not be scanned';
    }

    return (
      <>
        {model && <span className={classes.Part}>{model}</span>}
        {this.notification.model && (
          <span className={classNames(classes.Part, classes.Name)}>
            {this.notification.model.name}
          </span>
        )}
        {action && <span className={classes.Part}>{action}</span>}
      </>
    );
  }

  getAction(): NotificationAction {
    return {
      type: NotificationActionType.REDIRECT,
      payload: AppRoutes.views(),
    };
  }
}

class ScanAllTablesEventsNotificationStringifier extends BaseNotificationStringifier {
  stringify() {
    let action = 'Scanning all tables events';
    if (this.notification.jobStatus === NotificationJobStatus.COMPLETED) {
      action = 'All tables events scanned successfully';
    }
    if (this.notification.jobStatus === NotificationJobStatus.FAILED) {
      action = 'All tables events could not be scanned';
    }
    return <span className={classes.Part}>{action}</span>;
  }

  getAction(): NotificationAction {
    return {
      type: NotificationActionType.REDIRECT,
      payload: AppRoutes.views(),
    };
  }
}

class ScanAllTablesSchemaNotificationStringifier extends BaseNotificationStringifier {
  stringify() {
    let action = 'Scanning all tables schema';
    if (this.notification.jobStatus === NotificationJobStatus.COMPLETED) {
      action = 'All tables schema scanned successfully';
    }
    if (this.notification.jobStatus === NotificationJobStatus.FAILED) {
      action = 'All tables schema could not be scanned';
    }
    return <span className={classes.Part}>{action}</span>;
  }

  getAction(): NotificationAction {
    return {
      type: NotificationActionType.REDIRECT,
      payload: AppRoutes.views(),
    };
  }
}

class ScanContentValuesNotificationStringifier extends UpdateNotificationStringifier {
  getAction(): NotificationAction {
    if (!this.notification.model) {
      return;
    }

    return {
      type: NotificationActionType.PANEL,
      payload: {
        key: PanelKey.VIEW_CONTENT_PANEL,
        parameters: {
          [CONTENT_ID_PATH_PARAM]: this.notification.modelId,
        },
      },
    };
  }
}

class ScanMetricValuesNotificationStringifier extends UpdateNotificationStringifier {
  getAction(): NotificationAction {
    if (!this.notification.model) {
      return;
    }

    return {
      type: NotificationActionType.PANEL,
      payload: {
        key: PanelKey.VIEW_METRIC_PANEL,
        parameters: {
          [METRIC_ID_PATH_PARAM]: this.notification.modelId,
        },
      },
    };
  }
}

class ScanFunnelValuesNotificationStringifier extends UpdateNotificationStringifier {
  getAction(): NotificationAction {
    if (!this.notification.model) {
      return;
    }

    return {
      type: NotificationActionType.PANEL,
      payload: {
        key: PanelKey.VIEW_FUNNEL_PANEL,
        parameters: {
          [FUNNEL_ID_PATH_PARAM]: this.notification.modelId,
        },
      },
    };
  }
}

class ScanSegmentValuesNotificationStringifier extends UpdateNotificationStringifier {
  getAction(): NotificationAction {
    if (!this.notification.model) {
      return;
    }

    return {
      type: NotificationActionType.PANEL,
      payload: {
        key: PanelKey.VIEW_SEGMENT_PANEL,
        parameters: {
          [SEGMENT_ID_PATH_PARAM]: this.notification.modelId,
        },
      },
    };
  }
}

class ScanTableEventsNotificationStringifier extends UpdateNotificationStringifier {
  stringify() {
    const model = this.getModel();

    let action = `events are being scanned`;
    if (this.notification.jobStatus === NotificationJobStatus.COMPLETED) {
      action = `events scanned successfully`;
    }
    if (this.notification.jobStatus === NotificationJobStatus.FAILED) {
      action = `events could not be scanned`;
    }

    return (
      <>
        {model && <span className={classes.Part}>{model}</span>}
        {this.notification.model && (
          <span className={classNames(classes.Part, classes.Name)}>
            {this.notification.model.name}
          </span>
        )}
        {action && <span className={classes.Part}>{action}</span>}
      </>
    );
  }

  getAction(): NotificationAction {
    if (!this.notification.model) {
      return;
    }

    return {
      type: NotificationActionType.PANEL,
      payload: {
        key: PanelKey.VIEW_TABLE_PANEL,
        parameters: {
          [TABLE_ID_PATH_PARAM]: this.notification.modelId,
        },
      },
    };
  }
}

class ScanTableSchemaNotificationStringifier extends UpdateNotificationStringifier {
  stringify() {
    const model = this.getModel();

    let action = `schema is being scanned`;
    if (this.notification.jobStatus === NotificationJobStatus.COMPLETED) {
      action = `schema scanned successfully`;
    }
    if (this.notification.jobStatus === NotificationJobStatus.FAILED) {
      action = `schema could not be scanned`;
    }

    return (
      <>
        {model && <span className={classes.Part}>{model}</span>}
        {this.notification.model && (
          <span className={classNames(classes.Part, classes.Name)}>
            {this.notification.model.name}
          </span>
        )}
        {action && <span className={classes.Part}>{action}</span>}
      </>
    );
  }

  getAction(): NotificationAction {
    if (!this.notification.model) {
      return;
    }

    return {
      type: NotificationActionType.PANEL,
      payload: {
        key: PanelKey.VIEW_TABLE_PANEL,
        parameters: {
          [TABLE_ID_PATH_PARAM]: this.notification.modelId,
        },
      },
    };
  }
}

const CLASSES_MAP = {
  [NotificationType.SCAN_CONTENT_VALUES]: ScanContentValuesNotificationStringifier,
  [NotificationType.SCAN_METRIC_SAMPLES]: ScanMetricValuesNotificationStringifier,
  [NotificationType.SCAN_FUNNEL_SAMPLES]: ScanFunnelValuesNotificationStringifier,
  [NotificationType.SCAN_SEGMENT_VALUES]: ScanSegmentValuesNotificationStringifier,
  [NotificationType.SCAN_TABLE_EVENTS]: ScanTableEventsNotificationStringifier,
  [NotificationType.SCAN_TABLE_SCHEMA]: ScanTableSchemaNotificationStringifier,
  [NotificationType.RUN_ANALYSIS]: AnalysisResultRunningNotificationStringifier,
  [NotificationType.SCAN_SOURCE]: ScanSourceNotificationStringifier,
  [NotificationType.SCAN_ALL_TABLES_EVENTS]: ScanAllTablesEventsNotificationStringifier,
  [NotificationType.SCAN_ALL_TABLES_SCHEMA]: ScanAllTablesSchemaNotificationStringifier,
};

export class NotificationClassesFactory {
  static create(notification: Notification) {
    return new CLASSES_MAP[notification.type](notification);
  }
}

import * as React from 'react';
import {HomepageMetric} from '../../../../../../../objects/models/homepage.model';
import {useMemo} from 'react';
import {HomepageListMode} from '../homepage-list.component';
import {HomepageListItem} from './homepage-list-item.component';
import {useHomepageModelData} from '../../../hooks/homepage-summary.hooks';
import {GoalStatus} from '../../goal-status/goal-status.component';

interface OwnProps {
  item: HomepageMetric;
  mode: HomepageListMode;
  onClick: () => void;
  onRemove: () => void;
  onAdd: () => void;
  isSelected?: boolean;
  isOnHomepage: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const HomepageMetricListItem: React.FC<AllProps> = (props: AllProps) => {
  const {item: itemFromProps} = props;
  const {reviewedSeries, lastSampleValues, renderValue, trend} =
    useHomepageModelData(itemFromProps);
  const item = useMemo(
    () => ({
      name: itemFromProps.name,
      granularity: reviewedSeries?.granularity,
      samples: lastSampleValues,
      renderValue,
      trend,
    }),
    [itemFromProps, reviewedSeries, lastSampleValues, renderValue, trend]
  );

  return (
    <HomepageListItem
      {...props}
      item={item}
      subTitle={<GoalStatus metric={itemFromProps} small />}
    />
  );
};

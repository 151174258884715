import {AnalysisTypeId} from '../../../../../../constants/analysis-type-id';
import {MetricType} from '../../../../../../objects/models/metric.model';
import {ModelType} from 'ui-components';
import {capitalize, flatten, keys, values} from 'lodash';
import i18n from 'i18next';
import TransKeys from 'translations';

export enum AnalysisTypeTopic {
  ACTIONS = 'actions',
  ACTION_COMBINATIONS = 'action_combinations',
  SEQUENCES = 'sequences',
  SEGMENTS = 'segments',
  TRENDS = 'trends',
  HABIT_MOMENT = 'habit_moment',
}

const allTopicOptions = () =>
  values(AnalysisTypeTopic).map(v => ({
    value: v,
    label: i18n.t(TransKeys.CREATE_ANALYSIS_FORM.WIZARD.TOPICS[v.toUpperCase()]),
  }));

const conversionTopics = {
  [AnalysisTypeTopic.ACTIONS]: [
    AnalysisTypeId.GOAL_DRIVERS,
    AnalysisTypeId.CORRELATION_DRIVERS,
    AnalysisTypeId.FIRST_SESSION,
  ],
  [AnalysisTypeTopic.ACTION_COMBINATIONS]: [AnalysisTypeId.USER_JOURNEY],
  [AnalysisTypeTopic.SEQUENCES]: [AnalysisTypeId.USER_JOURNEY],
  [AnalysisTypeTopic.SEGMENTS]: [AnalysisTypeId.KPI_ANALYSIS],
  [AnalysisTypeTopic.TRENDS]: [AnalysisTypeId.KPI_ANALYSIS],
};

const retentionTopics = {
  [AnalysisTypeTopic.ACTIONS]: [
    AnalysisTypeId.GOAL_DRIVERS,
    AnalysisTypeId.CORRELATION_DRIVERS,
    AnalysisTypeId.FIRST_SESSION,
  ],
  [AnalysisTypeTopic.ACTION_COMBINATIONS]: [AnalysisTypeId.USER_JOURNEY],
  [AnalysisTypeTopic.SEQUENCES]: [AnalysisTypeId.USER_JOURNEY],
  [AnalysisTypeTopic.SEGMENTS]: [AnalysisTypeId.KPI_ANALYSIS],
  [AnalysisTypeTopic.TRENDS]: [AnalysisTypeId.KPI_ANALYSIS],
  [AnalysisTypeTopic.HABIT_MOMENT]: [AnalysisTypeId.HABIT_MOMENTS],
};

const engagementTopics = {
  [AnalysisTypeTopic.ACTIONS]: [AnalysisTypeId.ENGAGEMENT_DRIVERS],
  [AnalysisTypeTopic.SEGMENTS]: [AnalysisTypeId.KPI_ANALYSIS],
  [AnalysisTypeTopic.TRENDS]: [AnalysisTypeId.KPI_ANALYSIS],
};

const metricTypeTopic = {
  [MetricType.CONVERSION]: conversionTopics,
  [MetricType.RETENTION]: retentionTopics,
  [MetricType.L7]: engagementTopics,
  [MetricType.L28]: engagementTopics,
  [MetricType.DAU]: engagementTopics,
  [MetricType.WAU]: engagementTopics,
  [MetricType.MAU]: engagementTopics,
  [MetricType.USAGE]: engagementTopics,
};

const funnelTopic = {
  [AnalysisTypeTopic.SEGMENTS]: [AnalysisTypeId.FUNNEL_ANALYSIS],
  [AnalysisTypeTopic.TRENDS]: [AnalysisTypeId.FUNNEL_ANALYSIS],
};

export const generatePlaceholderSentenceOptions = (): {model: string; topic: string}[] => {
  const ACCEPTED_TOPICS_MAPPING = {
    [ModelType.FUNNEL]: keys(funnelTopic),
    [ModelType.METRIC]: keys({
      ...conversionTopics,
      ...retentionTopics,
      ...engagementTopics,
    }),
  };
  const orderedTopics = [
    AnalysisTypeTopic.ACTIONS,
    AnalysisTypeTopic.SEGMENTS,
    AnalysisTypeTopic.TRENDS,
    AnalysisTypeTopic.ACTION_COMBINATIONS,
    AnalysisTypeTopic.SEQUENCES,
    AnalysisTypeTopic.HABIT_MOMENT,
  ];
  const modelTypes = [ModelType.METRIC, ModelType.FUNNEL];
  const res = [];

  for (const t of orderedTopics) {
    for (const m of modelTypes) {
      if (ACCEPTED_TOPICS_MAPPING[m].indexOf(t) === -1) {
        continue;
      }
      const topic = i18n
        .t(TransKeys.CREATE_ANALYSIS_FORM.WIZARD.TOPICS[t.toUpperCase()])
        .toLowerCase();
      let model = i18n.t(TransKeys.MODELS[m.toUpperCase()]);
      if (m !== ModelType.METRIC) {
        model = capitalize(model);
      }
      res.push({model, topic});
    }
  }

  return res;
};

const getTopicMapping = (modelType: ModelType.METRIC | ModelType.FUNNEL, model: any) => {
  switch (modelType) {
    case ModelType.FUNNEL:
      return funnelTopic;
    case ModelType.METRIC:
      return metricTypeTopic[model.type];
  }
  return {};
};

export const getAnalysisTypeIds = (
  modelType: ModelType.METRIC | ModelType.FUNNEL,
  model: any,
  topic?: AnalysisTypeTopic
): number[] => {
  let topicItems = getTopicMapping(modelType, model);
  if (topic) {
    return topicItems[topic] || [];
  }
  return flatten(values(topicItems));
};

export const getTopicOptions = (modelType: ModelType.METRIC | ModelType.FUNNEL, model: any) => {
  let topicItems = getTopicMapping(modelType, model);
  if (!topicItems) {
    return allTopicOptions();
  }
  return allTopicOptions().filter(o => Object.keys(topicItems).includes(o.value));
};

import {NRC} from './index';
import {RequestType, toSnakeCase} from 'front-core';
import {
  ValidateAnalysisTimeframeDTO,
  ValidateSignalBoundingDaysRequestDTO,
  ValidateNameDTO,
  ValidateSqlDTO,
  ValidateRootCauseAnalysisSignalTimeframeDTO,
} from '../objects/dto/validations.dto';

export const validateNameNetworkRequest: NRC<ValidateNameDTO, void> = (
  payload: ValidateNameDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/validations/name`,
  body: payload,
  bodyTransformer: toSnakeCase,
});

export const validateSqlNetworkRequest: NRC<ValidateSqlDTO, void> = (payload: ValidateSqlDTO) => ({
  method: RequestType.POST,
  relativeUrl: `/app/validations/sql`,
  body: payload,
  bodyTransformer: toSnakeCase,
  responseTransformer: (response: any) => response.data,
});

export const validateAnalysisTimeframeRequest: NRC<ValidateAnalysisTimeframeDTO, void> = (
  payload: ValidateAnalysisTimeframeDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/validations/analysis-signal-timeframe`,
  body: payload,
  bodyTransformer: toSnakeCase,
});

export const validateSignalBoundingDaysRequest: NRC<ValidateSignalBoundingDaysRequestDTO, void> = (
  payload: ValidateSignalBoundingDaysRequestDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/validations/signal-bounding-days`,
  body: payload,
  bodyTransformer: toSnakeCase,
});

export const validateSignalIdsNetworkRequest: NRC<any, void> = (ids: number[]) => ({
  method: RequestType.POST,
  relativeUrl: `/app/validations/validate-signal-ids`,
  body: {
    signalIds: ids,
  },
  bodyTransformer: toSnakeCase,
});

export const validateRootCauseAnalysisSignalTimeframeRequest: NRC<
  ValidateRootCauseAnalysisSignalTimeframeDTO,
  void
> = (payload: ValidateRootCauseAnalysisSignalTimeframeDTO) => ({
  method: RequestType.POST,
  relativeUrl: `/app/validations/root-cause-analysis-signal-timeframe`,
  body: payload,
  bodyTransformer: toSnakeCase,
});

import {hotjar} from 'react-hotjar';
import appConfig from './app.config';
import {HotjarEvent} from '../constants/hotjar-event';

export const initHotjar = () => {
  if (appConfig.hotjarID && appConfig.hotjarSnippetVersion) {
    hotjar.initialize(appConfig.hotjarID, appConfig.hotjarSnippetVersion);
  }
};

export const setHotjarUser = (id, isInternalUser, scopeProductId) => {
  if (hotjar.initialized()) {
    hotjar.identify(id, {isInternalUser, scopeProductId});
  }
};

// https://help.hotjar.com/hc/en-us/articles/4405109971095-Events-API-Reference#the-events-api-call
// Hotjar doesn't support sending attributes with events, so we concat them to the event name
// Event name must not exceed 250 characters, and can contain alphanumeric characters (a-z, A-Z, 0-9), underscores (_), and dashes (-)
export const sendHotjarEvent = (event: HotjarEvent, attributes: string = null) => {
  if (hotjar.initialized()) {
    let payload = event as string;

    if (attributes) {
      payload += `_${attributes}`;
    }

    const payloadValidity = payload.length <= 250 && payload.match(/^[a-zA-Z0-9_-]*$/);
    if (!payloadValidity) {
      console.error(`Hotjar event payload is invalid: ${payload}`);
      return;
    }

    hotjar.event(payload);
  }
};

import {StoreDefinition, StorePropertyType} from 'front-core';
import {InterfaceActionsTypes} from './interface.actions';
import {
  autoCloseGrowthMapReducer,
  confirmationMessageResolvedReducer,
  showToastMessageReducer,
} from './interface.redcuers';

export const INTERFACE_STORE_KEY = 'INTERFACE_STORE';

export const interfaceStore: StoreDefinition = {
  key: INTERFACE_STORE_KEY,
  properties: {
    confirmationMessage: {
      propertyType: StorePropertyType.OBJECT,
      defaultValue: undefined,
    },
    isGrowthMapOpen: {
      propertyType: StorePropertyType.BOOLEAN,
      defaultValue: false,
    },
    isGrowthMapHidden: {
      propertyType: StorePropertyType.BOOLEAN,
      defaultValue: false,
    },
  },
  reducer: {
    [InterfaceActionsTypes.CONFIRMATION_MESSAGE_RESOLVED]: confirmationMessageResolvedReducer,
    [InterfaceActionsTypes.SHOW_TOAST_MESSAGE]: showToastMessageReducer,
    '@@router/LOCATION_CHANGE': autoCloseGrowthMapReducer,
  },
};

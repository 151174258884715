import {NRC} from './index';
import {RequestType} from 'front-core';
import {toCamelCase} from 'front-core';

export const getSlackChannelsNetworkRequest: NRC<void, void> = () => ({
  method: RequestType.GET,
  relativeUrl: `/app/slack-channels`,
  bodyTransformer: toCamelCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const removeSlackFromProductNetworkRequest: NRC<void, void> = () => ({
  method: RequestType.DELETE,
  relativeUrl: `/app/slack-channels`,
  bodyTransformer: toCamelCase,
  responseTransformer: res => toCamelCase(res.data),
});

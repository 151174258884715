export enum ModelCategory {
  CONVERSION = 'conversion',
  ENGAGEMENT = 'engagement',
  MONETIZATION = 'monetization',
  RETENTION = 'retention',
  ACTIVATION = 'activation',
  ACQUISITION = 'acquisition',
  CHURN = 'churn',
  FUNNEL = 'funnel',
  FEATURE = 'feature',
}

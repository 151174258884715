import {FolderSolidIcon} from 'ui-components';

export enum AnalysesMainViews {
  RECENT = 'recent',
  FAVORITES = 'favorites',
  ARCHIVED = 'archived',
  SHARED = 'shared',
  ALL = 'all',
}

export const DEFAULT_FOLDER_ICON = FolderSolidIcon;

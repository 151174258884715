import yup from '../../config/yup.config';
import {values} from 'lodash';
import {TableEntity} from '../models/table.model';
import {queryValidator} from '../../modules/analyses/analysis-forms/analysis-parameters/shared-validators';

interface SharedAnalysisFollowUpActionsDTO {
  analysisResultId?: number;
  analysisFolderId?: number;
  analysisTypeIds: number[];
  segment?: number;
  segmentClass?: string;
}

const sharedAnalysisFollowUpActionsDTOValidator = {
  analysisFolderId: yup.number().nullable(),
  analysisResultId: yup.number().nullable(),
  analysisTypeIds: yup.array().of(yup.number()).min(1).required(),
  segment: yup.number().nullable(),
  segmentClass: yup.array().of(yup.mixed()).nullable(),
};

export interface RCAFromLineChartFollowUpActionsDTO {
  timeAggregation: string;
  startDateAnomaly: string;
  startDateComparing: string;
  useLoopsAnomalyDetectionAlgo: boolean;
  goal?: number;
  goalDefinition?: any;
}

export const rcaFromLineChartFollowUpActionsDTOValidator = yup.object().shape(
  {
    ...sharedAnalysisFollowUpActionsDTOValidator,
    timeAggregation: yup.string().oneOf(['day', 'week', 'month']),
    startDateAnomaly: yup.string().required(),
    startDateComparing: yup.string().optional().nullable(),
    signalName: yup.string().optional().nullable(),
    analysisMode: yup.string().optional().nullable(),
    userAnalysisName: yup.string().required(),
    useLoopsAnomalyDetectionAlgo: yup.boolean().required(),
    goal: yup.number().when('goalDefinition', {
      is: undefined,
      then: yup.number().required(),
      otherwise: yup.number().nullable(),
    }),
    goalDefinition: yup.object().when('goal', {
      is: undefined,
      then: queryValidator(false),
      otherwise: yup.object().nullable(),
    }),
    entity: yup.string().oneOf(values(TableEntity)).nullable(),
    higherIsBetter: yup.boolean().nullable(),
    includedSegmentsTag: yup.string().nullable(),
    includedSegmentsSignals: yup.array().of(yup.number()).nullable(),
    runPopulationSizeExplainer: yup.boolean(),
    runGroupSizeExplainer: yup.boolean(),
    runErrorExplainer: yup.boolean(),
    runExperimentExplainer: yup.boolean(),
  },
  [
    // Handles cyclic dependency  https://github.com/jquense/yup/issues/193
    ['goal', 'goalDefinition'],
    ['goalDefinition', 'goal'],
  ]
);

export interface FunnelAnalysisConversionFollowUpActionsDTO
  extends SharedAnalysisFollowUpActionsDTO {
  goal: number;
  refDate: number;
  funnel: number;
}

export const funnelAnalysisConversionFollowUpActionParametersValidator = yup.object().shape({
  goal: yup.number().required(),
  refDate: yup.number().required(),
  funnel: yup.number().required(),
  ...sharedAnalysisFollowUpActionsDTOValidator,
});

export interface GoalDriversAnalysisImproveFeatureAdoptionFollowUpActionsDTO
  extends SharedAnalysisFollowUpActionsDTO {
  featureId: number;
}

export const goalDriversAnalysisImproveFeatureAdoptionFollowUpActionParametersValidator = yup
  .object()
  .shape({
    featureSignal: yup.number().required(),
    treatmentsTag: yup.string().nullable(),
    treatmentsSignals: yup.array().of(yup.number()).nullable(),
    ...sharedAnalysisFollowUpActionsDTOValidator,
  });

export interface RetentionAnalysisInvestigateBucketFollowUpActionsDTO
  extends SharedAnalysisFollowUpActionsDTO {
  bucket: number;
}

export const retentionAnalysisInvestigateBucketFollowUpActionParametersValidator = yup
  .object()
  .shape({
    bucket: yup.number().required(),
    ...sharedAnalysisFollowUpActionsDTOValidator,
  });

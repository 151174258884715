import * as React from 'react';
import classes from './homepage-configuration-panel.module.scss';
import {useCallback, useMemo, useState} from 'react';
import {
  Button,
  Chip,
  ChipVariant,
  CircleInfoRegularIcon,
  FancyHeader,
  LabelWrapper,
  LoopsIcon,
  ModalLayout,
  Select,
  SettingsIcon,
  OptionBox,
} from 'ui-components';
import TransKeys from '../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {AnomalyMode} from '../../../../objects/models/homepage.model';
import {composition} from 'front-core';
import {
  ConfidenceIntervalConfig,
  UserSettings,
} from '../../../../objects/models/user-settings.model';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {updateUserSettings} from '../../../../store/settings/user-settings.actions';

interface OwnProps {
  onClose?: () => void;
}

type AllProps = OwnProps;

const THRESHOLD_OPTIONS = [0.05, 0.1, 0.2];
const DEFAULT_THRESHOLD = 0.1;
const thresholdOptions = {
  options: THRESHOLD_OPTIONS.map(v => ({value: v, label: `${v * 100}%`})),
};

const HomepageConfigurationPanelComponent: React.FC<AllProps> = (props: AllProps) => {
  const {onClose} = props;
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {userSettings} = useProductData();
  const [configuration, setConfiguration] = useState<UserSettings>(userSettings);
  const containerStyle = useMemo(() => ({height: '62rem', width: '74rem'}), []);
  const onChange = useCallback(
    (data: Partial<ConfidenceIntervalConfig>) => setConfiguration(prev => ({...prev, ...data})),
    [setConfiguration]
  );
  const onSubmit = useCallback(() => {
    dispatch(updateUserSettings(configuration, () => onClose()));
  }, [configuration, onClose, dispatch]);

  return (
    <div style={containerStyle}>
      <ModalLayout
        footer={
          <div className={classes.Footer}>
            <div className={classes.Info}>
              <CircleInfoRegularIcon />
              {t(TransKeys.HOMEPAGE_CONFIGURATION_PANEL.FOOTER.INFO)}
            </div>
            <div className={classes.Actions}>
              <Button onClick={onClose} variant={'outlined'}>
                {t(TransKeys.GENERAL.ACTIONS.CANCEL)}
              </Button>
              <Button onClick={onSubmit}>{t(TransKeys.GENERAL.ACTIONS.SAVE)}</Button>
            </div>
          </div>
        }
      >
        <div className={classes.HomepageConfigurationPanel}>
          <FancyHeader
            title={t(TransKeys.HOMEPAGE_CONFIGURATION_PANEL.TITLE)}
            icon={SettingsIcon}
            onClose={onClose}
            className={classes.Header}
          />
          <div className={classes.Content}>
            <div className={classes.SectionHeader}>
              <div className={classes.Title}>
                {t(TransKeys.HOMEPAGE_CONFIGURATION_PANEL.ANOMALY_MODE.TITLE)}
              </div>
              <div className={classes.SubTitle}>
                {t(TransKeys.HOMEPAGE_CONFIGURATION_PANEL.ANOMALY_MODE.SUB_TITLE)}
              </div>
            </div>
            <div className={classes.OptionsWrapper}>
              <OptionBox
                className={classes.Option}
                title={
                  <>
                    <LoopsIcon />
                    {t(TransKeys.HOMEPAGE_CONFIGURATION_PANEL.MODES.LOOPS_ALGO.TITLE)}
                    <Chip
                      label={t(TransKeys.GENERAL.LABELS.RECOMMENDED).toUpperCase()}
                      variant={ChipVariant.NEW}
                      size={'small'}
                    />
                  </>
                }
                description={t(TransKeys.HOMEPAGE_CONFIGURATION_PANEL.MODES.LOOPS_ALGO.DESCRIPTION)}
                selected={configuration.anomalyMode === AnomalyMode.LOOPS_ALGO}
                onSelect={() => onChange({anomalyMode: AnomalyMode.LOOPS_ALGO})}
              />
              <OptionBox
                className={classes.Option}
                title={t(TransKeys.HOMEPAGE_CONFIGURATION_PANEL.MODES.XOX_SAME_WEEKDAY.TITLE)}
                description={t(
                  TransKeys.HOMEPAGE_CONFIGURATION_PANEL.MODES.XOX_SAME_WEEKDAY.DESCRIPTION
                )}
                selected={configuration.anomalyMode === AnomalyMode.XOX_SAME_WEEKDAY}
                onSelect={() =>
                  onChange({
                    anomalyMode: AnomalyMode.XOX_SAME_WEEKDAY,
                    anomalyThreshold: DEFAULT_THRESHOLD,
                  })
                }
              >
                <LabelWrapper
                  label={t(TransKeys.HOMEPAGE_CONFIGURATION_PANEL.INPUTS.THRESHOLD.TITLE)}
                >
                  <div className={classes.ThresholdInfo}>
                    {t(TransKeys.HOMEPAGE_CONFIGURATION_PANEL.INPUTS.THRESHOLD.DESCRIPTION)}
                  </div>
                  <div onClick={e => e.stopPropagation()}>
                    <Select
                      value={configuration.anomalyThreshold}
                      options={thresholdOptions}
                      // @ts-ignore
                      onChange={(v: number) => onChange({anomalyThreshold: v})}
                      searchable={false}
                      clearable={false}
                      sortValues={false}
                      disabled={configuration.anomalyMode !== AnomalyMode.XOX_SAME_WEEKDAY}
                    />
                  </div>
                </LabelWrapper>
              </OptionBox>
            </div>
          </div>
        </div>
      </ModalLayout>
    </div>
  );
};

export const HomepageConfigurationPanel = composition<AllProps>(
  HomepageConfigurationPanelComponent
);

import {Divider} from '@material-ui/core';

import {withStopPropagation} from 'front-core';
import {TooltipIfOverflow} from 'ui-components';
import {
  Experiment,
  ExperimentType,
} from '../../../../../../../../../objects/models/experiment.model';
import {ListRowItemCell} from '../../../../../../../../shared/components/general/list-row-item-cell/list-row-item-cell.component';
import classes from './experiment-description-cell.module.scss';
import {ExperimentStatusIcon} from './status-icon/status-icon.component';

type ExperimentDescriptionCellProps = {
  experiment: Experiment;
  className?: string;
  onViewExperimentAutomation: (expAutomationId: number) => void;
};

export const ExperimentDescriptionCell = (props: ExperimentDescriptionCellProps) => {
  const {experiment, onViewExperimentAutomation, className} = props;

  const name = experiment.name;
  const title = experiment.type === ExperimentType.PRE_POST ? 'Release Analysis' : 'A/B Test';

  return (
    <ListRowItemCell
      title={
        <div className={classes.TitleWrapper}>
          <span>{title}</span>
          {experiment.experimentAutomation && (
            <>
              <Divider orientation="vertical" />
              <div className={classes.Automation}>
                <span>{'Automation: '}</span>
                <span
                  onClick={withStopPropagation(() =>
                    onViewExperimentAutomation(experiment.experimentAutomation.id)
                  )}
                  className={classes.AutomationName}
                >
                  {experiment.experimentAutomation.name}
                </span>
              </div>
            </>
          )}
        </div>
      }
      className={className}
      content={
        <>
          <div className={classes.Description}>
            <div className={classes.StatusIcon}>
              <ExperimentStatusIcon experiment={experiment} />
            </div>
            <TooltipIfOverflow title={name}>
              <span className={classes.Name}>{name}</span>
            </TooltipIfOverflow>
          </div>
        </>
      }
    />
  );
};

import {NRC} from './index';
import {RequestType} from 'front-core';
import {toCamelCase, toSnakeCase} from 'front-core';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';
import {getter} from '../store/store.utils';
import {CORE_STORE_KEY} from '../store/core/core.store';
import {DEMO_PRODUCT_ID} from '../constants/ui';
import {
  AddMetricToTeamDTO,
  GetRCAForMetricByDateDTO,
  MetricDTO,
  SetMetricOwnerDTO,
  UpdateTeamMetricsDTO,
} from '../objects/dto/metric.dto';

const isDemoProduct = () => getter(CORE_STORE_KEY, 'scopeProduct') === DEMO_PRODUCT_ID;

export const getMetricsNetworkRequest: NRC<void, any> = (payload: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/metrics`,
  query: payload,
  queryTransformer: query =>
    toFiltersDTO({
      isActive: isDemoProduct ? undefined : true,
      ...query,
    }),
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const getMetricsByCategoryNetworkRequest: NRC<void, any> = (payload: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/metrics/by-category`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data),
});

export const searchMetricsNetworkRequest: NRC<void, any> = (value: any, payload: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/metrics`,
  query: {q: value, ...payload},
  queryTransformer: toFiltersDTO,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data.data),
});

export const getMetricNetworkRequest: NRC<void, any> = (metricId: number, query: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/metrics/${metricId}`,
  query: query,
  queryTransformer: toSnakeCase,
  bodyTransformer: data => toSnakeCase(data, ['signal_definition', 'signal_population_filter']),
  responseTransformer: res =>
    toCamelCase(res.data, ['signal_definition', 'signal_population_filter']),
});

export const getMetricMaterialNetworkRequest: NRC<void, any> = (metricId: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/metrics/${metricId}/material`,
  bodyTransformer: data => toSnakeCase(data),
  responseTransformer: res => toCamelCase(res.data),
});

export const getMetricPageNetworkRequest: NRC<void, any> = (metricId: number, query: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/metrics/metric-page/${metricId}`,
  query: query,
  queryTransformer: toSnakeCase,
  bodyTransformer: data => toSnakeCase(data, ['signal_definition', 'signal_population_filter']),
  responseTransformer: res =>
    toCamelCase(res.data, ['signal_definition', 'signal_population_filter']),
});

export const getRCAForMetricByDate: NRC<void, any> = (data: GetRCAForMetricByDateDTO) => ({
  method: RequestType.GET,
  relativeUrl: `/app/metrics/metric-page/${data.metricId}/rca`,
  query: {date: data.date, granularity: data.granularity},
  queryTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const createMetricNetworkRequest: NRC<MetricDTO, void> = (data: MetricDTO) => ({
  method: RequestType.POST,
  relativeUrl: `/app/metrics`,
  body: data,
  bodyTransformer: data => toSnakeCase(data, ['signal_definition', 'signal_population_filter']),
  responseTransformer: res =>
    toCamelCase(res.data, ['signal_definition', 'signal_population_filter']),
});

export const editMetricNetworkRequest: NRC<MetricDTO, void> = (data: MetricDTO) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/metrics/${data.id}`,
  body: data,
  bodyTransformer: data => toSnakeCase(data, ['signal_definition', 'signal_population_filter']),
  responseTransformer: res =>
    toCamelCase(res.data, ['signal_definition', 'signal_population_filter']),
});

export const deleteMetricNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.DELETE,
  relativeUrl: `/app/metrics/${id}`,
  responseTransformer: toCamelCase,
});

export const publishMetricNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.POST,
  relativeUrl: `/app/metrics/${id}/publish`,
  responseTransformer: toCamelCase,
});

export const setMetricOwnerNetworkRequest: NRC<SetMetricOwnerDTO, void> = (
  data: SetMetricOwnerDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/metrics/${data.metricId}/owner`,
  body: data,
  bodyTransformer: data => toSnakeCase(data, ['signal_definition', 'signal_population_filter']),
  responseTransformer: res =>
    toCamelCase(res.data, ['signal_definition', 'signal_population_filter']),
});

export const setMetricNorthStarNetworkRequest: NRC<SetMetricOwnerDTO, void> = (id: number) => ({
  method: RequestType.POST,
  relativeUrl: `/app/metrics/${id}/north-star`,
  bodyTransformer: data => toSnakeCase(data, ['signal_definition', 'signal_population_filter']),
  responseTransformer: res =>
    toCamelCase(res.data, ['signal_definition', 'signal_population_filter']),
});

export const resampleMetricNetworkRequest: NRC<SetMetricOwnerDTO, void> = (id: number) => ({
  method: RequestType.POST,
  relativeUrl: `/app/metrics/${id}/resample`,
});

export const validateMetricNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/metrics/${id}/validate`,
  responseTransformer: res =>
    toCamelCase(res.data, ['signal_definition', 'signal_population_filter']),
});

export const getTeamMetricsNetworkRequest: NRC<void, void> = () => ({
  method: RequestType.GET,
  relativeUrl: `/app/metrics/team`,
  responseTransformer: res => toCamelCase(res.data),
});

export const updateTeamMetricsNetworkRequest: NRC<UpdateTeamMetricsDTO, void> = (
  data: UpdateTeamMetricsDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/metrics/team`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const addMetricToTeamNetworkRequest: NRC<AddMetricToTeamDTO, void> = (
  data: AddMetricToTeamDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/metrics/add-to-team`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

import * as React from 'react';
import classNames from 'classnames';
import classes from './left-navigation.module.scss';
import {NavLink as RouterNavLink} from 'react-router-dom';

interface NavProps {
  className?: string;
  children?: React.ReactNode;
}

interface NavLinkProps extends NavProps {
  to: string;
  chip?: string;
}

export const Nav: React.FC<NavProps> = (props: NavProps) => {
  const {className, children} = props;
  return <div className={classNames(className, classes.Navigator)}>{children}</div>;
};

export const NavTitle: React.FC<NavProps> = (props: NavProps) => {
  const {className, children} = props;
  return <div className={classNames(className, classes.Title)}>{children}</div>;
};

export const NavSubtitle: React.FC<NavProps> = (props: NavProps) => {
  const {className, children} = props;
  return <div className={classNames(className, classes.Subtitle)}>{children}</div>;
};

export const NavList: React.FC<NavProps> = (props: NavProps) => {
  const {className, children} = props;
  return <div className={classNames(className, classes.List)}>{children}</div>;
};

export const NavLink: React.FC<NavLinkProps> = (props: NavLinkProps) => {
  const {className, children, to, chip} = props;

  return (
    <RouterNavLink
      className={classNames(className, classes.Link)}
      activeClassName={classes.Active}
      to={to}
    >
      {children}
      {chip && <div className={classes.Chip}>{chip}</div>}
    </RouterNavLink>
  );
};

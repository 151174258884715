import {Epic} from 'redux-observable';
import {createRequestEpic} from 'front-core';
import {ActionKey} from '../../constants/action-key';
import HttpClient from '../../services/http-client.service';
import {loadNotificationsSuccess, NotificationsActionType} from './notifications.actions';
import {
  getNotificationsNetworkRequest,
  markNotificationAsReadNetworkRequest,
  markNotificationsAsReadNetworkRequest,
  markNotificationsAsSeenNetworkRequest,
} from '../../http/notifications.network-requests';

export const initNotificationsEpic: Epic = createRequestEpic(
  {
    types: [NotificationsActionType.LOAD_NOTIFICATIONS],
    actionKey: ActionKey.LOAD_NOTIFICATIONS,
    request: filters => getNotificationsNetworkRequest(filters),
    onSuccess: res => [loadNotificationsSuccess(res)],
    onError: err => [],
  },
  HttpClient
);

export const markNotificationsAsSeenEpic: Epic = createRequestEpic(
  {
    types: [NotificationsActionType.MARK_NOTIFICATIONS_AS_SEEN],
    actionKey: ActionKey.MARK_NOTIFICATIONS_AS_SEEN,
    request: markNotificationsAsSeenNetworkRequest,
    onSuccess: _ => [],
    onError: err => [],
  },
  HttpClient
);

export const markNotificationAsReadEpic: Epic = createRequestEpic(
  {
    types: [
      NotificationsActionType.MARK_NOTIFICATION_AS_READ,
      NotificationsActionType.MARK_NOTIFICATIONS_AS_READ,
    ],
    actionKey: ActionKey.MARK_NOTIFICATION_AS_READ,
    request: (payload, action) => {
      // mark single as read
      if (action.type === NotificationsActionType.MARK_NOTIFICATION_AS_READ) {
        return markNotificationAsReadNetworkRequest(payload);
      }
      // mark all as read
      return markNotificationsAsReadNetworkRequest();
    },
    onSuccess: _ => [],
    onError: err => [],
  },
  HttpClient
);

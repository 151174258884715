import {QuickRunAnalysesType} from '../../../../objects/models/analysis.model';

const DEFAULT_CONVERSION_CONFIG = {
  goal_drivers_on_feature_usage: true,
  goal_drivers_for_each_content: true,
  user_journey_on_feature_usage_sequence: true,
  user_journey_for_each_content_sequence: true,
  user_journey_on_feature_usage_combination: true,
  user_journey_for_each_content_combination: true,
  kpi_analysis_on_feature_usage: true,
};

const DEFAULT_RETENTION_CONFIG = {
  ...DEFAULT_CONVERSION_CONFIG,
  retention_analysis: true,
  habit_moment: true,
};

export const CONTENT_SELECTION_RELEVANT_TYPES = [
  QuickRunAnalysesType.PAYMENT_RETENTION,
  QuickRunAnalysesType.ACTIVE_RETENTION,
  QuickRunAnalysesType.CONVERSION_TO_PAID,
  QuickRunAnalysesType.CONVERSION_TO_TRIAL,
  QuickRunAnalysesType.TRIAL_TO_PAID,
  QuickRunAnalysesType.ALL_CONTENTS,
];

export const QUICK_RUN_ANALYSES_CONFIG = {
  [QuickRunAnalysesType.PAYMENT_RETENTION]: DEFAULT_RETENTION_CONFIG,
  [QuickRunAnalysesType.ACTIVE_RETENTION]: DEFAULT_RETENTION_CONFIG,
  [QuickRunAnalysesType.CONVERSION_TO_PAID]: DEFAULT_CONVERSION_CONFIG,
  [QuickRunAnalysesType.CONVERSION_TO_TRIAL]: DEFAULT_CONVERSION_CONFIG,
  [QuickRunAnalysesType.TRIAL_TO_PAID]: DEFAULT_CONVERSION_CONFIG,
  [QuickRunAnalysesType.ALL_FUNNELS]: {
    funnel_analysis_for_every_funnel: true,
  },
  [QuickRunAnalysesType.ALL_CONTENTS]: {
    content_funnel_for_every_content: true,
  },
  // [QuickRunAnalysesType.REVENUE_AOV]: {
  //   kpi_analysis_on_feature_usage: true,
  // },
};

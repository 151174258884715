import {Epic} from 'redux-observable';
import {createRequestEpic} from 'front-core';
import {ActionKey} from '../../constants/action-key';
import HttpClient from '../../services/http-client.service';
import {MetricCategoriesActionType} from './metric-categories.actions';
import {modelCreated, modelDeleted, modelUpdated, notifyEvent} from '../core/core.actions';
import {ModelKey} from '../../constants/model-key';
import {metricCategoryToastCreator} from '../toasts.actions';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {showToastMessage} from '../interface/interface.actions';
import {ToastType} from '../../objects/system/toast-type.enum';
import {demoProductLimitedActionsFilter, errorExtractor} from '../store.utils';
import {
  createMetricCategoryNetworkRequest,
  editMetricCategoryNetworkRequest,
  deleteMetricCategoryNetworkRequest,
  swapMetricCategoryNetworkRequest,
} from '../../http/metric-categories.network-requests';

export const createMetricCategoryEpic: Epic = createRequestEpic(
  {
    types: [MetricCategoriesActionType.CREATE_METRIC_CATEGORY],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.CREATE_METRIC_CATEGORY,
    request: createMetricCategoryNetworkRequest,
    onSuccess: metricCategory => [
      notifyEvent(AmplitudeEvent.METRIC_CATEGORY_CREATED, {id: metricCategory.id}),
      metricCategoryToastCreator('CREATE_SUCCESS'),
      modelCreated(metricCategory, ModelKey.METRIC_CATEGORY),
    ],
    onError: err_ => {
      const err = errorExtractor(err_);
      if (err.errorCode === 5002) {
        return [
          showToastMessage('Category name already is already taken', ToastType.ERROR),
          notifyEvent(AmplitudeEvent.CREATE_METRIC_CATEGORY_ERROR, err),
        ];
      }
      return [metricCategoryToastCreator('CREATE_ERROR')];
    },
  },
  HttpClient
);

export const updateMetricCategoryEpic: Epic = createRequestEpic(
  {
    types: [MetricCategoriesActionType.UPDATE_METRIC_CATEGORY],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.UPDATE_METRIC_CATEGORY,
    request: editMetricCategoryNetworkRequest,
    onSuccess: metricCategory => [
      notifyEvent(AmplitudeEvent.METRIC_CATEGORY_UPDATED, {id: metricCategory.id}),
      metricCategoryToastCreator('UPDATE_SUCCESS'),
      modelUpdated(metricCategory, ModelKey.METRIC_CATEGORY),
    ],
    onError: err_ => {
      const err = errorExtractor(err_);
      if (err.errorCode === 5002) {
        return [
          showToastMessage('Category name already is already taken', ToastType.ERROR),
          notifyEvent(AmplitudeEvent.UPDATE_METRIC_CATEGORY_ERROR, err),
        ];
      }
      return [metricCategoryToastCreator('UPDATE_ERROR')];
    },
  },
  HttpClient
);

export const swapMetricCategoryEpic: Epic = createRequestEpic(
  {
    types: [MetricCategoriesActionType.SWAP_METRIC_CATEGORY],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.SWAP_METRIC_CATEGORY,
    request: swapMetricCategoryNetworkRequest,
    onSuccess: (res, payload) => [
      notifyEvent(AmplitudeEvent.METRIC_CATEGORY_UPDATED, {id: payload.metricCategoryId}),
      metricCategoryToastCreator('UPDATE_SUCCESS'),
      modelUpdated({id: payload.metricCategoryId}, ModelKey.METRIC_CATEGORY),
    ],
    onError: err_ => {
      return [metricCategoryToastCreator('UPDATE_ERROR')];
    },
  },
  HttpClient
);

export const deleteMetricCategoryEpic: Epic = createRequestEpic(
  {
    types: [MetricCategoriesActionType.DELETE_METRIC_CATEGORY],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.DELETE_METRIC_CATEGORY,
    request: deleteMetricCategoryNetworkRequest,
    onSuccess: (res, payload) => [
      notifyEvent(AmplitudeEvent.METRIC_CATEGORY_DELETED, {id: payload}),
      metricCategoryToastCreator('DELETE_SUCCESS'),
      modelDeleted(payload, ModelKey.METRIC_CATEGORY),
    ],
    onError: err => [metricCategoryToastCreator('DELETE_ERROR')],
  },
  HttpClient
);

import {NRC} from './index';
import {RequestType} from 'front-core';
import {toCamelCase} from 'front-core';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';

export const getNotificationsNetworkRequest: NRC<void, any> = query => ({
  method: RequestType.GET,
  relativeUrl: `/app/notifications`,
  query,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data),
});

export const markNotificationsAsSeenNetworkRequest: NRC<void, any> = () => ({
  method: RequestType.POST,
  relativeUrl: `/app/notifications/seen`,
});

export const markNotificationsAsReadNetworkRequest: NRC<void, any> = () => ({
  method: RequestType.POST,
  relativeUrl: `/app/notifications/read`,
});

export const markNotificationAsReadNetworkRequest: NRC<void, any> = (id: number) => ({
  method: RequestType.POST,
  relativeUrl: `/app/notifications/${id}/read`,
});

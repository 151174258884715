import * as React from 'react';
import {useCallback} from 'react';
import {composition} from 'front-core';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {getFunnelNetworkRequest} from '../../../../http/funnels.network-requests';
import {FUNNEL_ID_PATH_PARAM, SIGNAL_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {
  Button,
  FancyHeader,
  FeatureIcon,
  FunnelIcon,
  LabelWrapper,
  ModalLayout,
  ModelType,
} from 'ui-components';
import TransKeys from '../../../../constants/translation-keys';
import {Funnel} from '../../../../objects/models/funnel.model';
import classes from './funnel-view-panel.module.scss';
import {useTranslation} from 'react-i18next';
import {get} from 'lodash';
import {Modifiers} from '../../../shared/components/general/modifiers/modifiers.component';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {TitleWithIcon} from '../../../shared/components/general/title/title.component';
import {withModalErrorHandler} from '../../../../core/hoc/with-model-error-handler.hoc';
import {AdHocIndication} from '../../../shared/components/general/ad-hoc-indication/ad-hoc-indication.component';
import {useDispatch} from 'react-redux';
import {publishAdHocFunnelConfirmed} from '../../../../store/funnels/funnels.actions';
import {getSelected} from '../../../../store/selected/selected.actions';
import usePermissions from '../../../../core/hooks/use-permissions.hook';
import {Action, Subject} from '../../../../constants/permissions';
import {QuerySqlTabsNames} from '../../../shared/core/query-sql-tabs/query-sql-tabs.component';
import {getEntityIcon} from '../../../../constants/entity.consts';
import {FunnelStepsDefinition} from '../../../../modules/funnels/components/funnel-steps-definition/funnel-steps-definition.component';

interface OwnProps {
  funnel: Funnel;
  onClose?: () => void;
  [SIGNAL_ID_PATH_PARAM]?: number;
  initialTab?: QuerySqlTabsNames;
}

type AllProps = OwnProps;

const SELECTED_FUNNEL_KEY = SharedSelectionKeys.FUNNEL_VIEW__FUNNEL;

export const FunnelViewPanelComponent: React.FC<AllProps> = (props: AllProps) => {
  const {onClose, funnel, [SIGNAL_ID_PATH_PARAM]: signalId, initialTab} = props;
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {can} = usePermissions();
  const {productEntitiesMap} = useProductData();

  const onPublish = useCallback(
    () =>
      dispatch(
        publishAdHocFunnelConfirmed(funnel?.id, () => [getSelected(SELECTED_FUNNEL_KEY, funnel.id)])
      ),
    [dispatch, funnel?.id]
  );

  return (
    <div className={classes.FunnelViewPanelContainer}>
      <ModalLayout
        footer={
          funnel.isAdHoc &&
          can(Subject.FUNNEL, Action.CREATE) && (
            <Button
              caps={false}
              helperText={t(TransKeys.GENERAL.ACTIONS.SAVE_TO_DMP_HELPER_TEXT, {
                model: t(TransKeys.MODELS.FUNNEL),
              })}
              onClick={onPublish}
            >
              {t(TransKeys.GENERAL.ACTIONS.SAVE_TO_DMP, {model: t(TransKeys.MODELS.FUNNEL)})}
            </Button>
          )
        }
      >
        <div className={classes.FunnelViewPanel}>
          <FancyHeader
            icon={FunnelIcon}
            title={
              <>
                {t(TransKeys.MODELS.FUNNEL)}
                {funnel.isAdHoc && (
                  <AdHocIndication className={classes.AdHocIndication} model={ModelType.FUNNEL} />
                )}
              </>
            }
            caps
            onClose={onClose}
            className={classes.Header}
          />
          <div className={classes.Body}>
            <div className={classes.Block}>
              <div className={classes.Name}>{funnel.name}</div>
              {funnel.shortDescription && (
                <div className={classes.Description}>{funnel.shortDescription}</div>
              )}
            </div>
            {funnel.entity && (
              <div className={classes.InlineBlock}>
                <LabelWrapper label={t(TransKeys.GENERAL.LABELS.ENTITY)} fullWidth={false}>
                  <TitleWithIcon
                    text={productEntitiesMap[funnel.entity].name}
                    icon={getEntityIcon(funnel.entity)}
                  />
                </LabelWrapper>
                <div className={classes.Spacer} />
                <LabelWrapper
                  label={t(TransKeys.FUNNEL_VIEW_PANEL.LABELS.BOUNDING_DAYS)}
                  fullWidth={false}
                >
                  <div className={classes.BoundingValue}>
                    <span>{funnel.defaultBoundingDays}</span>
                    <span>Days</span>
                  </div>
                </LabelWrapper>
              </div>
            )}
            <div className={classes.Block}>
              <LabelWrapper label={t(TransKeys.GENERAL.LABELS.STEPS)}>
                <FunnelStepsDefinition
                  funnel={funnel}
                  signalId={signalId}
                  initialTab={initialTab}
                />
              </LabelWrapper>
            </div>
            {funnel.history.length > 0 && (
              <div className={classes.Block}>
                <Modifiers history={funnel.history} />
              </div>
            )}
          </div>
        </div>
      </ModalLayout>
    </div>
  );
};

const FunnelViewPanel = composition<AllProps>(
  FunnelViewPanelComponent,
  withModalErrorHandler({
    modalTitle: TransKeys.MODELS.FUNNEL,
    modalIcon: FeatureIcon,
    extractErrorCodeFromProps: props => get(props.errors, 'funnel.errorCode'),
  }),
  withLoadBefore({
    funnel: {
      selectedKey: SELECTED_FUNNEL_KEY,
      actionKey: SELECTED_FUNNEL_KEY,
      request: getFunnelNetworkRequest,
      mapPayloadFromProps: props => props[FUNNEL_ID_PATH_PARAM],
      shouldCall: props => props[FUNNEL_ID_PATH_PARAM] !== undefined,
    },
  })
);

export default FunnelViewPanel;

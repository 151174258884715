import * as React from 'react';
import {useContext, useEffect} from 'react';
import classNames from 'classnames';
import classes from '../../analysis-forms.module.scss';
import {TimeFrameSelector} from '../../components/ui-selectors/time-frame-selector/time-frame-selector.component';
import {ParametersFormContext} from '../../../../shared/core/parameters-form/parameters-form.context';
import {startEndDatesDefaultHandler} from '../../../../shared/core/parameters-form/parameters-form-default-handlers.utils';
import {SegmentFilterSelector} from '../../components/ui-selectors/segment-filter-selector/segment-filter-selector.component';
import {SimpleOptionsSelector} from '../../components/ui-selectors/simple-options-selector/simple-options-selector.component';
import {DaysCountSelector} from '../../components/ui-selectors/days-count-selector/days-count-selector.component';
import {AnalysisFormProps} from '../../analysis-forms.types';
import {TreatmentSelector} from '../../components/ui-selectors/treatment-selector/treatment-selector.component';
import {TableEntity} from '../../../../../objects/models/table.model';

const OPTIONS = [
  {value: 'L7', label: '7 days (L7)'},
  {value: 'L28', label: '28 days (L28)'},
];

const ENGAGEMENT_TYPE_SCHEMA_KEYS = {
  option_key: 'engagement_type',
};
const ADOPTION_PERIOD_SCHEMA_KEYS = {
  days_count_key: 'adoption_days',
};
const treatmentsSelectorMap = {
  treatments_tag: 'feature_tag',
  treatments_signals: 'treatments_signals',
};

export const Analysis89Form: React.FC<AnalysisFormProps> = (props: AnalysisFormProps) => {
  const {className} = props;
  const {errors, parameters, changeParametersValue, registerDefaultHandler, removeDefaultHandler} =
    useContext(ParametersFormContext);

  useEffect(() => {
    registerDefaultHandler('start_end_dates', startEndDatesDefaultHandler);
    registerDefaultHandler('analysis_89', parameters => {
      const defaults: any = {
        [ADOPTION_PERIOD_SCHEMA_KEYS.days_count_key]: 7,
      };
      return defaults;
    });

    return () => {
      removeDefaultHandler('start_end_dates');
      removeDefaultHandler('analysis_89');
    };
  }, [registerDefaultHandler, removeDefaultHandler]);

  return (
    <div className={classNames(classes.AnalysisForm, className)}>
      <SimpleOptionsSelector
        title={'Engagement units'}
        subTitle={'Set the engagement unit'}
        helperText={
          'The engagement will be calculate as the average of the number of distinct days of usage within the engagement unit.'
        }
        className={classes.Parameter}
        options={OPTIONS}
        schemaKeysMapping={ENGAGEMENT_TYPE_SCHEMA_KEYS}
        value={parameters}
        errors={errors}
        onChange={changeParametersValue}
      />
      <DaysCountSelector
        title={'Adoption period'}
        subTitle={'Set the time window user has to adopt the feature.'}
        className={classes.Parameter}
        schemaKeysMapping={ADOPTION_PERIOD_SCHEMA_KEYS}
        value={parameters}
        errors={errors}
        onChange={changeParametersValue}
      />
      <TreatmentSelector
        value={parameters}
        onChange={changeParametersValue}
        className={classes.Parameter}
        schemaKeysMapping={treatmentsSelectorMap}
        errors={errors}
        entityContext={TableEntity.USER}
      />
      <TimeFrameSelector className={classes.Parameter} errors={errors} />
      <SegmentFilterSelector
        onChange={changeParametersValue}
        value={parameters}
        className={classes.Parameter}
        errors={errors}
      />
    </div>
  );
};

import {
  DoorOpenDuotoneIcon,
  HandPointerDuotoneIcon,
  KeySkeletonDuotoneIcon,
  MagnetDuotoneIcon,
  SquareDollarDuotoneIcon,
  GolfFlagHoleDuotoneIcon,
} from 'ui-components';
import sharedClasses from '../questionnaire.module.scss';
import TransKeys from 'translations';

export enum UserKPITypeOption {
  CONVERSION = 'conversion',
  ENGAGEMENT = 'engagement',
  MONETIZATION = 'monetization',
  RETENTION = 'retention',
  ACTIVATION = 'activation',
  ACQUISITION = 'acquisition',
  CHURN = 'churn',
}

export const KPI_TYPES_OPTIONS = [
  {
    icon: GolfFlagHoleDuotoneIcon,
    className: sharedClasses.ConversionIcon,
    label: TransKeys.QUESTIONNAIRE.KPIS_STEP.OPTIONS.CONVERSION,
    value: UserKPITypeOption.CONVERSION,
  },
  {
    icon: HandPointerDuotoneIcon,
    className: sharedClasses.EngagementIcon,
    label: TransKeys.QUESTIONNAIRE.KPIS_STEP.OPTIONS.ENGAGEMENT,
    value: UserKPITypeOption.ENGAGEMENT,
  },
  {
    icon: SquareDollarDuotoneIcon,
    className: sharedClasses.MonetizationIcon,
    label: TransKeys.QUESTIONNAIRE.KPIS_STEP.OPTIONS.MONETIZATION,
    value: UserKPITypeOption.MONETIZATION,
  },
  {
    icon: MagnetDuotoneIcon,
    className: sharedClasses.RetentionIcon,
    label: TransKeys.QUESTIONNAIRE.KPIS_STEP.OPTIONS.RETENTION,
    value: UserKPITypeOption.RETENTION,
  },
  {
    icon: KeySkeletonDuotoneIcon,
    className: sharedClasses.ActivationIcon,
    label: TransKeys.QUESTIONNAIRE.KPIS_STEP.OPTIONS.ACTIVATION,
    value: UserKPITypeOption.ACTIVATION,
  },
  // {
  //   icon: ShoppingCartDuotoneIcon,
  //   className: sharedClasses.AcquisitionIcon,
  //   label: TransKeys.QUESTIONNAIRE.KPIS_STEP.OPTIONS.ACQUISITION,
  //   value: UserKPITypeOption.ACQUISITION,
  // },
  {
    icon: DoorOpenDuotoneIcon,
    className: sharedClasses.ChurnIcon,
    label: TransKeys.QUESTIONNAIRE.KPIS_STEP.OPTIONS.CHURN,
    value: UserKPITypeOption.CHURN,
  },
];

import {ContentDTO} from '../../objects/dto/content.dto';
import {action, OnSuccessActionHook} from 'front-core';
import {showConfirmationMessage} from '../interface/interface.actions';
import i18n from '../../config/i18n.config';
import TransKeys from '../../constants/translation-keys';

export enum ContentActionType {
  CREATE_CONTENT = '@@content/CREATE_CONTENT',
  UPDATE_CONTENT = '@@content/UPDATE_CONTENT',
  DELETE_CONTENT = '@@content/DELETE_CONTENT',
  RESCAN_CONTENT = '@@content/RESCAN_SEGMENT',
}

export const createContent = (data: ContentDTO, onSuccess?: OnSuccessActionHook) =>
  action(ContentActionType.CREATE_CONTENT, data, {onSuccess});

export const updateContent = (data: ContentDTO, onSuccess?: OnSuccessActionHook) =>
  action(ContentActionType.UPDATE_CONTENT, data, {onSuccess});

export const deleteContent = (id: number) => action(ContentActionType.DELETE_CONTENT, id);

export const deleteContentConfirmed = (id: number) =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.DELETE_CONTENT.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.DELETE_CONTENT.CONTENT),
    },
    () => [deleteContent(id)]
  );

export const rescanContent = (id: number) => action(ContentActionType.RESCAN_CONTENT, id);

export const rescanContentConfirmed = (id: number) =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.RESCAN_CONTENT.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.RESCAN_CONTENT.CONTENT),
    },
    () => [rescanContent(id)]
  );

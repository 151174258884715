import {NRC} from './index';
import {RequestType, toSnakeCase} from 'front-core';
import {toCamelCase} from 'front-core';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';
import {ContentDTO} from '../objects/dto/content.dto';

const exceptKeys = [
  'usage_signal_definition',
  'exposure_signal_definition',
  'intent_signal_definition',
  'activation_signal_definition',
  'churn_signal_definition',
];

export const getContentNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/contents/${id}`,
  responseTransformer: res => toCamelCase(res.data, ['definition']),
});

export const searchContentsNetworkRequest: NRC<void, any> = (value: any, payload: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/contents`,
  query: {q: value, ...payload},
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data.data),
});

export const getContentsNetworkRequest: NRC<void, any> = query => ({
  method: RequestType.GET,
  relativeUrl: `/app/contents`,
  query: query,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data),
});
export const createContentNetworkRequest: NRC<ContentDTO, void> = (data: ContentDTO) => ({
  method: RequestType.POST,
  relativeUrl: `/app/contents`,
  body: data,
  bodyTransformer: data => toSnakeCase(data, exceptKeys),
  responseTransformer: res => toCamelCase(res.data, exceptKeys),
});
export const editContentNetworkRequest: NRC<ContentDTO, void> = (data: ContentDTO) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/contents/${data.id}`,
  body: data,
  bodyTransformer: data => toSnakeCase(data, exceptKeys),
  responseTransformer: res => toCamelCase(res.data, exceptKeys),
});
export const deleteContentNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.DELETE,
  relativeUrl: `/app/contents/${id}`,
  responseTransformer: toCamelCase,
});

export const rescanContentNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.POST,
  relativeUrl: `/app/contents/${id}/rescan`,
  responseTransformer: toCamelCase,
});

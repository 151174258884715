import * as React from 'react';
import classNames from 'classnames';
import classes from './options-tabs.module.scss';
import {useMemo} from 'react';
import {HoverHelperTip} from 'ui-components';
import {StandardCheckBox} from '../standard-check-box/standard-check-box.component';

export interface OptionTab {
  key: string;
  title: string;
  helperText?: string;
  error?: boolean;
  disabled?: boolean;
  render: () => JSX.Element;
}

interface OwnProps {
  className?: string;
  tabs: OptionTab[];
  selectedKey: string;
  onTabChange: (key: string) => void;
  renderTabTitle?: (tab: OptionTab) => JSX.Element;
  renderEmptyState?: () => JSX.Element;
}

type AllProps = OwnProps;

export const OptionTabs: React.FC<AllProps> = (props: AllProps) => {
  const {tabs, selectedKey, onTabChange, renderEmptyState, renderTabTitle, className} = props;
  const activeTab = useMemo(() => tabs.find(t => t.key === selectedKey), [tabs, selectedKey]);

  return (
    <div className={classNames(classes.OptionTabs, className)}>
      <div className={classes.Tabs}>
        {tabs.map(t => (
          <div
            key={t.key}
            onClick={() => !t.disabled && onTabChange(t.key)}
            className={classNames(
              classes.Tab,
              t.error && classes.Error,
              t.disabled && classes.Disabled,
              t.key === selectedKey && classes.Selected
            )}
          >
            <StandardCheckBox
              className={classes.Checkbox}
              checked={t.key === selectedKey}
              radio
              disabled={t.disabled}
            />
            <div className={classes.Title}>{(renderTabTitle && renderTabTitle(t)) || t.title}</div>
            {t.helperText && <HoverHelperTip className={classes.HelperText} title={t.helperText} />}
          </div>
        ))}
      </div>
      <div className={classes.Content}>
        {activeTab && activeTab.render()}
        {!activeTab && renderEmptyState && renderEmptyState()}
      </div>
    </div>
  );
};

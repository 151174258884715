import {NRC} from './index';
import {RequestType, toCamelCase} from 'front-core';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';

export const getTableColumnNetworkRequest: NRC<void, any> = (id: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/table-columns/${id}`,
  responseTransformer: res => toCamelCase(res.data),
});

export const getAllTableColumnsNetworkRequest: NRC<void, any> = (payload: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/table-columns`,
  query: {...payload, limit: 1000},
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data.data),
});

import {action} from 'front-core';
import {SelectedConfig} from './selected.types';

export enum SelectedActionType {
  CREATE_SELECTED = '@@selected/CREATE_SELECTED',
  UPSERT_SELECTED = '@@selected/UPSERT_SELECTED',
  GET_SELECTED = '@@selected/GET_SELECTED',
  REMOVE_SELECTED = '@@selected/REMOVE_SELECTED',
}

export const createSelected = (config: SelectedConfig) =>
  action(SelectedActionType.CREATE_SELECTED, config);

export const upsertSelected = (selectedKey: string, data: any, append = false) =>
  action(SelectedActionType.UPSERT_SELECTED, {selectedKey, data, append});

export const getSelected = (selectedKey: string, payload: any = null, ...args: any[]) =>
  action(SelectedActionType.GET_SELECTED, {selectedKey, payload, args: args || []});

export const removeSelected = (selectedKey: string) =>
  action(SelectedActionType.REMOVE_SELECTED, selectedKey);

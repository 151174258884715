import {NRC} from './index';
import {RequestType} from 'front-core';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';
import {toCamelCase, toSnakeCase} from 'front-core';
import {ExperimentFiltersDTO} from '../objects/dto/experiment-filters.dto';
import {
  ExperimentDTO,
  SetExperimentOwnerDTO,
  SetExperimentTeamDTO,
} from '../objects/dto/experiment.dto';
import {ExperimentSubscriptionDTO} from '../objects/dto/experiment-subscription.dto';

export const getExperimentNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/experiments/${id}`,
  responseTransformer: res => toCamelCase(res.data, ['results', 'preview']),
});

export const getExperimentDataNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/experiments/${id}/data`,
  responseTransformer: res => toCamelCase(res.data, ['run_parameters', 'parameters']),
});

export const getExperimentCountsNetworkRequest: NRC<void, ExperimentFiltersDTO> = (
  query: ExperimentFiltersDTO
) => ({
  method: RequestType.GET,
  relativeUrl: `/app/experiments/counts`,
  query,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => res.data,
});

export const getExperimentsNetworkRequest: NRC<void, ExperimentFiltersDTO> = (
  payload: ExperimentFiltersDTO
) => ({
  method: RequestType.GET,
  relativeUrl: `/app/experiments`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data),
});

export const createExperimentNetworkRequest: NRC<ExperimentDTO, void> = (data: ExperimentDTO) => ({
  method: RequestType.POST,
  relativeUrl: `/app/experiments`,
  body: data,
  bodyTransformer: body => toSnakeCase(body, ['parameters', 'runParameters']),
  responseTransformer: res => toCamelCase(res.data),
});

export const patchExperimentNetworkRequest: NRC<ExperimentDTO, void> = (data: ExperimentDTO) => ({
  method: RequestType.PATCH,
  relativeUrl: `/app/experiments/${data.id}`,
  body: data,
  bodyTransformer: body => toSnakeCase(body, ['parameters', 'runParameters']),
  responseTransformer: res => toCamelCase(res.data),
});

export const updateExperimentNetworkRequest: NRC<ExperimentDTO, void> = (data: ExperimentDTO) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/experiments/${data.id}`,
  body: data,
  bodyTransformer: body => toSnakeCase(body, ['parameters', 'runParameters']),
  responseTransformer: res => toCamelCase(res.data),
});

export const deleteExperimentNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.DELETE,
  relativeUrl: `/app/experiments/${id}`,
  responseTransformer: toCamelCase,
});

export const setExperimentOwnerNetworkRequest: NRC<SetExperimentOwnerDTO, void> = (
  data: SetExperimentOwnerDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/experiments/${data.experimentId}/owner`,
  body: data,
  bodyTransformer: data => toSnakeCase(data),
  responseTransformer: res => toCamelCase(res.data),
});

export const setExperimentTeamNetworkRequest: NRC<SetExperimentTeamDTO, void> = (
  data: SetExperimentTeamDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/experiments/${data.experimentId}/team`,
  body: data,
  bodyTransformer: data => toSnakeCase(data),
  responseTransformer: res => toCamelCase(res.data),
});

export const createExperimentSubscriptionNetworkRequest: NRC<ExperimentSubscriptionDTO, any> = (
  payload: ExperimentSubscriptionDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/experiments/${payload.experimentId}/subscribe`,
  body: payload,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const editExperimentSubscriptionNetworkRequest: NRC<ExperimentSubscriptionDTO, any> = (
  payload: ExperimentSubscriptionDTO
) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/experiments/${payload.experimentId}/subscribe`,
  body: payload,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const deleteExperimentSubscriptionNetworkRequest: NRC<void, void> = (
  payload: ExperimentSubscriptionDTO
) => ({
  method: RequestType.DELETE,
  relativeUrl: `/app/experiments/${payload.experimentId}/subscribe`,
  queryTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const rerunExperimentLastCompletedAnalysisResultNetworkRequest: NRC<void, void> = (
  id: number
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/experiments/${id}/re-run`,
  responseTransformer: toCamelCase,
});

import {Epic} from 'redux-observable';
import {createRequestEpic} from 'front-core';
import {ActionKey} from '../../constants/action-key';
import HttpClient from '../../services/http-client.service';
import {modelCreated, modelDeleted, notifyEvent} from '../core/core.actions';
import {ModelKey} from '../../constants/model-key';
import {goalToastCreator} from '../toasts.actions';
import {GoalsActionType} from './goals.actions';
import {
  createGoalNetworkRequest,
  deleteGoalNetworkRequest,
  updateGoalNetworkRequest,
} from '../../http/goals.network-requests';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {demoProductLimitedActionsFilter} from '../store.utils';

export const createGoalEpic: Epic = createRequestEpic(
  {
    types: [GoalsActionType.CREATE_GOAL],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.CREATE_GOAL,
    request: createGoalNetworkRequest,
    onSuccess: goal => [
      notifyEvent(AmplitudeEvent.GOAL_CREATED, {id: goal.id}),
      goalToastCreator('CREATE_SUCCESS'),
      modelCreated(goal, ModelKey.GOAL),
    ],
    onError: err => [goalToastCreator('CREATE_ERROR')],
  },
  HttpClient
);

export const updateGoalEpic: Epic = createRequestEpic(
  {
    types: [GoalsActionType.UPDATE_GOAL],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.UPDATE_GOAL,
    request: updateGoalNetworkRequest,
    onSuccess: goal => [
      notifyEvent(AmplitudeEvent.GOAL_UPDATED, {id: goal.id}),
      goalToastCreator('UPDATE_SUCCESS'),
      modelCreated(goal, ModelKey.GOAL),
    ],
    onError: err => [goalToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);

export const deleteGoalEpic: Epic = createRequestEpic(
  {
    types: [GoalsActionType.DELETE_GOAL],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.DELETE_GOAL,
    request: deleteGoalNetworkRequest,
    onSuccess: (res, payload) => [
      notifyEvent(AmplitudeEvent.GOAL_DELETED, {id: payload}),
      goalToastCreator('DELETE_SUCCESS'),
      modelDeleted(payload, ModelKey.GOAL),
    ],
    onError: err => [goalToastCreator('DELETE_ERROR')],
  },
  HttpClient
);

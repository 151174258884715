import * as React from 'react';
import {HealthMonitorRouting} from './health-monitor-routing.component';

interface OwnProps {}

type AllProps = OwnProps;

const HealthMonitor: React.FC<AllProps> = (props: AllProps) => {
  return <HealthMonitorRouting />;
};

export default HealthMonitor;

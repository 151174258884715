import * as React from 'react';
import {useDemoProduct} from '../hooks/use-demo-product.hook';

interface WithDisableDemoProductInjectedProps {
  disabled?: boolean;
}

export function withDisableDemoProduct<P>(
  WrappedComponent: React.ComponentType<P & WithDisableDemoProductInjectedProps>
): React.ComponentType<P & WithDisableDemoProductInjectedProps> {
  const WithDisableDemoProduct: React.FC<any> = (
    props: P & WithDisableDemoProductInjectedProps
  ) => {
    const {isDemoProduct} = useDemoProduct();
    if (!isDemoProduct) {
      return <WrappedComponent {...props} />;
    }
    return <WrappedComponent {...props} disabled={true} />;
  };

  const wrappedComponentName =
    WrappedComponent.displayName || (WrappedComponent as any).name || 'Component';
  (WithDisableDemoProduct as any).displayName = `WithDisableDemoProduct(${wrappedComponentName})`;

  return WithDisableDemoProduct;
}

import * as React from 'react';
import {
  TreatmentAdoptionSimulationFigure,
  TreatmentAdoptionSimulationItemType,
  calculateMaxPotential,
  HoverHelperTip,
  TooltipIfOverflow,
  AnalysisAlgoMode,
  TrendChip,
  InteractionType,
  ModelType,
  InteractionContext,
  FlexibleTable,
} from 'ui-components';
import classes from './drivers.module.scss';
import {AnalysisResult} from '../../../../../../../objects/models/analysis-result.model';
import {useCallback, useContext, useMemo} from 'react';
import {capitalize, get, take} from 'lodash';
import {exists, number2k, safeDivision} from 'front-core';
import pluralize from 'pluralize';

interface OwnProps {
  feature: TreatmentAdoptionSimulationFigure;
  analysisResult: AnalysisResult;
  className?: string;
}

const TYPE_MAPPING = {
  [TreatmentAdoptionSimulationItemType.POSITIVE]: TreatmentAdoptionSimulationItemType.POSITIVE,
  [TreatmentAdoptionSimulationItemType.NEGATIVE]: TreatmentAdoptionSimulationItemType.NEGATIVE,
  [TreatmentAdoptionSimulationItemType.NO_RECOMMENDATION]:
    TreatmentAdoptionSimulationItemType.NO_RECOMMENDATION,
  [TreatmentAdoptionSimulationItemType.INSIGNIFICANT_POSITIVE]:
    TreatmentAdoptionSimulationItemType.INSIGNIFICANT,
  [TreatmentAdoptionSimulationItemType.INSIGNIFICANT_NEGATIVE]:
    TreatmentAdoptionSimulationItemType.INSIGNIFICANT,
};

type AllProps = OwnProps;

const MAX_ITEMS_TO_SHOW = 20;

export const TreatmentAdoption: React.FC<AllProps> = (props: AllProps) => {
  const {feature, className} = props;
  const {data, options} = feature;
  const {postMessage} = useContext(InteractionContext);

  const items = useMemo(() => {
    const res = data.items
      .map(i => {
        let item: any = {
          ...i,
          name: i.treatmentName[0],
          signalId: Number(get(i.treatmentCommand[0], 'payload.refId')),
          uplift: safeDivision(
            i.goalInAdopters - i.goalInNonAdopters,
            Math.abs(i.goalInNonAdopters)
          ),
          type: TYPE_MAPPING[i.type],
          simulationType: TYPE_MAPPING[i.type],
          value: i.adoption,
          insignificant: TYPE_MAPPING[i.type] === TreatmentAdoptionSimulationItemType.INSIGNIFICANT,
        };
        item = {
          ...item,
          ...calculateMaxPotential(data.goalValue, item),
        };
        item = {
          ...item,
          potentialDiff: safeDivision(item.maxPotential - data.goalValue, data.goalValue),
        };
        return item;
      })
      .filter(i => {
        const a =
          data.mode === AnalysisAlgoMode.CAUSATION ? exists(i.maxPotential) : exists(i.uplift);
        const b = i.type !== TreatmentAdoptionSimulationItemType.NO_RECOMMENDATION;
        return a && b;
      })
      .sort((a, b) =>
        data.mode === AnalysisAlgoMode.CAUSATION
          ? (b.maxPotential || 0) - (a.maxPotential || 0)
          : (b.uplift || 0) - (a.uplift || 0)
      );
    return take(res, MAX_ITEMS_TO_SHOW);
  }, [data]);
  const onTreatmentClicked = useCallback(
    item =>
      postMessage({
        type: InteractionType.REFERENCE,
        payload: {
          modelId: item.signalId,
          modelType: ModelType.SIGNAL,
        },
      }),
    [postMessage]
  );
  const columns = useMemo(
    () => [
      {
        key: 'treatmentName',
        title: capitalize(options.treatmentModelName),
        weight: 2,
        sortable: true,
        render: i => (
          <div className={classes.Name}>
            <TooltipIfOverflow title={i.treatmentName}>
              <span className={classes.Text} onClick={() => onTreatmentClicked(i)}>
                {i.treatmentName}
              </span>
            </TooltipIfOverflow>
            {(i.info || i.helperText) && <HoverHelperTip title={i.info || i.helperText} small />}
          </div>
        ),
      },
      {
        key: 'adoption',
        title: 'Adoption rate',
        weight: 1,
        sortable: true,
        render: i => (
          <div className={classes.Data}>
            <span>{number2k(i.adoption * 100)}%</span>
            <span className={classes.Abs}>
              ({number2k(i.adoptionAbs)} {pluralize(options.entity)})
            </span>
          </div>
        ),
      },
      {
        key: 'goalInAdopters',
        title: 'KPI in adopters',
        weight: 1,
        sortable: true,
        render: i => (
          <div className={classes.Data}>
            {options.isPercentageValue === true && <>{number2k(i.goalInAdopters * 100)}%</>}
            {options.isPercentageValue === false && <>{number2k(i.goalInAdopters)}</>}
          </div>
        ),
      },
      {
        key: 'maxPotential',
        title: 'Max Potential',
        weight: 1,
        sortable: true,
        hidden: data.mode === AnalysisAlgoMode.CORRELATION,
        render: i => (
          <div className={classes.Data}>
            {options.isPercentageValue === true && i.maxPotential && (
              <>{number2k(i.maxPotential * 100)}%</>
            )}
            {options.isPercentageValue === false && i.maxPotential && (
              <>{number2k(i.maxPotential)}</>
            )}
            <span className={classes.PotentialDiff}>(+{number2k(i.potentialDiff * 100)}%)</span>
          </div>
        ),
      },
      {
        key: 'uplift',
        title: 'Uplift',
        weight: 1,
        sortable: true,
        hidden: data.mode === AnalysisAlgoMode.CAUSATION,
        render: i => (
          <div className={classes.Data}>
            <TrendChip value={i.uplift * 100} size={'small'} />
          </div>
        ),
      },
    ],
    [options, onTreatmentClicked, data.mode]
  );

  return <FlexibleTable className={className} columns={columns} data={items} />;
};

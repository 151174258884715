import {NRC} from './index';
import {exists, RequestType, toCamelCase, toSnakeCase} from 'front-core';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';
import {
  HealthMonitorGroupDTO,
  HealthMonitorInitializeDTO,
  MonitoredMetricDTO,
  RemoveHealthMonitorGroupDTO,
} from '../objects/dto/health-monitor.dto';
import {HealthMonitorSubscriptionDTO} from '../objects/dto/health-monitor-subscription.dto';

export const getHealthMonitorNetworkRequest: NRC<void, any> = payload => ({
  method: RequestType.GET,
  relativeUrl: exists(payload.id) ? `/app/health-monitor/${payload.id}` : `/app/health-monitor`,
  query: {
    ...payload,
    id: undefined,
  },
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data),
});

export const getHealthMonitorInitialMetricsNetworkRequest: NRC<void, any> = () => ({
  method: RequestType.GET,
  relativeUrl: `/app/health-monitor/initial-metric-options`,
  responseTransformer: res => toCamelCase(res.data),
});

export const createMonitoredMetricNetworkRequest: NRC<MonitoredMetricDTO, void> = (
  payload: MonitoredMetricDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/health-monitor/metrics`,
  body: payload,
  bodyTransformer: data => toSnakeCase(data, ['parameters', 'analysisParameters']),
  responseTransformer: res => toCamelCase(res.data, ['parameters', 'analysis_parameters']),
});

export const updateMonitoredMetricNetworkRequest: NRC<MonitoredMetricDTO, void> = (
  payload: MonitoredMetricDTO
) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/health-monitor/metrics/${payload.id}`,
  body: payload,
  bodyTransformer: data => toSnakeCase(data, ['parameters', 'analysisParameters']),
  responseTransformer: res => toCamelCase(res.data, ['parameters', 'analysis_parameters']),
});

export const removeMonitoredMetricNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.DELETE,
  relativeUrl: `/app/health-monitor/metrics/${id}`,
  responseTransformer: res => toCamelCase(res.data),
});

export const getMonitoredMetricNetworkRequest: NRC<void, any> = (id: number, parameters: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/health-monitor/metrics/${id}`,
  query: parameters,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data, ['parameters', 'analysis_parameters']),
});

export const getMonitoredMetricDataNetworkRequest: NRC<void, any> = (
  id: number,
  parameters: any
) => ({
  method: RequestType.GET,
  relativeUrl: `/app/health-monitor/metrics/${id}/data`,
  query: parameters,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data),
});

export const getMetricSeriesDataNetworkRequest: NRC<void, any> = (id: number, parameters: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/health-monitor/metric-series/${id}/data`,
  query: parameters,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data),
});

export const createHealthMonitorGroupNetworkRequest: NRC<HealthMonitorGroupDTO, void> = (
  payload: HealthMonitorGroupDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/health-monitor/groups`,
  body: payload,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const getHealthMonitorGroupNetworkRequest: NRC<void, any> = (id: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/health-monitor/groups/${id}`,
  responseTransformer: res => toCamelCase(res.data),
});

export const editHealthMonitorGroupNetworkRequest: NRC<HealthMonitorGroupDTO, void> = (
  payload: HealthMonitorGroupDTO
) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/health-monitor/groups/${payload.id}`,
  body: payload,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const removeHealthMonitorGroupNetworkRequest: NRC<RemoveHealthMonitorGroupDTO, void> = (
  payload: RemoveHealthMonitorGroupDTO
) => ({
  method: RequestType.DELETE,
  relativeUrl: `/app/health-monitor/groups`,
  body: payload,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const createHealthMonitorSubscriptionNetworkRequest: NRC<
  HealthMonitorSubscriptionDTO,
  any
> = (payload: HealthMonitorSubscriptionDTO) => ({
  method: RequestType.POST,
  relativeUrl: `/app/health-monitor/${payload.metricHealthMonitorId}/subscribe`,
  body: payload,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const editHealthMonitorSubscriptionNetworkRequest: NRC<HealthMonitorSubscriptionDTO, any> = (
  payload: HealthMonitorSubscriptionDTO
) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/health-monitor/${payload.metricHealthMonitorId}/subscribe`,
  body: payload,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const deleteHealthMonitorSubscriptionNetworkRequest: NRC<
  HealthMonitorSubscriptionDTO,
  any
> = (payload: HealthMonitorSubscriptionDTO) => ({
  method: RequestType.DELETE,
  relativeUrl: `/app/health-monitor/${payload.metricHealthMonitorId}/subscribe`,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const initializeHealthMonitorNetworkRequest: NRC<HealthMonitorInitializeDTO, void> = (
  data: HealthMonitorInitializeDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/health-monitor/init`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

import {action, OnSuccessActionHook} from 'front-core';
import {FunnelDTO} from '../../objects/dto/funnel.dto';
import {showConfirmationMessage} from '../interface/interface.actions';
import i18n from '../../config/i18n.config';
import TransKeys from '../../constants/translation-keys';

export enum FunnelsActionType {
  CREATE_FUNNEL = '@@funnels/CREATE_FUNNEL',
  UPDATE_FUNNEL = '@@funnels/UPDATE_FUNNEL',
  DELETE_FUNNEL = '@@funnels/DELETE_FUNNEL',
  PUBLISH_AD_HOC_FUNNEL = '@@funnels/PUBLISH_AD_HOC_FUNNEL',
}

export const createFunnel = (data: FunnelDTO, onSuccess: OnSuccessActionHook) =>
  action(FunnelsActionType.CREATE_FUNNEL, data, {onSuccess});

export const updateFunnel = (data: FunnelDTO, onSuccess: OnSuccessActionHook) =>
  action(FunnelsActionType.UPDATE_FUNNEL, data, {onSuccess});

export const deleteFunnel = (id: number) => action(FunnelsActionType.DELETE_FUNNEL, id);

export const deleteFunnelConfirmed = (id: number) =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.DELETE_FUNNEL.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.DELETE_FUNNEL.CONTENT),
    },
    () => [deleteFunnel(id)]
  );

export const publishAdHocFunnel = (id: number, onSuccess?: OnSuccessActionHook) =>
  action(FunnelsActionType.PUBLISH_AD_HOC_FUNNEL, id, {onSuccess});

export const publishAdHocFunnelConfirmed = (id: number, onSuccess?: OnSuccessActionHook) =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.PUBLISH_AD_HOC_FUNNEL.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.PUBLISH_AD_HOC_FUNNEL.CONTENT),
    },
    () => [publishAdHocFunnel(id, onSuccess)]
  );

import * as React from 'react';
import classNames from 'classnames';
import classes from './emoji-picker.module.scss';
import {PopoverWrapper} from 'ui-components';
import EmojiPicker, {EmojiStyle} from 'emoji-picker-react';

interface OwnProps {
  value: string;
  onChange: (emoji: string) => void;
  placeholderIcon: any;
  className?: string;
}

type AllProps = OwnProps;

export const IconPicker: React.FC<AllProps> = (props: AllProps) => {
  const {value, onChange, placeholderIcon: PlaceholderIcon, className} = props;

  return (
    <PopoverWrapper
      buttonRenderer={props => (
        <div
          className={classNames(classes.IconPicker, props.isOpen && classes.Open, className)}
          onClick={props.onClick}
        >
          {value || <PlaceholderIcon className={classes.Placeholder} />}
        </div>
      )}
    >
      <EmojiPicker
        onEmojiClick={e => onChange(e.emoji)}
        previewConfig={{showPreview: false}}
        emojiStyle={EmojiStyle.NATIVE}
      />
    </PopoverWrapper>
  );
};

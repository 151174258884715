import {
  Workflow,
  WorkflowGranularity,
  WorkflowStatus,
  WorkflowType,
} from '../models/workflow.model';
import yup from '../../config/yup.config';
import {values} from 'lodash';
import {TableEntity} from '../models/table.model';
import {queryElementValidatorFactory} from './query-builder.dto';
import {exists} from 'front-core';
import {BaseFiltersDTO} from './base-filters.dto';

export type WorkflowDTO = Partial<Workflow>;

export interface WorkflowFiltersDTO extends BaseFiltersDTO {
  q?: string;
  status?: WorkflowStatus;
  type?: WorkflowType;
  teamId?: number;
  orderBy?: string;
  order?: 'asc' | 'desc';
}

export interface SetWorkflowOwnerDTO {
  workflowId: number;
  userId: number;
}

export interface SetWorkflowTeamDTO {
  workflowId: number;
  teamId: number;
}

export interface UpdateWorkflowTeamDTO {
  workflowId: number;
  status: WorkflowStatus;
}

export interface RenameWorkflowDTO {
  workflowId: number;
  name: string;
}

const sharedWorkflowParameters = {
  group_by: yup.number().nullable(),
  attributes: yup.array().of(yup.number()).min(1).required(),
  population_filter: queryElementValidatorFactory(false),
};

export const kpiReachParametersValidator = yup
  .object()
  .shape({
    metric: yup.number().required(),
    ...sharedWorkflowParameters,
  })
  .required();

export const customSQLParametersValidator = yup
  .object()
  .shape({
    sql: yup.string().required(),
    ...sharedWorkflowParameters,
  })
  .required();

const parametersValidator = yup.lazy((obj, {parent}) => {
  if (parent.type === WorkflowType.KPI_REACH) {
    return kpiReachParametersValidator;
  }
  if (parent.type === WorkflowType.CUSTOM_SQL) {
    return customSQLParametersValidator;
  }
  return yup.object().shape({});
});

export const workflowValidator = yup.object().shape({
  teamId: yup.number().nullable(),
  name: yup.string().required(),
  entity: yup.string().oneOf(values(TableEntity)).required(),
  type: yup.string().oneOf(values(WorkflowType)).required(),
  granularity: yup.string().oneOf(values(WorkflowGranularity)).required(),
  parameters: parametersValidator,
});

const transformer = value => (!exists(value) ? undefined : value);

export const workflowOutputsValidator = yup.object().shape({
  emailOutput: yup
    .object()
    .shape({
      emails: yup.array().of(yup.string().email()).nullable(),
      user_ids: yup.array().of(yup.number()).nullable(),
      send_when_empty: yup.boolean().transform(transformer).default(false).required(),
    })
    .test({
      name: 'at-least-one-target',
      message: 'At least one target should be defined',
      test: function (value) {
        if (value === null || value === undefined) {
          return true;
        }
        if (exists(value.emails) || exists(value.user_ids)) {
          return true;
        }
        return this.createError({
          message: 'At least one target should be defined',
        });
      },
    })
    .nullable()
    .notRequired()
    .default(null),
  slackOutput: yup
    .object()
    .shape({
      channels: yup.array().of(yup.string()).required(),
      send_when_empty: yup.boolean().transform(transformer).default(false).required(),
    })
    .nullable()
    .notRequired()
    .default(null),
  databaseOutput: yup
    .object()
    .shape({
      name: yup.string().required(),
      state: yup.string().oneOf(['daily', 'last']).required(),
    })
    .nullable()
    .notRequired()
    .default(null),
});
// .test({
//   name: 'at-least-one-output',
//   test: function (value) {
//     if (exists(value.emailOutput) || exists(value.slackOutput) || exists(value.databaseOutput)) {
//       return true;
//     }
//     return this.createError({
//       message: 'At least one output should be defined',
//       path: `root`,
//     });
//   },
// });

import {useTranslation} from 'react-i18next';
import {FancyBlock} from 'ui-components';
import {FunnelStepsDefinition} from '../../../../../../modules/funnels/components/funnel-steps-definition/funnel-steps-definition.component';
import {Funnel} from '../../../../../../objects/models/funnel.model';
import TransKeys from '../../../../../../constants/translation-keys';

type FunnelPageDefinitionTabProps = {
  funnel: Funnel | null;
};

export const FunnelPageDefinitionTab = (props: FunnelPageDefinitionTabProps) => {
  const {funnel} = props;
  const {t} = useTranslation();

  const stepsDefinitionTabContentTitle = t(TransKeys.FUNNEL_PAGE.TABS.DEFINITION_CONTENT.TITLE);

  return (
    <FancyBlock title={stepsDefinitionTabContentTitle} addPadding>
      <FunnelStepsDefinition funnel={funnel} />
    </FancyBlock>
  );
};

import * as React from 'react';
import {IconButton, InfoIcon, TableIcon, SmartSelectorPanel} from 'ui-components';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../../../constants/translation-keys';
import {get} from 'lodash';
import {useMemo, useState} from 'react';
import classes from '../../smart-selector.module.scss';
import classNames from 'classnames';
import {Dialog, Tooltip} from '@material-ui/core';
import {SQLViewer} from '../../../../components/general/sql-viewer/sql-viewer.component';
import {TitleWithIcon} from '../../../../components/general/title/title.component';
import moment from 'moment';
import {TIME_FORMATS} from '../../../../../../constants/time-formats';
import {exists} from 'front-core';
import InvalidSignalWarning from '../../../../../dmp/components/invalid-signal-warning/invalid-signal-warning.component';

interface OwnProps {
  label?: string;
  data: any;
  className?: string;
}

type AllProps = OwnProps;

export const TableEventPreview: React.FC<AllProps> = (props: AllProps) => {
  const {label, data, className} = props;
  const {t} = useTranslation();
  const [showQuery, setShowQuery] = useState(false);
  const table = useMemo(
    () => ({
      name: data.tableName || get(data, 'tableDefinition.name'),
      type: get(data, 'tableDefinition.type'),
      query: get(data, 'tableDefinition.query'),
    }),
    [data]
  );
  const firstSeen = useMemo(() => {
    if (data.firstSeen) {
      return moment(data.firstSeen).format(TIME_FORMATS.DEFAULT_DATE_FORMAT);
    }
  }, [data]);
  const disabled = useMemo(() => exists(data.disabled) && data.disabled, [data]);

  return (
    <>
      <SmartSelectorPanel
        className={className}
        label={label}
        title={data.name}
        description={data.description}
      >
        <div className={classes.LabelRow}>
          <span className={classes.Label}>
            {table.type === 'query'
              ? t(TransKeys.GENERAL.LABELS.SOURCE_VIEW)
              : t(TransKeys.GENERAL.LABELS.SOURCE_TABLE)}
          </span>
        </div>
        <div className={classes.InfoRow}>
          <Tooltip title={table.name.length > 30 ? table.name : ''} placement={'top'}>
            <span className={classNames(classes.Text, classes.Strong)}>{table.name}</span>
          </Tooltip>
          {table.type === 'query' && (
            <IconButton
              tooltipText={t(TransKeys.GENERAL.ACTIONS.SHOW_DEFINITION)}
              onClick={_ => setShowQuery(true)}
              icon={InfoIcon}
              size={'small'}
            />
          )}
          {disabled && (
            <InvalidSignalWarning
              title={t(TransKeys.TABLE_EVENTS.TABLE_VALIDATION_STATUS_HELPER_TEXT)}
            />
          )}
        </div>
        {firstSeen && (
          <>
            <div className={classes.LabelRow}>
              <span className={classes.Label}>
                {t(TransKeys.TABLE_EVENTS.TABLE.HEADERS.FIRST_SEEN)}
              </span>
            </div>
            <div className={classes.InfoRow}>
              <span className={classes.Text}>{firstSeen}</span>
            </div>
          </>
        )}
      </SmartSelectorPanel>
      <Dialog
        open={showQuery}
        onClose={_ => setShowQuery(false)}
        PaperProps={{className: classes.Modal}}
        maxWidth={'md'}
      >
        <div className={classes.InternalModal}>
          <TitleWithIcon
            className={classes.TitleWrapper}
            titleClassName={classes.Title}
            icon={TableIcon}
            text={table.name}
          />
          <div className={classes.Body}>
            <SQLViewer sql={table.query} />
          </div>
        </div>
      </Dialog>
    </>
  );
};

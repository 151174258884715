import * as React from 'react';
import {useMemo, useState} from 'react';
import classNames from 'classnames';
import classes from './analysis-sub-folders.module.scss';
import {
  AnalysisFolder,
  AnalysisFolderType,
} from '../../../../../../objects/models/analysis-folder.model';
import {
  ActionsDropdown,
  ArchiveIcon,
  ArrowRightLightIcon,
  AvatarGroup,
  Chip,
  EditIcon,
  LeaveIcon,
  MoreIcon,
  ShareIcon,
  SkeletonLoading,
  StarLightIcon,
  StarSolidIcon,
  TeamIconChip,
  TooltipIfOverflow,
} from 'ui-components';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {AnalysisFolderActions} from '../../../../hooks/use-analysis-folder-actions.hook';
import {withStopPropagation} from 'front-core';
import {useCurrentUser} from '../../../../../../core/hooks/use-user.hook';
import {DEFAULT_FOLDER_ICON} from '../../analysis-main.types';
import {range} from 'lodash';
import {translateAnalysisFolderName} from '../../analyses.utils';

interface OwnProps extends AnalysisFolderActions {
  analysisFolders: Partial<AnalysisFolder>[];
  onFolderClicked: (analysisFolderId: number) => void;
  showParticipants?: boolean;
  isLoading?: boolean;
  className?: string;
}

type AllProps = OwnProps;

const MAX_LEN_TO_RENDER = 11;

const MoreIconComponent = props => (
  <div
    onClick={withStopPropagation(props.onClick)}
    className={classNames(classes.MoreButton, classes.Action, props.active && classes.Active)}
  >
    <MoreIcon className={classes.Icon} />
  </div>
);

export const AnalysisSubFolders: React.FC<AllProps> = (props: AllProps) => {
  const {
    analysisFolders,
    onFolderClicked,
    onEdit,
    onShare,
    onDeshare,
    onExplore,
    onDelete,
    onMove,
    isLoading,
    showParticipants,
    className,
  } = props;
  const [showAll, setShowAll] = useState(false);
  const {t} = useTranslation();
  const user = useCurrentUser();
  const renderedFolders = useMemo(
    () => (showAll ? analysisFolders : analysisFolders.slice(0, MAX_LEN_TO_RENDER)),
    [analysisFolders, showAll]
  );
  const createActionsForFolder = (analysisFolder: Partial<AnalysisFolder>) => [
    {
      key: 'share',
      title: t(TransKeys.GENERAL.ACTIONS.SHARE),
      onClick: () => onShare(analysisFolder.id),
      icon: ShareIcon,
      hide: analysisFolder.actualType === AnalysisFolderType.PUBLIC || Boolean(analysisFolder.team),
    },
    {
      key: 'edit',
      title: t(TransKeys.GENERAL.ACTIONS.EDIT),
      onClick: () => onEdit(analysisFolder.id),
      icon: EditIcon,
      hide: Boolean(analysisFolder.team),
    },
    {
      key: 'star',
      title: analysisFolder.isExplored
        ? t(TransKeys.GENERAL.ACTIONS.UNEXPLORE)
        : t(TransKeys.GENERAL.ACTIONS.EXPLORE),
      onClick: () => onExplore(analysisFolder as any),
      icon: analysisFolder.isExplored ? StarSolidIcon : StarLightIcon,
    },
    {
      key: 'move',
      title: t(TransKeys.GENERAL.ACTIONS.MOVE_TO_FOLDER),
      onClick: () => onMove(analysisFolder as any),
      icon: ArrowRightLightIcon,
      hide: Boolean(analysisFolder.team),
    },
    {
      key: 'leave',
      title: t(TransKeys.GENERAL.ACTIONS.LEAVE),
      onClick: () => onDeshare(analysisFolder.id),
      icon: LeaveIcon,
      hide:
        analysisFolder.actualType === AnalysisFolderType.PUBLIC ||
        analysisFolder.isShared === false ||
        user.id === analysisFolder.userId,
    },
    {
      key: 'archive',
      title:
        analysisFolder.deletedOn !== null
          ? t(TransKeys.GENERAL.ACTIONS.UNARCHIVE)
          : t(TransKeys.GENERAL.ACTIONS.ARCHIVE),
      onClick: () => onDelete(analysisFolder.id),
      hide: user.id !== analysisFolder.userId || Boolean(analysisFolder.team),
      icon: ArchiveIcon,
    },
  ];

  if (isLoading) {
    return (
      <div className={classNames(classes.AnalysisSubFolders, className)}>
        {range(0, 4).map(i => (
          <SkeletonLoading key={i} className={classes.FolderPlaceholder} />
        ))}
      </div>
    );
  }

  const renderIcon = (analysisFolder: AnalysisFolder) => {
    if (analysisFolder.icon) {
      return analysisFolder.icon;
    }
    if (analysisFolder.team) {
      return <TeamIconChip {...analysisFolder.team} />;
    }
    return <DEFAULT_FOLDER_ICON className={classes.FolderIcon} />;
  };

  return (
    <div className={classNames(classes.AnalysisSubFolders, className)}>
      {renderedFolders.map(af => (
        <div className={classes.Folder} onClick={() => onFolderClicked(af.id)} key={af.id}>
          <div className={classes.Main}>
            <div className={classes.Info}>
              <div className={classes.Icon}>{renderIcon(af as any)}</div>
              <TooltipIfOverflow title={translateAnalysisFolderName(af.name)} interactive={false}>
                <div className={classes.Name}>{translateAnalysisFolderName(af.name)}</div>
              </TooltipIfOverflow>
              {af.numberOfChildren > 0 && (
                <div className={classes.NumberOfChildren}>({af.numberOfChildren})</div>
              )}
            </div>
            {af.isShared && (
              <Chip
                className={classes.FolderChip}
                label={t(TransKeys.ANALYSES_MAIN.FOLDERS_DEFAULT_NAME.__SHARED.TITLE)}
                size={'xsmall'}
              />
            )}
            <ActionsDropdown
              actions={createActionsForFolder(af)}
              label={t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
              popoverDirection={'right'}
              buttonComponent={MoreIconComponent}
            />
          </div>
          {showParticipants && (
            <div className={classes.Participants}>
              <AvatarGroup
                size={'small'}
                maxCount={4}
                variant={'secondary'}
                avatarsData={af.participants.map(p => ({
                  name: p.fullName,
                  helperText: p.fullName,
                }))}
              />
            </div>
          )}
        </div>
      ))}
      {renderedFolders.length < analysisFolders.length && (
        <div className={classes.ShowMore} onClick={() => setShowAll(true)}>
          {t(TransKeys.ANALYSES_MAIN.VIEW_FOLDER.ACTIONS.SHOW_MORE_FOLDERS, {
            count: analysisFolders.length - renderedFolders.length,
          })}
        </div>
      )}
      {showAll && (
        <div className={classes.ShowMore} onClick={() => setShowAll(false)}>
          {t(TransKeys.ANALYSES_MAIN.VIEW_FOLDER.ACTIONS.SHOW_LESS_FOLDERS)}
        </div>
      )}
    </div>
  );
};

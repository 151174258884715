import {BaseModel} from './base.model';

export enum FeedbackModelType {
  ANALYSIS_RESULT = 'analysis_result',
}

export interface Feedback extends BaseModel {
  modelType: FeedbackModelType;
  modelId: number;
  key: string;
  isPositive: boolean;
  description?: string;
  additionalData?: any;
}

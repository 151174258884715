import {useState, useCallback} from 'react';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Link} from 'ui-components';
import classes from './name-description-input.module.scss';
import sharedClasses from '../../../shared.module.scss';
import {TextareaFormInput, TextFormInput} from '../shared-form-input.component';
import TransKeys from '../../../../../constants/translation-keys';

interface OwnProps {
  nameLabel?: string;
  namePlaceholder?: string;
  descriptionLabel?: string;
  descriptionPlaceholder?: string;
  placeholderIsValue?: boolean;
}

type AllProps = OwnProps;

export const NameDescriptionInput: React.FC<AllProps> = (props: AllProps) => {
  const {nameLabel, namePlaceholder, descriptionLabel, descriptionPlaceholder, placeholderIsValue} =
    props;
  const {t} = useTranslation();
  const {watch, setValue} = useFormContext();
  const shortDescription = watch('shortDescription');
  const [showDescription, setShowDescription] = useState(!!shortDescription);

  const removeDescription = useCallback(() => {
    setValue('shortDescription', null);
    setShowDescription(false);
  }, [setValue, setShowDescription]);

  return (
    <div className={classes.Container}>
      <div className={sharedClasses.Block}>
        <TextFormInput
          label={nameLabel}
          placeholder={namePlaceholder}
          name={'name'}
          placeholderIsValue={placeholderIsValue}
          renderRight={
            !showDescription && (
              <Link
                className={classes.DescriptionLink}
                inline
                underline={false}
                onClick={() => setShowDescription(true)}
              >
                {t(TransKeys.GENERAL.ACTIONS.ADD_DESCRIPTION)}
              </Link>
            )
          }
        />
      </div>
      {showDescription && (
        <div className={sharedClasses.Block}>
          <TextareaFormInput
            label={descriptionLabel}
            placeholder={descriptionPlaceholder}
            name={'shortDescription'}
            renderRight={
              <Link
                className={classes.DescriptionLink}
                inline
                underline={false}
                onClick={removeDescription}
              >
                {t(TransKeys.GENERAL.ACTIONS.REMOVE_DESCRIPTION)}
              </Link>
            }
          />
        </div>
      )}
    </div>
  );
};

NameDescriptionInput.defaultProps = {
  nameLabel: 'Name',
  namePlaceholder: 'Name',
  descriptionLabel: 'Description',
  descriptionPlaceholder: 'Description',
};

import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo} from 'react';
import classNames from 'classnames';
import classes from './view-monitored-metric.module.scss';
import {
  ANALYSIS_PARAMETERS_KEY,
  ANALYSIS_RESULT_ID_PATH_PARAM,
  ANALYSIS_TYPE_ID_PATH_PARAM,
  AppRoutes,
  DOCUMENT_ID_PATH_PARAM,
} from '../../../../constants/app-routes';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {
  createSelected,
  getSelected,
  removeSelected,
} from '../../../../store/selected/selected.actions';
import {useDispatch, useSelector} from 'react-redux';
import {
  getReducedLoadingStateSelector,
  getSingleSelectedSelector,
} from '../../../../store/store.selectors';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {CalcSeriesMode} from '../../../../constants/metric-series.consts';
import {ArrowRightIcon, Button, FancyBlock, BellIcon, LineChart, Sentence} from 'ui-components';
import {MonitoredMetric} from '../../../../objects/models/health-monitor.model';
import {
  getMetricSeriesDataNetworkRequest,
  getMonitoredMetricDataNetworkRequest,
} from '../../../../http/health-monitor.network-requests';
import TransKeys from '../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {AppSentence} from '../../../shared/core/app-sentence/app-sentence.component';
import {useHistory} from 'react-router';
import {Phenomena} from '../../../../objects/models/phenomena.model';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {useMetricSeriesSamplesToChartData} from '../../../../core/hooks/metric-series.hooks';
import {MonitoredMetricHeader} from './components/monitored-metric-header.component';
import {ModelKey} from '../../../../constants/model-key';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {CoreActionsType} from '../../../../store/core/core.actions';
import {getLastSampleTrendValue} from '../../../../utils/metric-series.utils';
import {ImagesResource} from '../../../../assets/images';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {PanelKey} from '../../../../constants/panels';
import {AnalysisTypeId} from '../../../../constants/analysis-type-id';
import {PanelType} from '../../../../objects/system/panel-type.enum';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {GOAL_KEY} from '../../../analyses/analysis-forms/analysis-parameters/analysis-101/analysis-101-form.component';

interface OwnProps {
  modelId: number;
  modelType: 'MonitoredMetric' | 'MetricSeries';
  toDate?: string;
  mode: CalcSeriesMode;
  onEdit?: () => void;
  onDelete?: () => void;
  className?: string;
}

type AllProps = OwnProps;

const SELECTED_MONITORED_METRIC_KEY = SharedSelectionKeys.VIEW_MONITORED_METRIC__MONITORED_METRIC;

export const ViewMetricSeries: React.FC<AllProps> = (props: AllProps) => {
  const {modelId, modelType, toDate, onEdit, onDelete, className} = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const notify = useAmplitude();
  const {t} = useTranslation();
  const {openPrimaryPanel} = useContext(PanelsContext);
  const {productEntitiesMap} = useProductData();
  const monitoredMetric: MonitoredMetric = useSelector(state =>
    getSingleSelectedSelector(SELECTED_MONITORED_METRIC_KEY, state)
  );
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(SELECTED_MONITORED_METRIC_KEY)(state)
  );
  const chartData = useMetricSeriesSamplesToChartData(monitoredMetric, productEntitiesMap);
  const onViewPhenomena = useCallback(
    (phenomena: Partial<Phenomena>) => {
      history.push(
        AppRoutes.viewAnalysis(phenomena.analysisId, {
          [ANALYSIS_RESULT_ID_PATH_PARAM]: phenomena.analysisResultId,
          [DOCUMENT_ID_PATH_PARAM]: phenomena.rootDocumentId,
        })
      );
      notify(AmplitudeEvent.MONITORED_METRIC_ALERT_CLICKED, {
        monitored_metric_id: monitoredMetric.id,
        phenomena_id: phenomena.id,
      });
    },
    [history, notify, monitoredMetric]
  );
  const goToViewAnalysisResult = useCallback(() => {
    const params = {
      [ANALYSIS_RESULT_ID_PATH_PARAM]: monitoredMetric.relatedAnalysisResultId,
      [DOCUMENT_ID_PATH_PARAM]: monitoredMetric.rootDocumentId,
    };
    history.push(AppRoutes.viewMetricSeriesResult(monitoredMetric.metricSeriesId, params));
    notify(AmplitudeEvent.MONITORED_METRIC_DEEP_DIVE_CLICK, {
      monitored_metric_id: monitoredMetric.id,
      analysis_result_id: monitoredMetric.relatedAnalysisResultId,
    });
  }, [history, monitoredMetric, notify]);
  const sentence = useMemo(() => {
    if (!monitoredMetric) {
      return;
    }
    if (!monitoredMetric.rootDocumentId) {
      return null;
    }
    const trend = getLastSampleTrendValue(monitoredMetric);
    if (!trend) {
      return;
    }
    let string = `**${monitoredMetric.name.trim()}** `;
    string += trend.isBetter ? 'increased by ' : 'declined by ';
    string += `\${CHANGE}(${trend.value}|`;
    string += `${monitoredMetric.higherIsBetter ? 'True' : 'False'}|`;
    if (trend.isSignificant !== undefined) {
      string += `${trend.isSignificant ? 'True' : 'False'}`;
    }
    string += ') vs expected';
    return string;
  }, [monitoredMetric]);
  const onRequestAnalysis = useCallback(
    (monitoredMetric: Partial<MonitoredMetric>) => {
      const {analysisContext} = monitoredMetric;
      openPrimaryPanel(
        PanelKey.ANALYSIS_FORM_PANEL,
        {
          [ANALYSIS_TYPE_ID_PATH_PARAM]: AnalysisTypeId.RCA_ANALYSIS,
          [ANALYSIS_PARAMETERS_KEY]: {
            [GOAL_KEY]: analysisContext.goalId,
          },
        },
        PanelType.MODAL
      );
    },
    [openPrimaryPanel]
  );
  useEffect(() => {
    dispatch(
      createSelected({
        selectedKey: SELECTED_MONITORED_METRIC_KEY,
        actionKey: SELECTED_MONITORED_METRIC_KEY,
        request:
          modelType === 'MonitoredMetric'
            ? getMonitoredMetricDataNetworkRequest
            : getMetricSeriesDataNetworkRequest,
      })
    );
    return () => {
      dispatch(removeSelected(SELECTED_MONITORED_METRIC_KEY));
    };
  }, [dispatch, modelType]);

  useEffect(() => {
    modelId && dispatch(getSelected(SELECTED_MONITORED_METRIC_KEY, modelId, {toDate}));
  }, [dispatch, modelId, toDate]);
  useEffect(() => {
    const listener = action => {
      const {modelKey, data} = action.payload;
      if (modelKey === ModelKey.MONITORED_METRIC && data.id === modelId) {
        dispatch(getSelected(SELECTED_MONITORED_METRIC_KEY, modelId, {toDate}));
      }
    };
    dispatch(registerActionListener(CoreActionsType.MODEL_CREATED, listener));
    dispatch(registerActionListener(CoreActionsType.MODEL_UPDATED, listener));
    return () => {
      dispatch(removeActionListener(CoreActionsType.MODEL_CREATED, listener));
      dispatch(removeActionListener(CoreActionsType.MODEL_UPDATED, listener));
    };
  }, [dispatch, toDate, modelId]);

  if (!monitoredMetric && isLoading) {
    return (
      <div className={classNames(classes.ViewMonitoredMetric, className)}>
        <GenericLoading />
      </div>
    );
  }

  if (!modelId || !monitoredMetric) {
    return (
      <div className={classNames(classes.ViewMonitoredMetric, className)}>
        <div className={classes.EmptyState}>
          <img src={ImagesResource.app.healthMonitorEmptyState} alt={''} />
          <div className={classes.Text}>
            {t(TransKeys.HEALTH_MONITOR.EMPTY_STATES.SELECT_A_METRIC)}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(classes.ViewMonitoredMetric, className)}>
      {isLoading && <GenericLoading />}
      <MonitoredMetricHeader
        monitoredMetric={monitoredMetric}
        onEdit={modelType === 'MonitoredMetric' ? onEdit : undefined}
        onDelete={modelType === 'MonitoredMetric' ? onDelete : undefined}
        onRequestAnalysis={onRequestAnalysis}
        className={classes.Header}
      />
      {(monitoredMetric.samples.length === 0 || !monitoredMetric.metricSeriesId) && (
        <div className={classes.InnerEmptyState}>
          <div className={classes.EmptyState}>
            <img src={ImagesResource.app.healthMonitorEmptyState} alt={''} />
            <div className={classes.Text}>
              {!monitoredMetric.metricSeriesId
                ? t(TransKeys.GENERAL.LABELS.PROCESSING_DATA_PLACEHOLDER)
                : t(TransKeys.HEALTH_MONITOR.EMPTY_STATES.NO_SAMPLES_WERE_FOUND)}
            </div>
          </div>
        </div>
      )}
      <div className={classes.Body}>
        {sentence && (
          <div className={classes.SentenceBlock}>
            <Sentence text={sentence} className={classes.Sentence} />
            <Button
              onClick={() => goToViewAnalysisResult()}
              size={'large'}
              endIcon={ArrowRightIcon}
            >
              {t(TransKeys.VIEW_MONITORED_METRIC.ACTIONS.DEEP_DIVE)}
            </Button>
          </div>
        )}
        {chartData && (
          <FancyBlock
            title={t(TransKeys.VIEW_MONITORED_METRIC.CHART_SECTION.TITLE)}
            addPadding
            className={classes.Section}
          >
            <div className={classes.Chart}>
              <LineChart {...chartData} />
            </div>
          </FancyBlock>
        )}

        {modelType === 'MonitoredMetric' && monitoredMetric.phenomenas.length > 0 && (
          <FancyBlock
            title={t(TransKeys.VIEW_MONITORED_METRIC.ALERTS_SECTION.TITLE)}
            subTitle={t(TransKeys.VIEW_MONITORED_METRIC.ALERTS_SECTION.SUB_TITLE)}
            icon={BellIcon}
            addPadding
            className={classes.Section}
          >
            <div className={classes.List}>
              {monitoredMetric.phenomenas.map(p => (
                <div key={p.id} className={classes.Item}>
                  <div className={classes.Text}>
                    <AppSentence text={p.phenomenaSentence} size={'small'} />
                  </div>
                  <div className={classes.Actions}>
                    <div onClick={_ => onViewPhenomena(p)} className={classes.Action}>
                      <ArrowRightIcon className={classes.Icon} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </FancyBlock>
        )}
      </div>
    </div>
  );
};

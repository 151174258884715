import {Reducer} from 'redux';
import {LocalStorageKey} from '../../constants/local-storage-key';
import HttpClient from '../../services/http-client.service';

export const setScopeProductReducer = (state, action): Reducer => {
  const updatedState = {...state};
  const scope = action.payload;
  const didScopeChange = scope !== updatedState.scopeProduct;

  updatedState.scopeProduct = scope;

  if (scope) {
    localStorage.setItem(LocalStorageKey.SCOPE_PRODUCT, scope);
    HttpClient.setHeader('X-Product-ID', scope);
  } else {
    localStorage.removeItem(LocalStorageKey.SCOPE_PRODUCT);
    HttpClient.removeHeader('X-Product-ID');
  }

  if (didScopeChange) {
    setTimeout(() => window.location.reload(), 0);
  }

  return updatedState;
};

export const changeAdminPrivilegesReducer = (state, action): Reducer => {
  const updatedState = {...state};
  const show = action.payload;
  updatedState.adminPrivileges = show;
  localStorage.setItem(LocalStorageKey.ADMIN_PRIVILEGES, show);
  return updatedState;
};

import {action} from 'front-core';
import {NotificationsConfigDTO} from '../../objects/dto/notifications-config.dto';
import {showConfirmationMessage} from '../interface/interface.actions';

export enum UserNotificationsConfigActionType {
  GET_USER_NOTIFICATIONS_CONFIG = '@@user_notifications/USER_NOTIFICATIONS_CONFIG',
  UPDATE_USER_NOTIFICATIONS_CONFIG = '@@user_notifications/UPDATE_USER_NOTIFICATIONS_CONFIG',
  REMOVE_SLACK_FROM_PRODUCT = '@@user_notifications/REMOVE_SLACK_FROM_PRODUCT',
}

export const getUserNotificationsConfig = () =>
  action(UserNotificationsConfigActionType.GET_USER_NOTIFICATIONS_CONFIG);

export const updateUserNotificationsConfig = (data: NotificationsConfigDTO) =>
  action(UserNotificationsConfigActionType.UPDATE_USER_NOTIFICATIONS_CONFIG, data);

export const removeSlackFromProductConfirmed = () =>
  showConfirmationMessage(
    {
      title: 'Remove slack from your workspace',
      content:
        'Are you sure you want to remove slack from your workspace? Note! it will disconnect Slack for all of your team members as well',
    },
    () => [removeSlackFromProduct()]
  );

export const removeSlackFromProduct = () =>
  action(UserNotificationsConfigActionType.REMOVE_SLACK_FROM_PRODUCT);

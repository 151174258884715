import * as yup from 'yup';
import {SchemaOf} from 'yup';

export interface MFADTO {
  tokenId: number;
  code: string;
}

export interface ResendMFADTO {
  tokenId: number;
}

export const mfaDTOValidator: SchemaOf<MFADTO> = yup.object().shape({
  tokenId: yup.number().required(),
  code: yup.string().min(6).required(),
});

import {useMemo} from 'react';
import classes from './user-permissions-table.module.scss';
import {Subject, Action} from '../../../../../../constants/permissions';
import usePermissions from '../../../../../../core/hooks/use-permissions.hook';
import {PermissionLabel} from '../../../../../shared/core/permissions/permission-label.component';

interface OwnProps {}

type AllProps = OwnProps;

const DISPLAYED_ACTIONS = [Action.VIEW, Action.CREATE, Action.EDIT, Action.DELETE];

export const UserPermissionsTable: React.FC<AllProps> = () => {
  const {permissions} = usePermissions();

  const displayData = useMemo(
    () =>
      Object.keys(permissions).reduce((acc, subject) => {
        const actions = permissions[subject as unknown as Subject];
        const displayedActions = actions.filter(action => DISPLAYED_ACTIONS.includes(action));
        if (displayedActions.length) {
          acc[subject] = displayedActions;
        }
        return acc;
      }, {}),
    [permissions]
  );

  return (
    <table className={classes.Table}>
      {Object.keys(displayData).map(subject => (
        <tr>
          <td className={classes.Cell}>
            <PermissionLabel subject={subject as unknown as Subject} />
          </td>
          <td className={classes.Cell}>
            <div className={classes.Actions}>
              {displayData[subject].map(action => (
                <PermissionLabel action={action} />
              ))}
            </div>
          </td>
        </tr>
      ))}
    </table>
  );
};

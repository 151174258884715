declare global {
  interface Window {
    hsConversationsSettings: any;
    HubSpotConversations: any;
    hbspt: any;
  }
}

export const HUBSPOT_REGION = 'na1';
export const HUBSPOT_PORTAL_ID = '21602226';
export const HUBSPOT_SCHEDULE_DEMO_FORM_ID = 'b01ce2b7-0847-4b6e-b358-98f807d9ce9d';

export const initHubspot = () => {
  window.hsConversationsSettings = {
    loadImmediately: false,
  };

  const script = document.createElement('script');
  script.defer = true;
  script.id = 'hs-script-loader';
  script.src = 'https://js.hs-scripts.com/21602226.js';
  document.getElementsByTagName('head')[0].appendChild(script);
};

export const setHubSpotConversation = (email, token) => {
  if (!email || !token) {
    return;
  }

  window.hsConversationsSettings = {
    identificationEmail: email,
    identificationToken: token,
  };

  loadHubspotConversation();
};

export const resetHubSpotConversation = () => {
  window.hsConversationsSettings = {
    loadImmediately: false,
  };

  window.HubSpotConversations.resetAndReloadWidget();
};

export const loadHubspotConversation = (widgetOpen = false) => {
  window.HubSpotConversations.widget.load({widgetOpen});
};

export const removeHubSpotConversation = () => {
  window.HubSpotConversations.widget.remove();
};

import {action, ActionHooks, OnSuccessActionHook} from 'front-core';
import {
  ChangeAnalysisParametersDTO,
  MoveAnalysisToFolderDTO,
  AnalysisDTO,
  AnalysisRerunDTO,
  QuickRunForAdminsDTO,
} from '../../objects/dto/analysis.dto';
import {AnyAction} from 'redux';
import {AnalysisResult} from '../../objects/models/analysis-result.model';
import {ScheduleAnalysisDTO} from '../../objects/dto/schedule-analysis.dto';
import i18n from '../../config/i18n.config';
import TransKeys from '../../constants/translation-keys';

import {showConfirmationMessage} from '../interface/interface.actions';

export enum AnalysesActionType {
  REQUEST_ANALYSIS = '@@ANALYSES/REQUEST_ANALYSIS',
  EDIT_ANALYSIS = '@@ANALYSES/EDIT_ANALYSIS',
  UPDATE_ANALYSIS_RESULT = '@@ANALYSIS/UPDATE_ANALYSIS_RESULT',
  DELETE_ANALYSIS_RESULT = '@@ANALYSIS/DELETE_ANALYSIS_RESULT',
  CREATE_SCHEDULE_ANALYSIS = '@@ANALYSIS/CREATE_SCHEDULE_ANALYSIS',
  EDIT_SCHEDULE_ANALYSIS = '@@ANALYSIS/EDIT_SCHEDULE_ANALYSIS',
  DELETE_SCHEDULE_ANALYSIS = '@@ANALYSIS/DELETE_SCHEDULE_ANALYSIS',
  CHANGE_ANALYSIS_PARAMETERS = '@@ANALYSIS/CHANGE_ANALYSIS_PARAMETERS',
  RERUN_ANALYSIS_RESULT = '@@ANALYSIS/RERUN_ANALYSIS_RESULT',
  STOP_ANALYSIS_RESULT = '@@ANALYSES/STOP_ANALYSIS_RESULT',
  MOVE_ANALYSIS_TO_FOLDER = '@@ANALYSIS/MOVE_ANALYSIS_TO_FOLDER',
  QUICK_RUN_FOR_ADMINS = '@@ANALYSIS/QUICK_RUN_FOR_ADMINS',
}

export const requestAnalysis = (
  data: AnalysisDTO,
  onSuccess?: (res: any, action: AnyAction) => AnyAction[]
) => action(AnalysesActionType.REQUEST_ANALYSIS, data, {onSuccess});

export const updateAnalysisResult = (
  data: Partial<AnalysisResult>,
  onSuccess?: OnSuccessActionHook
) => action(AnalysesActionType.UPDATE_ANALYSIS_RESULT, data, {onSuccess});

export const createScheduleAnalysis = (data: ScheduleAnalysisDTO, hooks?: ActionHooks) =>
  action(AnalysesActionType.CREATE_SCHEDULE_ANALYSIS, data, hooks);

export const editScheduleAnalysis = (data: ScheduleAnalysisDTO, hooks?: ActionHooks) =>
  action(AnalysesActionType.EDIT_SCHEDULE_ANALYSIS, data, hooks);

export const deleteScheduleAnalysis = (
  data: Omit<ScheduleAnalysisDTO, 'cronExp'>,
  hooks?: ActionHooks
) => action(AnalysesActionType.DELETE_SCHEDULE_ANALYSIS, data, hooks);

export const changeAnalysisParameters = (data: ChangeAnalysisParametersDTO, hooks?: ActionHooks) =>
  action(AnalysesActionType.CHANGE_ANALYSIS_PARAMETERS, data, hooks);

export const editAnalysis = (
  data: Partial<AnalysisResult>,
  onSuccess?: (res: any, action: AnyAction) => AnyAction[]
) => action(AnalysesActionType.EDIT_ANALYSIS, data, {onSuccess});

export const stopAnalysisResult = (id: number) =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.STOP_ANALYSIS_RESULT.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.STOP_ANALYSIS_RESULT.CONTENT),
    },
    () => [action(AnalysesActionType.STOP_ANALYSIS_RESULT, id)]
  );

export const rerunAnalysisResult = (
  data: AnalysisRerunDTO,
  onSuccess?: (res: any, action: AnyAction) => AnyAction[]
) => action(AnalysesActionType.RERUN_ANALYSIS_RESULT, data, {onSuccess});

export const deleteAnalysisResult = (id: number) =>
  action(AnalysesActionType.DELETE_ANALYSIS_RESULT, id);

// We're passing here onApprove as we want to trigger a panel which
// is handled via context and redux, therefore we're not responding
// with an action
export const rerunAnalysisResultWithOpportunitiesConfirmed = (id: number, onApprove: () => void) =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.RERUN_ANALYSIS_RESULT.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.RERUN_ANALYSIS_RESULT_WITH_OPPORTUNITIES.CONTENT),
    },
    () => {
      onApprove();
      return [];
    }
  );

export const moveAnalysisToFolder = (
  data: MoveAnalysisToFolderDTO,
  onSuccess?: OnSuccessActionHook
) => action(AnalysesActionType.MOVE_ANALYSIS_TO_FOLDER, data, {onSuccess});

export const quickRunForAdmins = (data: QuickRunForAdminsDTO, onSuccess?: OnSuccessActionHook) =>
  action(AnalysesActionType.QUICK_RUN_FOR_ADMINS, data, {onSuccess});

import * as React from 'react';
import classes from './analysis-types-follow-up-panel.module.scss';
import {Button, ModalLayout} from 'ui-components';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import SuccessFollowUpPanel from '../success-follow-up-panel/success-follow-up-panel.component';
import AnalysisTypesFollowUpPanelFooter from './components/analysis-types-follow-up-panel-footer.component';
import {useMemo} from 'react';
import classNames from 'classnames';

interface OwnProps {
  onClose: () => void;
  disabled: boolean;
  showSuccessMessage: boolean;
  onSubmit: (data?: any) => void;
  children: React.ReactNode;
  className?: string;
}

type AllProps = OwnProps;

const AnalysisTypesFollowUpPanel: React.FC<AllProps> = (props: AllProps) => {
  const {onClose, disabled, showSuccessMessage, onSubmit, children, className} = props;
  const {t} = useTranslation();

  const footerProp = useMemo(
    () =>
      showSuccessMessage ? (
        <Button onClick={onClose}>{t(TransKeys.GENERAL.LABELS.DONE)}</Button>
      ) : (
        <AnalysisTypesFollowUpPanelFooter disabled={disabled} onSubmit={onSubmit} />
      ),
    [showSuccessMessage, onClose, t, disabled, onSubmit]
  );

  return (
    <div className={classNames(classes.Container, className)}>
      <ModalLayout footer={footerProp}>
        {showSuccessMessage ? (
          <SuccessFollowUpPanel
            onClose={onClose}
            title={t(TransKeys.FOLLOW_UP_ANALYSIS.SUCCESS_MESSAGE.TITLE)}
            subTitle={t(TransKeys.FOLLOW_UP_ANALYSIS.SUCCESS_MESSAGE.SUB_TITLE)}
          />
        ) : (
          children
        )}
      </ModalLayout>
    </div>
  );
};

export default AnalysisTypesFollowUpPanel;

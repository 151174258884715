import {useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';
import {AmplitudeEvent} from '../../../constants/amplitude-event';
import {
  ANALYSIS_TYPE_ID_PATH_PARAM,
  AppRoutes,
  EXPERIMENT_ID_PATH_PARAM,
} from '../../../constants/app-routes';
import {PanelKey} from '../../../constants/panels';
import {PanelsContext} from '../../../core/contexts/panels.context';
import {useAmplitude} from '../../../core/hooks/amplitude.hook';
import {Experiment, ExperimentStatus} from '../../../objects/models/experiment.model';
import {ShareResourceType} from '../../../objects/models/share.model';
import {PanelType} from '../../../objects/system/panel-type.enum';
import {
  deleteExperimentConfirmed,
  patchExperimentConfirmed,
  setExperimentOwner,
  updateExperimentConfirmed,
  updateExperimentTeam,
} from '../../../store/experiments/experiments.actions';

export const useExperimentActions = () => {
  const dispatch = useDispatch();
  const {openPrimaryPanel} = useContext(PanelsContext);
  const notify = useAmplitude();

  const onDelete = useCallback(
    (exp: Experiment) => dispatch(deleteExperimentConfirmed(exp.id)),
    [dispatch]
  );

  const onSubscribe = useCallback(
    (exp: Experiment) =>
      openPrimaryPanel(PanelKey.EXPERIMENT_SUBSCRIPTION_FORM_PANEL, {
        [EXPERIMENT_ID_PATH_PARAM]: exp.id,
      }),
    [openPrimaryPanel]
  );

  const onShare = useCallback(
    (exp: Experiment) => {
      openPrimaryPanel(
        PanelKey.SHARE_RESOURCE_PANEL,
        {
          modelId: exp.id,
          type: ShareResourceType.EXPERIMENT,
          copyLink: AppRoutes.asUrl(AppRoutes.viewExperiment(exp.id)),
        },
        PanelType.MODAL
      );
      notify(AmplitudeEvent.RESOURCE_SHARE_CLICKED, {
        type: ShareResourceType.EXPERIMENT,
        id: exp.id,
      });
    },
    [openPrimaryPanel, notify]
  );
  const onEditDuplicate = useCallback(
    (exp: Experiment, cloneMode = false) => {
      const onApprove = () =>
        openPrimaryPanel(
          PanelKey.EXPERIMENT_FORM_PANEL,
          {
            [EXPERIMENT_ID_PATH_PARAM]: exp.id,
            [ANALYSIS_TYPE_ID_PATH_PARAM]: exp.analysis.analysisTypeId,
            cloneMode,
          },
          PanelType.MODAL
        );
      if (cloneMode) {
        return onApprove();
      }
      dispatch(updateExperimentConfirmed(onApprove));
    },
    [openPrimaryPanel, dispatch]
  );
  const onRename = useCallback(
    (experimentId: number) => {
      openPrimaryPanel(PanelKey.RENAME_EXPERIMENT_PANEL, {
        [EXPERIMENT_ID_PATH_PARAM]: experimentId,
      });
    },
    [openPrimaryPanel]
  );

  const onMarkComplete = useCallback(
    (exp: Experiment) =>
      dispatch(
        patchExperimentConfirmed({
          id: exp.id,
          status: ExperimentStatus.DONE,
        })
      ),
    [dispatch]
  );

  const onChangeOwner = useCallback(
    (ownerId: number, exp: Experiment) =>
      dispatch(
        setExperimentOwner({
          experimentId: exp.id,
          userId: ownerId,
        })
      ),
    [dispatch]
  );

  const setExperimentTeam = useCallback(
    (experimentId: number, teamId: number) =>
      dispatch(updateExperimentTeam({experimentId, teamId})),
    [dispatch]
  );

  return {
    onDelete,
    onSubscribe,
    onShare,
    onEditDuplicate,
    onRename,
    onMarkComplete,
    onChangeOwner,
    setExperimentTeam,
  };
};

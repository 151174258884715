import * as React from 'react';
import {SmartSelector} from 'ui-components';
import classes from './metric-selector.module.scss';
import {useMetricSelectorProps} from '../../../shared/core/smart-selector/metric-smart-selector/use-metric-selector-props.hook';

interface OwnProps {
  onChange: (value: number, item?: any) => void;
  excludeIds?: number[];
  emptyState?: any;
  onCreate?: (categoryId?: number) => void;
  filters?: any;
}

type AllProps = OwnProps;

export const MetricSelector: React.FC<AllProps> = (props: AllProps) => {
  const {emptyState} = props;
  const {sources, load} = useMetricSelectorProps(props);

  return (
    <div className={classes.MetricSelector}>
      <SmartSelector
        className={classes.Selector}
        sources={sources}
        load={load}
        emptyState={emptyState}
        withPreview={false}
      />
    </div>
  );
};

MetricSelector.defaultProps = {};

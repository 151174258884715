import {action, OnSuccessActionHook} from 'front-core';
import {MilestoneDTO} from '../../objects/dto/milestone.dto';
import {showConfirmationMessage} from '../interface/interface.actions';
import i18n from '../../config/i18n.config';
import TransKeys from '../../constants/translation-keys';

export enum MilestonesActionType {
  CREATE_MILESTONE = '@@milestones/CREATE_MILESTONE',
  UPDATE_MILESTONE = '@@milestones/UPDATE_MILESTONE',
  DELETE_MILESTONE = '@@milestones/DELETE_MILESTONE',
  VALIDATE_MILESTONE = '@@milestones/VALIDATE_MILESTONE',
}

export const createMilestone = (data: MilestoneDTO, onSuccess?: OnSuccessActionHook) =>
  action(MilestonesActionType.CREATE_MILESTONE, data, {onSuccess});

export const updateMilestone = (data: MilestoneDTO, onSuccess?: OnSuccessActionHook) =>
  action(MilestonesActionType.UPDATE_MILESTONE, data, {onSuccess});

export const deleteMilestone = (id: number) => action(MilestonesActionType.DELETE_MILESTONE, id);

export const deleteMilestoneConfirmed = (id: number) =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.DELETE_MILESTONE.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.DELETE_MILESTONE.CONTENT),
    },
    () => [deleteMilestone(id)]
  );

export const validateMilestone = (id: number, onSuccess?: OnSuccessActionHook) =>
  action(MilestonesActionType.VALIDATE_MILESTONE, id, {onSuccess});

import * as React from 'react';
import {Action, Role, Subject} from 'src/constants/permissions';
import {useTranslation} from 'react-i18next';
import TransKeys from 'src/constants/translation-keys';

const camelCaseToTranslationKey = value => {
  const snakeCase = (value || '').replace(/([A-Z])/g, '_$1');
  return snakeCase.toUpperCase();
};
interface OwnProps {
  role?: Role;
  subject?: Subject;
  action?: Action;
  className?: string;
}

type AllProps = OwnProps;

export const PermissionLabel: React.FC<AllProps> = (props: AllProps) => {
  const {role, subject, action, className} = props;
  const {t} = useTranslation();

  const translateRole =
    (role && t(TransKeys.PERMISSIONS.ROLES[camelCaseToTranslationKey(role)])) || role;
  const translateSubject =
    (subject && t(TransKeys.PERMISSIONS.SUBJECTS[camelCaseToTranslationKey(subject)])) || subject;
  const translateAction =
    (action && t(TransKeys.PERMISSIONS.ACTIONS[camelCaseToTranslationKey(action)])) || action;

  return <span className={className}>{translateRole || translateSubject || translateAction}</span>;
};

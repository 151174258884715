import classNames from 'classnames';
import classes from './layout.module.scss';
import {usePendoAnchor, PendoAnchors} from 'ui-components';

interface OwnProps {
  className?: string;
  children?: React.ReactNode;
}

type AllProps = OwnProps;

export const Actions: React.FC<AllProps> = (props: AllProps) => {
  const {children, className} = props;

  const ref = usePendoAnchor(PendoAnchors.PAGE_LAYOUT_ACTIONS);

  return (
    <div className={classNames(classes.Actions, className)} ref={ref}>
      {children}
    </div>
  );
};

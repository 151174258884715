import yup from '../../config/yup.config';
import {keys} from 'lodash';
import {ParametersSchema, ParameterType} from 'ui-components';
import {queryElementValidatorFactory} from './query-builder.dto';

export const createParameterValidator = (
  type: ParameterType,
  required?: boolean,
  list?: boolean,
  options?: string[]
) => {
  let validator: any = yup.mixed();

  switch (type) {
    case ParameterType.BOOLEAN:
      validator = yup.boolean();
      break;
    case ParameterType.SELECT:
      validator = yup.mixed().oneOf([null, ...options]);
      break;
    case ParameterType.DATE:
    case ParameterType.STRING:
      validator = yup.string();
      break;
    case ParameterType.JSON:
      validator = yup.object();
      break;
    case ParameterType.NUMBER:
    case ParameterType.FUNNEL:
    case ParameterType.FEATURE:
    case ParameterType.SEGMENT:
    case ParameterType.SIGNAL:
      validator = yup.number().transform((value, original) => (original === '' ? null : value));
      break;
    case ParameterType.QUERY_BUILDER:
      return queryElementValidatorFactory(required);
  }

  if (list) {
    validator = yup.array().of(validator);
    if (required) {
      validator = validator.min(1);
    }
  }

  if (required) {
    validator = validator.required();
  } else {
    validator = validator.nullable();
  }

  return validator;
};

export const createParametersValidator = (schema: ParametersSchema) =>
  yup.object().shape(
    keys(schema).reduce((acc, curr) => {
      const param = schema[curr];
      acc[curr] = createParameterValidator(
        param.type,
        param.required,
        param.list || param.collection,
        param.options
      );
      return acc;
    }, {})
  );

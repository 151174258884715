import {Route, Switch} from 'react-router';
import {AppRoutes} from '../../constants/app-routes';
import {FunnelPage} from './pages/funnel-page/funnel-page.component';

export const FunnelsRouting = () => {
  return (
    <Switch>
      <Route path={AppRoutes.viewFunnel().pathname} component={FunnelPage} exact />
    </Switch>
  );
};

import {AmplitudeEvent} from '../../constants/amplitude-event';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {notifyEvent} from '../../store/core/core.actions';

export function useAmplitude() {
  const dispatch = useDispatch();
  return useCallback(
    (eventName: AmplitudeEvent, payload: any = {}) => dispatch(notifyEvent(eventName, payload)),
    [dispatch]
  );
}

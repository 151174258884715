import * as React from 'react';
import classes from './error-viewer.module.scss';
import {AppErrorFigureProps} from './app-error-viewer.component';
import {ImagesResource} from '../../../../../../assets/images';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../../../constants/translation-keys';
import {useCallback, useContext, useMemo, useState} from 'react';
import {Button} from 'ui-components';
import {PanelKey} from '../../../../../../constants/panels';
import {ANALYSIS_RESULT_ID_PATH_PARAM, AppRoutes} from '../../../../../../constants/app-routes';
import {PanelType} from '../../../../../../objects/system/panel-type.enum';
import {AmplitudeEvent} from '../../../../../../constants/amplitude-event';
import {PanelsContext} from '../../../../../../core/contexts/panels.context';
import {useAmplitude} from '../../../../../../core/hooks/amplitude.hook';
import {contactSupport} from '../../../../../../utils/general.utils';
import {NoteText} from '../../../../components/general/note-text/note-text.component';
import {AnalysisTypeId} from '../../../../../../constants/analysis-type-id';
import {useHistory} from 'react-router';
import classNames from 'classnames';

export function Error2001Viewer(props: AppErrorFigureProps) {
  const {analysisResultId, analysisTypeId} = props;
  const {t} = useTranslation();
  const {openPrimaryPanel} = useContext(PanelsContext);
  const notify = useAmplitude();
  const history = useHistory();
  const [isDone, setIsDone] = useState(false);

  const options = useMemo(
    () =>
      t(
        analysisTypeId === AnalysisTypeId.A_B_TEST
          ? TransKeys.ANALYSIS_ERRORS.ERROR_2001.CONTENT.OPTIONS_FOR_EXPERIMENT
          : TransKeys.ANALYSIS_ERRORS.ERROR_2001.CONTENT.OPTIONS
      ).split('|'),
    [t, analysisTypeId]
  );
  const onDuplicate = useCallback(() => {
    const panelKey =
      analysisTypeId === AnalysisTypeId.A_B_TEST
        ? PanelKey.CHANGE_ANALYSIS_PARAMETERS_FORM_PANEL
        : PanelKey.ANALYSIS_FORM_PANEL;
    const eventKey =
      analysisTypeId === AnalysisTypeId.A_B_TEST
        ? AmplitudeEvent.CHANGE_EXPERIMENT_PARAMETERS_CLICKED
        : AmplitudeEvent.ANALYSIS_DUPLICATE_CLICKED;
    openPrimaryPanel(
      panelKey,
      {
        [ANALYSIS_RESULT_ID_PATH_PARAM]: analysisResultId,
        onSuccess: () => setIsDone(true),
      },
      PanelType.MODAL
    );
    notify(eventKey, {
      analysis_result_id: analysisResultId,
      source: 'error-2001',
    });
  }, [openPrimaryPanel, notify, analysisResultId, analysisTypeId, setIsDone]);
  const onBack = useCallback(() => {
    if (analysisTypeId === AnalysisTypeId.A_B_TEST) {
      history.push(AppRoutes.experiments());
      return;
    }
    history.push(AppRoutes.analyses());
  }, [history, analysisTypeId]);

  return (
    <div className={classes.ErrorFigure}>
      <div className={classes.Error}>
        <div className={classes.ImageWrapper}>
          <img src={ImagesResource.app.magnifyingGlass} alt={''} />
        </div>
        <div className={classNames(classes.Title, classes.NoMargin)}>
          {t(TransKeys.ANALYSIS_ERRORS.ERROR_2001.TITLE)}
        </div>
        <div className={classes.Title}>{t(TransKeys.ANALYSIS_ERRORS.ERROR_2001.SUB_TITLE)}</div>
        {!isDone && (
          <>
            <div className={classes.Content}>
              <ul className={classes.List}>
                {options.map((o, idx) => (
                  <li key={idx} className={classes.Option}>
                    <div className={classes.OptionContent}>{o}</div>
                  </li>
                ))}
              </ul>
            </div>
            <div className={classes.Button}>
              <Button size={'large'} onClick={onDuplicate}>
                {t(TransKeys.ANALYSIS_ERRORS.ERROR_2001.CONTENT.ACTION)}
              </Button>
            </div>
            <div className={classes.Support}>
              <NoteText
                text={t(TransKeys.SUPPORT.NEED_HELP)}
                buttonText={t(TransKeys.SUPPORT.SUPPORT_BUTTON_TEXT)}
                onButtonClicked={contactSupport}
              />
            </div>
          </>
        )}
        {isDone && (
          <Button className={classes.CTA} onClick={() => onBack()}>
            {t(TransKeys.ANALYSIS_ERRORS.ERROR_2001.CONTENT.ACTION_DONE)}
          </Button>
        )}
      </div>
    </div>
  );
}

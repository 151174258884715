import * as React from 'react';
import classNames from 'classnames';
import classes from './sql-query-builder.module.scss';
import {QueryBuilderFactory, Textarea} from 'ui-components';
import {
  METADATA_KEY,
  PARAMETERS_METADATA_KEY,
} from '../../../../../constants/parameters-saved-keys';
import {useCallback} from 'react';

interface OwnProps {
  query: any;
  onChange?: (query: any) => void;
  errors?: any;
  disabled?: boolean;
  className?: string;
}

type AllProps = OwnProps;

const createSQLInitialQuery = () => {
  const q = QueryBuilderFactory.createRawQuery();
  q[PARAMETERS_METADATA_KEY] = {
    [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: 'SQLQueryBuilder',
  };
  return q;
};

export const SQLQueryBuilder: React.FC<AllProps> = (props: AllProps) => {
  const {query, onChange, errors, disabled, className} = props;

  const onSQLChange = useCallback(
    (sql: string) => {
      const newQuery = query ? {...query} : createSQLInitialQuery();
      newQuery.value = sql;
      onChange(newQuery);
    },
    [query, onChange]
  );
  return (
    <div className={classNames(classes.SQLQueryBuilder, className)}>
      <Textarea
        error={Boolean(errors?.value)}
        value={query?.value}
        onChange={e => onSQLChange(e.target.value)}
        placeholder={'Write your SQL here'}
        disabled={disabled}
        fullWidth
      />
    </div>
  );
};

import * as React from 'react';
import {useEffect} from 'react';
import {SIGNAL_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {ModalLayout, SignalIcon, useRemoteSourceStated} from 'ui-components';
import classes from './view-signal-definition-panel.module.scss';
import {TitleWithIcon} from '../../../shared/components/general/title/title.component';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {getSignalNetworkRequest} from '../../../../http/signals.network-requests';
import {QuerySqlTabs} from '../../../shared/core/query-sql-tabs/query-sql-tabs.component';

interface OwnProps {
  [SIGNAL_ID_PATH_PARAM]?: number;
  onClose: () => void;
}

type AllProps = OwnProps;

export const ViewSignalDefinitionPanel: React.FC<AllProps> = (props: AllProps) => {
  const {[SIGNAL_ID_PATH_PARAM]: signalId, onClose} = props;
  const {
    source: signal,
    exec: getSignal,
    isLoading: isLoadingSignal,
  } = useRemoteSourceStated({
    networkRequest: getSignalNetworkRequest,
    initialValue: null,
  });
  useEffect(() => {
    signalId && getSignal(signalId);
  }, [getSignal, signalId]);

  return (
    <div
      style={{
        minHeight: '20rem',
        maxHeight: '60vh',
        minWidth: '60rem',
        maxWidth: '60vw',
      }}
    >
      <ModalLayout className={classes.ViewSignalDefinitionPanel} onClose={_ => onClose()}>
        <div className={classes.Wrapper}>
          {isLoadingSignal && <GenericLoading />}
          <TitleWithIcon className={classes.Title} icon={SignalIcon} text={signal?.name} />
          <div className={classes.Body}>
            {signal && <QuerySqlTabs query={signal.definition} signalId={signal.id} />}
          </div>
        </div>
      </ModalLayout>
    </div>
  );
};

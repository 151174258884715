import {useEffect, useCallback} from 'react';

export function useScroll(targetRef: any, containerRef: any, onTargetEnterView: any) {
  const scrollTargetIntoView = useCallback(() => {
    targetRef.current.scrollIntoView({
      block: 'start',
    });
  }, [targetRef]);

  useEffect(() => {
    const target = targetRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        entry.isIntersecting && onTargetEnterView();
      },
      {
        root: containerRef.current,
        rootMargin: '-50%',
      }
    );
    if (targetRef.current) {
      observer.observe(targetRef.current);
    }
    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, [targetRef, containerRef, onTargetEnterView]);

  return {scrollTargetIntoView};
}

import * as React from 'react';
import {
  MultiLoadResponse,
  SmartSelectorParameters,
  SmartSelectorSource,
  SmartSelector,
  useRemoteSourceStated,
} from 'ui-components';
import {useCallback, useContext, useEffect, useMemo, useRef} from 'react';
import {exists, HttpClientContext} from 'front-core';
import {signalSmartSelectorNetworkRequest} from '../../../../http/smart-selector.network-requests';
import classes from './smart-selector.module.scss';
import {SmartSelectorLayout} from './smart-selector-layout.component';
import {getSignalNetworkRequest} from '../../../../http/signals.network-requests';
import {SignalSmartSelectorKey} from './signal-smart-selector.component';
import {isArray, isEmpty, isObject} from 'lodash';
import {SignalPreview} from './components/previews/signal-preview.component';
import {CreatableSmartSelector, SmartSelectorSharedProps} from './smart-selector.shared';

interface OwnProps extends SmartSelectorSharedProps, CreatableSmartSelector {}

type AllProps = OwnProps;

export const SegmentForSignalSmartSelector: React.FC<AllProps> = (props: AllProps) => {
  const {
    placeholder,
    value,
    onChange: onChangeFromProps,
    onCreate: onCreateFromProps,
    filters: filtersFromProps,
    className,
    disabled,
    onSignalInfo,
    clearable,
    error,
    fullWidth,
    emptyState,
  } = props;
  const selectorRef = useRef<any>(null);
  const http = useContext(HttpClientContext);
  const {source: signal, exec} = useRemoteSourceStated({
    type: 'source',
    networkRequest: getSignalNetworkRequest,
    initialValue: null,
  });
  const selectedSignal = useMemo(
    () => (value && signal?.id === value ? signal : null),
    [value, signal]
  );
  const labelValue = useMemo(() => {
    if (value && selectedSignal) {
      return selectedSignal.name;
    }
  }, [value, selectedSignal]);
  const onChange = useCallback(
    (value: number, item?: any) => {
      selectorRef.current.close();
      onChangeFromProps(value, item);
    },
    [onChangeFromProps]
  );
  const onCreate = useCallback(() => {
    selectorRef.current.close();
    onCreateFromProps();
  }, [onCreateFromProps]);
  const filters = useMemo(() => {
    if (!filtersFromProps) {
      return undefined;
    }
    if (isArray(filtersFromProps) && filtersFromProps.length) {
      return filtersFromProps;
    } else if (isObject(filtersFromProps) && !isEmpty(filtersFromProps)) {
      return [filtersFromProps];
    }
    return undefined;
  }, [filtersFromProps]);
  const sources: SmartSelectorSource[] = useMemo(
    () => [
      {
        key: SignalSmartSelectorKey.SEGMENTS,
        name: `Segments`,
        onCreate: onCreateFromProps ? onCreate : undefined,
        createLabel: 'Create Segment',
        onSelect: item => onChange(item.signalId, item),
        renderItemPreview: item => (
          <SignalPreview label={'Segment'} data={item} onInfo={() => onSignalInfo(item.signalId)} />
        ),
      },
    ],
    [onChange, onCreate, onSignalInfo, onCreateFromProps]
  );
  const load = useCallback(
    async (keys: string[], parameters: SmartSelectorParameters): Promise<MultiLoadResponse> => {
      // @ts-ignore
      return await http.exec(
        signalSmartSelectorNetworkRequest({
          signalFilters: filters,
          page: parameters.page,
          q: parameters.query,
          limit: 10,
          keys,
        })
      );
    },
    [http, filters]
  );
  useEffect(() => {
    value && signal?.id !== value && exec(value);
  }, [value, exec, signal]);

  return (
    <SmartSelectorLayout
      id={'segment-selector'}
      label={labelValue}
      disabled={disabled}
      error={error}
      clearable={clearable && exists(value)}
      onClear={() => onChange(null)}
      placeholder={placeholder}
      className={className}
      ref={selectorRef}
      fullWidth={fullWidth}
    >
      <SmartSelector
        className={classes.Selector}
        sources={sources}
        load={load}
        emptyState={emptyState}
      />
    </SmartSelectorLayout>
  );
};

SegmentForSignalSmartSelector.defaultProps = {};

import * as React from 'react';
import classNames from 'classnames';
import classes from './simple-boolean-selector.module.scss';
import {ParameterInputWrapper} from '../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {useMemo} from 'react';
import {Checkbox, LabelWrapper} from 'ui-components';
import {hasErrors} from '../../../../../../utils/general.utils';
import {values} from 'lodash';

interface SchemaKeysMapping {
  boolean_key: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  boolean_key: 'boolean_key',
};

interface OwnProps {
  title: string;
  text?: string;
  onChange: (parameters: any) => void;
  value: any;
  schemaKeysMapping?: SchemaKeysMapping;
  subTitle?: string;
  helperText?: any;
  trueLabel?: any;
  falseLabel?: any;
  display?: 'vertical' | 'horizontal';
  errors?: any;
  className?: string;
}

type AllProps = OwnProps;

export const SimpleBooleanSelector: React.FC<AllProps> = (props: AllProps) => {
  const {
    title,
    text,
    subTitle,
    helperText,
    schemaKeysMapping,
    errors,
    value,
    trueLabel,
    falseLabel,
    display,
    onChange,
    className,
  } = props;

  const hasError = useMemo(
    () => hasErrors(errors, values(schemaKeysMapping)),
    [errors, schemaKeysMapping]
  );
  const booleanValue = useMemo(
    () => value[schemaKeysMapping.boolean_key],
    [value, schemaKeysMapping]
  );

  return (
    <ParameterInputWrapper
      title={title}
      subTitle={subTitle}
      className={classNames(
        classes.SimpleBooleanSelector,
        display === 'vertical' && classes.Vertical,
        className
      )}
      helperText={helperText}
      error={hasError}
    >
      <LabelWrapper label={text} error={hasError}>
        <div className={classes.BooleanSelector}>
          <div className={classes.Option}>
            <Checkbox
              checked={booleanValue === true}
              className={classes.Checkbox}
              onChange={() => onChange({[schemaKeysMapping.boolean_key]: true})}
            />
            <div
              className={classes.BooleanLabel}
              onClick={() => onChange({[schemaKeysMapping.boolean_key]: true})}
            >
              {trueLabel}
            </div>
          </div>
          <div className={classes.Option}>
            <Checkbox
              checked={booleanValue === false}
              className={classes.Checkbox}
              onChange={() => onChange({[schemaKeysMapping.boolean_key]: false})}
            />
            <div
              className={classes.BooleanLabel}
              onClick={() => onChange({[schemaKeysMapping.boolean_key]: false})}
            >
              {falseLabel}
            </div>
          </div>
        </div>
      </LabelWrapper>
    </ParameterInputWrapper>
  );
};

SimpleBooleanSelector.defaultProps = {
  schemaKeysMapping: DEFAULT_SCHEMA_KEYS_MAPPING,
  trueLabel: 'Yes',
  falseLabel: 'No',
  display: 'horizontal',
};

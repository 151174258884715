import {TableEntity} from '../objects/models/table.model';
import {
  CampaignIcon,
  FilterMoneyIcon,
  TeamIcon,
  UserIcon,
  createFontAwesomeIcon,
} from 'ui-components';
import {ProductEntities} from '../objects/models/product-data.model';
import {getter} from '../store/store.utils';
import {CORE_STORE_KEY} from '../store/core/core.store';

export const ENTITY_ICONS = {
  [TableEntity.USER]: UserIcon,
  [TableEntity.TEAM]: TeamIcon,
  [TableEntity.CAMPAIGN]: CampaignIcon,
  [TableEntity.LEAD]: FilterMoneyIcon,
};

export const getEntityIcon = (entity: TableEntity) => {
  const productEntities: ProductEntities = getter(CORE_STORE_KEY, 'productData.productEntities');
  if (productEntities[entity]?.icon) {
    return createFontAwesomeIcon(`fa-light fa-${productEntities[entity].icon}`);
  }
  return ENTITY_ICONS[entity];
};

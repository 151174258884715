import * as React from 'react';
import {
  AIConversationMessage,
  AIConversationRole,
  AIModelType,
  ModelsListAction,
  RunAnalysisAction,
} from '../../../../../../objects/models/ai-chat.model';
import {AnalysisType} from '../../../../../../objects/models/analysis-type.model';
import {Button, MarkdownViewerClasses, marked, TextButton} from 'ui-components';
import {useMemo} from 'react';
import {sanitize} from 'front-core';
import classes from './message-renderer.module.scss';
import classNames from 'classnames';

interface OwnProps {
  message: AIConversationMessage;
  analysisTypes: {[id: number]: AnalysisType};
  onRunAnalysisAction: (action: RunAnalysisAction) => void;
  onModelReference: (modelId: number, modelType: AIModelType) => void;
}

type AllProps = OwnProps;

const MarkdownMessageContent = (props: {text: string}) => {
  const rendered = useMemo(() => marked(props.text || ''), [props.text]);
  return (
    <div
      className={MarkdownViewerClasses.Markdown}
      dangerouslySetInnerHTML={{__html: sanitize(rendered)}}
    />
  );
};

export const MessageRenderer: React.FC<AllProps> = (props: AllProps) => {
  const {message, analysisTypes, onRunAnalysisAction, onModelReference} = props;

  if (message.role === AIConversationRole.ASSISTANT && message.content && !message.action) {
    return (
      <div className={classes.MessageContent}>
        <MarkdownMessageContent text={message.content} />
      </div>
    );
  }
  if (!message.action) {
    return (
      <div className={classes.MessageContent}>
        {message.content || (
          <div className={classes.JSON}>{JSON.stringify(message.message, null, 2)}</div>
        )}
      </div>
    );
  }

  const {text, type} = message.action;

  if (type === 'run_analysis') {
    const action = message.action as RunAnalysisAction;
    const analysisType = analysisTypes[action.analysisTypeId];
    return (
      <div className={classNames(classes.Action, classes.RunAnalysisActionContent)}>
        {analysisType && (
          <img className={classes.Image} src={analysisType.image} alt={analysisType.staticName} />
        )}
        <div className={classes.Content}>
          {analysisType && <div className={classes.Title}>{analysisType.staticName}</div>}
          <div className={classes.Text}>{text}</div>
          <Button
            variant={'outlined'}
            className={classes.ActionButton}
            onClick={() => onRunAnalysisAction(action)}
          >
            Run analysis
          </Button>
        </div>
      </div>
    );
  }
  if (type === 'models_list') {
    const action = message.action as ModelsListAction;
    return (
      <div className={classNames(classes.Action, classes.ModelListActionContent)}>
        <div className={classes.List}>
          {action.items.map(item => (
            <TextButton key={item.id} onClick={() => onModelReference(item.id, action.modelType)}>
              {item.name}
            </TextButton>
          ))}
        </div>
      </div>
    );
  }
};

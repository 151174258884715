import * as React from 'react';
import classNames from 'classnames';
import classes from './analyses-empty-state.module.scss';
import {AnalysisType} from '../../../../objects/models/analysis-type.model';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../constants/translation-keys';
import {Link} from 'ui-components';
import {AnalysisTypeCard} from '../analysis-type-card/analysis-type-card.component';

interface OwnProps {
  analysisTypes: AnalysisType[];
  onSelect: (analysisTypeId?: number) => void;
  className?: string;
}

type AllProps = OwnProps;

export const AnalysesEmptyState: React.FC<AllProps> = (props: AllProps) => {
  const {analysisTypes, onSelect, className} = props;
  const {t} = useTranslation();

  return (
    <div className={classNames(classes.AnalysesEmptyState, className)}>
      <div className={classes.Title}>{t(TransKeys.ANALYSES_EMPTY_STATE.TITLE)}</div>
      <div className={classes.SubTitle}>{t(TransKeys.ANALYSES_EMPTY_STATE.SUB_TITLE)}</div>
      <div className={classes.Options}>
        {analysisTypes.map(a => (
          <AnalysisTypeCard
            className={classes.Option}
            key={a.id}
            analysisType={a}
            onClick={() => onSelect(a.id)}
          />
        ))}
      </div>
      <div className={classes.ShowAllBtn}>
        <Link className={classes.Btn} onClick={() => onSelect()}>
          {t(TransKeys.ANALYSES_EMPTY_STATE.SEE_ALL)}
        </Link>
      </div>
    </div>
  );
};

import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useForm, FormProvider} from 'react-hook-form';
import {StringParam, useQueryParam} from 'use-query-params';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  SetPasswordFormDto,
  SetPasswordFormDTOValidator,
} from '../../../../objects/dto/set-password-form.dto';
import AuthScopeFormContainer from '../auth-scope/auth-scope-form-container/auth-scope-form-container.component';
import TransKeys from '../../../../constants/translation-keys';
import {useRemoteSource, useRemoteSourceStated} from 'ui-components';
import {
  getUserDataForSetPasswordNetworkRequest,
  setPasswordNetworkRequest,
} from '../../../../http/auth.network-requests';
import {showToastMessage} from '../../../../store/interface/interface.actions';
import {ToastType} from '../../../../objects/system/toast-type.enum';
import {useHistory} from 'react-router';
import AuthForm from '../auth-scope/auth-scope-form/auth-form.component';
import classes from '../shared-forms.module.scss';
import {AppRoutes} from '../../../../constants/app-routes';
import {AuthFormTextInput} from '../auth-scope/inputs/auth-text-input/auth-text-input.component';

interface OwnProps {}

type AllProps = OwnProps;

const SetPasswordForm: React.FC<AllProps> = (props: AllProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [token] = useQueryParam('token', StringParam);
  const [requestSucceeded, setRequestSucceeded] = useState(false);

  const formMethods = useForm<SetPasswordFormDto>({
    defaultValues: {
      password: '',
      verifyPassword: '',
      firstName: '',
      lastName: '',
    },
    resolver: yupResolver(SetPasswordFormDTOValidator),
  });
  const {handleSubmit, setValue} = formMethods;

  const {exec: getUserDataForSetPassword, isLoading: isLoadingUserData} = useRemoteSourceStated({
    networkRequest: () => getUserDataForSetPasswordNetworkRequest({token}),
    type: null,
    onSuccess: data => {
      setValue('firstName', data.firstName);
      setValue('lastName', data.lastName);
    },
  });

  const {exec: execSetPassword, isLoading} = useRemoteSource({
    networkRequest: setPasswordNetworkRequest,
    type: null,
    onSuccess: () => {
      dispatch(
        showToastMessage(t(FORM_TRANS_KEYS.TOASTS_MESSAGES.HTTP.SUCCESS), ToastType.SUCCESS)
      );
      setRequestSucceeded(true);
      setTimeout(() => {
        window.location.href = '/';
      }, 1500);
    },
    onError: () => {
      setRequestSucceeded(false);
      dispatch(showToastMessage(t(FORM_TRANS_KEYS.TOASTS_MESSAGES.HTTP.ERROR), ToastType.ERROR));
    },
  });

  const onSubmit = useCallback(
    async (formData: SetPasswordFormDto) => await execSetPassword({...formData, token: token}),
    [execSetPassword, token]
  );

  useEffect(() => {
    getUserDataForSetPassword();
  }, [getUserDataForSetPassword]);

  const FORM_TRANS_KEYS =
    location.pathname.split('-')[0].substring(1) === 'reset'
      ? TransKeys.AUTH_FORMS.RESET_PASSWORD
      : TransKeys.AUTH_FORMS.SET_PASSWORD;

  return (
    <AuthScopeFormContainer>
      <FormProvider {...formMethods}>
        <AuthForm
          disableOnSubmit={isLoading || isLoadingUserData || requestSucceeded}
          onSubmit={handleSubmit(onSubmit)}
          onSubmitText={t(FORM_TRANS_KEYS.LABELS.SUBMIT)}
          subTitle={t(FORM_TRANS_KEYS.FORM_SUB_HEADING)}
          title={t(FORM_TRANS_KEYS.FORM_HEADING)}
          renderLeftToSubmit={
            <span className={classes.Footer} onClick={() => history.push(AppRoutes.login())}>
              {t(TransKeys.AUTH_FORMS.SHARED_LABELS.FOOTER.BACK)}
            </span>
          }
        >
          <div className={classes.FormBlock}>
            <AuthFormTextInput
              required
              label={t(TransKeys.AUTH_FORMS.SHARED_LABELS.FIRST_NAME)}
              name={'firstName'}
              type={'text'}
            />
          </div>
          <div className={classes.FormBlock}>
            <AuthFormTextInput
              required
              label={t(TransKeys.AUTH_FORMS.SHARED_LABELS.LAST_NAME)}
              name={'lastName'}
              type={'text'}
            />
          </div>
          <div className={classes.FormBlock}>
            <AuthFormTextInput
              required
              label={t(FORM_TRANS_KEYS.LABELS.PASSWORD)}
              name={'password'}
              type={'password'}
              helperText={t(TransKeys.AUTH_FORMS.SHARED_ERRORS.VERIFY_PASSWORD)}
            />
          </div>
          <div className={classes.FormBlock}>
            <AuthFormTextInput
              required
              label={t(FORM_TRANS_KEYS.LABELS.VERIFY_PASSWORD)}
              name={'verifyPassword'}
              type={'password'}
            />
          </div>
        </AuthForm>
      </FormProvider>
    </AuthScopeFormContainer>
  );
};

export default SetPasswordForm;

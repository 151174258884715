import {exists, withStopPropagation} from 'front-core';
import {Experiment} from '../../../../../../../../../objects/models/experiment.model';
import classes from './experiment-primary-kpi-cell.module.scss';
import {ExperimentTrendChip} from './experiment-primary-kpi-trend-chip.component';
import {ListRowItemCell} from '../../../../../../../../shared/components/general/list-row-item-cell/list-row-item-cell.component';
import {TooltipIfOverflow} from 'ui-components';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../../../../../../constants/translation-keys';

type ExperimentPrimaryKPICellProps = {
  experiment: Experiment;
  onClick: (mainKpiId: number) => void;
  className?: string;
};

export const ExperimentPrimaryKPICell = (props: ExperimentPrimaryKPICellProps) => {
  const {experiment, onClick, className} = props;

  const {t} = useTranslation();

  const moreKPIs =
    experiment.analysis?.parameters?.secondaryGoal ??
    experiment.analysis?.parameters?.secondaryConversionEvents;

  const hasMoreKPIs = exists(moreKPIs) && moreKPIs.length > 0;

  return (
    <ListRowItemCell
      className={className}
      title={t(TransKeys.EXPERIMENTS.PRIMARY_KPI_CELL.TITLE)}
      content={
        <div className={classes.PrimaryKPIContent}>
          <span className={classes.NameWrapper}>
            <TooltipIfOverflow title={experiment.mainKpi.name} interactive={false}>
              <span
                onClick={withStopPropagation(() => onClick(experiment.mainKpi.id))}
                className={classes.Name}
              >
                {experiment.mainKpi.name}
              </span>
            </TooltipIfOverflow>
            <ExperimentTrendChip experiment={experiment} />
            {hasMoreKPIs && <span className={classes.More}>{`+${moreKPIs.length} More`}</span>}
          </span>
        </div>
      }
    />
  );
};

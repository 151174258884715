import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import classes from '../../analysis-forms.module.scss';
import {LabelWrapper, SwitchActions} from 'ui-components';
import {ParameterInputWrapper} from '../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {TimeFrameSelector} from '../../components/ui-selectors/time-frame-selector/time-frame-selector.component';
import {values} from 'lodash';
import {ParametersFormContext} from '../../../../shared/core/parameters-form/parameters-form.context';
import {startEndDatesDefaultHandler} from '../../../../shared/core/parameters-form/parameters-form-default-handlers.utils';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';
import {SegmentFilterSelector} from '../../components/ui-selectors/segment-filter-selector/segment-filter-selector.component';
import TransKeys from '../../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {ExtendedParameters} from '../../../../shared/form/form-layout/extended-parameters/extended-parameters.component';
import {TableEntity, TableEntityBinding} from '../../../../../objects/models/table.model';
import {EntitySelector} from '../../components/ui-selectors/entity-selector/entity-selector.component';
import {createUndefinedObject, hasError} from '../../../../../utils/general.utils';
import {AnalysisFormProps} from '../../analysis-forms.types';
import {TimeGranularitySelector} from '../../components/ui-selectors/time-granularity-selector/time-granularity-selector.component';
import {PanelKey} from '../../../../../constants/panels';
import {ALLOW_AD_HOC_PROP_KEY} from '../../../../../constants/shared-component-prop-key';
import {METRIC_ID_PATH_PARAM} from '../../../../../constants/app-routes';
import {Metric} from '../../../../../objects/models/metric.model';
import {PanelsContext} from '../../../../../core/contexts/panels.context';
import {MetricForSignalSmartSelector} from '../../../../shared/core/smart-selector/metric-for-signal-smart-selector.component';
import {Action, Subject} from '../../../../../constants/permissions';
import usePermissions from '../../../../../core/hooks/use-permissions.hook';
import {SegmentCategoriesSelector} from '../../components/ui-selectors/segment-categories-selector/segment-categories-selector.component';
import {SignalDataType, SignalType} from '../../../../../objects/models/signal.model';
import {AnalysisParametersFormMode} from '../../../panels/analysis-form-panel/components/analysis-parameters-tab/analysis-parameters-tab.component';
import {AnalysisSelectorVariant} from '../../components/ui-selectors/analysis-selector.types';
import {
  AnalysisFormPart,
  AnalysisFormSentence,
  AnalysisSimpleForm,
} from '../../components/simple-form-layout/analysis-simple-form.component';
import {useMetricKPIAnalysis} from '../../hooks/use-metric-kpi-analysis.hook';
import {PARAMETERS_METADATA_KEY} from '../../../../../constants/parameters-saved-keys';

export const GOAL_TYPE_KEY = 'goal_type';
export const GOAL_KEY = 'goal';
export const QUERY_GOAL_KEY = 'goal_query';
export const ANALYSIS_MODE_KEY = 'analysis_mode';
const entitySchemaMapping = {
  entity: 'entity',
};
const TIME_GRANULARITY_SCHEMA_MAPPING = {
  time_granularity: 'time_aggregation',
};
const segmentFilterSchemaMapping = {
  population_filter: 'population_filter',
};
const segmentCategoriesSchemaMapping = {
  included_segments_tag: 'included_segments_tag',
  included_segments_signals: 'included_segments_signals',
};

export const createKPISignalFilters = (entityContext = undefined) => [
  {
    type: SignalType.DIMENSION,
    data_type: [
      SignalDataType.INTEGER,
      SignalDataType.DECIMAL,
      SignalDataType.BOOLEAN,
      SignalDataType.TIMESTAMP,
    ],
    with_population_filter: true,
    entity_binding: TableEntityBinding.ONE_WAY,
    entityContext,
  },
  {
    type: SignalType.MEASURE,
    data_type: [
      SignalDataType.INTEGER,
      SignalDataType.DECIMAL,
      SignalDataType.BOOLEAN,
      SignalDataType.TIMESTAMP,
    ],
    with_population_filter: true,
    entity_binding: TableEntityBinding.TWO_WAY,
    entityContext,
  },
];

export const Analysis101Form: React.FC<AnalysisFormProps> = (props: AnalysisFormProps) => {
  const {onSignalInfo, className} = props;
  const {
    errors,
    parameters,
    formMode,
    changeParametersValue,
    registerDefaultHandler,
    removeDefaultHandler,
  } = useContext(ParametersFormContext);
  const {openSecondaryPanel} = useContext(PanelsContext);
  const {t} = useTranslation();
  const {can} = usePermissions();
  const [isOpenAdvancedParams, setIsOpenAdvancedParams] = useState(false);
  const {productEntities, defaultTableEntity} = useProductData();
  const {[entitySchemaMapping.entity]: entityContext} = parameters;

  useEffect(() => {
    registerDefaultHandler('start_end_dates', startEndDatesDefaultHandler);
    registerDefaultHandler('analysis_101', () => {
      let defaults: any = {
        [entitySchemaMapping.entity]: defaultTableEntity,
        [TIME_GRANULARITY_SCHEMA_MAPPING.time_granularity]: 'week',
      };
      return defaults;
    });

    return () => {
      removeDefaultHandler('start_end_dates');
      removeDefaultHandler('analysis_101');
    };
  }, [registerDefaultHandler, removeDefaultHandler, defaultTableEntity]);
  // Memos
  const metricFilters = useMemo(() => createKPISignalFilters(entityContext), [entityContext]);
  const {onMetricSelected, showSelectMode, usageActions} = useMetricKPIAnalysis();

  const isOpenAdvancedParamsOrError = useMemo(() => {
    return isOpenAdvancedParams || hasError(errors, [...values(TIME_GRANULARITY_SCHEMA_MAPPING)]);
  }, [isOpenAdvancedParams, errors]);
  // Callbacks
  const onChangeEntityContext = useCallback(
    (entity: TableEntity) => {
      const resetKeys = [
        GOAL_KEY,
        ANALYSIS_MODE_KEY,
        segmentFilterSchemaMapping.population_filter,
        ...values(segmentCategoriesSchemaMapping),
      ];
      const resetParameters = createUndefinedObject(resetKeys);
      changeParametersValue({
        [entitySchemaMapping.entity]: entity,
        ...resetParameters,
        // Reset metadata see useMetricKPIAnalysis
        [PARAMETERS_METADATA_KEY]: {},
      });
    },
    [changeParametersValue]
  );
  const onCreateKPI = useCallback(
    (metricId?: number) => {
      openSecondaryPanel(PanelKey.METRIC_FORM_PANEL, {
        [ALLOW_AD_HOC_PROP_KEY]: true,
        [METRIC_ID_PATH_PARAM]: metricId,
        onSuccess: (metric: Metric) => {
          onMetricSelected(metric.signalId, metric);
        },
      });
    },
    [openSecondaryPanel, onMetricSelected]
  );

  const renderKPISelector = (inline = false) => (
    <MetricForSignalSmartSelector
      placeholder={inline ? 'KPI' : t(TransKeys.ANALYSIS_FORMS.ANALYSIS_101.GOAL.SELECT_EXISTING)}
      value={parameters[GOAL_KEY]}
      error={Boolean(errors?.[GOAL_KEY])}
      onChange={onMetricSelected}
      onCreate={can(Subject.METRIC, Action.CREATE) ? onCreateKPI : undefined}
      onSignalInfo={onSignalInfo}
      filters={metricFilters}
      addButton={
        can(Subject.METRIC, Action.CREATE) && formMode === AnalysisParametersFormMode.ADVANCED
      }
    />
  );

  const renderAdvancedMode = () => (
    <div className={classNames(classes.AnalysisForm, className)}>
      <EntitySelector
        value={parameters}
        productEntities={productEntities}
        schemaKeysMapping={entitySchemaMapping}
        onChange={v => onChangeEntityContext(v[entitySchemaMapping.entity])}
        className={classes.Parameter}
      />
      <ParameterInputWrapper
        title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_101.GOAL.TITLE)}
        subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_101.GOAL.SUB_TITLE)}
        helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_101.GOAL.HELPER_TEXT)}
        className={classes.Parameter}
        error={errors?.[GOAL_KEY]}
      >
        {renderKPISelector()}
        {showSelectMode && (
          <>
            <div className={classes.MarginBottom} />
            <LabelWrapper label={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_101.DEFINE_KPI.SELECT_MODE)}>
              <SwitchActions showActionsLabel actions={usageActions} />
            </LabelWrapper>
          </>
        )}
      </ParameterInputWrapper>
      {/*used to solve race condition in SegmentCategoriesForAnalysisSmartSelector*/}
      {entityContext && (
        <SegmentCategoriesSelector
          onChange={changeParametersValue}
          value={parameters}
          schemaKeysMapping={segmentCategoriesSchemaMapping}
          className={classes.Parameter}
          entityContext={entityContext}
          errors={errors}
        />
      )}
      <TimeFrameSelector className={classes.Parameter} errors={errors} />
      {entityContext && (
        <SegmentFilterSelector
          onChange={changeParametersValue}
          value={parameters}
          className={classes.Parameter}
          schemaKeysMapping={segmentFilterSchemaMapping}
          entityContext={entityContext}
          errors={errors}
        />
      )}
      <ExtendedParameters
        className={classes.SpaceBottom}
        label={t(TransKeys.GENERAL.LABELS.ADVANCED_PARAMETERS)}
        isOpen={isOpenAdvancedParamsOrError}
        onOpenChanged={() => setIsOpenAdvancedParams(!isOpenAdvancedParams)}
      >
        <TimeGranularitySelector
          value={parameters}
          schemaKeysMapping={TIME_GRANULARITY_SCHEMA_MAPPING}
          onChange={changeParametersValue}
          errors={errors}
        />
      </ExtendedParameters>
    </div>
  );

  const renderSimpleMode = () => (
    <AnalysisSimpleForm
      parameters={parameters}
      errors={errors}
      changeParametersValue={changeParametersValue}
      onChangeEntityContext={onChangeEntityContext}
    >
      <AnalysisFormSentence>
        <AnalysisFormPart>
          {t(TransKeys.ANALYSIS_FORMS.ANALYSIS_101.SIMPLE.SEGMENT_SELECTOR_PREFIX)}
        </AnalysisFormPart>
        <AnalysisFormPart>
          {entityContext && (
            <SegmentCategoriesSelector
              onChange={changeParametersValue}
              value={parameters}
              schemaKeysMapping={segmentCategoriesSchemaMapping}
              className={classes.Parameter}
              entityContext={entityContext}
              errors={errors}
              variant={AnalysisSelectorVariant.INLINE}
            />
          )}
        </AnalysisFormPart>
        <AnalysisFormPart>
          {t(TransKeys.ANALYSIS_FORMS.ANALYSIS_101.SIMPLE.KPI_SELECTOR_PREFIX)}
        </AnalysisFormPart>
        <AnalysisFormPart>{renderKPISelector(true)}</AnalysisFormPart>
      </AnalysisFormSentence>
    </AnalysisSimpleForm>
  );

  return formMode === AnalysisParametersFormMode.ADVANCED
    ? renderAdvancedMode()
    : renderSimpleMode();
};

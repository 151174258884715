import {Select, TextInput} from 'ui-components';

interface OwnProps {
  allowFreeInput: boolean;
  placeholder: string;
  name: string;
  disabled?: boolean;
  onChange: (value: string) => void;
  value: string;
  error: boolean;
  options: any;
  className?: string;
}

type AllProps = OwnProps;

export const ColumnInput: React.FC<AllProps> = (props: AllProps) => {
  const {allowFreeInput, placeholder, disabled, onChange, value, error, options, className} = props;

  if (allowFreeInput || options?.options.length === 0) {
    return (
      <TextInput
        className={className}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={v => onChange(v as string)}
        error={error}
        fullWidth
      />
    );
  }

  return (
    <Select
      value={value}
      onChange={value => onChange(value as any)}
      placeholder={placeholder}
      disabled={disabled}
      error={error}
      options={options}
      searchable={false}
      clearable={false}
      capitalize={false}
      fullWidth
    />
  );
};

import * as React from 'react';
import classes from './panel-layout.module.scss';
import {CloseModalButton} from 'ui-components';

interface OwnProps {
  onClose?: (e) => void;
  children: any;
}

type AllProps = OwnProps;

export const PanelLayout: React.FC<AllProps> = ({children, onClose}: AllProps) => {
  return (
    <div className={classes.PanelLayout}>
      {onClose && (
        <div className={classes.Close}>
          <CloseModalButton onClick={onClose} className={classes.BackIcon} />
        </div>
      )}
      {children}
    </div>
  );
};

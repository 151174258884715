import * as React from 'react';
import {useEffect, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import {get} from 'lodash';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {
  FancyBlock,
  useRemoteSourceStated,
  RCAMode,
  TeamSelector,
  UserGroupLightIcon,
} from 'ui-components';
import TransKeys from 'translations';
import {getRCAInsightsExecutiveSummeryNetworkRequest} from '../../../../../../../../http/insights.network-requests';
import {GenericLoading} from '../../../../../../components/general/generic-loading/generic-loading.component';
import classes from './rca-executive-summery.module.scss';
import {
  ANALYSIS_RESULT_ID_PATH_PARAM,
  SIGNAL_ID_PATH_PARAM,
} from '../../../../../../../../constants/app-routes';
import {AnalysisResultStatus} from '../../../../../../../../objects/models/analysis-result.model';
import {TIME_FORMATS} from '../../../../../../../../constants/time-formats';
import {RCAExecutiveSummaryOrchestrator} from './components/rca-executive-summary-orchestrator/rca-executive-summary-orchestrator.component';
import {Team} from '../../../../../../../../objects/models/team.model';

interface OwnProps {
  mode: RCAMode;
  analyzedDate: string;
  resultId: number;
  resultStatus: AnalysisResultStatus;
  signalId: number;
  teamId: number;
  setTeamId: (teamId: number) => void;
  teams: Team[];
  className?: string;
}

type AllProps = OwnProps;

const RCA_SERIALIZATION_ERROR_CODE = 4000;

const LoadingComponent: React.FC = () => {
  const {t} = useTranslation();
  return (
    <FancyBlock title={t(TransKeys.DOCUMENT_VIEWER.RCA_EXECUTIVE_SUMMARY.TITLE)} collapsible={true}>
      <div className={classNames(classes.RCAExecutiveSummaryContainer, classes.Loading)}>
        <GenericLoading />
      </div>
    </FancyBlock>
  );
};

export const RCAExecutiveSummery: React.FC<AllProps> = (props: AllProps) => {
  const {
    [SIGNAL_ID_PATH_PARAM]: signalId,
    [ANALYSIS_RESULT_ID_PATH_PARAM]: resultId,
    analyzedDate,
    mode,
    resultStatus,
    teamId,
    setTeamId,
    teams,
    className,
  } = props;
  const analysisResultIdRef = useRef<number>(resultId);
  analysisResultIdRef.current = resultId;
  const [deserializationError, setDeserializationError] = useState<boolean | null>(null);

  const {t} = useTranslation();
  const {
    source: executiveSummary,
    exec: getExecutiveSummary,
    isLoading,
  } = useRemoteSourceStated({
    type: 'source',
    networkRequest: getRCAInsightsExecutiveSummeryNetworkRequest,
    onError: err => {
      const errorCode = get(err, 'data.error_code');
      if (errorCode === RCA_SERIALIZATION_ERROR_CODE) {
        setDeserializationError(true);
      }
    },
  });

  const showLoading = useMemo(
    () => isLoading || !executiveSummary || resultStatus === AnalysisResultStatus.RUNNING,
    [isLoading, executiveSummary, resultStatus]
  );
  const showTeamSelector = teams.length > 0;
  const teamOptions = useMemo(
    () => [
      {
        id: null,
        name: 'All',
        icon: UserGroupLightIcon,
      },
      ...teams,
    ],
    [teams]
  );

  useEffect(() => {
    const params = {
      signalId,
      resultId: analysisResultIdRef.current,
      analyzedDate: moment.utc(analyzedDate).format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT),
      teamId,
    };
    getExecutiveSummary(params);
  }, [getExecutiveSummary, signalId, analyzedDate, teamId]);

  if (
    deserializationError ||
    [AnalysisResultStatus.RUNNING, AnalysisResultStatus.COMPLETED].includes(resultStatus) === false
  ) {
    return null;
  }

  if (showLoading) {
    return <LoadingComponent />;
  }

  return (
    <FancyBlock title={t(TransKeys.DOCUMENT_VIEWER.RCA_EXECUTIVE_SUMMARY.TITLE)} collapsible={true}>
      <div className={classNames(classes.RCAExecutiveSummaryContainer, className)}>
        <div className={classes.TeamSelectorWrapper}>
          {showTeamSelector && (
            <TeamSelector
              value={teamId}
              onChange={setTeamId}
              teams={teamOptions}
              clearable={false}
              withPrefix
            />
          )}
        </div>
        <RCAExecutiveSummaryOrchestrator executiveSummary={executiveSummary} mode={mode} />
      </div>
    </FancyBlock>
  );
};

import {NRC} from './index';
import {RequestType, toSnakeCase} from 'front-core';
import {toCamelCase} from 'front-core';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';
import {EventGroupDTO} from '../objects/dto/event-group.dto';

export const getEventGroupNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/event-groups/${id}`,
  responseTransformer: res => toCamelCase(res.data, ['signal_definition']),
});

export const getEventGroupsNetworkRequest: NRC<void, any> = query => ({
  method: RequestType.GET,
  relativeUrl: `/app/event-groups`,
  query: query,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data, ['data.*.signal_definition']),
});

export const createEventGroupNetworkRequest: NRC<EventGroupDTO, void> = (data: EventGroupDTO) => ({
  method: RequestType.POST,
  relativeUrl: `/app/event-groups`,
  body: data,
  bodyTransformer: data => toSnakeCase(data, ['signal_definition']),
  responseTransformer: res => toCamelCase(res.data, ['signal_definition']),
});

export const editEventGroupNetworkRequest: NRC<EventGroupDTO, void> = (data: EventGroupDTO) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/event-groups/${data.id}`,
  body: data,
  bodyTransformer: data => toSnakeCase(data, ['signal_definition']),
  responseTransformer: res => toCamelCase(res.data, ['signal_definition']),
});

export const deleteEventGroupNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.DELETE,
  relativeUrl: `/app/event-groups/${id}`,
  responseTransformer: toCamelCase,
});

export const validateEventGroupNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/event-groups/${id}/validate`,
  responseTransformer: res => toCamelCase(res.data, ['signal_definition']),
});

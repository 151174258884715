import {useCallback, useContext, useMemo, useState} from 'react';
import {StepProps} from '../weekly-interest-form-panel.component';
import {useTranslation} from 'react-i18next';
import classes from './steps.module.scss';
import {ModelCategoryBadge} from '../../../../shared/form/components/model-categories-input/model-category-badge.component';
import {Button, Link, ModelType, Select, Textarea} from 'ui-components';
import {FunnelSmartSelector} from '../../../../shared/core/smart-selector/funnel-smart-selector.component';
import {FeatureSmartSelector} from '../../../../shared/core/smart-selector/feature-smart-selector.component';
import {MetricForSignalSmartSelector} from '../../../../shared/core/smart-selector/metric-for-signal-smart-selector.component';
import {PanelsContext} from '../../../../../core/contexts/panels.context';
import {SIGNAL_ID_PATH_PARAM} from '../../../../../constants/app-routes';
import {PanelKey} from 'src/constants/panels';
import TransKeys from 'translations';
import classnames from 'classnames';
import {getTopicOptions} from '../../../../analyses/panels/analysis-form-panel/components/analysis-type-selection-tab/analysis-type-selection-tab.utils';

const TOPIC_MODEL_TYPES = [ModelType.FUNNEL, ModelType.METRIC];

export const InterestModelSelectionStep: React.FC<StepProps> = (props: StepProps) => {
  const {data, onNextStep} = props;
  const {t} = useTranslation();
  const {category, modelType} = data;
  const [other, setOther] = useState<string>(null);
  const [signalId, setSignalId] = useState<number>(null);
  const [modelId, setModelId] = useState<number>(null);
  const [model, setModel] = useState<any>(null);
  const [topic, setTopic] = useState<string>(null);
  const {openSecondaryPanel} = useContext(PanelsContext);

  const othersOpened = other !== null;

  const onSignalInfo = useCallback(
    signalId =>
      openSecondaryPanel(PanelKey.SIGNAL_DEFINITION_PANEL, {
        [SIGNAL_ID_PATH_PARAM]: signalId,
      }),
    [openSecondaryPanel]
  );

  const onSetModel = useCallback(
    (id, model) => {
      setSignalId(id);
      setModelId(model.id);
      setModel(model);
    },
    [setModelId, setModel, setSignalId]
  );

  const onSetTopic = useCallback(
    (topic: string) => {
      setTopic(topic);
    },
    [setTopic]
  );

  const onNext = useCallback(() => {
    onNextStep({modelName: model?.name, topic, modelId, other});
  }, [onNextStep, model, modelId, topic, other]);

  const onOtherClick = useCallback(() => {
    setOther(other === null ? '' : null);
  }, [other, setOther]);

  const onOtherChange = useCallback(
    e => {
      setOther(e.target.value);
    },
    [setOther]
  );

  const disabled = useMemo(() => {
    // if others opened, need to fill it
    if (othersOpened) {
      return other === '';
    }

    // if model type requires topic, need to fill it
    if (TOPIC_MODEL_TYPES.includes(modelType) && !topic) {
      return true;
    }

    // need to select model
    return !model;
  }, [model, topic, other, modelType, othersOpened]);

  const renderModelSelector = useCallback(() => {
    if (modelType === ModelType.FUNNEL) {
      return (
        <FunnelSmartSelector
          placeholder={t(
            TransKeys.WEEKLY_INTEREST_FORM_PANEL.STEP_2_MODEL.INPUTS.MODEL.FUNNEL.PLACEHOLDER
          )}
          value={signalId}
          onChange={onSetModel}
          onSignalInfo={onSignalInfo}
          fullWidth
        />
      );
    }
    if (modelType === ModelType.FEATURE) {
      return (
        <FeatureSmartSelector
          placeholder={t(
            TransKeys.WEEKLY_INTEREST_FORM_PANEL.STEP_2_MODEL.INPUTS.MODEL.FEATURE.PLACEHOLDER
          )}
          value={signalId}
          onChange={onSetModel}
          onSignalInfo={onSignalInfo}
          fullWidth
        />
      );
    }
    return (
      <MetricForSignalSmartSelector
        placeholder={t(
          TransKeys.WEEKLY_INTEREST_FORM_PANEL.STEP_2_MODEL.INPUTS.MODEL.METRIC.PLACEHOLDER
        )}
        value={signalId}
        onChange={onSetModel}
        onSignalInfo={onSignalInfo}
        fullWidth
      />
    );
  }, [onSetModel, onSignalInfo, signalId, t, modelType]);

  const topicOptions = useMemo(
    () => ({options: model ? getTopicOptions(modelType as any, model) : []}),
    [model, modelType]
  );

  return (
    <>
      <div className={classes.Title}>
        {t(TransKeys.WEEKLY_INTEREST_FORM_PANEL.STEP_2_MODEL.TITLE, {model: modelType})}
      </div>
      <div className={classes.Description}>
        {t(TransKeys.WEEKLY_INTEREST_FORM_PANEL.STEP_2_MODEL.DESCRIPTION)}
      </div>
      <div className={classes.Body}>
        <div className={classes.SelectedModelDetails}>
          <div className={classes.SelectedModel}>
            <ModelCategoryBadge category={category} />
          </div>
          <div className={classes.Details}>
            {!othersOpened && (
              <>
                <div className={classes.Detail}>
                  <span>
                    {t(TransKeys.WEEKLY_INTEREST_FORM_PANEL.STEP_2_MODEL.INPUTS.MODEL.LABEL)}
                  </span>
                  {renderModelSelector()}
                </div>
                {TOPIC_MODEL_TYPES.includes(modelType) && (
                  <div className={classes.Detail}>
                    <span>
                      {t(TransKeys.WEEKLY_INTEREST_FORM_PANEL.STEP_2_MODEL.INPUTS.TOPIC.LABEL)}
                    </span>
                    <Select
                      value={topic}
                      onChange={value => onSetTopic(value as string)}
                      options={topicOptions}
                      dropdownButtonClassName={classes.SelectUnit}
                      searchable={false}
                      clearable={false}
                      capitalize={false}
                      disabled={!model}
                      placeholder={t(
                        model
                          ? TransKeys.WEEKLY_INTEREST_FORM_PANEL.STEP_2_MODEL.INPUTS.MODEL.TOPIC
                              .PLACEHOLDER
                          : TransKeys.WEEKLY_INTEREST_FORM_PANEL.STEP_2_MODEL.INPUTS.MODEL.TOPIC
                              .NO_MODEL_PLACEHOLDER
                      )}
                    />
                  </div>
                )}
              </>
            )}
            <div className={classnames(classes.Detail, classes.Other)}>
              <Link onClick={onOtherClick} className={classes.Secondary} underline={false}>
                {t(TransKeys.WEEKLY_INTEREST_FORM_PANEL.STEP_2_MODEL.INPUTS.OTHER.HELPER_TEXT)}
              </Link>
              {othersOpened && <Textarea value={other} onChange={onOtherChange} fullWidth />}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.Footer}>
        <Button className={classes.Button} onClick={onNext} disabled={disabled}>
          {t(TransKeys.GENERAL.ACTIONS.NEXT)}
        </Button>
      </div>
    </>
  );
};

import {BaseModel} from './base.model';
import {TableEntity} from './table.model';

export enum ValidationStatus {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  UNKNOWN = 'unknown',
}

export enum SignalType {
  DIMENSION = 'dimension',
  MEASURE = 'measure',
  METRIC = 'metric',
  PROPERTY = 'property',
}

export enum SignalDataType {
  STRING = 'string',
  INTEGER = 'integer',
  DECIMAL = 'decimal',
  BOOLEAN = 'boolean',
  TIMESTAMP = 'timestamp',
}

export enum AggregationStrategy {
  SUM = 'sum',
  AVERAGE = 'average',
  WEIGHTED_AVERAGE = 'weighted_average',
  MIN = 'min',
  MAX = 'max',
  FIRST_VALUE = 'first_value',
  LAST_VALUE = 'last_value',
}

export enum AggregationMode {
  COUNT_DISTINCT_FROM_X = 'count_distinct_from_x',
  COUNT_ENTITIES = 'count_entities',
  SUM_FROM_X = 'sum_from_x',
  SUM_FROM_TOTAL = 'sum_from_total',
  SUM = 'sum',
}

export interface Signal extends BaseModel {
  id: number;
  name: string;
  type: string;
  entity: TableEntity;
  dataType: string;
  tags: string[];
  shortDescription: string;
  definition?: any;
  timeAggregationStrategy?: AggregationStrategy;
  dimensionAggregationStrategy?: AggregationStrategy;
  validationStatus?: ValidationStatus;
}

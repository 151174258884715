import * as React from 'react';
import classNames from 'classnames';
import classes from './step-title.module.scss';

interface OwnProps {
  title: string;
  subTitle?: string;
  className?: string;
}

type AllProps = OwnProps;

export const StepTitle: React.FC<AllProps> = (props: AllProps) => {
  const {title, subTitle, className} = props;

  return (
    <div className={classNames(classes.StepTitle, className)}>
      <div className={classes.Title}>{title}</div>
      {subTitle && <div className={classes.SubTitle}>{subTitle}</div>}
    </div>
  );
};

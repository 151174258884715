import {action, OnSuccessActionHook} from 'front-core';
import {showConfirmationMessage} from '../interface/interface.actions';
import i18n from '../../config/i18n.config';
import TransKeys from '../../constants/translation-keys';
import {AnnotationDTO} from '../../objects/dto/annotation.dto';

export enum AnnotationsActionType {
  CREATE_ANNOTATION = '@@ANNOTATIONS/CREATE_EXPERIMENT',
  UPDATE_ANNOTATION = '@@ANNOTATIONS/UPDATE_ANNOTATION',
  UPDATE_ANNOTATION_STATUS = '@@ANNOTATIONS/UPDATE_ANNOTATION_STATUS',
  DELETE_ANNOTATION = '@@ANNOTATIONS/DELETE_ANNOTATION',
}

export const createAnnotation = (data: AnnotationDTO, onSuccess?: OnSuccessActionHook) =>
  action(AnnotationsActionType.CREATE_ANNOTATION, data, {onSuccess});

export const updateAnnotation = (data: AnnotationDTO, onSuccess?: OnSuccessActionHook) =>
  action(AnnotationsActionType.UPDATE_ANNOTATION, data, {onSuccess});

export const updateAnnotationStatus = (data: AnnotationDTO, onSuccess?: OnSuccessActionHook) =>
  action(AnnotationsActionType.UPDATE_ANNOTATION_STATUS, data, {onSuccess});

export const updateAnnotationStatusConfirmed = (
  title: string,
  content: string,
  data: AnnotationDTO,
  onSuccess?: OnSuccessActionHook
) =>
  showConfirmationMessage(
    {
      title,
      content,
    },
    () => [updateAnnotationStatus(data, onSuccess)]
  );

export const deleteAnnotationConfirmed = (id: number) =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.DELETE_ANNOTATION.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.DELETE_ANNOTATION.CONTENT),
    },
    () => [deleteAnnotation(id)]
  );

export const deleteAnnotation = (id: number) => action(AnnotationsActionType.DELETE_ANNOTATION, id);

import * as React from 'react';
import {useMemo} from 'react';
import classNames from 'classnames';
import classes from './analyses-view-header.module.scss';
import TransKeys from 'translations';
import {
  AnalysisFolder,
  AnalysisFolderType,
} from '../../../../../../objects/models/analysis-folder.model';
import {useTranslation} from 'react-i18next';
import {AnalysisFolderActions} from '../../../../hooks/use-analysis-folder-actions.hook';
import {
  ActionsDropdown,
  ArchiveIcon,
  ArrowRightLightIcon,
  Chip,
  ChipVariant,
  EditIcon,
  IconButton,
  LeaveIcon,
  MoreIcon,
  ShareIcon,
  StarLightIcon,
  StarSolidIcon,
  TeamIconChip,
  ToggleIconButton,
} from 'ui-components';
import {useCurrentUser} from '../../../../../../core/hooks/use-user.hook';
import {IconPicker} from '../../../../../shared/components/general/emoji-picker/emoji-picker.component';
import {DEFAULT_FOLDER_ICON} from '../../analysis-main.types';

interface OwnProps extends AnalysisFolderActions {
  analysisFolder: AnalysisFolder;
  onParentClicked?: (id: number | string) => void;
  className?: string;
}

type AllProps = OwnProps;

export const AnalysesViewHeader: React.FC<AllProps> = (props: AllProps) => {
  const {
    analysisFolder,
    onParentClicked,
    onEdit,
    onExplore,
    onShare,
    onDeshare,
    onChangeIcon,
    onMove,
    onDelete,
    className,
  } = props;
  const user = useCurrentUser();
  const isOwner = user.id === analysisFolder.userId;

  const {t} = useTranslation();
  const actions = useMemo(
    () => [
      {
        key: 'edit',
        title: t(TransKeys.GENERAL.ACTIONS.EDIT),
        onClick: () => onEdit(analysisFolder.id),
        icon: EditIcon,
      },
      {
        key: 'move',
        title: t(TransKeys.GENERAL.ACTIONS.MOVE_TO_FOLDER),
        onClick: () => onMove(analysisFolder),
        icon: ArrowRightLightIcon,
        hide: analysisFolder.type === AnalysisFolderType.PUBLIC || analysisFolder.isRoot,
      },
      {
        key: 'leave',
        title: t(TransKeys.GENERAL.ACTIONS.LEAVE),
        onClick: () => onDeshare(analysisFolder.id),
        icon: LeaveIcon,
        hide:
          analysisFolder.type === AnalysisFolderType.PUBLIC ||
          analysisFolder.isShared === false ||
          isOwner,
      },
      {
        key: 'archive',
        title:
          analysisFolder.deletedOn !== null
            ? t(TransKeys.GENERAL.ACTIONS.UNARCHIVE)
            : t(TransKeys.GENERAL.ACTIONS.ARCHIVE),
        onClick: () => onDelete(analysisFolder.id),
        hide: !isOwner && !(analysisFolder.actualType === AnalysisFolderType.PUBLIC),
        icon: ArchiveIcon,
      },
    ],
    [analysisFolder, isOwner, onEdit, onDelete, onDeshare, onMove, t]
  );
  const renderChip = () => {
    if (analysisFolder.deletedOn !== null) {
      return (
        <Chip
          className={classes.SharedChip}
          label={t(TransKeys.ANALYSES_MAIN.FOLDERS_DEFAULT_NAME.ARCHIVED.TITLE)}
          variant={ChipVariant.WARN}
        />
      );
    }
    if (analysisFolder.isShared) {
      return (
        <Chip
          className={classes.SharedChip}
          label={t(TransKeys.ANALYSES_MAIN.FOLDERS_DEFAULT_NAME.__SHARED.TITLE)}
        />
      );
    }
    return null;
  };

  return (
    <div className={classNames(classes.AnalysesViewHeader, className)}>
      <div className={classes.Head}>
        <div className={classes.Title}>
          {(analysisFolder.parents || []).map(p => (
            <React.Fragment key={p.id}>
              {p.team && <TeamIconChip {...p.team} size={'medium'} className={classes.TeamIcon} />}
              <div className={classes.Parent} onClick={() => onParentClicked(p.id)}>
                {t(TransKeys.ANALYSES_MAIN.FOLDERS_DEFAULT_NAME[p.name.toUpperCase()]?.['TITLE']) ||
                  p.name}
              </div>
              <div className={classes.Separator}>/</div>
            </React.Fragment>
          ))}
          {!analysisFolder.isRoot && !analysisFolder.team && (
            <div className={classes.AnalysisFolderIcon}>
              <IconPicker
                onChange={icon => onChangeIcon(analysisFolder.id, icon)}
                placeholderIcon={DEFAULT_FOLDER_ICON}
                value={analysisFolder.icon}
              />
            </div>
          )}
          {analysisFolder.team && (
            <div className={classes.AnalysisFolderIcon}>
              <TeamIconChip {...analysisFolder.team} size={'medium'} />
            </div>
          )}
          <div className={classes.Name}>
            {t(
              TransKeys.ANALYSES_MAIN.FOLDERS_DEFAULT_NAME[analysisFolder.name.toUpperCase()]?.[
                'TITLE'
              ]
            ) || analysisFolder.name}
          </div>
          {renderChip()}
        </div>
        {!analysisFolder.isRoot && (
          <div className={classes.Actions}>
            <ToggleIconButton
              titleChecked={t(TransKeys.GENERAL.ACTIONS.UNEXPLORE)}
              titleUnchecked={t(TransKeys.GENERAL.ACTIONS.EXPLORE)}
              onChange={() => onExplore(analysisFolder)}
              isChecked={analysisFolder.isExplored}
              icon={StarLightIcon}
              checkedIcon={StarSolidIcon}
              size={'large'}
            />
            {!(analysisFolder.actualType === AnalysisFolderType.PUBLIC) && (
              <IconButton
                tooltipText={t(TransKeys.GENERAL.ACTIONS.SHARE)}
                icon={ShareIcon}
                onClick={() => onShare(analysisFolder.id)}
                size={'large'}
              />
            )}
            {!analysisFolder.team && (
              <ActionsDropdown
                iconSize={'large'}
                actions={actions}
                label={t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
                icon={MoreIcon}
                iconDropdown
              />
            )}
          </div>
        )}
      </div>
      {analysisFolder.shortDescription && (
        <div className={classes.Description}>{analysisFolder.shortDescription}</div>
      )}
    </div>
  );
};

import {Epic, ofType} from 'redux-observable';
import {changeLoading, removeError, saveError} from 'front-core';
import HttpClient from '../../services/http-client.service';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {concat, from, of} from 'rxjs';
import SelectedStoreService from './selected.service';
import {errorExtractor} from '../store.utils';
import {SelectedActionType, upsertSelected} from './selected.actions';

export const getSelectedEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(SelectedActionType.GET_SELECTED),
    map(action => ({
      action,
      config: SelectedStoreService.getListConfig(action.payload.selectedKey),
    })),
    mergeMap(({action, config}) =>
      concat(
        of(changeLoading(config.actionKey, true)),
        of(removeError(config.actionKey)),
        from(HttpClient.exec(config.request(action.payload.payload, ...action.payload.args))).pipe(
          mergeMap((data: any) => of(upsertSelected(action.payload.selectedKey, data))),
          catchError((err: any) => {
            const actions = [of(saveError(config.actionKey, errorExtractor(err)))];
            if (config.onError) {
              actions.push(...config.onError(err, action.payload, action).map(a => of(a)));
            }
            return concat(...actions);
          })
        ),
        of(changeLoading(config.actionKey, false))
      )
    )
  );

import {BaseModel} from './base.model';
import {Opportunity} from './opportunity.model';
import {AnalysisType} from './analysis-type.model';
import {TableEntity} from './table.model';
import {AnalysisGoal} from './analysis-result.model';
import {UserBasicInfo} from './user.model';

export enum AnalysisStatus {
  ACTIVE = 'active',
  DISABLED = 'disabled',
  PAUSED = 'paused',
  ERROR = 'error',
}

export enum QuickRunAnalysesType {
  PAYMENT_RETENTION = 'payment_retention',
  ACTIVE_RETENTION = 'active_retention',
  CONVERSION_TO_PAID = 'conversion_to_paid',
  CONVERSION_TO_TRIAL = 'conversion_to_trial',
  TRIAL_TO_PAID = 'trial_to_paid',
  ALL_FUNNELS = 'all_funnels',
  ALL_CONTENTS = 'all_contents',
  // REVENUE_AOV = 'revenue_aov',
}

export interface Analysis extends BaseModel {
  title: string;
  productId: number;
  analysisTypeId: number;
  lastResultId?: number;
  lastCompletedResultId?: number;
  hasRequestedResult?: boolean;
  analysisType?: Partial<AnalysisType>;
  status: AnalysisStatus;
  image?: string;
  shortDescription?: string;
  cronExp?: string;
  opportunities: Array<Partial<Opportunity>>;
  resultsCount: number;
  lastRanOn: string;
  customizable: boolean;
}

export interface QuickRunForAdmins {
  runType: QuickRunAnalysesType;
  signalId?: number;
  preliminaryPeriod?: number;
  entity: TableEntity;
  populationFilter?: any;
  analysisFolderId?: number;
  runParameters: {start_date: string; end_date: string};
  analysesConfiguration: {[key: string]: boolean};
  contentIds?: number[];
}

export interface SimilarAnalysis {
  id: number;
  resultId: number;
  name: string;
  analysisTypeId: number;
  analysisFolderId: number;
  analysisTypeName: string;
  requestedByUser: UserBasicInfo;
  requestedByUserId: number;
  parameters: any;
  runParameters: any;
  analysisGoal?: AnalysisGoal;
  createdOn: string;
}

import {NRC} from './index';
import {RequestType} from 'front-core';
import {OpportunityFiltersDTO} from '../objects/dto/opportunity-filters.dto';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';
import {toCamelCase} from 'front-core';

export const getOpportunityNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/opportunities/${id}`,
  responseTransformer: res =>
    toCamelCase(res.data, [
      'results',
      'analysis_result.run_parameters',
      'analysis_result_parameters',
    ]),
});

export const getOpportunitiesNetworkRequest: NRC<void, OpportunityFiltersDTO> = (
  payload: OpportunityFiltersDTO
) => ({
  method: RequestType.GET,
  relativeUrl: `/app/opportunities`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data),
});

export const getOpportunitiesFeedNetworkRequest: NRC<void, OpportunityFiltersDTO> = (
  payload: OpportunityFiltersDTO
) => ({
  method: RequestType.GET,
  relativeUrl: `/app/opportunities/feed`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data),
});

export const getOpportunityCountersNetworkRequest: NRC<void, void> = () => ({
  method: RequestType.GET,
  relativeUrl: `/app/opportunities/counters`,
  responseTransformer: toCamelCase,
});

import * as React from 'react';
import classNames from 'classnames';
import classes from './cohorts-definition-selector.module.scss';
import {useCallback, useMemo} from 'react';
import {values} from 'lodash';
import {ParameterInputWrapper} from '../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {HoverHelperTip, LabelWrapper, Select, TextInput} from 'ui-components';
import {hasErrors, limitNumberValue} from '../../../../../../utils/general.utils';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {TIME_UNIT_PERIODIC_OPTIONS} from '../../../../../../constants/options';

interface SchemaKeysMapping {
  cohort_granularity: string;
  bucket_granularity: string;
  n_cohorts: string;
  n_buckets: string;
  bucket_n_unit: string;
  // ui parameter
  is_custom_buckets: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  cohort_granularity: 'cohort_granularity',
  bucket_granularity: 'bucket_granularity',
  n_cohorts: 'n_cohorts',
  n_buckets: 'n_buckets',
  bucket_n_unit: 'bucket_n_unit',
  is_custom_buckets: 'is_custom_buckets',
};

interface OwnProps {
  title: string;
  subTitle?: string;
  value: any;
  onChange: (parameters: any) => void;
  schemaKeysMapping?: SchemaKeysMapping;
  bottomHelper?: any;
  helperText?: any;
  errors?: any;
  className?: string;
}

type AllProps = OwnProps;

const MIN_COUNT_VALUE = 1;
const MAX_COUNT_VALUE = 50;
const MIN_BUCKET_N_UNIT = 1;
const MAX_BUCKET_N_UNIT = 365;
const CUSTOM_OPTION_VALUE = 'custom';

export const CohortsDefinitionSelector: React.FC<AllProps> = (props: AllProps) => {
  const {
    title,
    subTitle,
    helperText,
    bottomHelper,
    errors,
    onChange,
    schemaKeysMapping,
    value,
    className,
  } = props;
  const {t} = useTranslation();
  const hasError = useMemo(
    () => hasErrors(errors, values(schemaKeysMapping)),
    [errors, schemaKeysMapping]
  );
  const isCustomBuckets = useMemo(
    () => value[schemaKeysMapping.is_custom_buckets],
    [value, schemaKeysMapping]
  );
  const bucketsOptions = useMemo(
    () => ({
      options: [
        ...TIME_UNIT_PERIODIC_OPTIONS.options,
        {
          value: CUSTOM_OPTION_VALUE,
          label: 'custom',
        },
      ],
    }),
    []
  );
  const selectedBucketOption = useMemo(() => {
    if (isCustomBuckets) {
      return CUSTOM_OPTION_VALUE;
    }
    return value[schemaKeysMapping.bucket_granularity];
  }, [isCustomBuckets, value, schemaKeysMapping]);
  const onBucketsUnitChange = useCallback(
    value => {
      if (value === CUSTOM_OPTION_VALUE) {
        onChange({
          [schemaKeysMapping.is_custom_buckets]: true,
          [schemaKeysMapping.bucket_n_unit]: 14,
          [schemaKeysMapping.bucket_granularity]: 'day',
        });
        return;
      }
      onChange({
        [schemaKeysMapping.bucket_granularity]: value,
        [schemaKeysMapping.is_custom_buckets]: null,
        [schemaKeysMapping.bucket_n_unit]: null,
      });
    },
    [schemaKeysMapping, onChange]
  );

  return (
    <ParameterInputWrapper
      title={title}
      subTitle={subTitle}
      helperText={helperText}
      className={classNames(classes.CohortsDefinitionSelector, className)}
      bottomHelper={bottomHelper}
      error={hasError}
    >
      <div className={classes.Row}>
        <LabelWrapper
          label={t(TransKeys.ANALYSIS_FORMS.SHARED.COHORTS_DEFINITION.COHORTS.LABEL)}
          className={classes.LabelWrapper}
        >
          <div className={classes.Row}>
            <div className={classes.Item}>
              <TextInput
                className={classes.Input}
                value={value?.[schemaKeysMapping.n_cohorts]}
                onChange={value =>
                  onChange({
                    [schemaKeysMapping.n_cohorts]: limitNumberValue(
                      value as number,
                      MIN_COUNT_VALUE,
                      MAX_COUNT_VALUE
                    ),
                  })
                }
                type={'number'}
              />
            </div>
            <div className={classes.Item}>
              <Select
                value={value?.[schemaKeysMapping.cohort_granularity]}
                onChange={value =>
                  onChange({
                    [schemaKeysMapping.cohort_granularity]: value,
                  })
                }
                options={TIME_UNIT_PERIODIC_OPTIONS}
                dropdownButtonClassName={classes.SelectUnit}
                searchable={false}
                clearable={false}
                sortValues={false}
              />
            </div>
            <div className={classes.Item}>cohorts</div>
            <HoverHelperTip
              title={t(TransKeys.ANALYSIS_FORMS.SHARED.COHORTS_DEFINITION.COHORTS.HELPER_TEXT)}
            />
          </div>
        </LabelWrapper>
        <LabelWrapper
          label={t(TransKeys.ANALYSIS_FORMS.SHARED.COHORTS_DEFINITION.BUCKETS.LABEL)}
          className={classes.LabelWrapper}
        >
          <div className={classes.Row}>
            <div className={classes.Item}>
              <TextInput
                className={classes.Input}
                value={value?.[schemaKeysMapping.n_buckets]}
                onChange={value =>
                  onChange({
                    [schemaKeysMapping.n_buckets]: limitNumberValue(
                      value as number,
                      MIN_COUNT_VALUE,
                      MAX_COUNT_VALUE
                    ),
                  })
                }
                type={'number'}
              />
            </div>
            <div className={classes.Item}>
              <Select
                value={selectedBucketOption}
                onChange={onBucketsUnitChange}
                options={bucketsOptions}
                dropdownButtonClassName={classes.SelectUnit}
                searchable={false}
                clearable={false}
                sortValues={false}
              />
            </div>
            <div className={classes.Item}>buckets</div>
            <HoverHelperTip
              title={t(TransKeys.ANALYSIS_FORMS.SHARED.COHORTS_DEFINITION.BUCKETS.HELPER_TEXT)}
            />
          </div>
        </LabelWrapper>
        {isCustomBuckets && (
          <LabelWrapper label={`What's the custom bucket size?`} className={classes.LabelWrapper}>
            <div className={classes.Row}>
              <div className={classes.Item}>
                <TextInput
                  className={classes.Input}
                  value={value?.[schemaKeysMapping.bucket_n_unit]}
                  onChange={value =>
                    onChange({
                      [schemaKeysMapping.bucket_n_unit]: limitNumberValue(
                        value as number,
                        MIN_BUCKET_N_UNIT,
                        MAX_BUCKET_N_UNIT
                      ),
                    })
                  }
                  type={'number'}
                />
              </div>
              <div className={classes.Item}>
                {/*<Select*/}
                {/*  value={value[schemaKeysMapping.bucket_granularity]}*/}
                {/*  onChange={value =>*/}
                {/*    onChange({*/}
                {/*      [schemaKeysMapping.bucket_granularity]: value,*/}
                {/*    })*/}
                {/*  }*/}
                {/*  options={TIME_UNIT_PLURAL_OPTIONS}*/}
                {/*  dropdownButtonClassName={classes.SelectUnit}*/}
                {/*  searchable={false}*/}
                {/*  clearable={false}*/}
                {/*  sortValues={false}*/}
                {/*/>*/}
                days
              </div>
            </div>
          </LabelWrapper>
        )}
      </div>
    </ParameterInputWrapper>
  );
};

CohortsDefinitionSelector.defaultProps = {
  schemaKeysMapping: DEFAULT_SCHEMA_KEYS_MAPPING,
};

import * as React from 'react';
import classNames from 'classnames';
import classes from './winner-label.module.scss';

interface OwnProps {
  text: string;
  className?: string;
}

type AllProps = OwnProps;

export const WinnerLabel: React.FC<AllProps> = (props: AllProps) => {
  const {text, className} = props;

  return <div className={classNames(classes.WinnerLabel, className)}>{text}</div>;
};

import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {INTERFACE_STORE_KEY} from '../../store/interface/interface.store';
import {setIsGrowthMapOpen} from '../../store/interface/interface.actions';
import {ExpandButton} from './components/expand-button/expand-button.component';
import {Map} from './pages/map/map.component';
import {useCallback} from 'react';

interface OwnProps {}

type AllProps = OwnProps;

export const AppGrowthMap: React.FC<AllProps> = (props: AllProps) => {
  const dispatch = useDispatch();
  const isGrowthMapOpen = useSelector(state => state[INTERFACE_STORE_KEY].isGrowthMapOpen);
  const setIsOpen = useCallback((open: boolean) => dispatch(setIsGrowthMapOpen(open)), [dispatch]);

  return (
    <ExpandButton isOpen={isGrowthMapOpen} onChange={setIsOpen}>
      <Map />
    </ExpandButton>
  );
};

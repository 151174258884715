import {ModelSeriesGranularity} from '../../../../objects/models/model-sample-series.model';
import {KPINode} from './components/kpi-node/kpi-node.component';
import {KPIEdge} from './components/kpi-edge/kpi-edge.component';
import {KPIGroup} from './components/kpi-group/kpi-group.component';
import {SnapGrid} from '@xyflow/react';
import {AddInputMetricNode} from './components/add-input-metric-node/add-input-metric-node.component';

export enum TreeVariant {
  FULL = 'full',
  SIMPLE = 'simple',
}
export enum TreeMode {
  VIEW = 'view',
  EDIT = 'edit',
}

export enum NodeType {
  METRIC = 'KPIBox',
  METRIC_GROUP = 'KPIGroup',
  ADD_INPUT_METRIC = 'AddInputMetric',
}

export enum EdgeType {
  METRIC_CONNECTION = 'KPIEdge',
}
export const NODE_PLACEHOLDER_PORTAL_CLASS_NAME = 'node-placeholder-portal';
export const SNAP_GRID: SnapGrid = [5, 5];
export const DEFAULT_GRANULARITY = ModelSeriesGranularity.WEEK;
export const KPI_NODE_WIDTH = 260;
export const KPI_NODE_HEIGHT = 80;
export const KPI_GROUP_NODE_WIDTH = 84;
export const KPI_GROUP_NODE_HEIGHT = 28;

export const nodeTypes = {
  [NodeType.METRIC]: KPINode,
  [NodeType.METRIC_GROUP]: KPIGroup,
  [NodeType.ADD_INPUT_METRIC]: AddInputMetricNode,
};

export const edgeTypes = {
  [EdgeType.METRIC_CONNECTION]: KPIEdge,
};

export const DISABLE_REACT_FLOW_INTERACTION_PROPS = {
  nodesDraggable: false,
  nodesConnectable: false,
  nodesFocusable: false,
  elementsSelectable: false,
  zoomOnDoubleClick: false,
  preventScrolling: false,
  zoomOnScroll: false,
};

export const HIDE_REACT_FLOW_ATTRIBUTION = {
  proOptions: {hideAttribution: true},
};

import {NRC} from './index';
import {RequestType} from 'front-core';
import {toCamelCase, toSnakeCase} from 'front-core';
import {AnalysisFolderDTO, MoveAnalysisFolderDTO} from '../objects/dto/analysis-folder.dto';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';

export const searchAnalysisFoldersNetworkRequest: NRC<void, any> = (query: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/analysis-folders/search`,
  query: query,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data),
});

export const getAnalysisFolderNetworkRequest: NRC<void, any> = (id: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/analysis-folders/${id}`,
  responseTransformer: res => toCamelCase(res.data),
});

export const createAnalysisFolderNetworkRequest: NRC<AnalysisFolderDTO, any> = (
  data: AnalysisFolderDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/analysis-folders`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const updateAnalysisFolderNetworkRequest: NRC<AnalysisFolderDTO, any> = (
  data: AnalysisFolderDTO
) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/analysis-folders/${data.id}`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const deleteAnalysisFolderNetworkRequest: NRC<AnalysisFolderDTO, any> = (id: number) => ({
  method: RequestType.DELETE,
  relativeUrl: `/app/analysis-folders/${id}`,
  responseTransformer: res => toCamelCase(res.data),
});

export const getAnalysisFoldersTreeNetworkRequest: NRC<void, void> = (filters: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/analysis-folders/tree`,
  query: filters,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data),
});

export const moveAnalysisFolderNetworkRequest: NRC<MoveAnalysisFolderDTO, void> = (
  data: MoveAnalysisFolderDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/analysis-folders/${data.id}/move`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

import {INFRA_STORE_KEY} from 'front-core';
import {pick} from 'lodash';
import {REMOTE_LISTS_STORE_KEY} from './remote-lists/remote-lists.store';
import {SELECTED_STORE_KEY} from './selected/selected.store';

export const getReducedLoadingStateSelector =
  (...keys: string[]) =>
  (store: any): boolean => {
    const infraStore = store[INFRA_STORE_KEY];
    return keys.reduce((previousValue, currentValue) => {
      return previousValue || infraStore.loading[currentValue];
    }, false);
  };

export const getLoadingStateSelector =
  (...keys: string[]) =>
  (store: any) => {
    const infraStore = store[INFRA_STORE_KEY];
    return pick(infraStore.loading, keys);
  };

export const getListsSelector =
  (...keys: string[]) =>
  (store: any) => {
    const lists = store[REMOTE_LISTS_STORE_KEY].lists;
    return pick(lists, keys);
  };

export const getListCount = (key: string) => (store: any) => {
  const lists = store[REMOTE_LISTS_STORE_KEY].lists;
  return lists[key]?.meta?.total;
};

export const getSelectedSelector =
  (...keys: string[]) =>
  (store: any) => {
    const selected = store[SELECTED_STORE_KEY].selected;
    return pick(selected, keys);
  };

export const getErrorSelector =
  (...keys: string[]) =>
  (store: any) => {
    const selected = store[INFRA_STORE_KEY].errors;
    return pick(selected, keys);
  };

export const getSingleSelectedSelector = (key: string, store: any) => {
  return store[SELECTED_STORE_KEY].selected[key];
};

export const getSingleErrorSelector = (key: string, store: any) => {
  return store[INFRA_STORE_KEY].errors[key];
};

import * as React from 'react';
import {Route, Switch} from 'react-router';
import {AppRoutes} from '../../constants/app-routes';
import {WorkflowsMain} from './pages/workflows-main/workflows-main.component';
import {ViewWorkflow} from './pages/view-workflow/view-workflow.component';

export const WorkflowsRouting: React.FC = props => {
  return (
    <Switch>
      <Route path={AppRoutes.workflows().pathname} component={WorkflowsMain} exact />
      <Route path={AppRoutes.viewWorkflow().pathname} component={ViewWorkflow} exact />
    </Switch>
  );
};

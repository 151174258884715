import * as React from 'react';
import {AnalysisResult} from '../../../../../objects/models/analysis-result.model';
import {
  GlobalDocumentData,
  GlobalDocumentDataContextProvider,
  useRemoteSourceStated,
} from 'ui-components';
import {useCallback, useEffect, useMemo} from 'react';
import {getSegmentCategoriesMapNetworkRequest} from '../../../../../http/segment-categories.network-requests';
import {getFavoritesSegmentsNetworkRequest} from '../../../../../http/segments.network-requests';
import {ModelKey} from '../../../../../constants/model-key';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../../store/actions-listener/actions-listener.actions';
import {CoreActionsType} from '../../../../../store/core/core.actions';
import {useDispatch} from 'react-redux';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';
import {NumberParam, useQueryParam} from 'use-query-params';
import {TEAM_ID_PATH_PARAM} from '../../../../../constants/app-routes';
import {GenericLoading} from '../../../components/general/generic-loading/generic-loading.component';

interface OwnProps {
  analysisResult: AnalysisResult;
  children: any;
}

type AllProps = OwnProps;

export const AppGlobalDocumentDataProvider: React.FC<AllProps> = (props: AllProps) => {
  const {analysisResult, children} = props;
  const dispatch = useDispatch();
  const {actualTeams: teams, defaultTeamId} = useProductData();
  const [teamId, setTeamId_] = useQueryParam(TEAM_ID_PATH_PARAM, NumberParam);
  const {source: segmentCategoriesMap, exec: getSegmentCategoriesMap} = useRemoteSourceStated({
    networkRequest: getSegmentCategoriesMapNetworkRequest,
  });
  const {
    source: favoriteSegments,
    exec: getFavoriteSegments,
    isLoading: isLoadingFavoriteSegments,
  } = useRemoteSourceStated({
    networkRequest: getFavoritesSegmentsNetworkRequest,
  });

  useEffect(() => {
    getSegmentCategoriesMap();
    getFavoriteSegments();
  }, [getSegmentCategoriesMap, getFavoriteSegments]);

  useEffect(() => {
    const listener = action => {
      if (action.payload.modelKey === ModelKey.FAVORITE_SEGMENTS) {
        getFavoriteSegments();
      }
    };
    dispatch(registerActionListener(CoreActionsType.MODEL_UPDATED, listener));
    return () => {
      dispatch(removeActionListener(CoreActionsType.MODEL_UPDATED, listener));
    };
  }, [dispatch, getFavoriteSegments]);
  const setTeamId = useCallback(
    teamId => (teamId === null ? setTeamId_(undefined) : setTeamId_(teamId)),
    [setTeamId_]
  );

  const globalDocumentData: GlobalDocumentData = useMemo(
    () => ({
      analysisResultId: analysisResult.id,
      analysisId: analysisResult.analysisId,
      analysisTypeId: analysisResult.analysisTypeId,
      analysisTypeName: analysisResult.analysisTypeName,
      analysisName: analysisResult.userAnalysisName || analysisResult.analysisName,
      analysisRunParameters: analysisResult.runParameters,
      analysisParameters: analysisResult.parameters,
      analysisResultStatus: analysisResult.status,
      segmentCategoriesMap,
      favoriteSegments,
      teams,
      defaultTeamId,
      teamId,
      setTeamId,
    }),
    [
      analysisResult,
      segmentCategoriesMap,
      favoriteSegments,
      teams,
      defaultTeamId,
      teamId,
      setTeamId,
    ]
  );

  if (!favoriteSegments || isLoadingFavoriteSegments) {
    return <GenericLoading />;
  }

  return (
    <GlobalDocumentDataContextProvider {...globalDocumentData}>
      {children}
    </GlobalDocumentDataContextProvider>
  );
};

import * as React from 'react';
import {CSSProperties, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import classes from './map.module.scss';
import {Filters, GrowthMap, KPIElement} from 'ui-components';
import {CircularProgress} from '@material-ui/core';
import TransKeys from '../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {AppRoutes, METRIC_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {PanelKey} from '../../../../constants/panels';
import {PanelType} from '../../../../objects/system/panel-type.enum';
import {dateRangeFilter} from '../../../../constants/filters';
import {
  createSelected,
  getSelected,
  removeSelected,
} from '../../../../store/selected/selected.actions';
import {getGrowthMapNetworkRequest} from '../../../../http/growth-map.network-requests';
import {useDispatch, useSelector} from 'react-redux';
import {
  getReducedLoadingStateSelector,
  getSingleErrorSelector,
  getSingleSelectedSelector,
} from '../../../../store/store.selectors';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {EmptyState} from '../../../shared/components/general/override';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {Transition} from 'react-transition-group';
import {setIsGrowthMapOpen} from '../../../../store/interface/interface.actions';
import classNames from 'classnames';
import {PanelsContext} from '../../../../core/contexts/panels.context';

interface OwnProps {
  className?: string;
  style?: CSSProperties;
}

type AllProps = OwnProps;

const GROWTH_MAP_SELECTED_KEY = SharedSelectionKeys.VIEW_GROWTH_MAP__MAP;

export const Map: React.FC<AllProps> = (props: AllProps) => {
  const {className, style} = props;
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {openPrimaryPanel} = useContext(PanelsContext);
  const [currentFilters, setCurrentFilters] = useState({});
  const history = useHistory();
  const notifyAmplitude = useAmplitude();

  const map = useSelector(state => getSingleSelectedSelector(GROWTH_MAP_SELECTED_KEY, state));
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(GROWTH_MAP_SELECTED_KEY)(state)
  );
  const error = useSelector(state => getSingleErrorSelector(GROWTH_MAP_SELECTED_KEY, state));
  const filtersDef = useMemo(() => [dateRangeFilter()], []);

  const onFiltersChange = useCallback(
    filters => {
      setCurrentFilters(filters);
      dispatch(getSelected(GROWTH_MAP_SELECTED_KEY, filters));
    },
    [dispatch]
  );
  const onClearAll = useCallback(() => setCurrentFilters({}), [setCurrentFilters]);
  const onOpportunitiesClicked = useCallback(
    (member: KPIElement) => {
      history.push(AppRoutes.opportunities({[METRIC_ID_PATH_PARAM]: member.metricId}));
      dispatch(setIsGrowthMapOpen(false));
    },
    [dispatch, history]
  );
  const onKPIClicked = useCallback(
    (member: KPIElement) => {
      openPrimaryPanel(
        PanelKey.VIEW_METRIC_PANEL,
        {
          [METRIC_ID_PATH_PARAM]: member.metricId,
        },
        PanelType.MODAL
      );
    },
    [openPrimaryPanel]
  );

  useEffect(() => {
    dispatch(
      createSelected({
        selectedKey: GROWTH_MAP_SELECTED_KEY,
        actionKey: GROWTH_MAP_SELECTED_KEY,
        request: getGrowthMapNetworkRequest,
      })
    );
    dispatch(getSelected(GROWTH_MAP_SELECTED_KEY));
    notifyAmplitude(AmplitudeEvent.GROWTH_MAP_OPENED);
    return () => {
      dispatch(removeSelected(GROWTH_MAP_SELECTED_KEY));
    };
  }, [dispatch, notifyAmplitude]);
  useEffect(() => {
    map &&
      setCurrentFilters({
        ...currentFilters,
        dates: {from: map['fromDate'], to: map['toDate']},
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return (
    <div style={style} className={classNames(classes.GrowthMap, className)}>
      {isLoading && (
        <div className={classes.LoadingWrapper}>
          <CircularProgress color={'secondary'} />
        </div>
      )}
      <Transition in={Boolean(map)} timeout={1000}>
        {state =>
          state === 'entered' ? (
            <>
              <div className={classes.FiltersWrapper}>
                <Filters
                  selected={currentFilters}
                  filters={filtersDef}
                  onChange={onFiltersChange}
                  onClearAll={onClearAll}
                  maxWidth={'100%'}
                />
              </div>
              {!isLoading && error && (
                <div className={classes.Error}>
                  <EmptyState
                    title={t(TransKeys.ERRORS.GROWTH_MAP.TITLE)}
                    subTitle={t(TransKeys.ERRORS.GROWTH_MAP.SUB_TITLE)}
                    variant={'dark'}
                    error
                  />
                </div>
              )}
              {map && (
                <GrowthMap
                  data={map as any}
                  onOpportunitiesClicked={onOpportunitiesClicked}
                  onLoopsClicked={onOpportunitiesClicked}
                  onKPIClicked={onKPIClicked}
                  initialX={30}
                  initialZoom={0.93}
                />
              )}
            </>
          ) : (
            <div />
          )
        }
      </Transition>
    </div>
  );
};

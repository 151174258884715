import * as React from 'react';
import classes from './squiggly-empty-state.module.scss';
import {useTranslation} from 'react-i18next';
import {ImagesResource} from '../../../../../assets/images';
import TransKeys from 'translations';

interface OwnProps {
  onClick: () => void;
  title: string;
  buttonText: string;
  subTitle?: string;
}
type AllProps = OwnProps;

const SquigglyEmptyState: React.FC<AllProps> = (props: AllProps) => {
  const {t} = useTranslation();
  const {onClick, title, subTitle, buttonText} = props;

  return (
    <div className={classes.SquigglyEmptyState}>
      <div className={classes.DescriptionWrapper}>
        <img src={ImagesResource.app.squigglyArrowUp} alt={''} />
        <div className={classes.Texts}>
          <span className={classes.Bold}>{title}</span>
          {subTitle && <span>{subTitle}</span>}
        </div>
      </div>
      <div className={classes.ButtonWrapper}>
        <span>{t(TransKeys.GENERAL.LABELS.OR)}</span>
        <span className={classes.Button} onClick={() => onClick()}>
          {buttonText}
        </span>
      </div>
    </div>
  );
};

export default SquigglyEmptyState;

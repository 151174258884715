import * as React from 'react';
import classes from '../../homepage-subscription-panel.module.scss';
import {useMemo} from 'react';
import {TeamIconChip, UserIcon} from 'ui-components';
import {Team} from '../../../../../../objects/models/team.model';

interface OwnProps {
  selectedTeam?: Team;
}

type AllProps = OwnProps;

export const HomepageName: React.FC<AllProps> = (props: AllProps) => {
  const {selectedTeam} = props;
  const name = useMemo(() => {
    if (selectedTeam) {
      return selectedTeam.name;
    }
    return 'My Homepage';
  }, [selectedTeam]);

  return (
    <div className={classes.Top}>
      <span className={classes.Label}>Homepage</span>
      <div className={classes.HomepageName}>
        {selectedTeam && <TeamIconChip {...selectedTeam} />}
        {!selectedTeam && <UserIcon />}
        <span>{name}</span>
      </div>
    </div>
  );
};

import * as React from 'react';
import classNames from 'classnames';
import classes from '../../analysis-forms.module.scss';
import {QueryBuilderFactory} from 'ui-components';
import {TimeFrameSelector} from '../../components/ui-selectors/time-frame-selector/time-frame-selector.component';
import {useCallback, useContext, useEffect} from 'react';
import {RetentionQueryBuilder} from '../../../../shared/core/query-builders/retention-builder/retention-query-builder.component';
import {ParametersFormContext} from '../../../../shared/core/parameters-form/parameters-form.context';
import {startEndDatesDefaultHandler} from '../../../../shared/core/parameters-form/parameters-form-default-handlers.utils';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';
import {SegmentFilterSelector} from '../../components/ui-selectors/segment-filter-selector/segment-filter-selector.component';
import {WindowSizeSelector} from '../../components/ui-selectors/window-size-selector/window-size-selector.component';
import TransKeys from '../../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {get, values} from 'lodash';
import {ParameterInputWrapper} from '../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {TableEntity} from '../../../../../objects/models/table.model';
import {EntitySelector} from '../../components/ui-selectors/entity-selector/entity-selector.component';
import {createUndefinedObject} from '../../../../../utils/general.utils';
import {AnalysisFormProps} from '../../analysis-forms.types';
import {TreatmentSelector} from '../../components/ui-selectors/treatment-selector/treatment-selector.component';

const QUERY_GOAL_KEY = 'goal_query';
const REF_DATE_PARAMETER = 'reference_date';
const churnMomentSelectorMap = {
  window_size_n: 'max_window',
  window_size_unit: 'max_window_unit',
};
const treatmentsSelectorMap = {
  treatments_tag: 'features_tag',
  treatments_signals: 'treatments_signals',
};
const entitySchemaMapping = {
  entity: 'entity',
};
const segmentFilterSchemaMapping = {
  population_filter: 'population_filter',
};

export const Analysis142Form: React.FC<AnalysisFormProps> = (props: AnalysisFormProps) => {
  const {onSignalInfo, className} = props;
  const {errors, parameters, changeParametersValue, registerDefaultHandler, removeDefaultHandler} =
    useContext(ParametersFormContext);
  const {t} = useTranslation();
  const {getSignalByTag, productEntities} = useProductData();
  const entityContext = parameters[entitySchemaMapping.entity];

  useEffect(() => {
    registerDefaultHandler('start_end_dates', startEndDatesDefaultHandler);
    registerDefaultHandler('analysis_142', parameters => {
      const defaults = {};
      // goal_query
      defaults[entitySchemaMapping.entity] = TableEntity.USER;
      const joinDateSignal = getSignalByTag('join_date', entityContext);
      const activeSignal = getSignalByTag('active', entityContext);
      const query = QueryBuilderFactory.createTemplate();
      query.template = 'bounded_actions_ts';
      query.parameters[1] = 1;
      query.parameters[2] = 2;
      query.parameters[3] = 'month';
      query.parameters[4] = QueryBuilderFactory.createSignalColumn(joinDateSignal?.id);
      query.parameters[0] = [QueryBuilderFactory.createSignalColumn(activeSignal?.id)];
      defaults[QUERY_GOAL_KEY] = query;
      // churn moment
      defaults[churnMomentSelectorMap.window_size_n] = 4;
      defaults[churnMomentSelectorMap.window_size_unit] = 'week';
      return defaults;
    });

    return () => {
      removeDefaultHandler('start_end_dates');
      removeDefaultHandler('analysis_142');
    };
  }, [registerDefaultHandler, removeDefaultHandler, getSignalByTag, entityContext]);

  useEffect(() => {
    const queryParamValue = get(parameters, `${QUERY_GOAL_KEY}.parameters.4`);
    if (queryParamValue !== parameters[REF_DATE_PARAMETER]) {
      changeParametersValue({[REF_DATE_PARAMETER]: queryParamValue});
    }
  }, [parameters, changeParametersValue]);

  const onChangeEntityContext = useCallback(
    (entity: TableEntity) => {
      const resetKeys = Array.from(
        new Set([...values(segmentFilterSchemaMapping), QUERY_GOAL_KEY])
      );
      const resetParameters = createUndefinedObject(resetKeys);
      changeParametersValue({
        [entitySchemaMapping.entity]: entity,
        ...resetParameters,
      });
    },
    [changeParametersValue]
  );

  return (
    <div className={classNames(classes.AnalysisForm, className)}>
      <EntitySelector
        value={parameters}
        productEntities={productEntities}
        schemaKeysMapping={entitySchemaMapping}
        onChange={v => onChangeEntityContext(v[entitySchemaMapping.entity])}
        className={classes.Parameter}
      />
      <ParameterInputWrapper
        title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_142.GOAL.TITLE)}
        subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_142.GOAL.SUB_TITLE)}
        helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_142.GOAL.HELPER_TEXT)}
        className={classes.Parameter}
        error={errors?.[QUERY_GOAL_KEY]}
      >
        <RetentionQueryBuilder
          query={parameters[QUERY_GOAL_KEY]}
          onChange={value => changeParametersValue({[QUERY_GOAL_KEY]: value})}
          onSignalInfo={onSignalInfo}
          errors={errors?.[QUERY_GOAL_KEY]}
          entityContext={entityContext}
        />
      </ParameterInputWrapper>
      <WindowSizeSelector
        title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_142.WINDOW.TITLE)}
        subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_142.WINDOW.SUB_TITLE)}
        helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_142.WINDOW.HELPER_TEXT)}
        value={parameters}
        onChange={changeParametersValue}
        className={classes.Parameter}
        schemaKeysMapping={churnMomentSelectorMap}
        errors={errors}
        preText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_142.WINDOW.PRE_TEXT)}
        postText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_142.WINDOW.POST_TEXT)}
      />
      <TreatmentSelector
        value={parameters}
        onChange={changeParametersValue}
        className={classes.Parameter}
        schemaKeysMapping={treatmentsSelectorMap}
        errors={errors}
        entityContext={entityContext}
      />
      <TimeFrameSelector className={classes.Parameter} errors={errors} />
      {entityContext && (
        <SegmentFilterSelector
          onChange={changeParametersValue}
          value={parameters}
          className={classes.Parameter}
          schemaKeysMapping={segmentFilterSchemaMapping}
          entityContext={entityContext}
          errors={errors}
        />
      )}
      {/*<ExtendedParameters*/}
      {/*  className={classes.SpaceBottom}*/}
      {/*  label={t(TransKeys.GENERAL.LABELS.ADVANCED_PARAMETERS)}*/}
      {/*  isOpen={isOpenAdvancedParamsOrError}*/}
      {/*  onOpenChanged={() => setIsOpenAdvancedParams(!isOpenAdvancedParams)}*/}
      {/*>*/}
      {/*  <FeatureInteractionSelector*/}
      {/*    schemaKeysMapping={featureInteractionSelectorMap}*/}
      {/*    value={parameters}*/}
      {/*    onChange={changeParametersValue}*/}
      {/*    onSignalInfo={onSignalInfo}*/}
      {/*    title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_142.TREATMENTS_TAG.TITLE)}*/}
      {/*    className={classes.Parameter}*/}
      {/*    errors={errors}*/}
      {/*  />*/}
      {/*</ExtendedParameters>*/}
    </div>
  );
};

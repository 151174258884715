import {createWaitEpic} from 'front-core';
import {ActionKey} from '../../constants/action-key';
import {InterfaceActionsTypes, setConfirmationMessage} from './interface.actions';

export const confirmationRequestEpic = createWaitEpic({
  types: [InterfaceActionsTypes.SHOW_CONFIRMATION_MESSAGE],
  actionKey: ActionKey.DONT_CARE,
  actions: payload => [setConfirmationMessage(payload.message)],
  wait: [[InterfaceActionsTypes.CONFIRMATION_MESSAGE_RESOLVED]],
  finally: (actions, {payload}) => {
    if (actions[0].payload) {
      return payload.onApprove();
    }
    return [];
  },
});

import {TimeframeOption} from './homepage-model-samples-viewer.types';
import moment from 'moment';
import {TIME_FORMATS} from '../../../../../../constants/time-formats';

export const getDateFromTimeframeOption = (lastValid?: string, option?: TimeframeOption) => {
  return moment
    .utc(
      lastValid ? lastValid : undefined,
      lastValid ? TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT : undefined
    )
    .startOf('d')
    .subtract(option?.count || 0, option?.unit || 'd')
    .format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT);
};

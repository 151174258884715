import {MonitoredMetric} from '../objects/models/health-monitor.model';
import {last} from 'lodash';
import {exists, number2k} from 'front-core';
import {CalcSeriesMode} from '../constants/metric-series.consts';

export const getLastSampleValue = (metricSeries: MonitoredMetric): string => {
  const lastSample = last(metricSeries.samples);
  if (!lastSample) {
    return;
  }
  const unit = metricSeries.unit;
  let value: any = lastSample.value;

  value = number2k(value);
  if (unit === 'PERCENTAGE') {
    return `${value}%`;
  }

  return value;
};

export const getLastSampleTrendValue = (
  metricSeries: MonitoredMetric,
  mode: CalcSeriesMode = CalcSeriesMode.VS_EXPECTED
): {value: number; expected: string; isBetter?: boolean; isSignificant?: boolean} => {
  const lastSample = last(metricSeries.samples);
  if (!lastSample) {
    return;
  }
  const unit = metricSeries.unit;
  const {value, expectedLower, expectedUpper} = lastSample;

  let trendValue = undefined;
  let isBetter = undefined;

  if (!exists(expectedLower) || !exists(expectedUpper)) {
    return undefined;
  }

  let expected: any = (expectedUpper + expectedLower) / 2;
  if (mode === CalcSeriesMode.VS_EXPECTED) {
    if (expected === 0) {
      trendValue = 0;
    } else {
      trendValue = ((value - expected) / expected) * 100;
    }
  }

  isBetter = trendValue >= 0;
  if (metricSeries.higherIsBetter === false) {
    isBetter = !isBetter;
  }

  const isSignificant = value > expectedUpper || value < expectedLower;
  expected = number2k(expected);
  if (unit === 'PERCENTAGE') {
    expected = `${expected}%`;
  }

  return {value: trendValue, expected, isBetter, isSignificant};
};

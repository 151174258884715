import {ConditionOperator, Literal, LiteralValueType, QueryBuilderFactory} from 'ui-components';
import {values} from 'lodash';

const LITERAL_LIST_TYPES = [
  LiteralValueType.STRING_LIST,
  LiteralValueType.FLOAT_LIST,
  LiteralValueType.INTEGER_LIST,
];
const LITERAL_LIST_OPS = [
  ConditionOperator.IN,
  ConditionOperator.NIN,
  ConditionOperator.NIN_OR_NULL,
];
const LITERAL_TYPE_AS_LIST = {
  [LiteralValueType.STRING]: LiteralValueType.STRING_LIST,
  [LiteralValueType.FLOAT]: LiteralValueType.FLOAT_LIST,
  [LiteralValueType.INTEGER]: LiteralValueType.INTEGER_LIST,
};
const LITERAL_LIST_AS_TYPE = {
  [LiteralValueType.STRING_LIST]: LiteralValueType.STRING,
  [LiteralValueType.FLOAT_LIST]: LiteralValueType.FLOAT,
  [LiteralValueType.INTEGER_LIST]: LiteralValueType.INTEGER,
};
const ACCEPTED_TYPES = [...values(LITERAL_TYPE_AS_LIST), ...values(LITERAL_LIST_AS_TYPE)];
const NO_RIGHT_OPS = [ConditionOperator.IS_NULL, ConditionOperator.IS_NOT_NULL];
/**
 * This function is used to change a Literal according to its related operator.
 * If the op exists in LITERAL_LIST_OPS, we need to change the value_type to its "list" correlated type,
 * otherwise do the opposite.
 */
export const mapLiteralByOp = (literal: Literal, op: ConditionOperator): Literal => {
  if (NO_RIGHT_OPS.indexOf(op) > -1) {
    const newLiteral = QueryBuilderFactory.createLiteral(true);
    newLiteral.value_type = LiteralValueType.NULL;
    return newLiteral;
  }
  if (ACCEPTED_TYPES.indexOf(literal.value_type) === -1) {
    return literal;
  }
  const isListValue = LITERAL_LIST_TYPES.indexOf(literal.value_type) > -1;

  if (LITERAL_LIST_OPS.indexOf(op) > -1 && !isListValue) {
    literal.value_type = LITERAL_TYPE_AS_LIST[literal.value_type];
    return literal;
  }
  if (LITERAL_LIST_OPS.indexOf(op) === -1 && isListValue) {
    literal.value_type = LITERAL_LIST_AS_TYPE[literal.value_type];
    return literal;
  }

  return literal;
};

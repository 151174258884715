import * as React from 'react';
import classes from './view-workflow.module.scss';
import {useHistory, useParams} from 'react-router';
import {WORKFLOW_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';
import {
  createSelected,
  getSelected,
  removeSelected,
} from '../../../../store/selected/selected.actions';
import {getAnalysisResultNetworkRequest} from '../../../../http/analysis-results.network-requests';
import {ModelKey} from '../../../../constants/model-key';
import {getWorkflowNetworkRequest} from '../../../../http/workflows.network-requests';
import {
  getReducedLoadingStateSelector,
  getSingleSelectedSelector,
} from '../../../../store/store.selectors';
import {Workflow} from '../../../../objects/models/workflow.model';
import {AnalysisResult} from '../../../../objects/models/analysis-result.model';
import TransKeys from '../../../../constants/translation-keys';
import PageLayout from '../../../shared/components/layout/page-layout';
import {AnalysisResults} from '../../../shared/core/document-viewer/analysis-results.component';
import {EmptyState} from '../../../shared/components/general/override';
import {useTranslation} from 'react-i18next';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {WorkflowHeader} from './components/workflow-header/workflow-header.component';

interface OwnProps {}

type AllProps = OwnProps;

const SELECTED_WORKFLOW_KEY = 'VIEW_WORKFLOW/WORKFLOW';
const SELECTED_ANALYSIS_RESULT_KEY = 'VIEW_WORKFLOW/ANALYSIS_RESULT';

export const ViewWorkflow: React.FC<AllProps> = (props: AllProps) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const history = useHistory();
  const {[WORKFLOW_ID_PATH_PARAM]: workflowId} = useParams<any>();
  const [reviewedAnalysisResultId, setReviewedAnalysisResultId] = React.useState<number | null>(
    null
  );

  const workflow: Workflow = useSelector(state =>
    getSingleSelectedSelector(SELECTED_WORKFLOW_KEY, state)
  );
  const isLoadingWorkflow = useSelector(state =>
    getReducedLoadingStateSelector(SELECTED_WORKFLOW_KEY)(state)
  );
  const analysisResult: AnalysisResult = useSelector(state =>
    getSingleSelectedSelector(SELECTED_ANALYSIS_RESULT_KEY, state)
  );
  const isLoadingResult = useSelector(state =>
    getReducedLoadingStateSelector(SELECTED_ANALYSIS_RESULT_KEY)(state)
  );
  const resultId =
    reviewedAnalysisResultId || workflow?.lastCompletedResultId || workflow?.lastResultId;
  const showEmptyState =
    (!analysisResult || analysisResult.rootDocumentId === null) &&
    !isLoadingResult &&
    !isLoadingWorkflow;

  useEffect(() => {
    dispatch(
      createSelected({
        selectedKey: SELECTED_WORKFLOW_KEY,
        actionKey: SELECTED_WORKFLOW_KEY,
        request: getWorkflowNetworkRequest,
        modelKey: ModelKey.WORKFLOW,
      })
    );
    dispatch(
      createSelected({
        selectedKey: SELECTED_ANALYSIS_RESULT_KEY,
        actionKey: SELECTED_ANALYSIS_RESULT_KEY,
        request: getAnalysisResultNetworkRequest,
        modelKey: ModelKey.ANALYSIS_RESULT,
      })
    );
    // clean up
    return () => {
      dispatch(removeSelected(SELECTED_ANALYSIS_RESULT_KEY));
      dispatch(removeSelected(SELECTED_WORKFLOW_KEY));
    };
  }, [dispatch]);

  useEffect(() => {
    // When analysis result id changes we need to get it
    workflowId && dispatch(getSelected(SELECTED_WORKFLOW_KEY, workflowId));
  }, [workflowId, dispatch]);
  useEffect(() => {
    if (resultId) {
      dispatch(getSelected(SELECTED_ANALYSIS_RESULT_KEY, resultId));
    }
  }, [resultId, dispatch]);

  return (
    <PageLayout.Layout>
      <WorkflowHeader
        workflow={workflow}
        analysisResult={analysisResult}
        onChangeAnalysisResult={analysisResultId => setReviewedAnalysisResultId(analysisResultId)}
      />
      <PageLayout.Body noPadding isLoading={isLoadingWorkflow || isLoadingResult}>
        {isLoadingWorkflow && <GenericLoading />}
        {analysisResult && analysisResult.rootDocumentId && (
          <AnalysisResults
            analysisResult={analysisResult}
            isLoading={isLoadingResult}
            navigationDisabled
          />
        )}
        {showEmptyState && (
          <div className={classes.EmptyStateWrapper}>
            <EmptyState
              title={t(TransKeys.VIEW_ANALYSIS.WAITING_FOR_RESULTS.TITLE)}
              subTitle={t(TransKeys.VIEW_ANALYSIS.WAITING_FOR_RESULTS.SUB_TITLE)}
              buttonText={t(TransKeys.GENERAL.ACTIONS.BACK)}
              onClick={_ => history.goBack()}
            />
          </div>
        )}
      </PageLayout.Body>
    </PageLayout.Layout>
  );
};

import * as React from 'react';
import classes from './auth-scope-form-container.module.scss';
import {ImagesResource} from '../../../../../assets/images';

interface OwnProps {
  children?: React.ReactNode;
}

type AllProps = OwnProps;

const AuthScopeFormContainer: React.FC<AllProps> = props => {
  const {children} = props;
  return (
    <div className={classes.Container}>
      <div className={classes.Logo}>
        <img src={ImagesResource.app.logoPurple} alt={'Logo'} />
      </div>
      <div className={classes.FormContainer}>{children}</div>
    </div>
  );
};

export default AuthScopeFormContainer;

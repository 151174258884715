import * as React from 'react';
import {useCallback, useMemo} from 'react';
import {searchUsersNetworkRequest} from '../../../../../../http/users.network-requests';
import {
  MultiSelectInput,
  TextareaFormInput,
  TextFormInput,
} from '../../../../../shared/form/components/shared-form-input.component';
import TransKeys from '../../../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {AmplitudeEvent} from '../../../../../../constants/amplitude-event';
import {ShareStrategyTypes} from '../../../../../../objects/models/share.model';
import {useAmplitude} from '../../../../../../core/hooks/amplitude.hook';

interface OwnProps {}

type AllProps = OwnProps;

export const DefaultShareFormFields: React.FC<AllProps> = (props: AllProps) => {
  const {t} = useTranslation();
  const notify = useAmplitude();
  const recipientsOptionsProp = useMemo(
    () => ({
      networkRequest: searchUsersNetworkRequest,
      labelAttributePath: 'fullName',
      valueAttributePath: 'id',
    }),
    []
  );
  const onShareInputChange = useCallback(
    (field: string) =>
      notify(AmplitudeEvent.RESOURCE_SHARE_INPUT_CHANGED, {
        strategy: ShareStrategyTypes.DEFAULT,
        field,
      }),
    [notify]
  );

  const onInviteesChange = useCallback(() => onShareInputChange('invitees'), [onShareInputChange]);
  const onRecipientsChange = useCallback(
    () => onShareInputChange('recipients'),
    [onShareInputChange]
  );

  return (
    <>
      <MultiSelectInput
        label={t(TransKeys.SHARE_RESOURCE_FORM.INPUTS.RECIPIENTS.LABEL)}
        name={'recipients'}
        options={recipientsOptionsProp}
        placeholder={t(TransKeys.SHARE_RESOURCE_FORM.INPUTS.RECIPIENTS.PLACEHOLDER)}
        onChange={onRecipientsChange}
      />
      <TextFormInput
        name={'invitees'}
        label={t(TransKeys.SHARE_RESOURCE_FORM.INPUTS.INVITEES.LABEL)}
        placeholder={t(TransKeys.SHARE_RESOURCE_FORM.INPUTS.INVITEES.PLACEHOLDER)}
        helperText={t(TransKeys.SHARE_RESOURCE_FORM.INPUTS.INVITEES.HELPER_TEXT)}
        multiple
        onChange={onInviteesChange}
      />
      <TextareaFormInput
        label={t(TransKeys.SHARE_RESOURCE_FORM.INPUTS.MESSAGE.LABEL)}
        name={'message'}
        placeholder={t(TransKeys.SHARE_RESOURCE_FORM.INPUTS.MESSAGE.PLACEHOLDER)}
        helperText={t(TransKeys.SHARE_RESOURCE_FORM.INPUTS.MESSAGE.HELPER_TEXT)}
      />
    </>
  );
};

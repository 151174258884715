import {Team} from '../../../../../../../../../objects/models/team.model';
import {TeamIconChip} from 'ui-components';
import {ListRowItemCell} from '../../../../../../../../shared/components/general/list-row-item-cell/list-row-item-cell.component';
import classes from './experiment-team-cell.module.scss';

type ExperimentTeamCellProps = {
  team?: Team;
  className?: string;
};

export const ExperimentTeamCell = (props: ExperimentTeamCellProps) => {
  const {team, className} = props;

  return (
    <ListRowItemCell
      title={'Team'}
      className={className}
      content={<>{team ? <TeamIconChip {...team} /> : <span className={classes.NoTeam}>-</span>}</>}
    />
  );
};

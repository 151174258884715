import {Epic} from 'redux-observable';
import {createRequestEpic} from 'front-core';
import {ActionKey} from '../../constants/action-key';
import HttpClient from '../../services/http-client.service';
import {notifyEvent} from '../core/core.actions';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {showToastMessage} from '../interface/interface.actions';
import {ToastType} from '../../objects/system/toast-type.enum';
import {SourceTableActionType} from './source-tables.actions';
import {triggerSourceTableDiscoveryNetworkRequest} from '../../http/source-tables.network-requests';
import {SOURCE_META_KEY} from '../../constants/app-sources';
import {demoProductLimitedActionsFilter} from '../store.utils';

export const triggerSourceTableDiscoveryEpic: Epic = createRequestEpic(
  {
    types: [SourceTableActionType.TRIGGER_SOURCE_TABLE_DISCOVERY],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.TRIGGER_SOURCE_TABLE_DISCOVERY,
    request: triggerSourceTableDiscoveryNetworkRequest,
    onSuccess: (res, _, {metadata}) => [
      notifyEvent(AmplitudeEvent.SOURCE_TABLE_DISCOVERY, {source: metadata?.[SOURCE_META_KEY]}),
      showToastMessage(
        'Job sent, it might take a few minutes until the table will appear',
        ToastType.SUCCESS
      ),
    ],
    onError: err_ => [showToastMessage('Failed to trigger scan data warehouse', ToastType.ERROR)],
  },
  HttpClient
);

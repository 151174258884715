import * as React from 'react';
import classNames from 'classnames';
import {
  AICMessageFeedback,
  AIConversationRole,
  AIConversationThread,
  AIModelType,
  RunAnalysisAction,
} from '../../../../../../objects/models/ai-chat.model';
import classes from './chat-messages.module.scss';
import {
  Avatar,
  IconButton,
  MicrochipAILightIcon,
  ThumpUpIcon,
  ThumbDownIcon,
  TrashIcon,
  EditIcon,
  TextInput,
  CloseIcon,
  CheckIcon,
  CircleInfoRegularIcon,
} from 'ui-components';
import moment from 'moment';
import {TIME_FORMATS} from '../../../../../../constants/time-formats';
import {exists} from 'front-core';
import {useAnalysisTypesCatalog} from '../../../../hooks/use-analysis-types-catalog.hook';
import {useCallback, useMemo, useState} from 'react';
import {keyBy} from 'lodash';
import {Tooltip} from '@material-ui/core';
import {MessageRenderer} from '../message-renderer/message-renderer.component';

interface OwnProps {
  thread: AIConversationThread;
  onStartNewSession: () => void;
  onDeleteThread: () => void;
  onUpdateThreadTitle: (title: string) => void;
  onMessageFeedback: (messageId: number, feedback: AICMessageFeedback) => void;
  onRunAnalysisAction: (action: RunAnalysisAction) => void;
  onModelReference: (modelId: number, modelType: AIModelType) => void;
  isLoading?: boolean;
  username: string;
  className?: string;
}

type AllProps = OwnProps;

export const ChatMessages: React.FC<AllProps> = (props: AllProps) => {
  const {
    thread,
    isLoading,
    onMessageFeedback,
    onStartNewSession,
    onDeleteThread,
    onUpdateThreadTitle: onUpdateThreadTitleFromProps,
    onRunAnalysisAction,
    onModelReference,
    username,
    className,
  } = props;
  const [editTitleMode, setEditTitleMode] = useState(false);
  const [threadTitle, setThreadTitle] = useState('');
  const {analysisTypes} = useAnalysisTypesCatalog({});
  const analysisTypesMap = useMemo(() => keyBy(analysisTypes, 'id'), [analysisTypes]);

  const onEditTitle = useCallback(
    (edit: boolean) => {
      if (edit) {
        setEditTitleMode(true);
        setThreadTitle(thread.title);
        return;
      }
      setEditTitleMode(false);
    },
    [thread, setEditTitleMode, setThreadTitle]
  );
  const onUpdateThreadTitle = useCallback(() => {
    if (threadTitle !== thread.title) {
      onUpdateThreadTitleFromProps(threadTitle);
    }
    onEditTitle(false);
  }, [onUpdateThreadTitleFromProps, onEditTitle, threadTitle, thread]);

  if (!exists(thread)) {
    return (
      <div className={classNames(classes.ChatMessages, className)}>
        <div className={classNames(classes.ChatHeader, classes.Disabled)}>New session</div>
        <div className={classes.Messages}>
          <div className={classes.EmptyState}>
            <div className={classes.Info}>
              <CircleInfoRegularIcon className={classes.InfoIcon} />
            </div>
            <div className={classes.Content}>
              Let me help you find answers to your analytics questions. Check important info.
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(classes.ChatMessages, className)}>
      {thread.title && (
        <div className={classes.ChatHeader}>
          {!editTitleMode && <div className={classes.ThreadName}>{thread.title}</div>}
          {editTitleMode && (
            <div className={classes.EditThreadName}>
              <TextInput value={threadTitle} onChange={title => setThreadTitle(title as any)} />
            </div>
          )}
          {!editTitleMode && (
            <div className={classes.Actions}>
              <IconButton
                icon={EditIcon}
                border={false}
                onClick={() => onEditTitle(true)}
                tooltipText={'Edit name'}
              />
              <IconButton
                icon={TrashIcon}
                border={false}
                onClick={() => onDeleteThread()}
                tooltipText={'Remove session'}
              />
            </div>
          )}
          {editTitleMode && (
            <div className={classes.Actions}>
              <IconButton
                icon={CheckIcon}
                border={false}
                onClick={() => onUpdateThreadTitle()}
                tooltipText={'Save'}
              />
              <IconButton
                icon={CloseIcon}
                border={false}
                onClick={() => onEditTitle(false)}
                tooltipText={'Cancel'}
              />
            </div>
          )}
        </div>
      )}
      <div className={classes.Messages}>
        {thread.messages.map(m => (
          <div
            className={classNames(
              classes.Message,
              m.role === AIConversationRole.USER ? classes.UserMessage : classes.BotMessage
            )}
          >
            <div className={classes.Avatar}>
              {m.role === AIConversationRole.USER && <Avatar name={username} />}
              {m.role === AIConversationRole.ASSISTANT && (
                <Tooltip title={'Loops Bot'} placement={'top'} interactive={false}>
                  <div className={classes.ChatAvatar}>
                    <MicrochipAILightIcon className={classes.ChartAvatarIcon} />
                  </div>
                </Tooltip>
              )}
            </div>
            <MessageRenderer
              message={m}
              analysisTypes={analysisTypesMap}
              onRunAnalysisAction={onRunAnalysisAction}
              onModelReference={onModelReference}
            />
            {m.role === AIConversationRole.ASSISTANT && (
              <div className={classes.Feedback}>
                <IconButton
                  tooltipText={
                    <span>
                      That's <b>correct</b>
                    </span>
                  }
                  className={classNames(
                    classes.FeedbackButton,
                    m.feedback === AICMessageFeedback.CORRECT && classes.FeedbackButtonActiveGreen
                  )}
                  icon={ThumpUpIcon}
                  onClick={() => onMessageFeedback(m.id, AICMessageFeedback.CORRECT)}
                />
                <IconButton
                  tooltipText={
                    <span>
                      That's <b>incorrect</b>
                    </span>
                  }
                  className={classNames(
                    classes.FeedbackButton,
                    m.feedback === AICMessageFeedback.INCORRECT && classes.FeedbackButtonActiveRed
                  )}
                  icon={ThumbDownIcon}
                  onClick={() => onMessageFeedback(m.id, AICMessageFeedback.INCORRECT)}
                />
              </div>
            )}
          </div>
        ))}
        {thread.completedOn && (
          <div className={classes.SessionCompleted}>
            <span className={classes.Text}>
              Session completed on{' '}
              {moment(thread.completedOn).format(TIME_FORMATS.READABLE_DATE_TIME)}
            </span>
            <span onClick={onStartNewSession} className={classes.NewSessionButton}>
              Click here to start a new session
            </span>
          </div>
        )}
        {isLoading && <div className={classes.LoadingMessage}>Working on it</div>}
      </div>
    </div>
  );
};

import {BaseModel} from './base.model';
import {TableEntity} from './table.model';
import {HistorifyModel} from './historify-model.model';
import {ValidationStatus} from './signal.model';

export enum EventGroupType {
  EVENT_GROUP = 'event_group',
  EVENT_PROPERTIES = 'event_properties',
}

export interface EventGroup extends BaseModel, HistorifyModel {
  name: string;
  productId: number;
  signalId: number;
  shortDescription: string;
  type: EventGroupType;
  entity: TableEntity;
  signalDefinition: any;
  signalValidationStatus?: ValidationStatus;
}

import {get} from 'lodash';
import {ActiveUsersQueryBuilder} from './active-users-query-builder/active-users-query-builder.component';
import {ConversionQueryBuilder} from './conversion-builder/conversion-query-builder.component';
import {LevelOfActiveQueryBuilder} from './level-of-active-query-builder/level-of-active-query-builder.component';
import {RetentionQueryBuilder} from './retention-builder/retention-query-builder.component';
import {SignalQueryBuilder} from './signal-builder/signal-query-builder.component';
import {SQLQueryBuilder} from './sql-query-builder/sql-query-builder.component';
import {TableColumnQueryBuilder} from './table-column-query-builder/table-column-query-builder.component';
import {TableEventsQueryBuilder} from './table-events-query-builder/table-events-query-builder.component';
import {TableEventsValueQueryBuilder} from './table-events-value-query-builder/table-events-value-query-builder.component';
import {UserPropertyColumnQueryBuilder} from './user-property-column-query-builder/user-property-column-query-builder.component';
import {UserPropertyConditionsQueryBuilder} from './user-property-conditions-query-builder/user-property-conditions-query-builder.component';
import {TemplateItemQueryBuilder} from './template-item-query-builder/template-item-query-builder.component';
import {EventGroupQueryBuilder} from './event-group-query-builder/event-group-query-builder.component';
import {RateQueryBuilder} from './rate-query-builder/rate-query-builder.component';
import {METADATA_KEY, PARAMETERS_METADATA_KEY} from '../../../../constants/parameters-saved-keys';
import {UsageQueryBuilder} from './usage-query-builder/usage-query-builder.component';
import {RevenueQueryBuilder} from './revenue-query-builder/revenue-query-builder.component';
import {PaymentRetentionQueryBuilder} from './payment-retention-builder/payment-retention-query-builder.component';
import {RevenueChurnQueryBuilder} from './revenue-churn-query-builder/revenue-churn-query-builder.component';
import {FunnelStepQueryBuilder} from './funnel-step-query-builder/funnel-step-query-builder.component';
import {HabitMomentQueryBuilder} from './habit-moment-query-builder/habit-moment-query-builder.component';
import {ActiveQueryBuilder} from './active-query-builder/active-query-builder.component';
import {CustomSqlQueryBuilder} from './custom-sql-query-builder/custom-sql-query-builder.component';
import {PopulationSegmentQueryBuilder} from './population-segment-query-builder/population-segment-query-builder.component';
import {BehavioralChurnQueryBuilder} from './behavioral-churn-query-builder/behavioral-churn-query-builder.component';
import {BoundedRevenueQueryBuilder} from './bounded-revenue-query-builder/bounded-revenue-query-builder.component';
import {PeriodOverPeriodQueryBuilder} from './period-over-period-query-builder/period-over-period-query-builder.component';
import {BehavioralFirstOrLastValueQueryBuilder} from './behavioral-first-or-last-value-query-builder/behavioral-first-or-last-value-query-builder.component';
import {BehavioralMilestoneQueryBuilder} from '../../../dmp/panels/milestone-form-panel/behavioral-milestone-query-builder/behavioral-milestone-query-builder.component';

const BUILDERS_MAP = {
  ActiveQueryBuilder: ActiveQueryBuilder,
  ActiveUsersQueryBuilder: ActiveUsersQueryBuilder,
  ConversionQueryBuilder: ConversionQueryBuilder,
  LevelOfActiveQueryBuilder: LevelOfActiveQueryBuilder,
  RetentionQueryBuilder: RetentionQueryBuilder,
  PeriodOverPeriodQueryBuilder: PeriodOverPeriodQueryBuilder,
  SignalQueryBuilder: SignalQueryBuilder,
  SQLQueryBuilder: SQLQueryBuilder,
  CustomSqlQueryBuilder: CustomSqlQueryBuilder,
  TableColumnQueryBuilder: TableColumnQueryBuilder,
  TableEventsQueryBuilder: TableEventsQueryBuilder,
  TableEventsValueQueryBuilder: TableEventsValueQueryBuilder,
  UserPropertyColumnQueryBuilder: UserPropertyColumnQueryBuilder,
  UserPropertyConditionsQueryBuilder: UserPropertyConditionsQueryBuilder,
  TemplateItemQueryBuilder: TemplateItemQueryBuilder,
  EventGroupQueryBuilder: EventGroupQueryBuilder,
  RateQueryBuilder: RateQueryBuilder,
  UsageQueryBuilder: UsageQueryBuilder,
  RevenueQueryBuilder: RevenueQueryBuilder,
  BoundedRevenueQueryBuilder: BoundedRevenueQueryBuilder,
  PaymentRetentionQueryBuilder: PaymentRetentionQueryBuilder,
  RevenueChurnQueryBuilder: RevenueChurnQueryBuilder,
  FunnelStepQueryBuilder: FunnelStepQueryBuilder,
  HabitMomentQueryBuilder: HabitMomentQueryBuilder,
  PopulationSegmentQueryBuilder: PopulationSegmentQueryBuilder,
  BehavioralMilestoneQueryBuilder: BehavioralMilestoneQueryBuilder,
  BehavioralChurnQueryBuilder: BehavioralChurnQueryBuilder,
  BehavioralFirstOrLastValueQueryBuilder: BehavioralFirstOrLastValueQueryBuilder,
};

/**
 * Returns the custom query builder if the query was created by it
 * @param query
 */
export const getComponentForQuery = (query: any) => {
  const uiParameters = get(query, PARAMETERS_METADATA_KEY, {});
  const componentName = uiParameters[METADATA_KEY.BUILDER_COMPONENT_NAME_KEY];
  return BUILDERS_MAP[componentName];
};

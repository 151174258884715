import React, {FC, useCallback, useMemo} from 'react';
import classNames from 'classnames';
import classes from './analysis-type-selection-sentence-wizard.module.scss';
import {ModelType, Select} from 'ui-components';
import {AnalysisTypeWizardSmartSelector} from '../../../../../../../shared/core/smart-selector/analysis-type-wizard-smart-selector.component';
import {AnalysisTypeTopic, getTopicOptions} from '../../analysis-type-selection-tab.utils';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {exists} from 'front-core';

export interface AnalysisTypeSubject {
  model?: any;
  modelType?: ModelType;
  topic?: AnalysisTypeTopic;
}

interface OwnProps extends AnalysisTypeSubject {
  onChange: (change: AnalysisTypeSubject) => void;
  onSignalInfo: (signalId: number) => void;
  className?: string;
}

type AllProps = OwnProps;

export const AnalysisTypeSelectionSentenceWizard: FC<AllProps> = React.memo((props: AllProps) => {
  const {model, modelType, topic, onChange, onSignalInfo, className} = props;
  const {t} = useTranslation();

  const onTopicChange = useCallback(
    (topic: AnalysisTypeTopic) => {
      onChange({topic});
    },
    [onChange]
  );
  const onModelChange = useCallback(
    (model: any) => {
      if (!exists(model)) {
        onChange(null);
        return;
      }
      onChange({
        model,
        // todo: to that in a better way
        modelType: 'steps' in model ? ModelType.FUNNEL : ModelType.METRIC,
        topic: undefined,
      });
    },
    [onChange]
  );

  const topicOptions = useMemo(() => {
    return {options: getTopicOptions(modelType as any, model)};
  }, [model, modelType]);

  return (
    <div className={classNames(classes.Container, className)}>
      <div className={classes.Header}>
        <div className={classes.Title}>
          <span>{t(TransKeys.CREATE_ANALYSIS_FORM.WIZARD.TITLE)}</span>
        </div>
      </div>
      <div className={classes.Content}>
        <div className={classes.Sentence}>
          <span>{t(TransKeys.CREATE_ANALYSIS_FORM.WIZARD.SELECT_MODEL_PREFIX)}</span>
          <AnalysisTypeWizardSmartSelector
            className={classNames(classes.TextSelectDropdown, model?.id && classes.Selected)}
            placeholder={t(TransKeys.CREATE_ANALYSIS_FORM.WIZARD.SELECT_MODEL_PLACEHOLDER)}
            value={model?.id}
            onChange={(v, item) => onModelChange(item)}
            onSignalInfo={onSignalInfo as any}
          />
        </div>
        <div className={classNames(classes.Sentence, !model && classes.Disabled)}>
          <span>{t(TransKeys.CREATE_ANALYSIS_FORM.WIZARD.SELECT_TOPIC_PREFIX)}</span>
          <Select
            placeholder={t(TransKeys.GENERAL.LABELS.SELECT)}
            dropdownButtonClassName={classNames(
              classes.TextSelectDropdown,
              exists(topic) && classes.Selected
            )}
            value={topic}
            onChange={v => onTopicChange(v as AnalysisTypeTopic)}
            options={topicOptions}
            searchable={false}
            clearable={exists(topic)}
            disabled={!model}
          />
        </div>
      </div>
    </div>
  );
});

import * as React from 'react';
import classNames from 'classnames';
import classes from './experiment-scope-selector.module.scss';
import {ParameterInputWrapper} from '../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {useCallback, useMemo} from 'react';
import {Checkbox, HoverHelperTip, LabelWrapper} from 'ui-components';
import {TableEventsQueryBuilder} from '../../../../../shared/core/query-builders/table-events-query-builder/table-events-query-builder.component';
import {get, pick, values} from 'lodash';
import {hasErrors} from '../../../../../../utils/general.utils';

interface SchemaKeysMapping {
  start_event_query: string;
  assignment_mode: string;
  assignment_type: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  start_event_query: 'start_event_query',
  assignment_mode: 'assignment_mode',
  assignment_type: 'assignment_type',
};

interface OwnProps {
  title: string;
  onChange: (parameters: any) => void;
  value: any;
  schemaKeysMapping?: SchemaKeysMapping;
  onSignalInfo?: (value: string | number) => void;
  subTitle?: string;
  bottomHelper?: any;
  helperText?: any;
  errors?: any;
  className?: string;
}

type AllProps = OwnProps;

export enum ExperimentScopeMode {
  NEW_USERS = 'new_users',
  EVENTS = 'events',
  AS_ASSIGNMENT = 'as_assignment',
}

export const ExperimentScopeSelector: React.FC<AllProps> = (props: AllProps) => {
  const {
    title,
    subTitle,
    helperText,
    bottomHelper,
    className,
    schemaKeysMapping,
    value,
    onChange,
    errors: errors_,
  } = props;
  const mode = useMemo(
    () => get(value, schemaKeysMapping.assignment_mode),
    [value, schemaKeysMapping]
  );
  const assignmentType = useMemo(
    () => get(value, schemaKeysMapping.assignment_type),
    [value, schemaKeysMapping]
  );
  const query = useMemo(
    () => get(value, schemaKeysMapping.start_event_query),
    [value, schemaKeysMapping]
  );
  const errors = useMemo(
    () => pick(errors_, [schemaKeysMapping.start_event_query, schemaKeysMapping.assignment_mode]),
    [errors_, schemaKeysMapping]
  );
  const hasError = useMemo(
    () => hasErrors(errors, values(schemaKeysMapping)),
    [errors, schemaKeysMapping]
  );
  const setNewUsersMode = useCallback(() => {
    onChange({
      [schemaKeysMapping.start_event_query]: null,
      [schemaKeysMapping.assignment_mode]: ExperimentScopeMode.NEW_USERS,
    });
  }, [schemaKeysMapping, onChange]);
  const setEventsMode = useCallback(() => {
    onChange({
      [schemaKeysMapping.start_event_query]: null,
      [schemaKeysMapping.assignment_mode]: ExperimentScopeMode.EVENTS,
    });
  }, [schemaKeysMapping, onChange]);
  const setAsAssignmentMode = useCallback(() => {
    onChange({
      [schemaKeysMapping.start_event_query]: undefined,
      [schemaKeysMapping.assignment_mode]: ExperimentScopeMode.AS_ASSIGNMENT,
    });
  }, [schemaKeysMapping, onChange]);

  return (
    <ParameterInputWrapper
      title={title}
      subTitle={subTitle}
      helperText={helperText}
      className={classNames(classes.ExperimentScopeSelector, className)}
      bottomHelper={bottomHelper}
      error={hasError}
    >
      {assignmentType === 'event' && (
        <div onClick={setAsAssignmentMode} className={classes.Option}>
          <Checkbox
            className={classes.Checkbox}
            checked={mode === ExperimentScopeMode.AS_ASSIGNMENT}
            onChange={setAsAssignmentMode}
          />
          <div className={classes.Label}>All users with assignment event</div>
        </div>
      )}
      <div onClick={setNewUsersMode} className={classes.Option}>
        <Checkbox
          className={classes.Checkbox}
          checked={mode === ExperimentScopeMode.NEW_USERS}
          onChange={setNewUsersMode}
        />
        <div className={classes.Label}>Users who joined after the experiment</div>
        <HoverHelperTip title={'Suits experiments on the beginning of the funnel.'} />
      </div>
      <div onClick={setEventsMode} className={classes.Option}>
        <Checkbox
          className={classes.Checkbox}
          checked={mode === ExperimentScopeMode.EVENTS}
          onChange={setEventsMode}
        />
        <div className={classes.Label}>Users who were exposed to a specific event</div>
      </div>
      {mode === ExperimentScopeMode.EVENTS && (
        <LabelWrapper className={classes.Builder} label={'Definition'}>
          <TableEventsQueryBuilder
            query={query}
            errors={get(errors, schemaKeysMapping.start_event_query)}
            onChange={q => onChange({[schemaKeysMapping.start_event_query]: q})}
            multiEvents={false}
          />
        </LabelWrapper>
      )}
    </ParameterInputWrapper>
  );
};

ExperimentScopeSelector.defaultProps = {
  schemaKeysMapping: DEFAULT_SCHEMA_KEYS_MAPPING,
};

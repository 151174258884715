import yup from '../../../../../config/yup.config';
import {PARAMETERS_METADATA_KEY} from '../../../../../constants/parameters-saved-keys';
import {values} from 'lodash';
import {TableEntity} from '../../../../../objects/models/table.model';
import {queryElementValidatorFactory} from '../../../../../objects/dto/query-builder.dto';
import {
  startEndDatesValidator,
  treatmentValidator,
  validateSignalTimeframeBoundingFor,
} from '../shared-validators';
import {ParameterType} from 'ui-components';
import {
  AnalysisParametersTransformer,
  baseAnalysisParametersTransformer,
} from '../shared-transformers';

export const analysis96ParametersValidator = yup.object().shape({
  parameters: yup
    .object()
    .shape({
      [PARAMETERS_METADATA_KEY]: yup.object().nullable(),
      entity: yup.string().oneOf(values(TableEntity)).required(),
      reference_date: yup.number().required(),
      group_by_signal: yup.number().nullable(),
      population_filter: queryElementValidatorFactory(false),
      ...treatmentValidator('feature_tag'),
    })
    .required(),
  runParameters: startEndDatesValidator.required().test(
    validateSignalTimeframeBoundingFor({
      signalIdParameter: 'reference_date',
      parameterName: 'Reference point',
      timeAggregationDefaultValue: 'week',
    })
  ),
});

export const analysis96ParametersTransformer: AnalysisParametersTransformer = (
  values,
  schema,
  productEntities
) => {
  [values, schema] = baseAnalysisParametersTransformer(values, schema, productEntities);
  const newValues = {...values};

  if ('reference_date' in schema) {
    schema['reference_date'].name = 'Reference point';
  }

  delete schema['feature_tag'];
  delete schema['treatments_signals'];
  schema['feature_tag'] = {
    name: 'Scope',
    type: ParameterType.__MERGED_TREATMENTS,
    key: 'feature_tag',
    order: 4,
  };
  newValues['feature_tag'] = [
    ...(newValues['feature_tag'] || []),
    ...(newValues['treatments_signals'] || []),
  ];

  return [newValues, schema];
};

import {createContext, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import * as React from 'react';
import {ParametersSchema, ParametersSchemaConfig} from 'ui-components';
import {keys, values} from 'lodash';
import {
  AfterSubmitHandler,
  AnalysisParametersFormMode,
} from '../../../analyses/panels/analysis-form-panel/components/analysis-parameters-tab/analysis-parameters-tab.component';

export type ParametersFormDefaultHandler = (parameters: any) => any;

export interface IParametersFormContext {
  schema: ParametersSchema;
  schemaConfig: ParametersSchemaConfig;
  parameters: any;
  formMode: AnalysisParametersFormMode;
  disabled?: boolean;
  errors?: any;
  changeParametersValue: (newParameters: any) => void;
  registerDefaultHandler: (key: string, handler: ParametersFormDefaultHandler) => void;
  removeDefaultHandler: (key: string) => void;
  setAfterSubmitHandler: (handler: AfterSubmitHandler) => void;
}

export const ParametersFormContext = createContext<IParametersFormContext>({
  schema: undefined,
  schemaConfig: undefined,
  parameters: undefined,
  disabled: undefined,
  errors: undefined,
  formMode: undefined,
  changeParametersValue: undefined,
  registerDefaultHandler: undefined,
  removeDefaultHandler: undefined,
  setAfterSubmitHandler: undefined,
});

interface OwnProps {
  schema: ParametersSchema;
  schemaConfig: ParametersSchemaConfig;
  parameters: any;
  onChange?: (value: any) => void;
  formMode: AnalysisParametersFormMode;
  setAfterSubmitHandler?: (handler: AfterSubmitHandler) => void;
  disabled?: boolean;
  errors?: any;
  children: any;
}

export const ParametersFormContextProvider: React.FC<OwnProps> = (props: OwnProps) => {
  const {
    schema,
    schemaConfig,
    parameters,
    errors,
    formMode,
    disabled,
    onChange: onChangeFromProps,
    setAfterSubmitHandler,
    children,
  } = props;
  const initialSetDefault = useRef(false);
  const [defaultValues, setDefaultValues] = useState<any>(undefined);
  const parametersRef = useRef<any>(undefined);
  const defaultHandlers = useRef<{[key: string]: ParametersFormDefaultHandler}>({});
  const previousDefaultValuesRef = useRef<any>(undefined);
  // parametersRef.current will always have the recent value of parameters
  // this is done to overcome useCallback re-evaluating every time parameters changed (as dependency)
  parametersRef.current = parameters;

  // This function is used to register a default handler
  // key - the registration key - will be used to remove after registration
  const registerDefaultHandler = useRef((key: string, handler: ParametersFormDefaultHandler) => {
    defaultHandlers.current[key] = handler;
    // If the context we already initiated, we'll set the defaults ourselves
    if (initialSetDefault.current === true) {
      const newDefaults = handler(parametersRef.current);
      if (!newDefaults) {
        return;
      }
      setDefaultValues(defaults => ({...defaults, ...newDefaults}));
    }
  });
  // remove a registered default handler
  const removeDefaultHandler = useRef((key: string) => {
    delete defaultHandlers.current[key];
  });

  const changeParametersValue = useCallback(
    newParameters =>
      onChangeFromProps &&
      onChangeFromProps({
        ...parameters, // maybe cloneDeep?
        ...newParameters,
      }),
    [parameters, onChangeFromProps]
  );

  // triggered by the effects when defaultValues were changed
  const onDefaultsChange = useCallback(
    defaultValues => {
      const newParameters = {
        ...parametersRef.current,
      };

      for (const k of keys(defaultValues)) {
        // if this parameter value is not defined or its value is the previous default value
        // we'll update to the new default value
        if (
          newParameters[k] === undefined ||
          newParameters[k] === previousDefaultValuesRef.current?.[k]
        ) {
          newParameters[k] = defaultValues[k];
        }
      }
      onChangeFromProps && onChangeFromProps(newParameters);
    },
    [onChangeFromProps]
  );

  // This effect is responsible for setting the initial default values
  // After mount you will be to update defaults yourself (see registerDefaultHandler)
  useEffect(() => {
    let defaultValues = {};
    for (const handler of values(defaultHandlers.current)) {
      const res = handler(parametersRef.current);
      defaultValues = {
        ...defaultValues,
        ...(res || {}),
      };
    }
    setDefaultValues(defaultValues);
  }, []);

  // Syncing default values after initialSetDefault
  useEffect(() => {
    if (initialSetDefault.current === true) {
      onDefaultsChange(defaultValues);
    }
  }, [defaultValues, onDefaultsChange]);
  // Initial setting of default values
  useEffect(() => {
    if (initialSetDefault.current === false && defaultValues !== undefined) {
      onDefaultsChange(defaultValues);
      initialSetDefault.current = true;
    }
  }, [defaultValues, onDefaultsChange]);

  // This effect is responsible for updating the previousDefaultValuesRef.current to hold the last value
  // IT MUST BE THE LAST EFFECT JUST BEFORE RENDER IN ORDER TO WORK!
  useEffect(() => {
    previousDefaultValuesRef.current = defaultValues;
  }, [defaultValues]);

  const contextValue: IParametersFormContext = useMemo(
    () => ({
      schema,
      schemaConfig,
      parameters,
      formMode,
      errors,
      disabled,
      changeParametersValue,
      registerDefaultHandler: registerDefaultHandler.current,
      removeDefaultHandler: removeDefaultHandler.current,
      setAfterSubmitHandler,
    }),
    [
      schema,
      schemaConfig,
      parameters,
      errors,
      disabled,
      changeParametersValue,
      setAfterSubmitHandler,
      formMode,
    ]
  );

  return (
    <ParametersFormContext.Provider value={contextValue}>{children}</ParametersFormContext.Provider>
  );
};

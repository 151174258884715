import * as React from 'react';
import {WorkflowDTO} from '../../../../../../objects/dto/workflow.dto';
import classes from './workflow-type-selection-tab.module.scss';
import {WorkflowType} from '../../../../../../objects/models/workflow.model';
import {FormStep} from '../../../../../shared/components/layout/form-step/form-step.component';
import {useRemoteSourceStated} from 'ui-components';
import {getAnalysisTypesCatalogNetworkRequest} from '../../../../../../http/analysis-types.network-requests';
import {GenericLoading} from '../../../../../shared/components/general/generic-loading/generic-loading.component';
import {useEffect} from 'react';
import {AnalysisType} from '../../../../../../objects/models/analysis-type.model';
import {AnalysisTypeCard} from '../../../../../analyses/components/analysis-type-card/analysis-type-card.component';
import {AnalysisTypeId} from '../../../../../../constants/analysis-type-id';

interface OwnProps {
  onSubmit: (data: Partial<WorkflowDTO>) => void;
  data: Partial<WorkflowDTO>;
}

type AllProps = OwnProps;

const WORKFLOWS_ANALYSIS_TYPE_ID = [
  AnalysisTypeId.REACH_KPI_WORKFLOW,
  AnalysisTypeId.CUSTOM_SQL_WORKFLOW,
];
const ANALYSIS_TYPE_TO_WORKFLOW_TYPE: Record<number, WorkflowType> = {
  [AnalysisTypeId.REACH_KPI_WORKFLOW]: WorkflowType.KPI_REACH,
  [AnalysisTypeId.CUSTOM_SQL_WORKFLOW]: WorkflowType.CUSTOM_SQL,
};

export const WorkflowTypeSelectionTab: React.FC<AllProps> = (props: AllProps) => {
  const {onSubmit} = props;
  const {
    source: analysisTypes,
    exec: getAnalysisTypes,
    isLoading,
  } = useRemoteSourceStated({
    type: 'source',
    initialValue: [],
    networkRequest: getAnalysisTypesCatalogNetworkRequest,
  });

  useEffect(() => {
    const filters = {
      orderBy: 'displayOrder',
      order: 'asc',
      id: WORKFLOWS_ANALYSIS_TYPE_ID,
      limit: 1000,
    };
    getAnalysisTypes(filters);
  }, [getAnalysisTypes]);

  return (
    <FormStep>
      <div className={classes.WorkflowTypeSelectionTab}>
        {isLoading && <GenericLoading />}
        {analysisTypes.map((analysisType: AnalysisType) => (
          <AnalysisTypeCard
            key={analysisType.id}
            analysisType={analysisType}
            onClick={() => onSubmit({type: ANALYSIS_TYPE_TO_WORKFLOW_TYPE[analysisType.id]})}
          />
        ))}
      </div>
    </FormStep>
  );
};

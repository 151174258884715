import appConfig from './app.config';

export const initUsersnap = (userId: number, productName: string) => {
  if (!appConfig.userSnapApiKey) {
    return;
  }

  // @ts-ignore
  window.onUsersnapCXLoad = function (api) {
    // For some reason user.userId does not appear in the usersnap
    // UI. I've opened a ticket at their support.
    // For now we'll send the userId in the custom object
    api.init({
      custom: {
        productName,
        userId,
      },
      user: {
        userId: String(userId),
      },
    });
  };
  const script = document.createElement('script');
  // @ts-ignore
  script.defer = 1;
  script.src = `https://widget.usersnap.com/global/load/${appConfig.userSnapApiKey}?onload=onUsersnapCXLoad`;
  document.getElementsByTagName('head')[0].appendChild(script);
};

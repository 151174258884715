export enum CronUnits {
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  DAY_OF_THE_MONTH = 'DAY_OF_THE_MONTH',
  MONTH = 'MONTH',
  DAY_OF_THE_WEEK = 'DAY_OF_THE_WEEK',
}

export enum CronUnitsPositions {
  MINUTES = 0,
  HOURS = 1,
  DAY_OF_THE_MONTH = 2,
  MONTH = 3,
  DAY_OF_THE_WEEK = 4,
}

export const CRON_SCHEDULE_KEYS = {
  DAILY: 'daily',
  MONTHLY: 'monthly',
  WEEKLY: 'weekly',
};

export const CRON_DEFAULT_VALUES = {
  WEEKLY: '0 0 * * 1',
  MONTHLY: '0 0 1 * *',
  DAILY: '0 0 * * *',
};

import * as React from 'react';
import {Route, Switch} from 'react-router';
import {AppRoutes} from '../../constants/app-routes';
import ViewAnalysis from './pages/view-analysis/view-analysis.component';
import ViewPhenomena from './pages/view-phenomena/view-phenomena.component';
import {AnalysesMain} from './pages/analyses-main/analyses-main.component';

export const AnalysesRouting: React.FC = props => {
  return (
    <Switch>
      <Route path={AppRoutes.viewAnalysis().pathname} component={ViewAnalysis} exact />
      <Route path={AppRoutes.viewPhenomena().pathname} component={ViewPhenomena} exact />
      <Route path={AppRoutes.analyses().pathname} component={AnalysesMain} />
    </Switch>
  );
};

import * as React from 'react';
import classNames from 'classnames';
import classes from '../../analysis-forms.module.scss';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {ExtendedParameters} from '../../../../shared/form/form-layout/extended-parameters/extended-parameters.component';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../../constants/translation-keys';
import {TimeFrameSelector} from '../../components/ui-selectors/time-frame-selector/time-frame-selector.component';
import {SegmentFilterSelector} from '../../components/ui-selectors/segment-filter-selector/segment-filter-selector.component';
import {ParametersFormContext} from '../../../../shared/core/parameters-form/parameters-form.context';
import {startEndDatesDefaultHandler} from '../../../../shared/core/parameters-form/parameters-form-default-handlers.utils';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';
import {createUndefinedObject, hasError} from '../../../../../utils/general.utils';
import {get, values} from 'lodash';
import {TableEntity, TableEntityBinding} from '../../../../../objects/models/table.model';
import {EntitySelector} from '../../components/ui-selectors/entity-selector/entity-selector.component';
import {AnalysisFormProps} from '../../analysis-forms.types';
import {SqlElementType} from 'ui-components';
import {SignalType} from '../../../../../objects/models/signal.model';
import {TreatmentSelector} from '../../components/ui-selectors/treatment-selector/treatment-selector.component';
import {exists} from 'front-core';
import {
  METADATA_KEY,
  PARAMETERS_METADATA_KEY,
} from '../../../../../constants/parameters-saved-keys';
import GoalQueryStrategySelector, {
  createInitialGoalQueryByStrategy,
  GoalStrategyPickerSchemaKeysMapping,
  UIGoalType,
} from '../../components/ui-selectors/goal-query-strategy-selector/goal-query-strategy-selector.component';
import {GroupBySelector} from '../../components/ui-selectors/group-by-selector/group-by-selector.component';

export const QUERY_GOAL_KEY = 'goal_query';

const treatmentsSelectorMap = {
  treatments_tag: 'treatments_tag',
  treatments_signals: 'treatments_signals',
  days_count_key: 'days_from_reference',
};
const entitySchemaMapping = {
  entity: 'entity',
};
const segmentFilterSchemaMapping = {
  population_filter: 'population_filter',
};
const GROUP_BY_SIGNAL_MAPPING = {
  signal_id: 'group_by',
};

const createGroupBySignalFilters = (entityContext = undefined) => ({
  type: SignalType.DIMENSION,
  entity_binding: TableEntityBinding.ONE_WAY,
  entityContext,
});

const goalQueryStrategySchemaMapping: GoalStrategyPickerSchemaKeysMapping = {
  goal_query: 'goal_query',
  ui_goal_type: 'strategy',
};

const ENABLED_STRATEGIES = [UIGoalType.CONVERSION, UIGoalType.RETENTION];

export const Analysis102Form: React.FC<AnalysisFormProps> = (props: AnalysisFormProps) => {
  const {onSignalInfo, className} = props;
  const [isOpenAdvancedParams, setIsOpenAdvancedParams] = useState(false);
  const {t} = useTranslation();
  const {errors, parameters, changeParametersValue, registerDefaultHandler, removeDefaultHandler} =
    useContext(ParametersFormContext);
  const {getSignalByTag, productEntities, defaultTableEntity} = useProductData();
  const entityContext = parameters[entitySchemaMapping.entity];
  const goalType = useMemo(
    () => parameters[goalQueryStrategySchemaMapping.ui_goal_type],
    [parameters]
  );
  const groupBySignalFilters = useMemo(
    () => createGroupBySignalFilters(entityContext),
    [entityContext]
  );

  useEffect(() => {
    registerDefaultHandler('start_end_dates', startEndDatesDefaultHandler);
    registerDefaultHandler('analysis_102', parameters => {
      const defaults = {};
      defaults[entitySchemaMapping.entity] = defaultTableEntity;
      defaults[treatmentsSelectorMap.days_count_key] = 14;
      defaults[goalQueryStrategySchemaMapping.ui_goal_type] = UIGoalType.CONVERSION;
      defaults[goalQueryStrategySchemaMapping.goal_query] = createInitialGoalQueryByStrategy(
        goalType,
        getSignalByTag,
        entityContext
      );
      return defaults;
    });

    return () => {
      removeDefaultHandler('start_end_dates');
      removeDefaultHandler('analysis_102');
    };
  }, [
    registerDefaultHandler,
    removeDefaultHandler,
    goalType,
    entityContext,
    defaultTableEntity,
    getSignalByTag,
  ]);
  // duplicated (80)
  const treatmentSelectorSince = useMemo(() => {
    const refDate = get(parameters, `${QUERY_GOAL_KEY}.parameters.4`);
    if (!exists(refDate)) {
      return;
    }
    if (refDate.type === SqlElementType.TABLE_COLUMN) {
      return refDate.column;
    }
    if (
      get(refDate, `${PARAMETERS_METADATA_KEY}.${METADATA_KEY.BUILDER_COMPONENT_NAME_KEY}`) ===
      'TemplateItemQueryBuilder'
    ) {
      return get(refDate, `${PARAMETERS_METADATA_KEY}.${METADATA_KEY.DISPLAY_NAME_KEY}`);
    }
    if (refDate.type === SqlElementType.SIGNAL_COLUMN) {
      return refDate.signal_id;
    }
  }, [parameters]);
  const onChangeEntityContext = useCallback(
    (entity: TableEntity) => {
      const resetKeys = Array.from(
        new Set([QUERY_GOAL_KEY, ...values(segmentFilterSchemaMapping)])
      );
      const resetParameters = createUndefinedObject(resetKeys);
      changeParametersValue({
        [entitySchemaMapping.entity]: entity,
        ...resetParameters,
      });
    },
    [changeParametersValue]
  );
  const isOpenAdvancedParamsOrError = useMemo(() => {
    return (
      isOpenAdvancedParams ||
      hasError(errors, [...values(treatmentsSelectorMap), ...values(GROUP_BY_SIGNAL_MAPPING)])
    );
  }, [isOpenAdvancedParams, errors]);

  return (
    <div className={classNames(classes.AnalysisForm, className)}>
      <EntitySelector
        value={parameters}
        productEntities={productEntities}
        schemaKeysMapping={entitySchemaMapping}
        onChange={v => onChangeEntityContext(v[entitySchemaMapping.entity])}
        className={classes.Parameter}
      />
      <GoalQueryStrategySelector
        schemaKeysMapping={goalQueryStrategySchemaMapping}
        title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_102.GOAL.TITLE)}
        subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_102.GOAL.SUB_TITLE)}
        helperText={TransKeys.ANALYSIS_FORMS.ANALYSIS_102.GOAL.HELPER_TEXT}
        errors={errors}
        value={parameters}
        onChange={changeParametersValue}
        onSignalInfo={onSignalInfo}
        entityContext={entityContext}
        enabledStrategies={ENABLED_STRATEGIES}
      />
      <TreatmentSelector
        value={parameters}
        onChange={changeParametersValue}
        className={classes.Parameter}
        schemaKeysMapping={treatmentsSelectorMap}
        errors={errors}
        since={treatmentSelectorSince}
        entityContext={entityContext}
      />
      <TimeFrameSelector className={classes.Parameter} errors={errors} />
      {entityContext && (
        <SegmentFilterSelector
          onChange={changeParametersValue}
          value={parameters}
          className={classes.Parameter}
          entityContext={entityContext}
          errors={errors}
        />
      )}
      <ExtendedParameters
        className={classes.SpaceBottom}
        label={t(TransKeys.GENERAL.LABELS.ADVANCED_PARAMETERS)}
        isOpen={isOpenAdvancedParamsOrError}
        onOpenChanged={() => setIsOpenAdvancedParams(!isOpenAdvancedParams)}
      >
        <GroupBySelector
          value={parameters}
          onChange={changeParametersValue}
          onSignalInfo={onSignalInfo}
          schemaKeysMapping={GROUP_BY_SIGNAL_MAPPING}
          filters={groupBySignalFilters}
          errors={errors}
        />
      </ExtendedParameters>
    </div>
  );
};

import * as React from 'react';
import {WorkflowDTO, workflowOutputsValidator} from '../../../../../../objects/dto/workflow.dto';
import classes from './workflow-outputs-tab.module.scss';
import {FormStep} from '../../../../../shared/components/layout/form-step/form-step.component';
import {Button, Checkbox} from 'ui-components';
import TransKeys from '../../../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  EnumFormInput,
  MultiSelectInput,
  TextFormInput,
} from '../../../../../shared/form/components/shared-form-input.component';
import {WorkflowOutputOption} from './components/workflow-output-option/workflow-output-option.component';
import {exists} from 'front-core';
import {useMemo} from 'react';
import {searchUsersNetworkRequest} from '../../../../../../http/users.network-requests';
import {SlackChannel} from '../../../../../analyses/panels/share-resource-form-panel/components/share-direct-slack-form-fields/share-direct-slack-form-fields.component';
import classNames from 'classnames';
import {useProductData} from '../../../../../../core/hooks/use-product-data.hook';

interface OwnProps {
  data: Partial<WorkflowDTO>;
  onSubmit: (data: Partial<WorkflowDTO>) => void;
  slackChannels: SlackChannel[];
  disabled?: boolean;
  onBack?: () => void;
}

type AllProps = OwnProps;

const parseSlackChannelOption = (m: SlackChannel) => ({
  label: m.type === 'channel' ? `# ${m.name}` : m.name,
  value: m.id,
});
const STATE_OPTIONS = [
  {label: 'Daily', value: 'daily'},
  {label: 'Last', value: 'last'},
];

export const WorkflowOutputsTab: React.FC<AllProps> = (props: AllProps) => {
  const {data, slackChannels, disabled, onSubmit, onBack} = props;
  const {t} = useTranslation();
  const {defaultSource} = useProductData();

  const formMethods = useForm({
    defaultValues: {
      emailOutput: null,
      slackOutput: null,
      databaseOutput: null,
      ...data,
    } as WorkflowDTO,
    resolver: yupResolver(workflowOutputsValidator.noUnknown()),
  });

  const {
    handleSubmit,
    watch,
    setValue,
    formState: {errors},
    control,
  } = formMethods;
  const userOptions = useMemo(
    () => ({
      networkRequest: searchUsersNetworkRequest,
      labelAttributePath: 'fullName',
      valueAttributePath: 'id',
    }),
    []
  );
  const channelsOptionsProp = useMemo(() => {
    if (!slackChannels || slackChannels.length === 0) {
      return null;
    }
    return {
      options: slackChannels.map(parseSlackChannelOption),
    };
  }, [slackChannels]);

  const emailOutput = watch('emailOutput');
  const slackOutput = watch('slackOutput');
  const databaseOutput = watch('databaseOutput');

  return (
    <FormStep
      footer={
        <>
          <Button variant={'outlined'} type={'button'} onClick={onBack}>
            {t(TransKeys.GENERAL.ACTIONS.BACK)}
          </Button>
          <Button onClick={handleSubmit(onSubmit)} disabled={disabled}>
            {t(TransKeys.GENERAL.ACTIONS.SAVE)}
          </Button>
        </>
      }
    >
      <FormProvider {...formMethods}>
        <div className={classes.WorkflowOutputsTab}>
          {(errors as any).root && (
            <div className={classes.Error}>{(errors as any).root.message}</div>
          )}
          <WorkflowOutputOption
            title={t(TransKeys.WORKFLOWS.OUTPUT_TYPE.EMAIL.LABEL)}
            isActive={exists(emailOutput)}
            onChange={v => setValue('emailOutput', v ? ({} as any) : null)}
            error={Boolean(errors.emailOutput)}
            className={classes.Option}
          >
            <MultiSelectInput
              label={t(TransKeys.WORKFLOW_FORM_PANEL.INPUTS.EMAIL_USER_ID.TITLE)}
              name={'emailOutput.user_ids'}
              options={userOptions}
              placeholder={t(TransKeys.SHARE_RESOURCE_FORM.INPUTS.RECIPIENTS.PLACEHOLDER)}
              className={classes.Input}
            />
            <TextFormInput
              name={'emailOutput.emails'}
              label={t(TransKeys.WORKFLOW_FORM_PANEL.INPUTS.EMAILS.TITLE)}
              placeholder={'Enter emails'}
              className={classes.Input}
              multiple
            />
            <Controller
              render={({field}) => (
                <Checkbox
                  checked={field.value}
                  onChange={field.onChange}
                  label={t(TransKeys.WORKFLOW_FORM_PANEL.INPUTS.SEND_WHEN_EMPTY)}
                />
              )}
              name={'emailOutput.send_when_empty'}
              control={control}
            />
          </WorkflowOutputOption>
          <WorkflowOutputOption
            title={t(TransKeys.WORKFLOWS.OUTPUT_TYPE.SLACK.LABEL)}
            isActive={exists(slackOutput)}
            onChange={v => setValue('slackOutput', v ? ({} as any) : null)}
            error={Boolean(errors.slackOutput)}
            className={classes.Option}
          >
            <MultiSelectInput
              options={channelsOptionsProp}
              label={''}
              name={'slackOutput.channels'}
              placeholder={'Add Slack channel or member'}
              className={classes.Input}
            />
            <Controller
              render={({field}) => (
                <Checkbox
                  checked={field.value}
                  onChange={field.onChange}
                  label={t(TransKeys.WORKFLOW_FORM_PANEL.INPUTS.SEND_WHEN_EMPTY)}
                />
              )}
              name={'slackOutput.send_when_empty'}
              control={control}
            />
          </WorkflowOutputOption>
          <WorkflowOutputOption
            title={t(TransKeys.WORKFLOWS.OUTPUT_TYPE.DATABASE.LABEL)}
            isActive={exists(databaseOutput)}
            disabled={!defaultSource.hasWriteAccess}
            onChange={v => setValue('databaseOutput', v ? ({state: 'daily'} as any) : null)}
            error={Boolean(errors.databaseOutput)}
            className={classes.Option}
          >
            <TextFormInput
              name={'databaseOutput.name'}
              label={t(TransKeys.WORKFLOW_FORM_PANEL.INPUTS.TABLE_NAME.TITLE)}
              placeholder={t(TransKeys.WORKFLOW_FORM_PANEL.INPUTS.TABLE_NAME.PLACEHOLDER)}
              className={classNames(classes.Input, classes.TableInput)}
            />
            <EnumFormInput
              options={{
                options: STATE_OPTIONS,
              }}
              label={t(TransKeys.WORKFLOW_FORM_PANEL.INPUTS.STATE.TITLE)}
              name={'databaseOutput.state'}
              placeholder={t(TransKeys.WORKFLOW_FORM_PANEL.INPUTS.STATE.PLACEHOLDER)}
              className={classes.StateInput}
              clearable={false}
            />
          </WorkflowOutputOption>
        </div>
      </FormProvider>
    </FormStep>
  );
};

import {values} from 'lodash';
import yup from '../../config/yup.config';
import {ShareResourceModel, ShareResourceType, ShareStrategyTypes} from '../models/share.model';

export interface ShareDTO extends ShareResourceModel {}

const shared = {
  modelId: yup.number().required(),
  type: yup.string().oneOf(values(ShareResourceType)).required(),
  strategyType: yup.string().oneOf(values(ShareStrategyTypes)).required(),
  message: yup.string().nullable(),
};

const then = {
  is: val => val && Array.isArray(val) && val.length === 0,
  then: schema => schema.min(1),
  otherwise: schema => schema.nullable(),
};

export const defaultShareDTOValidator = yup.object().shape(
  {
    ...shared,
    recipients: yup.array().of(yup.number()).when('invitees', then),
    invitees: yup.array().of(yup.string().email()).when('recipients', then),
    // Handles cyclic dependency https://github.com/jquense/yup/issues/193
  },
  [
    ['recipients', 'invitees'],
    ['invitees', 'recipients'],
  ]
);

export const directSlackShareDTOValidator = yup.object().shape(
  {
    ...shared,
    channels: yup.array().of(yup.string()).when('invitees', then),
    invitees: yup.array().of(yup.string()).when('channels', then),
    // Handles cyclic dependency https://github.com/jquense/yup/issues/193
  },
  [
    ['channels', 'invitees'],
    ['invitees', 'channels'],
  ]
);

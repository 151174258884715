import {Epic, ofType} from 'redux-observable';
import {changeLoading, empty, removeError, saveError} from 'front-core';
import {
  __remote_list_request_completed,
  _REMOTE_LIST_SWAP_TYPES,
  ListStrategy,
  RemoteListActionType,
} from './remote-list.actions';
import HttpClient from '../../services/http-client.service';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {concat, from, of} from 'rxjs';
import RemoteListsStoreService from './remote-lists.service';
import {errorExtractor, getter} from '../store.utils';
import {REMOTE_LISTS_STORE_KEY} from './remote-lists.store';

const mergeQuery = (newQuery: any, query: any, mergeStrategy: ListStrategy = 'replace') => {
  if (mergeStrategy === 'replace') {
    return newQuery;
  }
  return {
    ...query,
    ...newQuery,
  };
};

const handleRemoteListCall = ({action, config, query}) =>
  config === undefined
    ? concat(of(empty()))
    : concat(
        of(changeLoading(config.actionKey, true)),
        of(removeError(config.actionKey)),
        from(
          HttpClient.exec(
            config.request(
              mergeQuery(action.payload.query, query, action.payload.mergeQueryStrategy)
            )
          )
        ).pipe(
          mergeMap((res: any) => {
            let {listKey, query: newQuery, mergeQueryStrategy} = action.payload;
            let strategy;
            switch (action.type) {
              case RemoteListActionType.APPEND_LIST:
                strategy = 'append';
                break;
              case RemoteListActionType.REPLACE_LIST:
              default:
                strategy = 'replace';
                break;
            }
            return of(
              __remote_list_request_completed({
                listKey: listKey,
                data: res.data,
                meta: res.meta,
                query: mergeQuery({...newQuery, ...(res.query || {})}, query, mergeQueryStrategy),
                strategy,
              })
            );
          }),
          catchError((err: any) => {
            const actions = [of(saveError(config.actionKey, errorExtractor(err)))];
            if (config.onError) {
              actions.push(...config.onError(err, action.payload, action).map(a => of(a)));
            }
            return concat(...actions);
          })
        ),
        of(changeLoading(config.actionKey, false))
      );

const mapAction = action => ({
  action,
  config: RemoteListsStoreService.getListConfig(action.payload.listKey),
  query: getter(REMOTE_LISTS_STORE_KEY, `lists.${action.payload.listKey}.query`),
});

// using mergeMap (calls will NOT be replaced when called multiple times)
export const remoteListRootEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(RemoteListActionType.APPEND_LIST, RemoteListActionType.REPLACE_LIST),
    map(mapAction),
    mergeMap(handleRemoteListCall)
  );

// using switchMap (calls will be replaced when called multiple times)
export const remoteListSwitchedRootEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(
      RemoteListActionType.__APPEND_LIST_SWITCHED,
      RemoteListActionType.__REPLACE_LIST_SWITCHED
    ),
    map(mapAction),
    switchMap(({action, config, query}) =>
      handleRemoteListCall({
        action: {...action, type: _REMOTE_LIST_SWAP_TYPES[action.type]},
        config,
        query,
      })
    )
  );

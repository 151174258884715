import * as React from 'react';
import classes from '../../../analysis-forms.module.scss';
import {ParameterInputWrapper} from '../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {hasError} from '../../../../../../utils/general.utils';
import {values} from 'lodash';
import {BaseSignalBuilder} from '../../../../../shared/core/query-builders/signal-builder/base-signal-builder.component';
import {useTranslation} from 'react-i18next';
import {useMemo} from 'react';
import {SignalSmartSelectorKey} from '../../../../../shared/core/smart-selector/signal-smart-selector.component';
import TransKeys from 'translations';

interface SchemaKeysMapping {
  signal_id: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  signal_id: 'group_by',
};

interface OwnProps {
  value: any;
  onChange: (parameters: any) => void;
  schemaKeysMapping?: SchemaKeysMapping;
  onSignalInfo?: (value: string | number) => void;
  title?: string;
  subTitle?: string;
  bottomHelper?: any;
  helperText?: any;
  errors?: any;
  filters?: any;
  className?: string;
}

type AllProps = OwnProps;

const INCLUDE_SELECTOR = [
  SignalSmartSelectorKey.METRICS,
  SignalSmartSelectorKey.FUNNELS,
  SignalSmartSelectorKey.SEGMENTS,
  SignalSmartSelectorKey.EVENT_GROUPS,
  SignalSmartSelectorKey.FEATURES,
  SignalSmartSelectorKey.CONTENTS,
  SignalSmartSelectorKey.SIGNALS,
];

export const GroupBySelector: React.FC<AllProps> = (props: AllProps) => {
  const {
    title,
    subTitle,
    helperText,
    errors,
    value,
    filters,
    onChange,
    onSignalInfo,
    schemaKeysMapping,
  } = props;
  const {t} = useTranslation();

  const error = useMemo(
    () => hasError(errors, values(schemaKeysMapping)),
    [errors, schemaKeysMapping]
  );

  return (
    <ParameterInputWrapper
      title={title || t(TransKeys.ANALYSIS_FORMS.ANALYSIS_102.GROUP_BY.TITLE)}
      subTitle={subTitle || t(TransKeys.ANALYSIS_FORMS.ANALYSIS_102.GROUP_BY.SUB_TITLE)}
      helperText={helperText || t(TransKeys.ANALYSIS_FORMS.ANALYSIS_102.GROUP_BY.HELPER_TEXT)}
      className={classes.Parameter}
      error={error}
    >
      <BaseSignalBuilder
        value={value}
        onChange={onChange}
        onSignalInfo={onSignalInfo}
        schemaKeysMapping={schemaKeysMapping}
        filters={filters}
        errors={errors}
        include={INCLUDE_SELECTOR}
        clearable
      />
    </ParameterInputWrapper>
  );
};

GroupBySelector.defaultProps = {
  schemaKeysMapping: DEFAULT_SCHEMA_KEYS_MAPPING,
};

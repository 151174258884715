import * as React from 'react';
import {Route, Switch} from 'react-router';
import {AppRoutes} from '../../constants/app-routes';
import ExperimentsMain from './pages/experiments-main/experiments-main.component';
import ViewExperiment from './pages/view-experiment/view-experiment.component';

export const ExperimentRouting: React.FC = props => {
  return (
    <Switch>
      <Route path={AppRoutes.experiments().pathname} component={ExperimentsMain} exact />
      <Route path={AppRoutes.viewExperiment().pathname} component={ViewExperiment} exact />
    </Switch>
  );
};

import {NRC} from './index';
import {RequestType, toCamelCase, toSnakeCase} from 'front-core';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';
import {MilestoneDTO} from '../objects/dto/milestone.dto';

export const getMilestonesNetworkRequest: NRC<void, any> = (payload: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/milestones`,
  query: payload,
  queryTransformer: toFiltersDTO,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const getMilestoneNetworkRequest: NRC<void, any> = (milestoneId: number, query: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/milestones/${milestoneId}`,
  query: query,
  queryTransformer: toSnakeCase,
  bodyTransformer: data => toSnakeCase(data, ['signal_definition']),
  responseTransformer: res => toCamelCase(res.data, ['signal_definition']),
});

export const getMilestonesStatusNetworkRequest: NRC<void, any> = () => ({
  method: RequestType.GET,
  relativeUrl: `/app/milestones/status`,
  responseTransformer: res => res.data,
});

export const createMilestoneNetworkRequest: NRC<MilestoneDTO, void> = (data: MilestoneDTO) => ({
  method: RequestType.POST,
  relativeUrl: `/app/milestones`,
  body: data,
  bodyTransformer: data => toSnakeCase(data, ['signal_definition']),
  responseTransformer: res => toCamelCase(res.data, ['signal_definition']),
});

export const editMilestoneNetworkRequest: NRC<MilestoneDTO, void> = (data: MilestoneDTO) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/milestones/${data.id}`,
  body: data,
  bodyTransformer: data => toSnakeCase(data, ['signal_definition']),
  responseTransformer: res => toCamelCase(res.data, ['signal_definition']),
});

export const deleteMilestoneNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.DELETE,
  relativeUrl: `/app/milestones/${id}`,
  responseTransformer: toCamelCase,
});

export const validateMilestoneNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/milestones/${id}/validate`,
  responseTransformer: res => toCamelCase(res.data, ['signal_definition']),
});

import {ModelKey} from '../../constants/model-key';
import {values} from 'lodash';
import {SelectedConfig} from './selected.types';

const DEFAULT_CONFIG: Partial<SelectedConfig> = {
  primaryKey: 'id',
};

class SelectedStoreService {
  private selectedMap: {[selectedKey: string]: SelectedConfig} = {};

  addList(selectedKey: string, config: SelectedConfig) {
    // if (selectedKey in this.selectedMap) {
    //   throw new Error(`${selectedKey} already exists`);
    // }
    this.selectedMap[selectedKey] = {
      ...DEFAULT_CONFIG,
      ...config,
    };
  }

  removeList(selectedKey: string) {
    delete this.selectedMap[selectedKey];
  }

  getListConfig(selectedKey: string): SelectedConfig {
    if (!this.selectedMap[selectedKey]) {
      throw new Error(`${selectedKey} not exists`);
    }
    return this.selectedMap[selectedKey];
  }

  getConfigsByModelKey(modelKey: ModelKey): SelectedConfig[] {
    return values(this.selectedMap).filter(l => l.modelKey === modelKey);
  }
}

export default new SelectedStoreService();

import * as React from 'react';
import classNames from 'classnames';
import classes from './funnel-tabs.module.scss';
import {useMemo, useCallback} from 'react';
import {
  AddIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  ChevronDownIcon,
  CloseIcon,
  HoverHelperTip,
  IconButton,
} from 'ui-components';
import {Tooltip} from '@material-ui/core';
import InvalidSignalWarning from '../../../../dmp/components/invalid-signal-warning/invalid-signal-warning.component';

export interface FunnelTab {
  key: string;
  title: string;
  subTitle?: string;
  helperText?: string;
  warningText?: string;
  error?: boolean;
  disabled?: boolean;
  render: () => JSX.Element;
}

interface OwnProps {
  className?: string;
  tabs: FunnelTab[];
  selectedKey: string;
  lockFirstTab?: boolean;
  onTabChange: (key: string) => void;
  onAdd?: () => void;
  onSwap?: (idx: number, newIndex: number) => void;
  onDelete?: (idx: number) => void;
}

type AllProps = OwnProps;
const TAB_MIN_WIDTH_PERCENT = 0.85;

const calcMaxWidth = (idx: number, len: number) =>
  (TAB_MIN_WIDTH_PERCENT + (idx / (len - 1)) * (1 - TAB_MIN_WIDTH_PERCENT)) * 100 + '%';

export const FunnelTabs: React.FC<AllProps> = (props: AllProps) => {
  const {tabs, selectedKey, lockFirstTab, onTabChange, onAdd, onSwap, onDelete, className} = props;

  const tabsStyle = useMemo(
    () => tabs.map((t, idx) => ({maxWidth: calcMaxWidth(idx, tabs.length)})).reverse(),
    [tabs]
  );

  const allowMoveUp = useCallback(
    (idx: number) => onSwap && (lockFirstTab ? idx > 1 : idx > 0),
    [onSwap, lockFirstTab]
  );
  const allowMoveDown = useCallback(
    (idx: number) => onSwap && (lockFirstTab ? idx > 0 : true) && idx < tabs.length - 1,
    [onSwap, lockFirstTab, tabs.length]
  );
  const allowRemove = useCallback(
    (idx: number) => onDelete && (lockFirstTab ? idx > 0 : true) && tabs.length > 1,
    [onDelete, lockFirstTab, tabs.length]
  );
  const showActions = useCallback(
    (idx: number) => allowMoveUp(idx) || allowMoveDown(idx) || allowRemove(idx),
    [allowMoveUp, allowMoveDown, allowRemove]
  );
  const renderTab = useMemo(() => tabs.find(t => t.key === selectedKey), [selectedKey, tabs]);

  return (
    <div className={classNames(classes.FunnelTabs, className)}>
      <div className={classes.Tabs}>
        {tabs.map((tab, idx) => (
          <React.Fragment key={tab.key}>
            <Tooltip
              title={
                showActions(idx) ? (
                  <div className={classes.Actions}>
                    {allowMoveDown(idx) && (
                      <IconButton
                        size={'small'}
                        className={classes.Action}
                        icon={ArrowDownIcon}
                        onClick={() => onSwap(idx, idx + 1)}
                      />
                    )}
                    {allowMoveUp(idx) && (
                      <IconButton
                        size={'small'}
                        className={classes.Action}
                        icon={ArrowUpIcon}
                        onClick={() => onSwap(idx, idx - 1)}
                      />
                    )}
                    {allowRemove(idx) && (
                      <IconButton
                        size={'small'}
                        className={classes.Action}
                        icon={CloseIcon}
                        onClick={() => onDelete(idx)}
                      />
                    )}
                  </div>
                ) : (
                  ''
                )
              }
              placement={'right'}
              interactive
            >
              <div
                style={tabsStyle[idx]}
                onClick={_ => !tab.disabled && onTabChange(tab.key)}
                className={classNames(
                  classes.Tab,
                  tab.error && classes.Error,
                  tab.key === selectedKey && classes.Selected,
                  tab.disabled && classes.Disabled
                )}
              >
                <div className={classes.Title}>
                  {tab.title}
                  {tab.helperText && (
                    <HoverHelperTip className={classes.HelperText} title={tab.helperText} />
                  )}
                  {tab.warningText && <InvalidSignalWarning title={tab.warningText} />}
                </div>
                {tab.subTitle && <div className={classes.SubTitle}>{tab.subTitle}</div>}
              </div>
            </Tooltip>
            <div className={classes.ArrowDown}>
              <ChevronDownIcon className={classes.Icon} />
            </div>
          </React.Fragment>
        ))}
        {onAdd && <IconButton icon={AddIcon} onClick={onAdd} tooltipText={'Add a step'} />}
      </div>
      <div key={renderTab.key} className={classes.Content}>
        {renderTab.render()}
      </div>
    </div>
  );
};

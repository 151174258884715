import {MetricSampleWoWOverview} from '../../../../../../../../../insights/model-sample/objects';
import {useMemo} from 'react';
import {MetricValueType} from '../../../../../../../../../../objects/models/metric.model';
import {
  formatPercentageValue,
  formatPercentageValueWithPrefix,
} from '../../../../../../../../../insights/insights.utils';
import {number2k} from 'front-core';
import {
  SentenceContainer,
  SentencePart,
} from '../../../../../../../../../insights/components/shared-sentence-parts/shared-sentence-parts.components';
import sharedClasses from '../../rca-executive-summery.module.scss';
import {useTranslation} from 'react-i18next';
import classes from '../../rca-executive-summery.module.scss';
import * as React from 'react';
import TransKeys from 'translations';
import {HoverHelperTip} from 'ui-components';
import {ChangeDescriptionAlias} from '../rca-executive-summary-orchestrator/rca-executive-summary-orchestrator.component';
import classNames from 'classnames';

enum ChangeFromPredictedDescription {
  BELLOW = 'below',
  ABOVE = 'above',
}

interface OwnProps extends MetricSampleWoWOverview {
  changeDescriptionAlias: ChangeDescriptionAlias;
  className?: string;
}

type AllProps = OwnProps;

export const MetricSampleWoWOverviewSection: React.FC<AllProps> = (props: AllProps) => {
  const {
    metricName,
    metricValueType,
    higherIsBetter,
    lastSampleValue: lastSampleValueFromProps,
    wowChangeValue,
    lastValueAndLastExpectedValueByLoopsChange,
    wowChangeDescription,
    changeDescriptionAlias,
    isSignificantLoopsAlgo,
    isSignificantWoW,
    className,
  } = props;
  const {t} = useTranslation();

  const lastSampleValue = useMemo(
    () =>
      metricValueType === MetricValueType.PERCENTAGE
        ? formatPercentageValue(lastSampleValueFromProps)
        : number2k(lastSampleValueFromProps),
    [lastSampleValueFromProps, metricValueType]
  );
  const wowChangeValueStyleProps = useMemo(() => {
    if (!isSignificantWoW) {
      return {};
    }
    if (wowChangeValue > 0) {
      return higherIsBetter ? {positive: true} : {negative: true};
    } else if (wowChangeValue < 0) {
      return higherIsBetter ? {negative: true} : {positive: true};
    } else {
      return {};
    }
  }, [wowChangeValue, higherIsBetter, isSignificantWoW]);
  const changeFromPredictedData = useMemo(() => {
    let styleProps = {};
    let changeFromPredicted = '';

    if (lastValueAndLastExpectedValueByLoopsChange > 0) {
      styleProps = higherIsBetter ? {positive: true} : {negative: true};
      changeFromPredicted = ChangeFromPredictedDescription.ABOVE;
    } else if (lastValueAndLastExpectedValueByLoopsChange < 0) {
      styleProps = higherIsBetter ? {negative: true} : {positive: true};
      changeFromPredicted = ChangeFromPredictedDescription.BELLOW;
    }
    return {
      styleProps,
      changeInReferenceToExpectedValueDescription: changeFromPredicted,
      formattedValue: formatPercentageValueWithPrefix(lastValueAndLastExpectedValueByLoopsChange),
    };
  }, [lastValueAndLastExpectedValueByLoopsChange, higherIsBetter]);

  return (
    <div className={classNames(sharedClasses.ExecutiveSummarySection, className)}>
      <div className={classes.SubTitle}>
        {t(TransKeys.DOCUMENT_VIEWER.RCA_EXECUTIVE_SUMMARY.METRIC_OVERVIEW_TITLE)}
      </div>
      <SentenceContainer>
        <SentencePart bold text={metricName} />
        <SentencePart text="measured" />
        <SentencePart bold text={lastSampleValue} />
        <SentencePart text="in the analyzed period, reflecting" />
        <SentencePart
          {...wowChangeValueStyleProps}
          bold
          text={formatPercentageValueWithPrefix(wowChangeValue)}
        />
        <SentencePart text={`${wowChangeDescription} WoW.`} />
      </SentenceContainer>
      <SentenceContainer>
        <SentencePart text="In addition, the KPI was" />
        <SentencePart
          {...changeFromPredictedData.styleProps}
          bold
          text={changeFromPredictedData.formattedValue}
        />
        <SentencePart
          text={`${changeFromPredictedData.changeInReferenceToExpectedValueDescription} Loops prediction`}
        />
        <HoverHelperTip
          title={t(TransKeys.DOCUMENT_VIEWER.RCA_EXECUTIVE_SUMMARY.LOOPS_PREDICTION_TOOLTIP, {
            changeFromPredicted: changeFromPredictedData.formattedValue,
          })}
          className={sharedClasses.VerticalCenter}
        />
        <SentencePart text={`.`} />
        {isSignificantLoopsAlgo && (
          <>
            <SentencePart text={`This ${changeDescriptionAlias} is detected as an`} />
            <SentencePart bold text="anomaly" />
            <SentencePart text="by Loops." />
          </>
        )}
      </SentenceContainer>
    </div>
  );
};

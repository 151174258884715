import * as React from 'react';
import {useCallback, useMemo, useState} from 'react';
import classNames from 'classnames';
import classes from './days-count-selector.module.scss';
import {ParameterInputWrapper} from '../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {get, isNumber, values} from 'lodash';
import {HelperTipType, HoverHelperTip, LabelWrapper, Select, TextInput} from 'ui-components';
import {hasErrors, limitNumberValue} from '../../../../../../utils/general.utils';
import {exists} from 'front-core';
import {AnalysisSelectorProps, AnalysisSelectorVariant} from '../analysis-selector.types';

interface SchemaKeysMapping {
  days_count_key: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  days_count_key: 'days_count_key',
};

const CUSTOM_OPTION_VALUE = 'custom';

const OPTIONS = [
  {label: '7 days', value: 7},
  {label: '14 days', value: 14},
  {label: '28 days', value: 28},
  {label: 'Custom', value: CUSTOM_OPTION_VALUE},
];

interface OwnProps extends AnalysisSelectorProps {
  schemaKeysMapping?: SchemaKeysMapping;
  prefix?: string;
  suffix?: string;
  suffixHelper?: string;
  bottomHelper?: any;
  clearable?: boolean;
}

type AllProps = OwnProps;

const MIN_COUNT_VALUE = 1;
const MAX_COUNT_VALUE = 365;

const isCustomValue = (v: number) =>
  v !== undefined ? v === null || OPTIONS.find(o => o.value === v) === undefined : false;

export const DaysCountSelector: React.FC<AllProps> = (props: AllProps) => {
  const {
    title,
    subTitle,
    helperText,
    schemaKeysMapping,
    errors,
    value: parameters,
    onChange: onChange_,
    bottomHelper,
    prefix,
    suffix,
    suffixHelper,
    className,
    clearable,
    variant,
  } = props;
  const hasError = useMemo(
    () => hasErrors(errors, values(schemaKeysMapping)),
    [errors, schemaKeysMapping]
  );
  const errorMessage = useMemo(
    () => get(errors, schemaKeysMapping.days_count_key)?.message,
    [errors, schemaKeysMapping]
  );
  const onChange = useCallback(
    v =>
      onChange_({
        [schemaKeysMapping.days_count_key]:
          v !== null ? limitNumberValue(v, MIN_COUNT_VALUE, MAX_COUNT_VALUE) : v,
      }),
    [onChange_, schemaKeysMapping]
  );
  const value = useMemo(
    () => parameters[schemaKeysMapping.days_count_key],
    [parameters, schemaKeysMapping]
  );
  const options = useMemo(() => ({options: OPTIONS}), []);
  const [isCustomMode, setIsCustomMode] = useState(isCustomValue(value));
  const selectedOption = useMemo(() => {
    if (isCustomMode) {
      return CUSTOM_OPTION_VALUE;
    }
    if (value === null) {
      return value;
    }
    const op = OPTIONS.find(o => o.value === value);
    return op ? value : CUSTOM_OPTION_VALUE;
  }, [value, isCustomMode]);
  const onSelectChange = useCallback(
    v => {
      if (isNumber(v) || v === null) {
        onChange(v);
        setIsCustomMode(false);
        return;
      }
      if (v === CUSTOM_OPTION_VALUE) {
        setIsCustomMode(true);
      }
    },
    [onChange]
  );

  const renderContent = () => {
    return (
      <div
        className={classNames(classes.Row, variant === AnalysisSelectorVariant.INLINE && className)}
      >
        {prefix && <div className={classes.Item}>{prefix}</div>}
        <div className={classes.Item}>
          <Select
            value={selectedOption}
            options={options}
            onChange={onSelectChange}
            searchable={false}
            sortValues={false}
            clearable={clearable}
            error={exists(errorMessage)}
            capitalize={false}
          />
        </div>
        {selectedOption === CUSTOM_OPTION_VALUE && (
          <>
            <div className={classes.Item}>
              <TextInput
                className={classes.Input}
                value={value}
                onChange={v => onChange(v as any)}
                error={exists(errorMessage)}
                type={'number'}
                placeholder={'N'}
              />
            </div>
            <div className={classes.Item}>Days</div>
          </>
        )}
        {suffix && <div className={classNames(classes.Item, classes.Muted)}>{suffix}</div>}
        {AnalysisSelectorVariant.INLINE && exists(errorMessage) && (
          <HoverHelperTip
            title={errorMessage}
            className={classes.Item}
            type={HelperTipType.ERROR}
          />
        )}
        {suffixHelper && <HoverHelperTip title={suffixHelper} className={classes.Item} />}
      </div>
    );
  };

  if (variant === AnalysisSelectorVariant.INLINE) {
    return renderContent();
  }

  return (
    <ParameterInputWrapper
      title={title}
      subTitle={subTitle}
      className={classNames(classes.DaysCountSelector, className)}
      helperText={helperText}
      bottomHelper={bottomHelper}
      error={hasError}
    >
      <LabelWrapper
        label={''}
        error={exists(errorMessage)}
        helperText={errorMessage}
        fullWidth={false}
      >
        {renderContent()}
      </LabelWrapper>
    </ParameterInputWrapper>
  );
};

DaysCountSelector.defaultProps = {
  schemaKeysMapping: DEFAULT_SCHEMA_KEYS_MAPPING,
  clearable: false,
};

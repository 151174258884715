import {Epic} from 'redux-observable';
import {createRequestEpic} from 'front-core';
import {ActionKey} from '../../constants/action-key';
import HttpClient from '../../services/http-client.service';
import {MetricsActionType} from './metrics.actions';
import {
  addMetricToTeamNetworkRequest,
  createMetricNetworkRequest,
  deleteMetricNetworkRequest,
  editMetricNetworkRequest,
  publishMetricNetworkRequest,
  resampleMetricNetworkRequest,
  setMetricNorthStarNetworkRequest,
  setMetricOwnerNetworkRequest,
  updateTeamMetricsNetworkRequest,
  validateMetricNetworkRequest,
} from '../../http/metrics.network-requests';
import {modelCreated, modelDeleted, modelUpdated, notifyEvent} from '../core/core.actions';
import {ModelKey} from '../../constants/model-key';
import {metricToastCreator} from '../toasts.actions';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {showToastMessage} from '../interface/interface.actions';
import {ToastType} from '../../objects/system/toast-type.enum';
import {
  demoProductLimitedActionsFilter,
  errorExtractor,
  getDMPToastErrorMessage,
} from '../store.utils';

export const createMetricEpic: Epic = createRequestEpic(
  {
    types: [MetricsActionType.CREATE_METRIC],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.CREATE_METRIC,
    request: createMetricNetworkRequest,
    onSuccess: metric => [
      notifyEvent(AmplitudeEvent.METRIC_CREATED, {
        id: metric.id,
        type: metric.type,
        entity: metric.entity,
      }),
      metricToastCreator('CREATE_SUCCESS'),
      modelCreated(metric, ModelKey.METRIC),
    ],
    onError: err_ => {
      const err = errorExtractor(err_);
      const toastMessage = getDMPToastErrorMessage(err, 'metric');
      if (toastMessage) {
        return [
          showToastMessage(toastMessage, ToastType.ERROR),
          notifyEvent(AmplitudeEvent.CREATE_METRIC_ERROR, err),
        ];
      }
      return [metricToastCreator('CREATE_ERROR')];
    },
  },
  HttpClient
);

export const updateMetricEpic: Epic = createRequestEpic(
  {
    types: [MetricsActionType.UPDATE_METRIC],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.UPDATE_METRIC,
    request: editMetricNetworkRequest,
    onSuccess: metric => [
      notifyEvent(AmplitudeEvent.METRIC_UPDATED, {
        id: metric.id,
        type: metric.type,
        entity: metric.entity,
      }),
      metricToastCreator('UPDATE_SUCCESS'),
      modelUpdated(metric, ModelKey.METRIC),
    ],
    onError: err_ => {
      const err = errorExtractor(err_);
      const toastMessage = getDMPToastErrorMessage(err, 'metric');
      if (toastMessage) {
        return [
          showToastMessage(toastMessage, ToastType.ERROR),
          notifyEvent(AmplitudeEvent.UPDATE_METRIC_ERROR, err),
        ];
      }
      return [metricToastCreator('CREATE_ERROR')];
    },
  },
  HttpClient
);

export const deleteMetricEpic: Epic = createRequestEpic(
  {
    types: [MetricsActionType.DELETE_METRIC],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.DELETE_METRIC,
    request: deleteMetricNetworkRequest,
    onSuccess: (_, id) => [
      notifyEvent(AmplitudeEvent.METRIC_DELETED, {id: id}),
      metricToastCreator('DELETE_SUCCESS'),
      modelDeleted(id, ModelKey.METRIC),
    ],
    onError: err => [metricToastCreator('DELETE_ERROR')],
  },
  HttpClient
);

export const publishMetricEpic: Epic = createRequestEpic(
  {
    types: [MetricsActionType.PUBLISH_AD_HOC_METRIC],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.PUBLISH_METRIC,
    request: publishMetricNetworkRequest,
    onSuccess: metric => [
      notifyEvent(AmplitudeEvent.METRIC_PUBLISHED, {
        id: metric.id,
      }),
      metricToastCreator('UPDATE_SUCCESS'),
      modelUpdated(metric, ModelKey.METRIC),
    ],
    onError: err => [metricToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);

export const setMetricOwnerEpic: Epic = createRequestEpic(
  {
    types: [MetricsActionType.SET_METRIC_OWNER],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.SET_METRIC_OWNER,
    request: setMetricOwnerNetworkRequest,
    onSuccess: metric => [
      notifyEvent(AmplitudeEvent.SET_METRIC_OWNER, {
        id: metric.id,
        owner_id: metric.ownerId,
      }),
      modelUpdated(metric, ModelKey.METRIC),
    ],
    onError: err => [metricToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);

export const setMetricNorthStarEpic: Epic = createRequestEpic(
  {
    types: [MetricsActionType.SET_METRIC_NORTH_STAR],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.SET_METRIC_NORTH_STAR,
    request: setMetricNorthStarNetworkRequest,
    onSuccess: metric => [
      notifyEvent(AmplitudeEvent.SET_METRIC_NORTH_STAR, {id: metric.id}),
      modelUpdated(metric, ModelKey.METRIC),
    ],
    onError: err => [metricToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);

export const resampleMetricEpic: Epic = createRequestEpic(
  {
    types: [MetricsActionType.RESAMPLE_METRIC],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.RESAMPLE_METRIC,
    request: resampleMetricNetworkRequest,
    onSuccess: metric => [
      metricToastCreator('UPDATE_SUCCESS'),
      modelUpdated(metric, ModelKey.METRIC),
    ],
    onError: err => [metricToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);

export const validateMetricEpic: Epic = createRequestEpic(
  {
    types: [MetricsActionType.VALIDATE_METRIC],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.VALIDATE_METRIC,
    request: validateMetricNetworkRequest,
    onSuccess: metric => [
      notifyEvent(AmplitudeEvent.METRIC_VALIDATED, {
        id: metric.id,
      }),
      metricToastCreator('UPDATE_SUCCESS'),
      modelUpdated(metric, ModelKey.METRIC),
    ],
    onError: err => [metricToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);

export const updateTeamMetricsEpic: Epic = createRequestEpic(
  {
    types: [MetricsActionType.UPDATE_TEAM_METRICS],
    actionKey: ActionKey.UPDATE_TEAM_METRICS,
    request: updateTeamMetricsNetworkRequest,
    onSuccess: teamMetrics => {
      return [
        notifyEvent(AmplitudeEvent.UPDATE_TEAM_METRICS),
        showToastMessage('Team metrics updated successfully', ToastType.SUCCESS),
        modelUpdated(teamMetrics, ModelKey.TEAM_METRICS),
      ];
    },
    onError: err => {
      return [showToastMessage('Error while updating team metrics', ToastType.ERROR)];
    },
  },
  HttpClient
);

export const addMetricToTeamEpic: Epic = createRequestEpic(
  {
    types: [MetricsActionType.ADD_METRIC_TO_TEAM],
    actionKey: ActionKey.ADD_METRIC_TO_TEAM,
    request: addMetricToTeamNetworkRequest,
    onSuccess: teamMetrics => {
      return [
        notifyEvent(AmplitudeEvent.ADD_METRIC_TO_TEAM),
        showToastMessage('Team metrics updated successfully', ToastType.SUCCESS),
        modelUpdated(teamMetrics, ModelKey.TEAM_METRICS),
      ];
    },
    onError: err => {
      return [showToastMessage('Error while updating team metrics', ToastType.ERROR)];
    },
  },
  HttpClient
);

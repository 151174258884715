import {useMemo} from 'react';
import {composition} from 'front-core';
import TransKeys from '../../../../constants/translation-keys';
import {AppTabs, OpportunitiesMenuIcon} from 'ui-components';
import {useTranslation} from 'react-i18next';
import {
  isSnoozedFilter,
  queryFilter,
  singleAnalysisTypeFilter,
  singleMetricFilter,
} from '../../../../constants/filters';
import {values} from 'lodash';
import {useSelector} from 'react-redux';
import {CORE_STORE_KEY} from '../../../../store/core/core.store';
import {StringParam, useQueryParams, withDefault} from 'use-query-params';
import {OpportunityFeedTab} from './components/opportunity-feed-tab/opportunity-feed-tab.component';
import {OpportunitySimpleTab} from './components/opportunity-simple-tab/opportunity-simple-tab.component';
import PageLayout from '../../../shared/components/layout/page-layout/index';

interface OwnProps {}

type AllProps = OwnProps;

export enum OpportunitiesTabs {
  MY_FEED = 'my_feed',
  STARRED = 'starred',
  ALL = 'all',
}

const tabsConfigMap = {
  [OpportunitiesTabs.MY_FEED]: {},
  [OpportunitiesTabs.STARRED]: {
    isExplored: true,
  },
  [OpportunitiesTabs.ALL]: {},
};

const OpportunitiesMainComponent: React.FC<AllProps> = (props: AllProps) => {
  const {t} = useTranslation();
  const [selectedTab, setSelectedTab] = useQueryParams({
    tab: withDefault(StringParam, OpportunitiesTabs.MY_FEED),
  });
  const tabFilters = tabsConfigMap[selectedTab.tab];
  // /**
  //  * THIS IS TEMP FOR A SPECIFIC PRODUCT
  //  */
  const scopeProduct = useSelector(state => state[CORE_STORE_KEY].scopeProduct);
  const filtersDef = useMemo(
    () => [
      queryFilter(),
      {
        ...singleMetricFilter(),
        multi: true,
      },
      singleAnalysisTypeFilter(),
      isSnoozedFilter(),
    ],
    []
  );
  const defaultFilters = useMemo(
    () => ({
      isRelevant: true,
      orderBy: 'updatedOn',
      order: 'desc',
      ...(Number(scopeProduct) === 43 || Number(scopeProduct) === 29 ? {itemsPerPage: 20} : {}),
      ...tabFilters,
    }),
    [tabFilters, scopeProduct]
  );
  const tabs = useMemo(
    () =>
      values(OpportunitiesTabs).map(tab => ({
        key: tab,
        label: t(TransKeys.OPPORTUNITIES.TABS[tab.toUpperCase()]),
      })),
    [t]
  );

  const renderCurrentTabComponent = () => {
    let TabComponent;
    switch (selectedTab.tab) {
      case OpportunitiesTabs.MY_FEED:
        TabComponent = <OpportunityFeedTab />;
        break;
      default:
        TabComponent = (
          <OpportunitySimpleTab
            key={selectedTab.tab}
            tabName={selectedTab.tab as OpportunitiesTabs}
            filtersDef={filtersDef}
            defaultFilters={defaultFilters}
          />
        );
    }
    return TabComponent;
  };

  return (
    <PageLayout.Layout>
      <PageLayout.Title
        title={t(TransKeys.OPPORTUNITIES.HEADER.TITLE)}
        icon={OpportunitiesMenuIcon}
      />
      <PageLayout.Tabs>
        <AppTabs
          tabs={tabs}
          selected={selectedTab.tab}
          onChange={tabKey => setSelectedTab({tab: tabKey})}
          border={false}
        />
      </PageLayout.Tabs>
      {renderCurrentTabComponent()}
    </PageLayout.Layout>
  );
};

const OpportunitiesMain = composition<OwnProps>(OpportunitiesMainComponent);

export default OpportunitiesMain;

import * as React from 'react';
import {composition} from 'front-core';
import {AnalysesRouting} from './analyses-routing.component';

interface OwnProps {}

type AllProps = OwnProps;

const AnalysesComponent: React.FC<AllProps> = (props: AllProps) => {
  return <AnalysesRouting />;
};

const Analyses = composition<OwnProps>(AnalysesComponent);

export default Analyses;

import {StoreDefinition, StorePropertyType} from 'front-core';
import {NotificationsActionType} from './notifications.actions';
import {
  loadNotificationsReducer,
  markNotificationAsReadReducer,
  markNotificationsAsSeenReducer,
  newNotificationReducer,
} from './notifications.reducers';

export const NOTIFICATIONS_STORE_KEY = 'NOTIFICATIONS_STORE';

export const notificationsStore: StoreDefinition = {
  key: NOTIFICATIONS_STORE_KEY,
  properties: {
    notifications: {
      propertyType: StorePropertyType.OBJECT,
      defaultValue: {},
    },
    total: {
      propertyType: StorePropertyType.NUMBER,
      defaultValue: 0,
    },
    hasNext: {
      propertyType: StorePropertyType.BOOLEAN,
      defaultValue: true,
    },
  },
  reducer: {
    [NotificationsActionType.LOAD_NOTIFICATIONS_SUCCESS]: loadNotificationsReducer,
    [NotificationsActionType.MARK_NOTIFICATIONS_AS_SEEN]: markNotificationsAsSeenReducer,
    [NotificationsActionType.MARK_NOTIFICATIONS_AS_READ]: markNotificationAsReadReducer,
    [NotificationsActionType.MARK_NOTIFICATION_AS_READ]: markNotificationAsReadReducer,
    [NotificationsActionType.NEW_NOTIFICATION]: newNotificationReducer,
  },
};

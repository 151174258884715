import * as React from 'react';
import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import classes from './base-retention-builder.module.scss';
import {get} from 'lodash';
import {LabelWrapper, LiteralValueType, Select, TextInput, useQueryArray} from 'ui-components';
import {SignalSmartSelectorKey} from '../../smart-selector/signal-smart-selector.component';
import {ItemList} from '../components/item-list/item-list.component';
import {SignalDataType, SignalType} from '../../../../../objects/models/signal.model';
import {
  TableEntity,
  TableEntityBinding,
  TableType,
} from '../../../../../objects/models/table.model';
import {limitNumberValue} from '../../../../../utils/general.utils';
import {SelectorModelType} from '../../smart-selector/advanced-smart-selector.component';
import {TemplateItemQueryBuilder} from '../template-item-query-builder/template-item-query-builder.component';
import {TIME_UNIT_OPTIONS} from '../../../../../constants/options';
import TransKeys from 'translations';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';
import {exists} from 'front-core';

interface SchemaKeysMapping {
  actions: string;
  ref_date: string;
  actions_retention_unit?: string;
  actions_retention_n?: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  actions: 'actions',
  ref_date: 'ref_date',
  actions_retention_unit: 'actions_retention_unit',
  actions_retention_n: 'actions_retention_n',
};

interface OwnProps {
  value: any;
  onChange?: (parameters: any) => void;
  schemaKeysMapping?: SchemaKeysMapping;
  onSignalInfo?: (value: string | number) => void;
  entityContext?: TableEntity;
  refDateSignalFilters?: any;
  actionsSignalFilters?: any;
  refDateAllowTypes?: SelectorModelType[];
  errors?: any;
  disabled?: boolean;
  className?: string;
}

type AllProps = OwnProps;

const createRefDateSignalFilters = (entityContext = undefined) => [
  {
    type: SignalType.DIMENSION,
    data_type: SignalDataType.TIMESTAMP,
    entity_binding: TableEntityBinding.DEFAULT,
    exclude_templates: '*',
    entityContext,
  },
  {
    type: SignalType.DIMENSION,
    data_type: SignalDataType.TIMESTAMP,
    entity_binding: TableEntityBinding.DEFAULT,
    templates: ['dom_template'],
    entityContext,
  },
];
const createActionsSignalFilters = (entityContext = undefined) => ({
  type: SignalType.MEASURE,
  data_type: SignalDataType.BOOLEAN,
  entity_binding: TableEntityBinding.TWO_WAY,
  exclude_templates: '*',
  entityContext,
});
const createColumnFilters = (entityContext = undefined) => ({
  tableType: TableType.ENTITY_PROPERTIES,
  literalType: LiteralValueType.DATE,
  entity_binding: TableEntityBinding.DEFAULT,
  entityContext,
});

const MIN_COUNT_VALUE = 2;
const MAX_COUNT_VALUE = 181;

const INCLUDE_REF_DATE = [
  SignalSmartSelectorKey.FUNNELS,
  SignalSmartSelectorKey.FEATURES,
  SignalSmartSelectorKey.MILESTONES,
  SignalSmartSelectorKey.SIGNALS,
];

const INCLUDE_ACTIONS = [
  SignalSmartSelectorKey.FUNNELS,
  SignalSmartSelectorKey.FEATURES,
  SignalSmartSelectorKey.MILESTONES,
  SignalSmartSelectorKey.SIGNALS,
];

const DEFAULT_REF_DATE_ALLOW_TYPES = [SelectorModelType.SIGNAL, SelectorModelType.COLUMN];

export const BaseRetentionBuilder: React.FC<AllProps> = (props: AllProps) => {
  const {
    errors,
    onChange,
    onSignalInfo,
    schemaKeysMapping,
    value,
    entityContext,
    refDateSignalFilters,
    actionsSignalFilters,
    refDateAllowTypes,
    disabled,
    className,
  } = props;
  const {t} = useTranslation();
  const {addElement, removeElement} = useQueryArray(
    get(value, schemaKeysMapping.actions, []),
    data => onChange && onChange({[schemaKeysMapping.actions]: data}),
    () => null
  );
  const {getEntityName} = useProductData();
  const entityName = useMemo(
    () => (entityContext ? getEntityName(entityContext).toLowerCase() : TableEntity.USER),
    [entityContext, getEntityName]
  );
  const onActionsChanged = useCallback(
    (pValue: any, idx: number) => {
      const actions = [...get(value, schemaKeysMapping.actions, [])];
      actions[idx] = pValue;
      onChange && onChange({[schemaKeysMapping.actions]: actions});
    },
    [onChange, schemaKeysMapping, value]
  );
  const refDateFilters = useMemo(
    () => refDateSignalFilters || createRefDateSignalFilters(entityContext),
    [refDateSignalFilters, entityContext]
  );
  const actionsFilters = useMemo(
    () => actionsSignalFilters || createActionsSignalFilters(entityContext),
    [actionsSignalFilters, entityContext]
  );
  const columnFilters = useMemo(() => createColumnFilters(entityContext), [entityContext]);
  const eventFilters = useMemo(
    () => ({
      entity_binding: TableEntityBinding.DEFAULT,
      entityContext,
    }),
    [entityContext]
  );
  const fullWidthSince = useMemo(
    () => value[schemaKeysMapping.ref_date]?.type === 'And',
    [value, schemaKeysMapping]
  );

  const renderRefDateSelector = () => (
    <TemplateItemQueryBuilder
      onChange={value => onChange && onChange({[schemaKeysMapping.ref_date]: value})}
      query={value[schemaKeysMapping.ref_date]}
      errors={errors?.[schemaKeysMapping.ref_date]}
      signalFilters={refDateFilters}
      signalsInclude={INCLUDE_REF_DATE}
      columnFilters={columnFilters}
      eventFilters={eventFilters}
      disabled={disabled}
      allowTypes={refDateAllowTypes || DEFAULT_REF_DATE_ALLOW_TYPES}
      onSignalInfo={onSignalInfo}
    />
  );
  return (
    <div className={classNames(classes.BaseRetentionBuilder, className)}>
      {/* IN CASE THERE IS NO BOUNDING SELECTION WE NEED TO RENDER THE SELECTION UP FRONT*/}
      {!exists(schemaKeysMapping.actions_retention_n) &&
        !exists(schemaKeysMapping.actions_retention_unit) && (
          <div className={classes.Row}>
            <LabelWrapper
              label={t(TransKeys.QUERY_BUILDERS.BASE_RETENTION_QUERY_BUILDER.REF_DATE_LABEL)}
            >
              {renderRefDateSelector()}
            </LabelWrapper>
          </div>
        )}
      <div className={classes.Row}>
        <LabelWrapper
          label={t(TransKeys.QUERY_BUILDERS.BASE_RETENTION_QUERY_BUILDER.ACTIONS_LABEL, {
            entity: entityName,
          })}
        >
          <ItemList
            disabled={disabled}
            items={value[schemaKeysMapping.actions]}
            onRemoveItem={idx => removeElement(idx)}
            onAddItem={() => addElement()}
            renderItem={(item, idx) => (
              <TemplateItemQueryBuilder
                onChange={v => onActionsChanged(v, idx)}
                query={value[schemaKeysMapping.actions]?.[idx]}
                errors={errors?.[schemaKeysMapping.actions]?.[idx]}
                signalFilters={actionsFilters}
                signalsInclude={INCLUDE_ACTIONS}
                allowTypes={[SelectorModelType.SIGNAL, SelectorModelType.EVENT]}
                eventFilters={eventFilters}
                disabled={disabled}
                placeholder={'Select action'}
                onSignalInfo={onSignalInfo}
              />
            )}
          />
        </LabelWrapper>
      </div>
      {/* IN CASE THERE IS BOUNDING SELECTION WE WILL RENDER IT INLINE */}
      {schemaKeysMapping.actions_retention_n && schemaKeysMapping.actions_retention_unit && (
        <div className={classes.Row}>
          <LabelWrapper
            label={t(
              TransKeys.QUERY_BUILDERS.BASE_RETENTION_QUERY_BUILDER.ACTIONS_RETENTION_UNIT_LABEL
            )}
          >
            <div className={classNames(classes.Row, classes.Window)}>
              <div className={classes.Item}>On</div>
              <div className={classes.Item}>
                <Select
                  value={value[schemaKeysMapping.actions_retention_unit]}
                  onChange={value =>
                    onChange &&
                    onChange({
                      [schemaKeysMapping.actions_retention_unit]: value,
                    })
                  }
                  error={Boolean(errors?.[schemaKeysMapping.actions_retention_unit])}
                  options={TIME_UNIT_OPTIONS}
                  searchable={false}
                  clearable={false}
                  capitalize={false}
                  disabled={disabled}
                />
              </div>
              <div className={classes.Item}>number</div>
              <div className={classes.Item}>
                <TextInput
                  className={classes.Input}
                  value={value[schemaKeysMapping.actions_retention_n]}
                  error={Boolean(errors?.[schemaKeysMapping.actions_retention_n])}
                  onChange={value =>
                    onChange &&
                    onChange({
                      [schemaKeysMapping.actions_retention_n]: limitNumberValue(
                        value as any,
                        MIN_COUNT_VALUE,
                        MAX_COUNT_VALUE
                      ),
                    })
                  }
                  type={'number'}
                  disabled={disabled}
                />
              </div>
              <div className={classes.Item}>since</div>
              <div className={classNames(classes.Item, fullWidthSince && classes.FullWidth)}>
                {renderRefDateSelector()}
              </div>
            </div>
          </LabelWrapper>
        </div>
      )}
    </div>
  );
};

BaseRetentionBuilder.defaultProps = {
  schemaKeysMapping: DEFAULT_SCHEMA_KEYS_MAPPING,
};

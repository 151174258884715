import * as React from 'react';
import {SmartSelectorPanel, Ellipsis, Chip} from 'ui-components';
import classes from '../../smart-selector.module.scss';

interface OwnProps {
  label?: string;
  data: any;
  className?: string;
}

type AllProps = OwnProps;

export const SourceTablePreview: React.FC<AllProps> = (props: AllProps) => {
  const {data, className, label} = props;

  return (
    <SmartSelectorPanel
      className={className}
      label={label}
      title={<Ellipsis maxWidth={'100%'}>{data.name}</Ellipsis>}
    >
      <div className={classes.LabelRow}>
        <span className={classes.Label}>prefix</span>
      </div>
      <div className={classes.InfoRow}>
        <Chip label={data.prefix} />
      </div>
    </SmartSelectorPanel>
  );
};

export const SAVED_SIGNAL_TAGS = [
  'join_date',
  'first_payment',
  'active',
  'start_date',
  'event',
  'plan',
  'payment',
  'error',
  'payment_failure',
  'life_cycle',
  'churn',
];

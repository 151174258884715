import React, {useMemo} from 'react';
import {Select, TeamIconChip} from 'ui-components';
import classes from './app-user-modal.module.scss';
import {Team} from '../../../../objects/models/team.model';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';

interface OwnProps {
  teams: Team[];
  selectedTeamId?: number;
  onTeamSelected: (id: number) => void;
}

type AllProps = OwnProps;

export const TeamSelect: React.FC<AllProps> = (props: AllProps) => {
  const {teams, selectedTeamId, onTeamSelected} = props;
  const {t} = useTranslation();

  const options = useMemo(
    () =>
      teams.map(t => ({
        icon: ({className}) => <TeamIconChip {...t} className={className} />,
        value: t.id,
        label: t.name,
      })),
    [teams]
  );

  return (
    <Select
      dropdownButtonClassName={classes.DropdownButtonClassName}
      placeholder={t(TransKeys.GENERAL.LABELS.NO_TEAM_PLACEHOLDER)}
      value={selectedTeamId}
      searchable={options.length > 5}
      onChange={onTeamSelected as any}
      options={{options}}
      fullWidth
      clearable={true}
      capitalize={false}
      fitContent={false}
      sortValues={false}
    />
  );
};

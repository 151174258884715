import {action, ActionHooks} from 'front-core';
import {UserReactionDTO} from '../../objects/dto/user-reaction.dto';
import {UserReactionType} from '../../objects/models/user-reaction.model';
import {ShareDTO} from '../../objects/dto/share.dto';
import {showConfirmationMessage} from '../interface/interface.actions';
import i18n from 'i18next';
import TransKeys from 'translations';
import {ShareResourceType} from '../../objects/models/share.model';

export enum UserReactionsActionType {
  CREATE_REACTION = '@@USER_REACTIONS/CREATE_REACTION',
  CREATE_REACTION_SUCCESS = '@@USER_REACTIONS/CREATE_REACTION_SUCCESS',
  DELETE_REACTION = '@@USER_REACTIONS/DELETE_REACTION',
  DELETE_REACTION_SUCCESS = '@@USER_REACTIONS/DELETE_REACTION_SUCCESS',
  SHARE_RESOURCE = '@@USER_REACTIONS/SHARE_RESOURCE',
  DELETE_SHARE_RESOURCE = '@@USER_REACTIONS/DELETE_SHARE_RESOURCE',
}

export const createUserReaction = (
  type: UserReactionType,
  data: UserReactionDTO,
  hooks?: ActionHooks
) => action(UserReactionsActionType.CREATE_REACTION, {type, data}, hooks);

export const createUserReactionSuccess = (data: UserReactionDTO) =>
  action(UserReactionsActionType.CREATE_REACTION_SUCCESS, data);

export const deleteUserReaction = (
  type: UserReactionType,
  data: UserReactionDTO,
  hooks?: ActionHooks
) => action(UserReactionsActionType.DELETE_REACTION, {type, data}, hooks);

export const deleteUserReactionSuccess = (data: UserReactionDTO) =>
  action(UserReactionsActionType.DELETE_REACTION_SUCCESS, data);

export const shareResource = (data: ShareDTO, hooks?: ActionHooks) =>
  action(UserReactionsActionType.SHARE_RESOURCE, data, hooks);

export const deleteShareResourceConfirmed = (
  modelId: number,
  type: ShareResourceType,
  recipients: number[],
  hooks?: ActionHooks
) =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.DELETE_SHARE.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.DELETE_SHARE.CONTENT),
    },
    () => [deleteShareResource(modelId, type, recipients, hooks)]
  );

export const deleteShareResource = (
  modelId: number,
  type: ShareResourceType,
  recipients: number[],
  hooks?: ActionHooks
) => {
  return action(UserReactionsActionType.DELETE_SHARE_RESOURCE, {modelId, type, recipients}, hooks);
};

import * as React from 'react';
import {useCallback, useEffect, useContext, useMemo, useRef} from 'react';
import classes from './analysis-results.module.scss';
import {
  AdvancedDocument,
  DocumentElementType,
  DocumentResolver,
  DocumentViewerMode,
  DocumentContainer,
  InteractionContext,
  InteractionProvider,
  DocumentPhenomenasProvider,
  useRemoteSource,
  JourneyDriversSimulatorViewer,
  DocumentComponentsMap,
  RCAFigureViewer,
} from 'ui-components';
import {AnalysisResult} from '../../../../objects/models/analysis-result.model';
import classNames from 'classnames';
import {HttpClientContext} from 'front-core';
import {getDocumentNetworkRequest} from '../../../../http/documents.network-requests';
import {RemotePhenomenasViewer} from './viewers/remote-phenomenas-viewer.component';
import {JsonParam, StringParam, useQueryParam, useQueryParams, withDefault} from 'use-query-params';
import {DOCUMENT_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {AppErrorViewer} from './viewers/error-viewer/app-error-viewer.component';
import {Phenomena} from '../../../../objects/models/phenomena.model';
import {getPhenomenasNetworkRequest} from '../../../../http/phenomenas.network-requests';
import {useDocumentTracking} from './hooks/use-document-tracking.hook';
import {FeedbackViewer} from './viewers/feedback-viewer/feedback-viewer.component';
import {FeedbackModelType} from '../../../../objects/models/feedback.model';
import {sendHotjarEvent} from '../../../../config/hotjar.config';
import {HotjarEvent} from '../../../../constants/hotjar-event';
import {AppGlobalDocumentDataProvider} from './context-providers/app-global-document-data-provider.component';
import {AppRCAOverviewViewer} from './viewers/app-rca-overview-viewer.component';
import queryString from 'query-string';
import {AppExecutiveSummeryViewer} from './viewers/app-executive-summery-viewer/app-executive-summery-viewer.component';

interface OwnProps {
  documentId?: string;
  analysisResult: AnalysisResult;
  phenomena?: Phenomena;
  isLoading?: boolean;
  renderFirst?: (props) => JSX.Element;
  onPageChange?: (doc: AdvancedDocument) => void;
  onParametersChange?: (parameters: any) => void;
  mode?: DocumentViewerMode;
  analysisResultDocumentParameters?: any;
  onDocumentResolvedSuccess?: (docId: string) => void;
  onDocumentResolvedError?: (docId: string) => void;
  navigationDisabled?: boolean;
  componentsMap?: DocumentComponentsMap;
  scrollable?: boolean;
  className?: string;
}

type AllProps = OwnProps;

const getInitialRCAMode = () => {
  const parsed = queryString.parse(window.location.search);
  const res = parsed.rcaMode;
  if (res) {
    delete parsed.rcaMode;
    window.history.replaceState('', '', '?' + queryString.stringify(parsed));
  }
  return res;
};

export const AnalysisResults: React.FC<AllProps> = (props: AllProps) => {
  const {
    documentId: documentIdFromProps,
    analysisResult,
    analysisResultDocumentParameters,
    onDocumentResolvedSuccess,
    onDocumentResolvedError,
    renderFirst,
    onPageChange,
    onParametersChange,
    navigationDisabled,
    componentsMap: componentsMapFromProps,
    mode,
    scrollable,
    className,
  } = props;
  const http = useContext(HttpClientContext);
  const {postMessage} = useContext(InteractionContext);
  const rcaInitialModeRef = useRef(getInitialRCAMode());
  const track = useDocumentTracking(analysisResult);
  // State
  const [docId, setDocId] = useQueryParam(
    DOCUMENT_ID_PATH_PARAM,
    withDefault(StringParam, documentIdFromProps)
  );
  const [docQuery, setDocQuery] = useQueryParams({
    docQuery: withDefault(JsonParam, analysisResultDocumentParameters?.docQuery),
  });
  const {exec} = useRemoteSource({
    networkRequest: getPhenomenasNetworkRequest,
    type: 'source',
    transformer: res =>
      res.map((p: Phenomena) => ({
        id: p.id,
        type: p.type,
        typeName: p.typeName,
        sentence: p.phenomenaSentence,
        documentId: p.rootDocumentId,
        isUseful: p.isUseful,
      })),
  });
  const documentResolver: DocumentResolver = useCallback(
    async (documentId: string) => {
      try {
        const doc = (await http.exec(getDocumentNetworkRequest(documentId))) as any;
        const res = await fetch(doc.getDocUrl);
        const data = await res.json();
        return {
          ...doc,
          data,
        };
      } catch (e: any) {
        if (e.data?.error_code === 1404) {
          throw new Error(`Document ${documentId} not found`);
        }
        throw e;
      }
    },
    [http]
  );
  const phenomenasResolver = useCallback(async () => {
    return await exec({
      analysisResultId: analysisResult.id,
      filterDismissed: true,
      isInternal: false,
      customPhenomena: false,
      itemsPerPage: 1000,
      orderBy: ['isValid', 'type', 'hasExplanation', 'displayOrder'],
      order: ['desc', 'asc', 'desc', 'desc'],
    });
  }, [analysisResult.id, exec]);
  const documentId = useMemo(
    () => docId || analysisResult.rootDocumentId,
    [docId, analysisResult.rootDocumentId]
  );
  const componentsMap: DocumentComponentsMap = useMemo(
    () => ({
      [DocumentElementType.PHENOMENAS_FIGURE]: RemotePhenomenasViewer,
      [DocumentElementType.ERROR]: AppErrorViewer,
      [DocumentElementType.JOURNEY_DRIVERS_SIMULATION_FIGURE]: props => {
        return <JourneyDriversSimulatorViewer {...props} onParametersSubmit={onParametersChange} />;
      },
      [DocumentElementType._FEEDBACK]: props => (
        <FeedbackViewer
          {...props}
          modelId={analysisResult.id}
          modelType={FeedbackModelType.ANALYSIS_RESULT}
        />
      ),
      ...(componentsMapFromProps || {}),
      [DocumentElementType.RCA_FIGURE]: props => {
        if (componentsMapFromProps?.[DocumentElementType.RCA_FIGURE]) {
          const Component = componentsMapFromProps[DocumentElementType.RCA_FIGURE];
          return <Component {...props} initialMode={rcaInitialModeRef.current} />;
        }
        return <RCAFigureViewer {...props} initialMode={rcaInitialModeRef.current} />;
      },
      [DocumentElementType.RCA_OVERVIEW_FIGURE]: props => {
        if (componentsMapFromProps?.[DocumentElementType.RCA_OVERVIEW_FIGURE]) {
          const Component = componentsMapFromProps[DocumentElementType.RCA_OVERVIEW_FIGURE];
          return <Component {...props} />;
        }
        return <AppRCAOverviewViewer {...props} />;
      },
      [DocumentElementType._EXECUTIVE_SUMMARY_FIGURE]: props => {
        return <AppExecutiveSummeryViewer {...props} />;
      },
    }),
    [analysisResult.id, onParametersChange, componentsMapFromProps, rcaInitialModeRef]
  );
  const renderHead = useMemo(
    () =>
      renderFirst
        ? [
            {
              numerate: false,
              type: DocumentElementType._WINDOW_BLOCK,
              render: renderFirst,
            },
          ]
        : undefined,
    [renderFirst]
  );
  const onPostMessage = useCallback(
    message => {
      track(message.type, message.payload);
      postMessage(message, {analysisResultId: analysisResult.id});
    },
    [track, postMessage, analysisResult]
  );
  const onChangeDocId = useCallback(docId => setDocId(docId, 'replaceIn'), [setDocId]);
  const onDocQueryChange = useCallback(q => setDocQuery({docQuery: q}, 'replaceIn'), [setDocQuery]);

  useEffect(() => {
    const analysisTypeId = analysisResult.analysisTypeId;
    if (analysisTypeId) {
      sendHotjarEvent(HotjarEvent.ANALYSIS_RESULT_VIEW, `analysis_type_${analysisTypeId}`);
    }
  }, [analysisResult.analysisTypeId]);

  return (
    <InteractionProvider handler={onPostMessage}>
      <DocumentPhenomenasProvider phenomenasResolver={phenomenasResolver}>
        <AppGlobalDocumentDataProvider analysisResult={analysisResult}>
          <div className={classNames(classes.AnalysisResults, className)}>
            <div className={classes.ResultsWrapper}>
              <DocumentContainer
                documentId={documentId}
                documentResolver={documentResolver}
                onDocumentResolvedSuccess={onDocumentResolvedSuccess}
                onDocumentResolvedError={onDocumentResolvedError}
                onDocumentIdChanged={onChangeDocId}
                onPageChange={onPageChange}
                docQuery={docQuery.docQuery}
                setDocQuery={onDocQueryChange}
                mode={mode}
                parameters={analysisResultDocumentParameters?.parameters}
                renderHead={renderHead}
                componentsMap={componentsMap}
                navigationDisabled={navigationDisabled}
                scrollable={scrollable}
              />
            </div>
          </div>
        </AppGlobalDocumentDataProvider>
      </DocumentPhenomenasProvider>
    </InteractionProvider>
  );
};

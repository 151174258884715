import {useCallback, useMemo} from 'react';
import classNames from 'classnames';
import classes from './model-categories-input.module.scss';
import {ModelCategory} from '../../../../../objects/system/model-category.enum';
import {ModelCategoryBadge} from './model-category-badge.component';

interface OwnProps {
  options: ModelCategory[];
  values: ModelCategory[];
  onChange: (selected: ModelCategory[]) => void;
  maxSelections?: number;
  className?: string;
}

type AllProps = OwnProps;

const ModelCategoriesInput: React.FC<AllProps> = (props: AllProps) => {
  const {options, values, onChange, maxSelections, className} = props;

  const onOptionClicked = useCallback(
    (option: ModelCategory) => {
      if (maxSelections === 1) {
        return onChange([option]);
      }
      const set = new Set<ModelCategory>(values);
      if (set.has(option)) {
        set.delete(option);
      } else {
        set.add(option);
      }
      onChange(Array.from(set));
    },
    [values, onChange, maxSelections]
  );

  const disabled = useMemo(() => {
    return maxSelections && maxSelections > 1 && values.length >= maxSelections;
  }, [maxSelections, values]);

  return (
    <div className={classNames(className)}>
      <div className={classes.Options}>
        {options.map(category => {
          const selected = values.indexOf(category) > -1;
          return (
            <ModelCategoryBadge
              key={category}
              category={category}
              selected={selected}
              disabled={disabled}
              onClick={() => onOptionClicked(category)}
            />
          );
        })}
      </div>
    </div>
  );
};

ModelCategoriesInput.defaultProps = {};

export default ModelCategoriesInput;

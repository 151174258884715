import * as React from 'react';
import classNames from 'classnames';
import classes from './level-of-active-query-builder.module.scss';
import {
  METADATA_KEY,
  PARAMETERS_METADATA_KEY,
} from '../../../../../constants/parameters-saved-keys';
import {LabelWrapper, QueryBuilderFactory, Select} from 'ui-components';
import {useCallback, useMemo} from 'react';
import {get} from 'lodash';
import {del, set} from 'object-path-immutable';
import {ItemList} from '../components/item-list/item-list.component';
import {SignalDataType, SignalType} from '../../../../../objects/models/signal.model';
import {TableEntity, TableEntityBinding} from '../../../../../objects/models/table.model';
import {SelectorModelType} from '../../smart-selector/advanced-smart-selector.component';
import {TemplateItemQueryBuilder} from '../template-item-query-builder/template-item-query-builder.component';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';

interface OwnProps {
  query: any;
  onChange?: (parameters: any) => void;
  entityContext?: TableEntity;
  onSignalInfo?: (value: string | number) => void;
  errors?: any;
  disabled?: boolean;
  hideEngagementPeriod?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const createLevelOfActiveInitialQuery = (templateName: string) => {
  const q = QueryBuilderFactory.createTemplate();
  q.template = templateName;
  q.parameters[0] = [null];
  q[PARAMETERS_METADATA_KEY] = {
    [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: 'LevelOfActiveQueryBuilder',
  };
  return q;
};

const SELECT_OPTIONS = [
  {
    value: 'l7',
    label: '7',
  },
  {
    value: 'l28',
    label: '28',
  },
];

const ALLOW_TYPES = [SelectorModelType.SIGNAL, SelectorModelType.EVENT];

const createSignalFilters = (entityContext = undefined) => ({
  type: SignalType.MEASURE,
  data_type: SignalDataType.BOOLEAN,
  entity_binding: TableEntityBinding.TWO_WAY,
  entityContext,
});

export const LevelOfActiveQueryBuilder: React.FC<AllProps> = (props: AllProps) => {
  const {
    query,
    onChange,
    onSignalInfo,
    errors,
    disabled,
    entityContext,
    hideEngagementPeriod,
    className,
  } = props;
  const {getEntityName} = useProductData();
  const {t} = useTranslation();
  const options = useMemo(() => ({options: SELECT_OPTIONS}), []);
  const templateName = useMemo(() => get(query, `template`, 'l7'), [query]);
  const actions = useMemo(() => get(query, `parameters.0`, []), [query]);
  const signalFilters = useMemo(() => createSignalFilters(entityContext), [entityContext]);
  const eventFilters = useMemo(
    () => ({
      entity_binding: TableEntityBinding.TWO_WAY,
      entityContext,
    }),
    [entityContext]
  );
  const entityName = useMemo(
    () => (entityContext ? getEntityName(entityContext).toLowerCase() : TableEntity.USER),
    [entityContext, getEntityName]
  );
  const onChangeTemplate = useCallback(
    (templateName: string) => {
      const newQ = createLevelOfActiveInitialQuery(templateName);
      onChange && onChange(newQ);
    },
    [onChange]
  );
  const onActionsChanged = useCallback(
    (selectedValue, path) => {
      const newValue = set(query, path, selectedValue);
      onChange && onChange(newValue);
    },
    [query, onChange]
  );
  const removeAction = useCallback(
    path => {
      const newValue = del(query, path);
      onChange && onChange(newValue);
    },
    [query, onChange]
  );
  const addAction = useCallback(() => {
    const newValue = set(query, 'parameters.0', [...actions, null]);
    onChange && onChange(newValue);
  }, [query, actions, onChange]);

  return (
    <div className={classNames(classes.ActiveUsersQueryBuilder, className)}>
      {!hideEngagementPeriod && (
        <div className={classes.Row}>
          <LabelWrapper label={t(TransKeys.QUERY_BUILDERS.LEVEL_OF_ACTIVE.TEMPLATE_NAME.LABEL)}>
            <div className={classes.Row}>
              <div className={classes.Item}>
                {t(TransKeys.QUERY_BUILDERS.LEVEL_OF_ACTIVE.TEMPLATE_NAME.LEFT_TEXT)}
              </div>
              <div className={classes.Item}>
                <Select
                  value={templateName}
                  options={options}
                  onChange={onChangeTemplate as any}
                  searchable={false}
                  sortValues={false}
                  clearable={false}
                  dropdownButtonClassName={classes.Short}
                  disabled={disabled}
                />
              </div>
              <div className={classes.Item}>
                {t(TransKeys.QUERY_BUILDERS.LEVEL_OF_ACTIVE.TEMPLATE_NAME.RIGHT_TEXT)}
              </div>
            </div>
          </LabelWrapper>
        </div>
      )}
      <div className={classes.Row}>
        <LabelWrapper
          label={t(TransKeys.QUERY_BUILDERS.LEVEL_OF_ACTIVE.ACTIONS.LABEL, {entity: entityName})}
        >
          <ItemList
            items={actions}
            renderItem={(item, idx) => (
              <TemplateItemQueryBuilder
                onChange={v => onActionsChanged(v, `parameters.0.${idx}`)}
                query={actions[idx]}
                errors={get(errors, `parameters.0.${idx}`)}
                signalFilters={signalFilters}
                eventFilters={eventFilters}
                disabled={disabled}
                allowTypes={ALLOW_TYPES}
                onSignalInfo={onSignalInfo}
              />
            )}
            onRemoveItem={idx => removeAction(`parameters.0.${idx}`)}
            onAddItem={addAction}
            disabled={disabled}
          />
        </LabelWrapper>
      </div>
    </div>
  );
};

import yup from '../../config/yup.config';
import {Feature} from '../models/feature.model';
import {queryElementValidatorFactory} from './query-builder.dto';
import httpClientService from '../../services/http-client.service';
import {validateNameNetworkRequest} from '../../http/validations.network-requests';
import {values} from 'lodash';
import {TableEntity} from '../models/table.model';

export type FeatureDTO = Partial<Feature>;

export const featureDTOValidator = yup
  .object()
  .shape({
    id: yup.number().optional().nullable(),
    parentId: yup.number().optional().nullable(),
    name: yup
      .string()
      .required()
      .test('unique_name', 'Name already exists', async function (name: string) {
        const {id} = this.parent;
        try {
          await httpClientService.exec(
            validateNameNetworkRequest({
              name,
              existingId: id,
              modelType: 'feature',
            })
          );
          return true;
        } catch (e) {
          return false;
        }
      }),
    shortDescription: yup.string().nullable(),
    entity: yup.string().oneOf(values(TableEntity)).required(),
    launchOn: yup.string().nullable(),
    // signals
    usageSignalId: yup.number().optional().nullable(),
    usageSignalDefinition: queryElementValidatorFactory(false),
    activationSignalId: yup.number().optional().nullable(),
    activationSignalDefinition: queryElementValidatorFactory(false),
    exposureSignalId: yup.number().optional().nullable(),
    exposureSignalDefinition: queryElementValidatorFactory(false),
    intentSignalId: yup.number().optional().nullable(),
    intentSignalDefinition: queryElementValidatorFactory(false),
    churnSignalId: yup.number().optional().nullable(),
    churnSignalDefinition: queryElementValidatorFactory(false),
    tags: yup.array().of(yup.string().required()),
  })
  // @ts-ignore
  .atLeastOneRequired(
    [
      'usageSignalDefinition',
      'activationSignalDefinition',
      'exposureSignalDefinition',
      'intentSignalDefinition',
      'churnSignalDefinition',
    ],
    'Missing definition'
  );

import {exists} from 'front-core';
import {get, isArray, isEmpty, keys} from 'lodash';
import {ParametersSchema, ParameterType, SqlElementType} from 'ui-components';
import moment from 'moment/moment';
import {TIME_FORMATS} from '../../../../constants/time-formats';
import {ProductEntities} from '../../../../objects/models/product-data.model';

export type AnalysisParametersTransformer = (
  value: any,
  schema: ParametersSchema,
  productEntities?: ProductEntities
) => [any, ParametersSchema];

export const FEATURE_TAG_ACCEPTED_KEYS = [
  'tags_to_use',
  'treatments_tag',
  'features_tag',
  'feature_tag',
  'event_tag',
  'feature_usage_tag',
  'treatment_signal_slug',
  'confounders_tag',
];
export const BASE_ORDER_START = 10;
export const UNIT_TO_OCCURRENCE = {
  day: 'daily',
  week: 'weekly',
  month: 'monthly',
};
export const UNIT_TO_PLURAL = {
  day: 'days',
  week: 'weeks',
  month: 'months',
};

export const SPECIAL_TAGS_TRANSLATIONS = {
  __all_segments__: 'All Segment groups',
};

const _valueExists = (v: any) => {
  if (!exists(v)) {
    return false;
  }
  if (isArray(v) && v.length === 0) {
    return false;
  }
  if (typeof v === 'object') {
    return !isEmpty(v);
  }
  return true;
};

export const baseAnalysisParametersTransformer: AnalysisParametersTransformer = (
  values,
  schema,
  productEntities
) => {
  const newValues = {...values};
  const schemaKeys = keys(schema);
  for (let i = 0; i < schemaKeys.length; i++) {
    const key = schemaKeys[i];
    const parameter = schema[key];
    // Cleanup invalid parameters
    if (parameter.expose === false || !exists(parameter.expose)) {
      delete schema[key];
      delete newValues[key];
      continue;
    }
    if (!_valueExists(newValues[key])) {
      delete schema[key];
      continue;
    }
    // Setting the base order
    schema[key].order = BASE_ORDER_START + i;
    // Transforming dates to human string
    if (parameter.type === ParameterType.DATE) {
      newValues[key] = moment(newValues[key], TIME_FORMATS.PARAMETER_DATE_FORMAT).format(
        TIME_FORMATS.READABLE_DATE
      );
      schema[key].type = ParameterType.STRING;
    }
    // Feature tag
    if (FEATURE_TAG_ACCEPTED_KEYS.indexOf(key) > -1) {
      schema[key].name = 'Scope';
      schema[key].order = 100;
      // change type to select to render as chip
      schema[key].type = ParameterType.__MERGED_TREATMENTS;
      schema[key].list = true;
      // removing feature_ prefix
      if (typeof newValues[key] === 'string') {
        newValues[key] = newValues[key].split(',');
      }
    }
    // Population filter should appear before start end dates
    if (key === 'population_filter') {
      schema[key].order = 101;
    }
    // Making start and end dates appear last
    if (key === 'start_date' || key === 'end_date') {
      schema[key].order = 102;
    }

    if (key === 'entity') {
      schema[key].order = 0;
      schema[key].type = ParameterType.SELECT;
      newValues[key] = productEntities[newValues['entity']]?.name || newValues['entity'];
    }
    if (
      schema[key].type === ParameterType.QUERY_BUILDER &&
      get(newValues[key], 'type') === SqlElementType.SIGNAL_COLUMN
    ) {
      schema[key].type = ParameterType.SIGNAL;
      newValues[key] = get(newValues[key], 'signal_id');
    }
  }

  // Merging start and end dates into single parameter
  if ('start_date' in newValues && 'end_date' in newValues) {
    schema['start_date'].name = 'Time frame';
    newValues['start_date'] = `${newValues['start_date']} - ${newValues['end_date']}`;
    delete schema['end_date'];
    delete newValues['end_date'];
  }

  return [newValues, schema];
};

export const confoundersAnalysisParametersTransformer: AnalysisParametersTransformer = (
  values,
  schema
) => {
  const newValues = {...values};

  delete schema['confounders_tag'];
  delete schema['confounders_signals'];
  schema['confounders_tag'] = {
    name: 'Confounders',
    type: ParameterType.__MERGED_TREATMENTS,
    key: 'confounders_tag',
  };
  newValues['confounders_tag'] = [
    ...(newValues['confounders_tag'] || []),
    ...(newValues['confounders_signals'] || []),
  ];

  return [newValues, schema];
};

import moment from 'moment';
import {TIME_FORMATS} from '../../../../../../constants/time-formats';
import {TimeframeOption} from './homepage-model-samples-viewer.types';
import {ChartConfig} from '../../homepage-summary.types';

export const TIMEFRAME_OPTIONS: TimeframeOption[] = [
  {count: 30, unit: 'd'},
  {count: 3, unit: 'M'},
  {count: 6, unit: 'M'},
  // {count: 12, unit: 'M'},
];
export const DEFAULT_TIMEFRAME = TIMEFRAME_OPTIONS[1];
export const HOMEPAGE_CHART_CONFIG = 'hp-chart-config';
export const DEFAULT_CHART_CONFIG: ChartConfig = {
  showCI: true,
  showAnnotations: true,
  showQuarters: true,
  showTarget: true,
};
export const SHOW_ALL_POINTS_END_DATE = moment
  .utc()
  .startOf('d')
  .add(1, 'y')
  .format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT);

import {NotUsefulReasonsOptions} from './useful.model';

export enum UserReactionType {
  DISMISS = 'dismiss',
  EXPLORE = 'explore',
  SNOOZE = 'snooze',
  VIEW = 'view',
  USEFUL = 'useful',
}

export enum ModelDiscriminatorType {
  OPPORTUNITY = 'opportunity',
  ANALYSIS_RESULT = 'analysisresult',
  PHENOMENA = 'phenomena',
  EXPERIMENT = 'experiment',
  ANALYSIS_FOLDER = 'analysisfolder',
}

export interface UserReactionModel {
  parentId: number;
  discriminator: ModelDiscriminatorType;
  payload?: any;
}

export interface IsUsefulReactionPayload {
  status: boolean | null;
  reason?: NotUsefulReasonsOptions;
  more?: string;
}

import {useEffect} from 'react';
import {composition} from 'front-core';
import {PHENOMENA_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {useHistory, useParams} from 'react-router';
import classes from './view-phenomena.module.scss';
import {AnalysisResults} from '../../../shared/core/document-viewer/analysis-results.component';
import {useDispatch, useSelector} from 'react-redux';
import {
  getErrorSelector,
  getReducedLoadingStateSelector,
  getSingleSelectedSelector,
} from '../../../../store/store.selectors';
import {getAnalysisResultNetworkRequest} from '../../../../http/analysis-results.network-requests';
import {
  createSelected,
  getSelected,
  removeSelected,
} from '../../../../store/selected/selected.actions';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {ModelKey} from '../../../../constants/model-key';
import {EmptyState} from '../../../shared/components/general/override';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../constants/translation-keys';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {getPhenomenaByIdNetworkRequest} from '../../../../http/phenomenas.network-requests';
import {PhenomenaHeader} from '../../components/phenomena-header/phenomena-header.component';
import {isEmpty} from 'lodash';
import PageLayout from '../../../shared/components/layout/page-layout/index';

interface OwnProps {}

type AllProps = OwnProps;

const SELECTED_PHENOMENA_KEY = SharedSelectionKeys.VIEW_PHENOMENA__PHENOMENA;
const SELECTED_ANALYSIS_RESULT_KEY = SharedSelectionKeys.VIEW_PHENOMENA__RESULT;

const ViewPhenomenaComponent: React.FC<AllProps> = (props: AllProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {t} = useTranslation();

  const {[PHENOMENA_ID_PATH_PARAM]: phenomenaId} = useParams<any>();
  // Selectors
  const phenomena = useSelector(state => getSingleSelectedSelector(SELECTED_PHENOMENA_KEY, state));
  const analysisResult = useSelector(state =>
    getSingleSelectedSelector(SELECTED_ANALYSIS_RESULT_KEY, state)
  );
  const isLoadingPhenomena = useSelector(state =>
    getReducedLoadingStateSelector(SELECTED_PHENOMENA_KEY)(state)
  );
  const isLoadingResult = useSelector(state =>
    getReducedLoadingStateSelector(SELECTED_ANALYSIS_RESULT_KEY)(state)
  );
  const error = useSelector(state =>
    getErrorSelector(SELECTED_ANALYSIS_RESULT_KEY, SELECTED_PHENOMENA_KEY)(state)
  );
  // Effects
  useEffect(() => {
    dispatch(
      createSelected({
        selectedKey: SELECTED_PHENOMENA_KEY,
        actionKey: SELECTED_PHENOMENA_KEY,
        request: getPhenomenaByIdNetworkRequest,
        modelKey: ModelKey.PHENOMENA,
      })
    );
    dispatch(
      createSelected({
        selectedKey: SELECTED_ANALYSIS_RESULT_KEY,
        actionKey: SELECTED_ANALYSIS_RESULT_KEY,
        request: getAnalysisResultNetworkRequest,
        modelKey: ModelKey.ANALYSIS_RESULT,
      })
    );
    // clean up
    return () => {
      dispatch(removeSelected(SELECTED_PHENOMENA_KEY));
      dispatch(removeSelected(SELECTED_ANALYSIS_RESULT_KEY));
    };
  }, [dispatch]);
  useEffect(() => {
    // get analysis
    phenomenaId && dispatch(getSelected(SELECTED_PHENOMENA_KEY, phenomenaId));
  }, [phenomenaId, dispatch]);
  useEffect(() => {
    // When analysis result id changes we need to get it
    phenomena?.analysisResultId &&
      dispatch(getSelected(SELECTED_ANALYSIS_RESULT_KEY, phenomena?.analysisResultId));
  }, [phenomena?.analysisResultId, dispatch]);

  if (isLoadingPhenomena || !phenomena) {
    return <GenericLoading />;
  }

  return (
    <PageLayout.Layout>
      <PhenomenaHeader phenomena={phenomena} analysisResult={analysisResult} />
      <PageLayout.Body isLoading={isLoadingResult} noPadding>
        {phenomena && !analysisResult && !error && !isLoadingResult && (
          <div className={classes.EmptyStateWrapper}>
            <EmptyState
              title={t(TransKeys.VIEW_ANALYSIS.WAITING_FOR_RESULTS.TITLE)}
              subTitle={t(TransKeys.VIEW_ANALYSIS.WAITING_FOR_RESULTS.SUB_TITLE)}
              buttonText={t(TransKeys.GENERAL.ACTIONS.BACK)}
              onClick={_ => history.goBack()}
            />
          </div>
        )}
        {!isEmpty(error) && (
          <div className={classes.EmptyStateWrapper}>
            <EmptyState
              title={t(TransKeys.VIEW_ANALYSIS.EMPTY_STATE_404.TITLE)}
              subTitle={t(TransKeys.VIEW_ANALYSIS.EMPTY_STATE_404.SUB_TITLE)}
              buttonText={t(TransKeys.GENERAL.ACTIONS.BACK)}
              onClick={_ => history.goBack()}
            />
          </div>
        )}
        {analysisResult && (
          <AnalysisResults
            analysisResult={analysisResult}
            phenomena={phenomena}
            documentId={phenomena.rootDocumentId}
            isLoading={isLoadingResult}
            navigationDisabled
          />
        )}
      </PageLayout.Body>
    </PageLayout.Layout>
  );
};

const ViewPhenomena = composition<OwnProps>(ViewPhenomenaComponent);

export default ViewPhenomena;

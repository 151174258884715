import * as React from 'react';
import {useCallback, useMemo} from 'react';
import {
  Button,
  FancyHeader,
  ModalLayout,
  FoldersIcon,
  LabelWrapper,
  TextInput,
} from 'ui-components';
import classes from './analysis-folder-form-panel.module.scss';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../constants/translation-keys';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {useDispatch, useSelector} from 'react-redux';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors';
import {sharedClasses} from '../../../shared';
import {yupResolver} from '@hookform/resolvers/yup';
import {ActionKey} from '../../../../constants/action-key';
import {composition, OnSuccessActionHook} from 'front-core';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {TextareaFormInput} from '../../../shared/form/components/shared-form-input.component';
import {preventSubmitOnEnter} from '../../../../utils/general.utils';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {AnalysisFolder} from '../../../../objects/models/analysis-folder.model';
import {analysisFolderDTOValidator} from '../../../../objects/dto/analysis-folder.dto';
import {getAnalysisFolderNetworkRequest} from '../../../../http/analysis-folders.network-requests';
import {ANALYSIS_FOLDER_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {
  createAnalysisFolder,
  updateAnalysisFolder,
} from '../../../../store/analysis-folders/analysis-folders.actions';
import {useCurrentUser} from '../../../../core/hooks/use-user.hook';
import {FormLabelInput} from '../../../shared/form/components/form-label-input.component';
import {AnalysisFolderSmartSelector} from '../../../shared/core/smart-selector/analysis-folder-smart-selector.component';
import {IconPicker} from '../../../shared/components/general/emoji-picker/emoji-picker.component';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {DEFAULT_FOLDER_ICON} from '../../pages/analyses-main/analysis-main.types';
import {withDisableDemoProduct} from '../../../../core/hoc/with-disable-demo-product.hoc';

interface OwnProps {
  analysisFolder?: AnalysisFolder;
  data: Partial<AnalysisFolder>;
  onSubmit?: (data: Partial<AnalysisFolder>) => void;
  onSuccess?: OnSuccessActionHook;
  onClose?: () => void;
  [ANALYSIS_FOLDER_ID_PATH_PARAM]?: number;
  parentId?: number;
  disabled?: boolean;
}

type AllProps = OwnProps;

const createEmptyAnalysisFolder = (): Partial<AnalysisFolder> => ({
  name: '',
  shortDescription: '',
  parentId: null,
});

const SELECTED_ANALYSIS_FOLDER_KEY = SharedSelectionKeys.ANALYSIS_FOLDER_FORM__ANALYSIS_FOLDER;

const AnalysisFolderFormPanelComponent: React.FC<AllProps> = (props: AllProps) => {
  const {
    analysisFolder = {} as any,
    data,
    onClose,
    onSubmit: onSubmitFromProps,
    onSuccess: onSuccessFromProps,
    disabled,
    parentId,
  } = props;
  const notify = useAmplitude();
  const user = useCurrentUser();
  const {rootPublicAnalysisFolder} = useProductData();
  const formMethods = useForm({
    defaultValues: {
      ...data,
      parentId: parentId || rootPublicAnalysisFolder,
      ...analysisFolder,
    } as any,
    resolver: yupResolver(analysisFolderDTOValidator.noUnknown()),
  });
  const {handleSubmit, watch, control} = formMethods;
  const folderId = watch('id');
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(
      ActionKey.CREATE_ANALYSIS_FOLDER,
      ActionKey.UPDATE_ANALYSIS_FOLDER
    )(state)
  );
  const isDisabled = useMemo(() => isLoading || disabled, [isLoading, disabled]);
  const editMode = Boolean(watch('id'));
  const onSubmit = useCallback(
    data => {
      if (onSubmitFromProps) {
        onSubmitFromProps(data);
        return;
      }
      const onSuccess = (res, action) => {
        onSuccessFromProps && onSuccessFromProps(res, action);
        onClose();
      };
      const action = editMode
        ? updateAnalysisFolder(data, onSuccess)
        : createAnalysisFolder(data, onSuccess);
      dispatch(action);
      notify(AmplitudeEvent.ANALYSIS_FOLDER_SAVE_CLICKED, {
        userId: user.id,
      });
    },
    [dispatch, editMode, onSubmitFromProps, onSuccessFromProps, onClose, user, notify]
  );
  const locationSelectorConfig = useMemo(() => {
    if (folderId) {
      return {
        excludeId: [folderId],
      };
    }
    return {};
  }, [folderId]);

  return (
    <div className={classes.AnalysisFolderFormPanelContainer}>
      <ModalLayout
        footer={
          <Button disabled={isDisabled} onClick={handleSubmit(onSubmit)}>
            {t(TransKeys.GENERAL.ACTIONS.SAVE)}
          </Button>
        }
      >
        {isLoading && <GenericLoading />}
        <div className={classes.AnalysisFolderFormPanel}>
          <FancyHeader
            icon={FoldersIcon}
            title={t(
              editMode
                ? TransKeys.ANALYSIS_FOLDER_FORM.TITLE_EDIT
                : TransKeys.ANALYSIS_FOLDER_FORM.TITLE_CREATE
            )}
            subTitle={t(TransKeys.ANALYSIS_FOLDER_FORM.MAIN_TITLE)}
            onClose={onClose}
            className={classes.Header}
          />

          <FormProvider {...formMethods}>
            <form
              className={sharedClasses.Form}
              onKeyDown={preventSubmitOnEnter}
              onSubmit={e => {
                e.stopPropagation();
                handleSubmit(onSubmit)(e);
              }}
            >
              <div className={sharedClasses.FormContent}>
                <div className={sharedClasses.Block}>
                  <div className={sharedClasses.Input}>
                    <LabelWrapper
                      label={t(TransKeys.ANALYSIS_FOLDER_FORM.INPUTS.NAME.LABEL)}
                      required
                    >
                      <div className={classes.NameWrapper}>
                        <Controller
                          render={({field}) => (
                            <IconPicker
                              className={classes.IconPicker}
                              value={field.value}
                              onChange={field.onChange}
                              placeholderIcon={DEFAULT_FOLDER_ICON}
                            />
                          )}
                          name={'icon'}
                          control={control}
                        />
                        <Controller
                          render={({field, fieldState: {error}}) => (
                            <TextInput
                              placeholder={t(
                                TransKeys.ANALYSIS_FOLDER_FORM.INPUTS.NAME.PLACEHOLDER
                              )}
                              value={field.value}
                              onChange={field.onChange}
                              error={Boolean(error)}
                              required
                            />
                          )}
                          name={'name'}
                          control={control}
                        />
                      </div>
                    </LabelWrapper>
                  </div>
                </div>
                <div className={sharedClasses.Block}>
                  <div className={sharedClasses.Input}>
                    <TextareaFormInput
                      placeholder={t(TransKeys.ANALYSIS_FOLDER_FORM.INPUTS.DESCRIPTION.PLACEHOLDER)}
                      label={t(TransKeys.ANALYSIS_FOLDER_FORM.INPUTS.DESCRIPTION.LABEL)}
                      name={'shortDescription'}
                      disabled={disabled}
                    />
                  </div>
                </div>
                <FormLabelInput
                  label={t(TransKeys.ANALYSIS_FOLDER_FORM.INPUTS.LOCATION.LABEL)}
                  name={'parentId'}
                  render={field => (
                    <AnalysisFolderSmartSelector
                      value={field.value}
                      placeholder={t(TransKeys.ANALYSIS_FOLDER_FORM.INPUTS.LOCATION.PLACEHOLDER)}
                      onChange={field.onChange}
                      excludeId={locationSelectorConfig.excludeId}
                    />
                  )}
                />
              </div>
            </form>
          </FormProvider>
        </div>
      </ModalLayout>
    </div>
  );
};

AnalysisFolderFormPanelComponent.defaultProps = {
  data: createEmptyAnalysisFolder(),
};

const AnalysisFolderFormPanel = composition<AllProps>(
  AnalysisFolderFormPanelComponent,
  withDisableDemoProduct,
  withLoadBefore({
    analysisFolder: {
      selectedKey: SELECTED_ANALYSIS_FOLDER_KEY,
      actionKey: SELECTED_ANALYSIS_FOLDER_KEY,
      request: getAnalysisFolderNetworkRequest,
      mapPayloadFromProps: props => props[ANALYSIS_FOLDER_ID_PATH_PARAM],
      shouldCall: props => props[ANALYSIS_FOLDER_ID_PATH_PARAM] !== undefined,
    },
  })
);

export default AnalysisFolderFormPanel;

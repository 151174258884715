import {NRC} from './index';
import {RequestType, toCamelCase, toSnakeCase} from 'front-core';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';
import {FunnelDTO} from '../objects/dto/funnel.dto';
import {ExperimentAutomationDTO} from '../objects/dto/experiment-automation.dto';

export const getExperimentAutomationNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/experiment-automations/${id}`,
  responseTransformer: res =>
    toCamelCase(res.data, [
      'key_signal_definition',
      'variant_signal_definition',
      'population_filter',
    ]),
});

export const getExperimentAutomationsNetworkRequest: NRC<void, any> = query => ({
  method: RequestType.GET,
  relativeUrl: `/app/experiment-automations`,
  query: query,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data, ['population_filter']),
});

export const createExperimentAutomationNetworkRequest: NRC<ExperimentAutomationDTO, void> = (
  data: FunnelDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/experiment-automations`,
  body: data,
  bodyTransformer: data =>
    toSnakeCase(data, ['key_signal_definition', 'variant_signal_definition', 'population_filter']),
  responseTransformer: res =>
    toCamelCase(res.data, [
      'key_signal_definition',
      'variant_signal_definition',
      'population_filter',
    ]),
});

export const editExperimentAutomationNetworkRequest: NRC<ExperimentAutomationDTO, void> = (
  data: ExperimentAutomationDTO
) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/experiment-automations/${data.id}`,
  body: data,
  bodyTransformer: data =>
    toSnakeCase(data, ['key_signal_definition', 'variant_signal_definition', 'population_filter']),
  responseTransformer: res =>
    toCamelCase(res.data, [
      'key_signal_definition',
      'variant_signal_definition',
      'population_filter',
    ]),
});

export const deleteExperimentAutomationNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.DELETE,
  relativeUrl: `/app/experiment-automations/${id}`,
  responseTransformer: toCamelCase,
});

import * as React from 'react';
import {useMemo} from 'react';
import {Redirect, Switch} from 'react-router';
import {StringParam, useQueryParam} from 'use-query-params';
import Login from './components/login/login.component';
import ForgotPasswordForm from './components/forgot-password/forgot-password-form.component';
import SetPasswordForm from './components/set-password/set-password-form.component';
import Core from '../core/core.component';
import {Guard} from 'front-core';
import {AppRoutes} from '../../constants/app-routes';
import {Route, useLocation} from 'react-router-dom';
import SignUpForm from './components/sign-up/sign-up-form.component';
import InviteSignupForm from './components/invite-signup/invite-signup-form.component';
import {useCurrentUser} from '../../core/hooks/use-user.hook';

const AppRouting: React.FC<{}> = (props: {}) => {
  const user = useCurrentUser();
  const [token] = useQueryParam('token', StringParam);
  const tokenExists = useMemo(() => Boolean(token), [token]);
  const location = useLocation();
  const redirectToLoginPath = useMemo(() => {
    if (!location.search) {
      return '/login';
    }
    return `/login?redirectTo=${location.pathname}${location.search}`;
  }, [location]);

  return (
    <Switch>
      <Route key={'requestAccess'} path={AppRoutes.signUp().pathname}>
        <Guard isActive={true} onNotActive={() => <Redirect to={'/'} />}>
          <SignUpForm />
        </Guard>
      </Route>
      <Route key={'forgotPassword'} path={AppRoutes.forgotPassword().pathname}>
        <Guard isActive={true} onNotActive={() => <Redirect to={'/'} />}>
          <ForgotPasswordForm />
        </Guard>
      </Route>
      <Route key={'resetPassword'} path={AppRoutes.resetPassword().pathname}>
        <Guard isActive={!Boolean(user) && tokenExists} onNotActive={() => <Redirect to={'/'} />}>
          <SetPasswordForm />
        </Guard>
      </Route>
      <Route key={'setPassword'} path={AppRoutes.setPassword().pathname}>
        <Guard isActive={!Boolean(user) && tokenExists} onNotActive={() => <Redirect to={'/'} />}>
          <SetPasswordForm />
        </Guard>
      </Route>
      <Route key={'signUp'} path={AppRoutes.signup().pathname}>
        <Guard isActive={!Boolean(user) && tokenExists} onNotActive={() => <Redirect to={'/'} />}>
          <InviteSignupForm />
        </Guard>
      </Route>
      <Route key={'login'} path={AppRoutes.login().pathname}>
        <Guard isActive={user === undefined} onNotActive={() => <Redirect to={'/'} />}>
          <Login />
        </Guard>
      </Route>
      <Route key={'core'} path={AppRoutes.root().pathname}>
        <Guard isActive={Boolean(user)} onNotActive={() => <Redirect to={redirectToLoginPath} />}>
          <Core />
        </Guard>
      </Route>
    </Switch>
  );
};

export default AppRouting;

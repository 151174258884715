import {HistoryItem} from '../objects/models/historify-model.model';
import {TIME_FORMATS} from '../constants/time-formats';
import moment from 'moment';
import {get} from 'lodash';

export const generateLastModified = (history: HistoryItem, timeAgo?: boolean) => {
  if (!history) {
    return;
  }
  let date: any = moment.utc(history.date).local();
  if (timeAgo) {
    date = date.fromNow();
  } else {
    date = date.format(TIME_FORMATS.READABLE_DATE);
  }

  return {
    date,
    user: get(history, 'user.fullName'),
  };
};

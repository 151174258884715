import {OrCondition, SqlElement, SqlElementType} from 'ui-components';
import {cloneDeep, get} from 'lodash';
import {METADATA_KEY, PARAMETERS_METADATA_KEY} from '../constants/parameters-saved-keys';
import {exists, ordinalSuffix} from 'front-core';
import {retentionQueryMapper} from '../modules/shared/core/query-builders/retention-builder/retention-query-builder.component';

export class SqlElementToTextUtils {
  private static translateRetentionQuery(query: SqlElement): string {
    const {actions_retention_unit, actions_retention_n} = retentionQueryMapper(query);
    return `${ordinalSuffix(actions_retention_n)} ${actions_retention_unit} retention`;
  }

  private static translatePaymentRetentionQuery(query: SqlElement): string {
    return 'Payment retention';
  }

  private static translateConversionQuery(query: SqlElement): string {
    const conversion = get(query, 'parameters.0');
    return get(conversion, `${PARAMETERS_METADATA_KEY}.${METADATA_KEY.DISPLAY_NAME_KEY}`);
  }

  private static translateTemplateItemQuery(query: SqlElement): string {
    return get(query, `${PARAMETERS_METADATA_KEY}.${METADATA_KEY.DISPLAY_NAME_KEY}`);
  }

  private static translatePopulationSegmentQuery(query: OrCondition): string {
    const segmentGroups = new Set();
    const queue: any[] = cloneDeep(query.conditions);

    for (const i of queue) {
      if (i.type === SqlElementType.AND_CONDITION || i.type === SqlElementType.OR_CONDITION) {
        queue.push(...i.conditions);
        continue;
      }
      if (i.type === SqlElementType.CONDITION) {
        i.left?.signal_id && segmentGroups.add(i.left.signal_id);
      }
    }

    return `${segmentGroups.size} segment groups`;
  }

  static translate(query: SqlElement): string {
    const uiParameters = get(query, PARAMETERS_METADATA_KEY, {});
    const builderName = uiParameters[METADATA_KEY.BUILDER_COMPONENT_NAME_KEY];
    if (!exists(query)) {
      return '';
    }

    switch (builderName) {
      case 'RetentionQueryBuilder':
        return SqlElementToTextUtils.translateRetentionQuery(query);
      case 'PaymentRetentionQueryBuilder':
        return SqlElementToTextUtils.translatePaymentRetentionQuery(query);
      case 'ConversionQueryBuilder':
        return SqlElementToTextUtils.translateConversionQuery(query);
      case 'PopulationSegmentQueryBuilder':
        return SqlElementToTextUtils.translatePopulationSegmentQuery(query as OrCondition);
      case 'TemplateItemQueryBuilder':
        return SqlElementToTextUtils.translateTemplateItemQuery(query);
    }
  }
}

import classes from './experiment-empty-state.module.scss';
import {AnalysisTypeCard} from '../../../analyses/components/analysis-type-card/analysis-type-card.component';
import {AnalysisType} from '../../../../objects/models/analysis-type.model';
import TransKeys from '../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import * as React from 'react';

interface OwnProps {
  analysisTypes: AnalysisType[];
  onSelect: (analysisTypeId: number) => void;
}

type AllProps = OwnProps;

const ExperimentEmptyState: React.FC<AllProps> = (props: AllProps) => {
  const {analysisTypes, onSelect} = props;
  const {t} = useTranslation();
  return (
    <div className={classes.EmptyState}>
      <div className={classes.Title}>{t(TransKeys.EXPERIMENTS.NO_EXPERIMENTS.TITLE)}</div>
      <div className={classes.SubTitle}>{t(TransKeys.EXPERIMENTS.NO_EXPERIMENTS.SUB_TITLE)}</div>
      <div className={classes.ExperimentAnalyses}>
        {analysisTypes
          .map(a => (
            <AnalysisTypeCard
              className={classes.Card}
              key={a.id}
              analysisType={a}
              onClick={() => onSelect(a.id)}
            />
          ))
          .reverse()}
      </div>
    </div>
  );
};

export default ExperimentEmptyState;

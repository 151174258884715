import classes from './layout.module.scss';

interface OwnProps {
  className?: string;
  children?: React.ReactNode;
}

type AllProps = OwnProps;

export const Layout: React.FC<AllProps> = (props: AllProps) => {
  const {children} = props;

  return <div className={classes.Layout}>{children}</div>;
};

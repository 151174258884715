import {useCallback} from 'react';
import {AnalysisResult} from '../../../objects/models/analysis-result.model';
import {AnalysisTypeId} from '../../../constants/analysis-type-id';
import {PendoGuides, triggerPendoGuide} from '../../../config/pendo.config';

export function useAnalysisGuides() {
  return useCallback((analysisResult: AnalysisResult) => {
    if (
      analysisResult?.isExample &&
      analysisResult?.analysisTypeId === AnalysisTypeId.GOAL_DRIVERS
    ) {
      return triggerPendoGuide(PendoGuides.EXAMPLE_GOAL_DRIVERS);
    }
  }, []);
}

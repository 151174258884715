import * as React from 'react';
import {useCallback, useContext, useMemo, useState} from 'react';
import classNames from 'classnames';
import classes from './app-notifications.module.scss';
import {Badge, CloseIcon, DialogLayout, NotificationsMenuIcon} from 'ui-components';
import {sharedClasses} from '../../../shared';
import {Popover} from '@material-ui/core';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import navigatorClasses from '../app-navigator/app-navigator.module.scss';
import {useDispatch, useSelector} from 'react-redux';
import {NOTIFICATIONS_STORE_KEY} from '../../../../store/notifications/notifications.store';
import {last, values} from 'lodash';
import {
  Notification,
  NotificationJobStatus,
  NotificationType,
} from '../../../../objects/models/notification.model';
import moment from 'moment';
import {TIME_FORMATS} from '../../../../constants/time-formats';
import {NotificationItem} from './notification-item/notification-item.component';
import {
  loadNotifications,
  markNotificationAsRead,
  markNotificationsAsRead,
  markNotificationsAsSeen,
} from '../../../../store/notifications/notifications.actions';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {ImagesResource} from '../../../../assets/images';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors';
import {ActionKey} from '../../../../constants/action-key';
import {
  NotificationActionType,
  NotificationClassesFactory,
} from './notification-item/notification-classes';
import {NavLink} from 'react-router-dom';

interface OwnProps {}

type AllProps = OwnProps;

export const AppNotifications: React.FC<AllProps> = (props: AllProps) => {
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {openSecondaryPanel} = useContext(PanelsContext);
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(ActionKey.LOAD_NOTIFICATIONS)(state)
  );

  const onClose = useCallback(() => {
    setNotificationsAnchorEl(null);
  }, [setNotificationsAnchorEl]);
  const onNotificationsClicked = useCallback(
    e => {
      setNotificationsAnchorEl(e.currentTarget);
      dispatch(markNotificationsAsSeen());
    },
    [dispatch, setNotificationsAnchorEl]
  );
  const notificationsData: {
    notifications: Notification[];
    hasNext: boolean;
  } = useSelector(state => {
    const data = state[NOTIFICATIONS_STORE_KEY];
    return {
      ...data,
      notifications: values(data.notifications)
        .sort(
          (a: Notification, b: Notification) =>
            moment.utc(b.createdOn, TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT).valueOf() -
            moment.utc(a.createdOn, TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT).valueOf()
        )
        .map((notification: Notification) => {
          // delay analysis result failed notification
          if (
            notification.type === NotificationType.RUN_ANALYSIS &&
            notification.jobStatus === NotificationJobStatus.FAILED
          ) {
            const delayFailure = moment(notification.updatedOn).add(24, 'h').isAfter(moment());
            if (delayFailure) {
              return {...notification, jobStatus: NotificationJobStatus.IN_PROGRESS};
            }
          }
          return notification;
        }),
    };
  });
  const {notifications, hasNext} = notificationsData;
  const count = useMemo(() => {
    const c = notifications.filter(n => !n.isSeen).length;
    if (c > 99) {
      return '99+';
    }
    return c;
  }, [notifications]);
  const lastId = useMemo(() => last(notifications)?.id, [notifications]);
  const onLoadMore = useCallback(() => dispatch(loadNotifications(lastId)), [dispatch, lastId]);
  const onMarkAllAsRead = useCallback(() => dispatch(markNotificationsAsRead()), [dispatch]);
  const onNotificationViewed = useCallback(
    (notification: Notification) => dispatch(markNotificationAsRead(notification.id)),
    [dispatch]
  );
  const renderNotification = notification => {
    const notificationClass = NotificationClassesFactory.create(notification);
    const notificationAction = notificationClass.getAction();

    const props_ = {
      notification,
      className: classes.Notification,
    };
    let render = null;
    const notificationActionType = notificationAction?.type;

    if (notificationActionType === NotificationActionType.REDIRECT) {
      render = (
        <NavLink className={sharedClasses.UnstyledLink} to={notificationAction.payload}>
          <NotificationItem {...props_} onClick={() => onNotificationViewed(notification)} />
        </NavLink>
      );
    } else if (notificationActionType === NotificationActionType.PANEL) {
      render = (
        <NotificationItem
          {...props_}
          onClick={() => {
            onNotificationViewed(notification);
            openSecondaryPanel(
              notificationAction.payload.key,
              notificationAction.payload.parameters
            );
          }}
        />
      );
    } else {
      render = <NotificationItem {...props_} onClick={() => onNotificationViewed(notification)} />;
    }

    return <React.Fragment key={notification.id}>{render}</React.Fragment>;
  };

  return (
    <>
      {/* Button */}
      <div
        className={classNames(
          navigatorClasses.NavItem,
          notificationsAnchorEl && navigatorClasses.Active
        )}
        onClick={onNotificationsClicked}
      >
        <Badge badgeContent={count || 0}>
          <NotificationsMenuIcon className={navigatorClasses.Icon} />
        </Badge>
        <span className={navigatorClasses.Label}>{t(TransKeys.NAVIGATOR.NOTIFICATIONS)}</span>
      </div>
      {/* Notifications Popover */}
      <Popover
        classes={{
          paper: sharedClasses.BlankPaper,
        }}
        open={Boolean(notificationsAnchorEl)}
        anchorEl={notificationsAnchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <DialogLayout className={classNames(classes.AppNotifications)}>
          <div className={classes.Header}>
            <div className={classes.Title}>Notifications</div>
            <div className={classes.Actions}>
              {notifications.length > 0 && (
                <span className={classes.TextButton} onClick={onMarkAllAsRead}>
                  Mark all as read
                </span>
              )}
              <div className={classes.Close} onClick={onClose}>
                <CloseIcon className={classes.CloseIcon} />
              </div>
            </div>
          </div>
          <div className={classes.List}>
            {notifications.map(renderNotification)}
            {notifications.length === 0 && (
              <div className={classes.EmptyState}>
                <img src={ImagesResource.app.telescopeV2} alt={''} />
                <div>There are no notifications</div>
              </div>
            )}
            {notifications.length > 0 && (
              <div
                onClick={hasNext ? onLoadMore : undefined}
                className={classNames(classes.LoadMore, !hasNext && classes.Disabled)}
              >
                {!isLoading && (
                  <span>
                    {hasNext ? t(TransKeys.GENERAL.ACTIONS.LOAD_MORE) : 'No more notifications'}
                  </span>
                )}
                {isLoading && <span>{t(TransKeys.GENERAL.LABELS.LOADING)}</span>}
              </div>
            )}
          </div>
        </DialogLayout>
      </Popover>
    </>
  );
};

import classes from './pagination.module.scss';
import classNames from 'classnames';
import {min} from 'lodash';

type ExperimentsPaginationProps = {
  total: number;
  isLoading?: boolean;
  page: number;
  perPage: number;
  onPageChange: (newPageNumber: number) => void;
};

export const Pagination = (props: ExperimentsPaginationProps) => {
  const {page, total, perPage, isLoading, onPageChange} = props;

  const prevDisabled = page <= 0;
  const nextDisabled = page + 1 >= total / perPage;

  return (
    total > 0 && (
      <div className={classes.PaginationContainer}>
        <div className={classNames(classes.Pagination, isLoading && classes.Loading)}>
          <div className={classes.Status}>{`${1 + page * perPage}-${min([
            (page + 1) * perPage,
            total,
          ])} of ${total}`}</div>
          <div className={classes.Navigation}>
            <span
              onClick={e => !isLoading && !prevDisabled && onPageChange(page - 1)}
              className={classNames(classes.Button, prevDisabled && classes.Disabled)}
            >
              {'<'}
            </span>
            <span
              onClick={e => !isLoading && !nextDisabled && onPageChange(page + 1)}
              className={classNames(classes.Button, nextDisabled && classes.Disabled)}
            >
              {'>'}
            </span>
          </div>
        </div>
      </div>
    )
  );
};

import {BaseModel} from './base.model';
import {HistorifyModel} from './historify-model.model';
import {TableEntity} from './table.model';
import {ValidationStatus} from './signal.model';

export enum MilestoneType {
  // This is the generic milestone type - we use it to tag all signals that are milestones
  LIFE_CYCLE = 'life_cycle',
  ACTIVE = 'active',
  JOIN_DATE = 'join_date',
  TRIAL_START = 'trial_start',
  ERROR = 'error',
  PAYMENT = 'payment',
  FIRST_PAYMENT = 'first_payment',
  PAYMENT_FAILURE = 'payment_failure',
  CHURN = 'churn',
  PLAN = 'plan',
  EVENT = 'event',
  CUSTOM = 'custom',
}

export const EDITABLE_MILESTONE_TYPES = [
  MilestoneType.ACTIVE,
  MilestoneType.JOIN_DATE,
  MilestoneType.TRIAL_START,
  MilestoneType.ERROR,
  MilestoneType.PAYMENT,
  MilestoneType.FIRST_PAYMENT,
  MilestoneType.PAYMENT_FAILURE,
  MilestoneType.CHURN,
  MilestoneType.CUSTOM,
];

export interface Milestone extends BaseModel, HistorifyModel {
  name: string;
  productId: number;
  signalId: number;
  type: MilestoneType;
  entity: TableEntity;
  shortDescription: string;
  signalDefinition: any;
  signalValidationStatus?: ValidationStatus;
}

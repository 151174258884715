import {StoreDefinition, StorePropertyType} from 'front-core';
import {UserOnboardingActionsType} from './user-onboarding.actions';
import {setDrawerCollapseReducer, setResettingOnboardingReducer} from './user-onboarding.reducers';

export const USER_ONBOARDING_STORE_KEY = 'USER_ONBOARDING_STORE';

export const userOnboardingStore: StoreDefinition = {
  key: USER_ONBOARDING_STORE_KEY,
  properties: {
    data: {
      propertyType: StorePropertyType.OBJECT,
      defaultValue: {},
    },
    drawerCollapsed: {
      propertyType: StorePropertyType.BOOLEAN,
      defaultValue: true,
    },
    resettingOnboarding: {
      propertyType: StorePropertyType.BOOLEAN,
      defaultValue: false,
    },
    showConfetti: {
      propertyType: StorePropertyType.BOOLEAN,
      defaultValue: false,
    },
    selectSimpleAnalysisPickerPanelData: {
      propertyType: StorePropertyType.OBJECT,
      defaultValue: {},
    },
  },
  reducer: {
    [UserOnboardingActionsType.SET_DRAWER_COLLAPSE]: setDrawerCollapseReducer,
    [UserOnboardingActionsType.RESET_USER_ONBOARDING]: setResettingOnboardingReducer,
  },
};

import {StoreDefinition, StorePropertyType} from 'front-core';
import {RemoteListActionType} from './remote-list.actions';
import {
  createRemoteListReducer,
  modelChanged,
  modelDeleted,
  removeRemoteListReducer,
  updateListSuccessReducer,
} from './remote-lists.reducers';
import {CoreActionsType} from '../core/core.actions';

export const REMOTE_LISTS_STORE_KEY = 'REMOTE_LISTS_STORE';

export const remoteListsStore: StoreDefinition = {
  key: REMOTE_LISTS_STORE_KEY,
  properties: {
    lists: {
      propertyType: StorePropertyType.OBJECT,
      defaultValue: {},
    },
  },
  reducer: {
    [RemoteListActionType.CREATE_LIST]: createRemoteListReducer,
    [RemoteListActionType.REMOVE_LIST]: removeRemoteListReducer,
    [RemoteListActionType.__REMOTE_LIST_REQUEST_COMPLETED]: updateListSuccessReducer,
    [CoreActionsType.MODEL_UPDATED]: modelChanged,
    [CoreActionsType.MODEL_DELETED]: modelDeleted,
  },
};

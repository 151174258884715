export enum ChartMode {
  INCOMPLETE = 'incomplete',
  PREDICTED = 'predicted',
}

export interface MetricGoalAtTime {
  datetime: string;
  value: number;
  matchingSampleValue?: number;
}

export interface ChartConfig {
  showCI: boolean;
  showAnnotations: boolean;
  showQuarters: boolean;
  showTarget: boolean;
}

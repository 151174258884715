import * as React from 'react';
import {Feedback} from '../../../../../../../../objects/models/feedback.model';
import classes from './feedback-form-panel.module.scss';
import {Button, FancyHeader, ModalLayout} from 'ui-components';
import TransKeys from '../../../../../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useCallback, useState} from 'react';
import {getReducedLoadingStateSelector} from '../../../../../../../../store/store.selectors';
import {ActionKey} from '../../../../../../../../constants/action-key';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {feedbackDTOValidator} from '../../../../../../../../objects/dto/feedback.dto';
import {createFeedback} from '../../../../../../../../store/feedbacks/feedbacks.actions';
import {GenericLoading} from '../../../../../../components/general/generic-loading/generic-loading.component';
import {sharedClasses} from '../../../../../../index';
import {preventSubmitOnEnter} from '../../../../../../../../utils/general.utils';
import {TextareaFormInput} from '../../../../../../form/components/shared-form-input.component';
import classnames from 'classnames';
import {OnSuccessActionHook} from 'front-core';

interface OwnProps {
  onClose?: () => void;
  feedback: Partial<Feedback>;
  onSuccess?: OnSuccessActionHook;
}

const FeedbackFormPanel: React.FC<OwnProps> = (props: OwnProps) => {
  const {feedback: feedbackFromProps, onClose: onCloseFromProps, onSuccess} = props;
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(ActionKey.CREATE_FEEDBACK)(state)
  );
  const [showThankYouScreen, setShowThankYouScreen] = useState(false);
  const formMethods = useForm({
    defaultValues: feedbackFromProps,
    resolver: yupResolver(feedbackDTOValidator),
  });
  const {handleSubmit} = formMethods;

  const onSubmit = useCallback(
    feedbackData => {
      const onActionSuccess = (res, action) => {
        onSuccess && onSuccess(res, action);
        setShowThankYouScreen(true);
      };
      dispatch(createFeedback(feedbackData, onActionSuccess));
    },
    [dispatch, onSuccess, setShowThankYouScreen]
  );
  const onClose = useCallback(() => {
    onCloseFromProps();
    setShowThankYouScreen(false);
  }, [onCloseFromProps, setShowThankYouScreen]);

  if (showThankYouScreen) {
    return (
      <div className={classnames(classes.FeedbackContainer, classes.ThankYou)}>
        <ModalLayout
          footer={
            <Button onClick={onClose}>
              {t(TransKeys.FEEDBACK_FORM.THANK_YOU.SCREEN.BUTTON_LABEL)}
            </Button>
          }
        >
          <div className={classes.FeedbackThankYou}>
            <div className={classes.Emoji}>{t(TransKeys.FEEDBACK_FORM.THANK_YOU.SCREEN.EMOJI)}</div>
            <div className={classes.Message}>
              {t(TransKeys.FEEDBACK_FORM.THANK_YOU.SCREEN.MESSAGE)}
            </div>
          </div>
        </ModalLayout>
      </div>
    );
  }

  return (
    <div className={classes.FeedbackContainer}>
      <ModalLayout
        footer={
          <div className={classes.FeedbackFooter}>
            <Button variant={'outlined'} onClick={handleSubmit(onSubmit)} disabled={isLoading}>
              {t(TransKeys.GENERAL.ACTIONS.SKIP)}
            </Button>
            <Button disabled={isLoading} onClick={handleSubmit(onSubmit)}>
              {t(TransKeys.GENERAL.ACTIONS.SAVE)}
            </Button>
          </div>
        }
      >
        {isLoading && <GenericLoading />}
        <div className={classes.FeedbackContent}>
          <FancyHeader
            title={t(TransKeys.FEEDBACK_FORM.TITLE)}
            onClose={onClose}
            className={classes.FeedbackHeader}
          />
          <FormProvider {...formMethods}>
            <form
              className={sharedClasses.Form}
              onKeyDown={preventSubmitOnEnter}
              onSubmit={e => {
                e.stopPropagation();
                handleSubmit(onSubmit);
              }}
            >
              <div className={sharedClasses.FormContent}>
                <div className={sharedClasses.Block}>
                  <div className={sharedClasses.Input}>
                    <TextareaFormInput
                      placeholder={t(TransKeys.FEEDBACK_FORM.INPUTS.DESCRIPTION.PLACEHOLDER)}
                      label={''}
                      name={'description'}
                      rows={5}
                    />
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </ModalLayout>
    </div>
  );
};

FeedbackFormPanel.defaultProps = {
  onSuccess: () => [],
};
export default FeedbackFormPanel;

import {NRC} from './index';
import {RequestType, toCamelCase, toSnakeCase} from 'front-core';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';
import {
  AddSegmentToTeamDTO,
  SegmentDTO,
  UpdateFavoriteSegmentsDTO,
} from '../objects/dto/segment.dto';

const segmentTransformer = segment => ({
  ...segment,
  classes: segment.classes ? segment.classes.sort((a, b) => b.count - a.count) : [],
});

export const getSegmentsNetworkRequest: NRC<void, any> = (payload: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/segments`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: response => {
    // sorting classes by count
    const res = toCamelCase(response.data);
    try {
      res.data = res.data.map(segmentTransformer);
    } catch (e) {
      console.error(e);
    }
    return res;
  },
});

export const getSegmentsByCategoryNetworkRequest: NRC<void, any> = (payload: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/segments/by-category`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: response => {
    const res = toCamelCase(response.data);
    for (const category of res.categories) {
      try {
        category.segments = category.segments.map(segmentTransformer);
      } catch (e) {
        console.error(e);
      }
    }
    return res;
  },
});

export const searchSegmentsNetworkRequest: NRC<void, any> = (value: any, payload: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/segments`,
  query: {q: value, ...payload},
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data.data),
});

export const getSegmentNetworkRequest: NRC<void, any> = (id: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/segments/${id}`,
  responseTransformer: res => segmentTransformer(toCamelCase(res.data, ['signal_definition'])),
});

export const createSegmentNetworkRequest: NRC<SegmentDTO, void> = (data: SegmentDTO) => ({
  method: RequestType.POST,
  relativeUrl: `/app/segments`,
  body: data,
  bodyTransformer: data => toSnakeCase(data, ['signal_definition']),
  responseTransformer: res => toCamelCase(res.data, ['signal_definition']),
});

export const editSegmentNetworkRequest: NRC<SegmentDTO, void> = (data: SegmentDTO) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/segments/${data.id}`,
  body: data,
  bodyTransformer: data => toSnakeCase(data, ['signal_definition']),
  responseTransformer: res => toCamelCase(res.data, ['signal_definition']),
});

export const rescanSegmentNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.POST,
  relativeUrl: `/app/segments/${id}/rescan`,
  responseTransformer: toCamelCase,
});

export const deleteSegmentNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.DELETE,
  relativeUrl: `/app/segments/${id}`,
  responseTransformer: toCamelCase,
});

export const validateSegmentNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/segments/${id}/validate`,
  responseTransformer: res => toCamelCase(res.data, ['signal_definition']),
});

export const getFavoritesSegmentsNetworkRequest: NRC<void, void> = () => ({
  method: RequestType.GET,
  relativeUrl: `/app/segments/favorites`,
  responseTransformer: res => toCamelCase(res.data),
});

export const updateFavoritesSegmentsNetworkRequest: NRC<UpdateFavoriteSegmentsDTO, void> = (
  data: UpdateFavoriteSegmentsDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/segments/favorites`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const addSegmentToTeamNetworkRequest: NRC<AddSegmentToTeamDTO, void> = (
  data: AddSegmentToTeamDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/segments/add-to-team`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

import {AmplitudeEvent} from '../constants/amplitude-event';
import {ModelKey} from '../constants/model-key';
import {ModelDiscriminatorType, UserReactionType} from '../objects/models/user-reaction.model';
import {LocationDescriptorObject} from 'history';
import {getter} from '../store/store.utils';

export const userReactionTypeToKeyMap = {
  [UserReactionType.DISMISS]: 'isDismissed',
  [UserReactionType.EXPLORE]: 'isExplored',
  [UserReactionType.SNOOZE]: 'snoozeUntil',
  [UserReactionType.VIEW]: 'isViewed',
  [UserReactionType.USEFUL]: 'isUseful',
};

export const modelDiscriminatorTypeToModelKeyMap = {
  [ModelDiscriminatorType.OPPORTUNITY]: ModelKey.OPPORTUNITY,
  [ModelDiscriminatorType.ANALYSIS_RESULT]: ModelKey.ANALYSIS_RESULT,
  [ModelDiscriminatorType.PHENOMENA]: ModelKey.PHENOMENA,
  [ModelDiscriminatorType.ANALYSIS_FOLDER]: ModelKey.ANALYSIS_FOLDER,
};

export const userReactionToAmplitudeEvent = {
  [UserReactionType.DISMISS]: AmplitudeEvent.MODEL_DISMISSED,
  [UserReactionType.EXPLORE]: AmplitudeEvent.MODEL_EXPLORED,
  [UserReactionType.SNOOZE]: AmplitudeEvent.MODEL_SNOOZED,
  [UserReactionType.VIEW]: AmplitudeEvent.MODEL_VIEWED,
  [UserReactionType.USEFUL]: AmplitudeEvent.MODEL_MARKED_USEFUL,
};

export const generateLocationPayload = (location: LocationDescriptorObject = undefined) => {
  location = location || (getter('router', 'location') as LocationDescriptorObject);

  return {
    path: location.pathname,
    query: location.query,
    search: location.search,
    filters: location.query?.filters,
  };
};

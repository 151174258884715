import TransKeys from 'src/constants/translation-keys';
import {useTranslation} from 'react-i18next';
import classes from '../../settings.module.scss';
import {useCallback} from 'react';
import {Link} from 'ui-components';
import {useUserOnboarding} from '../../../../core/hooks/use-user-onboarding.hook';
import {UserOnboardingStatus} from '../../../../constants/user-onboarding';
import usePermissions from '../../../../core/hooks/use-permissions.hook';
import {useDispatch} from 'react-redux';
import {moveToResetPassword} from '../../../../store/auth/auth.actions';

interface OwnProps {}

type AllProps = OwnProps;

export const UserOther: React.FC<AllProps> = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {role} = usePermissions();
  const {status, isDismissed, setDismiss, setDrawerCollapsed, setResetting} = useUserOnboarding();

  const onboardingStatusLabel = !role
    ? t(TransKeys.USER_SETTINGS.ONBOARDING.STATUS.UNASSIGNED_PRODUCT.LABEL)
    : t(TransKeys.USER_SETTINGS.ONBOARDING.STATUS[status.toUpperCase()].LABEL);

  const onViewOnboarding = useCallback(() => {
    if (isDismissed) {
      setDismiss(false);
    }
    setDrawerCollapsed(false);
  }, [isDismissed, setDismiss, setDrawerCollapsed]);

  const onRestartOnboarding = useCallback(() => {
    setResetting();
  }, [setResetting]);

  const onDismissOnboarding = useCallback(() => {
    setDismiss();
  }, [setDismiss]);

  const resetPassword = useCallback(() => {
    dispatch(moveToResetPassword());
  }, [dispatch]);

  const renderOnboardingActions = useCallback(() => {
    if (!role) {
      return null;
    }

    switch (status) {
      case UserOnboardingStatus.NOT_STARTED:
        return (
          <>
            <Link onClick={onRestartOnboarding} inline underline={false}>
              Start
            </Link>{' '}
            the onboarding process.
          </>
        );
      case UserOnboardingStatus.COMPLETED:
        return (
          <>
            <Link onClick={onDismissOnboarding} inline underline={false}>
              Dismiss
            </Link>{' '}
            the onboarding process or
            <Link onClick={onRestartOnboarding} inline underline={false}>
              start from the beginning
            </Link>
            .
          </>
        );
      case UserOnboardingStatus.COMPLETED_AND_DISMISSED:
        return (
          <>
            <Link onClick={onViewOnboarding} inline underline={false}>
              View
            </Link>{' '}
            your acheviements or
            <Link onClick={onRestartOnboarding} inline underline={false}>
              start from the beginning
            </Link>
            .
          </>
        );
      case UserOnboardingStatus.DISMISSED:
        return (
          <>
            <Link onClick={onViewOnboarding} inline underline={false}>
              Resume
            </Link>{' '}
            your onboarding or
            <Link onClick={onRestartOnboarding} inline underline={false}>
              start from the beginning
            </Link>
            .
          </>
        );
      case UserOnboardingStatus.IN_PROGRESS:
        return (
          <>
            You may choose to{' '}
            <Link onClick={onDismissOnboarding} inline underline={false}>
              dismiss
            </Link>{' '}
            your onboarding or
            <Link onClick={onRestartOnboarding} inline underline={false}>
              start over
            </Link>
            .
          </>
        );
      default:
        return null;
    }
  }, [status, role, onRestartOnboarding, onDismissOnboarding, onViewOnboarding]);

  return (
    <section className={classes.Page}>
      <header className={classes.Header}>{t(TransKeys.USER_SETTINGS.MENU.OTHER)}</header>
      <div className={classes.Section}>
        <div className={classes.SectionHeader}>Onboarding</div>
        <div className={classes.Description}>
          <div>{onboardingStatusLabel}</div>
          <div>{renderOnboardingActions()}</div>
        </div>
      </div>
      <div className={classes.Section}>
        <div className={classes.SectionHeader}>Reset Password</div>
        <div className={classes.Description}>
          <span className={classes.Link} onClick={() => resetPassword()}>
            Click here to reset your password
          </span>
        </div>
      </div>
    </section>
  );
};

import {action, OnSuccessActionHook} from 'front-core';
import {SegmentCategoryDTO, SwapCategoriesDTO} from '../../objects/dto/segment.dto';
import {showConfirmationMessage} from '../interface/interface.actions';
import i18n from '../../config/i18n.config';
import TransKeys from '../../constants/translation-keys';

export enum SegmentCategoriesActionType {
  CREATE_SEGMENT_CATEGORY = '@@segment-categories/CREATE_SEGMENT_CATEGORY',
  UPDATE_SEGMENT_CATEGORY = '@@segment-categories/UPDATE_SEGMENT_CATEGORY',
  DELETE_SEGMENT_CATEGORY = '@@segment-categories/DELETE_SEGMENT_CATEGORY',
  SWAP_SEGMENT_CATEGORY = '@@segment-categories/SWAP_SEGMENT_CATEGORY',
}

export const createSegmentCategory = (data: SegmentCategoryDTO, onSuccess: OnSuccessActionHook) =>
  action(SegmentCategoriesActionType.CREATE_SEGMENT_CATEGORY, data, {onSuccess});

export const updateSegmentCategory = (data: SegmentCategoryDTO, onSuccess: OnSuccessActionHook) =>
  action(SegmentCategoriesActionType.UPDATE_SEGMENT_CATEGORY, data, {onSuccess});

export const swapSegmentCategory = (data: SwapCategoriesDTO) =>
  action(SegmentCategoriesActionType.SWAP_SEGMENT_CATEGORY, data);

export const deleteSegmentCategory = (id: number) =>
  action(SegmentCategoriesActionType.DELETE_SEGMENT_CATEGORY, id);

export const deleteSegmentCategoryConfirmed = (id: number) =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.DELETE_SEGMENT_CATEGORY.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.DELETE_SEGMENT_CATEGORY.CONTENT),
    },
    () => [deleteSegmentCategory(id)]
  );

import classNames from 'classnames';
import classes from './period-over-period-query-builder.module.scss';
import {
  METADATA_KEY,
  PARAMETERS_METADATA_KEY,
} from '../../../../../constants/parameters-saved-keys';
import {LabelWrapper, QueryBuilderFactory} from 'ui-components';
import {get, values} from 'lodash';
import {useCallback, useMemo} from 'react';
import {del, set} from 'object-path-immutable';
import {ItemList} from '../components/item-list/item-list.component';
import {SelectorModelType} from '../../smart-selector/advanced-smart-selector.component';
import {TableEntity, TableEntityBinding} from '../../../../../objects/models/table.model';
import {SignalDataType, SignalType} from '../../../../../objects/models/signal.model';
import {TemplateItemQueryBuilder} from '../template-item-query-builder/template-item-query-builder.component';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';

interface OwnProps {
  query: any;
  onChange?: (parameters: any) => void;
  onSignalInfo?: (value: string | number) => void;
  entityContext?: TableEntity;
  errors?: any;
  disabled?: boolean;
  className?: string;
  hideEngagementPeriod?: boolean;
}

type AllProps = OwnProps;

const QUERY_BUILDER_NAME = 'PeriodOverPeriodQueryBuilder';

export enum PeriodType {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

const PERIOD_TYPE_TO_TEMPLATE_TYPE = {
  [PeriodType.DAILY]: 'dod',
  [PeriodType.WEEKLY]: 'wow',
  [PeriodType.MONTHLY]: 'mom',
};

export const createPeriodOverPeriodInitialQuery = (type: PeriodType) => {
  const q = QueryBuilderFactory.createTemplate(PERIOD_TYPE_TO_TEMPLATE_TYPE[type]);

  q.parameters[0] = [null];

  q[PARAMETERS_METADATA_KEY] = {
    [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: QUERY_BUILDER_NAME,
  };
  return q;
};

const ALLOW_TYPES = [SelectorModelType.SIGNAL, SelectorModelType.EVENT];

const createSignalFilters = (entityContext = undefined) => ({
  type: SignalType.MEASURE,
  data_type: SignalDataType.BOOLEAN,
  exclude_templates: values(PERIOD_TYPE_TO_TEMPLATE_TYPE),
  entity_binding: TableEntityBinding.TWO_WAY,
  entityContext,
});

const createEventFilters = (entityContext = undefined) => ({
  entity_binding: TableEntityBinding.TWO_WAY,
  entityContext,
});

export const PeriodOverPeriodQueryBuilder: React.FC<AllProps> = (props: AllProps) => {
  const {query, onChange, onSignalInfo, errors, disabled, entityContext, className} = props;
  const {t} = useTranslation();

  const actions = useMemo(() => get(query, `parameters.0`, []), [query]);
  const signalFilters = useMemo(() => createSignalFilters(entityContext), [entityContext]);
  const eventFilters = useMemo(() => createEventFilters(entityContext), [entityContext]);

  const onActionsChanged = useCallback(
    (selectedValue, path) => {
      const newValue = set(query, path, selectedValue);
      onChange(newValue);
    },
    [query, onChange]
  );
  const removeAction = useCallback(
    path => {
      const newValue = del(query, path);
      onChange(newValue);
    },
    [query, onChange]
  );
  const addAction = useCallback(() => {
    const newValue = set(query, 'parameters.0', [...actions, null]);
    onChange(newValue);
  }, [query, actions, onChange]);

  return (
    <div className={classNames(classes.PeriodOverPeriodQueryBuilder, className)}>
      <div className={classes.Row}>
        <LabelWrapper label={t(TransKeys.QUERY_BUILDERS.PERIOD_OVER_PERIOD.ACTIONS.LABEL)}>
          <ItemList
            items={actions}
            renderItem={(item, idx) => (
              <TemplateItemQueryBuilder
                onChange={v => onActionsChanged(v, `parameters.0.${idx}`)}
                query={actions[idx]}
                errors={get(errors, `parameters.0.${idx}`)}
                signalFilters={signalFilters}
                eventFilters={eventFilters}
                disabled={disabled}
                allowTypes={ALLOW_TYPES}
                onSignalInfo={onSignalInfo}
              />
            )}
            onRemoveItem={idx => removeAction(`parameters.0.${idx}`)}
            onAddItem={addAction}
            disabled={disabled}
          />
        </LabelWrapper>
      </div>
    </div>
  );
};

import * as yup from 'yup';

// https://github.com/jquense/yup#error-message-customization
const required = 'Required';
const minString = ({min}) => `Minimum ${min} characters`;
const maxString = ({max}) => `Maximum ${max} characters`;
const emailValid = 'Invalid email';
const minNumber = ({min}) => `Value must be greater than ${min}`;
const maxNumber = ({max}) => `Value must be less than ${max}`;
const typeError = 'Invalid type';

yup.setLocale({
  mixed: {
    required: required,
    notType: required,
    typeError: typeError,
  },
  string: {
    required: required,
    notType: required,
    min: minString,
    max: maxString,
    email: emailValid,
    typeError: typeError,
  },
  number: {
    required: required,
    notType: required,
    min: minNumber,
    max: maxNumber,
    typeError: typeError,
  },
  array: {
    min: required,
  },
});

export default yup;

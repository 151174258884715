import * as React from 'react';
import classNames from 'classnames';
import classes from './wizard-completed-tab.module.scss';
import {Button, PartyHornIcon} from 'ui-components';
import {FormStep} from '../../../../../shared/components/layout/form-step/form-step.component';
import {SimilarAnalysis} from '../../../../../../objects/models/analysis.model';
import {AnalysisCard} from '../../../../components/analysis-card/analysis-card.component';
import {exists} from 'front-core';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {GenericLoading} from '../../../../../shared/components/general/generic-loading/generic-loading.component';

interface OwnProps {
  title: string;
  subTitle?: string;
  actions?: Array<{
    text: string;
    onClick: () => void;
    variant?: string;
  }>;
  showSimilarAnalyses?: boolean;
  similarAnalyses?: SimilarAnalysis[];
  isLoadingSimilarAnalyses?: boolean;
  onSimilarAnalysisClick?: (analysis: SimilarAnalysis) => void;
  className?: string;
}

type AllProps = OwnProps;

export const WizardCompletedTab: React.FC<AllProps> = (props: AllProps) => {
  const {
    title,
    subTitle,
    actions,
    showSimilarAnalyses,
    similarAnalyses,
    isLoadingSimilarAnalyses,
    onSimilarAnalysisClick,
    className,
  } = props;
  const {t} = useTranslation();

  const renderFooter = () => {
    if (!Boolean(actions)) {
      return null;
    }
    return (
      <div className={classes.FooterActions}>
        {actions.map((a, idx) => (
          <Button key={idx} onClick={a.onClick} variant={a.variant as any}>
            {a.text}
          </Button>
        ))}
      </div>
    );
  };

  return (
    <FormStep footer={renderFooter()}>
      <div className={classNames(classes.WizardCompletedTab, className)}>
        <div className={classes.Container}>
          <PartyHornIcon className={classes.SuccessIcon} />
          <div className={classes.Title}>{title}</div>
          {subTitle && <div className={classes.SubTitle}>{subTitle}</div>}
          {isLoadingSimilarAnalyses && (
            <div className={classes.SimilarAnalyses}>
              <GenericLoading />
            </div>
          )}
          {showSimilarAnalyses && similarAnalyses?.length > 0 && (
            <div className={classes.SimilarAnalyses}>
              <div className={classes.SimilarAnalysesTitle}>
                {t(TransKeys.CREATE_ANALYSIS_FORM.SUCCESS_STEP.SIMILAR_ANALYSES_TITLE)}
              </div>
              <div className={classes.List}>
                {!isLoadingSimilarAnalyses &&
                  similarAnalyses?.map(analysis => (
                    <AnalysisCard
                      key={analysis.id}
                      name={analysis.name}
                      userFullName={analysis.requestedByUser.fullName}
                      isInternalUser={analysis.requestedByUser.isInternalUser}
                      analysisTypeName={analysis.analysisTypeName}
                      goalName={analysis.analysisGoal?.name}
                      // start_date_anomaly for RCA
                      startDate={
                        analysis.runParameters.start_date ||
                        analysis.runParameters.start_date_anomaly
                      }
                      endDate={analysis.runParameters.end_date}
                      hasPopulationFilter={exists(analysis.parameters.population_filter)}
                      onClick={() => onSimilarAnalysisClick(analysis)}
                      className={classes.AnalysisCard}
                    />
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </FormStep>
  );
};

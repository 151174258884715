import * as React from 'react';
import classes from './kpi-tree-page.module.scss';
import {KPITree} from '../../components/kpi-tree/kpi-tree.component';

interface OwnProps {}

type AllProps = OwnProps;

export const KPITreePage: React.FC<AllProps> = (props: AllProps) => {
  return (
    <div className={classes.KPITreePage}>
      <KPITree showHeader={false} showMinimap syncDateInParams />
    </div>
  );
};

import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo} from 'react';
import {composition, exists} from 'front-core';
import {
  ANALYSIS_RESULT_ID_PATH_PARAM,
  ANALYSIS_TYPE_ID_PATH_PARAM,
  AppRoutes,
  EXPERIMENT_ID_PATH_PARAM,
} from '../../../../constants/app-routes';
import {useHistory, useParams} from 'react-router';
import {useTranslation} from 'react-i18next';
import classes from './view-experiment.module.scss';
import {
  patchExperiment,
  patchExperimentConfirmed,
  updateExperimentConfirmed,
} from '../../../../store/experiments/experiments.actions';
import {ExperimentHeader} from '../../components/experiment-header/experiment-header.component';
import {AnalysisResults} from '../../../shared/core/document-viewer/analysis-results.component';
import {ExperimentStatus} from '../../../../objects/models/experiment.model';
import TransKeys from '../../../../constants/translation-keys';
import {PrimaryTabs} from 'ui-components';
import {ExperimentFinalInfo} from '../../components/experiment-final-info/experiment-final-info.component';
import {values} from 'lodash';
import {ABTestHeaderViewer} from '../../../shared/core/document-viewer/viewers/ab-test-header-viewer.component';
import {WinnerHeaderViewer} from '../../../shared/core/document-viewer/viewers/winner-header-viewer.component';
import {StringParam, useQueryParam} from 'use-query-params';
import {getAnalysisResultNetworkRequest} from '../../../../http/analysis-results.network-requests';
import {
  createSelected,
  getSelected,
  removeSelected,
  upsertSelected,
} from '../../../../store/selected/selected.actions';
import {useDispatch, useSelector} from 'react-redux';
import {
  getReducedLoadingStateSelector,
  getSingleSelectedSelector,
} from '../../../../store/store.selectors';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {getExperimentNetworkRequest} from '../../../../http/experiments.network-requests';
import {ModelKey} from '../../../../constants/model-key';
import {EmptyState} from '../../../shared/components/general/override';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {ModelDiscriminatorType} from '../../../../objects/models/user-reaction.model';
import {useSyncIsViewed} from '../../../../core/hooks/user-reactions.hook';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {CoreActionsType} from '../../../../store/core/core.actions';
import {PanelKey} from '../../../../constants/panels';
import {PanelType} from '../../../../objects/system/panel-type.enum';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {ShareResourceType} from '../../../../objects/models/share.model';
import PageLayout from 'src/modules/shared/components/layout/page-layout';
import {AnalysisTypeId} from '../../../../constants/analysis-type-id';
import {useAnalysisResultActions} from '../../../analyses/hooks/use-analysis-result-actions.hook';
import {ActionKey} from '../../../../constants/action-key';

interface OwnProps {}

const SELECTED_RESULT_KEY = SharedSelectionKeys.VIEW_EXPERIMENT__RESULT;
const SELECTED_EXPERIMENT_KEY = SharedSelectionKeys.VIEW_EXPERIMENT__EXPERIMENT;

type AllProps = OwnProps;

const ViewExperimentComponent: React.FC<AllProps> = (props: AllProps) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const notify = useAmplitude();
  const history = useHistory();
  const {[EXPERIMENT_ID_PATH_PARAM]: experimentId} = useParams<any>();
  const [selectedTab = 'analysis', setSelectedTab] = useQueryParam('tab', StringParam);
  const {openPrimaryPanel} = useContext(PanelsContext);
  const {onDownloadArtifact} = useAnalysisResultActions();

  const experiment = useSelector(state =>
    getSingleSelectedSelector(SELECTED_EXPERIMENT_KEY, state)
  );
  useSyncIsViewed(experiment, ModelDiscriminatorType.EXPERIMENT);

  const analysisResult = useSelector(state =>
    getSingleSelectedSelector(SELECTED_RESULT_KEY, state)
  );
  const isLoadingResult = useSelector(state =>
    getReducedLoadingStateSelector(
      SELECTED_RESULT_KEY,
      ActionKey.PATCH_EXPERIMENT,
      ActionKey.UPDATE_EXPERIMENT
    )(state)
  );

  const isCompleted = useMemo(
    () =>
      experiment?.status === ExperimentStatus.STOPPED ||
      experiment?.status === ExperimentStatus.DONE,
    [experiment]
  );
  const winner = useMemo(
    () => (analysisResult ? values(analysisResult.outputs).find(i => i.isWinner) : null),
    [analysisResult]
  );

  const renderAnalysisResults = useMemo(
    () =>
      analysisResult?.rootDocumentId &&
      experiment?.status !== ExperimentStatus.PENDING &&
      !Boolean(experiment?.reRunningAnalysisResult),
    [analysisResult, experiment]
  );
  const renderEmptyState = useMemo(
    () =>
      experiment &&
      !isLoadingResult &&
      (!exists(analysisResult?.rootDocumentId) || Boolean(experiment?.reRunningAnalysisResult)),
    [experiment, isLoadingResult, analysisResult]
  );

  const renderNotStartedEmptyState = useMemo(
    () => experiment && experiment.status === ExperimentStatus.PENDING,
    [experiment]
  );

  useEffect(() => {
    dispatch(
      createSelected({
        selectedKey: SELECTED_EXPERIMENT_KEY,
        actionKey: SELECTED_EXPERIMENT_KEY,
        request: getExperimentNetworkRequest,
        modelKey: ModelKey.EXPERIMENT,
      })
    );
    dispatch(
      createSelected({
        selectedKey: SELECTED_RESULT_KEY,
        actionKey: SELECTED_RESULT_KEY,
        request: getAnalysisResultNetworkRequest,
      })
    );
    // clean up
    return () => {
      dispatch(removeSelected(SELECTED_EXPERIMENT_KEY));
      dispatch(removeSelected(SELECTED_RESULT_KEY));
    };
  }, [dispatch]);
  useEffect(() => {
    experimentId && dispatch(getSelected(SELECTED_EXPERIMENT_KEY, experimentId));
  }, [experimentId, dispatch]);
  useEffect(() => {
    if (!experiment) {
      return;
    }
    if (
      experiment.status === ExperimentStatus.DONE ||
      experiment.status === ExperimentStatus.STOPPED
    ) {
      !selectedTab && setSelectedTab('learnings', 'replaceIn');
    }
  }, [experiment, selectedTab, setSelectedTab]);
  useEffect(() => {
    const resultId = experiment?.lastCompletedAnalysisResult?.id;
    if (resultId && resultId !== analysisResult?.id) {
      dispatch(getSelected(SELECTED_RESULT_KEY, resultId));
    }
    if (!resultId) {
      dispatch(upsertSelected(SELECTED_RESULT_KEY, undefined));
    }
  }, [experiment, analysisResult, dispatch]);

  useEffect(() => {
    experiment?.id &&
      notify(AmplitudeEvent.EXPERIMENT_DEEP_DIVE, {id: experiment.id, type: experiment?.type});
  }, [experiment, notify]);

  const onChangeStatus = (status: ExperimentStatus) =>
    dispatch(
      patchExperimentConfirmed({
        id: experiment.id,
        status,
      })
    );
  const onPropertyChange = (prop: string, value: any) =>
    dispatch(
      patchExperiment({
        id: experiment.id,
        [prop]: value,
      })
    );
  const onEditDuplicate = useCallback(
    (cloneMode = false) => {
      const onApprove = () =>
        openPrimaryPanel(
          PanelKey.EXPERIMENT_FORM_PANEL,
          {
            [EXPERIMENT_ID_PATH_PARAM]: experiment.id,
            [ANALYSIS_TYPE_ID_PATH_PARAM]: experiment.analysis.analysisTypeId,
            cloneMode,
          },
          PanelType.MODAL
        );
      if (cloneMode) {
        return onApprove();
      }
      dispatch(updateExperimentConfirmed(onApprove));
    },
    [openPrimaryPanel, experiment, dispatch]
  );

  const onRename = useCallback(
    (experimentId: number) => {
      openPrimaryPanel(
        PanelKey.RENAME_EXPERIMENT_PANEL,
        {
          [EXPERIMENT_ID_PATH_PARAM]: experimentId,
        },
        PanelType.MODAL
      );
    },
    [openPrimaryPanel]
  );
  const onShare = useCallback(() => {
    openPrimaryPanel(
      PanelKey.SHARE_RESOURCE_PANEL,
      {
        modelId: experiment.id,
        type: ShareResourceType.EXPERIMENT,
        copyLink: AppRoutes.asUrl(AppRoutes.viewExperiment(experiment.id)),
      },
      PanelType.MODAL
    );
    notify(AmplitudeEvent.RESOURCE_SHARE_CLICKED, {
      type: ShareResourceType.EXPERIMENT,
      id: experiment.id,
    });
  }, [openPrimaryPanel, experiment, notify]);

  const onViewAnalysisSqlQueries = useCallback(
    () =>
      experiment?.lastCompletedAnalysisResult?.executedSqlsCount &&
      openPrimaryPanel(
        PanelKey.VIEW_ANALYSIS_EXECUTED_SQLS_PANEL,
        {[ANALYSIS_RESULT_ID_PATH_PARAM]: experiment?.lastCompletedAnalysisResult?.id},
        PanelType.MODAL
      ),
    [experiment, openPrimaryPanel]
  );
  const renderExperimentHeader = () => {
    if (!analysisResult.outputs) {
      return null;
    }
    if (analysisResult.analysisTypeId === AnalysisTypeId.RELEASE_IMPACT) {
      return null;
    }

    if (winner) {
      const text = winner?.isControl
        ? t(TransKeys.GENERAL.LABELS.CONTROL_IS_WINNING_THE_EXPERIMENT)
        : t(TransKeys.GENERAL.LABELS.EXPERIMENT_WINNER, winner);
      return <WinnerHeaderViewer text={text} className={classes.WinnerHeaderViewer} />;
    }

    try {
      let controlLabel = '';
      const others = [];
      values(analysisResult.outputs).forEach(v => {
        controlLabel = v.isControl ? v.name : controlLabel;
        !v.isControl && others.push(v.name);
      });
      return <ABTestHeaderViewer controlLabel={controlLabel} others={others} />;
    } catch (e) {
      return null;
    }
  };
  const gotoOpportunity = () => history.push(AppRoutes.viewOpportunity(experiment.opportunity.id));

  useEffect(() => {
    const listener = action => {
      if (
        action.payload.modelKey === ModelKey.EXPERIMENT &&
        experimentId &&
        Number(experimentId) === action.payload.data
      ) {
        dispatch(getSelected(SELECTED_EXPERIMENT_KEY, experimentId));
      }
    };
    dispatch(registerActionListener(CoreActionsType.MODEL_UPDATED, listener));
    return () => {
      dispatch(removeActionListener(CoreActionsType.MODEL_UPDATED, listener));
    };
  }, [dispatch, experimentId]);

  if (!experiment) {
    return <GenericLoading />;
  }

  return (
    <PageLayout.Layout>
      <ExperimentHeader
        experiment={experiment}
        analysisResult={analysisResult}
        onChangeStatus={onChangeStatus}
        onEdit={onEditDuplicate}
        onDuplicate={() => onEditDuplicate(true)}
        onShare={onShare}
        onRename={onRename}
        onViewAnalysisSqlQueries={onViewAnalysisSqlQueries}
        onDownloadArtifact={onDownloadArtifact}
      />
      <PageLayout.Body isLoading={isLoadingResult} noPadding>
        <PrimaryTabs
          className={classes.PrimaryTabs}
          selected={selectedTab}
          onChange={tab => setSelectedTab(tab, 'replaceIn')}
          hideTabsButtons={!isCompleted}
          classes={{
            buttonsWrapper: classes.Tabs,
          }}
          tabs={[
            {
              key: 'analysis',
              label: t(TransKeys.VIEW_EXPERIMENT.TABS.RESULTS),
              render: () => (
                <div className={classes.TabContent}>
                  {renderAnalysisResults && (
                    <AnalysisResults
                      analysisResult={analysisResult}
                      isLoading={isLoadingResult}
                      renderFirst={renderExperimentHeader}
                    />
                  )}
                  {renderEmptyState && (
                    <div className={classes.EmptyState}>
                      <EmptyState
                        title={t(TransKeys.VIEW_EXPERIMENT.NO_ANALYSIS_EMPTY_STATE.TITLE)}
                        subTitle={t(TransKeys.VIEW_EXPERIMENT.NO_ANALYSIS_EMPTY_STATE.SUB_TITLE)}
                      />
                    </div>
                  )}
                  {renderNotStartedEmptyState && (
                    <div className={classes.EmptyState}>
                      <EmptyState
                        title={t(
                          TransKeys.VIEW_EXPERIMENT.EXPERIMENT_DID_NOT_STARTED_YET_EMPTY_STATE.TITLE
                        )}
                        subTitle={t(
                          TransKeys.VIEW_EXPERIMENT.EXPERIMENT_DID_NOT_STARTED_YET_EMPTY_STATE
                            .SUB_TITLE
                        )}
                        buttonText={t(TransKeys.GENERAL.ACTIONS.START_EXPERIMENT)}
                        onClick={() => onChangeStatus(ExperimentStatus.IN_PROGRESS)}
                      />
                    </div>
                  )}
                </div>
              ),
            },
            {
              key: 'learnings',
              label: t(TransKeys.VIEW_EXPERIMENT.TABS.LEARNINGS),
              hide: !isCompleted,
              render: () => (
                <div className={classes.TabContent}>
                  <ExperimentFinalInfo
                    onViewOpportunity={gotoOpportunity}
                    onViewResults={() => setSelectedTab('analysis', 'replaceIn')}
                    experiment={experiment}
                    analysisResult={analysisResult}
                    onLearningChange={value => onPropertyChange('learnings', value)}
                    onNextDescriptionChange={value => onPropertyChange('nextDescription', value)}
                  />
                </div>
              ),
            },
          ]}
        />
      </PageLayout.Body>
    </PageLayout.Layout>
  );
};

const ViewExperiment = composition<OwnProps>(ViewExperimentComponent);

export default ViewExperiment;

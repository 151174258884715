import {Epic} from 'redux-observable';
import {createRequestEpic} from 'front-core';
import {ActionKey} from '../../constants/action-key';
import HttpClient from '../../services/http-client.service';
import {modelCreated, modelUpdated, notifyEvent} from '../core/core.actions';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {HealthMonitorActionType, healthMonitorChanged} from './health-monitor.actions';
import {showToastMessage} from '../interface/interface.actions';
import {ToastType} from '../../objects/system/toast-type.enum';
import {
  createHealthMonitorGroupNetworkRequest,
  createHealthMonitorSubscriptionNetworkRequest,
  createMonitoredMetricNetworkRequest,
  deleteHealthMonitorSubscriptionNetworkRequest,
  editHealthMonitorGroupNetworkRequest,
  editHealthMonitorSubscriptionNetworkRequest,
  initializeHealthMonitorNetworkRequest,
  removeHealthMonitorGroupNetworkRequest,
  removeMonitoredMetricNetworkRequest,
  updateMonitoredMetricNetworkRequest,
} from '../../http/health-monitor.network-requests';
import {HealthMonitorModel} from '../../objects/models/health-monitor.model';
import i18n from '../../config/i18n.config';
import TransKeys from '../../constants/translation-keys';
import {ModelKey} from '../../constants/model-key';
import {demoProductLimitedActionsFilter} from '../store.utils';

export const initializeHealthMonitorEpic: Epic = createRequestEpic(
  {
    types: [HealthMonitorActionType.INITIALIZE_HEALTH_MONITOR],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.INITIALIZE_HEALTH_MONITOR,
    request: initializeHealthMonitorNetworkRequest,
    onSuccess: (_, payload) => [
      notifyEvent(AmplitudeEvent.HEALTH_MONITOR_INITIALIZED, {
        health_monitor_id: payload.metricHealthMonitorId,
      }),
      healthMonitorChanged(payload.metricHealthMonitorId),
    ],
    onError: err => [showToastMessage(err?.data.message, ToastType.ERROR)],
  },
  HttpClient
);

export const createHealthMonitorGroupEpic: Epic = createRequestEpic(
  {
    types: [HealthMonitorActionType.CREATE_HEALTH_MONITOR_GROUP],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.CREATE_HEALTH_MONITOR_GROUP,
    request: createHealthMonitorGroupNetworkRequest,
    onSuccess: (_, payload) => [
      notifyEvent(AmplitudeEvent.HEALTH_MONITOR_GROUP_CREATED, {
        health_monitor_id: payload.metricHealthMonitorId,
      }),
      healthMonitorChanged(payload.metricHealthMonitorId),
    ],
    onError: err => [showToastMessage(err?.data.message, ToastType.ERROR)],
  },
  HttpClient
);

export const editHealthMonitorGroupEpic: Epic = createRequestEpic(
  {
    types: [HealthMonitorActionType.EDIT_HEALTH_MONITOR_GROUP],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.EDIT_HEALTH_MONITOR_GROUP,
    request: editHealthMonitorGroupNetworkRequest,
    onSuccess: (_, payload) => [
      notifyEvent(AmplitudeEvent.HEALTH_MONITOR_GROUP_UPDATED, {
        health_monitor_id: payload.metricHealthMonitorId,
        group_id: payload.id,
      }),
      healthMonitorChanged(payload.metricHealthMonitorId),
    ],
    onError: err => [showToastMessage(err?.data.message, ToastType.ERROR)],
  },
  HttpClient
);

export const removeHealthMonitorGroupEpic: Epic = createRequestEpic(
  {
    types: [HealthMonitorActionType.REMOVE_HEALTH_MONITOR_GROUP],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.REMOVE_HEALTH_MONITOR_GROUP,
    request: removeHealthMonitorGroupNetworkRequest,
    onSuccess: (_, payload) => [
      notifyEvent(AmplitudeEvent.HEALTH_MONITOR_GROUP_REMOVED, {
        health_monitor_id: payload.metricHealthMonitorId,
      }),
      healthMonitorChanged(payload.metricHealthMonitorId),
    ],
    onError: err => [showToastMessage(err?.data.message, ToastType.ERROR)],
  },
  HttpClient
);

export const createMonitoredMetricEpic: Epic = createRequestEpic(
  {
    types: [HealthMonitorActionType.CREATE_MONITORED_METRIC],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.CREATE_MONITORED_METRIC,
    request: createMonitoredMetricNetworkRequest,
    onSuccess: (_, payload) => [
      notifyEvent(AmplitudeEvent.MONITORED_METRIC_CREATED, {
        health_monitor_id: payload.metricHealthMonitorId,
      }),
      modelCreated({id: payload.id}, ModelKey.MONITORED_METRIC),
      healthMonitorChanged(payload.metricHealthMonitorId),
    ],
    onError: err => [showToastMessage(err?.data.message, ToastType.ERROR)],
  },
  HttpClient
);

export const editMonitoredMetricEpic: Epic = createRequestEpic(
  {
    types: [HealthMonitorActionType.EDIT_MONITORED_METRIC],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.EDIT_MONITORED_METRIC,
    request: updateMonitoredMetricNetworkRequest,
    onSuccess: (_, payload) => [
      notifyEvent(AmplitudeEvent.MONITORED_METRIC_UPDATED, {
        monitored_metric_id: payload.id,
        health_monitor_id: payload.metricHealthMonitorId,
      }),
      modelUpdated({id: payload.id}, ModelKey.MONITORED_METRIC),
      healthMonitorChanged(payload.metricHealthMonitorId),
    ],
    onError: err => [showToastMessage(err?.data.message, ToastType.ERROR)],
  },
  HttpClient
);

export const deleteMonitoredMetricEpic: Epic = createRequestEpic(
  {
    types: [HealthMonitorActionType.REMOVE_MONITORED_METRIC],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.REMOVE_MONITORED_METRIC,
    request: removeMonitoredMetricNetworkRequest,
    onSuccess: (_, payload) => [
      notifyEvent(AmplitudeEvent.MONITORED_METRIC_REMOVED, {
        monitored_metric_id: payload,
      }),
      healthMonitorChanged(),
    ],
    onError: err => [showToastMessage(err?.data.message, ToastType.ERROR)],
  },
  HttpClient
);

export const createHealthMonitorSubscriptionEpic: Epic = createRequestEpic(
  {
    types: [HealthMonitorActionType.CREATE_HEALTH_MONITOR_SUBSCRIPTION],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.CREATE_HEALTH_MONITOR_SUBSCRIPTION,
    request: createHealthMonitorSubscriptionNetworkRequest,
    onSuccess: (healthMonitor: HealthMonitorModel, payload) => [
      notifyEvent(AmplitudeEvent.HEALTH_MONITOR_SUBSCRIPTION_CREATED, {
        id: healthMonitor.id,
        schedule: healthMonitor.cronExp,
      }),
      showToastMessage(
        i18n.t(TransKeys.TOASTS.CREATE_SUBSCRIPTION_SUCCESS, {
          model: ModelKey.HEALTH_MONITOR.toLowerCase().replace('_', ' '),
        }),
        ToastType.SUCCESS
      ),
      healthMonitorChanged(healthMonitor.id),
    ],
    onError: () => [
      showToastMessage(
        i18n.t(TransKeys.TOASTS.CREATE_SUBSCRIPTION_ERROR, {
          model: ModelKey.HEALTH_MONITOR.toLowerCase().replace('_', ' '),
        }),
        ToastType.ERROR
      ),
    ],
  },
  HttpClient
);

export const editHealthMonitorSubscriptionEpic: Epic = createRequestEpic(
  {
    types: [HealthMonitorActionType.EDIT_HEALTH_MONITOR_SUBSCRIPTION],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.EDIT_HEALTH_MONITOR_SUBSCRIPTION,
    request: editHealthMonitorSubscriptionNetworkRequest,
    onSuccess: (healthMonitor: HealthMonitorModel, payload) => [
      notifyEvent(AmplitudeEvent.HEALTH_MONITOR_SUBSCRIPTION_UPDATED, {
        id: healthMonitor.id,
        schedule: healthMonitor.cronExp,
      }),
      showToastMessage(
        i18n.t(TransKeys.TOASTS.EDIT_SUBSCRIPTION_SUCCESS, {
          model: ModelKey.HEALTH_MONITOR.toLowerCase().replace('_', ' '),
        }),
        ToastType.SUCCESS
      ),
      healthMonitorChanged(healthMonitor.id),
    ],
    onError: () => [
      showToastMessage(
        i18n.t(TransKeys.TOASTS.EDIT_SUBSCRIPTION_ERROR, {
          model: ModelKey.HEALTH_MONITOR.toLowerCase().replace('_', ' '),
        }),
        ToastType.ERROR
      ),
    ],
  },
  HttpClient
);

export const deleteHealthMonitorSubscriptionEpic: Epic = createRequestEpic(
  {
    types: [HealthMonitorActionType.DELETE_HEALTH_MONITOR_SUBSCRIPTION],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.DELETE_HEALTH_MONITOR_SUBSCRIPTION,
    request: deleteHealthMonitorSubscriptionNetworkRequest,
    onSuccess: (healthMonitor: HealthMonitorModel, payload) => [
      notifyEvent(AmplitudeEvent.HEALTH_MONITOR_SUBSCRIPTION_DELETED, {
        id: healthMonitor.id,
        schedule: healthMonitor.cronExp,
      }),
      showToastMessage(
        i18n.t(TransKeys.TOASTS.DELETE_SUBSCRIPTION_SUCCESS, {
          model: ModelKey.HEALTH_MONITOR.toLowerCase().replace('_', ' '),
        }),
        ToastType.SUCCESS
      ),
      healthMonitorChanged(healthMonitor.id),
    ],
    onError: () => [
      showToastMessage(
        i18n.t(TransKeys.TOASTS.DELETE_SUBSCRIPTION_ERROR, {
          model: ModelKey.HEALTH_MONITOR.toLowerCase().replace('_', ' '),
        }),
        ToastType.ERROR
      ),
    ],
  },
  HttpClient
);

import * as React from 'react';
import {exists} from 'front-core';
import {get, some, values} from 'lodash';
import {TableEntityBinding} from '../../../../../../../objects/models/table.model';
import {useFormContext} from 'react-hook-form';
import {MetricDefinitionProps} from './metric-definition.types';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../../../../constants/translation-keys';
import {ParameterInputWrapper} from '../../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {useCallback, useEffect, useMemo} from 'react';
import {SegmentForSignalSmartSelector} from '../../../../../../shared/core/smart-selector/segment-for-signal-smart-selector.component';
import {Select, useRemoteSourceStated} from 'ui-components';
import {getSegmentNetworkRequest} from '../../../../../../../http/segments.network-requests';
import classes from './metric-definition.module.scss';
import {SegmentMetricType} from '../../../../../../../objects/models/health-monitor.model';
import pluralize from 'pluralize';
import {useProductData} from '../../../../../../../core/hooks/use-product-data.hook';

interface OwnProps extends MetricDefinitionProps {
  onCreateSegment?: () => void;
}

type AllProps = OwnProps;

const ERRORS_KEYS = [
  `parameters.signal_id`,
  `parameters.segment_id`,
  `parameters.class_value`,
  `parameters.type`,
];

export const MonitoredMetricSegmentDefinition: React.FC<AllProps> = (props: AllProps) => {
  const {
    onSignalInfo,
    entityContext,
    onCreateSegment,
    parameters,
    setParameters,
    errors,
    className,
  } = props;
  const {t} = useTranslation();
  const {setValue} = useFormContext();
  const {productEntitiesMap} = useProductData();
  const {source: selectedSegment, exec: getSegment} = useRemoteSourceStated({
    type: 'source',
    networkRequest: getSegmentNetworkRequest,
  });
  const hasError = useMemo(() => some(ERRORS_KEYS.map(k => Boolean(get(errors, k)))), [errors]);
  const segmentId = useMemo(() => parameters.segment_id, [parameters]);
  const segmentClasses = useMemo(
    () =>
      (selectedSegment?.classes || []).map(c => ({
        label: c.name,
        value: c.value,
      })),
    [selectedSegment]
  );
  const segmentMetricTypeOptions = useMemo(
    () => ({
      options: values(SegmentMetricType).map(o => ({
        label: t(TransKeys.CREATE_MONITORED_METRIC_FORM.TABS.METRIC_DEFINITION.SEGMENT.MODES[o], {
          entity: pluralize(productEntitiesMap[entityContext]?.name).toLowerCase(),
        }),
        value: o,
      })),
    }),
    [t, entityContext, productEntitiesMap]
  );
  const updateName = useCallback(() => {
    setValue(
      'name',
      `${selectedSegment?.name} - ${parameters.class_value} for ${t(
        TransKeys.CREATE_MONITORED_METRIC_FORM.TABS.METRIC_DEFINITION.SEGMENT.MODES[
          parameters.type
        ],
        {
          entity: pluralize(productEntitiesMap[entityContext]?.name).toLowerCase(),
        }
      ).toLowerCase()}`
    );
  }, [t, selectedSegment, parameters, setValue, productEntitiesMap, entityContext]);

  useEffect(() => {
    if (!exists(segmentId)) {
      return;
    }
    if (selectedSegment?.id !== segmentId) {
      getSegment(segmentId);
    }
  }, [getSegment, segmentId, selectedSegment]);
  useEffect(() => {
    if (!exists(parameters.type)) {
      setParameters({type: SegmentMetricType.NEW_USERS});
    }
  }, [setParameters, parameters]);
  useEffect(() => {
    updateName();
  }, [parameters, selectedSegment, updateName]);

  return (
    <ParameterInputWrapper
      title={t(TransKeys.CREATE_MONITORED_METRIC_FORM.TABS.SELECT_METRIC.METRIC_DEFINITION.TITLE)}
      subTitle={t(
        TransKeys.CREATE_MONITORED_METRIC_FORM.TABS.SELECT_METRIC.METRIC_DEFINITION.SUB_TITLE
      )}
      className={className}
      error={hasError}
    >
      <div className={classes.Definition}>
        <div className={classes.Item}>
          <Select
            error={exists(get(errors, `parameters.type`))}
            value={parameters.type}
            options={segmentMetricTypeOptions}
            onChange={v => setParameters({type: v})}
            clearable={false}
            searchable={false}
          />
        </div>
        <div className={classes.Label}>
          {t(TransKeys.CREATE_MONITORED_METRIC_FORM.TABS.METRIC_DEFINITION.SEGMENT.WHERE)}
        </div>
        <div className={classes.Item}>
          <SegmentForSignalSmartSelector
            placeholder={t(
              TransKeys.CREATE_MONITORED_METRIC_FORM.TABS.METRIC_DEFINITION.SEGMENT.SELECT_SEGMENT
            )}
            value={parameters.signal_id}
            onSignalInfo={onSignalInfo as any}
            error={exists(get(errors, `parameters.segment_id`))}
            onCreate={onCreateSegment}
            onChange={(v, segment) => {
              setParameters({
                signal_id: v,
                segment_id: segment.id,
                class_value: undefined,
              });
              // set the name of the metric to be as the kpi
              setValue('name', segment?.name);
            }}
            filters={{
              entityContext,
              entityBinding: TableEntityBinding.DEFAULT,
            }}
          />
        </div>
        <div className={classes.Label}>
          {t(TransKeys.CREATE_MONITORED_METRIC_FORM.TABS.METRIC_DEFINITION.SEGMENT.EQUALS)}
        </div>
        <div className={classes.Item}>
          <Select
            key={segmentClasses.length}
            error={exists(get(errors, `parameters.class_value`))}
            placeholder={t(
              TransKeys.CREATE_MONITORED_METRIC_FORM.TABS.METRIC_DEFINITION.SEGMENT.SELECT_CLASS
            )}
            value={parameters.class_value}
            options={{options: segmentClasses}}
            onChange={v => setParameters({class_value: v})}
            clearable={false}
            searchable={false}
            capitalize={false}
            disabled={!exists(parameters.segment_id)}
          />
        </div>
      </div>
    </ParameterInputWrapper>
  );
};

import {NotificationsConfigDTO} from '../objects/dto/notifications-config.dto';
import {NRC} from './index';
import {RequestType, toSnakeCase} from 'front-core';

export const getUserNotificationsConfigNetworkRequest: NRC<void, NotificationsConfigDTO> = () => ({
  method: RequestType.GET,
  relativeUrl: `/app/user/notifications`,
  responseTransformer: res => res.data.data,
});

export const updateUserNotificationSettingsNetworkRequest: NRC<NotificationsConfigDTO, void> = (
  data: NotificationsConfigDTO
) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/user/notifications`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => res.data.data,
});

import classes from './settings.module.scss';
import {Route, Switch} from 'react-router';
import {AppRoutes} from '../../constants/app-routes';
import {UserNotifications} from './pages/user-notifications/user-notifications.component';
import {useTranslation} from 'react-i18next';
import TransKeys from 'src/constants/translation-keys';
import {composition} from 'front-core';
import {UserPermissions} from './pages/user-permissions/user-permissions.component';
import {
  Nav,
  NavLink,
  NavList,
  NavSubtitle,
} from '../shared/components/navigation/left-navigation/left-navigation.component';
import usePermissions from 'src/core/hooks/use-permissions.hook';
import PageLayout from '../shared/components/layout/page-layout';
import {GearLightIcon} from 'ui-components';
import {UserOther} from './pages/user-other/user-other.component';

interface OwnProps {
  className?: string;
}

type AllProps = OwnProps;

const SettingsComponent: React.FC<AllProps> = () => {
  const {t} = useTranslation();
  const {role} = usePermissions();

  return (
    <PageLayout.Layout>
      <PageLayout.Title title={t(TransKeys.GENERAL.LABELS.SETTINGS)} icon={GearLightIcon} />
      <PageLayout.Body noPadding className={classes.Container}>
        <Nav className={classes.Nav}>
          <NavSubtitle>{t(TransKeys.GENERAL.LABELS.USER)}</NavSubtitle>
          <NavList>
            <NavLink to={AppRoutes.settingsNotifications()}>
              {t(TransKeys.USER_SETTINGS.MENU.NOTIFICATIONS)}
            </NavLink>
            {role && (
              <NavLink to={AppRoutes.settingsPermissions()}>
                {t(TransKeys.USER_SETTINGS.MENU.PERMISSIONS)}
              </NavLink>
            )}
            <NavLink to={AppRoutes.settingsOther()}>
              {t(TransKeys.USER_SETTINGS.MENU.OTHER)}
            </NavLink>
          </NavList>
        </Nav>
        <div className={classes.Content}>
          <Switch>
            <Route
              path={AppRoutes.settingsNotifications().pathname}
              component={UserNotifications}
              exact
            />
            <Route
              path={AppRoutes.settingsPermissions().pathname}
              component={UserPermissions}
              exact
            />
            <Route path={AppRoutes.settingsOther().pathname} component={UserOther} exact />
          </Switch>
        </div>
      </PageLayout.Body>
    </PageLayout.Layout>
  );
};

export const Settings = composition<OwnProps>(SettingsComponent);

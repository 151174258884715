import {MetricType, MetricValueType} from './metric.model';
import {Goal} from './goal.model';
import {TableEntity} from './table.model';
import {ModelSampleSeries, ModelSampleSeriesModel} from './model-sample-series.model';
import {Annotation} from './annotation.model';

export enum AnomalyMode {
  LOOPS_ALGO = 'loops_algo',
  XOX_SAME_WEEKDAY = 'xox_same_weekday',
}

export enum HomepageSubscriptionVIA {
  MAIL = 'mail',
  SLACK = 'slack',
}

export enum HomepageSubscriptionPushType {
  SIGNIFICANT_CHANGE = 'significant_change',
  ALWAYS = 'always',
  NEVER = 'never',
}

export enum HomepageSubscriptionUnit {
  WEEKLY = 'weekly',
  DAILY = 'daily',
}

export enum HomepageSubscribedSegmentsType {
  ALL = 'all',
  TEAM = 'team',
  CUSTOM = 'custom',
}

export interface HomepageSettings {
  homepageId: number;
  metrics: number[];
}

export interface HomepageSubscriptionMetricSettings {
  [metricId: number]: HomepageSubscriptionPushType;
}

export interface HomepageSubscription {
  homepageId: number;
  isActive: boolean;
  via: HomepageSubscriptionVIA[];
  slackChannels: string[];
  anomalyMode: AnomalyMode;
  anomalyThreshold: number;
  subscribedSegmentsType: HomepageSubscribedSegmentsType;
  weeklyMetricSettings: HomepageSubscriptionMetricSettings;
  dailyMetricSettings: HomepageSubscriptionMetricSettings;
}

export interface AttachedHomepageModel {
  modelId: number;
  pivotId: number;
  modelType: ModelSampleSeriesModel;
  order: number;
}

export interface HomepageModel {
  id: number;
  name: string;
  modelType: ModelSampleSeriesModel;
  series: ModelSampleSeries[];
  entity: TableEntity;
  createdOn: string;
  // not relevant for all components
  maxSampleDate?: string;
  minSampleDate?: string;
}

export interface HomepageFunnel extends HomepageModel {
  steps: Array<{id: number; name: string}>;
}

export interface HomepageMetric extends HomepageModel {
  type: MetricType;
  valueType: MetricValueType;
  signalId: number;
  signalDefinition: any;
  signalPopulationFilter: any;
  goal: Partial<Goal>;
  higherIsBetter: boolean;
}

export interface HomepageAnnotation extends Annotation {}

import {useCallback, useContext, useMemo} from 'react';
import classes from './metric-page-header.module.scss';
import {MetricPage} from '../../../../../../objects/models/metric-page.model';
import {MetricValueType} from '../../../../../../objects/models/metric.model';
import {number2k, withStopPropagation} from 'front-core';
import {values} from 'lodash';
import {TeamIconChip} from 'ui-components';
import {ModelSeriesGranularity} from '../../../../../../objects/models/model-sample-series.model';
import TransKeys from '../../../../../../constants/translation-keys';
import {UserSmartSelectorAvatar} from '../../../../../shared/core/smart-selector/user-smart-selector-avatar/user-smart-selector-avatar.component';
import {useTranslation} from 'react-i18next';
import {Action, Subject} from '../../../../../../constants/permissions';
import {ModelPageHeader} from '../../../../../../modules/shared/components/layout/model-page-header/model-page-header.component';
import {ModelPageHeaderShortDescription} from '../../../../../../modules/shared/components/layout/model-page-header/model-page-header-short-description.component';
import {PanelsContext} from '../../../../../../core/contexts/panels.context';
import {PanelKey} from '../../../../../../constants/panels';
import {METRIC_ID_PATH_PARAM} from '../../../../../../constants/app-routes';
import usePermissions from '../../../../../../core/hooks/use-permissions.hook';

interface OwnProps {
  metric: MetricPage;
  onOwnerChange: (userId: number) => void;
}

type AllProps = OwnProps;

export const MetricPageHeader = (props: AllProps) => {
  const {metric, onOwnerChange} = props;
  const {t} = useTranslation();

  const {openSecondaryPanel} = useContext(PanelsContext);

  const value = useMemo(() => {
    let v;
    if (ModelSeriesGranularity.WEEK in metric.valueAvg) {
      v = metric.valueAvg[ModelSeriesGranularity.WEEK];
    } else {
      v = values(metric.valueAvg)[0];
    }
    return metric.valueType === MetricValueType.PERCENTAGE ? `${number2k(v * 100)}%` : number2k(v);
  }, [metric]);
  // const users = useMemo(() => {
  //   const users = metric.history.map(h => h.user).filter(u => u);
  //   const usersMap = keyBy(users, 'id');
  //   const set = new Set(users.map(s => s.id));
  //   return Array.from(set).map(i => ({name: usersMap[i].fullName}));
  // }, [metric]);

  const onEdit = useCallback(
    () =>
      openSecondaryPanel(PanelKey.METRIC_FORM_PANEL, {
        [METRIC_ID_PATH_PARAM]: metric.id,
      }),
    [openSecondaryPanel, metric.id]
  );

  const {can} = usePermissions();
  const hasPermission = can(Subject.METRIC, Action.EDIT);
  const handleEdit = hasPermission && (withStopPropagation(() => onEdit()) as () => void);

  return (
    <ModelPageHeader
      value={value}
      valueDescription={t(TransKeys.METRIC_PAGE.HEADER.VALUE_DESCRIPTION)}
      title={metric.name}
      onEdit={handleEdit}
      subtitleItems={[
        metric.teams.length > 0 && (
          <div className={classes.Teams}>
            {metric.teams.map(t => (
              <TeamIconChip {...t} key={t.id} showTooltip size={'medium'} />
            ))}
          </div>
        ),
        <UserSmartSelectorAvatar
          userName={metric.ownerUserName}
          onChange={userId => onOwnerChange(userId)}
          clearText={t(TransKeys.METRICS.ACTIONS.REMOVE_OWNER)}
          tooltipText={t(TransKeys.METRICS.ACTIONS.SET_OWNER)}
          size={'small'}
        />,
        <ModelPageHeaderShortDescription
          description={metric.shortDescription}
          placeholder={t(TransKeys.METRIC_PAGE.HEADER.SHORT_DESCRIPTION_PLACEHOLDER)}
        />,
      ]}
    />
  );
};

import {NRC} from './index';
import {RequestType, toSnakeCase} from 'front-core';
import {toCamelCase} from 'front-core';
import {WeeklyInterestsDTO} from '../objects/dto/push-insights.dto';

export const createWeeklyInterestsNetworkRequest: NRC<WeeklyInterestsDTO, void> = (
  data: WeeklyInterestsDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/push-insights/weekly-interests`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

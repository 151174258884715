import {useDispatch, useSelector} from 'react-redux';
import {USER_ONBOARDING_STORE_KEY} from '../../store/user-onboarding/user-onboarding.store';
import {useMemo, useCallback, useEffect} from 'react';
import {UserOnboardingStatus, UserOnboardingTaskType} from '../../constants/user-onboarding';
import {
  setDrawerCollapse,
  dismissUserOnboardingConfirmed,
  setSelectSimpleAnalysisPickerPanelData,
} from '../../store/user-onboarding/user-onboarding.actions';
import {
  resetUserOnboarding,
  dismissUserOnboarding,
} from '../../store/user-onboarding/user-onboarding.actions';
import {sendHotjarEvent} from '../../config/hotjar.config';
import {HotjarEvent} from '../../constants/hotjar-event';

export function useUserOnboarding() {
  const dispatch = useDispatch();

  const {
    data: onboarding,
    drawerCollapsed: isDrawerCollapsed,
    resettingOnboarding: isResetting,
    showConfetti,
    selectSimpleAnalysisPickerPanelData,
  } = useSelector(state => state[USER_ONBOARDING_STORE_KEY]);

  const tasks = useMemo(
    () =>
      (onboarding?.tasks || []).filter(t => Object.values(UserOnboardingTaskType).includes(t.type)),
    [onboarding?.tasks]
  );
  const completedTasks = useMemo(() => tasks.filter(t => t.completedOn), [tasks]);
  const isCompleted = Boolean(onboarding?.completedOn);
  const isDismissed = !onboarding || Boolean(onboarding?.dismissedOn);

  const setDrawerCollapsed = useCallback(
    (collapse = true) => {
      isDrawerCollapsed !== collapse && dispatch(setDrawerCollapse(collapse));
    },
    [dispatch, isDrawerCollapsed]
  );

  const setDismiss = useCallback(
    (isDismissed = true) => {
      isCompleted
        ? dispatch(dismissUserOnboarding(isDismissed))
        : dispatch(dismissUserOnboardingConfirmed(isDismissed));
    },
    [dispatch, isCompleted]
  );

  const setResetting = useCallback(
    (isResetting = true) => {
      dispatch(resetUserOnboarding(isResetting));
    },
    [dispatch]
  );
  const resetUserOnboardingSimpleAnalysisPickerPanelData = useCallback(() => {
    dispatch(setSelectSimpleAnalysisPickerPanelData());
  }, [dispatch]);

  const status = useMemo(() => {
    if (!onboarding) {
      return UserOnboardingStatus.NOT_STARTED;
    } else if (isDismissed && isCompleted) {
      return UserOnboardingStatus.COMPLETED_AND_DISMISSED;
    } else if (isDismissed) {
      return UserOnboardingStatus.DISMISSED;
    } else if (isCompleted) {
      return UserOnboardingStatus.COMPLETED;
    } else {
      return UserOnboardingStatus.IN_PROGRESS;
    }
  }, [onboarding, isDismissed, isCompleted]);

  useEffect(() => {
    status === UserOnboardingStatus.IN_PROGRESS && sendHotjarEvent(HotjarEvent.USER_ONBOARDING);
  }, [status]);

  return {
    status,
    tasks,
    completedTasks,
    isCompleted,
    isDismissed,
    setDismiss,
    isDrawerCollapsed,
    setDrawerCollapsed,
    isResetting,
    setResetting,
    showConfetti: Boolean(showConfetti),
    selectSimpleAnalysisPickerPanelData,
    resetUserOnboardingSimpleAnalysisPickerPanelData,
  };
}

import {Epic, ofType} from 'redux-observable';
import {filter, tap} from 'rxjs/operators';
import ActionsListenerService from './actions-listener.service';
import {ActionsListenerType} from './actions-listener.actions';
import {RootActionsTypes} from 'front-core';

export const registerActionListenerEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ActionsListenerType.REGISTER_ACTION_LISTENER),
    tap(({payload}) =>
      ActionsListenerService.registerActionListener(payload.actionKey, payload.listener)
    ),
    filter(() => false)
  );

export const removeActionListenerEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ActionsListenerType.REMOVE_ACTION_LISTENER),
    tap(({payload}) =>
      ActionsListenerService.removeActionListener(payload.actionKey, payload.listener)
    ),
    filter(() => false)
  );

export const actionsBusEpic: Epic = (action$, state$) =>
  action$.pipe(
    filter(action => action.type !== RootActionsTypes.EMPTY),
    tap(action => ActionsListenerService.onAction(action)),
    filter(() => false)
  );

import {createContext, useCallback, useEffect, useMemo, useState} from 'react';
import {PanelKey} from '../../constants/panels';
import {PanelType} from '../../objects/system/panel-type.enum';
import {JsonParam, useQueryParams} from 'use-query-params';
import {cloneDeep, isEqual, keys, pick, uniqueId} from 'lodash';
import {UserActivity} from '../../constants/user-activity';
import {trackUserActivity} from '../../store/core/core.actions';
import {useDispatch} from 'react-redux';

export interface PanelData {
  id: string;
  key: PanelKey;
  type: PanelType;
  parameters: any;
}

export interface IPanelsContext {
  primaryPanel: PanelData;
  secondaryPanels: PanelData[];
  openPrimaryPanel: (key: PanelKey, parameters?: any, type?: PanelType) => string;
  openSecondaryPanel: (key: PanelKey, parameters?: any) => string;
  closePanel: (panelId?: string) => void;
}

export const PanelsContext = createContext<IPanelsContext>({
  primaryPanel: undefined,
  secondaryPanels: undefined,
  openPrimaryPanel: undefined,
  openSecondaryPanel: undefined,
  closePanel: undefined,
});

interface OwnProps {
  children: any;
}

const PANEL_ID_PREFIX = 'panel_';

export const PanelsContextProvider: React.FC<OwnProps> = (props: OwnProps) => {
  const {children} = props;
  const dispatch = useDispatch();
  const [query, setQuery] = useQueryParams({
    panel: JsonParam,
  });
  const [primaryPanel, setPrimaryPanel] = useState<PanelData>();
  const [secondaryPanels, setSecondaryPanels] = useState<PanelData[]>([]);

  const onPrimaryPanelOpened = useCallback(
    (key: PanelKey, parameters: any = {}) => {
      if (key === PanelKey.ANALYSIS_FORM_PANEL && !parameters.analysisId && !parameters.resultId) {
        dispatch(trackUserActivity(UserActivity.OPEN_ANALYSES_CATALOG));
      }
    },
    [dispatch]
  );
  const openPrimaryPanel = useCallback(
    (key: PanelKey, parameters: any = {}, type: PanelType = PanelType.MODAL) => {
      const panelId = uniqueId(PANEL_ID_PREFIX);
      setPrimaryPanel({id: panelId, key, type, parameters});
      onPrimaryPanelOpened(key, parameters);
      return panelId;
    },
    [setPrimaryPanel, onPrimaryPanelOpened]
  );
  const openSecondaryPanel = useCallback(
    (key: PanelKey, parameters: any = {}) => {
      const panelId = uniqueId(PANEL_ID_PREFIX);
      setSecondaryPanels(secPanels => [
        ...secPanels,
        {id: uniqueId(PANEL_ID_PREFIX), key, type: PanelType.MODAL, parameters},
      ]);
      return panelId;
    },
    [setSecondaryPanels]
  );
  const closePanel = useCallback(
    (panelId: string) => {
      // by default, we close the primary modal
      const pId = panelId || primaryPanel?.id;
      if (primaryPanel && primaryPanel.id === panelId) {
        return setPrimaryPanel(undefined);
      }
      const secPanelIndex = secondaryPanels.findIndex(p => p.id === pId);
      if (secPanelIndex >= 0) {
        const sps = [...secondaryPanels];
        sps.splice(secPanelIndex, 1);
        setSecondaryPanels(sps);
      }
    },
    [primaryPanel, secondaryPanels, setPrimaryPanel, setSecondaryPanels]
  );

  useEffect(() => {
    if (!query.panel) {
      return;
    }
    const current = cloneDeep(pick(primaryPanel, ['key', 'type', 'parameters']));
    keys(current.parameters).forEach(k => {
      const param = current.parameters[k];
      if (typeof param === 'function' || param === undefined) {
        delete current.parameters[k];
      }
    });
    if (isEqual(query.panel, current)) {
      return;
    }
    const {key, parameters, type} = query.panel || {};
    key && openPrimaryPanel(key, parameters, type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.panel, openPrimaryPanel]);

  useEffect(() => {
    setQuery(
      {
        panel: primaryPanel
          ? {
              key: primaryPanel.key,
              type: primaryPanel.type,
              parameters: primaryPanel.parameters,
            }
          : undefined,
      },
      'replaceIn'
    );
  }, [setQuery, primaryPanel]);

  const contextValue = useMemo(
    () => ({
      primaryPanel,
      secondaryPanels,
      openPrimaryPanel,
      openSecondaryPanel,
      closePanel,
    }),
    [primaryPanel, secondaryPanels, openPrimaryPanel, openSecondaryPanel, closePanel]
  );

  return <PanelsContext.Provider value={contextValue}>{children}</PanelsContext.Provider>;
};

import {useCallback, useEffect, useMemo, useState} from 'react';
import {exists} from 'front-core';
import {searchSegmentsNetworkRequest} from '../../../../../http/segments.network-requests';
import {TableEntity} from 'src/objects/models/table.model';
import {TableEntityBinding} from '../../../../../objects/models/table.model';
import {
  ConditionOperator,
  useRemoteSource,
  ConditionOperatorName,
  QueryBuilderFactory,
  Select,
  MultiSelect,
  LiteralValueType,
} from 'ui-components';
import classes from './segment-condition.module.scss';
import {PARAMETERS_METADATA_KEY} from '../../../../../constants/parameters-saved-keys';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import classNames from 'classnames';
import {useTeamFilter} from '../../../../../core/contexts/team-filter.context';
import {TeamFilterHelper} from '../../smart-selector/components/helpers/team-filter-helper.component';
import * as React from 'react';

interface OwnProps {
  data: any;
  onChange: (parameters: any) => void;
  entityContext: TableEntity;
  disabled?: boolean;
  localErrors?: any;
}

type AllProps = OwnProps;

const SEGMENT_ID_KEY = 'segment_id';
const ALLOWED_OPS = [ConditionOperator.IN, ConditionOperator.NIN, ConditionOperator.NIN_OR_NULL];

const OP_OPTIONS = ALLOWED_OPS.map(k => ({
  label: ConditionOperatorName[k].toUpperCase(),
  value: k,
}));

export const SegmentCondition: React.FC<AllProps> = (props: AllProps) => {
  const {data, onChange, entityContext, disabled, localErrors} = props;
  const {t} = useTranslation();
  const {
    teamId,
    teamName,
    ignore: ignoreTeamFilter,
    isActive: isActiveTeamFilter,
  } = useTeamFilter();
  const [segment, setSegment] = useState(null);
  const [segmentOptions, setSegmentOptions] = useState([]);

  const segmentFilters = useMemo(
    () => ({
      signal_filters: {
        entityContext,
        entityBinding: TableEntityBinding.ONE_WAY,
      },
      teamId: isActiveTeamFilter ? teamId : undefined,
    }),
    [entityContext, teamId, isActiveTeamFilter]
  );
  const {exec: getSegments} = useRemoteSource({
    type: 'source',
    networkRequest: searchSegmentsNetworkRequest,
    requestPayload: {
      limit: 1000,
    },
  });

  const {exec: getSource} = useRemoteSource({
    type: 'source',
    networkRequest: searchSegmentsNetworkRequest,
  });
  const getAndSetSegments = useCallback(async () => {
    const segments = await getSegments(null, segmentFilters);
    const options = segments.map(s => {
      const disabled = s.signalValidationStatus === 'error';
      return {
        label: s.name,
        value: s.id,
        disabled,
        helperText: disabled
          ? t(TransKeys.GENERAL.ERRORS.MODEL_SIGNAL_VALIDATION_BROKEN, {model: 'Segment'})
          : '',
      };
    });
    setSegmentOptions(options);
  }, [getSegments, setSegmentOptions, segmentFilters, t]);

  const segmentClassesOptions = useMemo(() => {
    if (!segment || !segment.classes) {
      return [];
    }
    return segment.classes.map(c => ({label: c.name, value: c.value}));
  }, [segment]);

  const segmentIdFromData = useMemo(() => data[PARAMETERS_METADATA_KEY]?.[SEGMENT_ID_KEY], [data]);

  const getAndSetSegment = useCallback(
    async id => {
      const segments: any = await getSource(null, {id});
      const segment = segments[0];
      setSegment(segment);
      return segment;
    },
    [getSource]
  );

  const onSegmentClassesSelected = useCallback(
    (values: string[] | number[]) => {
      const q = QueryBuilderFactory.createLiteral();
      q.value = values;
      q.value_type = LiteralValueType.STRING_LIST;

      const newData = {...data};
      newData.right = q;
      onChange(newData);
    },
    [data, onChange]
  );

  const onOpChange = useCallback(
    (op: ConditionOperator) => {
      const newData = {...data};
      newData.op = op;
      onChange(newData);
    },
    [data, onChange]
  );

  const onSegmentSelected: any = useCallback(
    async (id: number) => {
      const newData = {
        ...data,
      };
      let left = QueryBuilderFactory.createSignalColumn();

      if (id !== null) {
        const segment = await getAndSetSegment(id);
        left.signal_id = segment.signalId;
        newData[PARAMETERS_METADATA_KEY] = {[SEGMENT_ID_KEY]: id};
      } else {
        delete newData[PARAMETERS_METADATA_KEY];
      }

      newData.left = left;

      if (id === null || (data as any).left?.signal_id !== left.signal_id) {
        newData.right = {
          ...data.right,
          value: [],
        } as any;
      }
      onChange(newData);
    },
    [data, onChange, getAndSetSegment]
  );

  useEffect(() => {
    if (exists(segmentIdFromData) && (segment?.id !== segmentIdFromData || !segment)) {
      getAndSetSegment(segmentIdFromData);
    }
  }, [getAndSetSegment, segmentIdFromData, segment]);
  useEffect(() => {
    getAndSetSegments();
  }, [getAndSetSegments]);

  return (
    <div className={classes.Container}>
      <Select
        className={classes.SegmentSelect}
        dropdownButtonClassName={classes.SegmentSelectButton}
        placeholder={'Segment'}
        value={segmentIdFromData}
        error={Boolean(localErrors?.left)}
        onChange={onSegmentSelected}
        options={{options: segmentOptions}}
        selectionHelper={
          isActiveTeamFilter ? (
            <TeamFilterHelper teamName={teamName} onIgnore={ignoreTeamFilter} />
          ) : undefined
        }
        disabled={disabled}
        clearable={false}
      />
      <Select
        className={classes.SegmentOpSelect}
        dropdownButtonClassName={classes.SegmentOpSelectButton}
        placeholder={'Operator'}
        value={data.op}
        error={Boolean(localErrors?.op)}
        onChange={v => onOpChange(v as ConditionOperator)}
        searchable={false}
        clearable={false}
        options={{options: OP_OPTIONS}}
        disabled={disabled}
      />
      {segmentClassesOptions && (
        <MultiSelect
          className={classNames(
            classes.SegmentClassesSelect,
            disabled && classes.SegmentClassesSelectDisabled
          )}
          key={segment?.id}
          value={(data.right as any)?.value || []}
          options={{options: segmentClassesOptions}}
          onChange={onSegmentClassesSelected}
          error={Boolean(localErrors?.right)}
          disabled={disabled || !Boolean(segmentIdFromData)}
          placeholder={disabled ? undefined : 'Select'}
          allowEdit={false}
        />
      )}
    </div>
  );
};

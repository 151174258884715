import yup from '../../config/yup.config';
import {SchemaOf} from 'yup';
import {SchedulingDTO, schedulingDTOValidator} from './scheduling.dto';

export interface ScheduleAnalysisDTO extends SchedulingDTO {
  analysisId: number;
  analysisResultId: number;
}

export const scheduleAnalysisDTOValidator: SchemaOf<ScheduleAnalysisDTO> = yup.object().shape({
  analysisId: yup.number().required(),
  analysisResultId: yup.number().required(),
  ...schedulingDTOValidator,
});

import * as React from 'react';
import {RCAOverviewViewer} from 'ui-components';
import {useCallback, useContext} from 'react';
import {getRCACompareDataNetworkRequest} from '../../../../../http/homepage.network-requests';
import moment from 'moment/moment';
import {TIME_FORMATS} from '../../../../../constants/time-formats';
import {HttpClientContext} from 'front-core';

export const AppRCAOverviewViewer: React.FC<any> = (props: any) => {
  const http = useContext(HttpClientContext);

  const RCAMetricsResolver = useCallback(
    async (granularity: string, startDate: string, endDate: string) => {
      return await http.exec(
        getRCACompareDataNetworkRequest({
          granularity,
          fromDatetime: moment.utc(startDate).format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT),
          toDatetime: moment.utc(endDate).format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT),
        })
      );
    },
    [http]
  );

  return <RCAOverviewViewer {...props} resolveRCAMetrics={RCAMetricsResolver} />;
};

AppRCAOverviewViewer.defaultProps = {};

import * as React from 'react';
import {Route, Switch} from 'react-router';
import {AppRoutes} from '../../constants/app-routes';
import OpportunitiesMain from './pages/opportunities-main/opportunities-main.component';
import ViewOpportunity from './pages/view-opportunity/view-opportunity.component';

export const OpportunitiesRouting: React.FC = props => {
  return (
    <Switch>
      <Route path={AppRoutes.opportunities().pathname} component={OpportunitiesMain} exact />
      <Route path={AppRoutes.viewOpportunity().pathname} component={ViewOpportunity} exact />
    </Switch>
  );
};

import {NRC} from './index';
import {RequestType} from 'front-core';
import {toCamelCase, toSnakeCase} from 'front-core';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';
import {GoalDTO} from '../objects/dto/goal.dto';

export const getGoalsNetworkRequest: NRC<void, any> = (payload: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/goals`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data),
});

export const getGoalNetworkRequest: NRC<void, any> = (id: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/goals/${id}`,
  responseTransformer: res => toCamelCase(res.data),
});

export const createGoalNetworkRequest: NRC<GoalDTO, any> = (data: GoalDTO) => ({
  method: RequestType.POST,
  relativeUrl: `/app/goals`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const updateGoalNetworkRequest: NRC<GoalDTO, any> = (data: GoalDTO) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/goals/${data.id}`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const deleteGoalNetworkRequest: NRC<GoalDTO, any> = (id: number) => ({
  method: RequestType.DELETE,
  relativeUrl: `/app/goals/${id}`,
  responseTransformer: res => toCamelCase(res.data),
});

import * as React from 'react';
import {useCallback, useContext, useMemo, useState} from 'react';
import {
  Button,
  FancyHeader,
  FunnelIcon,
  LabelWrapper,
  ModalLayout,
  ModelType,
  Select,
  SwitchActions,
  TextInput,
} from 'ui-components';
import {
  ANALYSIS_TYPE_ID_PATH_PARAM,
  FUNNEL_ID_PATH_PARAM,
  SIGNAL_ID_PATH_PARAM,
} from '../../../../constants/app-routes';
import classes from './funnel-form-panel.module.scss';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../constants/translation-keys';
import {Controller, FormProvider, useFieldArray, useForm} from 'react-hook-form';
import {Funnel} from '../../../../objects/models/funnel.model';
import {get, isEmpty, startCase, toLower, values} from 'lodash';
import {funnelDTOValidator} from '../../../../objects/dto/funnel.dto';
import {createFunnel, updateFunnel} from '../../../../store/funnels/funnels.actions';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {useDispatch, useSelector} from 'react-redux';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors';
import {sharedClasses} from '../../../shared';
import {yupResolver} from '@hookform/resolvers/yup';
import {getFunnelNetworkRequest} from '../../../../http/funnels.network-requests';
import {ActionKey} from '../../../../constants/action-key';
import {composition, OnSuccessActionHook, withMetadata} from 'front-core';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {FormHiddenInputs} from '../../../shared/form/components/form-hidden-inputs.component';
import {
  TextareaFormInput,
  TextFormInput,
} from '../../../shared/form/components/shared-form-input.component';
import {preventSubmitOnEnter} from '../../../../utils/general.utils';
import classNames from 'classnames';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {
  FunnelTab,
  FunnelTabs,
} from '../../../shared/components/general/funnel-tabs/funnel-tabs.component';
import {PanelKey} from '../../../../constants/panels';
import {METADATA_KEY, PARAMETERS_METADATA_KEY} from '../../../../constants/parameters-saved-keys';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {TableEntity} from '../../../../objects/models/table.model';
import {TitleWithIcon} from '../../../shared/components/general/title/title.component';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {ALLOW_AD_HOC_PROP_KEY} from '../../../../constants/shared-component-prop-key';
import {AdHocIndication} from '../../../shared/components/general/ad-hoc-indication/ad-hoc-indication.component';
import {FunnelStepQueryBuilder} from 'src/modules/shared/core/query-builders/funnel-step-query-builder/funnel-step-query-builder.component';
import {ConfirmationDialogContext} from '../../../../core/contexts/confirmation-dialog.context';
import {AnalysisTypeId} from '../../../../constants/analysis-type-id';
import {PanelHierarchy} from '../../../shared/infrastracture/module-structure/module-structure.component';
import {withModalInactiveSourceHandler} from '../../../../core/hoc/with-modal-inactive-source-handler.hoc';
import {useDemoProduct} from '../../../../core/hooks/use-demo-product.hook';
import {withDisableDemoProduct} from '../../../../core/hoc/with-disable-demo-product.hoc';
import {getEntityIcon} from '../../../../constants/entity.consts';

interface OwnProps {
  funnel?: Funnel;
  data: Partial<Funnel>;
  onSubmit?: (data: Partial<Funnel>) => void;
  onSuccess?: OnSuccessActionHook;
  onClose?: () => void;
  panelId?: string;
  panelHierarchy?: PanelHierarchy;
  cloneMode?: boolean;
  disabled?: boolean;
  [FUNNEL_ID_PATH_PARAM]?: number;
  [ALLOW_AD_HOC_PROP_KEY]?: boolean;
}

type AllProps = OwnProps;

const createEmptyFunnel = (): Partial<Funnel> => ({
  name: '',
  shortDescription: '',
  defaultBoundingDays: 7,
});

const CUSTOM_OPTION_VALUE = 'custom';
const BOUNDING_DAYS_OPTIONS = [
  {label: '7 days', value: 7},
  {label: '14 days', value: 14},
  {label: '28 days', value: 28},
  {label: 'Custom', value: CUSTOM_OPTION_VALUE},
];

const SELECTED_FUNNEL_KEY = SharedSelectionKeys.FUNNEL_FORM__FUNNEL;

const isEmptyStep = step => !step || isEmpty(step.definition);

const isCustomBoundingDays = (value: number) =>
  values(BOUNDING_DAYS_OPTIONS).find(o => o.value === value) === undefined;

const FunnelFormPanelComponent: React.FC<AllProps> = (props: AllProps) => {
  const {
    funnel,
    data,
    onClose,
    cloneMode,
    disabled,
    [ALLOW_AD_HOC_PROP_KEY]: allowAdHoc,
    onSubmit: onSubmitFromProps,
    onSuccess,
    panelHierarchy,
  } = props;
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {productEntities, productEntitiesMap, defaultTableEntity} = useProductData();
  const {demoProductValidator} = useDemoProduct();
  const {openSecondaryPanel} = useContext(PanelsContext);

  const overrideData = useMemo(() => (cloneMode ? {id: undefined} : {}), [cloneMode]);
  const defaultValues: Partial<Funnel> = useMemo(
    () => ({
      steps: [{}], // initial step for create mode
      ...data,
      entity: defaultTableEntity,
      ...funnel,
      ...overrideData,
    }),
    [data, defaultTableEntity, funnel, overrideData]
  );
  const formMethods = useForm({
    defaultValues: defaultValues as any,
    resolver: yupResolver(demoProductValidator || funnelDTOValidator),
  });
  const {
    handleSubmit,
    formState: {errors, isValidating, isSubmitting},
    control,
    setValue,
    getValues,
    watch,
  } = formMethods;
  const {
    fields: steps,
    append: addStep,
    remove: removeStep,
    swap: swapSteps,
  } = (useFieldArray as any)({
    control,
    name: 'steps',
    keyName: 'key',
  });
  const {setConfirmationDialog} = useContext(ConfirmationDialogContext);
  const stepsWatched = watch('steps');
  const entity = watch('entity');
  const [selectedStep, setSelectedStep] = useState(0);
  const [customBoundingDays, setCustomBoundingDays] = useState(
    isCustomBoundingDays(defaultValues.defaultBoundingDays)
  );
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(ActionKey.CREATE_FUNNEL, ActionKey.UPDATE_FUNNEL)(state)
  );
  const editMode = Boolean(watch('id'));
  const onSubmit = useCallback(
    (data, runAnalysis: boolean = false) => {
      if (onSubmitFromProps) {
        onSubmitFromProps(data);
        return;
      }
      const onActionSuccess = (res, action) => {
        onSuccess && onSuccess(res, action);
        runAnalysis &&
          openSecondaryPanel(PanelKey.ANALYSIS_FORM_PANEL, {
            [ANALYSIS_TYPE_ID_PATH_PARAM]: AnalysisTypeId.FUNNEL_ANALYSIS,
            parameters: {
              funnel: res.id,
              entity: res.entity,
            },
          });
        onClose();
      };
      dispatch(
        withMetadata(
          editMode ? updateFunnel(data, onActionSuccess) : createFunnel(data, onActionSuccess),
          {
            run_analysis: runAnalysis,
          }
        )
      );
    },
    [dispatch, editMode, onSubmitFromProps, onSuccess, onClose, openSecondaryPanel]
  );
  const onSubmitAdHoc = useCallback(data => onSubmit({...data, isAdHoc: true}), [onSubmit]);
  const onSubmitSaveToDMP = useCallback(data => onSubmit({...data, isAdHoc: false}), [onSubmit]);
  const onSubmitSaveRunAnalysis = useCallback(data => onSubmit(data, true), [onSubmit]);

  const onSwitchEntity = useCallback(
    (entity: TableEntity) => {
      const onApprove = () => {
        setSelectedStep(0);
        setValue('entity', entity);
        // For some reason, setValue('steps', [{}]) doesn't reset the first step
        // So we need to do it manually
        setValue('steps', [{}]);
        setValue('steps.0', {name: undefined, definition: undefined});
      };

      if (stepsWatched.length > 1 || !isEmptyStep(stepsWatched[0])) {
        setConfirmationDialog({
          title: t(TransKeys.CONFIRMATIONS.CHANGE, {subject: 'entity'}),
          content: t(TransKeys.CONFIRMATIONS.RESET_QUERY_WARNING),
          onApprove,
        });
      } else {
        onApprove();
      }
    },
    [t, setValue, setSelectedStep, setConfirmationDialog, stepsWatched]
  );

  const entityOptions = useMemo(
    () =>
      productEntities.map(p => ({
        key: p.key,
        label: p.name,
        onClick: () => onSwitchEntity(p.key),
        isActive: entity === p.key,
        icon: getEntityIcon(p.key),
      })),
    [productEntities, entity, onSwitchEntity]
  );
  const boundingDaysOptions = useMemo(() => ({options: BOUNDING_DAYS_OPTIONS}), []);

  const title = useMemo(() => {
    if (editMode) {
      return (
        <>
          {t(TransKeys.FUNNEL_FORM.TITLE_EDIT)}{' '}
          {funnel.isAdHoc && (
            <AdHocIndication model={ModelType.FUNNEL} className={classes.AdHocIndication} />
          )}
        </>
      );
    }
    return t(TransKeys.FUNNEL_FORM.TITLE_CREATE);
  }, [editMode, t, funnel]);

  const onSignalInfo = useCallback(
    signalId =>
      openSecondaryPanel(PanelKey.SIGNAL_DEFINITION_PANEL, {
        [SIGNAL_ID_PATH_PARAM]: signalId,
      }),
    [openSecondaryPanel]
  );

  const onAddStep = useCallback(() => {
    addStep({name: '', definition: null});
    setSelectedStep(steps.length);
  }, [addStep, steps, setSelectedStep]);

  const onDeleteStep = useCallback(
    (idx: number) => {
      removeStep(idx);
      if (idx <= selectedStep && selectedStep > 0) {
        setSelectedStep(selectedStep - 1);
      }
    },
    [removeStep, setSelectedStep, selectedStep]
  );

  const onSwapSteps = useCallback(
    (idx: number, newIdx: number) => {
      swapSteps(idx, newIdx);
      if (selectedStep === idx) {
        setSelectedStep(newIdx);
      }
      if (selectedStep === newIdx) {
        setSelectedStep(idx);
      }
    },
    [swapSteps, selectedStep, setSelectedStep]
  );

  const setFunnelStepName = useCallback(
    (idx, name) => {
      const stepNamePath = `steps.${idx}.name`;
      const stepName = getValues(stepNamePath);
      if (!stepName) {
        return setValue(stepNamePath, name);
      }
      if (!name) {
        // Upon reset of the step, set the stepName to ''
        return setValue(stepNamePath, '');
      }
      if (name !== stepName) {
        name = startCase(toLower(name));
        return setValue(stepNamePath, name);
      }
    },
    [getValues, setValue]
  );

  const onQueryChange = useCallback(
    (value, idx) => {
      setValue(`steps.${idx}.definition`, value);
      setFunnelStepName(
        idx,
        get(value, `${PARAMETERS_METADATA_KEY}.${METADATA_KEY.DISPLAY_NAME_KEY}`)
      );
    },
    [setValue, setFunnelStepName]
  );
  const onChangeDefaultBoundingDays = useCallback(
    (value: number | string, isSelectionValue?: boolean) => {
      if (value === CUSTOM_OPTION_VALUE) {
        setCustomBoundingDays(true);
        return;
      }
      setValue('defaultBoundingDays', value);
      if (isSelectionValue) {
        setCustomBoundingDays(false);
      }
    },
    [setValue, setCustomBoundingDays]
  );

  const tabs: FunnelTab[] = steps.map((step, idx) => ({
    key: idx,
    title: stepsWatched[idx].name || `Step ${idx + 1}`,
    subTitle: idx === 0 ? 'Initial step' : `Step ${idx + 1}`,
    error: get(errors, `steps.${idx}.definition`),
    render: () => (
      /*THIS IS SUPER IMPORTANT - DO NOT REMOVE! THIS IS USED TO OVERRIDE TAB KEY!*/
      <React.Fragment key={step.key}>
        <div className={classes.FunnelTabContent}>
          <LabelWrapper
            label={t(TransKeys.FUNNEL_FORM.INPUTS.STEPS.STEP_TITLE_COUNT, {count: idx + 1})}
            className={classes.StepInputWrapper}
          >
            <Controller
              name={`steps.${idx}.definition`}
              control={control}
              render={({field}) => (
                <FunnelStepQueryBuilder
                  query={field.value}
                  onChange={query => onQueryChange(query, idx)}
                  errors={get(errors, `steps.${idx}.definition`, {})}
                  onSignalInfo={onSignalInfo}
                  isFirstStep={idx === 0}
                  entity={entity}
                />
              )}
            />
          </LabelWrapper>
          <TextFormInput
            key={step.key}
            className={classes.StepNameInput}
            label={t(TransKeys.FUNNEL_FORM.INPUTS.STEPS.STEP_NAME)}
            placeholder={t(TransKeys.FUNNEL_FORM.INPUTS.STEPS.NAME_PLACEHOLDER, {
              stepIndex: idx + 1,
            })}
            name={`steps.${idx}.name`}
          />
        </div>
      </React.Fragment>
    ),
  }));

  const isDisabled = useMemo(
    () => isLoading || isSubmitting || isValidating || disabled,
    [isLoading, isSubmitting, isValidating, disabled]
  );
  const renderFooterActions = () => {
    const isAdHocAllowed = (allowAdHoc && !editMode) || funnel?.isAdHoc;

    if (isAdHocAllowed) {
      return [
        <Button
          className={classes.AdHocSave}
          helperText={t(TransKeys.GENERAL.ACTIONS.SAVE_AD_HOC_HELPER_TEXT, {
            model: t(TransKeys.MODELS.FUNNEL),
          })}
          disabled={isDisabled}
          onClick={handleSubmit(onSubmitAdHoc)}
        >
          {t(TransKeys.GENERAL.ACTIONS.SAVE_AD_HOC)}
        </Button>,
        <Button caps={false} disabled={isDisabled} onClick={handleSubmit(onSubmitSaveToDMP)}>
          {funnel?.isAdHoc
            ? t(TransKeys.GENERAL.ACTIONS.SAVE_TO_DMP)
            : t(TransKeys.GENERAL.ACTIONS.SAVE)}
        </Button>,
      ];
    }

    return [
      <Button
        caps={false}
        disabled={isDisabled}
        onClick={handleSubmit(onSubmitSaveToDMP)}
        variant={panelHierarchy === PanelHierarchy.PRIMARY ? 'outlined' : undefined}
      >
        {t(TransKeys.GENERAL.ACTIONS.SAVE)}
      </Button>,
      panelHierarchy === PanelHierarchy.PRIMARY ? (
        <Button caps={false} disabled={isDisabled} onClick={handleSubmit(onSubmitSaveRunAnalysis)}>
          {t(TransKeys.GENERAL.ACTIONS.SAVE_AND_RUN_ANALYSIS)}
        </Button>
      ) : null,
    ];
  };

  return (
    <div className={classes.FunnelFormContainer}>
      <ModalLayout footer={<div className={classes.SubmitActions}>{renderFooterActions()}</div>}>
        {isLoading && <GenericLoading />}
        <div className={classes.CreateFunnel}>
          <FancyHeader
            icon={FunnelIcon}
            title={title}
            subTitle={t(TransKeys.FUNNEL_FORM.MAIN_TITLE)}
            onClose={onClose}
            className={classes.CreateFunnelHeader}
          />
          <FormProvider {...formMethods}>
            <form className={sharedClasses.Form} onKeyDown={preventSubmitOnEnter}>
              <FormHiddenInputs names={['id']} />
              <div className={sharedClasses.FormContent}>
                <div className={sharedClasses.Block}>
                  <div className={sharedClasses.Input}>
                    <TextFormInput
                      label={t(TransKeys.FUNNEL_FORM.INPUTS.NAME.LABEL)}
                      placeholder={t(TransKeys.FUNNEL_FORM.INPUTS.NAME.PLACEHOLDER)}
                      name={'name'}
                      autoFocus
                      required
                    />
                  </div>
                </div>
                <div className={sharedClasses.Block}>
                  <div className={sharedClasses.Input}>
                    <TextareaFormInput
                      placeholder={t(TransKeys.FUNNEL_FORM.INPUTS.DESCRIPTION.PLACEHOLDER)}
                      label={t(TransKeys.FUNNEL_FORM.INPUTS.DESCRIPTION.LABEL)}
                      name={'shortDescription'}
                    />
                  </div>
                </div>
                {entityOptions.length > 1 && (
                  <div className={sharedClasses.Block}>
                    <div className={sharedClasses.Input}>
                      <LabelWrapper label={t(TransKeys.FUNNEL_FORM.INPUTS.ENTITY)} fullWidth>
                        {!editMode && (
                          <SwitchActions
                            actions={entityOptions}
                            showActionsLabel
                            disabled={editMode}
                          />
                        )}
                        {editMode && (
                          <TitleWithIcon
                            text={productEntitiesMap[entity].name}
                            icon={getEntityIcon(entity)}
                            size={'medium'}
                          />
                        )}
                      </LabelWrapper>
                    </div>
                  </div>
                )}
                <div className={classNames(sharedClasses.Block)}>
                  <div className={sharedClasses.Input}>
                    <LabelWrapper
                      label={t(TransKeys.FUNNEL_FORM.INPUTS.BOUNDING_DAYS.LABEL)}
                      tooltipContent={t(TransKeys.FUNNEL_FORM.INPUTS.BOUNDING_DAYS.DESCRIPTION)}
                      fullWidth
                    >
                      <Controller
                        render={({field, fieldState: {error}}) => (
                          <div className={classes.BoundingDays}>
                            <Select
                              value={customBoundingDays ? CUSTOM_OPTION_VALUE : field.value}
                              options={boundingDaysOptions}
                              onChange={v => onChangeDefaultBoundingDays(v, true)}
                              searchable={false}
                              sortValues={false}
                              clearable={false}
                              error={Boolean(error)}
                              capitalize={false}
                            />
                            {customBoundingDays && (
                              <>
                                <TextInput
                                  className={classes.Input}
                                  value={field.value}
                                  onChange={onChangeDefaultBoundingDays as any}
                                  error={Boolean(error)}
                                  type={'number'}
                                  placeholder={'N'}
                                />
                                <span>Days</span>
                              </>
                            )}
                          </div>
                        )}
                        name={'defaultBoundingDays'}
                        control={control}
                      />
                    </LabelWrapper>
                  </div>
                </div>

                <div className={classNames(sharedClasses.Block)}>
                  <div className={sharedClasses.Input}>
                    <LabelWrapper label={t(TransKeys.FUNNEL_FORM.INPUTS.DEFINE)} fullWidth>
                      {errors?.steps && typeof errors?.steps === 'object' && (
                        <div className={classes.Error}>{errors?.steps?.message}</div>
                      )}
                      <FunnelTabs
                        tabs={tabs}
                        selectedKey={selectedStep as any}
                        onTabChange={setSelectedStep as any}
                        onAdd={onAddStep}
                        onDelete={onDeleteStep}
                        onSwap={onSwapSteps}
                        lockFirstTab
                      />
                    </LabelWrapper>
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </ModalLayout>
    </div>
  );
};

FunnelFormPanelComponent.defaultProps = {
  data: createEmptyFunnel(),
};

const FunnelFormPanel = composition<AllProps>(
  FunnelFormPanelComponent,
  withModalInactiveSourceHandler,
  withDisableDemoProduct,
  withLoadBefore({
    funnel: {
      selectedKey: SELECTED_FUNNEL_KEY,
      actionKey: SELECTED_FUNNEL_KEY,
      request: getFunnelNetworkRequest,
      mapPayloadFromProps: props => props[FUNNEL_ID_PATH_PARAM],
      shouldCall: props => props[FUNNEL_ID_PATH_PARAM] !== undefined,
    },
  })
);
export default FunnelFormPanel;

import {action} from 'front-core';
import {ModelKey} from '../../constants/model-key';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {UserActivity} from '../../constants/user-activity';

export enum CoreActionsType {
  APP_FIRST_ACTION = '@@core/APP_FIRST_ACTION',
  INITIAL_LOADING = '@@core/INITIAL_LOADING',
  SET_SCOPE_PRODUCT = '@@core/SET_SCOPE_PRODUCT',
  CHANGE_DEFAULT_TEAM = '@@core/CHANGE_DEFAULT_TEAM',
  MODEL_UPDATED = '@@core/MODEL_UPDATED',
  MODEL_CREATED = '@@core/MODEL_CREATED',
  MODEL_DELETED = '@@core/MODEL_DELETED',
  NOTIFY_EVENT = '@@core/NOTIFY_EVENT',
  CREATE_USER_ACTIVITY = '@@core/CREATE_USER_ACTIVITY',
  GET_APP_STATUS_COUNTERS = '@@core/GET_APP_STATUS_COUNTERS',
  GET_PRODUCT_DATA = '@@core/GET_PRODUCT_DATA',
  GET_PRODUCT_DATA_COMPLETED = '@@core/GET_PRODUCT_DATA_COMPLETED',
  HUBSPOT_VISITOR_TOKEN_REQUEST = '@@core/HUBSPOT_VISITOR_TOKEN_REQUEST',
  CHANGE_ADMIN_PRIVILEGES = '@@core/CHANGE_ADMIN_PRIVILEGES',
}

export const initialLoading = () => action(CoreActionsType.INITIAL_LOADING);
export const appFirstAction = () => action(CoreActionsType.APP_FIRST_ACTION);
export const setScopeProduct = (productId: number) =>
  action(CoreActionsType.SET_SCOPE_PRODUCT, productId);
export const changeDefaultTeam = (defaultTeamId: number) =>
  action(CoreActionsType.CHANGE_DEFAULT_TEAM, defaultTeamId);
export const modelCreated = (data: any, modelKey: ModelKey) =>
  action(CoreActionsType.MODEL_CREATED, {data, modelKey});
export const modelUpdated = (data: any, modelKey: ModelKey) =>
  action(CoreActionsType.MODEL_UPDATED, {data, modelKey});
export const modelDeleted = (data: any, modelKey: ModelKey) =>
  action(CoreActionsType.MODEL_DELETED, {data, modelKey});
export const notifyEvent = (type: AmplitudeEvent, payload: any = {}) =>
  action(CoreActionsType.NOTIFY_EVENT, {type, payload});
export const trackUserActivity = (activityType: UserActivity, data: any = {}) =>
  action(CoreActionsType.CREATE_USER_ACTIVITY, {type: activityType, data});
export const getAppStatusCounters = () => action(CoreActionsType.GET_APP_STATUS_COUNTERS);
export const getProductData = () => action(CoreActionsType.GET_PRODUCT_DATA);
export const getProductDataCompleted = () => action(CoreActionsType.GET_PRODUCT_DATA_COMPLETED);
export const getHubspotVisitorToken = () => action(CoreActionsType.HUBSPOT_VISITOR_TOKEN_REQUEST);
export const changeAdminPrivileges = (show: boolean) =>
  action(CoreActionsType.CHANGE_ADMIN_PRIVILEGES, show);

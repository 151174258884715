import {NotSignificantChangeKPI, RCAInsightSentence} from './objects';
import sharedClasses from '../insights.module.scss';
import {
  ChangeInValueSentencePart,
  SegmentNameSentencePart,
} from '../components/shared-sentence-parts/shared-sentence-parts.components';

interface OwnProps extends RCAInsightSentence {
  formattedSentenceParts: NotSignificantChangeKPI;
}
type AllProps = OwnProps;

const NotSignificantChangeKPIFormatter: React.FC<AllProps> = (props: AllProps) => {
  const {formattedSentenceParts, sentenceParts} = props;
  const {presentTensePerformance} = formattedSentenceParts;
  const {segmentGroupName, segmentName, changeInValue} = sentenceParts;

  return (
    <div className={sharedClasses.InsightSentenceContainer}>
      <SegmentNameSentencePart segmentGroupName={segmentGroupName} segmentName={segmentName} />
      <span
        className={sharedClasses.InsightSentencePart}
      >{`${presentTensePerformance} vs the analyzed period by`}</span>
      <ChangeInValueSentencePart changeInValue={changeInValue} />
    </div>
  );
};

export {NotSignificantChangeKPIFormatter};

import {action} from 'front-core';
import {LoginDTO} from '../../objects/dto/login.dto';

export enum AuthActionType {
  LOGIN_REQUEST = '@@auth/LOGIN_REQUEST',
  LOGOUT_REQUEST = '@@auth/LOGOUT_REQUEST',
  UPDATE_TOKEN = '@@auth/UPDATE_TOKEN',
  MFA_REQUEST = '@@auth/MFA_REQUEST',
  RESEND_MFA_REQUEST = '@@auth/RESEND_MFA_REQUEST',
  MOVE_TO_RESET_PASSWORD = '@@auth/MOVE_TO_RESET_PASSWORD',

  GET_USER_REQUEST = '@@auth/GET_USER_REQUEST',
  GET_USER_SUCCESS = '@@auth/GET_USER_SUCCESS',
  GET_USER_ERROR = '@@auth/GET_USER_ERROR',
}

export const getUserRequest = () => action(AuthActionType.GET_USER_REQUEST);
export const loginRequest = (data: LoginDTO) => action(AuthActionType.LOGIN_REQUEST, data);
export const mfaRequest = (code: string, tokenId: number) =>
  action(AuthActionType.MFA_REQUEST, {code, tokenId});
export const resendMFARequest = (tokenId: number) =>
  action(AuthActionType.RESEND_MFA_REQUEST, {tokenId});
export const updateToken = (token?: string) => action(AuthActionType.UPDATE_TOKEN, token);
export const logout = () => action(AuthActionType.LOGOUT_REQUEST);
export const moveToResetPassword = () => action(AuthActionType.MOVE_TO_RESET_PASSWORD);
